import React from "react"
import Header from "../components/Header"
import CheckListInstanceGrid from "../components/CheckListInstanceGrid"

const CheckListInstances = () => {
  return (
    <Header title="Checklists">
      <CheckListInstanceGrid />
    </Header>
  )
}

export default CheckListInstances
