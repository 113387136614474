const formatAmount = (cents) => {
  return (
    "$" + (cents / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  )
}

const uppercaseFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export { formatAmount, uppercaseFirstLetter }
