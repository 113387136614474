import React, { useState } from "react"
import Header from "../components/Header"
import { List, ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material"
import { useId } from "./useId"
import { Typography } from "@mui/material"
import { green, red, blue, pink } from "@mui/material/colors"
import BugReportIcon from "@mui/icons-material/BugReport"
import UserExperienceIcon from "@mui/icons-material/Accessibility"
import FeatureIcon from "@mui/icons-material/Build"
import DataIcon from "@mui/icons-material/List"
import { spacing } from "./services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(1),
        padding: spacing(1),
    },
    releaseItem: {
        margin: 0,
    },
    newReleaseIcon: {
        backgroundColor: green[500],
    },
}

const itemIcons = {
    Bug: { icon: <BugReportIcon style={{ color: red[500] }} /> },
    Feature: { icon: <FeatureIcon style={{ color: green[500] }} /> },
    UX: { icon: <UserExperienceIcon style={{ color: blue[500] }} /> },
    Data: { icon: <DataIcon style={{ color: pink[500] }} /> },
}

function ReleaseNotes(props) {
    let { id } = useId(props)

    const [title, setTitle] = useState("Release Notes")

    const releaseNotes = [
        {
            date: "6 Apr 2022",
            changes: [
                {
                    type: "UX",
                    notes: "Remember filter settings - on Job Summary page (search filter) and Job Grid (job status type)",
                },
                {
                    type: "Bug",
                    notes: "Fix 'Select All' toggle on Job Summary page",
                },
            ],
        },
    ]

    const [data, setData] = useState(releaseNotes)

    return (
        <Header title={title}>
            <Paper sx={styles.pageContent}>Release Notes</Paper>

            {releaseNotes.map((entry) => (
                <Paper sx={styles.pageContent} key={entry.date}>
                    <Typography>{entry.date}</Typography>
                    <List>
                        {entry.changes.map((change) => (
                            <ListItem key={change.notes}>
                                <ListItemIcon>{itemIcons[change.type].icon}</ListItemIcon>
                                <ListItemText
                                    sx={styles.releaseItem}
                                    primary={
                                        <Typography variant="body2">{change.notes}</Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            ))}
        </Header>
    )
}

export default ReleaseNotes
