import React, { useState, useMemo } from "react"
import {
    Alert,
    AppBar,
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    IconButton,
    Slide,
    Toolbar,
    Typography,
} from "@mui/material"
import { useEffect } from "react"
import db from "../Firestore"
import { CloseIcon } from "../icons"
import Controls from "./controls/Controls"
import * as icons from "../icons"
import NavButtons from "./NavButtons"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const SupplierMergeDialog = (props) => {
    const { open, onClose, handleMerge, supplier, supplierId } = props

    const [duplicateSuppliers, setDuplicateSuppliers] = useState([])

    const [selectedSupplierId, setSelectedSupplierId] = useState("")

    const [accountId, setAccountId] = useState("")

    const [verifyMerge, setVerifyMerge] = useState(false)

    useEffect(() => {
        const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdTokenResult().then((token) => {
                    setAccountId(token.claims.account_id)
                })
            }
        })

        return unsub
    }, [])

    const isSuppliersToMerge = useMemo(() => {
        return duplicateSuppliers.find((s) => !s.hasOwnProperty("supplier_account_id"))
    }, [duplicateSuppliers])

    useEffect(() => {
        if (accountId) {
            db.collection("suppliers")
                .where("name", "==", supplier.name)
                .where("account_id", "==", supplier.account_id)
                .get()
                .then((querySnapshot) => {
                    const suppliers = []
                    querySnapshot.forEach((doc) => {
                        suppliers.push({ id: doc.id, ...doc.data() })
                    })
                    setDuplicateSuppliers(suppliers.filter((s) => s.id !== supplierId))
                })
        }
    }, [supplier.name, accountId])

    return (
        <Dialog open={open} onClose={onClose} fullScreen TransitionComponent={Transition}>
            <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Merge Duplicate Suppliers
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
                    <Box>
                        <Typography variant="h6">This supplier will be retained</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                            <Box sx={{ width: "45px" }}>
                                {supplier.hasOwnProperty("supplier_account_id") && (
                                    <icons.SupplierIcon color="secondary" />
                                )}
                            </Box>
                            <SupplierSummary supplier={supplier} />
                        </Box>
                    </Box>

                    {!isSuppliersToMerge && (
                        <Alert severity="warning" sx={{ marginTop: "10px" }}>
                            There are no suppliers with the same name to merge with this supplier
                        </Alert>
                    )}

                    <Box sx={{ marginTop: "20px" }}>
                        <Typography variant="h6">
                            Select supplier to merge into the above supplier
                        </Typography>
                    </Box>

                    <Box>
                        {duplicateSuppliers.map((s) => (
                            <Box key={s.id} sx={{ margin: "5px", marginBottom: "10px" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "nowrap",
                                    }}
                                >
                                    <Box>
                                        {s.hasOwnProperty("supplier_account_id") && (
                                            <Box sx={{ width: "40px" }}>
                                                <icons.SupplierIcon color="secondary" />
                                            </Box>
                                        )}
                                        {!s.hasOwnProperty("supplier_account_id") && (
                                            <Checkbox
                                                checked={selectedSupplierId === s.id}
                                                onChange={() =>
                                                    setSelectedSupplierId((curr) =>
                                                        s.id === curr ? "" : s.id
                                                    )
                                                }
                                                sx={{ paddingTop: 0 }}
                                                disabled={s.hasOwnProperty("supplier_account_id")}
                                            />
                                        )}
                                    </Box>
                                    <SupplierSummary supplier={s} />
                                </Box>
                                {s.id === selectedSupplierId && (
                                    <Alert severity="info" sx={{ marginTop: "10px" }}>
                                        This supplier will have its jobs and documents merged into
                                        the above supplier, and will then be deleted
                                    </Alert>
                                )}
                                {s.hasOwnProperty("supplier_account_id") && (
                                    <Alert severity="info" sx={{ marginTop: "10px" }}>
                                        This is an externally managed supplier. If you need to merge
                                        this supplier then open it from the Suppliers page so that
                                        it is the target supplier into which other suppliers can be
                                        merged
                                    </Alert>
                                )}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={verifyMerge}
                            onChange={(e) => setVerifyMerge(e.target.checked)}
                            disabled={selectedSupplierId === ""}
                        />
                    }
                    label="I understand that this action cannot be undone"
                />
                <NavButtons>
                    <Controls.Button
                        onClick={() => handleMerge(supplierId, selectedSupplierId)}
                        text="Merge Suppliers"
                        disabled={verifyMerge === false || selectedSupplierId === ""}
                    />
                    <Controls.Button onClick={onClose} text="Cancel" />
                </NavButtons>
            </DialogActions>
        </Dialog>
    )
}

const SupplierSummary = (props) => {
    const { supplier } = props

    return (
        <Box>
            <Typography variant="body1">{supplier.name}</Typography>
            <Typography variant="body1">{supplier.email}</Typography>
            <Typography variant="body1">{supplier.phone}</Typography>
            <Typography variant="body1">{supplier.addr1}</Typography>
            <Typography variant="body1">{supplier.addr2}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <Typography variant="body1">{supplier.city}</Typography>
                <Typography variant="body1">{supplier.state}</Typography>
                <Typography variant="body1">{supplier.postcode}</Typography>
            </Box>
        </Box>
    )
}

export default SupplierMergeDialog
