import { useState, useEffect } from "react"
import _ from "lodash"
import { selectMenuStatus } from "../../redux/selectors"
import { useSelector } from "react-redux"

function getWindowDimensions(window, menuOpen) {
  const usableWidth = window.innerWidth - (menuOpen ? 240 : 0)
  return {
    // width depending on if left menu open
    width: usableWidth,
    height: window.innerHeight,
    // width of browser window regardless of left menu
    browserWidth: window.innerWidth,
  }
}

const useWindowDimensions = () => {
  const menuStatus = useSelector(selectMenuStatus)

  const [menuOpen, setMenuOpen] = useState(menuStatus)

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(window, menuOpen)
  )

  const updateWindowDimensions = (dimensions) => {
    if (!_.isEqual(dimensions, windowDimensions)) {
      // Subtract 240px from width if menuOpen is true
      dimensions.browserWidth = dimensions.width
      if (menuOpen) {
        // usable width if left menu is open
        dimensions.width = dimensions.width - 240
      }
      setWindowDimensions(dimensions, menuOpen)
    }
  }

  useEffect(() => {
    if (menuStatus !== menuOpen) {
      setMenuOpen(menuStatus)
    }
  }, [menuStatus])

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions(window)
      updateWindowDimensions(dimensions)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen])

  useEffect(() => {
    function handleOrientationChange() {
      const dimensions = getWindowDimensions(window, menuOpen)
      updateWindowDimensions(dimensions)
    }

    window.addEventListener("orientationchange", handleOrientationChange)
    return () =>
      window.removeEventListener("orientationchange", handleOrientationChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return windowDimensions
}

export { useWindowDimensions }
