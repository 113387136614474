import React, { useState, useEffect } from "react"
import db from "../Firestore"
import JobCard from "./JobCard"
import { Grid } from "@mui/material"
import { selectSearchBarValue } from "../redux/selectors"
import { useSelector } from "react-redux"
import * as dataServices from "../pages/services/dataServices"
import * as Roles from "../pages/services/roleServices"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const styles = {
  gridContainer: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}

const WorkOrderJobs = (props) => {
  const searchValue = useSelector(selectSearchBarValue)

  const [jobs, setJobs] = useState([])

  const [workOrderId] = useState(props.workOrderId)

  const [user, setUser] = useState()

  const [claims, setClaims] = useState()

  const [maxModified, setMaxModified] = useState()

  const COLLECTION_NAME = "jobs"

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      setUser(user)
      user.getIdTokenResult().then((token) => {
        setClaims(token.claims)
      })
    })

    return unsub
  }, [])

  // Listen for changes

  useEffect(() => {
    if (workOrderId === undefined) {
      return
    }

    if (claims === undefined) {
      return
    }

    let accountField

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())
      .where("work_order_id", "==", workOrderId)
      .where(accountField, "==", claims.account_id)

    switch (claims.account_type) {
      case "centre":
        query = query.where("account_id", "==", claims.account_id)
        break

      case "supplier":
        query = query.where(
          "supplier_access_account_ids",
          "array-contains",
          claims.account_id
        )
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    const unsub = query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          // trigger refresh
          setMaxModified(change.doc.data().modified)
        })
      },
      (error) => console.error("error listening for job changes", error)
    )

    return unsub
  }, [workOrderId, claims])

  // Get most recent modified record

  useEffect(() => {
    if (workOrderId === undefined) {
      return
    }

    let accountField

    let query = db
      .collection(COLLECTION_NAME)
      .where("work_order_id", "==", workOrderId)
      .where(accountField, "==", claims.account_id)
      .orderBy("modified", "desc")
      .limit(30)

    switch (claims.account_type) {
      case "centre":
        query = query.where("account_id", "==", claims.account_id)
        break

      case "supplier":
        query = query.where(
          "supplier_access_account_ids",
          "array-contains",
          claims.account_id
        )
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    const unsub = query.onSnapshot(
      (querySnapshot) => {
        let newMaxModified = null
        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            newMaxModified = change.doc.data().modified
          }
        })
        if (newMaxModified !== null) {
          setMaxModified(newMaxModified)
        }
      },
      (error) => console.error("error listening for job changes", error)
    )

    return unsub
  }, [claims, workOrderId])

  const handleUpdateJob = (job) => {
    //TODO: check if this is called.
    console.log("update job...", job)
  }

  // Load jobs

  useEffect(() => {
    if (maxModified === undefined || claims === undefined) {
      return
    }

    let performQuery = true

    let query = db.collection(COLLECTION_NAME)
    query = dataServices
      .modifyQuery(query, "label", searchValue)
      .where("work_order_id", "==", workOrderId)

    switch (claims.account_type) {
      case "centre":
        query = query.where("account_id", "==", claims.account_id)
        break

      case "supplier":
        query = query.where(
          "supplier_access_account_ids",
          "array-contains",
          claims.account_id
        )
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    const isJobMgr = claims.roles.includes(Roles.JOB_ADMIN)

    if (!isJobMgr) {
      if (claims.centre_ids.length > 0) {
        query = query.where("centre_id", "in", claims.centre_ids)
      } else {
        // No centre ids defined for non job manager
        performQuery = false
      }
    }

    if (performQuery) {
      dataServices.loadData("(Load job cards)", query).then((jobs) => {
        // Get first 10 unique (limit in firestore) centre ids
        const centreIds = Array.from(
          new Set(
            jobs
              .filter(
                (job) => job.centre_id !== undefined && job.centre_id !== ""
              )
              .map((job) => job.centre_id)
          )
        ).slice(0, 10)

        if (centreIds.length > 0) {
          dataServices.getCentresByIdChunks(centreIds).then((result) => {
            setJobs(jobs)
          })
        } else {
          setJobs(jobs)
        }
      })
    }
  }, [claims, maxModified, workOrderId])

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      flex="wrap"
      sx={styles.gridContainer}
    >
      {jobs.map((job) => (
        <JobCard key={job.id} job={job} setJob={handleUpdateJob} />
      ))}
    </Grid>
  )
}

export default WorkOrderJobs
