import React from "react"
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material"
import TruncatedText from "./TruncatedText"
import LabelValue from "./LabelValue"

const CentreTile = ({ centre, handleEditCentre }) => {
  return (
    <>
      <Card sx={{ width: "300px" }}>
        <CardHeader
          title={
            <TruncatedText
              variant="body1"
              noWrap
              sx={{
                fontWeight: "bold",
                marginBottom: "5px",
                maxWidth: "240px",
              }}
            >
              {centre.name}
            </TruncatedText>
          }
          subheader={<Typography variant="caption">{centre.email}</Typography>}
        />
        <CardActionArea onClick={handleEditCentre}>
          <CardContent sx={{ padding: "15px" }}>
            <Stack gap={0.5}>
              <LabelValue label="Phone" value={centre.phone} />
              {centre.addr1 && (
                <LabelValue label="Addr1" value={centre.addr1} />
              )}
              {centre.addr2 && (
                <LabelValue label="Addr2" value={centre.addr2} />
              )}
              <LabelValue label="City" value={centre.city} />
              <LabelValue label="State" value={centre.state} />
              <LabelValue label="Postcode" value={centre.postcode} />
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

export default CentreTile
