import React, { useEffect, useMemo, useState } from "react"
import {
  Box,
  Slider,
  Typography,
  IconButton,
  Switch,
  FormControlLabel,
  Stack,
  Dialog,
  Tooltip,
} from "@mui/material"
import * as fileServices from "../pages/services/fileServices"
import * as assetServices from "../pages/services/assetServices"
import * as icons from "../icons"
import { selectCarouselScale, selectCarouselVisible } from "../redux/selectors"
import { setCarouselScale, setCarouselVisible } from "../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import * as dateServices from "../pages/services/dateServices"
import CloseIcon from "@mui/icons-material/Close"

const ScrollingImageList = ({ imageLoadInfo, selectedFileName }) => {
  const [imgSrcs, setImgSrcs] = useState([])
  const { images, fileInfo, docId, docType, accountId } = imageLoadInfo

  const dispatch = useDispatch()

  const imageScale = useSelector(selectCarouselScale)

  const setImageScale = (percentScale) => {
    dispatch(setCarouselScale(percentScale))
  }

  const [fullscreenOpen, setFullscreenOpen] = useState(false)

  const [fullscreenIndex, setFullscreenIndex] = useState("")

  const formatAsDDMMMYY = (timestamp) => {
    return timestamp && dateServices.tsToDateTimeStr(timestamp)
  }

  const isCarouselVisible = useSelector(selectCarouselVisible)

  const toggleCarouselVisible = () => {
    dispatch(setCarouselVisible(!isCarouselVisible))
  }

  useEffect(() => {
    if (images && docId && accountId) {
      const fetchImageSrcs = async () => {
        try {
          const result = await Promise.all(
            images.map(async (fileName) => {
              switch (docType) {
                case "job":
                  const url = await fileServices.getJobFileUrl(
                    docId,
                    accountId,
                    fileName
                  )
                  return { url, fileName }

                case "asset":
                  const assetUrl = await assetServices.getAssetFileUrl(
                    docId,
                    accountId,
                    fileName
                  )
                  return { url: assetUrl, fileName }

                default:
                  return { url: "", fileName: "" }
              }
            })
          )
          setImgSrcs(result)
        } catch (error) {
          console.error("Failed to load image sources", error)
        }
      }

      fetchImageSrcs()
    }
  }, [images, docId, accountId])

  const handleSliderChange = (event, newValue) => {
    setImageScale(newValue)
  }

  const getImageHeaderOpacity = (fileName, selectedFileName) => {
    if (fileName === selectedFileName) {
      return 0.5
    } else {
      return 0.2
    }
  }

  const getImageIcon = (fileName) => {
    const info = fileInfo.find((item) => item.file_name === fileName)
    return info === undefined || accountId === info.account_id ? (
      <icons.CentreIcon />
    ) : (
      <icons.SupplierIcon />
    )
  }

  const getImageLabel = (fileName) => {
    const result = fileInfo.find((item) => item.file_name === fileName)
    return result ? `${result.email} ${formatAsDDMMMYY(result.created)}` : ""
  }

  return (
    <>
      <Box>
        <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
          <FormControlLabel
            control={
              <Switch
                checked={isCarouselVisible}
                onChange={toggleCarouselVisible}
              />
            }
            label={<Typography variant="body2">Show</Typography>}
            labelPlacement="start"
          />

          {isCarouselVisible && (
            <FormControlLabel
              control={
                <Slider
                  aria-label="Image Size"
                  value={imageScale}
                  onChange={handleSliderChange}
                  min={100} // 100%
                  max={500} // 500%
                  sx={{ width: "150px" }}
                />
              }
              label={
                <Typography variant="body2" sx={{ width: "50px" }}>
                  Size
                </Typography>
              }
              labelPlacement="start"
            />
          )}
        </Stack>
        {isCarouselVisible && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              p: 1,
              "&::-webkit-scrollbar": {
                height: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                borderRadius: "10px",
              },
            }}
          >
            {imgSrcs.map(({ url, fileName }, index) => (
              <Box
                key={index}
                sx={{
                  width: `${imageScale * 3}px`,
                  position: "relative",
                  flexShrink: 0,
                }}
              >
                {/* Overlay Content */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0, // Ensure the overlay covers the entire width.
                    display: "flex",
                    justifyContent: "space-between", // Align items to the left and right.
                    alignItems: "center", // Center items vertically.
                    padding: "4px",
                    backgroundColor: `rgba(0, 0, 0, ${getImageHeaderOpacity(
                      fileName,
                      selectedFileName
                    )})`,
                    color: "white",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="caption">{fileName}</Typography>
                    {/* Second row of text */}
                    <Typography variant="caption">
                      {getImageLabel(fileName)}
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{ color: "white" }}
                    size="small"
                    onClick={() => console.log("Icon clicked!")}
                  >
                    {getImageIcon(fileName, selectedFileName)}
                  </IconButton>
                </Box>
                <Box
                  onClick={() => {
                    setFullscreenIndex(index)
                    setFullscreenOpen(true)
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    padding: 0,
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={url}
                    alt={`Image ${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "block",
                      objectFit: "contain",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)", // Adding shadow to the image
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {imgSrcs && imgSrcs.length > 0 && fullscreenIndex !== undefined && (
        <FullscreenImage
          imageSrcs={imgSrcs}
          currentIndex={fullscreenIndex}
          setCurrentIndex={setFullscreenIndex}
          open={fullscreenOpen}
          handleClose={() => setFullscreenOpen(false)}
        />
      )}
    </>
  )
}

const FullscreenImage = ({
  imageSrcs,
  currentIndex,
  setCurrentIndex,
  alt = "image",
  handleClose,
  open,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "ArrowRight") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageSrcs.length)
    } else if (event.key === "ArrowLeft") {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + imageSrcs.length) % imageSrcs.length
      )
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [imageSrcs])

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <Tooltip title="Close" enterDelay={300}>
        <IconButton
          edge="start"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent background
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.9)", // Darker on hover
            },
            // Increase the size of the close icon
            height: 48,
            width: 48,
          }}
        >
          <CloseIcon sx={{ fontSize: "1.5rem" }} /> {/* Larger icon */}
        </IconButton>
      </Tooltip>

      {imageSrcs &&
        currentIndex !== undefined &&
        imageSrcs.length >= currentIndex + 1 && (
          <img
            src={imageSrcs[currentIndex]?.url}
            alt={alt}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={handleClose} // Allow closing by clicking the image as well
          />
        )}
    </Dialog>
  )
}
export default ScrollingImageList
