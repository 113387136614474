import { Box, Button, Typography } from "@mui/material"
import React, { useMemo } from "react"
import * as colors from "@mui/material/colors"
import { Link } from "react-router-dom"
import TruncatedText from "../TruncatedText"

const styles = {
  button: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    width: "fit-content",
    paddingLeft: "2px",
    paddingRight: "2px",
    textTransform: "none",
    color: colors.blue[500],
    textDecoration: "underline",
    textDecorationColor: colors.blue[500],
    "&:hover": {
      backgroundColor: colors.blue[100],
    },
  },
}

const LinkButton = ({ children, truncateWidth, sx = {}, ...props }) => {
  const buttonProps = useMemo(() => {
    const result = {}
    if (props.hasOwnProperty("onClick")) {
      result.onClick = (event) => {
        // stopPropagation was put in to fix if you selected jobs on the job grid, then opening a job via a link in the grid would deselect the job
        event.stopPropagation()
        props.onClick()
      }
    } else {
      result.onClick = (event) => {
        event.stopPropagation()
      }
    }
    if (props.hasOwnProperty("to")) {
      result.to = props.to
    } else {
      result.to = ""
    }

    return result
  }, [props])

  return (
    <>
      {(buttonProps.to || buttonProps.onClick) && (
        <Button
          {...buttonProps}
          sx={{ ...styles.button, ...sx }}
          component={buttonProps.to === "" ? Box : Link}
        >
          {truncateWidth && (
            <TruncatedText
              variant="body2"
              component={"span"}
              sx={{ maxWidth: truncateWidth }}
            >
              {children}
            </TruncatedText>
          )}
          {!truncateWidth && (
            <Typography variant="body2" component={"span"}>
              {children}
            </Typography>
          )}
        </Button>
      )}
    </>
  )
}

export default LinkButton
