import React from "react"
import Header from "../components/Header"
import JobGrid from "../components/JobGrid"

const Jobs = () => {
  return (
    <Header title="Jobs">
      <JobGrid />
    </Header>
  )
}

export default Jobs
