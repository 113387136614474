import { Chip, Stack, Tooltip } from "@mui/material"
import { chipColors, chipTooltips } from "../pages/services/checkListServices"

const CheckListElementAnswers = ({
  element,
  readonly = true,
  handleChipDelete,
  handleChipClick,
}) => {
  return (
    <Stack direction="row" gap={1}>
      {element.answers &&
        element.answers.map((answer) => {
          const editProps = readonly
            ? {}
            : {
                onDelete: () => handleChipDelete(answer.label),
                onClick: () => handleChipClick(answer),
              }

          return (
            <Tooltip
              key={answer.label}
              title={`${chipTooltips[answer.value]}${
                !readonly ? ". Click to change" : ""
              }`}
            >
              <Chip
                size="small"
                label={answer.label}
                style={{
                  backgroundColor: chipColors[answer.value],
                }}
                {...editProps}
              />
            </Tooltip>
          )
        })}
    </Stack>
  )
}

export default CheckListElementAnswers
