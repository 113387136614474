import React, { useMemo } from "react"
import { TextField } from "@mui/material"
import _ from "lodash"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { getName } from "../pages/services/checkListServices"
import * as UIConstants from "./controls/UIConstants"

const CheckListDateField = ({
  section,
  element,
  values,
  handleDateChange,
  isComplete,
}) => {
  const name = useMemo(() => {
    const name = getName(section, element)
    return name
  }, [section, element])

  const val = useMemo(() => {
    const newVal = values.check_list_data[name]
    return newVal
  }, [values, name])

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          id={getName(section, element)}
          inputFormat="DD/MM/YYYY"
          disabled={isComplete}
          value={val}
          onChange={(selectedDate) =>
            !isComplete && handleDateChange(name, selectedDate)
          }
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          renderInput={(props) => (
            <TextField
              label={element.label}
              variant={UIConstants.STANDARD_INPUT_VARIANT}
              size={UIConstants.STANDARD_INPUT_SIZE}
              {...props}
            />
          )}
        />
      </LocalizationProvider>
    </>
  )
}

export default CheckListDateField
