import React, { useState } from "react"
import Header from "../components/Header"
import CheckListEditForm from "../components/CheckListEditForm"
import { useId } from "./useId"

function CheckListEdit(props) {
  let { id } = useId(props)

  const [title, setTitle] = useState("Edit CheckList Template")

  return (
    <Header title={title}>
      <CheckListEditForm id={id} setTitle={setTitle} />
    </Header>
  )
}

export default CheckListEdit
