import * as actions from "../actionTypes"

const initialState = {
  value: [],
}

export default function priorities(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PRIORITIES: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
