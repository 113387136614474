import { MenuItem, Select } from "@mui/material"
import * as UIConstants from "./controls/UIConstants"

const DashboardWidgetGroupBy = ({ groupBy, setGroupBy }) => {
  return (
    <Select
      labelId="group-by-label"
      id="groupby"
      value={groupBy}
      variant={UIConstants.STANDARD_INPUT_VARIANT}
      size={UIConstants.STANDARD_INPUT_SIZE}
      label="Group By"
      onChange={(event) => setGroupBy(event.target.value)}
    >
      <MenuItem value={""}>None</MenuItem>
      <MenuItem value={"centre_id"}>Centre</MenuItem>
      <MenuItem value={"status"}>Job Status</MenuItem>
      <MenuItem value={"category"}>Category</MenuItem>
      <MenuItem value={"location"}>Location</MenuItem>
      <MenuItem value={"priority"}>Priority</MenuItem>
      <MenuItem value={"supplier_id"}>Supplier</MenuItem>
    </Select>
  )
}

export default DashboardWidgetGroupBy
