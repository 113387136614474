import React, { useEffect, useState } from "react"
import ComboBox from "./ComboBox"
import * as dataServices from "../../pages/services/dataServices"
import _ from "lodash"
import { selectCentres } from "../../redux/selectors"
import { useSelector } from "react-redux"

const CentreCombobox = ({
  name,
  label,
  value,
  onChange,
  helperText,
  readonly,
  accountId,
  centreIdsFilter = [],
  // Flag to select next centre in combobox
  nextSelector,
  // Flag to select previous centre in combobox
  prevSelector,
  showError = false,
  errorFuncs = [],
}) => {
  const NONE_LABEL = "Select Centre"

  // centreIdsFilter is the array of centre ids that can be shown in the combobox.

  const [reloadState, setReloadState] = useState({
    readonly: false,
    value: "",
    accountId: "",
    centreIdsFilter: [],
  })

  const centres = useSelector(selectCentres)

  const [centreOptions, setCentreOptions] = useState([
    {
      id: "",
      label: NONE_LABEL,
    },
  ])

  useEffect(() => {
    setCentreOptions([
      {
        id: "",
        label: NONE_LABEL,
      },
      ...centres
        .sort((a, b) =>
          (a.short_name || a.name).localeCompare(b.short_name || b.name)
        )
        .map((centre) => createCentreOption(centre)),
    ])
  }, [centres])

  const createCentreOption = (centre) => {
    return {
      id: centre.id,
      label: centre.short_name || centre.name,
    }
  }

  const isValueChangedOnly = (newReloadState) => {
    const isValueChanged =
      newReloadState.readonly === reloadState.readonly &&
      newReloadState.value !== reloadState.value &&
      newReloadState.accountId === reloadState.accountId &&
      newReloadState.centreIdsFilter.length ===
        reloadState.centreIdsFilter.length &&
      JSON.stringify(newReloadState.centreIdsFilter.sort) ===
        JSON.stringify(reloadState.centreIdsFilter.sort)

    return isValueChanged
  }

  /**
   * Change which supplier is selected in the supplier combo box
   *
   * @param {*} delta | 1 to select next supplier in the supplier combo, -1 to select previous supplier
   */
  const selectNextOption = (delta) => {
    const index = centreOptions.findIndex((option) => option.id === value)
    const nextIndex =
      value === "" ? (delta === 1 ? 1 : index - 1) : index + delta
    const nextOption = centreOptions[nextIndex]
    if (nextOption) {
      const nextValue = nextOption ? nextOption.id : ""
      onChange({ target: { value: nextValue } })
    }
  }

  // This allows us to inject a change into the supplier combo box to advance the selection to the next item
  useEffect(() => {
    if (nextSelector) {
      selectNextOption(1)
    }
  }, [nextSelector])

  // This allows us to inject a change into the supplier combo box to advance the selection to the previous item
  useEffect(() => {
    if (prevSelector) {
      selectNextOption(-1)
    }
  }, [prevSelector])

  useEffect(() => {
    if (accountId === undefined || centres === undefined) {
      return
    }

    const newReloadState = { readonly, value, accountId, centreIdsFilter }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    setReloadState(newReloadState)

    if (readonly) {
      if (value !== "") {
        const centreIds = [value]

        // Load the centre because if we're only loading 1 centre
        // it can be because the supplier is viewing a job or similar
        // and so that means the centre details can't be pulled from redux
        // view the 'centres' selector
        dataServices
          .getCentresByAccountId(accountId, true, centreIds)
          .then((centres) => {
            const options = centres
              .sort((a, b) =>
                (a.short_name || a.name).localeCompare(b.short_name || b.name)
              )
              .map((centre) => createCentreOption(centre))
            setCentreOptions(options)
          })
      }
    } else {
      if (!isValueChangedOnly(newReloadState)) {
        setCentreOptions([
          { id: "", label: NONE_LABEL },
          ...centres
            .sort((a, b) =>
              (a.short_name || a.name).localeCompare(b.short_name || b.name)
            )
            .map((centre) => createCentreOption(centre)),
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readonly, value, accountId, centres, centreIdsFilter])

  return (
    <ComboBox
      name={name}
      label={label}
      noneLabel={NONE_LABEL}
      value={value}
      items={centreOptions}
      onChange={onChange}
      helperText={helperText}
      disabled={readonly}
      showError={showError}
      errorFuncs={errorFuncs}
    />
  )
}

export default CentreCombobox
