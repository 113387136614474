import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutForm from "../components/CheckoutForm"
import Header from "../components/Header"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
    "pk_test_51Il4rfBRExiK4XQlwBtc2e4j4Zxdna7y4bQbjbsCHKF1CHOcl7DiVueYv5KSZgUd4pSkL9YMuRWHUHmEgUhON5rN00n6KhY5fN"
)

const Checkout = () => {
    return (
        <Header title="Checkout">
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </Header>
    )
}

export default Checkout
