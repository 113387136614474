import React, { useEffect, useState } from "react"
import { Box, Grow, IconButton, Typography, Alert, Slider } from "@mui/material"
import * as fileServices from "../pages/services/fileServices"
import { Carousel } from "react-responsive-carousel"
// DO NOT DELETE THIS .css import otherwise <Carousel> will break
import styles from "react-responsive-carousel/lib/styles/carousel.min.css"
import CloseIcon from "@mui/icons-material/Close"
import { selectCarouselScale } from "../redux/selectors"
import { setCarouselScale } from "../redux/actions"
import { useDispatch, useSelector } from "react-redux"

const styleInfo = {
  carouselHeader: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
}

const ViewJobPhotos = ({
  jobToShowPhotos,
  setJobToShowPhotos,
  selectedIndex = 0,
  setSelectedIndex,
}) => {
  const [fileInfo, setFileInfo] = useState([])

  const dispatch = useDispatch()

  const carouselScale = useSelector(selectCarouselScale)

  useEffect(() => {
    if (jobToShowPhotos) {
      const newFileInfo = jobToShowPhotos.docs
        .filter((fileName) => fileName !== "")
        .map(async (fileName) => {
          return {
            fileName: fileName,
            url: await fileServices.getJobFileUrl(
              jobToShowPhotos.id,
              jobToShowPhotos.account_id,
              fileName
            ),
          }
        })

      Promise.all(newFileInfo)
        .then((result) => {
          setFileInfo(result)
        })
        .catch((err) => {
          console.log("%cerror", "color:red", err)
        })

      if (jobToShowPhotos.docs.length === 0) {
        closeCarousel()
      }
    }
  }, [jobToShowPhotos])

  useEffect(() => {
    setSelectedIndex && setSelectedIndex(selectedIndex)

    if (selectedIndex > fileInfo.length - 1 && fileInfo.length > 0) {
      setSelectedIndex(fileInfo.length - 1)
    }
  }, [selectedIndex])

  const closeCarousel = () => {
    setJobToShowPhotos(null)
    setSelectedIndex && setSelectedIndex(null)
  }

  const handleChange = (selectedIndex) => {
    setSelectedIndex && setSelectedIndex(selectedIndex)
  }

  const handleCarouselScaleChange = (event, value) => {
    dispatch(setCarouselScale(value))
  }

  return (
    <Box sx={{ maxWidth: (1500 * carouselScale) / 100 }}>
      {jobToShowPhotos && fileInfo.find((item) => item.url) && (
        <Grow in={jobToShowPhotos !== undefined}>
          <Box>
            <Box sx={styleInfo.carouselHeader}>
              <IconButton
                onClick={() => {
                  closeCarousel()
                }}
              >
                <CloseIcon />
              </IconButton>
              <Box sx={{ width: 200, ml: "20px" }}>
                <Slider
                  size="small"
                  value={carouselScale}
                  onChange={handleCarouselScaleChange}
                />
              </Box>
            </Box>

            <Carousel
              showArrows={true}
              centerMode={true}
              emulateTouch={true}
              selectedItem={selectedIndex}
              onChange={handleChange}
              dynamicHeight={false}
              height="200px"
              useKeyboardArrows={true}
            >
              {fileInfo
                .filter((item) => item.url)
                .map((item) => (
                  <div key={item.fileName}>
                    {item.url && <img alt="" src={item.url} />}
                    <div style={{ color: "#fff" }}>{item.fileName}</div>
                  </div>
                ))}
            </Carousel>
          </Box>
        </Grow>
      )}
      {fileInfo && fileInfo.find((item) => !item.url) && (
        <Box>
          <Box sx={{ marginLeft: "10px" }}>
            <Alert severity="warning">
              The following files are not found. You will have to delete the
              file name and re-upload the photo(s):
            </Alert>
          </Box>
          <Box sx={{ ml: "15px", mt: "10px" }}>
            {fileInfo &&
              fileInfo
                .filter((item) => !item.url)
                .map((item) => (
                  <Box key={item.fileName}>
                    <Typography variant="caption">{item.fileName}</Typography>
                  </Box>
                ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ViewJobPhotos
