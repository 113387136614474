import React from "react"
import CameraSnap from "./CameraSnap"
import Header from "../components/Header"
import { Paper } from "@mui/material"
import { spacing } from "../pages/services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(5),
        padding: spacing(3),
    },
}

function Camera() {
    return (
        <Header title="Take Photo">
            <Paper sx={styles.pageContent}>
                <CameraSnap />
            </Paper>
        </Header>
    )
}

export default Camera
