import React, { useEffect, useMemo, useState } from "react"
import * as dataServices from "../../pages/services/dataServices"
import { Autocomplete, Box, Chip, TextField, Typography } from "@mui/material"
import LinkButton from "./LinkButton"
import CoverageChip from "./CoverageChip"
import * as colors from "@mui/material/colors"
import * as UIConstants from "../controls/UIConstants"

const SupplierCombobox = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    helperText,
    readonly,
    accountId,
    isSaved = false,
    // Optional function to filter the options
    filterOptions,
    showChips = false,
    // Can override this to show a custom message if there is no value currently selected
    messageIfEmpty = undefined,
    sx = {},
  } = props

  const [supplierOptions, setSupplierOptions] = useState([])

  const filteredOptions = useMemo(() => {
    if (filterOptions) {
      return supplierOptions.filter(filterOptions)
    }

    return supplierOptions
  }, [supplierOptions, filterOptions])

  function createSupplierOption(supplier) {
    return {
      id: supplier.id,
      label: supplier.name,
      job_types: supplier.job_types || [],
      coverage: supplier.coverage || [],
    }
  }

  useEffect(() => {
    if (accountId) {
      if (readonly) {
        if (value !== "" && value !== undefined) {
          const supplierIds = [value]

          dataServices
            .getSuppliersById(supplierIds, accountId)
            .then((suppliers) => {
              const options = suppliers.map((supplier) =>
                createSupplierOption(supplier)
              )
              setSupplierOptions(options)
            })
        }
      } else {
        dataServices
          .getSuppliersByAccountId(accountId, true)
          .then((suppliers) => {
            const options = suppliers.map((supplier) =>
              createSupplierOption(supplier)
            )
            setSupplierOptions([
              {
                id: "",
                label: "Select Supplier",
              },
              ...options,
            ])
          })
      }
    }
  }, [readonly, value, accountId])

  const [inputValue, setInputValue] = useState("")

  const updateVal = (val) => {
    const selectedValue = val ? val.id : ""
    const payload = { target: { name: name, value: selectedValue } }
    onChange(payload)
  }

  // The value for an autocomplete must never resolve to undefined -- react rules.
  const getValue = (value) => {
    const result =
      filteredOptions && filteredOptions.find((o) => o.id === value)
    return result || ""
  }

  const fieldLabel = useMemo(() => {
    const newLabel =
      filteredOptions.length === 0 && messageIfEmpty
        ? messageIfEmpty
        : readonly
        ? `${label}`
        : `${label} (${filteredOptions.filter((item) => item.id).length})`

    return newLabel
  }, [filteredOptions, messageIfEmpty, readonly, label])

  return (
    <Box>
      <Autocomplete
        getOptionLabel={(option) => {
          const label = option?.label || ""

          return label
        }}
        isOptionEqualToValue={(option, value) => {
          const match = option.id === value.id

          return match
        }}
        autoHighlight
        autoComplete
        size={UIConstants.STANDARD_INPUT_SIZE}
        value={getValue(value)}
        id={name}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        onChange={(_, newValue) => updateVal(newValue)}
        options={filteredOptions}
        disabled={readonly}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <div>
                <Box>
                  <Box>{option.label}</Box>
                  {showChips && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "2px",
                      }}
                    >
                      {option.job_types?.length > 0 &&
                        option.job_types.map((jobType) => (
                          <Chip
                            key={jobType}
                            label={
                              <Typography variant="caption">
                                {jobType}
                              </Typography>
                            }
                            size="small"
                            sx={{
                              backgroundColor: colors.lightGreen[100],
                              margin: "1px",
                            }}
                          />
                        ))}
                      {option.coverage?.length > 0 &&
                        option.coverage.map((c) => (
                          <CoverageChip
                            key={`${c.state}-${c.area}`}
                            coverage={c}
                            small={true}
                          />
                        ))}
                    </Box>
                  )}
                </Box>
              </div>
            </li>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={fieldLabel}
              variant={UIConstants.STANDARD_INPUT_VARIANT}
              helperText={helperText}
              sx={sx}
            />
          )
        }}
      />

      {value && isSaved && (
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <LinkButton to={`/SupplierEdit/${value}`}>
            <Typography variant="caption">Open Supplier</Typography>
          </LinkButton>
        </Box>
      )}
    </Box>
  )
}

export default SupplierCombobox
