import * as actions from "../actionTypes"

const initialState = {}

export default function jobs(state = initialState, action) {
  switch (action.type) {
    case actions.SET_JOBS: {
      // This cache value is not keyed on anything since it is an object containing all jobs and centres
      // required to render the jobs summary view, i.e. we don't precede action.payload.value with a key
      // like most of the other redux reducers
      const newState = {
        ...state,
        ...action.payload.value,
      }

      return newState
    }

    default:
      return state
  }
}
