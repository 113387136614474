import dateFnsParse from "date-fns/parse"
import * as moment from "moment"

const getClassName = (obj) => {
  if (obj === null) {
    return "null"
  }
  return obj.constructor.name
}

const parseTimestamp = (ts) => {
  if (ts !== undefined && ts !== null) {
    return ts.toDate()
  }
  return null
}

const tsToDate = (ts) => {
  var t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(ts.seconds)
  return t
}

const dateStrToTs = (dateStr) => {
  return moment(dateStr, "DD/MMM/yyyy").toDate()
}

const tsToDateStr = (ts) => {
  var t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(ts.seconds)
  return moment(t).format("DD MMM yyyy")
}

const tsToDateTimeStr = (ts) => {
  var t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(ts.seconds)
  return moment(t).format("DD MMM yyyy hh:mm a")
}

const secsToDateTimeStr = (secs) => {
  var t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(secs)
  return moment(t).format("DD MMM yy HH:mm a")
}

const secsToDateStr = (secs) => {
  var t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(secs)
  return moment(t).format("D-MMM-yyyy")
}

const isFutureDate = (theDate) => {
  return theDate.getTime() > Date.now()
}

const isDateWithin3Months = (theDate) => {
  return (
    moment(Date.now()).add(3, "months").toDate() > theDate.getTime() &&
    isFutureDate(theDate)
  )
}

const dateFormats = ["dd/MM/yyyy", "dd/MMM/yyyy"]

function parseDateString(dateString) {
  // List of potential date formats that the dateString might be in.
  var formats = [
    "DD/MMM/yyyy",
    "DD/MM/yyyy",
    // Add more formats as needed
  ]

  var parsedDate

  for (var i = 0; i < formats.length; i++) {
    parsedDate = moment(dateString, formats[i], true)
    if (parsedDate.isValid()) {
      return parsedDate.toDate() // Returns JavaScript Date object
    }
  }

  // If none of the formats match, return null
  return null
}

const parseDate = (dateVal) => {
  let parsedDate

  try {
    const className = getClassName(dateVal)
    const keys = Object.keys(dateVal)
    if (keys.includes("seconds")) {
      parsedDate = dateVal.toDate()
    } else {
      switch (className) {
        case "String":
          parsedDate = dateFnsParse(dateVal, "dd/MM/yyyy", new Date())
          break

        case "Date":
          parsedDate = dateVal
          break

        case "null":
          parsedDate = null
          break

        case "ut":
          parsedDate = dateVal.toDate()
          break

        case "t": // firebase timestamp
          parsedDate = dateVal.toDate()
          break

        case "ct": // firebase timestamp
          parsedDate = tsToDate(dateVal)
          break

        default:
          console.error("Unexpected expiry date class", className, dateVal)
      }
    }
  } catch (err) {
    if (dateVal !== null) {
      console.error("Error parsing created date", { dateVal })
    }
  }
  return parsedDate
}

export {
  parseDate,
  parseTimestamp,
  parseDateString,
  secsToDateStr,
  secsToDateTimeStr,
  tsToDateStr,
  dateStrToTs,
  tsToDate,
  tsToDateTimeStr,
  getClassName,
  isDateWithin3Months,
  isFutureDate,
}
