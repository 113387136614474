import React from "react"
import Header from "../components/Header"
import CheckListTemplateGrid from "../components/CheckListTemplateGrid"

const CheckLists = () => {
  return (
    <Header title="Checklist Templates">
      <CheckListTemplateGrid />
    </Header>
  )
}

export default CheckLists
