import * as dataServices from "./dataServices"
import db from "../../Firestore"
import * as workOrderStatus from "../../components/workOrderStatus"
import * as supplierServices from "./supplierServices"
import * as jobServices from "./jobServices"
import firebase from "firebase/compat/app"
import { where } from "firebase/firestore"
import * as Roles from "./roleServices"
import { getAuth } from "firebase/auth"

const ALLOCATE_EXISTING = "existing"
const ALLOCATE_NEW = "new"
const ALLOCATE_NEW_FOR_QUOTES = "new_for_quotes"
const ALLOCATE_INTERNAL = "internal"

const allocationOptions = [
  { id: ALLOCATE_EXISTING, title: "Existing open work order" },
  { id: ALLOCATE_NEW, title: "New work order with supplier" },
  { id: ALLOCATE_INTERNAL, title: "New work order for internal use" },
  {
    id: ALLOCATE_NEW_FOR_QUOTES,
    title: "New work order for requesting quotes",
  },
]

// Format is
const calculateWorkOrderLabel = async (jobs) => {
  const centreIds = Array.from(
    new Set(jobs.map((job) => job.centre_id))
  ).filter((id) => id !== null)

  return await dataServices.getCentresByIdChunks(centreIds).then((centres) => {
    const label = centres.map((centre) => centre.name).join("/")
    return label
  })
}
// This function needs to remain in sync with WorkOrderEditForm.handleStatusChange
const changeWorkOrderStatus = async ({ workOrderId, status }) => {
  // If we're changing the work order to close, first check if all its jobs are closed

  if (status === workOrderStatus.STATUS_CLOSED) {
    const querySnapshot = await db
      .collection("jobs")
      .where("work_order_id", "==", workOrderId)
      .get()

    const jobs = querySnapshot.docs.map((doc) => doc.data())
    const allJobsClosed = jobs.every(
      (job) => job.status === workOrderStatus.STATUS_CLOSED
    )
    if (!allJobsClosed) {
      console.log("not all jobs are closed")
      return {
        msg: "Close all jobs in this work order first",
        status: "error",
      }
    }
  }

  // Get claims
  const currentUser = getAuth().currentUser
  const claims = await currentUser.getIdTokenResult()

  // Only call this if user is a centre user
  // and we're changing the work order status to allocated
  // we make sure all jobs are open, so the supplier can see them
  if (
    claims.account_type === Roles.ACCOUNT_TYPE_CENTRE &&
    status === workOrderStatus.STATUS_ALLOCATED
  ) {
    await jobServices.openAnyPendingJobs({
      accountId: claims.account_id,
      workOrderId,
    })
  }

  const workOrderRef = db.collection("work_orders").doc(workOrderId)
  const workOrder = (await workOrderRef.get()).data()

  const isCopyForwardRequired =
    isRecurringWorkOrder(workOrder) && status === workOrderStatus.STATUS_CLOSED

  let newWorkOrderId

  if (isCopyForwardRequired) {
    newWorkOrderId = await dataServices.copyForwardClosedWorkOrder(
      workOrderId,
      workOrder.account_id
    )

    await dataServices.updateWorkOrderCalendarInfo(
      newWorkOrderId,
      workOrder.account_id,
      {},
      workOrder.start_date
    )

    await dataServices.deleteWorkOrderCalendarInfo(
      workOrderId,
      workOrder.account_id
    )
  }

  await appendWorkOrderHistory({
    workOrderId,
    status,
    userEmail: getAuth().currentUser.email,
    comment: `Status changed to ${status}`,
  })

  return { msg: "", status: "success" }
}

const appendWorkOrderHistory = async ({
  workOrderId,
  status,
  userEmail,
  comment,
}) => {
  await db
    .collection("work_orders")
    .doc(workOrderId)
    .update({
      status: status,
      schedule: {},
      history: firebase.firestore.FieldValue.arrayUnion({
        status: status,
        date: dataServices.localTimestamp(),
        user: userEmail,
        comment: comment,
      }),
      modified: dataServices.serverTimestamp(),
    })
}

const getWorkOrderQueryParams = ({ direction, pagination, currentUser }) => {
  return {
    accountType: currentUser.claims.account_type,
    accountId: currentUser.claims.account_id,
    supplierId: pagination.supplier_id,
    maintUserId: pagination.show_my_work_orders
      ? currentUser.claims.sub
      : undefined,
    isJobAdmin: currentUser.claims.roles.includes(Roles.JOB_ADMIN),
    isCentreUserLoggedIn: currentUser.claims.account_type === "centre",
    statusFilter: pagination.statuses,
    workOrderNo: pagination.work_order_no,
    invoiceNo: pagination.invoice_no,
    centreId: pagination.centre_id,
    centreIds: currentUser.claims.centre_ids,
  }
}

const createWorkOrderQuery = ({
  accountType,
  accountId,
  // Either 1 of supplierId or maintUserId can be set but not both
  supplierId,
  maintUserId,
  isJobAdmin,
  isCentreUserLoggedIn,
  statusFilter = [],
  workOrderNo,
  invoiceNo,
  centreId,
  // centre ids from user's claims
  centreIds,
}) => {
  const queryMods = []
  const queryConstraints = []

  const isSingleDocSearch = workOrderNo !== "" || invoiceNo !== ""

  const supplierStatusValues = statusFilter !== undefined ? statusFilter : []

  // Remove any supplierStatusValue that isn't in workOrderStatus.supplierStatues, as a safety check
  const statusValues = isCentreUserLoggedIn
    ? statusFilter
    : supplierStatusValues.filter((status) =>
        workOrderStatus.supplierStatuses.includes(status)
      )

  switch (accountType) {
    case "centre":
      queryConstraints.push(where("account_id", "==", accountId))
      queryMods.push("account_id == " + accountId)
      break

    case "supplier":
      queryConstraints.push(
        where("supplier_access_account_ids", "array-contains", accountId)
      )
      queryMods.push(`supplier_access_account_ids contains ${accountId}`)
      break

    default:
      throw new Error("Unknown account type " + accountType)
  }

  if (supplierId && !maintUserId) {
    queryConstraints.push(where("supplier_id", "==", supplierId))
    queryMods.push(`supplier_id == ${supplierId}`)
  }

  if (maintUserId) {
    queryConstraints.push(where("maint_user_id", "==", maintUserId))
    queryMods.push(`maint_user_id == ${maintUserId}`)
  }

  if (isCentreUserLoggedIn) {
    if (statusValues && statusValues.length > 0) {
      if (!isSingleDocSearch) {
        // Only apply status filter if we're not directly selecting a
        // specific work order no or invoice no.
        // i.e. filtering by work order no or invoice no should retrieve a single record regardless of status
        if (statusValues.length === 1) {
          queryConstraints.push(where("status", "==", statusValues[0]))
          queryMods.push(`status == '${statusValues[0]}'`)
        } else {
          queryConstraints.push(where("status", "in", statusValues))
          queryMods.push(
            `status in [${statusValues.map((val) => `'${val}'`).join(",")}]`
          )
        }
      }
    }
  } else {
    // Supplier
    if (statusValues.length === 0) {
      queryConstraints.push(
        where("status", "in", workOrderStatus.supplierStatuses)
      )
      queryMods.push("status in " + workOrderStatus.supplierStatuses)
    } else {
      queryConstraints.push(where("status", "in", statusValues))
      queryMods.push(
        `status in [${statusValues.map((val) => `'${val}'`).join(",")}]`
      )
    }
  }

  if (workOrderNo !== "" && workOrderNo !== undefined) {
    queryConstraints.push(where("work_order_no", "==", parseInt(workOrderNo)))
    queryMods.push(`work_order_no == ${workOrderNo}`)
  }

  if (invoiceNo !== "" && invoiceNo !== undefined) {
    queryConstraints.push(where("invoice_no", "==", invoiceNo))
    queryMods.push(`invoice_no == ${invoiceNo}`)
  }

  // See if centre filter is set
  if (centreId !== "") {
    if (isJobAdmin || centreIds.includes(centreId)) {
      queryConstraints.push(where("centres", "array-contains", centreId))
      queryMods.push(`centres contains ${centreId}`)
    }
  } else if (centreIds > 0) {
    // If single centre selected, filter by that center
    if (!isJobAdmin) {
      queryConstraints.push(where("centres", "array-contains-any", centreIds))
      queryMods.push(`filter by centres ${centreIds}`)
    }
  }

  return { queryConstraints, queryMods }
}

/**
 * One of userId or supplierId should be specified, but not both.
 * The jobs can be allocated to a work order which is allocated either to a supplier, or a member of staff (userId).
 * @param {*} param0
 * @returns
 */
const allocateJobs = async ({
  jobIds,
  supplierId,
  userId,
  allocateOption,
  workOrderId,
  accountId,
  accountType,
  // If true, set the work order to Open and the jobs to Allocated (if they are Open)
  // so that the supplier can see the work order and jobs.
  makeVisibleToSupplier,
}) => {
  // Validate at least one of supplierId or userId provided but not both

  if (supplierId === "" && userId === "") {
    throw new Error("Supplier or user must be specified")
  }

  if (supplierId !== "" && userId !== "") {
    throw new Error("Supplier and user cannot both be specified")
  }

  let woId = workOrderId

  const jobs = await dataServices.getJobsById({
    accountId,
    accountType,
    jobIds,
  })

  const nextWorkOrderNo = await dataServices.getNextWorkOrderNo(accountId)

  const label = await calculateWorkOrderLabel(jobs)

  if (
    allocateOption === ALLOCATE_NEW ||
    allocateOption === ALLOCATE_NEW_FOR_QUOTES ||
    allocateOption === ALLOCATE_INTERNAL
  ) {
    const status =
      allocateOption === ALLOCATE_NEW || allocateOption === ALLOCATE_INTERNAL
        ? makeVisibleToSupplier
          ? workOrderStatus.STATUS_ALLOCATED
          : workOrderStatus.STATUS_OPEN
        : workOrderStatus.STATUS_QUOTE_REQUESTED

    const centreIds = Array.from(new Set(jobs.map((job) => job.centre_id)))

    const woValues = {
      account_id: accountId,
      work_order_no: nextWorkOrderNo,
      label: label,
      created: dataServices.serverTimestamp(),
      modified: dataServices.serverTimestamp(),
      start_date: dataServices.serverTimestamp(),
      status: status,
      notes: "",
      quote_details: "",
      // If we are requesting quotes from suppliers then the work order supplier
      // value will be blank until we select a quote and allocate a supplier or maint_user_id, i.e. internal staff member to process the work order
      supplier_id: supplierId || "",
      maint_user_id: userId || "",
      centres: centreIds,
      cost_estimate: [],
      cost_actuals: [],
      parts: [],
      history: [
        dataServices.createWorkOrderHistory({
          comment: "Created",
          dateTimestamp: dataServices.localTimestamp(),
          status: status,
          userEmail: getAuth().currentUser.email,
        }),
      ],
    }

    await db
      .collection("work_orders")
      .add(woValues)
      .then((docRef) => {
        woId = docRef.id
      })
  }

  const batch = db.batch()

  jobIds.forEach(async (jobId) => {
    const mergeValues = {
      work_order_id: woId,
      modified: dataServices.serverTimestamp(),
    }

    // Assign who is responsible for the job, either supplier or staff (maint_user_id)
    if (supplierId) {
      mergeValues.supplier_id = supplierId
    } else if (userId) {
      mergeValues.maint_user_id = userId
    }

    if (makeVisibleToSupplier) {
      mergeValues.status = jobServices.JOB_STATUS_OPEN
    }

    const jobRef = db.collection("jobs").doc(jobId)

    batch.update(jobRef, mergeValues)
  })

  batch.commit()

  console.log("updateSupplierAccess")

  await supplierServices.updateSupplierAccess({
    workOrderId: woId,
    source: "Allocate jobs",
  })

  console.groupEnd()

  return woId
}

const getOpenWorkOrderUrl = ({ accountType, workOrderId }) => {
  if (!workOrderId) {
    return ""
  }

  // return accountType === "centre"
  //     ? `/WorkOrderEdit/${workOrderId}`
  //     : `/PrintWorkOrder/${workOrderId}`

  return `/WorkOrderEdit/${workOrderId}`
}

const getOpenJobUrl = ({ accountType, jobId, workOrderId }) => {
  // return accountType === "centre"
  //     ? `/JobEdit/${jobId}`
  //     : getOpenWorkOrderUrl({ accountType, workOrderId })
  return `/JobEdit/${jobId}`
}

const isRecurringWorkOrder = (workOrder) => {
  return (
    workOrder.schedule &&
    workOrder.schedule.hasOwnProperty("type") &&
    workOrder.schedule?.type !== ""
  )
}

export {
  calculateWorkOrderLabel,
  appendWorkOrderHistory,
  createWorkOrderQuery,
  getWorkOrderQueryParams,
  changeWorkOrderStatus,
  isRecurringWorkOrder,
  getOpenWorkOrderUrl,
  getOpenJobUrl,
  allocateJobs,
  allocationOptions,
  ALLOCATE_EXISTING,
  ALLOCATE_NEW,
  ALLOCATE_NEW_FOR_QUOTES,
  ALLOCATE_INTERNAL,
}
