import { Box, Button, Switch, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import SignaturePad from "react-signature-canvas"
import { getName } from "../pages/services/checkListServices"

const CheckListSignatureField = ({
    section,
    element,
    values,
    handleSignatureChange,
    isComplete,
}) => {
    const name = getName(section, element)

    const [editSignature, setEditSignature] = useState(true)

    const [key, setKey] = useState(0)

    const padRef = useRef(null)

    const penColor = "rgb(66, 133, 244)"

    useEffect(() => {
        const signatureData = values.check_list_data[name]
        padRef.current.fromData(signatureData)
    }, [])

    useEffect(() => {
        if (editSignature) {
            padRef.current.on()
        } else {
            padRef.current.off()
        }
    }, [editSignature])

    useEffect(() => {
        if (values.check_list_data[name].length > 0) {
            setEditSignature(false)
        }
    }, [])

    const toggleEditSignature = () => {
        setEditSignature(!editSignature)
    }

    const handleSignaturePadEndDraw = () => {
        if (editSignature) {
            const optimizedSignature = padRef.current.toData().map((line, index) => {
                const optimizedLine = line.map((point) => {
                    return { x: parseInt(point.x.toFixed(0)), y: parseInt(point.y.toFixed(0)) }
                })

                return optimizedLine
            })

            handleSignatureChange(name, optimizedSignature)
        }
    }

    return (
        <>
            <div onTouchEnd={handleSignaturePadEndDraw} onMouseUp={handleSignaturePadEndDraw}>
                <Box
                    sx={{
                        width: 500,
                        height: 200,
                        border: "1px dotted #999",
                        marginTop: "15px",
                        marginBottom: "15px",
                    }}
                >
                    <SignaturePad
                        key={key}
                        canvasProps={{ width: 500, height: 200 }}
                        ref={padRef}
                        redrawOnResize={true}
                        options={{ minWidth: 2, maxWidth: 3, penColor: penColor }}
                    />
                </Box>
            </div>
            {!isComplete && (
                <>
                    <Button
                        onClick={() => {
                            padRef.current?.clear()
                            handleSignatureChange(name, [])
                            padRef.current.fromData([])
                            setKey((curr) => curr + 1)
                        }}
                        style={{ textTransform: "none" }}
                        variant="outlined"
                        size="small"
                    >
                        Clear signature
                    </Button>

                    <Switch checked={editSignature} onChange={toggleEditSignature} />
                    <Typography variant="caption">Edit Signature</Typography>
                </>
            )}
        </>
    )
}

export default CheckListSignatureField
