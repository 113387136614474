import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import * as dataServices from "../pages/services/dataServices"
import * as jobServices from "../pages/services/jobServices"
import { renderEmailComponent } from "../pages/services/emailServices"
import * as fileServices from "../pages/services/fileServices"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import { useSnackbar } from "notistack"
import _ from "lodash"
import * as colors from "@mui/material/colors"
import * as icons from "../icons"
import { spacing } from "../pages/services/styleServices"
import NavButtons from "./NavButtons"
import WorkOrderEmail from "../emails/WorkOrderEmail"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const styles = {
  dialogContent: {
    minWidth: 280,
  },
  jobImages: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: spacing(1),
    },
  },
  jobImagesTitle: {
    backgroundColor: colors.grey[800],
    color: "#fff",
    padding: spacing(0.5),
    marginBottom: spacing(1),
  },
}

const WorkOrderEmailDialog = ({ open, setOpen, workOrder, jobs, centres }) => {
  const [user, setUser] = useState()

  const [jobFileUrls, setJobFileUrls] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const [isSending, setSending] = useState(false)

  const [isPreview, setIsPreview] = useState(false)

  const [htmlContent, setHtmlContent] = useState("")

  const [loadedFiles, setLoadedFiles] = useState([])

  const { values, setValues, handleInputChange } = useForm({
    from: "",
    to: "",
    cc: "",
    subject: "",
    message: "",
  })

  useEffect(() => {
    if (open && user) {
      setDefaultValues(user)
      setSending(false)
    }
  }, [open, user])

  useEffect(() => {
    if (jobFileUrls) {
      handlePrepareSend()
    }
  }, [jobFileUrls])

  // useEffect(() => {
  //   setValues((curr) => ({
  //     ...curr,
  //     subject: `Work Order: ${workOrder.work_order_no} - ${workOrder.label}`,
  //   }))
  // }, [workOrder])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult(true).then((token) => {})

        setUser(user)
        setValues((curr) => ({ ...curr, from: user.email }))
      }
    })

    return unsub
  }, [])

  const sendMultipartEmail = async ({
    from,
    to,
    cc,
    subject,
    text,
    html,
    attachments,
  }) => {
    //const sendEmail = httpsCallable(functions, "sendMultipartEmail")

    // https://www.twilio.com/blog/sending-email-attachments-with-sendgrid

    const msg = {
      from: from,
      to: to,
      cc: cc,
      subject: subject,
      html: html,
      //text: text,
      attachments: attachments,
    }

    if (html === "") {
      console.log("%cno HTML defined. Not sending email", "color:red")
      return
    }

    console.log("%csending email", "color:pink", { msg })

    const result = await cloudFunctions.sendMultipartEmailMessage(msg)

    console.log("%csend result", "color:yellow", result)

    enqueueSnackbar("Sent work order email", { variant: "success" })

    setOpen(false)

    // enqueueSnackbar(`Problem sending email. Error ${err}`, {
    //   variant: "error",
    // })
    // console.error("Error calling sendMultipartEmail", err)
  }

  const getSupplier = async (workOrder) => {
    if (workOrder.supplier_id !== "") {
      const suppliers = await dataServices.getSuppliersById(
        [workOrder.supplier_id],
        workOrder.account_id
      )
      const supplier = suppliers[0]
      return supplier
    }
    return null
  }

  const setDefaultValues = async (user) => {
    const supplier = await getSupplier(workOrder)

    const newValues = {
      ...values,
      from: user.email,
      to: supplier?.email || "",
      subject: `Work Order: ${workOrder.work_order_no} - ${workOrder.label}`,
      //message: await getEmailBody({ workOrder, jobs }),
    }
    setValues(newValues)

    jobServices.getJobFileUrls({ jobs }).then((urls) => setJobFileUrls(urls))
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleUseSupplierEmail = async () => {
    const supplier = await getSupplier(workOrder)

    if (supplier) {
      setValues((curr) => ({ ...curr, to: supplier.email }))
    }
  }

  // useEffect(() => {
  //   if (
  //     loadedFiles.length === jobFileUrls?.length &&
  //     htmlContent !== "" &&
  //     isSending
  //   ) {
  //     // Check if we've completed loading all the base64 data

  //     handleSend(loadedFiles)
  //   }
  // }, [loadedFiles, jobFileUrls, htmlContent, isSending])

  const handlePreview = () => {
    setIsPreview(true)
    //getWorkOrderEmailAsHTML()
  }

  // Load base64 file data before assembling the mime multipart  email.
  const handlePrepareSend = async () => {
    //setSending(true)

    // if (jobFileUrls.length === 0) {
    //   handleSend([])
    //   return
    // }

    // Retrieve base64 file data. This is async, and relies on useEffect to detect
    // when all the files' base64 data has been loaded
    jobServices
      .getJobFileInfo({
        jobs: jobs,
        centres: centres,
        jobFileUrls,
        fileLoadedCallback: setLoadedFiles,
      })
      .then(() => getWorkOrderEmailAsHTML())
  }

  const getWorkOrderEmailAsHTML = async () => {

    console.log('%crendering HTML', 'color:orange', { workOrder, jobs, centres, values })
    const priorities = await jobServices.getJobPriorities(workOrder.account_id)

    const jobFileUrls = await jobServices.getJobFileUrls({ jobs })

    const centreManagerIds = centres
      .map((centre) => centre.user_id_contact)
      .filter((item) => item)

    const centreManagers = await dataServices.getUsersByIdChunks(
      workOrder.account_id,
      centreManagerIds
    )

    renderEmailComponent(
      WorkOrderEmail,
      {
        workOrder,
        jobs,
        centres,
        priorities,
        message: values.message,
        jobFileUrls,
        centreManagers,
      },
      async (html) => {
        setHtmlContent(html)
      }
    )
  }

  const handleSend = async () => {
    if (htmlContent === "") {
      console.log("No email content. Not sending email")
      return
    }

    const attachments = loadedFiles.map((item) => {
      return {
        content: item.base64data,
        filename: item.fileName,
        type: fileServices.getMimeType(item.fileName),
        disposition: "inline",
        content_id: item.fileName, // includes job id in file name
      }
    })

    console.log("attachments", attachments)

    sendMultipartEmail({
      from: values.from,
      to: values.to,
      cc: values.cc,
      subject: values.subject,
      message: values.message,
      text: "",
      html: htmlContent,
      attachments: attachments,
    })
  }

  return (
    <>
      <Dialog open={open} fullWidth>
        {isSending && <LinearProgress />}
        <DialogTitle>Send Work Order Email</DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Box>
            <Form>
              <Stack gap={1}>
                <Controls.TextInput
                  name="from"
                  label="From"
                  value={values.from}
                  onChange={handleInputChange}
                />

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Controls.TextInput
                    name="to"
                    label="To"
                    value={values.to}
                    onChange={handleInputChange}
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Use supplier email address">
                      <IconButton onClick={handleUseSupplierEmail}>
                        <icons.SupplierIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>

                <Controls.TextInput
                  name="cc"
                  label="CC"
                  value={values.cc}
                  onChange={handleInputChange}
                />

                <Controls.TextInput
                  name="subject"
                  label="Subject"
                  value={values.subject}
                  onChange={handleInputChange}
                />

                <Controls.TextInput
                  name="message"
                  label="Additional email text"
                  multiline={true}
                  value={values.message}
                  onChange={handleInputChange}
                />
              </Stack>
            </Form>
          </Box>

          {isPreview && (
            <Box>
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <NavButtons>
            <Controls.Button
              text="Cancel"
              onClick={handleClose}
              variant="outlined"
            />
            <Controls.Button text="Preview" onClick={handlePreview} />
            <Controls.Button
              text="Send"
              disabled={isSending}
              onClick={handleSend}
            />
          </NavButtons>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default WorkOrderEmailDialog
