import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material"
import React, { useRef, useState } from "react"
import { useEffect } from "react"
import * as icons from "../icons"
import moment from "moment"
import db from "../Firestore"
import { spacing } from "../pages/services/styleServices"
import * as fileServices from "../pages/services/fileServices"
import * as jobServices from "../pages/services/jobServices"
import { format } from "date-fns"
import ReactTimeAgo from "react-time-ago"
import StatusAvatar from "./controls/StatusAvatar"

const styles = {
  card: {
    margin: spacing(0.5),
    // so that images do not overlap page boundaries
    pageBreakBefore: "auto",
    pageBreakAfter: "auto",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    backgroundColor: "#ddd",
    padding: spacing(0.5),
    display: "flex",
    flexDirection: "row",
  },
}

const JobPrintForm = (props) => {
  const { id } = props.computedMatch.params

  const [job, setJob] = useState(undefined)

  const [jobFiles, setJobFiles] = useState([])

  const [centre, setCentre] = useState(undefined)

  const [supplier, setSupplier] = useState(undefined)

  const [maintUser, setMaintUser] = useState(undefined)

  const [priorities, setPriorities] = useState()

  useEffect(() => {
    if (id) {
      db.collection("jobs")
        .doc(id)
        .get()
        .then((doc) => {
          const jobData = doc.data()

          setJob(jobData)

          if (jobData.supplier_id) {
            db.collection("suppliers")
              .doc(jobData.supplier_id)
              .get()
              .then((doc) => {
                setSupplier(doc.data())
              })
          }

          if (jobData.maint_user_id) {
            db.collection("users")
              .doc(jobData.maint_user_id)
              .get()
              .then((doc) => {
                setMaintUser(doc.data())
              })
          }

          jobServices.getJobPriorities(jobData.account_id).then((result) => {
            setPriorities(result)
          })
        })
    }
  }, [id])

  useEffect(() => {
    if (job && id) {
      db.collection("centres")
        .doc(job.centre_id)
        .get()
        .then((doc) => {
          setCentre(doc.data())
        })

      const newFileInfo = job.docs
        .filter((fileName) => fileName !== "")
        .map(async (fileName) => {
          return {
            fileName: fileName,
            url: await fileServices.getJobFileUrl(id, job.account_id, fileName),
          }
        })

      Promise.all(newFileInfo)
        .then((result) => {
          setJobFiles(result)
        })
        .catch((err) => {
          console.log("%cerror", "color:red", err)
        })
    }
  }, [job, id])

  const getFileInfo = ({ job, fileName }) => {
    return job?.file_info?.find((fi) => fi.file_name === fileName)
  }

  const isCentrePhoto = ({ job, fileName }) => {
    const fileInfo = getFileInfo({ job, fileName })
    return fileInfo === undefined || fileInfo.account_id === job.account_id
  }

  return (
    <>
      <Card elevation={0}>
        {job && (
          <CardHeader
            avatar={<StatusAvatar status={job.status} />}
            title={<Typography variant="h4">{job.label}</Typography>}
            subheader={
              <Typography variant="h6">
                Created {format(job.created.toDate(), "dd MMM yyyy hh:mm a")}
                {", "}
                <ReactTimeAgo date={job.created.toDate()} locale="en-AU" />
              </Typography>
            }
          />
        )}
        <CardContent>
          {job && centre && (
            <Box sx={{ margin: "10px" }}>
              <Box sx={styles.header}>
                <Typography variant="h5">
                  {supplier ? <b>Supplier</b> : <b>Allocated To</b>}
                </Typography>
              </Box>
              {supplier && (
                <Box
                  sx={{
                    mb: "20px",
                    mt: "5px",
                    ml: "5px",
                  }}
                >
                  <Typography variant="body1">{supplier.name}</Typography>
                  <Typography variant="body1">{supplier.email}</Typography>
                  <Typography variant="body1">{supplier.phone}</Typography>
                  <Typography variant="body1">{supplier.addr1}</Typography>
                  <Typography variant="body1">{supplier.addr2}</Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "5px" }}
                  >
                    <Typography variant="body1">{supplier.city}</Typography>
                    <Typography variant="body1">{supplier.state}</Typography>
                    <Typography variant="body1">{supplier.postcode}</Typography>
                  </Box>
                </Box>
              )}
              {maintUser && (
                <Box
                  sx={{
                    marginBottom: "20px",
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography variant="body1">{maintUser.name}</Typography>
                  <Typography variant="body1">{maintUser.email}</Typography>
                  <Typography variant="body1">{maintUser.phone}</Typography>
                </Box>
              )}
              <Box sx={styles.header}>
                <Typography variant="h5">
                  <b>{centre.name}</b>
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: "5px",
                  ml: "5px",
                }}
              >
                <Typography variant="body1">{centre.email}</Typography>
                <Typography variant="body1">{centre.phone}</Typography>
                <Typography variant="body1">{centre.addr1}</Typography>
                <Typography variant="body1">{centre.addr2}</Typography>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                  <Typography variant="body1">{centre.city}</Typography>
                  <Typography variant="body1">{centre.state}</Typography>
                  <Typography variant="body1">{centre.postcode}</Typography>
                </Box>
              </Box>
              <Box sx={{ marginLeft: "5px", marginTop: "10px" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Job Details
                </Typography>
              </Box>
              <TableContainer sx={{ paddingTop: 0 }}>
                <Table>
                  <TableBody>
                    <TableRow key={job.id}>
                      <TableCell sx={{ minWidth: "200px" }}>
                        <Typography variant="body1">{job.label}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{job.location}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{job.category}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {job.description}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ minWidth: "100px" }}>
                        <Typography variant="body1">
                          {priorities &&
                            jobServices.getPriorityLabel(
                              priorities.lookup_values.find(
                                (v) => v.id === job.priority.id
                              )
                            )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  marginBottom: "20px",
                  marginLeft: "5px",
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Description
                </Typography>
                <Typography variabt="body2">{job.description}</Typography>
              </Box>

              {jobFiles &&
                job &&
                jobFiles.map((file) => {
                  const fileInfo = getFileInfo({
                    job,
                    fileName: file.fileName,
                  })

                  console.log("fileInfo", fileInfo)

                  const photoDate = fileInfo?.created
                    ? moment(fileInfo.created.toDate()).format(
                        "DD MMM YYYY hh:mm a"
                      )
                    : ""

                  const photoUploader = fileInfo?.email || ""

                  const subHeader = [photoUploader, photoDate]
                    .filter((item) => item)
                    .join(" | ")

                  return (
                    <Box key={`${job.id}-${file.url}`} sx={styles.card}>
                      <Card sx={styles.card} variant="outlined">
                        <CardHeader
                          title={file.fileName}
                          subheader={subHeader}
                          avatar={
                            isCentrePhoto({
                              job,
                              fileName: file.fileName,
                            }) ? (
                              <icons.CentreIcon />
                            ) : (
                              <icons.SupplierIcon />
                            )
                          }
                        />
                        <CardContent>
                          <Stack direction="column">
                            <AutoSizingImage url={file.url} />
                          </Stack>
                        </CardContent>
                      </Card>
                    </Box>
                  )
                })}
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  )
}

const AutoSizingImage = (props) => {
  const { url } = props

  const [dimensions, setDimensions] = useState({
    width: undefined,
    height: undefined,
  })

  const MM_PER_PIXEL = 0.26458333

  const MARGIN = 40

  const A4_PAGE_WIDTH_IN_MM = 210 - MARGIN

  const converPixelsToMM = (pixels) => {
    return pixels * MM_PER_PIXEL
  }

  const imgRef = useRef()

  return (
    <img
      ref={imgRef}
      src={url}
      alt={"job"}
      width={dimensions.width}
      height={dimensions.height}
      onLoad={(event) => {
        const { width, height } = event.target

        const newWidthMM = converPixelsToMM(width)
        //const newHeightMM = converPixelsToMM(height)

        const widthScaleFactor =
          newWidthMM < A4_PAGE_WIDTH_IN_MM
            ? 1
            : A4_PAGE_WIDTH_IN_MM / newWidthMM

        const scaledWidth = widthScaleFactor * width
        const scaledHeight = widthScaleFactor * height

        setDimensions({ width: scaledWidth, height: scaledHeight })
      }}
    />
  )
}

export default JobPrintForm
