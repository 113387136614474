import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import {
  Typography,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  Box,
} from "@mui/material"
import firebase from "firebase/compat/app"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setAssetGridPagination } from "../redux/actions"
import { selectAssetGridPageDocs } from "../redux/selectors"
import { selectAssetGridPagination } from "../redux/selectors"
import * as Roles from "../pages/services/roleServices"
import { setAssetGridPageDocs } from "../redux/actions"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import Controls from "./controls/Controls"
import _ from "lodash"
import LinkButton from "./controls/LinkButton"
import NavButtons from "./NavButtons"
import PageNo from "./PageNo"
import MultiSelectLabel from "./controls/MultiSelectLabel"
import AssetImportDialog from "./AssetImportDialog"
import { getWorksheetRows } from "../pages/services/excelServices"
import {
  IMPORT_FILE_HEADINGS,
  NAME_COL_INDEX,
  CENTRE_COL_INDEX,
  MAKE_COL_INDEX,
  MODEL_COL_INDEX,
  SERIAL_COL_INDEX,
  STATUS_COL_INDEX,
  DESCRIPTION_COL_INDEX,
  MAINTENANCE_COL_INDEX,
  NEXT_SERVICE_COL_INDEX,
  ACQUIRED_COL_INDEX,
  DISPOSED_COL_INDEX,
  WARRANTY_COL_INDEX,
  CENTRE_COL,
  LOCATION_COL_INDEX,
  WARRANTY_END_COL_INDEX,
  NAME_COL,
  MAKE_COL,
  MODEL_COL,
  SERIAL_COL,
  STATUS_COL,
  LOCATION_COL,
  DESCRIPTION_COL,
  MAINTENANCE_COL,
  NEXT_SERVICE_COL,
  ACQUIRED_COL,
  DISPOSED_COL,
  WARRANTY_COL,
  WARRANTY_END_COL,
} from "../pages/services/assetServices"
import { Desktop, Mobile } from "./WindowSizes"
import { useSnackbar } from "notistack"
import AssetTile from "./AssetTile"
import FabButton from "./controls/FabButton"
import FilterItems from "./FilterItems"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sortable: true,
    direction: ["asc"],
  },
  {
    id: "centre",
    numeric: false,
    disablePadding: false,
    label: "Centre",
    sortable: false,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    sortable: false,
  },
  {
    id: "make",
    numeric: false,
    disablePadding: false,
    label: "Make",
    sortable: false,
  },
  {
    id: "model",
    numeric: false,
    disablePadding: false,
    label: "Model",
    sortable: false,
  },
  {
    id: "supplier",
    numeric: false,
    disablePadding: false,
    label: "Supplier",
    sortable: false,
  },
  {
    id: "sn",
    numeric: false,
    disablePadding: false,
    label: "S/N",
    sortable: false,
  },
  {
    id: "next_service",
    numeric: false,
    disablePadding: false,
    label: "Next Service",
    sortable: true,
    direction: ["asc", "desc"],
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created",
    sortable: false,
  },
  {
    id: "modified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    sortable: false,
  },
]

const AssetGrid = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order : headCell.direction[0]
                }
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

AssetGrid.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    "&:hover": {
      cursor: "hand",
    },
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = useState(true)

  const pag = useSelector(selectAssetGridPagination)

  const dispatch = useDispatch()

  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const [rowsPerPage, setRowsPerPage] = useState(20)

  const [centres, setCentres] = useState([])

  const [suppliers, setSuppliers] = useState([])

  const [assets, setAssets] = useState([])

  const pgDocs = useSelector(selectAssetGridPageDocs)

  const COLLECTION_NAME = "assets"

  const [userCentreIds, setUserCentreIds] = useState([])

  const [isShowImport, setShowImport] = useState(false)

  const [centreId, setCentreId] = useState("")

  const [currentUser, setCurrentUser] = useState()

  const [claims, setClaims] = useState()

  const [accountId, setAccountId] = useState()

  const [accountType, setAccountType] = useState()

  const [assetNameFilter, setAssetNameFilter] = useState("")

  const [assetNameField, setAssetNameField] = useState("")

  const [assetTypeField, setAssetTypeField] = useState("")

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setClaims(token.claims)
          setCurrentUser(token)
          setAccountId(token.claims.account_id)
          setAccountType(token.claims.account_type)
        })
      }
    })

    return unsub
  }, [])

  // Listen for changes

  useEffect(() => {
    if (accountId === undefined || accountType === undefined) {
      return
    }

    const whereClauseLog = []

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())

    whereClauseLog.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    switch (accountType) {
      case "centre":
        query = query.where("account_id", "==", accountId)
        whereClauseLog.push("account_id == " + accountId)
        break

      default:
        throw new Error("Unknown account type " + accountType)
    }

    const unsub = query.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach(
        (change) => {
          switch (change.type) {
            case "added":
              setAssets((curr) => [
                { id: change.doc.id, ...change.doc.data() },
                ...curr.filter((asset) => asset.id !== change.doc.id),
              ])
              break

            case "modified":
              setAssets((curr) => [
                { id: change.doc.id, ...change.doc.data() },
                ...curr.filter((asset) => asset.id !== change.doc.id),
              ])
              break

            case "removed":
              setAssets((curr) => {
                const newAssets = [...curr]
                const index = newAssets.findIndex(
                  (asset) => asset.id === change.doc.id
                )
                newAssets.splice(index, 1)
                return newAssets
              })
              break

            default:
              throw new Error("Unknown change type " + change.type)
          }
        },
        (error) => console.error("error listening for asset changes", error)
      )
    })

    return unsub // gets called on unmount
  }, [accountId, accountType])

  const handleCentreChange = (event) => {
    // if we click the 'x' icon in the drop down we get null instead of '' for the event.target.value
    const value = event.target.value === null ? "" : event.target.value

    setCentreFilterValue(value)
  }

  const setCentreFilterValue = (centreId) => {
    // Check if the centre changed
    if (centreId === pag.centre_id) {
      return
    }

    setCentreId(centreId)

    const updatedPagination = {
      ...pag,
      page: 0,
      centre_id: centreId,
    }

    setDirection("")
    dispatch(setAssetGridPagination(updatedPagination))
    dispatch(setAssetGridPageDocs([]))
  }

  const getFromToTerms = (word) => {
    const strlength = word.length
    const strFrontCode = word.slice(0, strlength - 1)
    const strEndCode = word.slice(strlength - 1, word.length)
    // This is an important bit..
    const endCode =
      strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)

    return { start: word, end: endCode }
  }

  const updatePageDocs = () => {
    if (assets.length > 0 && direction !== "prev") {
      const newPageDocs = [...pgDocs]

      const newPageDoc = {
        first: assets[0].doc,
        last: assets[assets.length - 1].doc,
      }

      newPageDocs[pag.page] = newPageDoc
      dispatch(setAssetGridPageDocs(newPageDocs))
    }
  }

  useEffect(() => {
    updatePageDocs(assets)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets])

  // Load assets

  const [reloadState, setReloadState] = useState({})

  const [reloadTrigger, setReloadTrigger] = useState(0)

  useEffect(() => {
    const logId = "[ASSET GRID > LOAD ASSETS]"

    if (accountId === undefined) {
      return
    }

    const newReloadState = {
      accountId,
      pag,
      assetNameFilter,
      reloadTrigger,
    }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    setReloadState(newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    const queryMods = []

    let query = db.collection(COLLECTION_NAME)

    const isJobAdmin = claims.roles.includes(Roles.JOB_ADMIN)

    // See if centre filter is set
    if (claims.centre_ids.length > 0) {
      // If single centre selected, filter by that center
      if (!isJobAdmin) {
        if (claims.centre_ids.length === 1) {
          // Mininise use of 'in' operator as we're only allowed 1 per query
          query = query.where(
            "centre_id",
            "==",
            currentUser.claims.centre_ids[0]
          )
          queryMods.push(`centre_id == ${claims.centre_ids[0]}`)
        } else {
          query = query.where("centre_id", "in", claims.centre_ids)
          queryMods.push(`filter by centres ${claims.centre_ids}`)
        }
      }
    } else if (centreId !== "") {
      if (isJobAdmin || claims.centre_ids.includes(centreId)) {
        query = query.where("centre_id", "==", centreId)
        queryMods.push(`centre_id == ${centreId}`)
      } else if (!isJobAdmin) {
      }
    }

    switch (claims.account_type) {
      case "centre":
        query = query.where("account_id", "==", claims.account_id)
        queryMods.push(`account_id == ${claims.account_id}`)
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    if (assetNameFilter !== "") {
      const fromTo = getFromToTerms(assetNameFilter)
      query = query
        .where("name_lower", ">=", fromTo.start.toLocaleLowerCase())
        .where("name_lower", "<", fromTo.end.toLocaleLowerCase())
      queryMods.push(
        `name_lower >= ${fromTo.start.toLocaleLowerCase()} < ${fromTo.end.toLocaleLowerCase()}`
      )
    }

    if (assetTypeField !== "") {
      const fromTo = getFromToTerms(assetTypeField)
      query = query.where("type_lower", ">=", fromTo.start.toLocaleLowerCase())
      query = query.where("type_lower", "<", fromTo.end.toLocaleLowerCase())
      queryMods.push(
        `type_lower >= ${fromTo.start.toLocaleLowerCase()} < ${fromTo.end.toLocaleLowerCase()}`
      )
    }

    // Get current page of assets

    if (assetTypeField !== "") {
      query = query.orderBy("type_lower", "asc")
    }

    query = query.orderBy(
      pag.orderBy === "name" ? "name_lower" : pag.orderBy,
      pag.order
    )
    queryMods.push(
      "order by " +
        (pag.orderBy === "name" ? "name_lower" : pag.orderBy) +
        " " +
        pag.order
    )

    query = query.orderBy(firebase.firestore.FieldPath.documentId(), pag.order)
    queryMods.push("order by doc id " + pag.order)

    if (pag.page > 0 && direction !== "prev") {
      // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
      // But if not found, it just means this is the first time we've clicked Next through the collection
      if (pgDocs[pag.page - 1]) {
        const lastDoc = pgDocs[pag.page - 1].last
        query = query.startAfter(lastDoc)
        queryMods.push("start after last doc on previous page " + lastDoc.id)
      }
    } else if (direction === "prev") {
      if (!pgDocs[pag.page]) {
        console.error("Can't find pagedocs for page", pag.page)
      }
      query = query.startAt(pgDocs[pag.page].first)
      queryMods.push("start at 1st doc on page " + pgDocs[pag.page].first.id)
    }

    query = query.limit(rowsPerPage)

    dataServices
      .loadData("(Load asset grid)", query, false)
      .then(async (assets) => {
        // Get centre names
        const centreIds = assets.map((asset) => asset.centre_id)
        const missingCentreIds = centreIds.filter(
          (id) => !centres.find((c) => c.id === id)
        )

        const newCentres = [...centres]
        if (missingCentreIds.length > 0) {
          const centres = await dataServices.getCentresByIdChunks(
            missingCentreIds
          )
          newCentres.push(...centres)
          setCentres(newCentres)
        }

        // Get supplier names
        const supplierIds = assets
          .map((asset) => asset.supplier_id)
          .filter((id) => id)
        const missingSupplierIds = supplierIds.filter(
          (id) => !suppliers.find((s) => s.id === id)
        )

        const newSuppliers = [...suppliers]
        if (missingSupplierIds.length > 0) {
          const suppliers = await dataServices.getSuppliersByIdChunks(
            missingSupplierIds,
            accountId
          )
          newSuppliers.push(...suppliers)
          setSuppliers(newSuppliers)
        }

        assets.forEach((asset) => {
          const centre = newCentres.find(
            (centre) => centre.id === asset.centre_id
          )
          asset.centre = centre.short_name || centre.name

          if (asset.supplier_id) {
            const supplier = newSuppliers.find(
              (supplier) => supplier.id === asset.supplier_id
            )
            asset.supplier_name = supplier.name
          }
        })

        // TEMP = Fix data

        const updateLower = assets.find((a) => {
          if (a.name && a.name_lower === undefined) {
            return true
          }

          if (a.type && a.type_lower === undefined) {
            return true
          }

          return false
        })

        if (updateLower) {
          const batch = db.batch()
          assets.forEach((asset) => {
            if (asset.name && asset.name_lower === undefined) {
              batch.update(db.collection("assets").doc(asset.id), {
                name_lower: asset.name.toLowerCase(),
              })
            }

            if (asset.type && asset.type_lower === undefined) {
              batch.update(db.collection("assets").doc(asset.id), {
                type_lower: asset.type.toLowerCase(),
              })
            }
          })

          batch.commit()
        }

        setAssets(assets)
      })
      .then(updatePageDocs())
    //.catch((err) => console.error(`${logId} Error loading assets`, err))
  }, [accountId, pag, assetNameFilter, centreId, reloadTrigger])

  const handleRequestSort = (event, property) => {
    const isAsc = pag.orderBy === property && pag.order === "asc"

    const sortCol = headCells.find((cell) => cell.id === property)
    const sortSameCol = pag.orderBy === property

    // If we clicked on the same column to sort it, then find the other sort order for that column (there will only be
    // 1 or 2 array entries, being 'asc' or 'desc' in some order),
    // Otherwise it means we're sorting on a new column so get the 1st sort order from the 'direction' attribute, which
    // could be either 'asc' or 'desc'.
    const sortOrder = sortSameCol
      ? sortCol.direction.find((dir) => dir !== pag.order) || pag.order
      : sortCol.direction[0]

    const updatedPagination = {
      page: 0,
      order: sortOrder,
      orderBy: property,
    }

    dispatch(setAssetGridPagination(updatedPagination))
    dispatch(setAssetGridPageDocs([]))
    setDirection("")
  }

  const handleAssetNameFieldChange = (event) => {
    const assetName = event.target.value
    setAssetNameField(assetName)
  }

  const handleAssetTypeFieldChange = (event) => {
    const type = event.target.value
    setAssetTypeField(type)
  }

  const handleNewAsset = (event) => {
    event.preventDefault()
    history.push("/assetedit")
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }

      dispatch(setAssetGridPagination(updatedPagination))
    }
  }

  const handleNameSearch = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault()
      setAssetNameFilter(event.target.value)
    }
  }

  const handleImport = async ({ workbook, selectedIndex, sheetInfo }) => {
    setShowImport(false)

    const sheet = workbook.Sheets[workbook.SheetNames[selectedIndex]]
    const rows = getWorksheetRows(sheet)

    const centreNameIndex = IMPORT_FILE_HEADINGS.indexOf(CENTRE_COL)
    const uniqueCentreNames = Array.from(
      new Set(rows.map((row) => row[centreNameIndex]))
    )

    // Make sure name, centre, and description are provided
    const missingName = rows.find((row) => row[NAME_COL_INDEX] === undefined)
    if (missingName) {
      enqueueSnackbar(`Missing name in row ${rows.indexOf(missingName) + 1}`, {
        variant: "error",
      })
      return
    }

    const missingCentre = rows.find(
      (row) => row[CENTRE_COL_INDEX] === undefined
    )
    if (missingCentre) {
      enqueueSnackbar(
        `Missing centre in row ${rows.indexOf(missingCentre) + 1}`,
        {
          variant: "error",
        }
      )
      return
    }

    const missingDescription = rows.find(
      (row) => row[DESCRIPTION_COL_INDEX] === undefined
    )
    if (missingDescription) {
      enqueueSnackbar(
        `Missing description in row ${rows.indexOf(missingDescription) + 1}`,
        {
          variant: "error",
        }
      )
      return
    }

    const { foundCentres, unknownCentres } =
      await dataServices.validateCentreNames({
        uniqueCentreNames,
        accountId,
      })

    // If any unknown centres, then show error message and abort
    if (unknownCentres.length > 0) {
      enqueueSnackbar(`Unknown centre(s): ${unknownCentres.join(", ")}`, {
        variant: "error",
      })
      return
    }

    const assets = rows.map((row) => {
      const centre = foundCentres.find(
        (centre) => centre.name === row[CENTRE_COL_INDEX]
      )

      const getIndex = (headingName) =>
        sheetInfo[selectedIndex].headings.findIndex((h) => h === headingName)

      const result = {
        account_id: accountId,
        name: row[getIndex(NAME_COL)],
        centre_id: centre.id,
        make: row[getIndex(MAKE_COL)],
        model: row[getIndex(MODEL_COL)],
        sn: row[getIndex(SERIAL_COL)],
        status: row[getIndex(STATUS_COL)],
        location: row[getIndex(LOCATION_COL)],
        description: row[getIndex(DESCRIPTION_COL)],
        maintenance_schedule: row[getIndex(MAINTENANCE_COL)],
        next_service: row[getIndex(NEXT_SERVICE_COL)],
        acquired: row[getIndex(ACQUIRED_COL)],
        disposed: row[getIndex(DISPOSED_COL)],
        warranty: row[getIndex(WARRANTY_COL)],
        warranty_end: row[getIndex(WARRANTY_END_COL)],
        created: dataServices.serverTimestamp(),
        modified: dataServices.serverTimestamp(),
        docs: [],
      }
      return result
    })

    for (const asset of assets) {
      // Remove undefined properties
      Object.keys(asset).forEach(
        (key) => asset[key] === undefined && delete asset[key]
      )

      if (asset.acquired) {
        // Convert to timestamp
        asset.acquired = dataServices.timestampFromDate(
          new Date(asset.acquired)
        )
      }

      if (asset.disposed) {
        // Convert to timestamp
        asset.disposed = dataServices.timestampFromDate(
          new Date(asset.disposed)
        )
      }

      if (asset.next_service) {
        // Convert to timestamp
        asset.next_service = dataServices.timestampFromDate(
          new Date(asset.next_service)
        )
      }

      if (asset.warranty_end) {
        // Convert to timestamp
        asset.warranty_end = dataServices.timestampFromDate(
          new Date(asset.warranty_end)
        )
      }
    }

    // Check if asset exists based on centre, make, model, and serial no.
    // If it exists, then overwrite it, otherwise add a new asset

    for (const asset of assets) {
      const querySnapshot = await db
        .collection("assets")
        .where("account_id", "==", accountId)
        .where("centre_id", "==", asset.centre_id)
        .where("name", "==", asset.name)
        .get()

      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0]
        const id = doc.id
        await db
          .collection("assets")
          .doc(id)
          .update(
            { ...asset, modified: dataServices.serverTimestamp() },
            { merge: true }
          )
      } else {
        await db.collection("assets").add(asset)
      }
    }
  }

  return (
    <>
      {isShowImport && (
        <AssetImportDialog
          open={isShowImport}
          onClose={() => setShowImport(false)}
          onImport={handleImport}
        />
      )}

      <FilterItems>
        <Controls.TextInput
          name="asset_name"
          label="Name"
          type="search"
          value={assetNameField}
          onChange={handleAssetNameFieldChange}
          onKeyDown={(event) => handleNameSearch(event)}
        />

        <Box sx={{ minWidth: "280px" }}>
          {userCentreIds && (
            <Controls.CentreCombobox
              name="centre_id"
              label={
                <MultiSelectLabel
                  count={userCentreIds.length}
                  oneSelectedLabel="Centre"
                  multiSelectedLabel={`Centre (limit ${userCentreIds.length})`}
                />
              }
              value={centreId}
              accountId={accountId}
              readonly={false}
              onChange={handleCentreChange}
              centreIdsFilter={userCentreIds}
            />
          )}
        </Box>

        <Controls.TextInput
          name="type"
          label="Type"
          type="search"
          value={assetTypeField}
          onChange={handleAssetTypeFieldChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault()
              setReloadTrigger((curr) => curr + 1)
            }
          }}
        />
      </FilterItems>

      <Box>
        <Mobile width={675}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {centres &&
              assets &&
              assets.map((asset) => (
                <AssetTile
                  key={asset.id}
                  asset={asset}
                  centres={centres}
                  handleEditAsset={() => {
                    history.push(`/AssetEdit/${asset.id}`)
                  }}
                />
              ))}
          </Box>
        </Mobile>
      </Box>

      <Box sx={styles.root}>
        <Desktop width={675}>
          <Box sx={{ marginTop: "20px" }}>
            <TableContainer>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="Assets"
              >
                <AssetGrid
                  numSelected={0}
                  selected={[]}
                  assets={assets}
                  order={pag.order}
                  orderBy={pag.orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={assets.length}
                />
                <TableBody>
                  {assets.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={false}
                        tabIndex={-1}
                        key={row.id}
                        selected={false}
                        onClick={(event) =>
                          history.push(`/AssetEdit/${row.id}`)
                        }
                        sx={styles.tableRow}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <LinkButton
                            to={`/AssetEdit/${row.id}`}
                            sx={{ justifyContent: "flex-start" }}
                          >
                            {row.name}
                          </LinkButton>
                        </TableCell>

                        <TableCell align="left">
                          <LinkButton
                            to={`/CentreEdit/${row.centre_id}`}
                            sx={{ justifyContent: "flex-start" }}
                          >
                            {row.centre}
                          </LinkButton>
                        </TableCell>

                        <TableCell align="left">{row.type}</TableCell>

                        <TableCell align="left">{row.make}</TableCell>

                        <TableCell align="left">{row.model}</TableCell>

                        <TableCell align="left">{row.supplier_name}</TableCell>

                        <TableCell align="left">{row.sn}</TableCell>

                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {row.next_service &&
                              format(row.next_service.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {format(row.created.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {format(row.modified.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Desktop>
        <Box sx={{ marginTop: "10px" }}>
          <NavButtons>
            <Controls.Button
              size="small"
              disabled={pag.page === 0}
              onClick={() => handlePageNav(-1)}
              text="Prev"
            />
            <Controls.Button
              size="small"
              disabled={assets.length < rowsPerPage}
              onClick={() => handlePageNav(1)}
              text="Next"
            />
            <Controls.Button
              size="small"
              onClick={(event) => setShowImport(true)}
              text="Import"
              endIcon={<FileUploadIcon />}
            />
          </NavButtons>
        </Box>
        <PageNo pageNo={pag.page + 1} />
      </Box>

      <FabButton handleClick={handleNewAsset} label="New Asset" />
    </>
  )
}
