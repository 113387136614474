import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { lighten } from "@mui/material/styles"
import { styled } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import {
  Typography,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  Box,
  Switch,
} from "@mui/material"
import firebase from "firebase/compat/app"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setUserGridPagination } from "../redux/actions"
import { selectUserGridPageDocs, selectCentres } from "../redux/selectors"
import { ensureAllFieldsExist } from "../pages/services/userServices"
import { selectUserGridPagination } from "../redux/selectors"
import { setUserGridPageDocs } from "../redux/actions"
import useDocumentPagination from "./useDocumentPagination"
import Controls from "./controls/Controls"
import _ from "lodash"
import LinkButton from "./controls/LinkButton"
import { spacing } from "../pages/services/styleServices"
import PageNo from "./PageNo"
import NavButtons from "./NavButtons"
import { Desktop, Mobile } from "./WindowSizes"
import UserTile from "./UserTile"
import FabButton from "./controls/FabButton"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import FilterItems from "./FilterItems"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sortable: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    sortable: true,
    direction: ["asc"],
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    sortable: false,
  },
  {
    id: "active",
    numeric: false,
    disablePadding: true,
    label: "Active",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created",
    sortable: false,
  },
  {
    id: "modified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    sortable: false,
  },
]

function UserGrid(props) {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order : headCell.direction[0]
                }
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledToolbar = styled(Toolbar)(({ numselected, theme }) => {
  const result = {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  }

  if (numselected > 0) {
    result.color = theme.palette.secondary.main
    result.backgroundColor = lighten(theme.palette.secondary.light, 0.85)
  }

  return result
})

UserGrid.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props

  return <StyledToolbar numselected={numSelected}></StyledToolbar>
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
  },
  userGrid: {
    width: "100%",
    mb: spacing(1),
    mt: "20px",
    pl: spacing(0.5),
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    "&:hover": {
      cursor: "hand",
    },
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = useState(true)

  const pag = useSelector(selectUserGridPagination)

  const dispatch = useDispatch()

  const history = useHistory()

  const [rowsPerPage, setRowsPerPage] = useState(20)

  const [users, setUsers] = useState([])

  const pgDocs = useSelector(selectUserGridPageDocs)

  const {
    addPage,
    clearPageDocs,
    getFirstDocThisPage,
    getLastDocPreviousPage,
  } = useDocumentPagination(pgDocs)

  const COLLECTION_NAME = "users"

  const [maxModified, setMaxModified] = useState()

  const [accountId, setAccountId] = useState()

  const [claims, setClaims] = useState()

  const centres = useSelector(selectCentres)

  const [email, setEmail] = useState(pag.email)

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setAccountId(token.claims.account_id)
          setClaims(token.claims)
        })
      }
    })

    return unsub
  }, [])

  // Listen for changes

  useEffect(() => {
    if (claims === undefined) {
      return
    }

    const whereClauseLog = []

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())

    whereClauseLog.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    switch (claims.account_type) {
      case "centre":
        query = query.where("account_id", "==", claims.account_id)
        whereClauseLog.push("account_id == " + claims.account_id)
        break

      case "supplier":
        query = query.where("account_id", "==", claims.account_id)
        whereClauseLog.push("account_id == " + claims.account_id)
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    let newMaxModified = null
    const unsub = query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            // trigger refresh
            newMaxModified = change.doc.data().modified
          }
        })
      },
      (error) => console.error("error listening for user changes", error)
    )

    if (newMaxModified !== null) {
      setMaxModified(newMaxModified)
    }

    return unsub // gets called on unmount
  }, [claims])

  const updateFilterValue = ({ name, value }) => {
    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      [name]: value,
    }

    setDirection("")
    dispatch(setUserGridPagination(updatedPagination))
  }

  const setEmailFilterValue = (email) => {
    updateFilterValue({ name: "email", value: email })
  }

  const setActiveOnlyFilterValue = (activeOnly) => {
    updateFilterValue({ name: "active_only", value: activeOnly })
  }

  // Get most recent modified record

  useEffect(() => {
    if (claims === undefined) {
      return
    }

    let query = db.collection(COLLECTION_NAME)

    switch (claims.account_type) {
      case "centre":
        query = query.where("account_id", "==", claims.account_id)
        break

      case "supplier":
        query = query.where("account_id", "==", claims.account_id)
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    query = query.orderBy("modified", "desc").limit(30)

    const unsub = query.onSnapshot(
      (querySnapshot) => {
        let newMaxModified = null
        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            newMaxModified = change.doc.data().modified
          }
        })
        if (newMaxModified !== null) {
          setMaxModified(newMaxModified)
        }
      },
      (error) => console.error("error listening for user changes", error)
    )

    return unsub
  }, [claims])

  const updatePageDocs = () => {
    if (users.length > 0 && direction !== "prev") {
      const newPageDocs = [...pgDocs]

      const newPageDoc = {
        first: users[0].doc,
        last: users[users.length - 1].doc,
      }

      newPageDocs[pag.page] = newPageDoc
      dispatch(setUserGridPageDocs(newPageDocs))
    }
  }

  useEffect(() => {
    updatePageDocs(users)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  // Load users

  const [reloadState, setReloadState] = useState({})

  useEffect(() => {
    const logId = "[USER GRID > LOAD USERS]"

    if (
      accountId === undefined ||
      maxModified === undefined ||
      claims === undefined
    ) {
      return
    }

    const newReloadState = {
      accountId,
      maxModified,
      pag,
    }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    setReloadState(newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    const queryMods = []

    let query = db.collection(COLLECTION_NAME)

    // Get current page of users

    query = query.orderBy(pag.orderBy, pag.order)

    queryMods.push("order by " + pag.orderBy + " " + pag.order)
    query = query.orderBy(firebase.firestore.FieldPath.documentId(), pag.order)
    queryMods.push("order by doc id " + pag.order)

    if (pag.page > 0 && direction !== "prev") {
      // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
      // But if not found, it just means this is the first time we've clicked Next through the collection
      if (pgDocs[pag.page - 1]) {
        const lastDoc = pgDocs[pag.page - 1].last
        query = query.startAfter(lastDoc)
        queryMods.push("start after last doc on previous page " + lastDoc.id)
      }
    } else if (direction === "prev") {
      if (!pgDocs[pag.page]) {
        console.error("Can't find pagedocs for page", pag.page)
      }
      query = query.startAt(pgDocs[pag.page].first)
      queryMods.push("start at 1st doc on page " + pgDocs[pag.page].first.id)
    }

    switch (claims.account_type) {
      case "centre":
        query = query.where("account_id", "==", claims.account_id)
        queryMods.push(`account_id == ${claims.account_id}`)
        break

      case "supplier":
        query = query.where("account_id", "==", claims.account_id)
        queryMods.push(`account_id == ${claims.account_id}`)
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    if (pag.email) {
      query = query.where("email", ">=", pag.email)
      query = query.where("email", "<=", pag.email + "\uf8ff")
      queryMods.push(`email >= ${pag.email} <= ${pag.email}\uf8ff`)
    }

    if (pag.active_only) {
      query = query.where("active", "==", true)
      queryMods.push("active == true")
    }

    query = query.limit(rowsPerPage)

    dataServices
      .loadData("(Load user grid)", query, false)
      .then((users) => {
        // If any users do not have the 'active' boolean field then set it to true for those
        // user records and do a batch update to add that field

        // TEMPORARY CODE TO ENSURE 'active' FIELD EXISTS FOR ALL USERS
        // See if any users are missing an 'active' boolean field
        const isMissingActiveField = users.some(
          (user) => user.active === undefined
        )

        if (isMissingActiveField) {
          const batch = db.batch()

          users.forEach((user) => {
            if (user.active === undefined) {
              batch.update(db.collection(COLLECTION_NAME).doc(user.id), {
                active: true,
              })
              console.log("set active = true for user", user.id)
            }
          })

          batch
            .commit()
            .catch((err) => console.error(`${logId} Error updating users`, err))
        }

        const userInfo = ensureAllFieldsExist(users)
        setUsers(userInfo)
      })
      .then(updatePageDocs())
      .catch((err) => console.error(`${logId} Error loading users`, err))
  }, [accountId, maxModified, pag, claims])

  const handleRequestSort = (event, property) => {
    const isAsc = pag.orderBy === property && pag.order === "asc"

    const sortCol = headCells.find((cell) => cell.id === property)
    const sortSameCol = pag.orderBy === property

    // If we clicked on the same column to sort it, then find the other sort order for that column (there will only be
    // 1 or 2 array entries, being 'asc' or 'desc' in some order),
    // Otherwise it means we're sorting on a new column so get the 1st sort order from the 'direction' attribute, which
    // could be either 'asc' or 'desc'.
    const sortOrder = sortSameCol
      ? sortCol.direction.find((dir) => dir !== pag.order) || pag.order
      : sortCol.direction[0]

    const updatedPagination = {
      page: 0,
      order: sortOrder,
      orderBy: property,
    }

    dispatch(setUserGridPagination(updatedPagination))
    dispatch(setUserGridPageDocs([]))
    setDirection("")
  }

  const handleNewUser = (event) => {
    event.preventDefault()
    history.push("/invites")
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }

      dispatch(setUserGridPagination(updatedPagination))
    }
  }
  return (
    <>
      <FilterItems>
        <Box sx={{ minWidth: "150px" }}>
          <Controls.TextInput
            name="email"
            label="Email"
            value={email}
            type="search"
            onChange={(event) => {
              setEmail(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === "Tab") {
                event.preventDefault()
                setEmailFilterValue(event.target.value)
              }
            }}
          />
        </Box>
        <Box sx={{ minWidth: "150px" }}>
          <Switch
            checked={pag.active_only}
            onChange={(event) => setActiveOnlyFilterValue(event.target.checked)}
          />
          <Typography variant="caption">Active Only</Typography>
        </Box>
      </FilterItems>
      <Box>
        <Mobile width={675}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {users &&
              users.map((user) => (
                <UserTile
                  key={user.id}
                  user={user}
                  centres={centres}
                  isEditable={false}
                  handleEditUser={() => {
                    history.push(`/UserEdit/${user.id}`)
                  }}
                />
              ))}
          </Box>
        </Mobile>
      </Box>

      <Box sx={styles.root}>
        <Desktop width={675}>
          <Box sx={styles.userGrid}>
            <TableContainer>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="Users"
              >
                <UserGrid
                  numSelected={0}
                  selected={[]}
                  users={users}
                  order={pag.order}
                  orderBy={pag.orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={users.length}
                />
                <TableBody>
                  {users.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={false}
                        tabIndex={-1}
                        key={row.id}
                        selected={false}
                        onClick={(event) => history.push(`/UserEdit/${row.id}`)}
                        sx={styles.tableRow}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <LinkButton
                            to={`/UserEdit/${row.id}`}
                            sx={{ justifyContent: "flex-start" }}
                          >
                            {row.name || "No name provided"}
                          </LinkButton>
                        </TableCell>

                        <TableCell align="left">{row.email}</TableCell>

                        <TableCell align="left">{row.phone}</TableCell>

                        <TableCell align="left" padding="none">
                          {row.active ? "Yes" : "No"}
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {format(row.created.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {format(row.modified.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Desktop>
        <Box sx={{ marginTop: "10px" }}>
          <NavButtons>
            <Controls.Button
              size="small"
              disabled={pag.page === 0}
              onClick={() => handlePageNav(-1)}
              text="Prev"
            />
            <Controls.Button
              size="small"
              disabled={users.length < rowsPerPage}
              onClick={() => handlePageNav(1)}
              text="Next"
            />
          </NavButtons>
        </Box>
        <PageNo pagination={pag.page + 1} />
      </Box>

      <FabButton handleClick={handleNewUser} label="Create User Invite" />
    </>
  )
}
