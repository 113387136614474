import db from "../../Firestore"
import * as dataServices from "../services/dataServices"

const STATUS_OPEN = "open"
const STATUS_COMPLETED = "completed"
const STATUS_CLOSED = "closed"

// Delete action and comments subcollection in a batch
const deleteAction = async (id) => {
  const batch = db.batch()

  const actionRef = db.collection("actions").doc(id)
  const actionDoc = await actionRef.get()
  const action = actionDoc.data()
  const commentsRef = actionRef.collection("comments")

  batch.delete(actionRef)

  const commentDocs = await commentsRef
    .where("account_id", "==", action.account_id)
    .get()

  if (commentDocs.size > 499) {
    // Handle this case, as the batch can only support 500 operations
    throw new Error("Too many comments to delete in a single batch.")
  }

  commentDocs.forEach((doc) => {
    batch.delete(doc.ref)
  })

  return batch.commit()
}

const updateAction = async (id, data) => {
  const actionRef = db.collection("actions").doc(id)

  await actionRef.update({ ...data, modified: dataServices.serverTimestamp() })
}

const updateActionStatus = async (actionId, action, status) => {
  const newAction = {
    status,
    modified: dataServices.serverTimestamp(),
  }

  if (status === STATUS_COMPLETED) {
    newAction.assigned_to = action.created_by
  }

  await db
    .collection("actions")
    .doc(actionId)
    .update(newAction, { merge: true })
}

const isShowActionBadge = (action, email, isJobAdmin) => {
  if (action.status === STATUS_CLOSED) {
    return false
  }

  // console.log("%caction badge?", "color:yellow", { action, email, isJobAdmin })

  if (action.assigned_to === email) {
    if (
      isJobAdmin &&
      (action.status === STATUS_COMPLETED || action.status === STATUS_OPEN)
    ) {
      return true
    } else if (action.status === STATUS_OPEN) {
      return true
    }
  }

  return false
}

const getAllowedActionStatusValues = ({
  action,
  userEmail,
  isJobAdmin,
  isSupplierViewing,
}) => {
  if (action && userEmail) {
    const statusValues = []

    if (action.status === STATUS_OPEN) {
      statusValues.push(STATUS_COMPLETED)

      if (action.created_by === userEmail) {
        if (isJobAdmin && !isSupplierViewing) {
          // Job admins can close actions
          statusValues.push(STATUS_CLOSED)
        }
      }
    }

    if (action.status === STATUS_COMPLETED) {
      statusValues.push(STATUS_OPEN)
      if (isJobAdmin && !isSupplierViewing) {
        // Job admins can close actions
        statusValues.push(STATUS_CLOSED)
      } else if (action.created_by === userEmail) {
        // Users can close their own actions
        statusValues.push(STATUS_CLOSED)
      }
    }

    if (action.status === STATUS_CLOSED) {
      if (isJobAdmin && !isSupplierViewing) {
        statusValues.push(STATUS_OPEN)
        statusValues.push(STATUS_COMPLETED)
      }
    }

    return statusValues
  }
}

const loadNonClosedJobActions = async (
  jobs,
  assignedToEmail,
  userAccountType,
  userAccountId
) => {
  // Get jobs ids and split by account id, and then into batches of 10 within account id

  // console.log("%cloading actions", "color:yellow", {
  //   jobs,
  //   assignedToEmail,
  //   userAccountType,
  //   userAccountId,
  // })

  const jobsByAccountId = jobs.reduce((acc, job) => {
    if (!acc[job.account_id]) {
      acc[job.account_id] = []
    }
    acc[job.account_id].push(job)
    return acc
  }, {})

  // Split jobsByAccountId into batches of 10

  const jobsByAccountIdBatches = []
  Object.keys(jobsByAccountId).forEach((jobAccountId) => {
    const jobs = jobsByAccountId[jobAccountId]
    const jobsBatches = []
    while (jobs.length > 0) {
      jobsBatches.push(jobs.splice(0, 10))
    }
    jobsByAccountIdBatches.push({
      jobAccountId,
      jobsBatches,
    })
  })

  // Retrieve actions for each batch of jobs

  const loaders = []

  jobsByAccountIdBatches.forEach((jobsByAccountIdBatch) => {
    const { jobAccountId, jobsBatches } = jobsByAccountIdBatch
    jobsBatches.forEach((jobsBatch) => {
      const jobIds = jobsBatch.map((job) => job.id)

      let query = db
        .collection("actions")
        .where("account_id", "==", jobAccountId)
        .where("type", "==", "job")
        //.where("assigned_to", "==", assignedToEmail)
        .where("status", "!=", STATUS_CLOSED)
        .where("parent_id", "in", jobIds)

      if (userAccountType === "supplier") {
        query = query.where("supplier_account_id", "==", userAccountId)
      }

      loaders.push(query.get())
    })
  })

  const querySnapshots = await Promise.all(loaders)

  const result = querySnapshots.flatMap((querySnapshot) => {
    return querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      }
    })
  })

  //console.log("%cloaded jobs actions", "color:lightgreen", { result })

  return result
}

export {
  deleteAction,
  updateAction,
  loadNonClosedJobActions,
  updateActionStatus,
  getAllowedActionStatusValues,
  isShowActionBadge,
  //markAsCompleted,
  STATUS_OPEN,
  STATUS_COMPLETED,
  STATUS_CLOSED,
}
