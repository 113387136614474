import * as fileServices from "./fileServices"
import {
  getStorage,
  ref,
  uploadString,
  uploadBytesResumable,
} from "firebase/storage"
import db from "../../Firestore"
import { splitBase64 } from "./encodingServices"

const ensureMandatoryFields = (asset) => {
  return {
    docs: [],
    ...asset,
  }
}

const NAME_COL = "Name"
const CENTRE_COL = "Centre"
const MAKE_COL = "Make"
const MODEL_COL = "Model"
const SERIAL_COL = "Serial No"
const STATUS_COL = "Status"
const LOCATION_COL = "Location"
const DESCRIPTION_COL = "Description"
const MAINTENANCE_COL = "Maintenance Schedule"
const NEXT_SERVICE_COL = "Next Service"
const ACQUIRED_COL = "Acquired"
const DISPOSED_COL = "Disposed"
const WARRANTY_COL = "Warranty"
const WARRANTY_END_COL = "Warranty End"

const NAME_COL_INDEX = 0
const CENTRE_COL_INDEX = 1
const MAKE_COL_INDEX = 2
const MODEL_COL_INDEX = 3
const SERIAL_COL_INDEX = 4
const STATUS_COL_INDEX = 5
const LOCATION_COL_INDEX = 6
const DESCRIPTION_COL_INDEX = 7
const MAINTENANCE_COL_INDEX = 8
const NEXT_SERVICE_COL_INDEX = 9
const ACQUIRED_COL_INDEX = 10
const DISPOSED_COL_INDEX = 11
const WARRANTY_COL_INDEX = 12
const WARRANTY_END_COL_INDEX = 13

const IMPORT_FILE_HEADINGS = [
  NAME_COL,
  CENTRE_COL,
  MAKE_COL,
  MODEL_COL,
  SERIAL_COL,
  STATUS_COL,
  LOCATION_COL,
  DESCRIPTION_COL,
  MAINTENANCE_COL,
  NEXT_SERVICE_COL,
  ACQUIRED_COL,
  DISPOSED_COL,
  WARRANTY_COL,
  WARRANTY_END_COL,
]

const getAssetFilePath = (accountId, assetId) => {
  return `accounts/${accountId}/assets/${assetId}/`
}

const uploadFiles = async (assetId, asset, files) => {
  console.log("%cuploadFiles", "color:lightGreen", { assetId, asset, files })

  // Get file type for each file

  const fileTypes = files.map((file) => file.type)

  console.log("%cfileTypes", "color:lightGreen", fileTypes)

  const imageFiles = files.filter((file) => file.type.includes("image"))
  const otherFiles = files.filter((file) => !file.type.includes("image"))

  console.log("%cfiles", "color:lightGreen", { imageFiles, otherFiles })

  // Resize and store image files

  const resizedPromises = imageFiles.map(async (file) => ({
    file: await fileServices.resizeFile(file),
    name: file.name,
    unique: getUniqueFileName(asset, file.name),
  }))

  const resized = await Promise.all(resizedPromises)

  const folderPath = `accounts/${asset.account_id}/assets/${assetId}`

  for (const fileItem of resized) {
    console.log("fileItem", { fileItem, resized })
    const fullFilePath = `${folderPath}/${fileItem.unique}`
    const storageRef = ref(getStorage(), fullFilePath)

    console.log("storing resized", {
      resized: fileItem.file,
      base64elements: splitBase64(fileItem.file),
      fullFilePath,
    })

    const result = await uploadString(storageRef, fileItem.file, "data_url")
  }

  // Store other files, no resizing though.

  const otherFilesWithUniqueNames = otherFiles.map((file) => ({
    unique: getUniqueFileName(asset, file.name),
    name: file.name,
    file,
  }))

  const otherPromises = otherFilesWithUniqueNames.map(async (fileItem) => {
    const fullFilePath = `${folderPath}/${fileItem.unique}`
    console.log("fullFilePath", fullFilePath)
    const storageRef = ref(getStorage(), fullFilePath)

    console.log("storing other", { fileItem, fullFilePath })

    //const result = await uploadString(storageRef, fileItem.file, "data_url")

    const uploadTask = uploadBytesResumable(storageRef, fileItem.file)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log("Upload is " + progress + "% done")
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused")
            break
          case "running":
            console.log("Upload is running")
            break
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error("Upload failed:", error)
      },
      () => {
        // Handle successful uploads on complete
        console.log("Upload successful")
      }
    )
  })

  await Promise.all(otherPromises)

  const newFileNames = [
    ...asset.docs,
    ...resized.map((item) => item.unique),
    ...otherFilesWithUniqueNames.map((item) => item.unique),
  ]
  const mergeValues = {
    docs: newFileNames,
  }

  await db
    .collection("assets")
    .doc(assetId)
    .update(mergeValues, { merge: true })

  return { ...asset, docs: newFileNames }
}

// Older jobs won't have the 'docs' attribute. So need to populate this from querying Firebase storage

const getAssetFileUrl = async (assetId, accountId, fileName) => {
  const path = `${getAssetFilePath(accountId, assetId)}`
  return fileServices.getUrl(path, fileName)
}

const getAssetFileNames = async (asset, assetId) => {
  return ensureMandatoryFields(asset).docs
}

const getUniqueFileName = (asset, fileName) => {
  let uniqueFileName

  // See if file name already used. If so, we'll rename the file just uploaded
  const found = asset.docs.find((name) => name === fileName)

  if (found) {
    const parts = fileName.split(".")

    let foundUniqueName = false
    let counter = 1
    while (!foundUniqueName) {
      // Create a new file name to use. If the file name has no suffix just add a " (1)", " (2)", etc as required until we get a unique name
      // Otherwise if there is a suffix then make sure we strip the suffix out

      uniqueFileName =
        parts.length === 1
          ? fileName + ` {${counter})`
          : parts.slice(0, -1).join(".") +
            ` (${counter}).` +
            parts.slice(-1).pop()

      const isNewNameUsed = asset.docs.find(
        (fileName) => uniqueFileName === fileName
      )
      if (!isNewNameUsed) {
        foundUniqueName = true
      } else {
        counter = counter + 1
      }
    }
  } else {
    uniqueFileName = fileName
  }
  return uniqueFileName
}

export {
  ensureMandatoryFields,
  IMPORT_FILE_HEADINGS,
  NAME_COL_INDEX,
  CENTRE_COL_INDEX,
  MAKE_COL_INDEX,
  MODEL_COL_INDEX,
  SERIAL_COL_INDEX,
  STATUS_COL_INDEX,
  LOCATION_COL_INDEX,
  DESCRIPTION_COL_INDEX,
  MAINTENANCE_COL_INDEX,
  NEXT_SERVICE_COL_INDEX,
  ACQUIRED_COL_INDEX,
  DISPOSED_COL_INDEX,
  WARRANTY_COL_INDEX,
  WARRANTY_END_COL_INDEX,
  NAME_COL,
  CENTRE_COL,
  MAKE_COL,
  MODEL_COL,
  SERIAL_COL,
  STATUS_COL,
  LOCATION_COL,
  DESCRIPTION_COL,
  MAINTENANCE_COL,
  NEXT_SERVICE_COL,
  ACQUIRED_COL,
  DISPOSED_COL,
  WARRANTY_COL,
  WARRANTY_END_COL,
  getAssetFileNames,
  getUniqueFileName,
  uploadFiles,
  getAssetFileUrl,
  getAssetFilePath,
}
