import React from "react"
import { InputAdornment, TextField, Typography } from "@mui/material"
import * as UIConstants from "./UIConstants"

const Readonly = ({
  name,
  label,
  value,
  helperText,
  icon,
  multiline = false,
}) => {
  const helperTextId = name + "_helper_text"

  const inputProps = icon
    ? {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        },
      }
    : {}

  return (
    <TextField
      name={name}
      variant={UIConstants.STANDARD_INPUT_VARIANT}
      size={UIConstants.STANDARD_INPUT_SIZE}
      id={name}
      label={label}
      {...inputProps}
      value={value}
      multiline={multiline}
      disabled={true}
      aria-describedby={helperTextId}
      helperText={
        <Typography variant="caption" component={"span"}>
          {helperText}
        </Typography>
      }
    />
  )
}

export default Readonly
