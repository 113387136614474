import { useSelector } from "react-redux"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import { selectMenuStatus } from "../redux/selectors"

const usableWidth = (menuStatus, width) => {
  const result = menuStatus ? width - drawerWidth : width
  return result
}
// This number should be the same as in Header.js
const drawerWidth = 240

const Desktop = (props) => {
  const { width } = useWindowDimensions()

  const delimiterWidth = props.width

  // Is the menu open? - true/false
  const menuStatus = useSelector(selectMenuStatus)

  return usableWidth(menuStatus, width) > delimiterWidth && props.children
}

const Mobile = (props) => {
  const { width } = useWindowDimensions()

  const delimiterWidth = props.width

  // Is the menu open? - true/false
  const menuStatus = useSelector(selectMenuStatus)

  return usableWidth(menuStatus, width) <= delimiterWidth && props.children
}

export { Desktop, Mobile }
