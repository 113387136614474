import React, { useState } from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { IconButton } from "@mui/material"
import PhotoCameraRoundedIcon from "@mui/icons-material/PhotoCameraRounded"

const styles = {
    root: {
        height: "100%",
        textAlign: "center",
    },
    imgBox: {
        maxWidth: "80%",
        maxHeight: "80%",
        margin: "10px",
    },
    img: {
        height: "inherit",
        maxWidth: "inherit",
    },
    input: {
        display: "none",
    },
}

function CameraSnap() {
    const [source, setSource] = useState("")

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0]
                const newUrl = URL.createObjectURL(file)
                setSource(newUrl)
            }
        }
    }

    return (
        <div sx={styles.root}>
            <Grid container>
                <Grid item xs={12}>
                    <h5>Capture your image</h5>
                    {source && (
                        <Box display="flex" justifyContent="center" border={1} sx={styles.imgBox}>
                            <img src={source} alt={"snap"} sx={styles.img}></img>
                        </Box>
                    )}
                    <input
                        accept="image/*"
                        style={styles.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                    />
                    <label htmlFor="icon-button-file">
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            size="large"
                        >
                            <PhotoCameraRoundedIcon fontSize="large" color="primary" />
                        </IconButton>
                    </label>
                </Grid>
            </Grid>
        </div>
    )
}
export default CameraSnap
