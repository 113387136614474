import * as actions from "../actionTypes"

const initialState = {
    value: "",
}

export default function supplierSearch(state = initialState, action) {
    switch (action.type) {
        case actions.SET_SUPPLIER_SEARCH: {
            return {
                ...state,
                value: action.payload.value,
            }
        }

        default:
            return state
    }
}
