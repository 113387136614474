import React from "react"
import * as WorkOrder from "../../components/workOrderStatus"
import { Badge, Typography } from "@mui/material"
import StyledChip from "./StyledChip"

const getChipProps = (status, theme) => {
  return {
    color: WorkOrder.statusColors[status],
    borderColor: WorkOrder.statusColors[status],
    borderRadius: 5,
  }
}

const StatusChip = ({
  status,
  size = "small",
  selected = true,
  showBadge = false,
  ...other
}) => {
  const label = (
    <Typography variant="caption">{WorkOrder.statusLabels[status]}</Typography>
  )

  const wrapBadge = (wrap, child) => {
    if (wrap) {
      return (
        <Badge color="error" variant="dot">
          {child}
        </Badge>
      )
    }
    return child
  }

  return wrapBadge(
    showBadge,
    <StyledChip
      {...other}
      size={size}
      label={label}
      status={selected ? status : "deselected"}
      getChipProps={getChipProps}
      sx={{
        color: WorkOrder.statusColors[status],
        borderColor: WorkOrder.statusColors[status],
        backgroundColor: "#fff",
      }}
      variant="outlined"
    />
  )
}

export default StatusChip
