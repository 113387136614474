import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie } from "react-chartjs-2"
import { Box } from "@mui/material"

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = (props) => {
    const { data } = props

    return (
        <Box>
            <Pie data={data} />
        </Box>
    )
}

export default PieChart
