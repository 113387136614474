// These select names, e.g. searchBar, currentUser, etc,
// are those as defined in index.js for redux\reducers\index.js

export const selectSearchBarValue = (data) => data.searchBar.value

export const selectPriorities = (data) => data.priorities.value

export const selectChartJobsByCentreAndCategory = (data) =>
  data.chartJobsByCentreAndCategory.value

export const selectJobGridPagination = (data) => data.jobGridPagination.value

export const selectJobGridPageDocs = (data) => data.jobGridPageDocs.value

export const selectJobGridSelectedItems = (data) =>
  data.jobGridSelectedItems.value

export const selectActionsIndicator = (data) => data.actionsIndicator.value

export const selectSupplierGridPagination = (data) =>
  data.supplierGridPagination.value

// no .value on the end of accessing centres, since its an array of objects, not an object
export const selectCentres = (data) => data.centres

export const selectSupplierGridPageDocs = (data) =>
  data.supplierGridPageDocs.value

export const selectUserGridPagination = (data) => data.userGridPagination.value

export const selectUserGridPageDocs = (data) => data.userGridPageDocs.value

export const selectActionGridPagination = (data) =>
  data.actionGridPagination.value

export const selectActionGridPageDocs = (data) => data.actionGridPageDocs.value

export const selectQuoteGridPagination = (data) =>
  data.quoteGridPagination.value

export const selectQuoteGridPageDocs = (data) => data.quoteGridPageDocs.value

export const selectCentreGridPagination = (data) =>
  data.centreGridPagination.value

export const selectCentreGridPageDocs = (data) => data.centreGridPageDocs.value

export const selectCheckListTemplateGridPagination = (data) =>
  data.checkListTemplateGridPagination.value

export const selectCheckListTemplateGridPageDocs = (data) =>
  data.checkListTemplateGridPageDocs.value

export const selectAssetGridPagination = (data) =>
  data.assetGridPagination.value

export const selectAssetGridPageDocs = (data) => data.assetGridPageDocs.value

export const selectSupplierShowDocuments = (data) =>
  data.supplierShowDocuments.value

export const selectSupplierSearch = (data) => data.supplierSearch.value

export const selectCheckListInstanceGridPagination = (data) =>
  data.checkListInstanceGridPagination.value

export const selectCheckListInstanceGridPageDocs = (data) =>
  data.checkListInstanceGridPageDocs.value

export const selectMenuStatus = (data) => data.menu.value

export const selectConfigMenuStatus = (data) => data.configMenu.value

export const selectMyAccountMenuStatus = (data) => data.myAccountMenu.value

export const selectWorkOrderGridPagination = (data) =>
  data.workOrderGridPagination.value

export const selectWorkOrderGridPageDocs = (data) =>
  data.workOrderGridPageDocs.value

export const selectWorkOrderGridSelectedItems = (data) =>
  data.workOrderGridSelectedItems.value

export const selectAccountGridPagination = (data) =>
  data.accountGridPagination.value

export const selectJobSummarySearch = (data) => data.jobSummarySearch.value

export const selectAccountGridPageDocs = (data) =>
  data.accountGridPageDocs.value

export const selectCarouselScale = (data) => data.carouselScale.value

export const selectCarouselVisible = (data) => data.carouselVisible.value

export const selectAccountGridSelectedItems = (data) =>
  data.accountGridSelectedItems.value

// timestamp value
export const selectWorkOrderLastModified = (data) =>
  data.workOrderLastModified.value

// timestamp value
export const selectCommentsLastModified = (data) =>
  data.commentsLastModified.value

// value is initially empty, but then when updated by scrolling the work order calendar has values of
// { start: <date>, end: <date> }, where hr/mm for start and end are 00:00:00 and 23:59:59 respectively
export const selectWorkOrderCalendarDateRange = (data) =>
  data.workOrderCalendarDateRange.value

export const selectJobs = (data) => {
  return data.jobs
}
