import React from "react"
import { Box } from "@mui/material"
import WorkOrderFullListForm from "../components/WorkOrderFullListForm"

const WorkOrderFullList = (props) => {
  return (
    <Box padding={0}>
      <WorkOrderFullListForm {...props} />
    </Box>
  )
}

export default WorkOrderFullList
