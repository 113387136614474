import React, { useEffect, useState } from "react"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
} from "@mui/material"
import Controls from "./controls/Controls"
import DeleteIcon from "@mui/icons-material/Delete"
import { withRouter } from "react-router-dom"
import { useForm, Form } from "./useForm"
import { useSnackbar } from "notistack"
import * as Roles from "../pages/services/roleServices"
import { Alert } from "@mui/material"
import YesNo from "./YesNo"
import * as icons from "../icons"
import NavButtons from "./NavButtons"
import { useMemo } from "react"
import { getAuth, onAuthStateChanged } from "firebase/auth"

// single form value
const initialValues = () => {
  return {
    lookup_value: "",
  }
}

const LookupsList = (props) => {
  const { lookupType } = props

  // The whole lookup value being edited, incl. name, and values attribute
  const [lookup, setLookup] = useState({
    name: lookupType,
    lookup_values: [],
  })

  const COLLECTION_NAME = "lookups"

  // Form values, e.g. the lookup 'name' attribute
  const { values, setValues, handleInputChange } = useForm(initialValues())

  const [isEditable, setEditable] = useState(false)

  const [id, setId] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const [accountId, setAccountId] = useState()

  const [yesNoConfig, setYesNoConfig] = useState({
    openPrompt: false,
    description:
      "Remove lookup? You will still need to click Save after delete to update this list",
    title: "Delete",
    handleConfirm: null,
  })

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult(false).then((token) => {
        setAccountId(token.claims.account_id)
        setEditable(Roles.isLookupEditable(token.claims.roles))
      })
    })

    return unsub
  }, [])

  const handlePromptDeleteLookupValue = (value) => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: true,
      title: `Delete '${value}'?`,
      handleConfirm: () => handleDeleteLookupValue(value),
    }
    setYesNoConfig(newConfig)
  }

  const handleAddNewLookupValue = (event) => {
    event.preventDefault()

    if (values.lookup_value.trim() === "") {
      enqueueSnackbar("Enter value", { variant: "info" })
      return
    }

    if (lookup.lookup_values.includes(values.lookup_value.trim())) {
      enqueueSnackbar("Value already exists", { variant: "info" })
      return
    }

    const newLookupValues = [
      values.lookup_value.trim(),
      ...lookup.lookup_values,
    ]

    const newLookup = {
      ...lookup,
      lookup_values: [...newLookupValues],
    }

    setLookup(newLookup)

    // Clear out entry form ready for next lookup to be entered
    setValues(initialValues)
  }

  // Save

  const handleSaveLookupValues = async (event) => {
    event.preventDefault()

    await db.collection("lookups").doc(id).set({
      name: lookup.name,
      account_id: lookup.account_id,
      created: lookup.created,
      modified: dataServices.serverTimestamp(),
      lookup_values: lookup.lookup_values.sort(),
    })

    enqueueSnackbar("Updated", { variant: "success" })
  }

  const handleDeleteLookupValue = (value) => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)

    const newLookupValues = lookup.lookup_values.filter(
      (item) => item !== value
    )

    const newLookup = {
      ...lookup,
      lookup_values: newLookupValues,
    }

    setLookup(newLookup)
  }

  // Load lookup values. Create lookup if it doesn't exist

  useEffect(() => {
    if (accountId === undefined) {
      return
    }

    let query = db
      .collection(COLLECTION_NAME)
      .where("account_id", "==", accountId)
      .where("name", "==", lookupType)
      .limit(1)

    dataServices.loadData("(Load lookup values)", query).then((result) => {
      if (result.length === 0) {
        const newLookup = {
          name: lookup.name,
          account_id: accountId,
          created: dataServices.serverTimestamp(),
          modified: dataServices.serverTimestamp(),
          lookup_values: lookup.lookup_values.sort(),
        }

        db.collection(COLLECTION_NAME)
          .add(newLookup)
          .then((docRef) => {
            setId(docRef.id)
          })

        setLookup(newLookup)
      } else if (result.length === 1) {
        setLookup(result[0])
        setId(result[0].id)
      } else {
        console.error("Found too many lookup entries", result.length)
      }
    })
  }, [accountId])

  // Replace _ with spaces, and strip off the 's' from the end of the lookupType if it has one
  const typeName = useMemo(() => {
    const name = lookupType.replace(/_/g, " ")
    return name.endsWith("s") ? name.slice(0, -1) : name
  }, [lookupType])

  return (
    <>
      {isEditable || (
        <Alert severity="info">
          Lookup is read only. Requires Admin role to edit
        </Alert>
      )}

      <YesNo config={yesNoConfig} />

      <Form>
        <Box sx={{ maxWidth: "500px" }}>
          {isEditable && (
            <Stack gap={2} sx={{ marginBottom: "30px" }}>
              <Controls.TextInput
                autoFocus
                fullWidth
                name="lookup_value"
                type="search"
                label={`Add new ${typeName}`}
                value={values.lookup_value}
                onChange={handleInputChange}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    handleAddNewLookupValue(event)
                  }
                }}
              />

              <Box sx={{ marginLeft: "auto" }}>
                <Controls.Button text="Add" onClick={handleAddNewLookupValue} />
              </Box>
            </Stack>
          )}

          <List dense>
            {lookup.lookup_values.map((lookupValue) => (
              <ListItem key={lookupValue}>
                <ListItemText primary={lookupValue} />
                {isEditable && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="start"
                      aria-label="delete"
                      onClick={() => handlePromptDeleteLookupValue(lookupValue)}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>

          <NavButtons>
            {isEditable && (
              <Controls.Button
                text="Save"
                onClick={(event) => handleSaveLookupValues(event)}
                endIcon={<icons.SaveIcon />}
              />
            )}
            {/* <Controls.Button text="Cancel" onClick={handleCancel} /> */}
          </NavButtons>
        </Box>
      </Form>
    </>
  )
}

export default withRouter(LookupsList)
