import React, { useState } from "react"
import BillingForm from "../components/BillingForm"
import Header from "../components/Header"
import { useId } from "./useId"

const Billing = (props) => {
    let { id } = useId(props)

    const [title, setTitle] = useState("Billing")

    return (
        <Header title={title}>
            <BillingForm accountId={id} setTitle={setTitle} />
        </Header>
    )
}

export default Billing
