import React, { useState } from "react"
import JobEditForm from "../components/JobEditForm"
import Header from "../components/Header"
import { useId } from "./useId"

const JobEdit = (props) => {
    const { id } = useId(props)

    const [title, setTitle] = useState("Edit Job")

    return (
        <Header title={title}>
            <JobEditForm id={id} setTitle={setTitle} />
        </Header>
    )
}

export default JobEdit
