import * as dataServices from "./dataServices"
import db from "../../Firestore"

const handleSaveContact = async (contact) => {
  if (contact.id) {
    const { id, ...contactData } = contact

    const contactToUpdate = {
      ...contactData,
      modified: dataServices.serverTimestamp(),
      first_name_lower: contact.first_name.toLowerCase(),
      last_name_lower: contact.last_name.toLowerCase(),
    }
    delete contactToUpdate.id

    await db.collection("contacts").doc(contact.id).update(contactToUpdate)
    return contact.id
  } else {
    const contactToAdd = {
      ...contact,
      first_name_lower: contact.first_name.toLowerCase(),
      last_name_lower: contact.last_name.toLowerCase(),
      modified: dataServices.serverTimestamp(),
      created: dataServices.serverTimestamp(),
    }

    const docRef = await db.collection("contacts").add(contactToAdd)
    return docRef.id
  }
}

const handleRemoveContact = async (contactId) => {
  console.log("removing contact", contactId)
  await db.collection("contacts").doc(contactId).delete()
}

export { handleSaveContact, handleRemoveContact }
