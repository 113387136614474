import React, { useState, useEffect, useMemo } from "react"
import { useForm, Form } from "./useForm"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import YesNo from "./YesNo"
import { Box, IconButton, Stack, Paper, Tooltip, Divider } from "@mui/material"
import Controls from "./controls/Controls"
import { Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import _ from "lodash"
import { Alert } from "@mui/material"
import NavButtons from "./NavButtons"
import SubHeading from "./SubHeading"
import CheckListEditFormSection from "./CheckListEditFormSection"
import CheckListEditFormListElement from "./CheckListEditFormListElement"
import CheckListElementAnswers from "./CheckListElementAnswers"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const defaultAnswers = [
  { label: "Yes", value: "Y" },
  { label: "No", value: "N" },
  { label: "N/A", value: "N/A" },
]

const initialValues = () => {
  return {
    name: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
    version: 1,
    archived: false,

    // Users who can use this check list template
    users: [],
    sections: [],
  }
}

function CheckListEditForm(props) {
  const { enqueueSnackbar } = useSnackbar()

  const [id, setId] = useState("")

  // Has this check list been used? If so, we need to lock it because otherwise
  // allowing changes to this check list would corrupt any attempt to view
  // data in those already saved check lists
  const [isCheckListUsed, setCheckListUsed] = useState()

  const [userId, setUserId] = useState("")

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const history = useHistory()

  const { setTitle } = props

  // Contains a section and element no, e.g. { S: 1 }, if just editing
  // the section header, or { S: 1, E: 4 } if editing element 4 in section 1
  // or null if not editing anything.
  const [editId, setEditId] = useState(undefined)

  const isNew = () => props.id === undefined && id === ""

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const [users, setUsers] = useState()

  const [droppable, setDroppable] = useState(false)

  const [dragging, setDragging] = useState(false)

  const COLLECTION_NAME = "checklists"

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setValues((curr) => ({
            ...curr,
            account_id: token.claims.account_id,
          }))
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    if (props.id != null) {
      setId(props.id)

      loadCheckList(props.id)
    } else {
      // New checklists cannot have been used yet
      setCheckListUsed(false)

      // Add a blank section for new checklists
      if (values.sections.length === 0) {
        handleAddSection()
      }
    }
  }, [props.id, setValues])

  // Load checklist

  const loadCheckList = async (checkListId) => {
    const snapshot = await db.collection(COLLECTION_NAME).doc(checkListId).get()

    const newValues = {
      ...initialValues(),
      ...snapshot.data(),
    }

    const preparedValues = applySequenceNumbers(newValues)

    setValues(preparedValues)

    // Now see if this check list has been used

    const usedSnapshot = await db
      .collection("checklist_instances")
      .where("account_id", "==", preparedValues.account_id)
      .where("check_list_id", "==", checkListId)
      .limit(1)
      .get()

    // This will disable any changes to the check list if the used count is > 0

    setCheckListUsed(usedSnapshot.docs.length > 0)

    // Load users 1 at a time, using the dataServices.getUsersById method
    // which will return a list of users in the same order as the ids

    // const userPromises = newValues.users.map(async (userId) => {
    //   try {
    //     return await dataServices.getUserByUid(userId)
    //   } catch (e) {
    //     console.error("user not found", { user: userId, error: e })
    //   }
    // })

    // const userRecs = (await Promise.all(userPromises)).filter(
    //   (user) => user !== undefined
    // )
  }

  useEffect(() => {
    if (values.name !== "") {
      setTitle(values.name)
    }
  }, [setTitle, values.name])

  // 'confirmed' is true or false
  const handleDeleteConfirmed = () => {
    // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)

    if (id !== undefined && id !== "" && id !== null) {
      db.collection(COLLECTION_NAME)
        .doc(id)
        .delete()
        .then(history.goBack())
        .then(enqueueSnackbar("Deleted", { variant: "success" }))
    }
  }

  // Source and target have a section and element attribute, e.g. { section: 1, element: 3 }
  // unless its a section and then it only has a section attribute, e.g. { section: 1 }
  // Rules for dropping:
  // - Cannot drop on self
  // - Cannot drop on item above under same section
  const isDroppable = (source, target) => {
    // Rule 1. Cannot drop on self

    if (_.isEqual(source, target)) {
      return false
    }

    return true
  }

  const handleMove = ({ src, dest }) => {
    //console.log("move", { src, dest, values })

    // if dest has no element attribute, then we're dropping to become the first element
    // in that section
    const isDropToSection = !dest.hasOwnProperty("element")

    // Get 'src' element from values
    const srcElement = values.sections
      .find((section) => section.seq === src.section)
      .elements.find((element) => element.seq === src.element)

    // If the dest position is after the src position, and they're both in the same section, then
    // given that removing the src impacts the dest we need to adjust the dest position to be 1 less

    if (src.section === dest.section && src.element < dest.element) {
      dest.element = dest.element - 1
    }

    const removedSrc = {
      ...values,
      sections: values.sections.map((section) => {
        return {
          ...section,
          elements: section.elements.filter(
            (element) =>
              !_.isEqual(src, { section: section.seq, element: element.seq })
          ),
        }
      }),
    }

    // Now add the srcElement into the correct place in elements in the removedSrc object.
    // If we're dropping to a section, then we add to the beginning of the elements array.
    // Otherwise add it after the element we're dropping on.

    const newElements = isDropToSection
      ? [srcElement, ...removedSrc.sections[dest.section].elements]
      : [
          ...removedSrc.sections[dest.section].elements.slice(
            0,
            dest.element + 1
          ),
          srcElement,
          ...removedSrc.sections[dest.section].elements.slice(dest.element + 1),
        ]

    const newValues = {
      ...removedSrc,
      sections: removedSrc.sections
        .map((section) => {
          return {
            ...section,
            elements:
              section.seq === dest.section
                ? newElements
                : [...section.elements],
          }
        })
        .map((section) => {
          return {
            ...section,
            elements: section.elements.map((element, index) => {
              return {
                ...element,
                seq: index,
              }
            }),
          }
        }),
    }

    setValues(newValues)
  }

  const handlePromptDeleteItem = (itemId) => {
    if (itemId.hasOwnProperty("element")) {
      const newConfig = {
        title: "Delete?",
        description: "Remove checklist element?",
        openPrompt: true,
        handleConfirm: () => handleDeleteItem(itemId),
      }
      setYesNoConfig(newConfig)
    } else {
      const elementCount = values.sections.find(
        (section) => section.seq === itemId.section
      ).elements.length

      const newConfig = {
        title: "Delete?",
        description: `Delete checklist section${
          elementCount > 0 && `and all ${elementCount} questions`
        }. Are you sure?`,
        openPrompt: true,
        handleConfirm: () => handleDeleteItem(itemId),
      }
      setYesNoConfig(newConfig)
    }
  }

  const handleDeleteItem = (itemId) => {
    // We can be deleting either a section { section: 1 } , or an element { section 1: element 4 }
    const isElementDelete = itemId.hasOwnProperty("element")

    if (isElementDelete) {
      const newValues = {
        ...values,
        sections: values.sections.map((section) => {
          const newSection = {
            ...section,
            elements: section.elements.filter(
              (element) =>
                !_.isEqual(itemId, {
                  section: section.seq,
                  element: element.seq,
                })
            ),
          }

          return newSection
        }),
      }

      setValues(newValues)
    } else {
      // Delete an entire section, incl. any elements

      const newValues = {
        ...values,
        sections: values.sections.filter(
          (section) => section.seq !== itemId.section
        ),
      }

      setValues(newValues)
    }
  }

  const handleMoveElementDown = (itemId) => {
    // Find the specified section, then check if the selected element seq + 1 matches the
    // number of elements.
    // This assumes the sequencing of elements is always strictly 0 -> n
    const isItemLast =
      values.sections.find((section) => section.seq === itemId.section).elements
        .length ===
      itemId.element + 1

    if (isItemLast) {
      return
    }

    // We need to swap the section and element sequence numbers of
    // these elements, and then re-sort
    const fromId = itemId
    const toId = { section: itemId.section, element: itemId.element + 1 }

    moveElement(fromId, toId)
    setEditId(toId)
  }

  const handleMoveElementUp = (itemId) => {
    if (itemId.element === 0) {
      return
    }

    // We need to swap the section and element sequence numbers of
    // these elements, and then re-sort
    const fromId = itemId
    const toId = { section: itemId.section, element: itemId.element - 1 }

    moveElement(fromId, toId)
    setEditId(toId)
  }

  const moveElement = (fromId, toId) => {
    const newValues = {
      ...values,
      sections: values.sections.map((section) => {
        return {
          ...section,
          elements: section.elements
            .map((element) => {
              const elId = { section: section.seq, element: element.seq }

              if (_.isEqual(fromId, elId)) {
                return {
                  ...element,
                  seq: toId.element,
                }
              } else if (_.isEqual(toId, elId)) {
                return {
                  ...element,
                  seq: fromId.element,
                }
              } else {
                return element
              }
            })
            .sort((a, b) => a.seq - b.seq),
        }
      }),
    }

    setValues(newValues)
  }

  const handleMoveSectionDown = (seq) => {
    // This assumes the sequencing of sections is always strictly 0 -> n
    const isSectionLast = values.sections.length === seq + 1

    if (isSectionLast) {
      return
    }

    // We need to swap the section sequence numbers and then re-sort.
    // The sequence numbers of the elements in each section remains unchanged
    const fromSeq = seq
    const toSeq = seq + 1

    moveSection(fromSeq, toSeq)
    setEditId({ section: toSeq })
  }

  const handleMoveSectionUp = (seq) => {
    // Can't move 1st section up
    if (seq === 0) {
      return
    }

    const fromSeq = seq
    const toSeq = seq - 1

    moveSection(fromSeq, toSeq)
    setEditId({ section: toSeq })
  }

  const moveSection = (fromSeq, toSeq) => {
    const newValues = {
      ...values,
      sections: values.sections
        .map((section) => {
          return {
            ...section,
            seq:
              section.seq === fromSeq
                ? toSeq
                : section.seq === toSeq
                ? fromSeq
                : section.seq,
          }
        })
        .sort((a, b) => a.seq - b.seq),
    }

    setValues(newValues)
  }

  const getDefaultNewElement = () => {
    return {
      type: "check",
      label: "",
      text: "",
      mandatory: true,
      score_value: 1,
      answers: defaultAnswers,
    }
  }

  // Can be called to add to a section or element, where itemId would look
  // like the following respectively: { section: 1 }, or { section: 1, element: 3 }
  // We add the new element at the end of the section, or after the specified element.
  const handleAddElement = (itemId) => {
    // Did we click Add from a section (in which case we add at the end of the elements)
    // versus did we click Add on an element within the section in which case we
    // add/insert after that element.
    const isAddFromSection = !itemId.hasOwnProperty("element")

    if (isAddFromSection) {
      // Find last element in section and add after that.
      // Since we're using a 0-based sequence, if there are 0 elements
      // then the first element to add has a seq of 0, and so on.
      const newSeq = values.sections.find(
        (section) => section.seq === itemId.section
      ).elements.length

      const newElement = {
        ...getDefaultNewElement(),
        seq: newSeq,
      }

      const newValues = {
        ...values,
        sections: values.sections.map((section) => {
          const newElements =
            section.seq === itemId.section
              ? [...section.elements, newElement]
              : [...section.elements]

          const newSection = {
            ...section,
            elements: newElements,
          }

          return newSection
        }),
      }

      setValues(newValues)
      setEditId({ section: itemId.section, element: newSeq })
    } else {
      // Adding a new element under an existing element
      // Check if we're adding at the end, or inserting

      const sectionForAdd = values.sections.find(
        (section) => section.seq === itemId.section
      )

      if (itemId.element === sectionForAdd.elements.length - 1) {
        const newValues = {
          ...values,
          sections: values.sections.map((section) => {
            // No change to this section
            if (section.seq !== itemId.section) {
              return section
            }

            const newElement = {
              ...getDefaultNewElement(),
              seq: section.elements.length,
            }

            const newSection = {
              ...section,
              elements: [...section.elements, newElement],
            }

            setEditId({ section: itemId.section, element: newElement.seq })

            return newSection
          }),
        }
        setValues(newValues)
      } else {
        // Need to shift all elements down by 1 that are under where we want to insert.
        // We'll do this by physically inserting in the right spot and then just
        // resequence the whole array

        const newValues = {
          ...values,

          sections: values.sections.map((section) => {
            // No change to this section
            if (section.seq !== itemId.section) {
              return section
            }

            const newElements = [...section.elements]

            const newElement = {
              ...getDefaultNewElement(),
              seq: -1, // doesn't matter, we're going to resequence the whole array of elements
            }

            newElements.splice(itemId.element + 1, 0, newElement)

            setEditId({ section: section.seq, element: itemId.element + 1 })

            return {
              ...section,
              elements: newElements,
            }
          }),
        }

        const resequenced = applySequenceNumbers(newValues)

        setValues(resequenced)
      }
    }
  }

  const handleAddSection = () => {
    const newSection = {
      seq: values.sections.length,
      label: "",
      elements: [],
    }

    const newValues = {
      ...values,
      sections: [...values.sections, newSection],
    }

    setValues(newValues)
    setEditId({ section: newSection.seq })
  }

  const handleChangeElement = (newElement, srcId) => {
    const newValues = {
      ...values,
      sections: values.sections.map((section) => {
        return {
          ...section,
          elements: section.elements.map((element) => {
            const elId = { section: section.seq, element: element.seq }

            if (_.isEqual(elId, srcId)) {
              return newElement
            } else {
              return element
            }
          }),
        }
      }),
    }

    setValues(newValues)
  }

  // srcId is the id being editied, e.g. { section: 1 }, or { section: 3, element: 4 }
  const handleChange = (event, srcId) => {
    const { name, value } = event.target

    const newValues = {
      ...values,
      sections: values.sections.map((section) => {
        const id = { section: section.seq }

        if (_.isEqual(srcId, id)) {
          return {
            ...section,
            [name]: value,
          }
        } else {
          return {
            ...section,
            elements: section.elements.map((element) => {
              const elId = { section: section.seq, element: element.seq }

              const formattedValue =
                name === "score_value" && value ? parseInt(value) : value

              if (_.isEqual(elId, srcId)) {
                const newElement = {
                  ...element,
                  [name]: formattedValue,
                }

                if (name === "type" && value === "check") {
                  if (
                    newElement.answers === undefined ||
                    newElement.answers.length === 0
                  ) {
                    newElement.answers = [...defaultAnswers]
                  }

                  // Set default score value and mandatory status if
                  // we're changing this question to be a 'check' question
                  newElement.score_value = 1
                  newElement.mandatory = true
                }

                if (newElement.type !== "check") {
                  delete newElement.answers

                  // Can't score non-'check' answers
                  newElement.score_value = 0
                }

                return newElement
              } else {
                return element
              }
            }),
          }
        }
      }),
    }

    setValues(newValues)
  }

  const applySequenceNumbers = (vals) => {
    const newElements = {
      ...vals,
      sections: initSectionsForEditing(vals.sections),
    }

    return newElements
  }

  const initSectionsForEditing = (sections) => {
    return sections.map((section, index) => {
      return {
        label: "",

        ...section,

        // Initialise child 'elements'
        elements: initElementsForEditing(section.elements),

        // Add a sequence no. which we need for sorting
        seq: index,
      }
    })
  }

  const answerSortOrder = { Y: 1, N: 2, "N/A": 3 }

  const initElementsForEditing = (elements) => {
    return elements.map((element, index) => {
      const newElement = {
        ...getDefaultNewElement(),

        ...element,

        // Add a sequence no. which we need for sorting
        seq: index,
      }

      const newAnswers = newElement.answers.sort((a, b) => {
        const sortVal = answerSortOrder[a.value] - answerSortOrder[b.value]
        if (sortVal === 0) {
          return a.label.localeCompare(b.label)
        }
        return sortVal
      })

      newElement.answers = newAnswers

      return newElement
    })
  }

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    const newConfig = {
      title: "Delete Checklist?",
      description: "This delete is permanent",
      openPrompt: true,
      handleConfirm: handleDeleteConfirmed,
    }
    setYesNoConfig(newConfig)
  }

  const handleDeleteUser = (userId) => {
    const newValues = {
      ...values,
      users: values.users.filter((id) => id !== userId),
    }

    setValues(newValues)

    const newUsers = users.filter((user) => user.id !== userId)
    setUsers(newUsers)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    // Check that we don't have a 'check' question with 2 or more of the same answer type, e.g. 2 x 'Y' answers.
    const duplicateAnswers = values.sections
      .map((section) =>
        section.elements.find((element) => {
          const groupedAnswers = _.groupBy(element.answers, "value")
          const keys = Object.keys(groupedAnswers)
          return keys.find((key) => groupedAnswers[key].length > 1 && element)
        })
      )
      .filter((item) => item !== undefined)

    if (values.name === "") {
      enqueueSnackbar("Enter checklist name", { variant: "info" })
    } else if (duplicateAnswers.length > 0) {
      enqueueSnackbar(
        `Question [${duplicateAnswers[0].label}] has duplicate answer types. Only 1 of each answer type allowed`,
        {
          variant: "error",
        }
      )
    } else {
      if (isNew()) {
        dataServices
          .getCurrentUser()
          .then((user) => {
            const newRecord = {
              ...values,
              account_id: user.account_id,
              created: dataServices.serverTimestamp(),
              modified: dataServices.serverTimestamp(),
            }

            db.collection(COLLECTION_NAME)
              .add(newRecord)
              .then((docRef) => setId(docRef.id))
              .then(enqueueSnackbar("Created", { variant: "success" }))
          })
          .catch(function (error) {
            console.error("Error:" + error)
            enqueueSnackbar("Error", { variant: "error " })
          })
      } else {
        const stripIdsForSaving = (vals) => {
          return {
            ...vals,
            sections: vals.sections.map((section) => {
              const newSection = { ...section }
              return {
                ...newSection,
                elements: newSection.elements.map((element) => {
                  const newElement = {
                    ...element,
                  }
                  return newElement
                }),
              }
            }),
          }
        }

        const updateRecord = {
          ...stripIdsForSaving(values),
          modified: dataServices.serverTimestamp(),
        }

        db.collection(COLLECTION_NAME)
          .doc(id)
          .update(updateRecord)
          .then(enqueueSnackbar("Saved", { variant: "success" }))
      }
    }
  }

  const handleCopyTemplate = async () => {
    const copiedTemplateValues = {
      ...values,
      version: values.version + 1,
      created: dataServices.localTimestamp(),
      modified: dataServices.localTimestamp(),
    }

    // Archive old template
    await db
      .collection("checklists")
      .doc(id)
      .update({ version: values.version, archived: true }, { merge: true })

    db.collection("checklists")
      .add(copiedTemplateValues)
      .then((docRef) => {
        history.push(`/CheckListEdit/${docRef.id}`)
      })
      .then(
        enqueueSnackbar("Copied template. You can now modify this new version")
      )
  }

  return (
    <>
      <YesNo config={yesNoConfig} />
      {isCheckListUsed && (
        <Stack gap={2} sx={{ mb: "15px" }}>
          <Alert severity="info">
            This check list template has been used in an inspection. You will
            need to create a copy if you wish to make changes to the template,
            so that it does not affect existing inspections.
          </Alert>
          <Box style={{ mt: "6px" }}>
            <Controls.Button
              name="copy_template"
              text="Copy template"
              onClick={handleCopyTemplate}
            />
          </Box>
          <Divider />
        </Stack>
      )}
      <Form>
        <Stack gap={1}>
          <SubHeading title="Checklist Template" />

          <Box
            sx={{
              mt: "10px",
              mb: "20px",
              maxWidth: "400px",
              width: "100%",
            }}
          >
            <Controls.TextInput
              autoFocus
              name="name"
              label="Name"
              value={values.name}
              disabled={isCheckListUsed}
              onChange={handleInputChange}
            />
          </Box>

          <SubHeading title="Questions" />

          {values.sections.map((s) => (
            <CheckListSectionView
              key={s.seq}
              section={s}
              editId={editId}
              setEditId={setEditId}
              droppable={droppable}
              setDroppable={setDroppable}
              isDroppable={isDroppable}
              dragging={dragging}
              setDragging={setDragging}
              handleMove={handleMove}
              handleMoveElementUp={handleMoveElementUp}
              handleMoveElementDown={handleMoveElementDown}
              handleChange={handleChange}
              handleChangeElement={handleChangeElement}
              handlePromptDeleteItem={handlePromptDeleteItem}
              isCheckListUsed={isCheckListUsed}
              handleMoveSectionUp={handleMoveSectionUp}
              handleMoveSectionDown={handleMoveSectionDown}
              handleAddElement={handleAddElement}
            />
          ))}
        </Stack>

        <Box sx={{ marginTop: "10px" }}>
          <NavButtons>
            {!isNew() && !isCheckListUsed && (
              <Controls.Button
                text="Delete Template"
                type="button"
                onClick={handlePromptConfirmDelete}
                disabled={
                  isCheckListUsed === true || isCheckListUsed === undefined
                }
              />
            )}
            {!isCheckListUsed && (
              <Controls.Button
                text="Add Section"
                type="button"
                onClick={handleAddSection}
              />
            )}

            <Controls.Button
              type="button"
              text="Save"
              onClick={handleSubmit}
              disabled={isCheckListUsed}
            />
          </NavButtons>
        </Box>
      </Form>
    </>
  )
}

const CheckListSectionView = ({
  section,
  editId,
  setEditId,
  droppable,
  setDroppable,
  isDroppable,
  dragging,
  setDragging,
  handleMove,
  handleMoveElementUp,
  handleMoveElementDown,
  handleChange,
  handleChangeElement,
  handlePromptDeleteItem,
  isCheckListUsed,
  handleMoveSectionUp,
  handleMoveSectionDown,
  handleAddElement,
}) => {
  const SECTION_SELECTED = "1px solid #33f"
  const SECTION_UNSELECTED = "1px solid #fff"

  const isSelected = useMemo(() => {
    return editId && _.isEqual(editId, { section: section.seq })
  }, [editId])

  const handleDragEnter = (e) => {
    e.preventDefault()
    setDroppable(true)
    e.currentTarget.style.borderBottom = SECTION_SELECTED
  }

  const handleDragLeave = (e) => {
    setDroppable(false)
    e.currentTarget.style.borderBottom = SECTION_UNSELECTED
  }

  // Get ref to section/element that we dragged over
  const handleDragOver = (e) => {
    e.preventDefault()
    if (!droppable) {
      setDroppable(true)
      e.currentTarget.style.borderBottom = SECTION_SELECTED
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const data = JSON.parse(e.dataTransfer.getData("application/json"))
    // console.log("object was dropped in this object", {
    //   target: e.target,
    //   dragged: data,
    // })
    setDroppable(false)
    e.currentTarget.style.borderBottom = SECTION_UNSELECTED
    handleMove({
      src: data,
      dest: { section: section.seq },
    })
  }

  const handleToggleEdit = (e) => {
    e.preventDefault()
    if (isSelected) {
      setEditId(undefined)
    } else {
      setEditId({ section: section.seq })
    }
  }

  return (
    <Stack direction="row" sx={{ width: "100%", mb: "5px" }}>
      <Stack
        direction="column"
        gap={1}
        sx={{ mt: "5px", pt: "15px", width: "500px" }}
      >
        <Box
          sx={{ fontWeight: "bold" }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {!isSelected && <Box>Section - {section.label}</Box>}

            <Box sx={{ ml: "auto" }}>
              {!isCheckListUsed && (
                <Tooltip title="Add question">
                  <span>
                    <Controls.Button
                      text="Add Question"
                      type="button"
                      onClick={() => handleAddElement({ section: section.seq })}
                    />
                  </span>
                </Tooltip>
              )}
              {!isCheckListUsed && (
                <Tooltip title="Edit section">
                  <IconButton onClick={handleToggleEdit}>
                    {editId && editId.section === section.seq ? (
                      <ExpandLessIcon fontSize="small" />
                    ) : (
                      <ExpandMoreIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
          {isSelected && (
            <CheckListEditFormSection
              section={section}
              handleMoveSectionUp={handleMoveSectionUp}
              handleMoveSectionDown={handleMoveSectionDown}
              editId={editId}
              setEditId={setEditId}
              isEditing={isSelected}
              // Change a single field value
              handleChange={handleChange}
              // Change an entire element
              handleChangeElement={handleChangeElement}
              handlePromptDeleteItem={handlePromptDeleteItem}
              isCheckListUsed={isCheckListUsed}
            />
          )}
        </Box>
        <Stack direction="column" gap={1}>
          {section.elements.map((el) => (
            <CheckListElementView
              key={el.seq}
              section={section}
              element={el}
              editId={editId}
              setEditId={setEditId}
              isEditing={isSelected}
              droppable={droppable}
              setDroppable={setDroppable}
              isDroppable={isDroppable}
              dragging={dragging}
              setDragging={setDragging}
              handleMove={handleMove}
              handleMoveElementUp={handleMoveElementUp}
              handleMoveElementDown={handleMoveElementDown}
              handleChange={handleChange}
              handleChangeElement={handleChangeElement}
              handlePromptDeleteItem={handlePromptDeleteItem}
              isCheckListUsed={isCheckListUsed}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

const CheckListElementView = ({
  section,
  element,
  editId,
  setEditId,
  droppable,
  setDroppable,
  isDroppable,
  dragging,
  setDragging,
  handleMove,
  handleMoveElementUp,
  handleMoveElementDown,
  handleChange,
  handleChangeElement,
  handlePromptDeleteItem,
  isCheckListUsed,
}) => {
  const isSelected = useMemo(() => {
    return (
      editId && editId.section === section.seq && editId.element === element.seq
    )
  }, [editId])

  const ELEMENT_SELECTED = "1px solid #33f"
  const ELEMENT_UNSELECTED = "1px solid #ccc"

  // This is the { element: 1, section: 2 } object that we're dragging
  const getDraggedId = (e) => {
    if (e.dataTransfer) {
      const dataTransferStr = e.dataTransfer.getData("application/json")
      if (dataTransferStr) {
        const data = JSON.parse(e.dataTransfer.getData("application/json"))
        return data
      }
    }
  }

  const handleDragStart = (e) => {
    setDragging(true)
    e.dataTransfer.setData(
      "application/json",
      JSON.stringify({ section: section.seq, element: element.seq })
    )
  }

  const handleDragEnd = (e) => {
    setDragging(false)
    setDroppable(false)
  }

  const handleDragEnter = (e) => {
    setDroppable(true)
    e.currentTarget.style.borderBottom = ELEMENT_SELECTED
  }

  const handleDragLeave = (e) => {
    setDroppable(false)
    e.currentTarget.style.borderBottom = ELEMENT_UNSELECTED
  }

  // Get ref to section/element that we dragged over
  const handleDragOver = (e) => {
    e.preventDefault()
    if (!droppable) {
      const canDrop = isDroppable(getDraggedId(e), {
        section: section.seq,
        element: element.seq,
      })
      if (canDrop) {
        setDroppable(canDrop)
        e.currentTarget.style.borderBottom = ELEMENT_SELECTED
      }
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const data = JSON.parse(e.dataTransfer.getData("application/json"))
    // console.log("object was dropped in this object", {
    //   target: e.target,
    //   dragged: data,
    // })
    setDroppable(false)
    e.currentTarget.style.borderBottom = ELEMENT_UNSELECTED
    handleMove({
      src: data,
      dest: { section: section.seq, element: element.seq },
    })
  }

  const paperStyle = useMemo(() => {
    const result = {
      borderBottom: ELEMENT_UNSELECTED,
      cursor: dragging ? (droppable ? "grabbing" : "no-drop") : "grab",
    }
    return result
  }, [dragging, droppable])

  const handleToggleEdit = (e) => {
    e.preventDefault()
    if (isSelected) {
      setEditId(undefined)
    } else {
      setEditId({ section: section.seq, element: element.seq })
    }
  }

  return (
    <Paper
      elevation={0}
      sx={paperStyle}
      draggable={true}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <Stack direction="row" sx={{ width: "100%", mb: "5px" }}>
        <Stack direction="column" key={element.seq}>
          <Box sx={{ display: "flex", flexDirection: "row", width: "500px" }}>
            {!isSelected && (
              <Stack gap={1}>
                <Box>{element.label}</Box>

                <Typography color="primary" variant="body2">
                  {element.text}
                </Typography>
                {element.type === "check" && (
                  <CheckListElementAnswers
                    element={element}
                    readonly={isCheckListUsed}
                  />
                )}
              </Stack>
            )}

            <Box sx={{ ml: "auto" }}>
              <Tooltip title="Edit question">
                <IconButton onClick={handleToggleEdit}>
                  {editId?.section === section?.seq &&
                  editId?.element === element?.seq ? (
                    <ExpandLessIcon fontSize="small" />
                  ) : (
                    <ExpandMoreIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          {isSelected && (
            <CheckListEditFormListElement
              section={section}
              element={element}
              editId={editId}
              setEditId={setEditId}
              isEditing={() => isSelected}
              handleMoveElementUp={handleMoveElementUp}
              handleMoveElementDown={handleMoveElementDown}
              handleChange={handleChange}
              handleChangeElement={handleChangeElement}
              handlePromptDeleteItem={handlePromptDeleteItem}
              isCheckListUsed={isCheckListUsed}
            />
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}

export default withRouter(CheckListEditForm)
