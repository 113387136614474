import React from "react"
import Header from "../components/Header"
import QuoteGrid from "../components/QuoteGrid"

const Quotes = () => {
  return (
    <Header title="Quotes">
      <QuoteGrid />
    </Header>
  )
}

export default Quotes
