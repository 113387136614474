import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material"
import db from "../Firestore"
import { useEffect, useState } from "react"
import Controls from "./controls/Controls"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import * as workOrderStatus from "./workOrderStatus"
import * as supplierServices from "../pages/services/supplierServices"
import LinkIcon from "@mui/icons-material/Link"
import { useSnackbar } from "notistack"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const LinkToSupplierAccount = ({ open, supplierId, handleClose }) => {
  const [supplier, setSupplier] = useState()

  const [claims, setClaims] = useState()

  const [linkableSupplierResult, setLinkableSupplierResult] = useState()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdTokenResult(true)
          .then((token) => {
            setClaims(token.claims)
          })
          .catch((error) => {
            console.log("Error getting token in AppMenu", error)
          })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    db.collection("suppliers")
      .doc(supplierId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const supplierData = doc.data()
          setSupplier(supplierData)

          cloudFunctions
            .findLinkableSupplierAccount({
              email: supplierData.email,
            })
            .then((result) => {
              console.log("linkable supplier account", {
                result,
                email: supplierData.email,
              })
              setLinkableSupplierResult(result)
            })
        } else {
          console.log("No such document!")
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [supplierId])

  const handleLink = async () => {
    if (linkableSupplierResult?.accounts.length === 1) {
      // Get id for supplier to set as supplier_account_id field in our supplier record

      const supplierAccountId = linkableSupplierResult.accounts[0].id

      // Update supplier record with supplier_account_id

      const linkResult = await cloudFunctions.linkToSupplierAccount({
        supplierId,
        supplierAccountId,
      })

      console.log("%cLink result", "color:lightGreen", {
        supplierId,
        supplierAccountId,
        linkResult,
      })

      // Now update all open work orders so that the external supplier can access them.

      const workOrders = await db
        .collection("work_orders")
        .where("account_id", "==", claims.account_id)
        .where("supplier_id", "==", supplierId)
        .where("status", "in", workOrderStatus.supplierStatuses)
        .get()

      enqueueSnackbar("Enabling access to work orders for supplier", {
        variant: "info",
      })

      for (const workOrder of workOrders.docs) {
        console.log("Update work order access for", workOrder.id)
        await supplierServices.updateSupplierAccess({
          workOrderId: workOrder.id,
          source: "link supplier",
        })
      }

      enqueueSnackbar("Supplier linked", { variant: "success" })

      handleClose()
    }
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Link to Supplier</DialogTitle>
      <DialogContent sx={{ maxWidth: "400px" }}>
        {linkableSupplierResult?.accounts.length === 1 && (
          <Stack gap={2}>
            <Alert severity="success">
              <b>{supplier?.name}</b> account found
            </Alert>
            <Typography variant="body2">
              Click <b>Link</b> to enable work orders and jobs to be allocated
              to <b>{supplier?.name}</b> ({supplier?.email}) in the system.
            </Typography>
          </Stack>
        )}
        {linkableSupplierResult?.accounts.length !== 1 && (
          <Stack gap={2}>
            <Alert severity="warning">
              <b>{supplier?.name}</b> account not found
            </Alert>
            <Typography variant="body2">
              If you wish to allocate work orders to them in the system, please
              ask them to create an account, and then return here to link to
              their account based on their email address ({supplier?.email}).
            </Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Controls.Button
          text="Cancel"
          variant="outlined"
          onClick={handleClose}
        />
        <Controls.Button
          text="Link"
          onClick={handleLink}
          endIcon={<LinkIcon />}
          disabled={linkableSupplierResult?.accounts.length !== 1}
        />
      </DialogActions>
    </Dialog>
  )
}

export default LinkToSupplierAccount
