import React, { useEffect, useState } from "react"
import { Box, IconButton, Fab, Stack, Tooltip } from "@mui/material"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import AddIcon from "@mui/icons-material/Add"
import DashboardWidget from "./DashboardWidget"
import * as icons from "../icons"
import { useHistory } from "react-router-dom"
import { selectCentres } from "../redux/selectors"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import { getAuth, onAuthStateChanged } from "firebase/auth"
const styles = {
  fab: {
    position: "fixed",
    bottom: 16,
    right: 16,
    top: "auto",
    left: "auto",
  },
}

const DashboardWidgets = (props) => {
  const [userId, setUserId] = useState("")

  const [userCentreIds, setUserCentreIds] = useState([])

  const allCentres = useSelector(selectCentres)

  const [jobTypes, setJobTypes] = useState(undefined)

  const [priorities, setPriorities] = useState(undefined)

  const [locations, setLocations] = useState(undefined)

  const [accountId, setAccountId] = useState()

  const [widgetConfigs, setWidgetConfigs] = useState([])

  const [accountType, setAccountType] = useState("")

  const [selectedWidget, setSelectedWidget] = useState(undefined)

  const [addedWidgetId, setAddedWidgetId] = useState(undefined)

  const history = useHistory()

  const centres = useMemo(() => {
    if (allCentres.length === 0) return allCentres
    if (userCentreIds.length === 0) return allCentres
    return allCentres.filter((c) => userCentreIds.includes(c.id))
  }, [allCentres, userCentreIds])

  const [suppliers, setSuppliers] = useState([])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          setUserId(token.claims.user_id)
          setAccountId(token.claims.account_id)
          setAccountType(token.claims.account_type)
        })
      }
    })

    return unsub
  }, [])

  const blankWidgetConfig = {
    config: {
      job_types: [],
      centre_ids: [],
      locations: [],
      job_status: "",
      job_statuses: [],
      job_allocation: "",
      open_date_type: "",
      open_filter_date: null,
      open_days_min: 0,
      open_days_max: 0,
      open_prior_month: 0,
      closed_date_type: "",
      closed_filter_date: null,
      closed_days_min: 0,
      closed_days_max: 0,
      closed_prior_month: 0,
      priority: 0,
      group_by: "",
      group_by_filter: "",
      // use 10 as sensible defailt values for top and bottom
      group_by_filter_top: 10,
      group_by_filter_bottom: 10,
      group_by_filter_min: 0,
      group_by_filter_max: 0,
      // blank = no chart, otherwise 'bar', 'line', 'pie'
      format: "",
      // indicates the source of chart data, which can be the live jobs view or a trend line
      chart_data: "",
      expanded: false,
      // Should the title be automatically generated from the config?
      auto_title: true,
    },
  }

  const sortWidgets = (a, b) => {
    return (a?.position || 0) - (b?.position || 0)
  }

  const loadRefData = async (userId, accountId) => {
    const userDoc = await db.collection("users").doc(userId).get()
    const user = userDoc.data()

    setUserCentreIds(user.centres)

    const lookupDocRefs = await db
      .collection("lookups")
      .where("account_id", "==", accountId)
      .where("name", "in", ["location_types", "job_types"])
      .get()

    const lookups = lookupDocRefs.docs.map((doc) => {
      return { id: doc.id, ...doc.data() }
    })
    setJobTypes(
      lookups.find((l) => l.name === "job_types")?.lookup_values || []
    )
    setLocations(
      lookups.find((l) => l.name === "location_types")?.lookup_values || []
    )

    db.collection("suppliers")
      .where("account_id", "==", accountId)
      .get()
      .then((snapshot) => {
        const suppliers = []
        snapshot.forEach((doc) => {
          const data = doc.data()
          suppliers.push({ id: doc.id, name: data.name })
        })
        setSuppliers(suppliers)
      })

    const priorityDocRefs = await db
      .collection("priorities")
      .where("account_id", "==", accountId)
      .get()

    if (priorityDocRefs.docs.length > 0) {
      const priorityDoc = priorityDocRefs.docs[0]
      const priorities = priorityDoc.data().lookup_values
      setPriorities(priorities)
    } else {
      console.log("no priorities")
    }
    const dashboardDocRefs = await db
      .collection("dashboard")
      .where("account_id", "==", accountId)
      .where("user_id", "==", userId)
      .get()

    const configs = dashboardDocRefs.docs
      .map((doc, index) => {
        const config = { ...blankWidgetConfig.config, ...doc.data().config }

        if (config.job_status !== "" && config.job_statuses.length === 0) {
          config.job_statuses = [config.job_status]
        }

        // Ensure default values
        if (!["", "bar", "pie"].includes(config.format)) {
          config.format = ""
        }

        return {
          id: doc.id,
          ...doc.data(),
          config: config,
        }
      })
      .sort((a, b) => sortWidgets(a, b))

    // Check if the 'position' value for widgets is set and continuous from 1 -> n

    const positions = configs.map((c) => c.position).sort((a, b) => a - b)
    const positionsAreContinuous = positions.every((p, i) => p === i + 1)

    // If not, set the position value for each widget to be the index + 1 and then update to firestore as a batch

    if (!positionsAreContinuous) {
      const batch = db.batch()
      configs.forEach((c, i) => {
        const docRef = db.collection("dashboard").doc(c.id)
        const newPosition = i + 1
        batch.update(docRef, {
          position: newPosition,
          modified: dataServices.serverTimestamp(),
        })
        configs[i].position = newPosition
      })
      await batch.commit()
    }

    setWidgetConfigs(configs)
  }

  useEffect(() => {
    if (userId && accountId) {
      loadRefData(userId, accountId)
    }
  }, [userId, accountId])

  const handleAddWidget = () => {
    const newWidgetConfig = {
      ...blankWidgetConfig,

      title: "<new>",
      account_id: accountId,
      user_id: userId,
      type: "job",
      created: dataServices.serverTimestamp(),
      modified: dataServices.serverTimestamp(),
      position: widgetConfigs.length + 1,
    }

    db.collection("dashboard")
      .add(newWidgetConfig)
      .then(async (docRef) => {
        const doc = await db.collection("dashboard").doc(docRef.id).get()

        setWidgetConfigs((curr) => [
          ...curr,
          { ...newWidgetConfig, id: docRef.id, modified: doc.data().modified },
        ])
        setAddedWidgetId(docRef.id)
      })
  }

  // Swap the positions of two widgets and update firestore

  const swapWidgetPositions = (widget1, widget2) => {
    const batch = db.batch()

    const docRef1 = db.collection("dashboard").doc(widget1.id)
    batch.update(docRef1, {
      position: widget2.position,
      modified: dataServices.serverTimestamp(),
    })

    const docRef2 = db.collection("dashboard").doc(widget2.id)
    batch.update(docRef2, {
      position: widget1.position,
      modified: dataServices.serverTimestamp(),
    })

    batch.commit().then(() => {
      const newWidgetConfigs = [...widgetConfigs]

      const swapValue = widget1.position
      widget1.position = widget2.position
      widget2.position = swapValue
      setWidgetConfigs(newWidgetConfigs.sort((a, b) => sortWidgets(a, b)))
      setSelectedWidget(widget1.position)
    })
  }

  const handleMoveWidgetLeft = () => {
    const widgetToMove = widgetConfigs.find(
      (w) => w.position === selectedWidget
    )
    if (widgetToMove.position === 1) return
    const otherWidget = widgetConfigs.find(
      (w) => w.position === widgetToMove.position - 1
    )
    swapWidgetPositions(widgetToMove, otherWidget)
  }

  const handleMoveWidgetRight = () => {
    const widgetToMove = widgetConfigs.find(
      (w) => w.position === selectedWidget
    )
    if (widgetToMove.position === widgetConfigs.length) return
    const otherWidget = widgetConfigs.find(
      (w) => w.position === widgetToMove.position + 1
    )
    swapWidgetPositions(widgetToMove, otherWidget)
  }

  const handleRemoveWidget = (widgetId) => {
    const newWidgets = widgetConfigs.filter((w) => w.id !== widgetId)
    setWidgetConfigs(newWidgets)
  }

  const handleCreateJob = () => {
    history.push("/JobEdit")
  }

  return (
    <>
      {widgetConfigs && widgetConfigs.length > 0 && (
        <Stack
          direction="row"
          gap={1}
          sx={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <IconButton
            disabled={selectedWidget === undefined}
            onClick={handleMoveWidgetLeft}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            disabled={selectedWidget === undefined}
            onClick={handleMoveWidgetRight}
          >
            <ChevronRightIcon />
          </IconButton>
          <Tooltip title="Create job">
            <IconButton onClick={handleCreateJob}>
              <icons.JobIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      {centres && jobTypes && locations && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          {widgetConfigs.map((widgetConfig) => (
            <DashboardWidget
              key={widgetConfig.id}
              initialWidgetConfig={widgetConfig}
              centres={centres}
              suppliers={suppliers}
              jobTypes={jobTypes}
              locations={locations}
              priorities={priorities}
              userCentreIds={userCentreIds}
              selectedWidget={selectedWidget}
              setSelectedWidget={setSelectedWidget}
              removeWidget={handleRemoveWidget}
              addedWidgetId={addedWidgetId}
              blankWidgetConfig={blankWidgetConfig}
            />
          ))}
        </Box>
      )}
      {accountType === "centre" && (
        <Fab
          color="primary"
          sx={styles.fab}
          onClick={handleAddWidget}
          size="small"
        >
          <AddIcon />
        </Fab>
      )}
    </>
  )
}

export default DashboardWidgets
