import React from "react"
import { Box } from "@mui/material"
import JobPrintForm from "../components/JobPrintForm"

const JobPrint = (props) => {
  return (
    <Box padding={0}>
      <JobPrintForm {...props} />
    </Box>
  )
}

export default JobPrint
