import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material"
import React, { useMemo } from "react"
import * as UIConstants from "./UIConstants"
import { getErrorProps } from "./FieldValidation"

export default function Select({
  name,
  label,
  value,
  onChange,
  options,
  variant = UIConstants.STANDARD_INPUT_VARIANT,
  disabled = false,
  // Should a 'None' option be provided
  isNoneOption = true,
  noneValue = "",
  noneLabel = "None",
  showError = false,
  errorFuncs = [],
  ...other
}) {
  const valueExists = useMemo(() => {
    const result = Boolean(
      (isNoneOption && value === noneValue) ||
        options.find((option) => option.id === value)
    )

    return result
  }, [isNoneOption, noneValue, options, value]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyPress = (event) => {
    const currentIndex = options.findIndex((option) => option.id === value)

    const remainingOptions =
      currentIndex === -1 ? options : options.slice(currentIndex + 1)

    // Find current selected value, and search beyond that for next matching value
    let item = remainingOptions.find(
      (option) =>
        option.title.charAt(0).toLowerCase() === event.key.toLowerCase()
    )

    if (!item) {
      item = options.find(
        (option) =>
          option.title.charAt(0).toLowerCase() === event.key.toLowerCase()
      )
    }

    if (item) {
      onChange({ target: { name: name, value: item.id } })
    }
  }

  const errorProps = useMemo(() => {
    if (showError && errorFuncs) {
      return getErrorProps({ value, showError, errorFuncs })
    }
  }, [showError, errorFuncs, value])

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <InputLabel htmlFor={`${name}-label`}>{label}</InputLabel>
      <MuiSelect
        labelId={`${name}-label}`}
        name={name}
        id={name}
        label={label}
        size={UIConstants.STANDARD_INPUT_SIZE}
        value={value === undefined ? "" : valueExists ? value : ""}
        onChange={onChange}
        onKeyPress={(event) => handleKeyPress(event)}
        autoWidth={true}
        disabled={disabled}
        error={errorProps ? errorProps.error : false}
        {...other}
      >
        {isNoneOption && (
          <MenuItem key={noneValue} value={noneValue}>
            {noneLabel}
          </MenuItem>
        )}

        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {errorProps && (
        <FormHelperText error={errorProps.error}>
          {errorProps.helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
