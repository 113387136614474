import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { useMemo, useState } from "react"
import Controls from "./controls/Controls"
import * as dateServices from "../pages/services/dateServices"
import ReactTimeAgo from "react-time-ago"
import * as colors from "@mui/material/colors"
import { MoreVertIcon } from "../icons"
import DeleteIcon from "@mui/icons-material/Delete"
import ReplyIcon from "@mui/icons-material/Reply"
import EditIcon from "@mui/icons-material/Edit"
import NavButtons from "./NavButtons"
import AddComment from "./AddComment"

const ActionComment = ({
  comment,
  userEmail,
  handleAddComment,
  handleUpdateComment,
  handleDeleteComment,
  nesting,
  comments,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const [showReply, setShowReply] = useState(false)

  const [editCommentId, setEditCommentId] = useState(null)

  const [editedComment, setEditedComment] = useState("")

  const isMyComment = useMemo(() => {
    return userEmail && userEmail === comment.created_by
  }, [userEmail, comment])

  const paperStyle = useMemo(() => {
    return isMyComment
      ? { p: "5px" }
      : {
          backgroundColor: colors.lightBlue[50],
          border: `1px solid ${colors.lightBlue[100]}`,
          p: "5px",
        }
  }, [isMyComment])

  return (
    <>
      <Paper sx={paperStyle} square={false} elevation={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: `${nesting * 5}px`,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {editCommentId && editCommentId === comment.id && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Controls.TextInput
                    name="comment"
                    label="Edit message"
                    value={editedComment}
                    onChange={(e) => setEditedComment(e.target.value)}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "auto",
                      marginBottom: "15px",
                      gap: "10px",
                    }}
                  >
                    <NavButtons>
                      <Controls.Button
                        text="Cancel"
                        size="small"
                        variant="outlined"
                        onClick={() => setEditCommentId(null)}
                      />
                      <Controls.Button
                        text="Save"
                        size="small"
                        onClick={() => {
                          handleUpdateComment(comment.id, editedComment)
                          setEditCommentId(null)
                        }}
                      />
                    </NavButtons>
                  </Box>
                </Box>
              )}
              {!editCommentId && (
                <Typography
                  align={isMyComment ? "right" : "left"}
                  variant="body2"
                >
                  {comment.comment}
                </Typography>
              )}
              <CreatedInfo
                createdBy={comment.created_by}
                created={comment.created}
              />
            </Box>

            <Box sx={{ marginLeft: "auto", width: "40px" }}>
              <IconButton
                size="small"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box>
          {showReply && !editCommentId && (
            <AddComment
              handleAddComment={handleAddComment}
              parentCommentId={comment.id}
              parentAccountId={comment.account_id}
              label="Add a reply..."
              buttonLabel="Save Reply"
              showCancel={true}
              setShowReply={setShowReply}
            />
          )}
        </Box>
        <Box>
          {comments
            .filter((c) => c.parent_id === comment.id)
            .map((c) => (
              <ActionComment
                key={c.id}
                comment={c}
                userEmail={userEmail}
                handleAddComment={handleAddComment}
                handleUpdateComment={handleUpdateComment}
                handleDeleteComment={handleDeleteComment}
                nesting={nesting + 1}
                comments={comments}
              />
            ))}
        </Box>
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {isMyComment && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              setEditCommentId(comment.id)
              setEditedComment(comment.comment)
            }}
          >
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>
        )}
        {isMyComment && (
          <MenuItem
            onClick={() => {
              handleDeleteComment(comment.id)
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
        )}
        <MenuItem onClick={() => setShowReply(true)}>
          <ListItemIcon>
            <ReplyIcon fontSize="small" />
          </ListItemIcon>
          Reply
        </MenuItem>
      </Menu>
    </>
  )
}

export default ActionComment

const CreatedInfo = ({ createdBy, created }) => {
  const createdDate = useMemo(() => {
    if (created) {
      return created
    }
  }, [created])

  return (
    <Tooltip title="Created">
      <Stack direction="row" gap={1}>
        <Box sx={{ color: colors.blueGrey[300] }}>
          <Typography variant="caption">{createdBy} </Typography>
        </Box>
        <Box sx={{ color: colors.blueGrey[300] }}>
          <Typography variant="caption">
            {createdDate && (
              <ReactTimeAgo
                date={dateServices.parseDate(createdDate)}
                locale="en-AU"
              />
            )}
          </Typography>
        </Box>
      </Stack>
    </Tooltip>
  )
}
