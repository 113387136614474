import React, { useEffect, useState, createRef } from "react"
import db from "../Firestore"
import { Box, Paper, Typography } from "@mui/material"
import * as dataServices from "../pages/services/dataServices"
import ReactTimeAgo from "react-time-ago"
import { Masonry } from "@mui/lab"
import * as moment from "moment"
import LinkButton from "./controls/LinkButton"
import _ from "lodash"
import Controls from "./controls/Controls"
import * as icons from "../icons"
import { spacing } from "../pages/services/styleServices"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const styles = {
    createdBy: {
        fontWeight: "bold",
        marginRight: spacing(1),
    },
    jobInfo: {
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        marginBottom: spacing(1),
        marginLeft: spacing(1),
    },
    jobLabel: {
        display: "flex",
        flexDirection: "row",
        gap: spacing(1),
        alignItems: "center",
        marginLeft: spacing(1),
    },
}

const CommentGrid = (props) => {
    const [accountId, setAccountId] = useState(undefined)

    const [accountType, setAccountType] = useState(undefined)

    const [isSupplierViewing, setSupplierViewing] = useState(undefined)

    const [supplierAccountIds, setSupplierAccountIds] = useState([])

    const [suppliers, setSuppliers] = useState([])

    const [comments, setComments] = useState([])

    const [centres, setCentres] = useState([])

    const [jobSort, setJobSort] = useState([])

    const [jobs, setJobs] = useState([])

    useEffect(() => {
        const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
            user.getIdTokenResult().then((token) => {

                const isSupplier = token.claims.account_type === "supplier"
                setSupplierViewing(isSupplier)
                if (isSupplier) {
                    setSupplierAccountIds(token.claims.supplier_account_ids)
                }

                setAccountId(token.claims.account_id)
                setAccountType(token.claims.account_type)
            })
        })
        return unsub
    }, [])

    useEffect(() => {
        if (accountId === undefined) return
        if (isSupplierViewing && supplierAccountIds.length === 0) return

        const last60Days = dataServices.timestampFromDate(
            moment(Date.now()).add(-90, "days").toDate()
        )

        let query

        if (isSupplierViewing) {
            query = db
                .collection("comments")
                .where("account_id", "in", supplierAccountIds)
                .where("supplier_account_id", "==", accountId)
                .where("type", "==", "job")
                .where("created", ">", last60Days)

        
        } else {
            query = db
                .collection("comments")
                .where("account_id", "==", accountId)
                .where("type", "==", "job")
                .where("created", ">", last60Days)
        }

        const unsub = query.onSnapshot(
            (querySnapshot) => {
                let newComments = [...comments]

                const removeCommentIds = []

                const modifiedComments = []

                const newJobIds = []

                querySnapshot.docChanges().forEach((change) => {
                    const comment = change.doc.data()

                    if (change.type === "added") {
                        newComments = [...newComments, { ...comment, id: change.doc.id }]
                        if (!jobs.find((j) => j.id === comment.parent_id)) {
                            newJobIds.push(comment.parent_id)
                        }
                    }

                    if (change.type === "modified") {
                        modifiedComments.push({ ...comment, id: change.doc.id })
                    }

                    if (change.type === "removed") {
                        removeCommentIds.push(change.doc.id)
                    }
                })

                const newJobSort = []

                setComments((comments) => {
                    const newCommentsVal = [...comments, ...newComments]
                        .filter((c) => !removeCommentIds.includes(c.id))
                        .filter((c) => !modifiedComments.find((mc) => mc.id === c.id))

                    newCommentsVal.push(...modifiedComments)

                    // Work out how to sort jobs, by most recent comment

                    const commentsByJob = _.groupBy(newCommentsVal, "parent_id")

                    const jobWithMaxComment = Object.values(commentsByJob).map((c) => {
                        return c.reduce((prev, current) => {
                            return prev.created > current.created ? prev : current
                        })
                    })

                    const jobsByCommentCreated = jobWithMaxComment.sort((a, b) => {
                        return b.created - a.created
                    })

                    newJobSort.push(...jobsByCommentCreated.map((j) => j.parent_id))

                    return newCommentsVal.sort((a, b) => b.created.seconds - a.created.seconds)
                })

                dataServices
                    .getJobsByIdChunks({ accountId, accountType, jobIds: newJobIds })
                    .then(async (newJobs) => {
                        setJobs((jobs) => {
                            const newJobsVal = _.uniqBy([...jobs, ...newJobs], "id")
                            return newJobsVal
                        })

                        setJobSort(newJobSort)

                        const supplierIds = Array.from(new Set(newJobs.map((j) => j.supplier_id)))
                        const newSupplierIds = supplierIds.filter(
                            (id) => !suppliers.find((s) => s.id === id)
                        )

                        const suppliersResult = await dataServices.getSuppliersByIdChunks(
                            newSupplierIds,
                            accountId
                        )

                        setSuppliers((suppliers) => {
                            return [...suppliers, ...suppliersResult]
                        })

                        const centreIds = Array.from(new Set(newJobs.map((j) => j.centre_id)))
                        const newCentreIds = centreIds.filter(
                            (id) => !centres.find((c) => c.id === id)
                        )

                        const centresResult = await dataServices.getCentresByIdChunks(newCentreIds)

                        setCentres((centres) => {
                            return [...centres, ...centresResult]
                        })
                    })

                console.groupEnd()
            },
            (error) => console.error("error listening for comment changes", error)
        )

        return unsub
    }, [accountId, accountType, isSupplierViewing, supplierAccountIds])

    return (
        <>
            <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
                {jobs &&
                    jobSort &&
                    jobs
                        .sort((a, b) => {
                            return jobSort.indexOf(a.id) - jobSort.indexOf(b.id)
                        })
                        .map((job) => (
                            <JobComments
                                key={job.id}
                                accountId={job.account_id}
                                supplierId={job.supplier_id}
                                job={job}
                                suppliers={suppliers}
                                comments={comments}
                                centres={centres}
                            />
                        ))}
            </Masonry>
        </>
    )
}

const JobComments = (props) => {
    const { job, suppliers, comments, centres, accountId, supplierId } = props

    const [comment, setComment] = useState({ comment: "" })

    const commentRef = createRef()

    // Does the comment field have focus?
    const [commentFocus, setCommentFocus] = useState(false)

    const handleAddComment = async (commentText) => {
        await dataServices.addCommentToJob({
            comment,
            supplierId,
            accountId,
            jobId: job.id,
            email: getAuth().currentUser.email,
        })

        clearComment()
    }

    const clearComment = () => {
        setComment({ comment: "" })
    }

    return (
        <Paper>
            <Box sx={styles.jobLabel}>
                <icons.JobIcon />
                <LinkButton to={`/jobedit/${job.id}`}>{job.label}</LinkButton>
            </Box>

            <Box sx={styles.jobInfo}>
                <Typography variant="caption">
                    {(suppliers && suppliers.find((s) => s.id === job.supplier_id)?.name) ||
                        "Loading..."}
                </Typography>
                <Typography variant="caption">
                    {(centres && centres.find((c) => c.id === job.centre_id)?.name) || "Loading..."}
                </Typography>
                <Typography variant="caption">{job.location}</Typography>
                <Typography variant="caption">{job.category}</Typography>
            </Box>
            {comments &&
                comments
                    .filter((c) => c.parent_id === job.id)
                    .map((comment) => (
                        <Box key={comment.id} sx={{ marginBottom: "10px", marginLeft: "5px" }}>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Box>
                                    <Typography variant="caption" sx={styles.createdBy}>
                                        {comment.created_by}
                                    </Typography>
                                </Box>
                                {comment.created && (
                                    <Typography variant="caption" color="#bbb">
                                        <ReactTimeAgo
                                            date={comment.created.toDate()}
                                            locale="en-AU"
                                        />
                                    </Typography>
                                )}
                            </Box>
                            <Box>
                                <Typography variant="body2">{comment.comment}</Typography>
                            </Box>
                        </Box>
                    ))}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", margin: "10px" }}>
                <Box>
                    <Controls.TextInput
                        name="comment"
                        label={(comment && comment.id && "Edit Comment") || "Add Comment"}
                        inputRef={commentRef}
                        multiline={true}
                        value={comment.comment}
                        onChange={(event) => {
                            if (commentFocus) {
                                const newComment = {
                                    ...comment,
                                    comment: event.target.value,
                                }
                                setComment(newComment)
                            }
                        }}
                        onFocus={() => setCommentFocus(true)}
                    />
                </Box>

                <Box>
                    {commentFocus && (
                        <>
                            <Controls.Button
                                text={(comment && comment.id && "Save") || "Add Comment"}
                                onClick={() => {
                                    handleAddComment(comment)
                                    setCommentFocus(false)
                                }}
                                disabled={comment === ""}
                            />
                            <Controls.Button
                                text="Cancel"
                                onClick={() => {
                                    setCommentFocus(false)
                                    clearComment()
                                }}
                            />
                        </>
                    )}
                </Box>
            </Box>
        </Paper>
    )
}

export default CommentGrid
