import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material"
import { useState } from "react"
import TruncatedText from "./TruncatedText"
import LabelValue from "./LabelValue"
import Controls from "./controls/Controls"
import WorkOrderMenu from "./WorkOrderMenu"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { format } from "date-fns"

const WorkOrderTile = ({
  workOrder,
  isEditable,
  suppliers,
  users,
  isSupplierViewing,
  handleCloseWorkOrder,
  handleOpenWorkOrder,
  handleCompleteWorkOrder,
  handleEditWorkOrder,
}) => {
  const [anchorEl, setAnchorEl] = useState()

  const menuId = `menu-${workOrder.id}`

  const handleToggleMenu = (event) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const supplierName = suppliers.find(
    (s) => s.id === workOrder.supplier_id
  )?.name

  const maintUserName = users.find(
    (u) => u.id === workOrder.maint_user_id
  )?.name

  return (
    <>
      <Card sx={{ width: "300px" }}>
        <CardHeader
          title={
            <TruncatedText
              variant="body1"
              noWrap
              sx={{
                fontWeight: "bold",
                marginBottom: "5px",
                maxWidth: "240px",
              }}
            >
              {workOrder.label}
            </TruncatedText>
          }
          subheader={<Controls.StatusChip status={workOrder.status} />}
          action={
            <IconButton aria-label="settings" onClick={handleToggleMenu}>
              <MoreVertIcon />
            </IconButton>
          }
        />
        <CardActionArea onClick={() => handleEditWorkOrder()}>
          <CardContent sx={{ padding: "15px" }}>
            <LabelValue label="Work Order No" value={workOrder.work_order_no} />
            {isSupplierViewing !== undefined &&
              isSupplierViewing === false &&
              supplierName && (
                <LabelValue
                  label="Supplier"
                  value={
                    <TruncatedText variant="body2" sx={{ maxWidth: "150px" }}>
                      {supplierName}
                    </TruncatedText>
                  }
                />
              )}
            {isSupplierViewing !== undefined &&
              isSupplierViewing === false &&
              maintUserName && (
                <LabelValue label="Allocated To" value={maintUserName} />
              )}
            <LabelValue
              label="Start Date"
              value={format(workOrder.start_date.toDate(), "dd MMM yy")}
            />
          </CardContent>
        </CardActionArea>
        {anchorEl && (
          <WorkOrderMenu
            workOrder={workOrder}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            menuId={menuId}
            isEditable={isEditable}
            isSupplierViewing={isSupplierViewing}
            handleCloseWorkOrder={() => {
              handleCloseWorkOrder(workOrder.id)
              setAnchorEl(null)
            }}
            handleOpenWorkOrder={() => {
              handleOpenWorkOrder(workOrder.id)
              setAnchorEl(null)
            }}
            handleCompleteWorkOrder={() => {
              handleCompleteWorkOrder(workOrder.id)
              setAnchorEl(null)
            }}
            handleEditWorkOrder={() => {
              handleEditWorkOrder()
              setAnchorEl(null)
            }}
          />
        )}
      </Card>
    </>
  )
}

export default WorkOrderTile
