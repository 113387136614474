import React, { useEffect, useState } from "react"
import * as dataServices from "../pages/services/dataServices"
import { Grid } from "@mui/material"
import Controls from "./controls/Controls"
import { useHistory } from "react-router-dom"
import { Form } from "./useForm"
import { FormLabel } from "@mui/material"
import { Typography } from "@mui/material"
import AssignmentIcon from "@mui/icons-material/Assignment"
import { spacing } from "../pages/services/styleServices"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const styles = {
  buttons: {
    marginTop: spacing(2),
  },
  paddedLabel: {
    paddingLeft: "5px",
  },
  statusChip: {
    marginBottom: spacing(2),
    paddingLeft: spacing(1),
  },
}

const WorkOrderHeader = (props) => {
  const [values, setValues] = useState()

  const { id } = props

  const history = useHistory()

  const [accountId, setAccountId] = useState()

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult().then((token) => {
        setAccountId(token.claims.account_id)
      })
    })

    return unsub
  }, [])

  // Load work order

  useEffect(() => {
    if (accountId) {
      dataServices
        .getWorkOrdersById({ workOrderIds: [id], accountId })
        .then((workOrders) => {
          const workOrder = workOrders[0]

          setValues(workOrder)
        })
    }
  }, [id, accountId])

  const handleCancel = (event) => {
    event.preventDefault()
    history.goBack()
  }

  return (
    <>
      {values && (
        <Form>
          <Grid container direction="column">
            <Grid item>
              <Controls.TextInput
                name="label"
                label="Work Order"
                value={values.label}
                disabled
                icon={<AssignmentIcon />}
              />
            </Grid>

            <Grid item>
              <FormLabel sx={styles.paddedLabel}>
                <Typography variant="caption" gutterBottom={true}>
                  Status
                </Typography>
              </FormLabel>
            </Grid>
            <Grid item sx={styles.statusChip}>
              <Controls.StatusChip status={values.status} />
            </Grid>

            <Grid item>
              <Controls.TextInput
                name="work_order_no"
                label="Ref No"
                value={values.work_order_no}
                disabled
              />
            </Grid>

            {values.notes !== "" && (
              <Grid item>
                <Controls.TextInput
                  name="notes"
                  label="Notes"
                  multiline={true}
                  value={values.notes}
                  disabled
                />
              </Grid>
            )}
          </Grid>
        </Form>
      )}

      {props.children}

      <Grid item sx={styles.buttons}>
        <Controls.Button text="Cancel" onClick={handleCancel} />
      </Grid>
    </>
  )
}

export default WorkOrderHeader
