import React, { useMemo } from "react"
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
} from "@mui/material"
import TruncatedText from "./TruncatedText"
import LabelValue from "./LabelValue"
import CentreName from "./CentreName"
import { format } from "date-fns"
import CheckListRating from "./CheckListRating"
import ViewListIcon from "@mui/icons-material/ViewList"
import { green } from "@mui/material/colors"

const CheckListInstanceTile = ({
  checkListInstance,
  centres,
  users,
  handleEditCheckList,
}) => {
  const closeMenuAndExecute = (callback) => {
    callback(checkListInstance)
  }

  const centre = useMemo(() => {
    return centres?.find((c) => c.id === checkListInstance.centre_id)
  }, [checkListInstance, centres])

  const user = useMemo(() => {
    return users?.find((u) => u.id === checkListInstance.user_id)
  }, [checkListInstance, users])

  return (
    <>
      <Card sx={{ width: "300px" }}>
        <CardHeader
          title={
            <TruncatedText
              variant="body1"
              noWrap
              sx={{
                fontWeight: "bold",
                marginBottom: "5px",
                maxWidth: "240px",
              }}
            >
              {checkListInstance.check_list_name}
            </TruncatedText>
          }
          avatar={
            <Avatar
              sx={
                checkListInstance.completed && { backgroundColor: green[300] }
              }
            >
              <ViewListIcon />
            </Avatar>
          }
          sx={{ alignItems: "flex-start" }}
          subheader={<CheckListRating values={checkListInstance} />}
        />
        <CardActionArea
          onClick={() => closeMenuAndExecute(handleEditCheckList)}
        >
          <CardContent sx={{ padding: "15px" }}>
            <Stack gap={0.5}>
              <LabelValue
                label="Centre"
                value={
                  <CentreName
                    truncateWidth="100px"
                    centre={centre}
                    textAlign="right"
                  />
                }
              />
              <LabelValue
                label="Created"
                value={
                  checkListInstance.created &&
                  format(checkListInstance.created.toDate(), "dd/MM/yy")
                }
              />
              <LabelValue
                label="User"
                value={
                  <TruncatedText
                    variant="body2"
                    sx={{ width: "180px" }}
                    textAlign={"right"}
                  >
                    {user?.email}
                  </TruncatedText>
                }
              />
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

export default CheckListInstanceTile
