import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { lighten } from "@mui/material/styles"
import { styled } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import AssignmentIcon from "@mui/icons-material/Assignment"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import {
  Checkbox,
  Tooltip,
  Box,
  Typography,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  Chip,
  ListItemIcon,
  Menu,
  MenuItem,
  Autocomplete,
  TextField,
  Stack,
  Divider,
  colors,
} from "@mui/material"
import LinkButton from "./controls/LinkButton"
import firebase from "firebase/compat/app"
import { useSnackbar } from "notistack"
import { useHistory } from "react-router-dom"
import * as UIConstants from "./controls/UIConstants"
import { useDispatch, useSelector } from "react-redux"
import { selectJobGridPagination } from "../redux/selectors"
import { setJobGridPagination } from "../redux/actions"
import { selectJobGridPageDocs } from "../redux/selectors"
import { setJobGridPageDocs } from "../redux/actions"
import { selectJobGridSelectedItems } from "../redux/selectors"
import { setJobGridSelectedItems } from "../redux/actions"
import Controls from "./controls/Controls"
import * as Roles from "../pages/services/roleServices"
import _ from "lodash"
import * as icons from "../icons"
import * as jobServices from "../pages/services/jobServices"
import AllocateJobsDialog from "./AllocateJobsDialog"
import DoneIcon from "@mui/icons-material/Done"
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService"
import SearchIcon from "@mui/icons-material/Search"
import YesNo from "./YesNo"
import { spacing } from "../pages/services/styleServices"
import * as actionServices from "../pages/services/actionServices"
import JobStatusChip from "./controls/JobStatusChip"
import MultiSelectLabel from "./controls/MultiSelectLabel"
import Priority from "./Priority"
import PageNo from "./PageNo"
import NavButtons from "./NavButtons"
import Select from "./controls/Select"
import { J4J_YELLOW, JOB_TYPES } from "../pages/services/colorServices"
import * as workOrderServices from "../pages/services/workOrderServices"
import { Desktop, Mobile } from "./WindowSizes"
import JobTile from "./JobTile"
import useMyAccountPriorities from "./useMyAccountPriorities"
import TruncatedText from "./TruncatedText"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import {
  getCountFromServer,
  collection,
  query,
  orderBy,
  startAfter,
  startAt,
  limit,
  getDocs,
} from "firebase/firestore"
import useDocumentPagination from "./useDocumentPagination"
import FilterItems from "./FilterItems"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const headCells = [
  {
    id: "label",
    numeric: false,
    disablePadding: true,
    label: "Job",
    sortable: false,
  },
  {
    id: "centre_id",
    numeric: false,
    disablePadding: true,
    label: "Centre",
    sortable: false,
  },
  {
    id: "category",
    numeric: false,
    disablePadding: true,
    label: "Category",
    sortable: false,
    //direction: ["asc"],
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: true,
    label: "Priority",
    sortable: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sortable: false,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: true,
    label: "Location",
    sortable: false,
  },
  {
    id: "work_order",
    numeric: false,
    disablePadding: true,
    label: "Work Order",
    sortable: false,
  },
  {
    id: "supplier_id",
    numeric: false,
    disablePadding: true,
    label: "Supplier",
    sortable: false,
  },
  {
    id: "user_id",
    numeric: false,
    disablePadding: true,
    label: "Job Owner",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: true,
    label: "Created",
    sortable: true,
    direction: ["desc", "asc"],
  },
]

const StyledToolbar = styled(Toolbar)(({ numselected, theme }) => {
  const result = {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  }

  if (numselected > 0) {
    result.color = theme.palette.secondary.main
    result.backgroundColor = lighten(theme.palette.secondary.light, 0.85)
  }

  return result
})

const JobGrid = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    selected,
    jobs,
    rowCount,
    onRequestSort,
    userAccountType,
  } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const jobsSelectedCount = jobs.filter((item) =>
    selected.find((s) => item.id === s.id)
  ).length
  const allJobsOnPageSelected = jobsSelectedCount === rowCount && rowCount > 0
  const someJobsOnPageSelected =
    jobsSelectedCount > 0 && jobsSelectedCount < rowCount

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            //indeterminate={numSelected > 0 && numSelected < rowCount}
            indeterminate={someJobsOnPageSelected || rowCount === 0}
            //checked={rowCount > 0 && numSelected === rowCount}
            checked={allJobsOnPageSelected}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all jobs" }}
            sx={{ color: J4J_YELLOW }}
          />
        </TableCell>
        {headCells
          .filter(
            (headCell) =>
              userAccountType === Roles.ACCOUNT_TYPE_CENTRE ||
              (userAccountType === Roles.ACCOUNT_TYPE_SUPPLIER &&
                !["supplier_id", "user_id"].includes(headCell.id))
          )
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  active={headCell.sortable && orderBy === headCell.id}
                  direction={
                    orderBy === headCell.id ? order : headCell.direction[0]
                  }
                  onClick={createSortHandler(headCell.id)}
                >
                  <b>{headCell.label}</b>
                </TableSortLabel>
              ) : (
                <b>{headCell.label}</b>
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  )
}

JobGrid.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const toolbarStyles = {
  jobChip: {
    marginLeft: "3px",
    marginRight: "3px",
    marginTop: "2px",
    marginBottom: "6px",
  },
  title: {
    flex: "1 1 100%",
  },
}

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    selected,
    setSelected,
    jobs,
    accountId,
    accountType,
    dispatch,
    setJobGridSelectedItems,
    setReloadTrigger,
  } = props

  const { enqueueSnackbar } = useSnackbar()

  const [allocateDialogOpen, setAllocateDialogOpen] = useState(false)

  const [yesNoCloseJobConfig, setYesNoCloseJobConfig] = useState({
    openPrompt: false,
    description: "Close selected job(s)",
    title: "Close",
    handleConfirm: null,
  })

  const [yesNoDeleteJobsConfig, setYesNoDeleteJobsConfig] = useState({
    openPrompt: false,
    description: "Delete selected job(s)",
    title: "Delete",
    handleConfirm: null,
  })

  const handleDeleteChip = (jobId) => {
    const newSelected = selected.filter((item) => item.id !== jobId)
    setSelected(newSelected)
    dispatch(setJobGridSelectedItems(newSelected))
  }

  const handleAllocated = () => {
    setAllocateDialogOpen(false)

    // deselect jobs that were selected
    dispatch(setJobGridSelectedItems([]))
    setSelected([])

    // Trigger a reload of the jobs
    setReloadTrigger((curr) => curr + 1)
  }

  const getSelectedJobs = async () => {
    // 'jobs' will only contain the jobs on the current page
    const foundJobs = jobs.filter((item) =>
      selected.find((s) => item.id === s.id)
    )

    // 'selected' will contain all the jobs selected across all pages
    const missingJobs = selected.filter(
      (item) => !foundJobs.find((s) => item.id === s.id)
    )

    // Load jobs not on the current page
    const loadedJobs = await dataServices.getJobsByIdChunks({
      accountId,
      accountType,
      jobIds: missingJobs.map((item) => item.id),
    })

    // Merge the jobs on the current page with the loaded jobs
    const selectedJobs = [...foundJobs, ...loadedJobs]

    return selectedJobs
  }

  const handlePromptCloseJobs = async () => {
    const newConfig = {
      ...yesNoCloseJobConfig,
      openPrompt: true,
      handleConfirm: () => handleCloseJobs(),
    }

    setYesNoCloseJobConfig(newConfig)
  }

  const handleCloseJobs = async () => {
    const jobsToClose = await getSelectedJobs()

    for (var i = 0; i < jobsToClose.length; i++) {
      const job = jobsToClose[i]

      dataServices.changeJobStatus(
        job.id,
        job.account_id,
        job.work_order_id,
        jobServices.JOB_STATUS_CLOSED
      )
    }

    // Remove items that were processed from being selected
    setSelected([])

    setReloadTrigger((curr) => curr + 1)
  }

  const handlePromptDeleteJobs = async () => {
    // See if any jobs are attached to a work order

    const selectedJobs = await getSelectedJobs()

    const jobsWithWorkOrders = selectedJobs.filter((job) => job.work_order_id)

    if (jobsWithWorkOrders.length > 0) {
      enqueueSnackbar("Cannot delete jobs that are attached to a work order", {
        variant: "info",
      })
      return
    }

    const newConfig = {
      ...yesNoDeleteJobsConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteJobs(),
    }

    setYesNoDeleteJobsConfig(newConfig)
  }

  const handleDeleteJobs = async () => {
    const selectedJobs = await getSelectedJobs()

    const jobIds = selectedJobs.map((job) => job.id)

    const resultMessage = await dataServices.deleteJobs({
      accountId,
      jobIds,
      accountType,
    })

    if (resultMessage?.message) {
      enqueueSnackbar(resultMessage.message, {
        variant: resultMessage.severity,
      })
    }

    if (resultMessage.severity === "success") {
      setReloadTrigger((curr) => curr + 1)
    }

    // Remove items that were processed from being selected
    setSelected([])
  }

  const handleAllocateJobs = async () => {
    // See if all jobs selected are:
    // - not assigned to a supplier
    // - not assigned to a centre
    // - current user is a job manager
    // - has a status that can be assigned, e.g. 'open'

    //TODO: need to do this in batches of 10, since firestore only allows 'IN' clause to refer to max 10 ids

    const selectedJobs = await getSelectedJobs()

    let ok = true

    if (ok) {
      const jobWithNoCentre = selectedJobs.find(
        (job) => !job.hasOwnProperty("centre_id") || job.centre_id === ""
      )
      if (jobWithNoCentre) {
        enqueueSnackbar(
          `Jobs '${jobWithNoCentre.label}' needs a centre allocated`,
          {
            variant: "info",
          }
        )
        ok = false
      }
    }

    if (ok) {
      // Make sure all selected jobs either have no supplier, or all assigned to the same supplier.
      // Actually assigning the jobs to a work order will set the supplier of any jobs that do not have a supplier
      // const supplierIds = Array.from(
      //     new Set(
      //         selectedJobs
      //             .filter((job) => job.supplier_id !== "" && job.supplier_id !== null)
      //             .map((job) => job.supplier_id)
      //     )
      // )

      setAllocateDialogOpen(true)
    }
  }

  return (
    <>
      <StyledToolbar numselected={numSelected}>
        {numSelected > 0 ? (
          <Typography
            sx={toolbarStyles.title}
            color="inherit"
            variant="subtitle1"
            component={"span"}
          >
            {numSelected} selected
            {selected.map((item) => (
              <Chip
                sx={toolbarStyles.jobChip}
                label={item.label}
                onDelete={() => handleDeleteChip(item.id)}
                color="primary"
                key={item.id}
              />
            ))}
          </Typography>
        ) : (
          <Typography
            sx={toolbarStyles.title}
            variant="h6"
            id="tableTitle"
            component={"span"}
          >
            Jobs
          </Typography>
        )}

        <YesNo config={yesNoCloseJobConfig} />

        <YesNo config={yesNoDeleteJobsConfig} />

        {numSelected > 0 && accountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title="Delete Jobs">
              <IconButton
                aria-label="Delete Jobs"
                onClick={handlePromptDeleteJobs}
                size="large"
              >
                <icons.DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Allocate Jobs">
              <IconButton
                aria-label="Allocate Jobs"
                onClick={handleAllocateJobs}
                size="large"
              >
                <AssignmentIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close Jobs">
              <IconButton
                aria-label="Close Jobs"
                onClick={handlePromptCloseJobs}
                size="large"
              >
                <icons.JobCloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </StyledToolbar>

      {allocateDialogOpen && (
        <AllocateJobsDialog
          open={allocateDialogOpen}
          handleAllocated={handleAllocated}
          handleClose={() => setAllocateDialogOpen(false)}
          jobsToAllocate={selected}
        />
      )}
    </>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: spacing(2),
  },
  table: {
    minWidth: 750,
  },
  filterGrid: {
    paddingLeft: "15px",
  },
  statusMenu: {
    marginLeft: spacing(0.5),
  },
  jobStatusFilter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "nowrap",
    gap: spacing(1),
  },
  tableRow: {
    "&:hover": {
      cursor: "hand",
    },
  },
  fab: {
    position: "fixed",
    bottom: 16,
    right: 16,
    top: "auto",
    left: "auto",
  },
}

export default function EnhancedTable() {
  const selectedJobItems = useSelector(selectJobGridSelectedItems)
  const [selected, setSelected] = React.useState(selectedJobItems)

  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = useState(true)

  const pag = useSelector(selectJobGridPagination)

  const pgDocs = useSelector(selectJobGridPageDocs)
  const {
    addPage,
    clearPageDocs,
    getFirstDocThisPage,
    getLastDocPreviousPage,
  } = useDocumentPagination(pgDocs)

  const dispatch = useDispatch()

  const history = useHistory()

  const [currentUser, setCurrentUser] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const [rowsPerPage, setRowsPerPage] = useState(20)

  const [jobCount, setJobCount] = useState()

  const [jobsActions, setJobsActions] = useState([])

  // A map of account id to priority lookup values
  // e.g. [ { account_id: '123', lookup_values: [{ id: 1, title: "P1", description: "Health & Safety"}] }
  const [prioritiesForAccounts, setPrioritiesForAccounts] = useState([])

  // Filter
  const [supplierId, setSupplierId] = useState(pag.supplier_id)

  // Filter
  const [centreId, setCentreId] = useState(pag.centre_id)

  // Autocomplete options
  const [jobTypeOptions, setJobTypeOptions] = useState([])

  // Filter
  const [jobTypesFilter, setJobTypesFilter] = useState(pag.job_types)

  // Filter
  const [jobAllocated, setJobAllocated] = useState(pag.job_allocated)

  // Filter
  const [textSearch, setTextSearch] = useState(pag.text_search)

  // Filter
  const [jobStatusFilter, setJobStatusFilter] = useState(pag.job_status_filter)

  // Filter
  const [priority, setPriority] = useState(pag.priority)

  // For selecting the next centre in the center combobox
  const [nextSelector, setNextSelector] = useState(0)

  // For selecting the previous centre in the center combobox
  const [prevSelector, setPrevSelector] = useState(0)

  // A way to force a reload of the jobs by incrementing this value
  const [reloadTrigger, setReloadTrigger] = useState(0)

  const [centres, setCentres] = useState([])

  const [suppliers, setSuppliers] = useState([])

  const [users, setUsers] = useState([])

  const [workOrders, setWorkOrders] = useState([])

  // Leave as undefined til actually known, so we don't issue a query and trigger a firestore permission error
  const [userAccountType, setUserAccountType] = useState()

  const [jobs, setJobs] = useState([])

  const COLLECTION_NAME = "jobs"

  const [jobStatusFilterAnchorEl, setJobStatusFilterAnchorEl] = useState(null)

  const [accountId, setAccountId] = useState()

  const myAccountPriorities = useMyAccountPriorities({ accountId })

  const [userRoles, setUserRoles] = useState([])

  const [userCentreIds, setUserCentreIds] = useState([])

  useEffect(() => {
    if (pag && userAccountType && currentUser) {
      if (!pag.initialized) {
        const isCentreUser = userAccountType === Roles.ACCOUNT_TYPE_CENTRE
        if (isCentreUser) {
          // Load user record
          db.collection("users")
            .doc(currentUser.claims.user_id)
            .get()
            .then((doc) => {
              const user = doc.data()

              const centreCount = user.centres.length

              // Rule: Default the user's job status filter to both OPEN + PENDING so long as they
              // do not have more than 1 centre -- to avoid the firebase limitation of disallowing
              // multiple 'in' clauses in a query

              const newJobStatusFilter =
                centreCount > 1
                  ? [jobServices.JOB_STATUS_PENDING]
                  : [
                      jobServices.JOB_STATUS_PENDING,
                      jobServices.JOB_STATUS_OPEN,
                    ]

              const updatedPagination = {
                ...pag,
                job_status_filter: newJobStatusFilter,

                // If the user has access to more than 1 centre then we need to use the 'in' clause
                // to limit jobs by centre. That means other filters, e.g. job types, cannot use the 'in'
                // clause and so must be limited to 1 selection
                job_types: centreCount > 1 ? [] : pag.job_types,

                // Set initialized to true so we only run this code section once.
                initialized: true,
              }

              setJobStatusFilter(newJobStatusFilter)

              dispatch(setJobGridPagination(updatedPagination))
            })
        } else {
          const updatedPagination = {
            ...pag,
            initialized: true,
          }

          dispatch(setJobGridPagination(updatedPagination))
        }
      }
    }
  }, [pag, userAccountType, currentUser])

  useEffect(() => {
    setPrioritiesForAccounts([myAccountPriorities])
  }, [myAccountPriorities])

  const updateUserCentreIdsIfChanged = (newUserCentreIds) => {
    if (!_.isEqual(newUserCentreIds.sort(), userCentreIds.sort())) {
      setUserCentreIds(newUserCentreIds)
    }
  }

  // If a new user logs into the same browser, we don't want the previous user's centre filter
  // remaining intact and allowing the user to see jobs they're not supposed to have access to.

  const shouldClearCentreFilter = (token) => {
    if (token && token.claims && token.claims.roles) {
      const isJobAdmin = token.claims.roles.includes(Roles.JOB_ADMIN)
      const isAdmin = token.claims.roles.includes(Roles.ADMIN)
      const isSystem = token.claims.system_role === true

      if (!isJobAdmin && !isAdmin && !isSystem) {
        // Clear the current centre filter if it is not in their allowed list of centres
        return (
          token.claims.centre_ids.length > 0 &&
          !token.claims.centre_ids.includes(pag.centre_id)
        )
      }
      return false
    }
    return false
  }

  const isJobStatusFilterPending = (jobStatusFilter) => {
    return jobStatusFilter.includes(jobServices.JOB_STATUS_PENDING)
  }

  const setDefaultSupplierJobStatusFilter = () => {
    const newJobStatusFilter = [jobServices.JOB_STATUS_OPEN]
    setJobStatusFilter(newJobStatusFilter)
    dispatch(
      setJobGridPagination({
        ...pag,
        job_status_filter: newJobStatusFilter,
      })
    )
  }

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          setCurrentUser(token)
          setUserCentreIds(token.claims.centre_ids)
          setUserAccountType(token.claims.account_type)
        })
      }

      return unsub
    })
  }, [])

  useEffect(() => {
    if (
      userAccountType === Roles.SUPPLIER &&
      isJobStatusFilterPending(jobStatusFilter)
    ) {
      setDefaultSupplierJobStatusFilter()
    }
  }, [jobStatusFilter, userAccountType])

  const [isShowJobStatusFilter, setShowJobStatusFilter] = useState(undefined)

  useEffect(() => {
    if (userAccountType === Roles.SUPPLIER) {
      if (isJobStatusFilterPending(jobStatusFilter)) {
        setShowJobStatusFilter(false)
      }
    } else {
      setShowJobStatusFilter(true)
    }
  }, [userAccountType, jobStatusFilter])

  // If the user has been limited to >1 centre then that will mean we have to use the 1 'in' clause
  // available to limit jobs by centre. That means other filters, e.g. job types, cannot use the 'in'
  // clause and so must be limited to 1 selection
  const isLimitJobTypeFilter = useMemo(() => {
    if (userCentreIds && jobStatusFilter) {
      return userCentreIds.length > 1 || jobStatusFilter.length > 1
    }
    return false
  }, [userCentreIds, jobStatusFilter])

  // TEMP - set a closed attribute to null if not closed attribute exists

  const jobsWithCentresAndJobOwners = useMemo(() => {
    if (jobs.length > 0 && centres.length > 0) {
      return jobs.map((job) => ({
        ...job,
        centre: centres.find((centre) => centre.id === job.centre_id),
        job_owner: users.find((user) => user.id === job.user_id),
      }))
    }
  }, [jobs, centres, users])

  useEffect(() => {
    const jobsToUpdate = jobs.filter((job) => job.closed === undefined)

    const batch = db.batch()

    jobsToUpdate.forEach((job) => {
      const jobRef = firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(job.id)
      batch.update(jobRef, { closed: null }, { merge: true })
    })

    batch.commit()
  }, [jobs])

  // TEMP - Set priority = null if priority === ""   -- must be numeric

  useEffect(() => {
    const jobsToUpdate = jobs.filter(
      (job) => job.priority === "" || job.priority === null
    )

    const batch = db.batch()

    jobsToUpdate.forEach((job) => {
      const jobRef = firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(job.id)
      batch.update(jobRef, { priority: 0 }, { merge: true })
    })

    batch.commit()
  }, [jobs])

  useEffect(() => {
    if (accountId) {
      db.collection("lookups")
        .where("account_id", "==", accountId)
        .where("name", "==", "job_types")
        .get()
        .then((querySnapshot) => {
          const options = querySnapshot.docs.flatMap(
            (doc) => doc.data().lookup_values
          )
          setJobTypeOptions(options)
        })
    }
  }, [accountId])

  // TEMP - This useEffect was added to retrospectively set the 'closed' timestamp attribute if
  // the job is closed. It gets the most recent history record for each job and checks if
  // it is closed. If so, it sets the closed timestamp to the timestamp of the history record.

  useEffect(() => {
    // Check for jobs with a 'closed' status but no 'closed' timestamp attribute
    const jobsToUpdate = jobs.filter((job) => {
      return job.status === "closed" && !job.closed
    })

    if (jobsToUpdate.length > 0) {
      const batch = db.batch()

      jobsToUpdate.forEach((job) => {
        // Get most recent job history of when job changed to 'closed' status
        const closedHistory =
          job.history?.filter((history) => {
            return history.status === "closed"
          }) || []

        if (closedHistory.length > 0) {
          const mostRecentClosedHistory =
            closedHistory[closedHistory.length - 1]

          // Update the job's 'closed' timestamp attribute
          const jobRef = db.collection(COLLECTION_NAME).doc(job.id)
          batch.update(
            jobRef,
            {
              closed: mostRecentClosedHistory.date,
              modified: dataServices.localTimestamp(),
            },
            { merge: true }
          )
        }
      })

      batch.commit()
    }
  }, [jobs])

  useEffect(() => {
    if (currentUser) {
      if (shouldClearCentreFilter(currentUser)) {
        setCentreFilterValue(null)
      }

      setAccountId(currentUser.claims.account_id)
      setUserAccountType(currentUser.claims.account_type)

      if (currentUser.claims.roles) {
        setUserRoles(currentUser.claims.roles)

        if (currentUser.claims.roles.includes(Roles.JOB_ADMIN)) {
          updateUserCentreIdsIfChanged([]) // all centres
        } else {
          updateUserCentreIdsIfChanged(currentUser.claims.centre_ids)

          if (currentUser.claims.centre_ids.length === 1) {
            setCentreFilterValue(currentUser.claims.centre_ids[0])
          }
        }
      }
    }
  }, [currentUser])

  const handleSupplierChange = (event) => {
    // if we click the 'x' icon in the drop down we get null instead of '' for the event.target.value
    const value = event.target.value === null ? "" : event.target.value

    setSupplierId(value)

    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      supplier_id: value,
    }

    setDirection("")

    dispatch(setJobGridPagination(updatedPagination))
  }

  const handleToggleJobStatusFilter = (event, statusToToggle) => {
    // If the status is already selected then deselect it, otherwise the filter is the
    // new 'statusToToggle'. Firestore has a limitation of only 1 'in' clause allowed per
    // query and so we need to check if other filters are consuming that 1 'in' clause
    // slot. If so, then we can only have 1 status selected at a time.

    const isLimitStatusSelections =
      userCentreIds.length >= 2 || jobTypesFilter.length > 1

    let newStatusFilter
    if (isLimitStatusSelections) {
      newStatusFilter = jobStatusFilter.includes(statusToToggle)
        ? []
        : [statusToToggle]
    } else {
      newStatusFilter = jobStatusFilter.includes(statusToToggle)
        ? [...jobStatusFilter].filter((status) => status !== statusToToggle)
        : [...jobStatusFilter, statusToToggle]
    }

    setJobStatusFilter(newStatusFilter)

    const updatedPagination = {
      ...pag,
      page: 0,
      job_status_filter: newStatusFilter,
    }
    setDirection("")

    dispatch(setJobGridPagination(updatedPagination))
  }

  // We've split out this function since it can be called from the UI if the user
  // selects a centre from the combobox, or if the user only has 1 centre allocated
  // we automatically set the centre value.
  const setCentreFilterValue = (centreId) => {
    // Check if the centre changed
    if (centreId === pag.centre_id) {
      return
    }

    setCentreId(centreId)

    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      centre_id: centreId,
    }

    setDirection("")

    dispatch(setJobGridPagination(updatedPagination))
  }

  const setPriorityFilter = (priority) => {
    // Check if the priority changed
    if (priority === pag.priority) {
      return
    }

    setPriority(priority)

    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      priority: priority,
    }

    setDirection("")

    dispatch(setJobGridPagination(updatedPagination))
  }

  const setTextSearchFilter = (textSearch) => {
    setTextSearch(textSearch)
    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      text_search: textSearch,
    }

    setDirection("")

    dispatch(setJobGridPagination(updatedPagination))
  }

  const setJobAllocatedFilter = (jobAllocated) => {
    // Check if the job allocated changed
    if (jobAllocated === pag.job_allocated) {
      return
    }

    setJobAllocated(jobAllocated)

    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      job_allocated: jobAllocated,
    }

    setDirection("")

    dispatch(setJobGridPagination(updatedPagination))
  }

  const setJobTypesFilterValue = (jobTypes) => {
    // Check if the job type changed
    if (_.isEqual(jobTypes, pag.job_types)) {
      return
    }

    setJobTypesFilter(jobTypes)

    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      job_types: jobTypes,
    }

    setDirection("")

    dispatch(setJobGridPagination(updatedPagination))
  }

  const handleCentreChange = (event) => {
    // if we click the 'x' icon in the drop down we get null instead of '' for the event.target.value
    const value = event.target.value === null ? "" : event.target.value

    setCentreFilterValue(value)
  }

  const handleJobAllocatedChange = (event) => {
    const value = event.target.value === null ? "" : event.target.value
    setJobAllocatedFilter(value)
  }

  const handleCloseJob = async (job) => {
    handleChangeJobStatus(job, jobServices.JOB_STATUS_CLOSED)
  }

  const handleOpenJob = async (job) => {
    handleChangeJobStatus(job, jobServices.JOB_STATUS_OPEN)
  }

  const handleCompleteJob = async (job) => {
    handleChangeJobStatus(job, jobServices.JOB_STATUS_COMPLETED)
  }

  const handleEditJob = (job) => {
    history.push("/jobedit/" + job.id)
  }

  const handleChangeJobStatus = async (job, status) => {
    const resultMessage = await dataServices.changeJobStatus(
      job.id,
      job.account_id,
      job.work_order_id,
      status
    )

    if (resultMessage) {
      enqueueSnackbar(resultMessage, { variant: "info" })
    }
  }

  // Check if the user is able to see any job information based on their roles and centres assigned
  const isJobsViewable = (isJobAdmin, centreIds) => {
    return isJobAdmin || (centreIds !== undefined && centreIds.length > 0)
  }

  const updatePageDocs = ({ querySnapshot, pagination, direction }) => {
    if (querySnapshot.docs.length > 0 && direction !== "prev") {
      const newPageDocs = addPage({ querySnapshot, page: pagination.page })

      dispatch(setJobGridPageDocs(newPageDocs))
    }
  }

  const distinct = (value, index, self) => {
    return self.indexOf(value) === index
  }

  // Lookup and cache the labels for centres

  useEffect(() => {
    const centreIds = jobs
      .map((job) => job.centre_id)
      .filter(distinct)
      .filter((item) => item !== "" && item !== null && item !== undefined)

    if (centreIds.length > 0) {
      // See if centres already contain the required keys
      const loadedCentreIds = centres.map((centre) => centre.id)

      const containsAllCentres = centreIds.every((id) =>
        loadedCentreIds.includes(id)
      )

      if (!containsAllCentres) {
        const missingCentreIds = centreIds.filter(
          (id) => !loadedCentreIds.includes(id)
        )

        if (missingCentreIds.length > 0) {
          dataServices
            .getCentresByIdChunks(missingCentreIds)
            .then((extraCentres) => {
              const newCentres = [...centres, ...extraCentres]
              setCentres(newCentres)
            })
        }
      }
    }
  }, [jobs, centres])

  /**
   * See if we have all account's priorities. This only occurs if a supplier is
   * looking at jobs assigned to them from different accounts
   *
   * @param {*} jobs Jobs for which we want to ensure we have the priorities. For suppliers, jobs shown can be from multiple accounts
   * @param {*} accountId Account id for current user
   */
  const getPrioritiesForJobs = (jobs, accountId) => {
    const jobAccountIds = jobs.map((job) => job.account_id).filter(distinct)

    if (prioritiesForAccounts.length === 0) {
      setPrioritiesForAccounts((curr) => [myAccountPriorities])
    }

    // Remove the current user's account id from the jobAccountIds
    const missingAccountIds = jobAccountIds.filter((id) => id !== accountId)

    // Load remainder of missing priorities
    jobServices
      .getJobPrioritiesForAccounts(missingAccountIds)
      .then((priorities) => {
        setPrioritiesForAccounts((curr) => [...curr, ...priorities])
      })
  }

  // Load and cache work orders (wo)

  useEffect(() => {
    const woIds = jobs
      .map((job) => job.work_order_id)
      .filter(distinct)
      .filter((item) => item !== "" && item !== null && item !== undefined)

    if (woIds.length === 0) {
      return
    }

    // See if work orders already contain the required keys
    const loadedIds = workOrders.map((workOrder) => workOrder.id)

    const containsAll = woIds.every((id) => loadedIds.includes(id))

    if (!containsAll) {
      const missingWorkOrderIds = woIds.filter((id) => !loadedIds.includes(id))

      dataServices
        .getWorkOrdersByIdChunks({
          workOrderIds: missingWorkOrderIds,
          accountId,
          accountType: userAccountType,
        })
        .then((extraWorkOrders) => {
          const newWorkOrders = [...workOrders, ...extraWorkOrders]
          setWorkOrders(newWorkOrders)

          // DEBUG - list the ids of extra work orders that failed to load

          const extraWorkOrderIds = extraWorkOrders.map((wo) => wo.id)
          const didNotLoadWorkOrders = missingWorkOrderIds.filter(
            (id) => !extraWorkOrderIds.includes(id)
          )

          const jobAndWorkOrder = didNotLoadWorkOrders.map((id) => {
            return {
              work_order_id: id,
              jobs: jobs.filter((job) => job.work_order_id === id)?.id,
            }
          })

          if (didNotLoadWorkOrders.length > 0) {
            console.error("Failed to load work orders for ids", {
              didNotLoadWorkOrders,
              jobAndWorkOrder,
              jobs: jobs.map((j) => ({
                id: j.id,
                work_order_id: j.work_order_id,
              })),
            })
          }
        })
        .catch((err) =>
          console.error("error loading work orders", err, missingWorkOrderIds)
        )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, accountId])

  // Load and cache suppliers

  useEffect(() => {
    if (jobs && accountId) {
      // Load and cache suppliers

      const supplierIds = jobs
        .map((job) => job.supplier_id)
        .filter(distinct)
        .filter((item) => item !== "" && item !== null && item !== undefined)

      if (supplierIds.length === 0) {
        return
      }

      // See if suppliers already contain the required keys
      const loadedIds = suppliers.map((supplier) => supplier.id)

      const containsAllSuppliers = supplierIds.every((id) =>
        loadedIds.includes(id)
      )

      if (!containsAllSuppliers) {
        const missingSupplierIds = supplierIds.filter(
          (id) => !loadedIds.includes(id)
        )

        dataServices
          .getSuppliersByIdChunks(missingSupplierIds, accountId)
          .then((extraSuppliers) => {
            const newSuppliers = [...suppliers, ...extraSuppliers]
            setSuppliers(newSuppliers)
          })
      }

      // Load and cache job owners (users) and maint user ids (users assigned to fix jobs)

      const userIds = jobs
        .map((job) => job.user_id)
        .concat(jobs.map((job) => job.maint_user_id))
        .filter(distinct)
        .filter((item) => item !== "" && item !== null && item !== undefined)

      if (userIds.length === 0) {
        return
      }

      // See if users already contain the required keys
      const loadedUserIds = users.map((user) => user.id)

      const containsAllUsers = userIds.every((id) => loadedUserIds.includes(id))

      if (!containsAllUsers) {
        const missingUserIds = userIds.filter(
          (id) => !loadedUserIds.includes(id)
        )

        dataServices
          .getUsersByIdChunks(accountId, missingUserIds)
          .then((extraUsers) => {
            const newUsers = [...users, ...extraUsers]
            setUsers(newUsers)
          })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, accountId])

  const loadOpenJobActions = (jobs, userAccountType, userAccountId) => {
    // Get jobs ids and split by account id, and then into batches of 10 within account id

    actionServices
      .loadNonClosedJobActions(
        jobs,
        currentUser.claims.email,
        userAccountType,
        userAccountId
      )
      .then((actions) => {
        setJobsActions(actions)
      })
  }

  const addOpenUrls = ({ job, userAccountType }) => {
    const openJobUrl = workOrderServices.getOpenJobUrl({
      accountType: userAccountType,
      jobId: job.id,
      workOrderId: job.work_order_id,
    })
    const openWorkOrderUrl = workOrderServices.getOpenWorkOrderUrl({
      accountType: userAccountType,
      workOrderId: job.work_order_id,
    })

    return { ...job, openJobUrl, openWorkOrderUrl }
  }

  // Load jobs

  const [reloadState, setReloadState] = useState({})

  useEffect(() => {
    const logId = "[JOB GRID > LOAD JOBS]"

    if (currentUser === undefined) {
      return
    }

    if (!userAccountType) {
      return
    }

    const newReloadState = {
      accountId: currentUser.claims.account_id,
      pagination: pag,
      userCentreIds,
      jobStatusFilter,
      supplierId,
      priority,
      reloadTrigger,
    }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    setReloadState(newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    if (!pag.initialized) {
      return
    }

    const jobQueryParams = jobServices.getJobQueryParams({
      direction: "next",
      pagination: pag,
      currentUser,
    })

    let { queryMods, queryConstraints } =
      jobServices.createJobQuery(jobQueryParams)

    const isJobAdmin = currentUser.claims.roles.includes(Roles.JOB_ADMIN)

    const dataQueryClauses = []

    if (jobQueryParams.jobAllocated !== "") {
      dataQueryClauses.push(orderBy("work_order_id"))
      queryMods.push("order by work_order_id")
    }

    if (pag.orderBy === "category" && jobTypesFilter.length > 0) {
      //FIXME: should this be sorted by 'category', not 'created'
      dataQueryClauses.push(orderBy("created", "desc"))
      queryMods.push("order by created desc")
    } else {
      dataQueryClauses.push(orderBy(pag.orderBy, pag.order))
      queryMods.push("order by " + pag.orderBy + " " + pag.order)
    }

    if (pag.page > 0 && direction !== "prev") {
      const lastDoc = getLastDocPreviousPage(pag.page)
      queryMods.push("start after last doc on previous page")
      dataQueryClauses.push(startAfter(lastDoc))
    } else if (direction === "prev") {
      queryMods.push("start at 1st doc on page")
      const firstDoc = getFirstDocThisPage(pag.page)
      dataQueryClauses.push(startAt(firstDoc))
    }

    dataQueryClauses.push(limit(rowsPerPage))
    queryMods.push("limit " + rowsPerPage)

    const dataQuery = query(
      collection(db, "jobs"),
      ...[...queryConstraints, ...dataQueryClauses]
    )

    // Execute dataQuery to get the jobs

    if (isJobsViewable(isJobAdmin, userCentreIds)) {
      getDocs(dataQuery)
        .then((querySnapshot) => {
          const jobs = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            }
          })

          const jobsWithOpenUrl = jobs.map((job) => {
            return addOpenUrls({ job, userAccountType })
          })

          setJobs(jobsWithOpenUrl)

          updatePageDocs({ querySnapshot, pagination: pag, direction })

          loadOpenJobActions(
            jobs,
            currentUser.claims.account_type,
            currentUser.claims.account_id
          )

          getPrioritiesForJobs(jobs, currentUser.claims.account_id)

          if (jobs.length === rowsPerPage || pag.page > 0) {
            getJobCount({ queryConstraints })
          } else {
            setJobCount(jobs.length)
          }
        })
        .catch((err) => console.error(`${logId} Error loading jobs`, err))
    } else {
      setJobs([])
      setJobCount(0)
    }
  }, [
    centreId,
    pag,
    userCentreIds,
    jobStatusFilter,
    currentUser,
    supplierId,
    priority,
    userAccountType,
    reloadTrigger,
  ])

  const getJobCount = ({ queryConstraints }) => {
    const countJobs = query(collection(db, "jobs"), ...queryConstraints)
    getCountFromServer(countJobs).then((result) => {
      setJobCount(result.data().count)
    })
  }

  const handleRequestSort = (event, property) => {
    const sortCol = headCells.find((cell) => cell.id === property)
    const sortSameCol = pag.orderBy === property

    // If we clicked on the same column to sort it, then find the other sort order for that column (there will only be
    // 1 or 2 array entries, being 'asc' or 'desc' in some order),
    // Otherwise it means we're sorting on a new column so get the 1st sort order from the 'direction' attribute, which
    // could be either 'asc' or 'desc'.
    const sortOrder = sortSameCol
      ? sortCol.direction.find((dir) => dir !== pag.order) || pag.order
      : sortCol.direction[0]

    const updatedPagination = {
      page: 0,
      order: sortOrder,
      orderBy: property,
      supplier_id: supplierId,
      centre_id: centreId,
      job_allocated: jobAllocated,
      job_status_filter: jobStatusFilter,
      priority: priority,
      job_types: jobTypesFilter,
      initialized: true,
    }

    dispatch(setJobGridPagination(updatedPagination))

    clearPageDocs()
    dispatch(setJobGridPageDocs([]))

    setDirection("")
  }

  // Select all on job grid

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allSelected = jobs.map((n) => {
        return {
          id: n.id,
          label: n.label,
        }
      })

      // Find newly selected ids

      const newSelected = [...selected]

      const newAdditions = allSelected.filter(
        (item) => selected.find((s) => item.id === s.id) === undefined
      )
      newAdditions.forEach((item) => newSelected.push(item))

      setSelected(newSelected)
      dispatch(setJobGridSelectedItems(newSelected))
    } else {
      // Remove all jobs from selected
      const allDeselected = jobs.map((n) => {
        return {
          id: n.id,
          label: n.label,
        }
      })
      const newSelected = selected.filter(
        (item) => allDeselected.find((s) => item.id === s.id) === undefined
      )
      setSelected(newSelected)
      dispatch(setJobGridSelectedItems(newSelected))
    }
  }

  const handleNewJob = (event) => {
    event.preventDefault()
    history.push("/jobedit")
  }

  const deselectJob = (id) => {
    const existing = selected.find((item) => item.id === id)
    if (existing) {
      const newSelected = selected.filter((item) => item.id !== id)
      setSelected(newSelected)
      dispatch(setJobGridSelectedItems(newSelected))
    }
  }

  const handleClick = (event, label, id) => {
    const existing = selected.find((item) => item.id === id) !== undefined

    if (!existing) {
      //if (event.target.checked) {
      const newSelected = [...selected]
      newSelected.push({
        id: id,
        label: label,
      })
      setSelected(newSelected)
      dispatch(setJobGridSelectedItems(newSelected))
    } else {
      deselectJob(id)
    }
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }

      dispatch(setJobGridPagination(updatedPagination))
    }
  }

  // Reload job into grid
  const handleStatusChanged = (jobId, status) => {
    const updatedJobs = jobs.map((job) => {
      if (job.id === jobId) {
        return {
          ...job,
          status: status,
        }
      }
      return job
    })

    setJobs(updatedJobs)
  }

  const jobTypesWidth = useMemo(() => {
    if (jobTypesFilter?.length === 0) {
      return "150px"
    }
    const count = Math.min(jobTypesFilter?.length, 3)
    const width = Math.max(200, count * 120)

    return `${width}px`
  }, [jobTypesFilter])

  const isSelected = (id) =>
    selected.find((item) => item.id === id) !== undefined

  return (
    <>
      <FilterItems>
        {userAccountType === Roles.ACCOUNT_TYPE_CENTRE &&
          currentUser?.claims?.roles.includes(Roles.JOB_ADMIN) && (
            <>
              <Box>
                <TextSearchField
                  textSearch={textSearch}
                  setTextSearchFilter={setTextSearchFilter}
                />
              </Box>

              <Divider orientation="vertical" flexItem />
            </>
          )}

        {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            <Box sx={{ minWidth: "280px" }}>
              {userCentreIds && (
                <Controls.CentreCombobox
                  name="centre_id"
                  label={
                    <MultiSelectLabel
                      count={userCentreIds.length}
                      oneSelectedLabel="Centre"
                      multiSelectedLabel={`Centre (limit ${userCentreIds.length})`}
                    />
                  }
                  value={centreId}
                  accountId={accountId}
                  readonly={false}
                  onChange={handleCentreChange}
                  centreIdsFilter={userCentreIds}
                  nextSelector={nextSelector}
                  prevSelector={prevSelector}
                />
              )}
            </Box>
            <Box>
              <Tooltip title="Previous centre">
                <IconButton
                  size="small"
                  onClick={() => setPrevSelector((curr) => curr + 1)}
                >
                  <NavigateBeforeIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="Next centre">
                <IconButton
                  size="small"
                  onClick={() => setNextSelector((curr) => curr + 1)}
                >
                  <NavigateNextIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        )}

        {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <Box sx={{ minWidth: "300px" }}>
            <Controls.SupplierCombobox
              name="supplier_id"
              label="Supplier"
              value={supplierId}
              accountId={accountId}
              readonly={textSearch !== ""}
              onChange={handleSupplierChange}
            />
          </Box>
        )}

        {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <Box>
            <Select
              label="Priority"
              noneValue={0}
              noneLabel="All"
              value={priority}
              onChange={(e) => setPriorityFilter(e.target.value)}
              options={
                (myAccountPriorities &&
                  myAccountPriorities.lookup_values?.map((p) => ({
                    id: p.id,
                    title: `${p.title} - ${p.description}`,
                  }))) ||
                []
              }
              disabled={textSearch !== ""}
            />
          </Box>
        )}

        {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <Box
            sx={{
              minWidth: jobTypesWidth,
            }}
          >
            <Autocomplete
              multiple
              disabled={textSearch !== ""}
              limitTags={isLimitJobTypeFilter ? 1 : 10}
              onChange={(event, newValue) => {
                if (isLimitJobTypeFilter && newValue.length > 1) {
                  enqueueSnackbar(
                    `Filtering can only use 1 multi-select value at any one time.`,
                    { variant: "info" }
                  )
                  return
                }

                setJobTypesFilterValue(newValue)
              }}
              options={jobTypeOptions}
              value={jobTypesFilter}
              variant={UIConstants.STANDARD_INPUT_VARIANT}
              size={UIConstants.STANDARD_INPUT_SIZE}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <MultiSelectLabel
                      count={jobTypesFilter?.length || 0}
                      multiSelectedLabel={"Job Types"}
                      oneSelectedLabel={"Job Types"}
                    />
                  }
                  placeholder="Job Type"
                  variant={UIConstants.STANDARD_INPUT_VARIANT}
                  size={UIConstants.STANDARD_INPUT_SIZE}
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                    sx={{ backgroundColor: JOB_TYPES }}
                  />
                ))
              }
            />
          </Box>
        )}

        {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <Box sx={{ width: "150px" }}>
            <Controls.ComboBox
              name="job_allocated"
              label="Allocated"
              value={jobAllocated}
              items={[
                { id: "", label: "All" },
                { id: "Y", label: "Allocated" },
                { id: "N", label: "Unallocated" },
              ]}
              onChange={handleJobAllocatedChange}
              disabled={textSearch !== ""}
            />
          </Box>
        )}

        <Box>
          <Box>
            <Box>
              <Typography
                component={"span"}
                variant="caption"
                color="text.secondary"
                // Bold any filter heading that is consuming the 1 'in' query slot for Firebase
                sx={{ fontWeight: jobStatusFilter?.length > 1 && "bold" }}
              >
                Job Status
              </Typography>
            </Box>
            {isShowJobStatusFilter !== undefined &&
              isShowJobStatusFilter &&
              currentUser && (
                <Box sx={styles.jobStatusFilter}>
                  {jobStatusFilter &&
                    jobStatusFilter.map((jobStatus) => (
                      <JobStatusChip key={jobStatus} status={jobStatus} />
                    ))}
                  {jobStatusFilter && jobStatusFilter.length === 0 && "All"}
                  <IconButton
                    onClick={(event) => {
                      setJobStatusFilterAnchorEl(event.target)
                    }}
                    //disabled={textSearch !== ""}
                  >
                    <icons.MoreVertIcon />
                  </IconButton>
                </Box>
              )}
          </Box>
          <Menu
            id="jobStatusFilter"
            anchorEl={jobStatusFilterAnchorEl}
            open={jobStatusFilterAnchorEl !== null}
            onClose={() => {
              setJobStatusFilterAnchorEl(null)
            }}
          >
            <JobStatusMenuItem
              handleToggleJobStatusFilter={handleToggleJobStatusFilter}
              label="Open"
              status="open"
              jobStatusFilter={jobStatusFilter}
            />
            {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
              <JobStatusMenuItem
                handleToggleJobStatusFilter={handleToggleJobStatusFilter}
                label="Pending"
                status={jobServices.JOB_STATUS_PENDING}
                jobStatusFilter={jobStatusFilter}
              />
            )}
            <JobStatusMenuItem
              handleToggleJobStatusFilter={handleToggleJobStatusFilter}
              label="Closed"
              status="closed"
              jobStatusFilter={jobStatusFilter}
            />
            <JobStatusMenuItem
              handleToggleJobStatusFilter={handleToggleJobStatusFilter}
              label="Completed"
              status="completed"
              jobStatusFilter={jobStatusFilter}
            />
          </Menu>
        </Box>
      </FilterItems>

      <Box>
        <Mobile width={675}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {centres &&
              jobsWithCentresAndJobOwners &&
              jobsWithCentresAndJobOwners.map((job) => (
                <JobTile
                  key={job.id}
                  job={job}
                  isEditable={false}
                  isSupplierViewing={
                    userAccountType === Roles.ACCOUNT_TYPE_SUPPLIER
                  }
                  handleCloseJob={() => {
                    handleCloseJob(job)
                  }}
                  handleOpenJob={() => {
                    handleOpenJob(job)
                  }}
                  handleCompleteJob={() => {
                    handleCompleteJob(job)
                  }}
                  handleEditJob={() => {
                    handleEditJob(job)
                  }}
                  showRemoveJobPrompt={false}
                  priorities={prioritiesForAccounts.find(
                    (p) => p.account_id === job.account_id
                  )}
                  jobsActions={jobsActions}
                  handleFileUpload={undefined}
                  setJobToShowPhotos={undefined}
                  showPhotos={false}
                />
              ))}
          </Box>
        </Mobile>
      </Box>

      <Box sx={styles.root}>
        <Desktop width={675}>
          <Box>
            <EnhancedTableToolbar
              numSelected={selected.length}
              selected={selected}
              setSelected={setSelected}
              dispatch={dispatch}
              setJobGridSelectedItems={setJobGridSelectedItems}
              jobs={jobs}
              accountId={accountId}
              accountType={userAccountType}
              setReloadTrigger={setReloadTrigger}
            />
            <TableContainer>
              <Table
                sx={styles.table}
                aria-labelledby="Jobs"
                size={dense ? "small" : "medium"}
                aria-label="Jobs"
              >
                <JobGrid
                  numSelected={selected.length}
                  selected={selected}
                  jobs={jobs}
                  order={pag.order}
                  orderBy={pag.orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={jobs.length}
                  userAccountType={userAccountType}
                />
                <TableBody>
                  {jobs.map((row, index) => {
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    let centreName = ""
                    if (row.centre_id !== "") {
                      const centre = centres.find(
                        (centre) => centre.id === row.centre_id
                      )
                      if (centre) {
                        centreName = centre.short_name || centre.name
                      }
                    }

                    let supplierName = ""
                    let maintUserName = ""

                    if (row.supplier_id !== "") {
                      const supplier = suppliers.find(
                        (supplier) => supplier.id === row.supplier_id
                      )
                      if (supplier) {
                        supplierName = supplier.name
                      }
                    } else if (row.maint_user_id !== "") {
                      const maintUser = users.find(
                        (user) => user.id === row.maint_user_id
                      )
                      if (maintUser) {
                        maintUserName = maintUser.name
                      }
                    }

                    let jobOwnerName = ""
                    if (row.user_id !== "") {
                      const jobOwner = users.find(
                        (user) => user.id === row.user_id
                      )
                      if (jobOwner) {
                        jobOwnerName = jobOwner.name
                      }
                    }

                    let workOrderLabel = ""
                    if (row.work_order_id !== "") {
                      const workOrder = workOrders.find(
                        (wo) => wo.id === row.work_order_id
                      )
                      if (workOrder) {
                        workOrderLabel = `${
                          workOrder.work_order_no || ""
                        }`.trim()
                      }
                    }

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        onClick={(event) => {
                          if (event.target.name === "select-job") return
                          history.push(row.openJobUrl)
                        }}
                        sx={styles.tableRow}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            name="select-job"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            sx={{ color: J4J_YELLOW }}
                            onClick={(event) => {
                              event.preventDefault()
                              handleClick(event, row.label, row.id)
                            }}
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ width: "250px" }}
                        >
                          <LinkButton
                            to={row.openJobUrl}
                            sx={{ justifyContent: "flex-start" }}
                          >
                            <TruncatedText
                              sx={{ width: "200px" }}
                              variant="body2"
                            >
                              {row.label}
                            </TruncatedText>
                          </LinkButton>
                        </TableCell>

                        <TableCell
                          align="left"
                          padding="none"
                          sx={{ width: "200px" }}
                        >
                          {userAccountType === Roles.ACCOUNT_TYPE_SUPPLIER && (
                            <TruncatedText
                              noWrap
                              variant="body2"
                              sx={{ width: "200px" }}
                            >
                              {centreName}
                            </TruncatedText>
                          )}
                          {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
                            <LinkButton
                              to={`/CentreEdit/${row.centre_id}`}
                              sx={{ justifyContent: "flex-start" }}
                            >
                              {centreName}
                            </LinkButton>
                          )}
                        </TableCell>

                        <TableCell
                          align="left"
                          padding="none"
                          sx={{ width: "150px" }}
                        >
                          {row.category}
                        </TableCell>

                        <TableCell
                          align="left"
                          padding="none"
                          sx={{ width: "60px" }}
                        >
                          <Priority
                            priority={row.priority}
                            priorities={
                              prioritiesForAccounts &&
                              prioritiesForAccounts.find(
                                (p) => p.account_id === row.account_id
                              )
                            }
                          />
                        </TableCell>

                        <TableCell
                          align="left"
                          padding="none"
                          sx={{ width: "120px" }}
                        >
                          <StatusCell
                            row={row}
                            userRoles={userRoles}
                            userAccountType={userAccountType}
                            showBadge={
                              jobsActions &&
                              jobsActions.find(
                                (action) => action.parent_id === row.id
                              )
                            }
                            tooltip={
                              jobsActions &&
                              jobsActions.find(
                                (action) => action.parent_id === row.id
                              )?.description
                            }
                            handleStatusChanged={handleStatusChanged}
                          />
                        </TableCell>

                        <TableCell
                          align="left"
                          padding="none"
                          sx={{ width: "140px" }}
                        >
                          {row.location}
                        </TableCell>

                        <TableCell
                          align="left"
                          padding="none"
                          sx={{ width: "100px" }}
                        >
                          {row.work_order_id !== "" ? (
                            userRoles.includes(Roles.JOB_ADMIN) ? (
                              <LinkButton to={row.openWorkOrderUrl}>
                                {workOrderLabel}
                              </LinkButton>
                            ) : (
                              <Typography
                                component={"span"}
                                noWrap={true}
                                variant="body2"
                                sx={{ maxWidth: "50px" }}
                              >
                                {workOrderLabel}
                              </Typography>
                            )
                          ) : (
                            ""
                          )}
                        </TableCell>

                        {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
                          <TableCell
                            align="left"
                            padding="none"
                            sx={{ width: "140px" }}
                          >
                            {supplierName && row.supplier_id && (
                              <LinkButton
                                to={`/SupplierEdit/${row.supplier_id}`}
                              >
                                <TruncatedText
                                  variant="body2"
                                  sx={{ maxWidth: "120px" }}
                                >
                                  {supplierName}
                                </TruncatedText>
                              </LinkButton>
                            )}
                            {maintUserName && row.maint_user_id && (
                              <TruncatedText
                                variant="body2"
                                sx={{ maxWidth: "120px" }}
                              >
                                {maintUserName}
                              </TruncatedText>
                            )}
                          </TableCell>
                        )}

                        {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
                          <TableCell
                            align="left"
                            padding="none"
                            sx={{ width: "140px" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                alignItems: "center",
                              }}
                              gap={0.5}
                            >
                              {row.self_repairable && (
                                <Tooltip title="Self repairable" arrow>
                                  <HomeRepairServiceIcon
                                    fontSize="small"
                                    sx={{ color: colors.pink[200] }}
                                  />
                                </Tooltip>
                              )}
                              {jobOwnerName && (
                                <TruncatedText
                                  variant="body2"
                                  sx={{ maxWidth: "120px" }}
                                >
                                  {jobOwnerName}
                                </TruncatedText>
                              )}
                            </Box>
                          </TableCell>
                        )}

                        <TableCell
                          align="left"
                          padding="none"
                          sx={{ width: "100px" }}
                        >
                          <Typography
                            component={"span"}
                            variant="body2"
                            noWrap={true}
                          >
                            {format(row.created.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Desktop>
        <Box sx={{ marginTop: "10px" }}>
          <NavButtons>
            <Controls.Button
              size="small"
              disabled={pag.page === 0}
              onClick={() => handlePageNav(-1)}
              text="Prev"
            />
            <Controls.Button
              size="small"
              disabled={jobs.length < rowsPerPage}
              onClick={() => handlePageNav(1)}
              text="Next"
            />
            {/* {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
              <Controls.Button
                size="small"
                onClick={(event) => handleNewJob(event)}
                text="New Job"
                endIcon={<icons.JobIcon />}
              />
            )} */}
          </NavButtons>
        </Box>
        <PageNo pageNo={pag.page + 1} count={jobCount} type={"jobs"} />
      </Box>

      {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
        <Controls.FabButton handleClick={handleNewJob} label="New Job" />
      )}
    </>
  )
}

const TextSearchField = ({ textSearch, setTextSearchFilter, disabled }) => {
  const [value, setValue] = useState("")

  return (
    <Box sx={{ maxWidth: "180px" }}>
      <Controls.TextInput
        label="Search"
        value={value}
        disabled={disabled}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === "Tab") {
            setTextSearchFilter(e.target.value)
          }
        }}
        icon={<SearchIcon />}
        onBlur={(e) => setTextSearchFilter(e.target.value)}
      />
    </Box>
  )
}

const StatusCell = (props) => {
  const {
    row,
    userRoles,
    userAccountType,
    showBadge = false,
    tooltip,
    // Can notify parent component of status change
    handleStatusChanged,
  } = props

  const { enqueueSnackbar } = useSnackbar()

  const [anchorEl, setAnchorEl] = useState()

  const handleStatusMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleStatusMenuClose = () => {
    setAnchorEl(null)
  }

  const handleStatusChange = async (status) => {
    const resultMessage = await dataServices.changeJobStatus(
      row.id,
      row.account_id,
      row.work_order_id,
      status
    )

    if (resultMessage) {
      enqueueSnackbar(resultMessage, { variant: "info" })
    }

    if (handleStatusChanged) {
      handleStatusChanged(row.id, status)
    }
  }

  const getStatusMenuItems = (row, userRoles) => {
    const menuItems = []

    if (row.status !== jobServices.JOB_STATUS_OPEN) {
      menuItems.push(
        <MenuItem
          key="open"
          onClick={(e) => {
            e.stopPropagation()
            handleStatusMenuClose()
            handleStatusChange(jobServices.JOB_STATUS_OPEN)
          }}
        >
          <ListItemIcon>
            <icons.JobOpenIcon />
          </ListItemIcon>
          <Typography component={"span"}>Mark as Open</Typography>
        </MenuItem>
      )
    }

    if (row.status !== jobServices.JOB_STATUS_PENDING) {
      const canChangeToPending =
        userRoles.includes(Roles.JOB_ADMIN) &&
        userAccountType === Roles.ACCOUNT_TYPE_CENTRE

      if (canChangeToPending) {
        menuItems.push(
          <MenuItem
            key="pending"
            onClick={(e) => {
              e.stopPropagation()
              handleStatusMenuClose()
              handleStatusChange(jobServices.JOB_STATUS_PENDING)
            }}
          >
            <ListItemIcon>
              <icons.JobPendingIcon />
            </ListItemIcon>
            <Typography component={"span"}>Mark as Pending</Typography>
          </MenuItem>
        )
      }
    }

    // Centres 'close' jobs, no need to go through the intermediate 'Complete' stage

    const canCloseJobs =
      row.status !== jobServices.JOB_STATUS_CLOSED &&
      userAccountType === Roles.ACCOUNT_TYPE_CENTRE &&
      (userRoles.includes(Roles.JOB_USER) ||
        userRoles.includes(Roles.JOB_ADMIN))

    if (canCloseJobs) {
      menuItems.push(
        <MenuItem
          key="close"
          onClick={(e) => {
            e.stopPropagation()
            handleStatusMenuClose()
            handleStatusChange(jobServices.JOB_STATUS_CLOSED)
          }}
        >
          <ListItemIcon>
            <icons.JobCloseIcon />
          </ListItemIcon>
          <Typography component={"span"}>Mark as Closed</Typography>
        </MenuItem>
      )
    }

    // Suppliers 'Complete' jobs, which then requires someone from the centre to Close

    if (
      row.status !== jobServices.JOB_STATUS_COMPLETED &&
      userAccountType === Roles.ACCOUNT_TYPE_SUPPLIER
    ) {
      menuItems.push(
        <MenuItem
          key="complete"
          onClick={(e) => {
            e.stopPropagation()
            handleStatusMenuClose()
            handleStatusChange(jobServices.JOB_STATUS_COMPLETED)
          }}
        >
          <ListItemIcon>
            <icons.JobCompleteIcon />
          </ListItemIcon>
          <Typography component={"span"}>Mark as Completed</Typography>
        </MenuItem>
      )
    }

    return menuItems
  }

  return (
    <Typography component={"span"} noWrap={true} variant="body2">
      <JobStatusChip
        status={row.status}
        showBadge={showBadge}
        tooltip={tooltip}
      />

      <IconButton
        size="small"
        sx={styles.statusMenu}
        onClick={(e) => {
          e.stopPropagation()
          handleStatusMenuOpen(e)
        }}
      >
        <icons.MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        id={"status-menu"}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleStatusMenuClose}
      >
        {getStatusMenuItems(row, userRoles)}
      </Menu>
    </Typography>
  )
}

const JobStatusMenuItem = (props) => {
  const { handleToggleJobStatusFilter, status, label, jobStatusFilter } = props

  return (
    <MenuItem onClick={(event) => handleToggleJobStatusFilter(event, status)}>
      <ListItemIcon>
        {jobStatusFilter && jobStatusFilter.includes(status) && <DoneIcon />}
      </ListItemIcon>
      {label}
    </MenuItem>
  )
}
