import React, { useState, useEffect } from "react"
import MuiAppBar from "@mui/material/AppBar"
import { styled, useTheme } from "@mui/material/styles"
import { Link } from "react-router-dom"
import Typography from "@mui/material/Typography"
import Toolbar from "@mui/material/Toolbar"
import Drawer from "@mui/material/Drawer"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import Collapse from "@mui/material/Collapse"
import ListItemText from "@mui/material/ListItemText"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ListItemIcon from "@mui/material/ListItemIcon"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ConfigIcon from "@mui/icons-material/Settings"
import DashboardIcon from "@mui/icons-material/Dashboard"
import IconButton from "@mui/material/IconButton"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import MenuIcon from "@mui/icons-material/Menu"
import * as icons from "../icons"
import { pink } from "@mui/material/colors"
import * as Roles from "../pages/services/roleServices"
import { Badge, Box, CssBaseline, ListItemButton } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import DescriptionIcon from "@mui/icons-material/Description"
import PlaceIcon from "@mui/icons-material/Place"
import HandymanIcon from "@mui/icons-material/Handyman"
import PriorityHighIcon from "@mui/icons-material/PriorityHigh"
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import BusinessIcon from "@mui/icons-material/Business"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import PaymentIcon from "@mui/icons-material/Payment"
import LogoutIcon from "@mui/icons-material/Logout"
import {
  selectActionsIndicator,
  selectMenuStatus,
  selectConfigMenuStatus,
  selectMyAccountMenuStatus,
} from "../redux/selectors"
import {
  setMenuStatus,
  setConfigMenuStatus,
  setMyAccountMenuStatus,
} from "../redux/actions"
import LogoFull from "./LogoFull"
import { isMobile } from "react-device-detect"
import { spacing } from "../pages/services/styleServices"
import { J4J_YELLOW } from "../pages/services/colorServices"
import { useMemo } from "react"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const drawerWidth = 240

const styles = {
  systemRoleIcon: {
    color: pink[600],
  },
  profileButton: {
    marginRight: spacing(2),
    paddingTop: spacing(2),
  },
}

const TitleTypography = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
}))

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}))

const getOpenOption = (item) => {
  return { label: item.label, uri: item.uri, icon: item.icon }
}

const createMenuLink = (menuItem, indent = false, handleMenuClicked) => {
  const sx = (indent && { marginLeft: "10px" }) || {}

  return (
    <StyledLink
      to={menuItem.uri}
      sx={styles.link}
      key={menuItem.uri}
      onClick={handleMenuClicked}
    >
      <ListItemButton key={menuItem.label} sx={sx}>
        {menuItem.icon ? <ListItemIcon>{menuItem.icon}</ListItemIcon> : null}
        <ListItemText primary={menuItem.label} />
      </ListItemButton>
    </StyledLink>
  )
}

function Header(props) {
  const theme = useTheme()

  const dispatch = useDispatch()

  // Is the menu open? - true/false
  const isMenuOpen = useSelector(selectMenuStatus)

  const isConfigMenuOpen = useSelector(selectConfigMenuStatus)

  const isMyAccountMenuOpen = useSelector(selectMyAccountMenuStatus)

  const hasActions = useSelector(selectActionsIndicator)

  const handleDrawerOpen = () => {
    dispatch(setMenuStatus(true))
  }

  const handleDrawerClose = () => {
    dispatch(setMenuStatus(false))
  }

  const toggleConfigMenuOpen = () => {
    dispatch(setConfigMenuStatus(!isConfigMenuOpen))
  }

  const toggleMyAccountMenuOpen = () => {
    dispatch(setMyAccountMenuStatus(!isMyAccountMenuOpen))
  }

  const [myAccountId, setMyAccountId] = useState("")

  const billingURL = () => `/Billing/${myAccountId}`

  const [claims, setClaims] = useState({
    account_type: "",
  })

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          setMyAccountId(token.claims.account_id)

          const newClaims = {
            roles: token.claims.roles,
            account_id: token.claims.account_id,
            account_type: token.claims.account_type,
            system_role: token.claims.system_role,
          }

          setClaims(newClaims)
        })
      }
    })

    return unsub
  }, [])

  const isSupplier = useMemo(() => {
    if (!claims || claims.account_type === "") {
      return undefined
    }
    return claims && claims.account_type === "supplier"
  }, [claims])

  const isAdmin = useMemo(() => {
    if (claims === undefined || claims.roles === undefined) {
      return false
    }
    return claims && claims.roles.includes(Roles.ADMIN)
  }, [claims])

  const isJobAdmin = useMemo(() => {
    if (claims === undefined || claims.roles === undefined) {
      return false
    }
    return claims && claims.roles.includes(Roles.JOB_ADMIN)
  }, [claims])

  const isSystem = useMemo(() => {
    const isSystemRole =
      claims.hasOwnProperty("system_role") && claims.system_role === true
    return isSystemRole
  }, [claims])

  const myAccountItemData = useMemo(() => {
    return [
      {
        label: "My Organisation",
        uri: () => "/AccountEdit/" + myAccountId,
        icon: <BusinessIcon />,
        canAccess: () => true,
      },
      {
        label: "My Profile",
        uri: "/Profile",
        icon: <AccountCircleIcon />,
        canAccess: () => true,
      },
      {
        label: "Billing",
        uri: () => billingURL(),
        icon: <PaymentIcon />,
        canAccess: () => isAdmin,
      },
      // {
      //   label: "Integration",
      //   uri: "/xeroLogin",
      //   //icon: <icons.XeroIcon />,
      //   canAccess: () => true,
      // },
      {
        label: "Log Off",
        uri: "/Signout",
        icon: <LogoutIcon />,
        canAccess: () => true,
      },
    ]
  }, [myAccountId])

  const configItemData = useMemo(() => {
    return [
      {
        label: "Users",
        uri: "/Users",
        icon: <icons.UserIcon />,
        canAccess: () => isAdmin,
      },
      {
        label: "User Invites",
        uri: "/Invites",
        create_uri: "/Invites",
        create_label: "Invite",
        icon: <icons.InviteIcon />,
        canAccess: () => isAdmin,
        canCreate: () => isAdmin,
      },
      {
        label: "Supplier Document Types",
        uri: "/supplierdoctypes",
        icon: <DescriptionIcon />,
        canAccess: () => true,
      },
      {
        label: "Supplier Coverage Areas",
        uri: "/coverage",
        icon: <PlaceIcon />,
        canAccess: () => true,
      },
      {
        label: "Job Types",
        uri: "/jobtypes",
        icon: <HandymanIcon />,
        canAccess: () => true,
      },
      {
        label: "Job Priorities",
        uri: "/priorities",
        icon: <PriorityHighIcon />,
        canAccess: () => true,
      },
      {
        label: "Job Locations",
        uri: "/locationtypes",
        icon: <MeetingRoomIcon />,
        canAccess: () => true,
      },
      {
        label: "Checklist Templates",
        uri: "/Checklists",
        create_uri: "/CheckListEdit",
        create_label: "CheckList",
        icon: <icons.ChecklistTemplateIcon />,
        canAccess: () => isAdmin,
        canCreate: () => isAdmin,
      },
    ]
  }, [isAdmin, myAccountId])

  const getActionIcon = (hasActions) => {
    if (hasActions) {
      return (
        <Badge color={"error"} variant="dot">
          <icons.ActionIcon />
        </Badge>
      )
    }
    return <icons.ActionIcon />
  }

  const drawItemData = useMemo(() => {
    return [
      {
        label: "Dashboard",
        uri: "/Dashboard",
        icon: <DashboardIcon />,
        canAccess: () => isSupplier === false,
      },
      {
        label: "DIVIDER",
        canAccess: () => isSupplier === false,
      },
      {
        label: "Jobs",
        uri: "/JobGrid",
        create_uri: "/JobEdit",
        create_label: "Job",
        icon: <icons.JobIcon />,
        canAccess: () => true,
        canCreate: () => isSupplier === false,
      },
      {
        label: "Actions",
        uri: "/Actions",
        icon: getActionIcon(hasActions),
        canAccess: () => true,
        canCreate: () => true,
      },
      {
        label: "Work Orders",
        uri: "/workorders",
        create_uri: "/WorkOrderEdit",
        create_label: "Work Order",
        icon: <icons.WorkOrderIcon />,
        canAccess: () => true,
        canCreate: () => isSupplier === false && isAdmin,
      },
      {
        label: "Calendar",
        uri: "/workordercalendar",
        icon: <icons.EventIcon />,
        canAccess: () => isSupplier === false,
      },
      {
        label: "Checklists",
        uri: "/CheckListItems",
        //create_uri: "/CheckListItemsEdit",
        create_label: "Checklist",
        icon: <icons.CheckListIcon />,
        canAccess: () => isSupplier === false,
        canCreate: () => isSupplier === false,
      },
      {
        label: "Assets",
        uri: "/Assets",
        create_uri: "/AssetEdit",
        create_label: "Asset",
        icon: <icons.AssetIcon />,
        canAccess: () => isSupplier === false,
        canCreate: () => isSupplier === false && isAdmin,
      },
      {
        label: "Quotes",
        uri: "/Quotes",
        icon: <icons.QuoteIcon />,
        canAccess: () => isSupplier || isAdmin || isJobAdmin,
        canCreate: false,
      },
      {
        label: "Suppliers",
        uri: "/Suppliers",
        create_uri: "/SupplierEdit",
        create_label: "Supplier",
        icon: <icons.SupplierIcon />,
        canAccess: () => isSupplier === false,
        canCreate: () => isSupplier === false && isAdmin,
      },
      {
        label: "Centres",
        uri: "/Centres",
        create_uri: "/CentreEdit",
        create_label: "Centre",
        icon: <icons.CentreIcon />,
        canAccess: () => isSupplier === false,
        canCreate: () => isSupplier === false && isAdmin,
      },
      {
        label: "Accounts",
        uri: "/Accounts",
        icon: <icons.AccountIcon sx={styles.systemRoleIcon} />,
        canAccess: () => isSystem,
        canCreate: false,
      },
    ]
  }, [isSupplier, isAdmin, isJobAdmin, isSystem, hasActions])

  const [clicked, setClicked] = useState(0)

  const handleMenuClicked = (event) => {
    setClicked((curr) => curr + 1)
  }

  useEffect(() => {
    if (isMobile) {
      handleDrawerClose()
    }
  }, [clicked, isMobile])

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={isMenuOpen}
        sx={{ backgroundColor: J4J_YELLOW }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(isMenuOpen && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <TitleTypography
            component={"span"}
            variant="h5"
            noWrap
            sx={{ color: "white" }}
          >
            {props.title}
          </TitleTypography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={isMenuOpen}
      >
        <DrawerHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Box sx={{ marginRight: "70px" }}>
              <LogoFull type="white" />
            </Box>

            <Box sx={{ display: "flex", marginLeft: "auto" }}>
              <IconButton onClick={handleDrawerClose} size="large">
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </Box>
          </Box>
        </DrawerHeader>

        {isSupplier === false && <Divider key="divider" />}

        <List dense>
          <DrawerItems
            drawItemData={drawItemData}
            handleMenuClicked={handleMenuClicked}
          />

          {isSupplier === false && (
            <ListItemButton onClick={toggleConfigMenuOpen}>
              <ListItemIcon>
                <ConfigIcon />
              </ListItemIcon>
              <ListItemText primary="Configuration" />
              {isConfigMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}

          <Collapse in={isConfigMenuOpen} timeout="auto" unmountOnExit>
            <List disablePadding dense>
              <OpenMenuItems
                menuData={configItemData}
                indent={true}
                handleMenuClicked={handleMenuClicked}
              />
            </List>
          </Collapse>

          <ListItemButton onClick={toggleMyAccountMenuOpen}>
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="My Account" />
            {isMyAccountMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isMyAccountMenuOpen} timeout="auto" unmountOnExit>
            <List disablePadding dense>
              <OpenMenuItems
                menuData={myAccountItemData}
                indent={true}
                handleMenuClicked={handleMenuClicked}
              />
            </List>
          </Collapse>
          <Divider />
        </List>
      </Drawer>
      <Main open={isMenuOpen}>
        <DrawerHeader />
        {props.children}
      </Main>
    </Box>
  )
}

const OpenMenuItems = ({ menuData, indent = false, handleMenuClicked }) => {
  return menuData
    .filter((item) => item.canAccess())
    .map((item, index) =>
      "DIVIDER" === item.label ? (
        <Divider key={"menuitems-" + index} />
      ) : (
        createMenuLink(getOpenOption(item), indent, handleMenuClicked)
      )
    )
}

const DrawerItems = ({ drawItemData, handleMenuClicked }) => {
  return drawItemData
    .filter((item) => item.canAccess())
    .map((item, index) =>
      "DIVIDER" === item.label ? (
        <Divider key={"menuitems-" + index} />
      ) : (
        createMenuLink(getOpenOption(item), false, handleMenuClicked)
      )
    )
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

export default Header
