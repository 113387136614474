import { Chip, Typography } from "@mui/material"
import * as colors from "@mui/material/colors"

const CoverageChip = ({ coverage, small = false }) => {
    return (
        <Chip
            sx={{ backgroundColor: colors.lightBlue[50] }}
            label={
                small ? (
                    <Typography variant="caption">
                        {coverage.state}, {coverage.area}
                    </Typography>
                ) : (
                    `${coverage.state}, ${coverage.area}`
                )
            }
            size="small"
        />
    )
}

export default CoverageChip
