import React from "react"
import Header from "../components/Header"
import { withRouter } from "react-router-dom"
import LookupsList from "../components/LookupsList"

function LookupsEdit(props) {
  const { lookupType, lookupTitle } = props

  return (
    <Header title={lookupTitle}>
      <LookupsList lookupType={lookupType} />
    </Header>
  )
}

export default withRouter(LookupsEdit)
