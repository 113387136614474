import React, { useEffect, useState, useMemo } from "react"
import { styled } from "@mui/material/styles"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  CardActions,
  Collapse,
  Skeleton,
  Link as MuiLink,
  Pagination,
  Stack,
  Divider,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  CircularProgress,
} from "@mui/material"
import firebase from "firebase/compat/app"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import * as colors from "@mui/material/colors"
import { DeleteIcon } from "../icons"
import RefreshIcon from "@mui/icons-material/Refresh"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SettingsIcon from "@mui/icons-material/Settings"
import LinkButton from "./controls/LinkButton"
import ReactTimeAgo from "react-time-ago"
import _ from "lodash"
import DashboardWidgetConfigDialog from "./DashboardWidgetConfigDialog"
import CategoryIcon from "@mui/icons-material/Category"
import TruncatedText from "./TruncatedText"
import DashboardWidgetChart from "./DashboardWidgetChart"
import PieChartIcon from "@mui/icons-material/PieChart"
import AssessmentIcon from "@mui/icons-material/Assessment"
import BarChartIcon from "@mui/icons-material/BarChart"
import TimelineIcon from "@mui/icons-material/Timeline"
import ListIcon from "@mui/icons-material/List"
import DashboardWidgetTrendlineChart from "./DashboardWidgetTrendlineChart"

const CHART_DATA_LIVE = ""
const CHART_DATA_AVG_OPEN_DAYS = "avg_open_days"
const CHART_DATA_AVG_CLOSED_DAYS = "avg_closed_days"
const CHART_DATA_TOTAL_OPEN_COUNT = "total_open_count"
const CHART_DATA_TOTAL_CLOSED_COUNT = "total_closed_count"
const CHART_DATA_TOTAL_COUNT = "total_count"

const CHART_DATA_TOOLTIP = {
  [CHART_DATA_LIVE]: "Live",
  [CHART_DATA_AVG_OPEN_DAYS]: "Average Open Days",
  [CHART_DATA_AVG_CLOSED_DAYS]: "Average Closed Days",
  [CHART_DATA_TOTAL_OPEN_COUNT]: "Total Open Count",
  [CHART_DATA_TOTAL_CLOSED_COUNT]: "Total Closed Count",
  [CHART_DATA_TOTAL_COUNT]: "Total Count",
}

const JOB_DATE_FIELDS = {
  "open-before-date": {
    label: "Before",
    refField: "open_filter_date",
    template: "Opened ${label} ${refValue}",
  },
  "open-after-date": {
    label: "After",
    refField: "open_filter_date",
    template: "Opened ${label} ${refValue}",
  },
  "open-days-min": {
    label: "Up To",
    refField: "open_days_min",
    template: "Opened ${label} ${refValue} days ago",
  },
  "open-days-max": {
    label: "More Than",
    refField: "open_days_max",
    template: "Opened ${label} ${refValue} days ago",
  },
  "open-this-month": { label: "Opened This Month", refField: null },
  "open-last-month": { label: "Opened Last Month", refField: null },
  "open-prior-month": {
    label: "Prior Month",
    refField: "open_prior_month",
    template: "Opened ${refValue} months ago",
  },
  "closed-before-date": {
    label: "Before",
    refField: "closed_filter_date",
    template: "Closed ${label} ${refValue}",
  },
  "closed-after-date": {
    label: "After",
    refField: "closed_filter_date",
    template: "Closed ${label} ${refValue}",
  },
  "closed-days-min": {
    label: "Up To",
    refField: "closed_days_min",
    template: "Closed ${label} ${refValue} days ago",
  },
  "closed-days-max": {
    label: "More Than",
    refField: "closed_days_max",
    template: "Closed ${label} ${refValue} days ago",
  },
  "closed-this-month": { label: "Closed This Month", refField: null },
  "closed-last-month": { label: "Closed Last Month", refField: null },
  "closed-prior-month": {
    label: "Prior Month",
    refField: "closed_prior_month",
    template: "Closed ${refValue} months ago",
  },
}

const DashboardWidget = ({
  initialWidgetConfig,
  centres,
  suppliers,
  jobTypes,
  locations,
  priorities,
  userCentreIds,
  selectedWidget,
  setSelectedWidget,
  removeWidget,
  addedWidgetId,
  blankWidgetConfig,
}) => {
  const [configOpen, setConfigOpen] = useState(false)

  const pageSize = 10

  const [widgetConfig, setWidgetConfig] = useState(initialWidgetConfig)

  // For single value results
  const [content, setContent] = useState(undefined)

  const [dataPage, setDataPage] = useState(1)

  // Has attributes: count, value, sql, and when set causes jobs to be retrieved
  const [itemToRetrieveRows, setItemToRetrieveRows] = useState(undefined)

  // anchor for popup menu to set widget format - table, bar chart, pie chart
  const [formatAnchorEl, setFormatAnchorEl] = useState(null)

  const [groupByAnchorEl, setGroupByAnchorEl] = useState(null)

  const [chartDataAnchorEl, setChartDataAnchorEl] = useState(null)

  // For when we use group by
  const [groupedContent, setGroupedContent] = useState(undefined)

  // For when we show trend lines
  const [trendContent, setTrendContent] = useState(undefined)

  const [rows, setRows] = useState([])

  const [loadingJobs, setLoadingJobs] = useState(false)

  const setExpanded = (expanded) => {
    if (widgetConfig.config.expanded !== expanded) {
      const newWidgetConfig = {
        ...widgetConfig,
        config: {
          ...widgetConfig.config,
          expanded: expanded,
        },
      }

      // console.log("%csetWidgetConfig (1)", "color:dodgerblue", {
      //   newWidgetConfig,
      // })
      setWidgetConfig(newWidgetConfig)

      db.collection("dashboard").doc(widgetConfig.id).update({
        config: newWidgetConfig.config,
        modified: dataServices.serverTimestamp(),
      })
    }
  }

  useEffect(() => {
    if (addedWidgetId !== undefined && addedWidgetId === widgetConfig.id) {
      setConfigOpen(true)
    }
  }, [addedWidgetId, widgetConfig.id])

  const setFormat = (format) => {
    const newWidgetConfig = {
      ...widgetConfig,
      config: {
        ...widgetConfig.config,
        format: format,
      },
    }

    //console.log("%csetWidgetConfig (2)", "color:dodgerblue", {
    //  newWidgetConfig,
    //})
    setWidgetConfig(newWidgetConfig)

    // Update the db with the new format

    db.collection("dashboard").doc(widgetConfig.id).update({
      config: newWidgetConfig.config,
      modified: dataServices.serverTimestamp(),
    })
  }

  const clearContent = () => {
    setContent(undefined)
    setGroupedContent(undefined)
    setTrendContent(undefined)
  }

  const setChartData = (chartData) => {
    const newWidgetConfig = {
      ...widgetConfig,
      config: {
        ...widgetConfig.config,
        chart_data: chartData,
      },
    }

    // See if we need to modify the jobs statuses. For any Open or Closed chart data selection
    // we need to make sure the job statuses are in the group of ['open', 'pending'] or ['closed', 'completed']
    // It is ok if for an open chart data we have any one of 'open' or 'pending' and for a close chart data we have any of 'closed' or 'completed'

    const jobStatuses = { ...newWidgetConfig.config.job_statuses }

    if (jobStatuses.length > 0) {
      if (
        [CHART_DATA_AVG_OPEN_DAYS, CHART_DATA_TOTAL_OPEN_COUNT].includes(
          chartData
        )
      ) {
        // Make sure statuses do not contain values other than 'open' or 'pending'. If so, set to 'open' and 'pending'
        if (!jobStatuses.includes("open") || !jobStatuses.includes("pending")) {
          jobStatuses = ["open", "pending"]
        }
      } else if (
        [CHART_DATA_AVG_CLOSED_DAYS, CHART_DATA_TOTAL_CLOSED_COUNT].includes(
          chartData
        )
      ) {
        // Make sure statuses do not contain values other than 'closed' or 'completed'. If so, set to 'closed' and 'completed'
        if (
          !jobStatuses.includes("closed") ||
          !jobStatuses.includes("completed")
        ) {
          jobStatuses = ["closed", "completed"]
        }
      }

      newWidgetConfig.config.job_statuses = jobStatuses
    }

    // Clear any cached result, to force a reload
    delete newWidgetConfig.result
    clearContent()

    // If setting the chart data value to a trend chart then we need to
    // make sure there is also a group by setting, otherwise the chart will not display

    if (
      [
        CHART_DATA_AVG_OPEN_DAYS,
        CHART_DATA_AVG_CLOSED_DAYS,
        CHART_DATA_TOTAL_OPEN_COUNT,
        CHART_DATA_TOTAL_CLOSED_COUNT,
        CHART_DATA_TOTAL_COUNT,
      ].includes(chartData) &&
      newWidgetConfig.config.group_by === ""
    ) {
      newWidgetConfig.config.group_by = "centre_id"
    }

    // console.log("%csetWidgetConfig (3)", "color:dodgerblue", {
    //   newWidgetConfig,
    // })
    setWidgetConfig(newWidgetConfig)

    // Update the db with the new format

    db.collection("dashboard").doc(widgetConfig.id).update({
      config: newWidgetConfig.config,
      modified: dataServices.serverTimestamp(),
    })
  }

  const setGroupBy = (groupBy) => {
    const newWidgetConfig = {
      ...widgetConfig,
      config: {
        ...widgetConfig.config,
        group_by: groupBy,
      },
    }
    // triggers reload...
    delete newWidgetConfig.result
    clearContent()
    clearJobRows()

    // console.log("%csetWidgetConfig (4)", "color:dodgerblue", {
    //   newWidgetConfig,
    // })
    setWidgetConfig(newWidgetConfig)

    db.collection("dashboard").doc(widgetConfig.id).update({
      config: newWidgetConfig.config,
      modified: dataServices.serverTimestamp(),
    })
  }

  const clearJobRows = () => {
    setRows([])
    setItemToRetrieveRows(undefined)
  }

  const parseDateStrToTimestampSeconds = (dateStr) => {
    const date = new Date(dateStr)
    return firebase.firestore.Timestamp.fromDate(date).seconds
  }

  const getDateRangeThisMonth = () => {
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
    const lastDay = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      0,
      23,
      59,
      59
    )

    const firstSecsThisMonth = parseDateStrToTimestampSeconds(firstDay)
    const lastSecsThisMonth = parseDateStrToTimestampSeconds(lastDay)

    return { firstSecsThisMonth, lastSecsThisMonth }
  }

  const getDateRangeLastMonth = () => {
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const lastDay = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59)

    const firstSecsLastMonth = parseDateStrToTimestampSeconds(firstDay)
    const lastSecsLastMonth = parseDateStrToTimestampSeconds(lastDay)

    return { firstSecsLastMonth, lastSecsLastMonth }
  }

  const isWidgetConfigBlank = () => {
    return _.isEqual(blankWidgetConfig.config, widgetConfig.config)
  }

  const isWidgetRefreshRequired = useMemo(() => {
    if (isWidgetConfigBlank()) {
      return false
    }

    // For trend charts, they're refreshed once per week (Sunday night) so we don't need to refresh
    // unless the last load data was before the last Sunday night
    const isTrendChart = [
      CHART_DATA_AVG_OPEN_DAYS,
      CHART_DATA_AVG_CLOSED_DAYS,
      CHART_DATA_TOTAL_OPEN_COUNT,
      CHART_DATA_TOTAL_CLOSED_COUNT,
      CHART_DATA_TOTAL_COUNT,
    ].includes(widgetConfig.config.chart_data)

    // If there is no cached data we need to therefore load it
    if (!widgetConfig.hasOwnProperty("result")) {
      return true
    } else if (widgetConfig.result?.length === 0) {
      return true
    }

    const now = new Date()

    const modified = widgetConfig.modified.toDate()

    const diff = now.getTime() - modified.getTime()
    const diffSecs = Math.round(diff / 1000)

    // We can cache the results of this type of widget for longer since it is based on date from the previous month
    const longerRefresh =
      ["closed-last-month", "closed-prior-month"].includes(
        widgetConfig.config.closed_date_type
      ) ||
      ["open-last-month", "open-prior-month"].includes(
        widgetConfig.config.open_date_type
      )

    // For trend charts, check if the modified date is before the last Sunday night

    if (isTrendChart) {
      const lastSunday = new Date()
      lastSunday.setDate(now.getDate() - now.getDay())

      const lastSundayMidnight = new Date(
        lastSunday.getFullYear(),
        lastSunday.getMonth(),
        lastSunday.getDate(),
        0,
        0,
        0
      )

      const lastSundayMidnightSecs = lastSundayMidnight.getTime() / 1000

      const modifiedSecs = modified.getTime() / 1000

      const refresh = modifiedSecs < lastSundayMidnightSecs

      // console.log("%crefresh trend", "color:yellow", {
      //   widget: widgetConfig.title,
      //   refresh,
      //   modifiedSecs,
      //   lastSundayMidnightSecs,
      // })

      return refresh
    } else {
      // Cache results from last month 20x more, ui.e. 200 minutes, since no need to refresh as often
      const refresh = diffSecs > 10 * 60 * (longerRefresh ? 20 : 1)
      return refresh
    }
  }, [widgetConfig])

  const getTotalLabelFilterInfo = (widgetConfig) => {
    switch (widgetConfig.config.group_by_filter) {
      case "top":
        return `Top ${widgetConfig.config.group_by_filter_top}`

      case "bottom":
        return `Bottom ${widgetConfig.config.group_by_filter_bottom}`

      case "range":
        if (
          widgetConfig.config.group_by_filter_min !== undefined &&
          widgetConfig.config.group_by_filter_max !== undefined
        ) {
          return `Range ${widgetConfig.config.group_by_filter_min} - ${widgetConfig.config.group_by_filter_max}`
        } else if (widgetConfig.config.group_by_filter_min !== undefined) {
          return `Min ${widgetConfig.config.group_by_filter_min}`
        } else if (widgetConfig.config.group_by_filter_max !== undefined) {
          return `Max ${widgetConfig.config.group_by_filter_max}`
        } else {
          return ""
        }
      default:
        return ""
    }
  }

  const getTotalLabelTrendInfo = (widgetConfig) => {
    return CHART_DATA_TOOLTIP[widgetConfig.config.chart_data]
  }

  const totalLabel = useMemo(() => {
    return [
      getTotalLabelTrendInfo(widgetConfig),
      getTotalLabelFilterInfo(widgetConfig),
    ]
      .filter((part) => part)
      .join(" | ")
  }, [widgetConfig])

  /**
   * The SQL for all the widget config params
   */
  const widgetSql = useMemo(() => {
    const where = []

    if (widgetConfig.config.centre_ids.length > 0) {
      // Limit search by centres that user selected, which is a subset of what they're allowed to see
      where.push(
        `centre_id in (${widgetConfig.config.centre_ids
          .map((id) => `'${id}'`)
          .join(", ")})`
      )
    } else if (userCentreIds.length > 0) {
      // Limit search by centres that user is allowed to see
      where.push(
        `centre_id in (${userCentreIds.map((id) => `'${id}'`).join(", ")})`
      )
    }

    if (widgetConfig.config.job_types.length > 0) {
      where.push(
        `category in (${widgetConfig.config.job_types
          .map((id) => `'${id}'`)
          .join(", ")})`
      )
    }

    if (widgetConfig.config.locations.length > 0) {
      where.push(
        `location in (${widgetConfig.config.locations
          .map((id) => `'${id}'`)
          .join(", ")})`
      )
    }

    if (widgetConfig.config.priority > 0) {
      where.push(`priority = '${widgetConfig.config.priority}'`)
    }

    // The job statuses filter is newer than job status and supercedes it be allowing multiple statuses to be selected
    // so we use job statuses in preference to job status if it is set
    // Only legacy widget configs will have job statuses undefined.
    if (widgetConfig.config.job_statuses?.length > 0) {
      where.push(
        `status in (${widgetConfig.config.job_statuses
          .map((status) => `'${status}'`)
          .join(", ")})`
      )
      // } else if (widgetConfig.config.job_status !== "") {
      //   where.push(`status = '${widgetConfig.config.job_status}'`)
    }

    if (widgetConfig.config.job_allocation === "allocated") {
      where.push(`work_order_id !=''`)
    } else if (widgetConfig.config.job_allocation === "unallocated") {
      where.push(`work_order_id = ''`)
    }

    const { firstSecsThisMonth, lastSecsThisMonth } = getDateRangeThisMonth()
    const { firstSecsLastMonth, lastSecsLastMonth } = getDateRangeLastMonth()

    if (widgetConfig.config.open_date_type !== "") {
      switch (widgetConfig.config.open_date_type) {
        case "open-before-date":
          if (widgetConfig.config.open_filter_date !== null) {
            const beforeSecs = parseDateStrToTimestampSeconds(
              widgetConfig.config.open_filter_date
            )
            where.push(`created < ${beforeSecs}`)
          }
          break

        case "open-after-date":
          if (widgetConfig.config.open_filter_date !== null) {
            const afterSecs = parseDateStrToTimestampSeconds(
              widgetConfig.config.open_filter_date
            )
            where.push(`created > ${afterSecs}`)
          }
          break

        case "open-days-min":
          if (widgetConfig.config.open_days_min > 0) {
            const now = new Date()
            const then = new Date(now)

            then.setDate(now.getDate() - widgetConfig.config.open_days_min)

            const afterSecsOpen = parseDateStrToTimestampSeconds(then)
            where.push(`created > ${afterSecsOpen}`)
          }
          break

        case "open-days-max":
          if (widgetConfig.config.open_days_max > 0) {
            const now = new Date()
            const then = new Date(now)

            then.setDate(now.getDate() - widgetConfig.config.open_days_max)

            const beforeSecsOpen = parseDateStrToTimestampSeconds(then)
            where.push(`created < ${beforeSecsOpen}`)
          }
          break

        case "open-this-month":
          // Get 1st and last day of this month
          where.push(`created >= ${firstSecsThisMonth}`)
          where.push(`created <= ${lastSecsThisMonth}`)
          break

        case "open-last-month":
          // Get 1st and last day of last month
          where.push(`created >= ${firstSecsLastMonth}`)
          where.push(`created <= ${lastSecsLastMonth}`)
          break

        case "open-prior-month":
          // Work out what month to use, where 0=this month, 1=previous month, etc.
          const priorMonth = widgetConfig.config.open_prior_month
          const now = new Date()
          const firstDay = new Date(
            now.getFullYear(),
            now.getMonth() - priorMonth,
            1
          )
          const lastDay = new Date(
            now.getFullYear(),
            now.getMonth() - priorMonth + 1,
            0,
            23,
            59,
            59
          )

          const firstSecsPriorMonth = parseDateStrToTimestampSeconds(firstDay)
          const lastSecsPriorMonth = parseDateStrToTimestampSeconds(lastDay)

          where.push(`created >= ${firstSecsPriorMonth}`)
          where.push(`created <= ${lastSecsPriorMonth}`)
          break

        default:
        // no op
      }
    }

    if (widgetConfig.config.closed_date_type !== "") {
      switch (widgetConfig.config.closed_date_type) {
        case "closed-before-date":
          if (widgetConfig.config.closed_filter_date !== null) {
            const beforeSecs = parseDateStrToTimestampSeconds(
              widgetConfig.config.closed_filter_date
            )
            where.push(`closed < ${beforeSecs}`)
          }
          break

        case "closed-after-date":
          if (widgetConfig.config.closed_filter_date !== null) {
            const afterSecs = parseDateStrToTimestampSeconds(
              widgetConfig.config.closed_filter_date
            )
            where.push(`closed > ${afterSecs}`)
          }
          break

        case "closed-days-min":
          if (widgetConfig.config.closed_days_min > 0) {
            const now = new Date()
            const then = new Date(now)

            then.setDate(now.getDate() - widgetConfig.config.closed_days_min)

            const afterSecsOpen = parseDateStrToTimestampSeconds(then)
            where.push(`closed > ${afterSecsOpen}`)
          }
          break

        case "closed-days-max":
          if (widgetConfig.config.closed_days_max > 0) {
            const now = new Date()
            const then = new Date(now)

            then.setDate(now.getDate() - widgetConfig.config.closed_days_max)

            const beforeSecsOpen = parseDateStrToTimestampSeconds(then)
            where.push(`closed < ${beforeSecsOpen}`)
          }
          break

        case "closed-this-month":
          // Get 1st and last day of this month

          where.push(`closed >= ${firstSecsThisMonth}`)
          where.push(`closed <= ${lastSecsThisMonth}`)
          break

        case "closed-last-month":
          // Get 1st and last day of last month
          where.push(`closed >= ${firstSecsLastMonth}`)
          where.push(`closed <= ${lastSecsLastMonth}`)
          break

        case "closed-prior-month":
          // Work out what month to use, where 0=this month, 1=previous month, etc.
          const priorMonthClosed = widgetConfig.config.closed_prior_month
          const nowClosed = new Date()
          const firstDayClosed = new Date(
            nowClosed.getFullYear(),
            nowClosed.getMonth() - priorMonthClosed,
            1
          )
          const lastDayClosed = new Date(
            nowClosed.getFullYear(),
            nowClosed.getMonth() - priorMonthClosed + 1,
            0,
            23,
            59,
            59
          )

          const firstSecsPriorMonthClosed =
            parseDateStrToTimestampSeconds(firstDayClosed)
          const lastSecsPriorMonthClosed =
            parseDateStrToTimestampSeconds(lastDayClosed)

          where.push(`closed >= ${firstSecsPriorMonthClosed}`)
          where.push(`closed <= ${lastSecsPriorMonthClosed}`)
          break

        default:
        // no op
      }
    }

    return where.join(" and ")
  }, [widgetConfig, userCentreIds])

  useEffect(() => {
    // console.log("%cuseEffect::widgetConfig", "color:dodgerblue", {
    //   config: widgetConfig.config,
    //   isWidgetRefreshRequired,
    // })

    handleGetWidgetData(widgetConfig, isWidgetRefreshRequired)
  }, [widgetConfig.config, isWidgetRefreshRequired])

  useEffect(() => {
    if (dataPage !== undefined && itemToRetrieveRows) {
      getJobs(itemToRetrieveRows, dataPage)
    }
  }, [dataPage, itemToRetrieveRows])

  const handleGetWidgetData = async (widgetData, forceReload) => {
    if (widgetData.config.chart_data === CHART_DATA_LIVE) {
      getLiveWidgetData(widgetConfig, forceReload)
    } else {
      getTrendWidgetData(widgetConfig, forceReload)
    }
  }

  const getLiveWidgetData = async (widgetConfig, forceReload) => {
    let widgetData

    // Check if widgetConfig.modified is more than 10 minutes old
    // If so, refresh the data
    if (isWidgetRefreshRequired || forceReload) {
      const cols =
        widgetConfig.config.group_by === ""
          ? "count(*) as count"
          : `${widgetConfig.config.group_by} as value, count(*) as count`

      const groupBy =
        widgetConfig.config.group_by === "" ? "" : widgetConfig.config.group_by

      const result = await cloudFunctions.queryViewByAccountId("jobs_view", {
        columns: cols,
        whereClause: widgetSql,
        groupBy: groupBy,
        groupByFilter: widgetConfig.config.group_by_filter,
        groupByFilterMin: widgetConfig.config.group_by_filter_min,
        groupByFilterMax: widgetConfig.config.group_by_filter_max,
        groupByFilterTop: widgetConfig.config.group_by_filter_top,
        groupByFilterBottom: widgetConfig.config.group_by_filter_bottom,
      })

      // Cache result

      db.collection("dashboard").doc(initialWidgetConfig.id).update(
        {
          result: result.data[0],
          config: widgetConfig.config,
          modified: dataServices.serverTimestamp(),
        },
        { merge: true }
      )

      // console.log("%csetWidgetConfig (5)", "color:dodgerblue", {
      //   widgetConfig,
      // })
      setWidgetConfig({
        ...widgetConfig,
        modified: dataServices.localTimestamp(),
      })

      widgetData = result.data[0]
    } else {
      widgetData = widgetConfig.result
    }

    if (widgetData) {
      if (widgetConfig.config.group_by === "") {
        const value = widgetData.reduce((a, b) => a + b.count, 0)
        setGroupedContent(undefined)
        setContent(value)
      } else {
        setContent(undefined)
        const keys = Object.keys(widgetData)
        const grouped = keys.map((key) => widgetData[key])

        const withSql = grouped.map((item) => {
          const sqlParts = [`${widgetConfig.config.group_by} = '${item.value}'`]

          // Add the common SQL for the widget config params
          if (widgetSql !== "") {
            sqlParts.push(widgetSql)
          }

          return { ...item, sql: Array.from(new Set(sqlParts)).join(" and ") }
        })

        // console.log("%cwithSql", "color:orange", widgetConfig.title, {
        //   keys,
        //   grouped,
        //   withSql,
        // })

        const getMapped = () => {
          switch (widgetConfig.config.group_by) {
            case "centre_id":
              return withSql.map((item) => {
                const centre = centres.find((c) => c.id === item.value)

                return {
                  ...item,
                  id: centre?.id,
                  // Use short_name if available, otherwise use name
                  value: centre?.short_name || centre?.name || "No centre",
                }
              })

            case "supplier_id":
              return withSql.map((item) => {
                const supplier = suppliers.find((s) => s.id === item.value)

                return {
                  ...item,
                  id: supplier?.id,
                  value: supplier?.name || "No supplier",
                }
              })

            case "priority":
              return withSql.map((item) => {
                const priority = priorities.find(
                  (p) => `${p.id}` === item.value
                )

                return {
                  ...item,
                  id: priority?.id,
                  value: priority?.title || "No priority",
                }
              })

            default:
              return withSql.map((item) => ({ ...item, id: item.value }))
          }
        }

        const mapped = getMapped()

        const sorted = mapped.sort((a, b) => {
          const countDiff = b.count - a.count
          if (countDiff !== 0) {
            return countDiff
          } else {
            return a.value.localeCompare(b.value)
          }
        })
        if (sorted.length > 0) {
          setGroupedContent(sorted)
        } else {
          setContent("0")
        }
      }
    }
  }

  const getTrendWidgetData = async (widgetConfig, forceReload) => {
    const load = isWidgetRefreshRequired || forceReload

    if (!load) {
      // console.log("%cusing cached trend results", "color:lightgreen", {
      //   widgetConfig,
      // })
      setContent(undefined)
      setGroupedContent(undefined)
      setTrendContent(widgetConfig.result)
      return
    }

    const calcType = [
      CHART_DATA_AVG_OPEN_DAYS,
      CHART_DATA_TOTAL_OPEN_COUNT,
      CHART_DATA_TOTAL_COUNT,
    ].includes(widgetConfig.config.chart_data)
      ? "open"
      : "closed"

    const valueType = [
      CHART_DATA_AVG_OPEN_DAYS,
      CHART_DATA_AVG_CLOSED_DAYS,
    ].includes(widgetConfig.config.chart_data)
      ? "avg"
      : "total"

    const snapshotParams = {
      whereClause: widgetSql,
      groupCol: widgetConfig.config.group_by,
      calcType: calcType,
      valueType: valueType,
    }

    // console.log("%cgetTrendWidgetData", "color:orange", {
    //   widgetConfig,
    //   snapshotParams,
    // })

    cloudFunctions
      .queryJobSnapshot(snapshotParams)
      .then((result) => {
        const xAxisValues = Array.from(
          new Set(result.map((row) => row.snapshot_date))
        ).sort((a, b) => a.localeCompare(b))

        const xAxis = [
          {
            data: xAxisValues.map((val) => {
              // convert yyyy-mm-dd to dd-mmm, where mmm is the 3 letter month, and mm was the 2 digit month, e.g. convert 12 -> Dec (where Dec is mixed case)
              const parts = val.split("-")
              const month = new Date(val + "T00:00:00").toLocaleString(
                "en-us",
                {
                  month: "short",
                }
              )
              return `${parts[2]}-${month}`
            }),
            scaleType: "band",
            id: "x-axis-id",
          },
        ]

        const series = result.reduce((acc, row) => {
          const seriesIndex = acc.findIndex(
            (accItem) => accItem.label === row[widgetConfig.config.group_by]
          )

          const xAxisIndex = xAxisValues.findIndex(
            (x) => x === row.snapshot_date
          )

          if (seriesIndex === -1) {
            const data = []
            data[xAxisIndex] = row.value ? Math.round(row.value) : null

            acc.push({
              label: row[widgetConfig.config.group_by],
              type: "line",
              data: data,
              value: row[widgetConfig.config.group_by],
            })
          } else {
            acc[seriesIndex].data[xAxisIndex] = row.value
          }

          return acc
        }, [])

        // Fill all data elements with null if empty, so that it can be stored in Firebase
        series.forEach((item) => {
          for (let i = 0; i < xAxisValues.length; i++) {
            if (item.data[i] === undefined) {
              item.data[i] = null
            }
          }
        })

        // Sort by highest value in the 'data' array first
        const sortedSeries = series.sort((a, b) => {
          const aMax = Math.max(...a.data)
          const bMax = Math.max(...b.data)

          return bMax - aMax
        })

        const withSql = sortedSeries.map((item) => {
          const sqlParts = [`${widgetConfig.config.group_by} = '${item.label}'`]

          // Add the common SQL for the widget config params
          if (widgetSql !== "") {
            sqlParts.push(widgetSql)
          }

          return { ...item, sql: Array.from(new Set(sqlParts)).join(" and ") }
        })
        //console.log("%cwithSql", "color:orange", { withSql })

        const getMappedSeries = (seriesData) => {
          switch (widgetConfig.config.group_by) {
            case "centre_id":
              return seriesData.map((item) => {
                //const centre = centres.find((c) => c.id === item.label)
                const centre = centres.find((c) => c.id === item.value)
                return {
                  ...item,
                  // Use short_name if available, otherwise use name
                  label: centre?.short_name || centre?.name || "No centre",
                }
              })

            case "supplier_id":
              return seriesData.map((item) => {
                const supplier = suppliers.find((s) => s.id === item.value)

                return {
                  ...item,
                  label: supplier?.name || "No supplier",
                }
              })

            case "priority":
              return seriesData.map((item) => {
                const priority = priorities.find(
                  (p) => `${p.id}` === item.value
                )
                return {
                  ...item,
                  label: priority?.title || "No priority",
                }
              })

            default:
              return seriesData.map((item) => ({ ...item }))
          }
        }

        const mappedSeries = getMappedSeries(withSql)

        let subsetSeries
        switch (widgetConfig.config.group_by_filter) {
          case "top":
            // Get top n, or full set of values if less than n
            subsetSeries = mappedSeries.slice(
              0,
              parseInt(widgetConfig.config.group_by_filter_top)
            )
            break

          case "bottom":
            // Get bottom n, or just full set of values if less than n
            subsetSeries = mappedSeries.slice(
              Math.max(
                0,
                mappedSeries.length -
                  parseInt(widgetConfig.config.group_by_filter_bottom)
              )
            )
            break

          default:
            subsetSeries = mappedSeries
        }

        const newTrendContent = { series: subsetSeries, xAxis }

        // console.log("%cnewTrendContent", "color:orange", {
        //   newTrendContent,
        //   widgetConfig,
        // })

        // Cache result

        db.collection("dashboard").doc(initialWidgetConfig.id).update(
          {
            result: newTrendContent,
            config: widgetConfig.config,
            modified: dataServices.serverTimestamp(),
          },
          { merge: true }
        )

        setTrendContent(newTrendContent)
        setContent(undefined)
        setGroupedContent(undefined)
        /**
         * series={[
        {
          label: "type1",
          type: "line",
          data: [4, 3, 1, 3, 4],
        },
        {
          label: "type2",
          type: "line",
          data: [3, 2, 3, 4, 5],
        },
      ]}
      xAxis={[
        {
          data: ["A", "B", "C", "D", "E"],
          scaleType: "band",
          id: "x-axis-id",
        },
      ]}
         */
      })
      .catch((err) => {
        console.log("%csnapshot error", "color:red", { err })
      })
  }

  const handleDelete = async () => {
    await db.collection("dashboard").doc(widgetConfig.id).delete()
    removeWidget(widgetConfig.id)
  }

  const getAutoTitle = (widgetConfig) => {
    //console.log("getAutoTitle", widgetConfig)
    if (!widgetConfig.config.auto_title) {
      return widgetConfig.title
    }

    const c = widgetConfig.config

    const capitaliseOnlyFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    }

    const titleParts = []

    if (c.centre_ids.length > 0) {
      if (c.centre_ids.length <= 3) {
        const centreShortNames = c.centre_ids.map((centreId) => {
          const centre = centres.find((c) => c.id === centreId)
          return centre.short_name || centre.name
        })
        titleParts.push(centreShortNames.join(", "))
      } else if (c.centre_ids.length > 3) {
        titleParts.push(`${c.centre_ids.length} centres`)
      }
    }

    if (c.job_types.length > 0) {
      if (c.job_types.length <= 3) {
        titleParts.push(c.job_types.join(", "))
      } else if (c.job_types.length > 3) {
        titleParts.push(`${c.job_types.length} job types`)
      }
    }

    if (c.locations.length > 0) {
      if (c.locations.length <= 3) {
        titleParts.push(c.locations.join(", "))
      } else if (c.locations.length > 3) {
        titleParts.push(`${c.locations.length} locations`)
      }
    }

    if (c.job_statuses.length > 0) {
      // Capitalize the first letter of each status
      const statusValues = c.job_statuses.map((status) => {
        return status.charAt(0).toUpperCase() + status.slice(1)
      })
      titleParts.push(statusValues.join(", "))
    }

    if (c.job_allocation !== "") {
      titleParts.push(capitaliseOnlyFirstLetter(c.job_allocation))
    }

    if (c.priority > 0) {
      const priority = priorities.find((p) => p.id === c.priority)
      titleParts.push(priority.title)
    }

    const substitute = (template, values) => {
      return template.replace(/\$\{(\w+)\}/g, (placeholder, key) => {
        return values[key] || placeholder
      })
    }

    if (c.open_date_type !== "") {
      // Find the JOB_DATE_FIELD value that matches the key in JOB_DATE_FIELDS
      const dateField = JOB_DATE_FIELDS[c.open_date_type]
      const refValue = c[dateField.refField] || ""
      //console.log("dateField", dateField, refValue)
      if (dateField.template) {
        titleParts.push(
          capitaliseOnlyFirstLetter(
            substitute(dateField.template, { label: dateField.label, refValue })
          )
        )
      } else {
        titleParts.push([dateField.label, refValue].join(" "))
      }
    }

    if (c.closed_date_type !== "") {
      // Find the JOB_DATE_FIELD value that matches the key in JOB_DATE_FIELDS
      const dateField = JOB_DATE_FIELDS[c.closed_date_type]
      const refValue = c[dateField.refField] || ""
      //console.log("dateField", dateField, refValue)
      if (dateField.template) {
        titleParts.push(
          capitaliseOnlyFirstLetter(
            substitute(dateField.template, { label: dateField.label, refValue })
          )
        )
      } else {
        titleParts.push([dateField.label, refValue].join(" "))
      }
    }

    // console.log("titleParts", titleParts)

    return titleParts.join(" | ")
  }

  const saveWidgetConfig = (widgetConfig) => {
    const newWidgetConfig = {
      ...widgetConfig,
      title: getAutoTitle(widgetConfig),
      result: widgetConfig.result,
    }
    setWidgetConfig(newWidgetConfig)
    clearJobRows()

    // Check if widgetConfig.result is empty array
    if (newWidgetConfig.result && newWidgetConfig.result.length === 0) {
      // So that we show the progress indicator
      clearContent()
    }

    // console.log("%csaveWidgetConfig (6)", "color:dodgerblue", {
    //   newWidgetConfig,
    // })

    const updateData = {
      title: newWidgetConfig.title,
      config: newWidgetConfig.config,
      result: newWidgetConfig.result,
      modified: dataServices.serverTimestamp(),
    }

    console.log("updateData", updateData)

    if (updateData.result === undefined) {
      delete updateData.result
    }

    db.collection("dashboard").doc(widgetConfig.id).update(updateData)
  }

  const getJobs = async (item, page) => {
    setLoadingJobs(true)
    setRows([])

    if (item.count === undefined) {
      const result = await cloudFunctions.queryViewByAccountId("jobs_view", {
        columns: "count(*) as count",
        whereClause: item.sql,
        groupBy: "",
        groupByFilter: widgetConfig.config.group_by_filter,
        groupByFilterMin: widgetConfig.config.group_by_filter_min,
        groupByFilterMax: widgetConfig.config.group_by_filter_max,
        groupByFilterTop: widgetConfig.config.group_by_filter_top,
        groupByFilterBottom: widgetConfig.config.group_by_filter_bottom,
      })

      //console.log("%ccount", "color:greenyellow", { item, result })

      item.count = result.data[0][0].count
    }

    cloudFunctions
      .queryViewByAccountId("jobs_view", {
        columns: "document_id as id, label, created",
        whereClause: item.sql,
        page: page,
        pageSize: pageSize,
        sortCols: ["3 desc", "1 asc"],
      })
      .then((result) => {
        setLoadingJobs(false)
        setRows(result.data[0].sort((a, b) => a.label.localeCompare(b.label)))
        setExpanded(true)
      })
  }

  const handleSelectWidget = () => {
    if (selectedWidget && selectedWidget === widgetConfig.position) {
      setSelectedWidget(undefined)
    } else {
      setSelectedWidget(widgetConfig.position)
    }
  }

  const totalCount = useMemo(() => {
    if ((content !== undefined || groupedContent) && widgetConfig) {
      if (widgetConfig.config.group_by === "") {
        return content
      } else {
        return groupedContent?.reduce((a, b) => a + b.count, 0)
      }
    }
  }, [content, groupedContent, widgetConfig])

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: widgetConfig.config.expanded ? "325px" : "160px",
          border:
            selectedWidget && selectedWidget === widgetConfig.position
              ? `1px solid ${colors.blue[900]}`
              : "1px solid #ccc",
        }}
        onClick={handleSelectWidget}
      >
        <CardHeader
          sx={{ padding: "5px" }}
          title={
            <Tooltip title={widgetConfig.title}>
              <Typography
                component="span"
                variant="caption"
                sx={{
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: widgetConfig.config.expanded ? "285px" : "105px",
                  fontWeight: "bold",
                  mt: "4px",
                }}
              >
                {widgetConfig.title}
              </Typography>
            </Tooltip>
          }
          subheader={
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="caption" sx={{ color: "#aaa" }}>
                <ReactTimeAgo
                  date={widgetConfig.modified.toDate()}
                  locale="en-AU"
                />
              </Typography>
              {totalLabel && (
                <Box sx={{ marginLeft: "auto" }}>
                  <Typography variant="caption" sx={{ color: "#aaa" }}>
                    {totalLabel}
                  </Typography>
                </Box>
              )}
            </Box>
          }
          action={
            <IconButton
              aria-label="settings"
              size="small"
              onClick={() => setConfigOpen(true)}
            >
              <SettingsIcon />
            </IconButton>
          }
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
            flexGrow: 1,
          }}
        >
          <Stack sx={{ display: "flex", alignItems: "center" }}>
            {totalCount !== undefined && (
              <Typography
                variant="h1"
                sx={{ fontWeight: "bold", color: "#518688" }}
              >
                {totalCount}
              </Typography>
            )}

            {widgetConfig.config.chart_data !== CHART_DATA_LIVE &&
              trendContent && (
                <Box sx={{ ml: "5px", mr: "5px" }}>
                  <DashboardWidgetTrendlineChart
                    widgetConfig={widgetConfig}
                    trendContent={trendContent}
                    setItemToRetrieveRows={setItemToRetrieveRows}
                    isTrendTotalCount={[
                      CHART_DATA_TOTAL_OPEN_COUNT,
                      CHART_DATA_TOTAL_CLOSED_COUNT,
                    ].includes(widgetConfig.config.chart_data)}
                  />
                </Box>
              )}

            {widgetConfig.config.chart_data === CHART_DATA_LIVE &&
              ["pie", "bar"].includes(widgetConfig.config.format) && (
                <DashboardWidgetChart
                  type={widgetConfig.config.format}
                  groupedContent={groupedContent}
                  expanded={widgetConfig.config.expanded}
                  // Enable clicking on chart to retrieve jobs for the selected chart item (bar, pie slice)
                  setItemToRetrieveRows={setItemToRetrieveRows}
                />
              )}

            {content === undefined &&
              groupedContent === undefined &&
              trendContent === undefined && <CircularProgress size={50} />}
          </Stack>
          <Collapse
            in={widgetConfig.config.expanded}
            timeout="auto"
            unmountOnExit
          >
            <Stack gap={1} sx={{ mt: "15px" }}>
              {widgetConfig.config.chart_data === CHART_DATA_LIVE &&
                widgetConfig.config.format === "" && (
                  <Box sx={{ ml: "20px", mr: "10px" }}>
                    <GroupedResults
                      groupBy={widgetConfig.config.group_by}
                      groupedContent={groupedContent}
                      setDataPage={setDataPage}
                      setItemToRetrieveRows={setItemToRetrieveRows}
                    />
                  </Box>
                )}
              <JobList
                rows={rows}
                dataPage={dataPage}
                setDataPage={setDataPage}
                itemToRetrieveRows={itemToRetrieveRows}
                pageSize={pageSize}
                loadingJobs={loadingJobs}
              />
            </Stack>
          </Collapse>
        </CardContent>
        <CardActions
          sx={{
            pt: 0, // paddingTop shorthand
            pb: 0, // paddingBottom shorthand
            display: "flex",
            justifyContent: "flex-end", // Aligns children (Box) to the right
            alignItems: "flex-end", // Aligns children vertically to the end
            width: "100%", // Ensures it takes full width if not already
          }}
        >
          <Box sx={{ display: "flex", marginLeft: "auto" }}>
            {widgetConfig.config.expanded && (
              <Tooltip
                title={`Chart Data - ${
                  CHART_DATA_TOOLTIP[widgetConfig.config.chart_data]
                }`}
              >
                <IconButton
                  onClick={(e) => setChartDataAnchorEl(e.currentTarget)}
                >
                  {widgetConfig.config.chart_data === "" && (
                    <AssessmentIcon fontSize="small" />
                  )}
                  {[
                    CHART_DATA_AVG_OPEN_DAYS,
                    CHART_DATA_AVG_CLOSED_DAYS,
                    CHART_DATA_TOTAL_OPEN_COUNT,
                    CHART_DATA_TOTAL_CLOSED_COUNT,
                    CHART_DATA_TOTAL_COUNT,
                  ].includes(widgetConfig.config.chart_data) && (
                    <TimelineIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            )}

            {chartDataAnchorEl && (
              <ChartDataMenu
                chartData={widgetConfig.config.chart_data}
                setChartData={setChartData}
                anchorEl={chartDataAnchorEl}
                setAnchorEl={setChartDataAnchorEl}
              />
            )}

            {widgetConfig.config.expanded && (
              <Tooltip title="Group by">
                <IconButton
                  onClick={(e) => setGroupByAnchorEl(e.currentTarget)}
                >
                  <CategoryIcon />
                </IconButton>
              </Tooltip>
            )}

            {groupByAnchorEl && (
              <GroupByMenu
                groupBy={widgetConfig.config.group_by}
                setGroupBy={setGroupBy}
                anchorEl={groupByAnchorEl}
                setAnchorEl={setGroupByAnchorEl}
              />
            )}

            {widgetConfig.config.expanded && (
              <Tooltip title="Layout">
                <IconButton onClick={(e) => setFormatAnchorEl(e.currentTarget)}>
                  {widgetConfig.config.format === "" && (
                    <ListIcon fontSize="small" />
                  )}
                  {widgetConfig.config.format === "pie" && (
                    <PieChartIcon fontSize="small" />
                  )}
                  {widgetConfig.config.format === "bar" && (
                    <BarChartIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            )}

            {formatAnchorEl && (
              <FormatMenu
                format={widgetConfig.config.format}
                setFormat={setFormat}
                anchorEl={formatAnchorEl}
                setAnchorEl={setFormatAnchorEl}
              />
            )}

            <Tooltip title="Refresh">
              <IconButton
                onClick={() => {
                  setContent(undefined)
                  setGroupedContent(undefined)
                  setRows([])

                  handleGetWidgetData(widgetConfig, true)
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <ExpandMore
              expand={widgetConfig.config.expanded}
              onClick={() => setExpanded(!widgetConfig.config.expanded)}
              aria-expanded={widgetConfig.config.expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </CardActions>
      </Card>
      {configOpen && (
        <DashboardWidgetConfigDialog
          open={configOpen}
          onClose={() => setConfigOpen(false)}
          widgetConfig={widgetConfig}
          centres={centres}
          jobTypes={jobTypes}
          locations={locations}
          priorities={priorities}
          setWidgetConfig={saveWidgetConfig}
        />
      )}
    </>
  )
}

const getWaiting = (count, page, pageSize) => {
  // Work out how many items left to show on this page
  // Unless we're on the last page, the number of items to show is pageSize

  const isLastPage = count - (page - 1) * pageSize <= pageSize
  const n = isLastPage ? count - (page - 1) * pageSize : pageSize

  return Array.from({ length: n }, (_, i) => (
    <Skeleton variant="rectangular" width={280} height={14} key={i} />
  ))
}

const JobList = ({
  rows,
  dataPage,
  setDataPage,
  itemToRetrieveRows,
  pageSize,
  loadingJobs = false,
}) => {
  return (
    <Box sx={{ margin: "0px" }}>
      <Box sx={{ ml: "15px" }}>
        {itemToRetrieveRows?.count > 0 && (
          <Stack sx={{ mb: "5px", mt: "15px" }}>
            <TruncatedText sx={{ width: "280px", fontWeight: "bold" }}>
              {itemToRetrieveRows.count} Jobs for{" "}
              {itemToRetrieveRows.value || "(None)"}
            </TruncatedText>
            {itemToRetrieveRows.showTrendDataInfo && (
              <Typography variant="caption" color="text.secondary">
                Live data totals may not match weekly snapshot totals
              </Typography>
            )}
          </Stack>
        )}
        <Stack direction="column" gap={0.5}>
          {rows &&
            !loadingJobs &&
            rows.map((row) => (
              <Box key={row.id}>
                <LinkButton to={`/jobedit/${row.id}`}>
                  <TruncatedText sx={{ width: "280px" }} variant="body2">
                    {row.label}
                  </TruncatedText>
                </LinkButton>
              </Box>
            ))}
          {loadingJobs && (
            <Stack gap={1} sx={{ mt: "5px" }}>
              {getWaiting(itemToRetrieveRows.count, dataPage, pageSize)}
            </Stack>
          )}
        </Stack>
      </Box>

      <Stack gap={1}>
        {rows && rows.length > 0 && itemToRetrieveRows.count > pageSize && (
          <>
            <Divider sx={{ mt: "10px", mb: "10px" }} />
            <Pagination
              sx={{ mt: "10px" }}
              color={"primary"}
              size="small"
              count={Math.ceil(itemToRetrieveRows.count / pageSize)}
              boundaryCount={2}
              shape="rounded"
              page={dataPage}
              onChange={(event, value) => {
                setDataPage(value)
              }}
            />
          </>
        )}
      </Stack>
    </Box>
  )
}

const FormatMenu = ({ format = "", setFormat, anchorEl, setAnchorEl }) => {
  const tableSx = format === "" ? { backgroundColor: "#f0f0f0" } : {}
  const barSx = format === "bar" ? { backgroundColor: "#f0f0f0" } : {}
  const pieSx = format === "pie" ? { backgroundColor: "#f0f0f0" } : {}

  const handleSelect = (format) => {
    setFormat(format)
    setAnchorEl(null)
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem onClick={() => handleSelect("")} sx={tableSx}>
        <ListItemIcon>
          <ListIcon fontSize="small" />
        </ListItemIcon>
        Table
      </MenuItem>
      <MenuItem onClick={() => handleSelect("bar")} sx={barSx}>
        <ListItemIcon>
          <BarChartIcon fontSize="small" />
        </ListItemIcon>
        Bar Chart
      </MenuItem>
      <MenuItem onClick={() => handleSelect("pie")} sx={pieSx}>
        <ListItemIcon>
          <PieChartIcon fontSize="small" />
        </ListItemIcon>
        Pie Chart
      </MenuItem>
    </Menu>
  )
}

const ChartDataMenu = ({
  chartData = "",
  setChartData,
  anchorEl,
  setAnchorEl,
}) => {
  const menuItems = [
    {
      key: CHART_DATA_LIVE,
      title: "Live - Totals",
      icon: <AssessmentIcon fontSize="small" />,
    },
    {
      key: CHART_DATA_TOTAL_COUNT,
      title: "Trend - Totals",
      icon: <TimelineIcon fontSize="small" />,
    },
    {
      key: CHART_DATA_AVG_OPEN_DAYS,
      title: "Trend - Average Open Days",
      icon: <TimelineIcon fontSize="small" />,
    },
    {
      key: CHART_DATA_AVG_CLOSED_DAYS,
      title: "Trend - Average Closed Days",
      icon: <TimelineIcon fontSize="small" />,
    },
    {
      key: CHART_DATA_TOTAL_OPEN_COUNT,
      title: "Trend - Total Open Count",
      icon: <TimelineIcon fontSize="small" />,
    },
    {
      key: CHART_DATA_TOTAL_CLOSED_COUNT,
      title: "Trend - Total Closed Count",
      icon: <TimelineIcon fontSize="small" />,
    },
  ]

  const handleSelect = (value) => {
    setChartData(value)
    setAnchorEl(null)
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      {menuItems.map((item) => (
        <MenuItem
          key={item.key}
          onClick={() => handleSelect(item.key)}
          sx={chartData === item.key ? { backgroundColor: "#f0f0f0" } : {}}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          {item.title}
        </MenuItem>
      ))}
    </Menu>
  )
}

const GroupByMenu = ({ groupBy, setGroupBy, anchorEl, setAnchorEl }) => {
  const handleSelect = (value) => {
    setGroupBy(value)
    setAnchorEl(null)
  }

  const groupByItems = [
    { id: "centre_id", title: "Centre" },
    { id: "status", title: "Job Status" },
    { id: "category", title: "Job Type" },
    { id: "location", title: "Location" },
    { id: "priority", title: "Priority" },
    { id: "supplier_id", title: "Supplier" },
  ]

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      {groupByItems.map((item) => (
        <MenuItem
          key={item.id}
          onClick={() => handleSelect(item.id)}
          sx={groupBy === item.id ? { backgroundColor: "#f0f0f0" } : {}}
        >
          {item.title}
        </MenuItem>
      ))}
    </Menu>
  )
}

const GroupedResults = ({
  groupBy,
  groupedContent,
  setDataPage,
  setItemToRetrieveRows,
}) => {
  const labelWidth = 275

  return (
    <Box>
      {groupedContent !== undefined && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {groupedContent.map((item) => (
            <Box
              key={`${groupBy}-${item.value}-${item.sql}`}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: `${labelWidth}px`,
              }}
            >
              <Box sx={{ width: `${labelWidth - 30}px` }}>
                <MuiLink
                  onClick={() => {
                    setDataPage(1) // reset page
                    setItemToRetrieveRows(item)
                  }}
                  sx={{ cursor: "hand" }}
                >
                  <Tooltip title="Click to see jobs for this group">
                    <TruncatedText noWrap variant="body2">
                      {item.value || "(None)"}
                    </TruncatedText>
                  </Tooltip>
                </MuiLink>
              </Box>

              <Box sx={{ marginLeft: "auto" }}>
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignSelf: "flex-end",
                    marginLeft: "auto",
                  }}
                >
                  {item.count}
                </Typography>
              </Box>
            </Box>
          ))}
          {groupedContent.length > 1 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: `${labelWidth}px`,
                borderTop: "1px dotted #888",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignSelf: "flex-end",
                  marginLeft: "auto",
                  fontWeight: "bold",
                }}
              >
                {groupedContent.reduce((a, b) => a + b.count, 0)}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default DashboardWidget
