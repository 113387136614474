import {
  Alert,
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import Controls from "./controls/Controls"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import * as Roles from "../pages/services/roleServices"
import { useSnackbar } from "notistack"
import Button from "./controls/Button"
import YesNo from "./YesNo"
import * as actionServices from "../pages/services/actionServices"
import ActionStatusChip from "./controls/ActionStatusChip"
import DraggablePaper from "../components/DraggablePaper"
import AddComment from "./AddComment"
import ActionComment from "./ActionComment"
import * as UIConstants from "../components/controls/UIConstants"
import { uppercaseFirstLetter } from "../pages/services/formatting"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const ActionDialog = ({
  parentId,
  parentType,
  centreId,
  id,
  setOpen,
  open,
}) => {
  const [accountId, setAccountId] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [isSupplierViewing, setSupplierViewing] = useState(false)
  const [isJobAdmin, setJobAdmin] = useState(false)

  const [claims, setClaims] = useState()

  const [editAssignedTo, setEditAssignedTo] = useState(true)

  const { enqueueSnackbar } = useSnackbar()

  // action value
  const [values, setValues] = useState({
    description: "",
    assigned_to: "",
  })

  const OPTION_TYPE_SUPPLIER = "Supplier"

  const [actionId, setActionId] = useState()

  const [comments, setComments] = useState([])

  const [job, setJob] = useState()

  const [options, setOptions] = useState([])

  const [statusAnchorEl, setStatusAnchorEl] = useState(null)

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete Action",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const [supplier, setSupplier] = useState()

  useEffect(() => {
    if (centreId) {
      setValues((curr) => ({ ...curr, centre_id: centreId }))
    }
  }, [centreId])

  const addOption = (option) => {
    const optionExists = options.find((o) => o.email === option.email)

    if (optionExists) {
      return
    }

    setOptions((curr) =>
      [option, ...curr].sort((a, b) => {
        // If an option has a 'type', sort it first, then sort by name

        if (a.type && !b.type) {
          return -1
        } else if (b.type && !a.type) {
          return 1
        }

        if (a.name < b.name) {
          return -1
        } else if (a.name > b.name) {
          return 1
        }
      })
    )
  }

  useEffect(() => {
    if (parentId && parentType) {
      db.collection("jobs")
        .doc(parentId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const jobData = doc.data()
            setJob(jobData)

            setValues((curr) => ({
              ...curr,
              description: jobData.label,
            }))

            if (jobData.user_id) {
              db.collection("users")
                .doc(jobData.user_id)
                .get()
                .then((userDoc) => {
                  const userData = userDoc.data()
                  addOption({
                    id: userDoc.id,
                    name: `${userData.name}`,
                    email: userData.email,
                    type: "Job Owner",
                  })
                })
            }

            if (jobData.centre_id) {
              db.collection("centres")
                .doc(jobData.centre_id)
                .get()
                .then((centreDoc) => {
                  const centreData = centreDoc.data()

                  if (centreDoc.data().user_id_contact) {
                    db.collection("users")
                      .doc(centreDoc.data().user_id_contact)
                      .get()
                      .then((userDoc) => {
                        const userData = userDoc.data()
                        addOption({
                          id: userDoc.id,
                          name: `${userData.name}`,
                          email: userData.email,
                          type: "Centre Manager",
                        })
                      })
                  } else {
                    addOption({
                      id: centreDoc.id,
                      name: `${centreData.name}`,
                      email: centreData.email,
                      type: "Main Centre Contact",
                    })
                  }
                })
            }
          }
        })
    }
  }, [parentId, parentType])

  useEffect(() => {
    if (claims?.account_type === Roles.ACCOUNT_TYPE_CENTRE && supplier) {
      if (supplier.supplier_account_id) {
        // Get email from supplier's account record -- since they may have changed it

        cloudFunctions
          .getSupplierOrgEmail({
            supplierAccountId: supplier.supplier_account_id,
          })
          .then((result) => {
            addOption({
              id: supplier.supplier_account_id,
              name: `${supplier.name}`,
              email: result.data.email,
              type: OPTION_TYPE_SUPPLIER,
            })
          })
          .catch((error) => {
            console.error("error getting supplier account", error)
          })
      } else {
        const assignToSupplierOption = {
          id: supplier.id,
          name: supplier.name,
          email: supplier.email,
          type: OPTION_TYPE_SUPPLIER,
        }
        addOption(assignToSupplierOption)
      }
    }
  }, [claims, supplier])

  useEffect(() => {
    setActionId(id)
  }, [id])

  // Get query to load comments, which is based on the account id of the parent reo
  const getCommentsQuery = ({ actionId, jobAccountId }) => {
    return db
      .collection("actions")
      .doc(actionId)
      .collection("comments")
      .where("account_id", "==", jobAccountId)
  }

  // Load comment changes
  useEffect(() => {
    if (!actionId) {
      return
    }

    if (values?.account_id === undefined) {
      return
    }

    const unsub = getCommentsQuery({
      actionId,
      jobAccountId: values?.account_id,
    }).onSnapshot(
      (querySnapshot) => {
        const newComments = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .sort((a, b) => {
            // For some reason, newly created records return 'created' as null, so treat those as the newest
            if (a.created === null) {
              return 1
            } else if (b.created === null) {
              return -1
            }
            return a.created.seconds - b.created.seconds
          })

        setComments(newComments)
      },
      (error) => console.error(`error loading action comments`, error)
    )

    return unsub
  }, [actionId, values?.account_id])

  useEffect(() => {
    if (actionId) {
      db.collection("actions")
        .doc(actionId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setValues((curr) => ({ ...curr, ...doc.data() }))
          }

          addOption({
            id: doc.data().created_by,
            name: doc.data().created_by,
            email: doc.data().created_by,
            type: "Action Creator",
          })

          if (doc.data().assigned_to !== doc.data().created_by) {
            addOption({
              id: doc.data().assigned_to,
              name: doc.data().assigned_to,
              email: doc.data().assigned_to,
              type: "Assigned To",
            })
          }
        })
        .catch((error) => {
          console.error("error getting action", error)
        })
    }
  }, [actionId])

  // Get supplier and work order
  useEffect(() => {
    if (!parentId || !parentType || !claims) {
      return
    }

    if (claims.account_type !== Roles.ACCOUNT_TYPE_CENTRE) {
      return
    }

    db.collection("jobs")
      .doc(parentId)
      .get()
      .then((jobDoc) => {
        // Get work order id from job
        const workOrderId = jobDoc.data().work_order_id

        if (workOrderId) {
          db.collection("work_orders")
            .doc(workOrderId)
            .get()
            .then((workOrderDoc) => {
              const supplierId = workOrderDoc.data().supplier_id
              if (supplierId) {
                db.collection("suppliers")
                  .doc(supplierId)
                  .get()
                  .then((supplierDoc) => {
                    if (
                      supplierDoc.exists &&
                      supplierDoc.data().supplier_account_id
                    ) {
                      const newSupplier = {
                        id: supplierDoc.id,
                        ...supplierDoc.data(),
                      }
                      setSupplier(newSupplier)
                    }
                  })
              }
            })
        }
      })
  }, [parentId, parentType, claims])

  useEffect(() => {
    if (accountId && userEmail && job && parentId && parentType) {
      if (!actionId) {
        setValues((curr) => {
          const newValues = {
            ...curr,
            created_by: userEmail,
            modified_by: userEmail,
            // leave blank for user to enter
            description: "",
            parent_id: parentId,
            type: parentType,
            // Need to use the job's account id, not the user's account id since the user could be a supplier.
            account_id: job.account_id,
            assigned_to: "",
            status: actionServices.STATUS_OPEN,
          }

          if (isSupplierViewing) {
            // Make it so the supplier can see the action
            newValues.supplier_account_id = claims.account_id
          }

          return newValues
        })
        setEditAssignedTo(true)
      }
    }
  }, [actionId, accountId, userEmail, job, parentId, isSupplierViewing])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult().then((token) => {
        setAccountId(token.claims.account_id)
        setClaims(token.claims)
        if (token.claims.email_verified) {
          setUserEmail(token.claims.email)
          setJobAdmin(token.claims.roles.includes("job_admin"))
        }

        setSupplierViewing(token.claims.account_type === "supplier")
      })
    })
    return unsub
  }, [])

  const [timeoutRef, setTimeoutRef] = useState()

  const handleSaveAction = async () => {
    if (!values.description) {
      enqueueSnackbar("Please enter a description", { variant: "info" })
      return false
    }

    if (!values.assigned_to) {
      enqueueSnackbar("Please enter an assigned to", { variant: "info" })
      return false
    }

    // Is the option selected with a type of 'Supplier'?

    const selectedOption = options.find((o) => o.email === values.assigned_to)

    if (selectedOption.type === OPTION_TYPE_SUPPLIER) {
      values.supplier_account_id = supplier.supplier_account_id
    }

    if (values.created === undefined) {
      values.created = dataServices.serverTimestamp()
    }
    if (values.modified === undefined) {
      values.modified = dataServices.serverTimestamp()
    }

    if (actionId) {
      await db.collection("actions").doc(actionId).update(values)
    } else {
      await db.collection("actions").add(values)
    }
    setOpen(false)
    return true
  }

  const getAssignToQueries = (searchVal) => {
    return [
      {
        type: "user",
        query: db
          .collection("users")
          .where("account_id", "==", accountId)
          .where("name", ">=", searchVal)
          .orderBy("name")
          .startAt(searchVal)
          .endAt(searchVal + "\uf8ff")
          .limit(5),
      },
      {
        type: "user",
        query: db
          .collection("users")
          .where("account_id", "==", accountId)
          .where("email", ">=", searchVal)
          .orderBy("email")
          .startAt(searchVal)
          .endAt(searchVal + "\uf8ff")
          .limit(5),
      },
      {
        type: "supplier",
        query: db
          .collection("suppliers")
          .where("account_id", "==", accountId)
          .where("email", ">=", searchVal)
          .orderBy("email")
          .startAt(searchVal)
          .endAt(searchVal + "\uf8ff")
          .limit(5),
      },
      {
        type: "supplier",
        query: db
          .collection("suppliers")
          .where("account_id", "==", accountId)
          .where("name", ">=", searchVal)
          .orderBy("name")
          .startAt(searchVal)
          .endAt(searchVal + "\uf8ff")
          .limit(5),
      },
    ]
  }

  const handleUpdateOptions = (newValue) => {
    if (timeoutRef) {
      clearTimeout(timeoutRef)
      setTimeoutRef(undefined)
    }

    if (newValue !== "") {
      const t = setTimeout(async () => {
        const queries = getAssignToQueries(newValue)
        const results = await Promise.all(
          queries.map(async (q) => ({
            type: q.type,
            result: await q.query.get(),
          }))
        )

        const options = results.flatMap((queryResult) =>
          queryResult.result.docs.map((doc) => {
            const { name, email } = doc.data()
            return {
              id: doc.id,
              name,
              email,
              type: queryResult.type,
            }
          })
        )

        console.log("%cnew options", "color:lightGreen", { options })

        // Remove options if they already exist

        const newOptions = options.filter(
          (option) => !options.find((o) => o.email === option.email)
        )

        for (const option of newOptions) {
          addOption(option)
        }
      }, 700)

      setTimeoutRef(t)
    }
  }

  const handleDeleteComment = (commentId) => {
    // If there are no subcomments then delete the comment, otherwise replace the text with '[Deleted]'

    if (comments.find((c) => c.parent_id === commentId)) {
      const commentRef = db
        .collection("actions")
        .doc(actionId)
        .collection("comments")
        .doc(commentId)
      commentRef.update(
        { comment: "[Deleted]", modified: dataServices.serverTimestamp() },
        { merge: true }
      )
    } else {
      // Just delete the comment

      const commentRef = db
        .collection("actions")
        .doc(actionId)
        .collection("comments")
        .doc(commentId)
      commentRef.delete().then(() => {})
    }

    updateActionModified()
  }

  const updateActionModified = () => {
    const actionRef = db.collection("actions").doc(actionId)
    actionRef.update(
      { modified: dataServices.serverTimestamp(), modified_by: userEmail },
      { merge: true }
    )
  }

  const handleUpdateComment = (commentId, commentText) => {
    const batch = db.batch()

    // Update modified date of the action, and comment text as a batch

    const actionRef = db.collection("actions").doc(actionId)
    batch.update(actionRef, {
      modified: dataServices.serverTimestamp(),
      modified_by: userEmail,
    })

    const commentRef = db
      .collection("actions")
      .doc(actionId)
      .collection("comments")
      .doc(commentId)

    batch.update(commentRef, {
      comment: commentText,
      modified: dataServices.serverTimestamp(),
    })

    batch.commit().then(() => {
      // setComments((curr) =>
      //     curr.map((c) => (c.id === commentId ? { ...c, comment: commentText } : c))
      // )
    })

    updateActionModified()
  }

  const handleAddComment = async ({
    commentText,
    parentCommentId,
    parentAccountId,
  }) => {
    if (commentText && commentText.trim() === "") {
      return
    }

    const comment = {
      account_id: parentAccountId,
      created_by: userEmail,
      created: dataServices.serverTimestamp(),
      modified: dataServices.serverTimestamp(),
      comment: commentText,
    }

    if (claims.account_type === Roles.ACCOUNT_TYPE_SUPPLIER) {
      comment.supplier_account_id = claims.account_id
    }

    if (parentCommentId) {
      comment.parent_id = parentCommentId
    }

    let actionDocRef

    if (actionId === undefined) {
      const saved = await handleSaveAction()
      if (!saved) {
        return
      }
    } else {
      actionDocRef = db.collection("actions").doc(actionId)

      // Update modified date and modified_by of the action

      actionDocRef.update({
        modified: dataServices.serverTimestamp(),
        modified_by: userEmail,
      })
    }

    if (actionDocRef) {
      actionDocRef.collection("comments").add(comment)
    }
  }

  const assignToCentreOption = useMemo(() => {
    if (claims?.account_type === Roles.ACCOUNT_TYPE_CENTRE) {
      const autoCompleteOption = {
        id: job?.centre_id,
        title: job?.centre_name,
        email: job?.centre_email,
        type: "ASSIGN_TO_CENTRE",
      }
      return autoCompleteOption
    }
    return null
  }, [claims, job])

  // const handleAssignToCentre = async () => {
  //   // Get center info, and also the centre manager if there is one.
  //   // We need this for the 'assign to centre' functionality
  //   const jobDoc = await db.collection("jobs").doc(parentId).get()

  //   const centreDoc = await db
  //     .collection("centres")
  //     .doc(jobDoc.data().centre_id)
  //     .get()

  //   const centreData = centreDoc.data()

  //   let email = centreData.email

  //   if (centreData.user_id_contact) {
  //     const userDoc = await db
  //       .collection("users")
  //       .doc(centreData.user_id_contact)
  //       .get()

  //     if (userDoc.exists) {
  //       email = userDoc.data().email
  //     }
  //   }

  //   const newValues = {
  //     ...values,
  //     assigned_to: email,
  //     modified: dataServices.serverTimestamp(),
  //   }
  //   setValues(newValues)
  // }

  // const handleAssignToSupplier = async () => {
  //   if (!supplier) {
  //     enqueueSnackbar(
  //       "Supplier is not setup to login to the portal. Cannot assign to them.",
  //       { variant: "info" }
  //     )
  //     return
  //   }

  //   const supplierEmail = supplier.email
  //   const supplierAccountId = supplier.supplier_account_id

  //   if (supplierEmail) {
  //     const newValues = {
  //       ...values,
  //       assigned_to: supplierEmail,
  //       supplier_account_id: supplierAccountId,
  //       modified: dataServices.serverTimestamp(),
  //       status: actionServices.STATUS_OPEN,
  //     }

  //     setValues(newValues)

  //     if (actionId) {
  //       await db.collection("actions").doc(actionId).update(newValues)

  //       enqueueSnackbar("Assigned to supplier", { variant: "success" })
  //       setOpen(false)
  //     } else {
  //       enqueueSnackbar("Click Save", { variant: "success" })
  //     }
  //   }
  // }

  const handlePromptDeleteAction = () => {
    setYesNoConfig((curr) => ({
      ...curr,
      openPrompt: true,
      handleConfirm: handleDeleteAction,
    }))
  }

  const handleDeleteAction = () => {
    setYesNoConfig((curr) => ({
      ...curr,
      openPrompt: false,
    }))

    actionServices.deleteAction(id)
    setOptions([])
    setOpen(false)
  }

  const allowedStatusChanges = useMemo(() => {
    return actionServices.getAllowedActionStatusValues({
      action: values,
      userEmail,
      isJobAdmin,
      isSupplierViewing,
    })
  }, [values, userEmail, isJobAdmin, isSupplierViewing])

  const handleUpdateAction = async ({ status }) => {
    setStatusAnchorEl(null)
    setValues({ ...values, status })

    if (actionId) {
      await actionServices.updateActionStatus(actionId, values, status)
    }
    setOpen(false)
  }

  return (
    <>
      <YesNo config={yesNoConfig} />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        // Make this this dialog is behind the YesNo dialog
        sx={{ zIndex: 1000 }}
        PaperComponent={DraggablePaper}
      >
        <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
          Action for {job?.label}
        </DialogTitle>

        <DialogContent sx={{ minWidth: "300px" }}>
          <Stack direction="column" spacing={3}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box sx={{ marginLeft: "auto" }}>
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{
                      paddingTop: "5px",
                      cursor: actionId ? "hand" : "auto",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      if (actionId) {
                        setStatusAnchorEl(e.currentTarget)
                      }
                    }}
                  >
                    <ActionStatusChip
                      status={values.status}
                      showBadge={actionServices.isShowActionBadge(
                        values,
                        userEmail,
                        isJobAdmin
                      )}
                    />
                    {actionId && (
                      <IconButton size="small">
                        <MoreVertIcon />
                      </IconButton>
                    )}
                  </Stack>
                </Box>
                <Menu
                  id="status-menu"
                  anchorEl={statusAnchorEl}
                  open={Boolean(statusAnchorEl)}
                  onClose={() => setStatusAnchorEl(null)}
                >
                  {allowedStatusChanges &&
                    allowedStatusChanges.map((status) => (
                      <MenuItem
                        key={status}
                        onClick={() => handleUpdateAction({ status })}
                      >
                        Mark as {uppercaseFirstLetter(status)}
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            </Box>{" "}
            <Controls.TextInput
              name="description"
              fullWidth
              autoFocus
              label="Your request"
              value={values.description}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
            />
            {editAssignedTo && (
              <AssignToAutocomplete
                values={values}
                options={options}
                setValues={setValues}
                handleUpdateOptions={handleUpdateOptions}
              />
            )}
            {actionId && (
              <>
                <Comments
                  comments={comments}
                  userEmail={userEmail}
                  handleAddComment={handleAddComment}
                  handleUpdateComment={handleUpdateComment}
                  handleDeleteComment={handleDeleteComment}
                />
                {values?.account_id && (
                  <AddComment
                    handleAddComment={handleAddComment}
                    parentCommentId={undefined}
                    parentAccountId={values.account_id}
                  />
                )}
              </>
            )}
          </Stack>
          {values.supplier_account_id &&
            claims?.account_type === Roles.ACCOUNT_TYPE_CENTRE && (
              <Alert severity="info">Supplier can see this action</Alert>
            )}
        </DialogContent>
        <DialogActions>
          {id && (
            <Button
              onClick={handlePromptDeleteAction}
              text="Delete"
              tooltip="Delete action"
            />
          )}
          {/* <Controls.Button onClick={handleSave} text="Save" /> */}
          <Controls.Button
            onClick={() => setOpen(false)}
            text="Cancel"
            tooltip="Close window"
          />
          <Controls.Button text="Save" onClick={handleSaveAction} />
        </DialogActions>
      </Dialog>
    </>
  )
}

const Comments = ({
  comments,
  userEmail,
  handleAddComment,
  handleUpdateComment,
  handleDeleteComment,
}) => {
  return (
    <Box>
      <Label text="Messages" />

      {comments.length === 0 && (
        <Box sx={{ marginTop: 0, paddingtop: 0 }}>
          <Typography variant="body2" color="text.primary">
            No messages entered yet
          </Typography>
        </Box>
      )}
      {comments
        .filter((c) => c.parent_id === undefined)
        .map((c) => (
          <Box key={c.id} sx={{ marginTop: "10px" }}>
            <ActionComment
              comment={c}
              userEmail={userEmail}
              handleAddComment={handleAddComment}
              handleUpdateComment={handleUpdateComment}
              handleDeleteComment={handleDeleteComment}
              nesting={0}
              comments={comments}
            />
          </Box>
        ))}
    </Box>
  )
}

const AssignToAutocomplete = ({
  values,
  setValues,
  options,
  handleUpdateOptions,
}) => {
  return (
    <Autocomplete
      multiple={false}
      onChange={(event, newValue) => {
        setValues({ ...values, assigned_to: newValue?.email })
      }}
      onInputChange={(event, newInputValue) => {
        handleUpdateOptions(newInputValue)
      }}
      isOptionEqualToValue={(option, value) => {
        return option?.email === values.assigned_to
      }}
      filterOptions={(options, params) => {
        return options
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <div>
              <Box>
                <Box>
                  <Typography>{option.name}</Typography>
                </Box>
                <Stack direction="row" gap={0.5}>
                  <Typography variant="caption" color="text.secondary">
                    {option.email}
                  </Typography>
                  {option.type && (
                    <Typography variant="caption">{option.type}</Typography>
                  )}
                </Stack>
              </Box>
            </div>
          </li>
        )
      }}
      options={options}
      getOptionLabel={(option) => {
        return option?.name || ""
      }}
      value={options.find((o) => o.email === values.assigned_to) || null}
      renderInput={(params) => (
        <TextField
          {...params}
          label={"Assign To (enter email)"}
          placeholder="Enter name or email"
          variant="outlined"
          sx={{ minWidth: "300px" }}
        />
      )}
      variant={UIConstants.STANDARD_INPUT_VARIANT}
    />
  )
}

const Label = ({ text }) => {
  return (
    <Box sx={{ marginTop: "10px" }}>
      <Typography variant="caption" color="text.secondary">
        {text}
      </Typography>
    </Box>
  )
}

export default ActionDialog
