import React, { useEffect, useState } from "react"
import ComboBox from "./ComboBox"
import * as dataServices from "../../pages/services/dataServices"
import _ from "lodash"

const UserCombobox = ({
  name,
  label,
  value,
  onChange,
  helperText,
  readonly,
  accountId,
  userIdsFilter = [],
}) => {
  // userIdsFilter is the array of user ids that can be shown in the combobox.

  const [reloadState, setReloadState] = useState({
    readonly: false,
    value: "",
    accountId: "",
    userIdsFilter: [],
  })

  const [userOptions, setUserOptions] = useState([
    {
      id: "",
      label: "-- Select --",
    },
  ])

  function createUserOption(user) {
    return {
      id: user.id,
      label: user.name,
    }
  }

  const isValueChangedOnly = (newReloadState) => {
    const isValueChanged =
      newReloadState.readonly === reloadState.readonly &&
      newReloadState.value !== reloadState.value &&
      newReloadState.accountId === reloadState.accountId &&
      newReloadState.userIdsFilter.length ===
        reloadState.userIdsFilter.length &&
      JSON.stringify(newReloadState.userIdsFilter.sort) ===
        JSON.stringify(reloadState.userIdsFilter.sort)

    return isValueChanged
  }

  useEffect(() => {
    if (accountId === undefined) {
      return
    }

    const newReloadState = { readonly, value, accountId, userIdsFilter }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    setReloadState(newReloadState)

    if (readonly) {
      if (value !== "") {
        const userIds = [value]
        dataServices.getUsersById(accountId, userIds).then((users) => {
          const options = users.map((user) => createUserOption(user))
          setUserOptions(options)
        })
      }
    } else {
      if (!isValueChangedOnly(newReloadState)) {
        dataServices
          .getUsersByAccountId(accountId, false, userIdsFilter)
          .then((users) => {
            const options = users
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((centre) => createUserOption(centre))
            options.unshift({ id: "", label: "-- Select --" })
            setUserOptions(options)
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readonly, value, accountId, userIdsFilter])

  return (
    <ComboBox
      name={name}
      label={label}
      value={value}
      items={userOptions}
      onChange={onChange}
      helperText={helperText}
      disabled={readonly}
    />
  )
}

export default UserCombobox
