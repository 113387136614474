import React from "react"
import Header from "../components/Header"
import ActionGrid from "../components/ActionGrid"

const Actions = () => {
    return (
        <Header title="Actions">
            <ActionGrid />
        </Header>
    )
}

export default Actions
