import React, { useState, useEffect, createRef, useMemo } from "react"
import {
  Box,
  Badge,
  Card,
  CardContent,
  CardActionArea,
  Grid,
  Typography,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material"
import { Link } from "react-router-dom"
import { styled } from "@mui/material/styles"
import Controls from "./controls/Controls"
import * as dataServices from "../pages/services/dataServices"
import ReactTimeAgo from "react-time-ago"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import DoneIcon from "@mui/icons-material/Done"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"
import AutorenewIcon from "@mui/icons-material/Autorenew"
import ImageSearchIcon from "@mui/icons-material/ImageSearch"
import db from "../Firestore"
import { resizeFile, getJobFileUrl } from "../pages/services/fileServices"
import * as jobServices from "../pages/services/jobServices"
import YesNo from "./YesNo"
import { LinearProgress } from "@mui/material"
import ImageModal from "./ImageModal"
import ImageCard from "./controls/ImageCard"
import { getStorage, ref, uploadString } from "firebase/storage"
import { spacing } from "../pages/services/styleServices"

const styles = {
  root: {
    flexGrow: 1,
    minWidth: 330,
    maxWidth: 330,
  },
  paper: {
    padding: spacing(1),
    textAlign: "center",
    color: "theme.palette.text.secondary",
  },
  statusChip: {
    marginBottom: "5px",
  },
  jobCardActionArea: {
    marginBottom: "3px",
  },
}

const JobCard = ({ job, setJob }) => {
  const url = useMemo(() => {
    return "/JobEdit/" + job.id
  }, [job])

  const [centreName, setCentreName] = useState("")

  const [anchorEl, setAnchorEl] = useState(null)

  // image being viewed
  const [selectedFile, setSelectedFile] = useState(null)

  const open = Boolean(anchorEl)

  const addFileRef = createRef()

  const [isShowProgress, setShowProgress] = useState(false)

  const [files, setFiles] = useState([])

  const [filesMeta, setFilesMeta] = useState([])

  const [isShowPhotos, setShowPhotos] = useState(false)

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete File?",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  useEffect(() => {
    if (job.centre_id && job.centre_id !== "") {
      dataServices
        .getCentresById([job.centre_id])
        .then((centres) => {
          if (centres && centres.length > 0) {
            setCentreName(centres[0].name)
          } else {
            console.error("Can't find centre for job", job.id)
          }
        })
        .catch((error, code) =>
          console.error("Unable to retrieve centre name", error, code)
        )
    }
  }, [job])

  // Configure file urls for thumbnails

  const loadJobFileMetaData = async () => {
    const urls = job.docs.map(async (doc) => {
      return {
        fileName: doc,
        url: await getJobFileUrl(job.id, job.account_id, doc),
      }
    })

    const result = await Promise.all(urls)

    setFilesMeta(result)
  }

  useEffect(() => {
    loadJobFileMetaData()
  }, [])

  const handleJobMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCompleteJob = () => {
    changeJobStatus("closed")
  }

  const handleReopenJob = () => {
    changeJobStatus("open")
  }

  const changeJobStatus = async (newStatus) => {
    setJob({ ...job, status: newStatus })
    await dataServices.changeJobStatus(
      job.id,
      job.account_id,
      job.work_order_id,
      newStatus
    )
    setAnchorEl(null)
  }

  const handleCloseJobMenu = () => {
    setAnchorEl(null)
  }

  const handleFileUpload = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]

      setShowProgress(true)

      const uniqueFileName = jobServices.getUniqueFileName(job, file.name)

      resizeFile(file).then(async (resized) => {
        // FIXME: be able to accumulate files keyed on file name, don't just overwrite
        const updatedFiles = [
          ...files,
          {
            id: uniqueFileName,
            name: uniqueFileName,
            data: resized,
          },
        ]

        setFiles(updatedFiles)

        // // {
        // //   base64data: <base65 data>
        // //   id: <id of record against which file will be stored>
        // //   collection: <name of collection against which file will be stored, e.g. 'jobs', 'work_orders'>
        // //   filename: <filename>
        // // }

        // const data = {
        //     base64data: resized,
        //     id: job.id,
        //     collection: "jobs",
        //     filename: file.name,
        // }

        setShowProgress(true)

        const folderPath = `accounts/${job.account_id}/jobs/${job.id}`

        const fullFilePath = `${folderPath}/${uniqueFileName}`

        const storageRef = ref(getStorage(), fullFilePath)

        const result = await uploadString(storageRef, resized, "data_url")

        setShowProgress(false)

        setFiles([])

        // Clear file input field
        event.target.value = null

        // Update the job doc so it contains the correct list of file attachments

        const newFiles = [...job.docs, uniqueFileName]

        const mergeValues = {
          docs: newFiles,
        }

        await db
          .collection("jobs")
          .doc(job.id)
          .update(mergeValues, { merge: true })

        job.docs = newFiles

        // Allow 3 second for uploaded file to be available for download and display as thumbnail
        new Promise((resolve) => setTimeout(loadJobFileMetaData, 3000))
        setShowPhotos(true)
      })

      setShowProgress(false)
    }
  }

  const handleStoreSelectedPhoto = (event) => {
    console.log(
      "store selected photo",
      addFileRef.current,
      addFileRef.current.value
    )
  }

  const handleClickAddPhoto = (event) => {
    addFileRef.current.click()
  }

  const handlePromptConfirmDeleteFile = (fileName) => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteFile(fileName),
    }
    setYesNoConfig(newConfig)
  }

  const toggleViewPhotos = () => {
    setShowPhotos(!isShowPhotos)
  }

  const handleDeleteFile = async (fileName) => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
      handleConfirm: undefined,
    }
    setYesNoConfig(newConfig)

    setShowProgress(true)

    const newValues = await jobServices.deleteJobFile({
      job,
      jobId: job.id,
      fileName,
    })

    setJob(newValues)

    const newMeta = filesMeta.filter(
      (fileMeta) => fileMeta.fileName !== fileName
    )
    setFilesMeta(newMeta)

    setShowProgress(false)
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: 0,
    margin: 0,
  }))

  return (
    <Grid item xs={2} sm={3} sx={styles.root}>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="selected-files"
        multiple
        type="file"
        ref={addFileRef}
        onChange={(e) => handleFileUpload(e)}
        onClick={handleStoreSelectedPhoto}
      />

      <Menu
        id="job-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClick={handleCloseJobMenu}
      >
        {job.status === "open" && (
          <MenuItem key="complete-job" onClick={handleCompleteJob}>
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
            <Typography>Close Job</Typography>
          </MenuItem>
        )}

        {job.status === "closed" && (
          <MenuItem key="reopen-job" onClick={handleReopenJob}>
            <ListItemIcon>
              <AutorenewIcon />
            </ListItemIcon>
            <Typography>Open Job</Typography>
          </MenuItem>
        )}

        <MenuItem key="add-photo" onClick={handleClickAddPhoto}>
          <ListItemIcon>
            <AddAPhotoIcon />
          </ListItemIcon>
          <Typography>Add Photo</Typography>
        </MenuItem>

        <MenuItem key="view-photos" onClick={toggleViewPhotos}>
          <ListItemIcon sx={{ marginLeft: -1, paddingLeft: 0 }}>
            <StyledBadge badgeContent={job.docs.length} color="primary">
              <ImageSearchIcon />
            </StyledBadge>
          </ListItemIcon>
          <Typography sx={{ marginLeft: "8px" }}>Hide/Show Photos</Typography>
        </MenuItem>
      </Menu>

      <YesNo config={yesNoConfig} />

      <Box padding={0.1}>
        {isShowProgress && <LinearProgress />}

        <Card>
          <CardHeader
            disableTypography={true}
            action={
              <IconButton
                aria-label="settings"
                aria-controls="job-menu"
                aria-haspopup="true"
                onClick={handleJobMenuClick}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
            }
            title={
              <>
                <Typography variant="h5">
                  <strong>{job.label}</strong>
                </Typography>
              </>
            }
            subheader={<Typography variant="body2">{centreName}</Typography>}
          />
          <CardContent>
            <CardActionArea
              component={Link}
              to={url}
              sx={styles.jobCardActionArea}
            >
              <Box sx={styles.statusChip}>
                <Controls.StatusChip status={job.status} />
              </Box>

              {job.location !== "" && (
                <Box>
                  <Typography variant="body2">{job.location}</Typography>
                </Box>
              )}

              <div>
                <Typography variant="caption" gutterBottom={true} noWrap={true}>
                  Created{" "}
                  <ReactTimeAgo date={job.created.toDate()} locale="en-AU" />
                </Typography>
              </div>
              {job.notes !== "" && (
                <div>
                  <Typography variant="body2">{job.notes}</Typography>
                </div>
              )}
            </CardActionArea>

            {isShowPhotos && (
              <Grid container direction="column">
                {filesMeta.map((fileMeta) => (
                  <Grid item key={fileMeta.fileName}>
                    <ImageCard
                      fileMeta={fileMeta}
                      maxWidth={300}
                      handleClickDelete={() =>
                        handlePromptConfirmDeleteFile(fileMeta.fileName)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </CardContent>
        </Card>

        {selectedFile && (
          <ImageModal
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        )}
      </Box>
    </Grid>
  )
}

export default JobCard
