import React, { useState, useEffect, useMemo } from "react"
import { Switch, Route, HashRouter } from "react-router-dom"
import "./index.css"
import UserFind from "./pages/UserFind"
import UserEdit from "./pages/UserEdit"
import JobEdit from "./pages/JobEdit"
import Jobs from "./pages/Jobs"
import WorkOrderEdit from "./pages/WorkOrderEdit"
import WorkOrders from "./pages/WorkOrders"
import AccountGrid from "./pages/Accounts"
import AccountEdit from "./pages/AccountEdit"
import SupplierEdit from "./pages/SupplierEdit"
import SupplierFind from "./pages/SupplierFind"
import Billing from "./pages/Billing"
import Camera from "./pages/Camera"
import CentreFind from "./pages/CentreFind"
import CentreEdit from "./pages/CentreEdit"
import XeroLoginPage from "./pages/XeroLoginPage"
import ProfilePage from "./pages/ProfilePage"
import LookupsEdit from "./pages/LookupsEdit"
import Dashboard from "./pages/Dashboard"
import SignIn from "./pages/SignIn"
import SignOut from "./pages/SignOut"
import Checkout from "./pages/Checkout"
import Actions from "./pages/Actions"
import SignUp from "./pages/SignUp"
import "fontsource-roboto"
import ProtectedRoute from "./ProtectedRoute"
import Invites from "./pages/Invites"
import Download from "./pages/Download"
import WorkOrderSummary from "./pages/WorkOrderSummary"
import * as Roles from "./pages/services/roleServices"
import WorkOrderCalendar from "./pages/WorkOrderCalendar"
import WorkOrderFullList from "./pages/WorkOrderFullList"
import CheckLists from "./pages/CheckLists"
import CheckListEdit from "./pages/CheckListEdit"
import ReleaseNotes from "./pages/ReleaseNotes"
import CheckListInstanceEdit from "./pages/CheckListInstanceEdit"
import CheckListInstances from "./pages/CheckListInstances"
import Comments from "./pages/Comments"
import JobPrint from "./pages/JobPrint"
import CoverageEdit from "./pages/CoverageEdit"
import PriorityEdit from "./pages/PriorityEdit"
import Quotes from "./pages/Quotes"
import AssetEdit from "./pages/AssetEdit"
import Assets from "./pages/Assets"
import db from "./Firestore"
import { obtainCustomClaims } from "./pages/services/cloudFunctions"
import CheckListPrint from "./pages/CheckListPrint"
import * as actionServices from "./pages/services/actionServices"
import { localTimestamp } from "./pages/services/dataServices"
import { useDispatch } from "react-redux"
import { setActionsIndicator, setCentres } from "./redux/actions"
import { useSnackbar } from "notistack"
import "./App.css"
import useNetworkStatus from "./components/useNetworkStatus"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const AppMenu = (props) => {
  const [isSystem, setSystem] = useState(false)

  const [roles, setRoles] = useState([])

  const [accountType, setAccountType] = useState()

  const dispatch = useDispatch()

  const isSystemRole = () => isSystem

  const isOnline = useNetworkStatus()

  const isAdminRole = useMemo(
    () => roles?.includes(Roles.ADMIN) || false,
    [roles]
  )

  const isJobAdminRole = useMemo(
    () => roles?.includes(Roles.JOB_ADMIN),
    [roles]
  )

  const isJobUserRole = useMemo(() => roles?.includes(Roles.JOB_USER), [roles])

  const isCentre = useMemo(() => accountType === "centre", [accountType])

  const { enqueueSnackbar } = useSnackbar()

  const [uid, setUid] = useState()

  const [claims, setClaims] = useState()

  // useEffect(() => {
  //   window.onerror = function (message, source, lineno, colno, error) {
  //     console.log("%cError", "color:purple", {
  //       message,
  //       source,
  //       lineno,
  //       colno,
  //       error,
  //     })
  //     return true
  //   }
  // }, [])

  useEffect(() => {
    console.log(
      "%conline status",
      "color: red",
      isOnline ? "online" : "offline"
    )
  }, [isOnline])

  // Listen for if user record changed, and if so update claims
  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdTokenResult(true)
          .then((token) => {
            setClaims(token.claims)
            setUid(token.claims.user_id)
            setSystem(token.claims.system_role === true)
            setRoles(token.claims.roles)
            setAccountType(token.claims.account_type)
          })
          .catch((error) => {
            console.log("Error getting token in AppMenu", error)
          })
      }
    })

    return unsub
  }, [])

  // Listen for centre changes, which stores centres in redux
  // and this is used to populate the CentreCombo component
  // instead of loading centres each time the combo is opened

  useEffect(() => {
    if (claims && claims.account_id) {
      let query = db
        .collection("centres")
        .where("account_id", "==", claims.account_id)

      const unsub = query.onSnapshot(
        (querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            // The change to a centre applies to this user if the user:
            // 1. Is not limited to view centres at all, or
            // 2. Is limited to view centres and the change is to a centre they are limited to view
            const isUserCentreChange =
              claims.centre_ids.length === 0 ||
              claims.centre_ids.includes(change.doc.id)

            switch (change.type) {
              case "added":
                if (isUserCentreChange) {
                  dispatch(
                    setCentres({
                      centre: change.doc.data(),
                      id: change.doc.id,
                      change_type: "add",
                    })
                  )
                }
                break
              case "removed":
                if (isUserCentreChange) {
                  dispatch(
                    setCentres({
                      centre: change.doc.data(),
                      id: change.doc.id,
                      change_type: "remove",
                    })
                  )
                }
                break

              case "modified":
                if (isUserCentreChange) {
                  dispatch(
                    setCentres({
                      centre: change.doc.data(),
                      id: change.doc.id,
                      change_type: "modify",
                    })
                  )
                }
                break
              default:
                break
            }
          })
        },
        (error) =>
          console.log(
            "%cerror listening for centre changes",
            "color:orange",
            error
          )
      )

      return unsub
    }
  }, [claims])

  // Listen for user actions

  const getActionsQuery = (claims) => {
    let query = db.collection("actions")

    if (claims.account_type === Roles.ACCOUNT_TYPE_CENTRE) {
      query = query
        .where("account_id", "==", claims.account_id)
        .where("assigned_to", "==", claims.email)
        .where("status", "in", [
          actionServices.STATUS_OPEN,
          actionServices.STATUS_COMPLETED,
        ])
    } else {
      query = query
        .where("supplier_account_id", "==", claims.account_id)
        .where("status", "==", actionServices.STATUS_OPEN)
        .where("assigned_to", "==", claims.email)
    }

    return query
  }

  const checkActionsForMe = async (claims) => {
    const query = getActionsQuery(claims).limit(1)
    const querySnapshot = await query.get()
    dispatch(setActionsIndicator(querySnapshot.size > 0))
  }

  const [lastRetrievedActions, setLastRetrievedActions] = useState(
    localTimestamp()
  )

  // Are there any outstanding actions
  useEffect(() => {
    if (claims && claims.account_id) {
      const query = getActionsQuery(claims).limit(1)

      query
        .get()
        .then((querySnapshot) => {
          dispatch(setActionsIndicator(querySnapshot.size > 0))
        })
        .catch((error) => {
          console.log("%cError getting actions", "color:orange", error)
        })
    }
  }, [claims])

  useEffect(() => {
    // Check that custom claims have been added, which includes amongst others an account_id attribute
    if (claims && claims.account_id) {
      let query = getActionsQuery(claims)

      if (lastRetrievedActions) {
        query = query
          .where("modified", ">", lastRetrievedActions)
          .orderBy("modified", "desc")
      }

      const unsub = query.onSnapshot(
        (querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            setLastRetrievedActions(localTimestamp())
            switch (change.type) {
              case "added":
                dispatch(setActionsIndicator(true))
                enqueueSnackbar(
                  'Action updated: "' + change.doc.data().description + '"',
                  {
                    variant: "info",
                  }
                )
                setTimeout(() => {
                  checkActionsForMe(claims)
                }, 1000)
                break
              case "removed":
                // Wait 1 second - sometimes the supplier red dot on actions doesn't clear. Seeing if this fixes it.
                setTimeout(() => {
                  checkActionsForMe(claims)
                }, 1000)
                break
              default:
                break
            }
          })
        },
        (error) =>
          console.log(
            "%cerror listening for action changes",
            "color:orange",
            error
          )
      )

      return unsub
    }
  }, [claims])

  const signOutInactiveUser = async () => {
    getAuth().signOut()
    enqueueSnackbar(
      "Your account has been made inactive and you will be signed out",
      {
        variant: "info",
      }
    )
    // go to /signin page

    window.location.href = "/#/SignIn"
  }

  useEffect(() => {
    if (!uid) {
      return
    }

    const unsub = db
      .collection("users")
      .doc(uid)
      .onSnapshot(
        (doc) => {
          try {
            if (doc.exists) {
              console.log("%crefresh custom claims", "color:green", {
                user: doc.data(),
              })
              // See if user made inactive, and if so sign out
              if (doc.data().active === false) {
                signOutInactiveUser()
              } else {
                obtainCustomClaims()
              }
            }
          } catch (error) {
            console.log(
              "%cError in AppMenu getting custom claims",
              "color:orange",
              error
            )
          }
        },
        (error) =>
          console.log(
            "%cError in AppMenu listening for user changed",
            "color:orange",
            error
          )
      )

    return unsub
  }, [uid])

  return (
    <>
      {!isOnline && <OfflineDialog open={!isOnline} handleClose={() => {}} />}
      <HashRouter>
        <Switch>
          <Route exact path="/" component={SignIn} allowAccess={true} />

          <Route path="/Download" component={Download} allowAccess={true} />

          <Route path="/SignIn" component={SignIn} allowAccess={true} />

          <Route path="/SignOut" component={SignOut} allowAccess={true} />

          <Route path="/SignUp" component={SignUp} allowAccess={true} />

          <ProtectedRoute
            path="/checkout"
            component={Checkout}
            allowAccess={true}
          />

          <ProtectedRoute
            path="/billing/:id"
            component={Billing}
            allowAccess={isAdminRole && isCentre}
          />

          <ProtectedRoute
            path="/dashboard"
            component={Dashboard}
            allowAccess={true}
          />

          <ProtectedRoute
            path="/workordercalendar"
            component={WorkOrderCalendar}
            allowAccess={true}
          />

          {/* View all checklist configs */}
          <ProtectedRoute
            path="/checklists"
            component={CheckLists}
            allowAccess={() => isAdminRole && isCentre}
          />

          {/* Open an existing checklist config */}
          <ProtectedRoute
            path="/checklistedit/:id"
            component={CheckListEdit}
            allowAccess={() => isAdminRole && isCentre}
          />

          {/* Create a new checklist config*/}
          <ProtectedRoute
            path="/checklistedit"
            component={CheckListEdit}
            allowAccess={() => isAdminRole && isCentre}
          />

          {/* Edit a checklist instance */}
          <ProtectedRoute
            path="/CheckListInstanceEdit/:id"
            component={CheckListInstanceEdit}
            allowAccess={true}
            //TODO: Change this back when built
            //allowAccess={isCentre}
          />

          {/* Create a new checklist instance */}
          <ProtectedRoute
            path="/CheckListInstanceEdit"
            component={CheckListInstanceEdit}
            allowAccess={isCentre}
          />

          {/* Open checklist instances grid */}
          <ProtectedRoute
            path="/CheckListItems"
            component={CheckListInstances}
            allowAccess={isCentre}
          />

          <ProtectedRoute
            path="/CheckListPrint/:id"
            component={CheckListPrint}
            allowAccess={isCentre}
          />

          {/* Edit a centre */}
          <ProtectedRoute
            path="/centreedit/:id"
            component={CentreEdit}
            allowAccess={isCentre}
          />

          {/* Create a new centre */}
          <ProtectedRoute
            path="/centreedit"
            component={CentreEdit}
            allowAccess={isCentre}
          />

          <ProtectedRoute
            path="/centres"
            component={CentreFind}
            allowAccess={isCentre}
          />

          <ProtectedRoute
            path="/XeroLogin"
            component={XeroLoginPage}
            // leave allowAccess={true} so it doesn't redirect away when we return from our callback cloud function
            allowAccess={true}
          />

          {/* Edit an asset */}
          <ProtectedRoute
            path="/assetedit/:id"
            component={AssetEdit}
            allowAccess={isCentre}
          />

          {/* Create a new asset */}
          <ProtectedRoute
            path="/assetedit"
            component={AssetEdit}
            allowAccess={isCentre}
          />

          <ProtectedRoute
            path="/assets"
            component={Assets}
            allowAccess={isCentre}
          />

          {/* Edit a job */}
          <ProtectedRoute
            path="/jobedit/:id"
            component={JobEdit}
            allowAccess={true}
          />

          {/* Create a new job */}
          <ProtectedRoute
            path="/jobedit"
            component={JobEdit}
            allowAccess={true}
          />

          {/* Print a job */}
          <ProtectedRoute
            path="/jobprint/:id"
            component={JobPrint}
            allowAccess={true}
          />

          {/* Open jobs */}
          <ProtectedRoute path="/jobgrid" component={Jobs} allowAccess={true} />

          <ProtectedRoute
            path="/quotes"
            component={Quotes}
            allowAccess={true}
          />

          <ProtectedRoute
            path="/comments"
            component={Comments}
            allowAccess={true}
          />

          <ProtectedRoute
            path="/actions"
            component={Actions}
            allowAccess={true}
          />

          <ProtectedRoute
            path="/releasenotes"
            component={ReleaseNotes}
            allowAccess={true}
          />

          {/* Edit a work order */}
          <ProtectedRoute
            path="/workorderedit/:id"
            key="edit-job"
            component={WorkOrderEdit}
            allowAccess={true}
          />

          {/* Create a new work order */}
          <ProtectedRoute
            path="/workorderedit"
            key="new-job"
            component={WorkOrderEdit}
            allowAccess={isCentre}
          />

          <ProtectedRoute
            path="/workorders"
            component={WorkOrders}
            allowAccess={true}
          />

          {/* Simplistic job tile view of a work order */}
          <ProtectedRoute
            path="/WorkOrderSummary/:id"
            component={WorkOrderSummary}
            allowAccess={true}
          />

          {/* Full list of work orders and jobs for a supplier, incl. thumbnails */}
          <ProtectedRoute
            path="/SupplierWorkOrders/:supplierId"
            component={WorkOrderFullList}
            allowAccess={() => isJobAdminRole()}
          />

          <ProtectedRoute
            path="/PrintWorkOrder/:workOrderId"
            component={WorkOrderFullList}
            allowAccess={() => isJobAdminRole() || isJobUserRole()}
          />

          {/* Edit a user */}
          <ProtectedRoute
            path="/useredit/:id"
            component={UserEdit}
            allowAccess={() => isAdminRole}
          />

          {/* Create a new user 
                -- CAN'T DO THIS - NEED TO CREATE INVITE INSTEAD
                <ProtectedRoute path="/useredit" component={UserEdit}/>
                */}

          <ProtectedRoute
            path="/users"
            component={UserFind}
            allowAccess={() => isAdminRole}
          />

          <ProtectedRoute
            path="/invites"
            component={Invites}
            allowAccess={() => isAdminRole}
          />

          <ProtectedRoute
            path="/jobtypes"
            key="job-types"
            component={LookupsEdit}
            lookupType="job_types"
            lookupTitle="Job Types"
            allowAccess={() => isAdminRole}
          />

          <ProtectedRoute
            path="/supplierdoctypes"
            key="supplier-doc-types"
            component={LookupsEdit}
            lookupType="supplier_doc_types"
            lookupTitle="Supplier Document Types"
            allowAccess={() => isAdminRole}
          />

          <ProtectedRoute
            path="/locationtypes"
            key="location-types"
            component={LookupsEdit}
            lookupType="location_types"
            lookupTitle="Job Locations"
            allowAccess={() => isAdminRole}
          />

          <ProtectedRoute
            path="/coverage"
            key="coverage"
            component={CoverageEdit}
            allowAccess={() => isAdminRole}
          />

          <ProtectedRoute
            path="/priorities"
            key="priorities"
            component={PriorityEdit}
            allowAccess={() => isAdminRole}
          />

          <ProtectedRoute
            path="/accounts"
            component={AccountGrid}
            allowAccess={() => isSystemRole()}
          />

          {/*
                <ProtectedRoute path="/accounts" component={AccountFind}/>
                */}

          {/* Edit an account */}
          <ProtectedRoute
            path="/accountedit/:id"
            component={AccountEdit}
            allowAccess={() => isSystemRole()}
          />

          <ProtectedRoute
            path="/accountedit"
            component={AccountEdit}
            allowAccess={() => isSystemRole()}
          />

          <ProtectedRoute
            path="/myaccount"
            component={AccountEdit}
            allowAccess={true}
          />

          {/* Edit a supplier */}
          <ProtectedRoute
            path="/supplieredit/:id"
            component={SupplierEdit}
            allowAccess={true}
          />

          {/* Create a new supplier */}
          <ProtectedRoute
            path="/supplieredit"
            component={SupplierEdit}
            allowAccess={true}
          />

          <ProtectedRoute
            path="/suppliers"
            component={SupplierFind}
            allowAccess={true}
          />

          <ProtectedRoute
            path="/profile"
            component={ProfilePage}
            allowAccess={true}
          />
          <ProtectedRoute
            path="/camera"
            component={Camera}
            allowAccess={true}
          />
        </Switch>
      </HashRouter>
    </>
  )
}

const OfflineDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Connection Lost"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          J4J app has disconnected, either because it has timed out, or you may
          have lost your Internet connection. Please refresh your browser to
          reconnect.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppMenu
