import { Box, Rating, Typography } from "@mui/material"
import StarIcon from "@mui/icons-material/Star"

const CheckListRating = ({ values }) => {
  return (
    <Box>
      <Rating
        name="score"
        value={values.score * 5}
        readOnly
        precision={0.2}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      <Box>
        <Typography variant="caption">
          ({values.score && Math.round(values.score * 100)}%)
        </Typography>
      </Box>
    </Box>
  )
}

export default CheckListRating
