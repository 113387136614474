import {
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import * as jobServices from "../pages/services/jobServices"
import * as icons from "../icons"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"
import StyledBadge from "./StyledBadge"
import EditIcon from "@mui/icons-material/Edit"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"

const JobMenu = ({
  job,
  anchorEl,
  setAnchorEl,
  menuId,
  isEditable,
  isSupplierViewing,
  handleCloseJob,
  handleOpenJob,
  handleCompleteJob,
  handleClickAddPhoto,
  handleViewPhotos,
  handleEditJob,
  handleRemoveJob,
  showPhotos,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: isMobile ? "left" : "left",
      }}
      transformOrigin={{
        vertical: isMobile ? "top" : "top",
        horizontal: isMobile ? "left" : "left",
      }}
      sx={{
        left: isMobile ? 0 : undefined, // Position menu at the left edge of the screen on mobile devices
        width: isMobile ? "100%" : undefined, // Set menu width to 100% on mobile devices
      }}
    >
      {/* Suppliers can't mark a job as closed */}
      {job.status !== jobServices.JOB_STATUS_CLOSED &&
        !isSupplierViewing &&
        handleCloseJob && (
          <MenuItem key="close-job" onClick={handleCloseJob}>
            <ListItemIcon>
              <icons.JobCloseIcon />
            </ListItemIcon>
            <Typography>Mark as Closed</Typography>
          </MenuItem>
        )}

      {job.status !== jobServices.JOB_STATUS_OPEN && handleOpenJob && (
        <MenuItem key="reopen-job" onClick={handleOpenJob}>
          <ListItemIcon>
            <icons.JobOpenIcon />
          </ListItemIcon>
          <Typography>Mark as Open</Typography>
        </MenuItem>
      )}

      {job.status !== jobServices.JOB_STATUS_COMPLETED && handleCompleteJob && (
        <MenuItem key="complete-job" onClick={handleCompleteJob}>
          <ListItemIcon>
            <icons.JobCompleteIcon />
          </ListItemIcon>
          <Typography>Mark as Completed</Typography>
        </MenuItem>
      )}

      {showPhotos && handleClickAddPhoto && (
        <MenuItem key="add-photo" onClick={handleClickAddPhoto}>
          <ListItemIcon>
            <AddAPhotoIcon />
          </ListItemIcon>
          <Typography>Add Photo</Typography>
        </MenuItem>
      )}

      {showPhotos && handleViewPhotos && (
        <MenuItem
          key="view-photos"
          onClick={handleViewPhotos}
          disabled={job.docs.length === 0}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {job.docs.length === 0 ? (
              <ListItemIcon>
                <icons.ImageSearchIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon sx={{ marginLeft: -1, paddingLeft: 0 }}>
                <StyledBadge badgeContent={job.docs.length} color="primary">
                  <icons.ImageSearchIcon />
                </StyledBadge>
              </ListItemIcon>
            )}
            <Typography sx={{ marginLeft: "8px" }}>View Photos</Typography>
          </Box>
        </MenuItem>
      )}

      <MenuItem onClick={handleEditJob}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        Edit Job
      </MenuItem>

      {isEditable && !isSupplierViewing && handleRemoveJob && (
        <MenuItem onClick={handleRemoveJob}>
          <ListItemIcon>
            <RemoveCircleIcon fontSize="small" />
          </ListItemIcon>
          <Tooltip title="Detact this from from the work order">
            <Typography>Detach Job</Typography>
          </Tooltip>
        </MenuItem>
      )}
    </Menu>
  )
}

export default JobMenu
