import React, { useState, useEffect, useMemo } from "react"
import Controls from "./controls/Controls"
import { useForm } from "./useForm"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import { useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import * as workOrderStatus from "../components/workOrderStatus"
import firebase from "firebase/compat/app"
import AssignmentIcon from "@mui/icons-material/Assignment"
import EventRepeatIcon from "@mui/icons-material/EventRepeat"
import ViewJobPhotos from "./ViewJobPhotos"
import YesNo from "./YesNo"
import * as icons from "../icons"
import * as UIConstants from "./controls/UIConstants"
import {
  parseDate,
  parseTimestamp,
  tsToDate,
} from "../pages/services/dateServices"
import * as supplierServices from "../pages/services/supplierServices"
import _ from "lodash"
import { useDispatch } from "react-redux"
import * as Roles from "../pages/services/roleServices"
import * as jobServices from "../pages/services/jobServices"
import * as workOrderServices from "../pages/services/workOrderServices"
import { getScheduleDescription } from "../pages/services/scheduleServices"
import * as actionServices from "../pages/services/actionServices"
import WorkOrderJobsGrid from "./WorkOrderJobsGrid"
import {
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  FormControlLabel,
  Alert,
  Stack,
} from "@mui/material"
import ChangeWorkOrderStatusButton from "./controls/ChangeWorkOrderStatusButton"
import WorkOrderSchedule from "./WorkOrderSchedule"
import { Box } from "@mui/material"
import moment from "moment"
import { setWorkOrderLastModified } from "../redux/actions"
import History from "./History"
import WorkOrderEmailDialog from "./WorkOrderEmailDialog"
import ProgressBackdrop from "./ProgressBackdrop"
import { spacing } from "../pages/services/styleServices"
import JobStatusChip from "./controls/JobStatusChip"
import NavButtons from "./NavButtons"
import WorkOrderQuotesGrid from "./WorkOrderQuotesGrid"
import SubHeading from "./SubHeading"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const initialValues = () => {
  return {
    label: "",
    work_order_no: "",
    notes: "",
    status: "open",
    quote_details: "",
    start_date: parseTimestamp(dataServices.localTimestamp()),
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
    cost_estimate: [],
    cost_actuals: [],
    parts: [],
    schedule: {},
    history: [],
    supplier_access_account_ids: [],
    invoice_no: "",
  }
}

// Basic fields for cost_estimate and cost_actual items

const costItemInitialValues = {
  type: "",
  qty: 0,
  price_ex_tax: 0,
  tax: 0,
  price_inc_tax: 0,
}

const styles = {
  addMultiple: {
    margin: spacing(1),
    padding: spacing(1),
  },
  buttons: {
    marginTop: spacing(2),
  },
  spacer: {
    marginTop: spacing(2),
  },
  nested: {
    paddingLeft: spacing(4),
  },
  cellSmall: {
    minWidth: "45px",
    padding: "4px 4px",
  },
  menuCell: {
    maxWidth: "20px",
    padding: 0,
    margin: 0,
  },
  subTotal: {
    padding: "4px",
    paddingRight: "0px",
  },
  tableHead: {
    backgroundColor: "theme.palette.primary.light",
    color: "theme.palette.common.white",
    minWidth: "45px",
    padding: "4px 4px",
  },
  tableHeadMenu: {
    backgroundColor: "theme.palette.primary.light",
    color: "theme.palette.common.white",
    maxWidth: "20px",
    padding: "2px 2px",
  },
  statusChip: {
    marginBottom: spacing(2),
    paddingLeft: spacing(1),
  },
  paddedLabel: {
    paddingLeft: "5px",
  },
  link: {
    textDecoration: "none",
    color: "theme.palette.text.primary",
  },
}

const WorkOrderEditForm = (props) => {
  const history = useHistory()

  // The jobs attached to this work order
  const [jobs, setJobs] = useState([])

  const [centres, setCentres] = useState([])

  const { setTitle } = props

  const [previousPage, setPreviousPage] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  const [workOrderId, setWorkOrderId] = useState("")

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete",
    openPrompt: false,
    description: "Delete?",

    // Callback if user clicks 'Yes' to delete a child record.
    // We set the callback and label depending on what the user is deleting
    handleConfirm: null,
  })

  const [workOrdersCalendar, setWorkOrdersCalendar] = useState([])

  const [priorities, setPriorities] = useState([])

  const [emailSupplierConfig, setEmailSupplierConfig] = useState({
    title: "Send email to supplier?",
    openPrompt: false,
    description: "", // set dynamically when we show the prompt
    handleConfirm: null,
  })

  const isNew = () => props.id === undefined && workOrderId === ""

  const [isEditable, setEditable] = useState(false)

  const [isFinanceRole, setFinanceRole] = useState(false)

  const { values, setValues, handleInputChange } = useForm(initialValues())

  // true => if a supplier is logged in and viewing this page. They only see their actions
  const [isSupplierViewing, setSupplierViewing] = useState(undefined)

  const [accountType, setAccountType] = useState()

  const [isShowProgress, setShowProgress] = useState(false)

  const [isShowSelectJobsToClose, setShowSelectJobsToClose] = useState(false)

  //const [user, setUser] = useState()

  const [accountId, setAccountId] = useState()

  const [userEmail, setUserEmail] = useState()

  const [jobsActions, setJobsActions] = useState([])

  const [jobToShowPhotos, setJobToShowPhotos] = useState(null)

  const [showSendWorkOrderEmailDialog, setShowWorkOrderEmailDialog] =
    useState(false)

  /**
   * We want to hide the quotes grid sometimes when its not relevant any more.
   */
  const isShowQuotesGrid = useMemo(() => {
    if (isSupplierViewing === undefined) return false

    if (isSupplierViewing) {
      if (workOrderStatus.supplierViewQuotesStatuses.includes(values.status)) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }, [isSupplierViewing, values])

  const hideRemoveJobPrompt = () => {
    const config = { ...promptRemoveJobConfig, openPrompt: false }
    setPromptRemoveJobConfig(config)
  }

  const hasNonClosedJobActions = useMemo(() => {
    if (!jobsActions) {
      return false
    }

    return jobsActions.some(
      (action) => action.status !== actionServices.STATUS_CLOSED
    )
  }, [jobsActions])

  const showRemoveJobPrompt = (jobId) => {
    const config = {
      ...promptRemoveJobConfig,
      openPrompt: true,
      handleConfirm: () => handleDetachJob(jobId),
    }
    setPromptRemoveJobConfig(config)
  }

  const handleDetachJob = async (jobIdToRemove) => {
    hideRemoveJobPrompt()
    const newJobs = jobs.filter((job) => job.id !== jobIdToRemove)
    setJobs(newJobs)

    await db.collection("jobs").doc(jobIdToRemove).update(
      {
        work_order_id: "",
        // If removing jobs from a work order, need to also remove supplier access to it.
        supplier_id: "",
        supplier_access_account_ids: [],
        modified: dataServices.serverTimestamp(),
      },

      { merge: true }
    )
  }

  const [promptRemoveJobConfig, setPromptRemoveJobConfig] = useState({
    title: "Detach job",
    description: "Detach job from this work order?",
    openPrompt: false,
    handleConfirm: handleDetachJob,
  })

  const [showWorkOrderSchedule, setShowWorkOrderSchedule] = useState(false)

  const [showHistory, setShowHistory] = useState(false)

  const [approver, setApprover] = useState()

  const [currentUser, setCurrentUser] = useState()

  /**
   * Get the list of dates on which this work order is scheduled to occur next
   */
  const nextScheduledDates = useMemo(() => {
    const months = workOrdersCalendar.map((month) => {
      const m = moment(month.slot + "-01", "YYYY-MM-DD")

      const first = month.dates[0]
      const monthName = moment(tsToDate(first)).format("MMMM")

      return (
        <Box
          key={`scheduled-dates-${monthName}`}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <Box sx={{ width: "35px" }}>
            <Typography variant="caption" component={"span"}>
              {m.format("MMM")}
            </Typography>
          </Box>

          <Box>
            <Typography variant="caption" component={"span"}>
              {month.dates
                .map((ts) => moment(tsToDate(ts)).format("Do"))
                .join(", ")}
            </Typography>
          </Box>
        </Box>
      )
    })

    return months
  }, [workOrdersCalendar])

  useEffect(() => {
    if (values.approver && accountType === Roles.ACCOUNT_TYPE_CENTRE) {
      db.collection("users")
        .doc(values.approver)
        .get()
        .then((user) => {
          setApprover(user.data())
        })
    } else {
      setApprover(null)
    }
  }, [values.approver, accountType])

  /**
   * Get a label explaining the schedule for this job
   */
  const scheduleLabel = useMemo(() => {
    return getScheduleDescription(values.schedule)
  }, [values.schedule])

  const rateLabels = {
    callout: "Callout",
    hourly_rate: "Hourly Rate",
    flat_rate: "Flat Rate",
    daily_rate: "Daily Rate",
  }

  useEffect(() => {
    const centreIds = Array.from(
      new Set(
        jobs.map((job) => job.centre_id).filter((centreId) => centreId !== null)
      )
    )

    dataServices
      .getCentresByIdChunks(centreIds)
      .then((centres) => {
        setCentres(centres)
      })
      .catch((error) => {
        console.error(`Error loading centres`, error)
      })
  }, [jobs])

  useEffect(() => {
    if (props.history) {
      if (props.history.location) {
        if (props.history.location.state) {
          if (props.history.location.state.previousPage) {
            setPreviousPage(props.history.location.state.previousPage)
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult(false).then((token) => {
          setCurrentUser(token)
          setAccountId(token.claims.account_id)
          setUserEmail(token.claims.email)
          setAccountType(token.claims.account_type)

          const isSupplier =
            token.claims.account_type === Roles.ACCOUNT_TYPE_SUPPLIER
          const isCentreAccount =
            token.claims.account_type === Roles.ACCOUNT_TYPE_CENTRE

          const isEditable = !isSupplier && isCentreAccount
          setEditable(isEditable)
          setFinanceRole(token.claims.roles.includes(Roles.FINANCE))
        })
      }
    })

    return unsub
  }, [])

  // Listen for changes

  useEffect(() => {
    if (workOrderId !== "" && accountId) {
      const unsub = db
        .collection("work_orders")
        .doc(workOrderId)
        .onSnapshot(
          (snapshot) => {
            const changedData = snapshot.data()

            // For some reason, when we update the work order status as a result
            // of a job being closed, it generates 2 x snapshot calls, 1 with modified === null
            // and then 1 with modified !== null, so the code below ignores the  modified === null
            // snapshot

            if (changedData && changedData.modified !== null) {
              const newValues = prepareWorkOrderValues(changedData)
              setValues(newValues)
            }
          },
          (error) =>
            console.error("error listening for work order changes", error)
        )

      return unsub
    }
  }, [workOrderId, accountId])

  // Initialise account if it a new record

  useEffect(() => {
    if (props.id === null || props.id === undefined) {
      if (accountId === undefined) {
        return
      }

      const newValues = {
        ...values,
        account_id: accountId,
      }

      setValues(newValues)
    }
  }, [accountId])

  // Get the largest 'index' attribute from items in an array, or -1 if arr is empty

  const getMaxIndexValue = (arr) => {
    // It's important we return -1 if the array is empty, because the caller
    // will add 1 to determine the size of a HTML elements array to create
    // i.e. -1 + 1 = 0 sized array if arr.length === 0, which is correct.
    if (arr.length === 0) {
      return -1
    }

    const maxIndexItem = arr.reduce((max, item) =>
      max.index > item.index ? max : item
    )
    return maxIndexItem.index
  }

  const loadJobsForWorkOrder = async (
    workOrderId,
    userAccountType,
    userAccountId
  ) => {
    let query = db.collection("jobs").where("work_order_id", "==", workOrderId)

    //TODO: the above userAccountId and userAccountType don't seem to be for the current user, and that's
    // why the getIdTokenResult() is used below. This is a bit of a hack, and should be refactored
    const token = await getAuth().currentUser.getIdTokenResult()

    if (token.claims.account_type === "centre") {
      query = query.where("account_id", "==", token.claims.account_id)
    } else if (token.claims.account_type === "supplier") {
      query = query.where(
        "supplier_access_account_ids",
        "array-contains",
        accountId
      )
    }
    const jobList = await dataServices
      .loadData("(Load work order jobs)", query)
      .then((jobs) =>
        jobs.map((job) => {
          return {
            ...jobServices.ensureMandatoryFields(job),
          }
        })
      )

    const updatedJobs = jobList.map(async (job) => {
      return {
        ...job,
        docs: await jobServices.getJobFileNames(job, job.id),
      }
    })

    const loadedJobs = await Promise.all(updatedJobs)

    const centreIds = Array.from(
      new Set(
        loadedJobs
          .map((job) => job.centre_id)
          .filter((centreId) => centreId !== null)
      )
    )

    const centres = await dataServices.getCentresByIdChunks(centreIds)

    const users = await dataServices.getUsersByIdChunks(
      accountId,
      loadedJobs.map((job) => job.user_id).filter((userId) => userId)
    )

    const jobActions = await actionServices.loadNonClosedJobActions(
      loadedJobs,
      userEmail,
      userAccountType,
      userAccountId
    )

    setJobsActions(jobActions)

    // Map centre names onto job
    // Note that we add the docs: [] at the start, because the docs[] attribute was introduced
    // as a new field, and not all jobs have it.
    const mappedJobs = loadedJobs.map((job, index) => {
      return {
        ...job,
        centre: centres.find((centre) => centre.id === job.centre_id),
        user: users.find((user) => user.id === job.user_id),
        index: index,
      }
    })

    if (mappedJobs.length > 0) {
      // It is ok to just get the account id for the first job since printing a work
      // order will have jobs all from the same account, whereas the job grid
      // can show jobs from multiple accounts if you're a supplier to which
      // multiple job has been allocated
      jobServices
        .getJobPriorities(mappedJobs[0].account_id)
        .then((priorities) => {
          setPriorities(priorities)
        })
    }

    const sortedJobs = mappedJobs.sort((a, b) =>
      a.centre.name.localeCompare(b.centre.name)
    )

    setJobs(sortedJobs)
  }

  // woData is the raw data loaded from firestore

  const prepareWorkOrderValues = (woData) => {
    const data = {
      ...initialValues(),
      ...woData,
      start_date: parseTimestamp(woData.start_date),

      cost_estimate: [],
      cost_actuals: [],
      parts: [],

      // schedule is a new field, so it may not exist in older work orders (July 20, 2021)
      // schedule contains different attributes depending on the schedule type
      schedule: woData.schedule || {},
    }

    if (data.schedule && data.schedule.end_date) {
      data.schedule.end_date = parseTimestamp(data.schedule.end_date)
    }

    return data
  }

  // Load work order

  const [loadState, setLoadState] = useState({})

  useEffect(() => {
    if (accountId === undefined) {
      return
    }

    const newLoadState = { accountId, id: props.id }
    const isLoadStateChanged = !_.isEqual(newLoadState, loadState)

    if (!isLoadStateChanged) {
      return
    }

    setLoadState(newLoadState)

    if (props.id !== null && props.id !== undefined) {
      getAuth()
        .currentUser.getIdTokenResult()
        .then((token) => {
          setSupplierViewing(token.claims.account_type === "supplier")

          setWorkOrderId(props.id)

          db.collection("work_orders")
            .doc(props.id)
            .get()
            .then(async (snapshot) => {
              if (!snapshot.exists) {
                enqueueSnackbar("Work order does not exist", {
                  variant: "error",
                })
                return
              }
              const woData = snapshot.data()

              const data = prepareWorkOrderValues(woData)

              if (data.supplier_id === null) {
                data.supplier_id = ""
              }

              setValues(data)

              // Get jobs for this work order

              await loadJobsForWorkOrder(
                props.id,
                token.claims.account_type,
                accountId
              )

              loadWorkOrdersCalendar(props.id, accountId)

              // DEBUG - remove when not needed to correct supplier access
              if (accountType === Roles.ACCOUNT_TYPE_CENTRE) {
                await supplierServices.updateSupplierAccess({
                  workOrderId: props.id,
                  source: "Load work order",
                })
              }
            })
        })
    }
  }, [accountId, props])

  useEffect(() => {
    setTitle(values.label)
  }, [values.label, setTitle])

  const loadWorkOrdersCalendar = async (workOrderId, accountId) => {
    //DEBUG
    const wocQuery = db
      .collection("work_orders_calendar")
      .where("work_order_id", "==", workOrderId)
      .where("account_id", "==", accountId)

    const recs = await dataServices.loadData("from load work order", wocQuery)

    setWorkOrdersCalendar(recs.sort((a, b) => a.slot.localeCompare(b.slot)))
  }

  const navigateAfterSubmit = () => {
    if (history.length > 0) {
      goBackBasedOnPath()
    } else {
      history.push("/workorders")
    }
  }

  const goBackBasedOnPath = () => {
    if (previousPage === "AllocateJobs") {
      // Go back before the allocate jobs page to the work order grid
      // goBack(-2) doesn't seem to work...?, e.g. history.goBack(-2), or history.goBack(2)
      history.push("/workorders")
    } else {
      history.goBack()
    }
  }

  // This function needs to remain in sync with workOrderServices.changeWorkOrderStatus
  const handleStatusChange = async ({ newStatus, comment, jobs }) => {
    // Check if all jobs are closed -- if not enable those jobs to be
    // detached from the work order, or rolled into a new work order.

    if (newStatus === workOrderStatus.STATUS_CLOSED) {
      const allClosed = jobs.every(
        (j) => j.status === jobServices.JOB_STATUS_CLOSED
      )

      if (!allClosed) {
        setShowSelectJobsToClose(true)
        return
      }
    }

    let newValues = {
      ...values,
      status: newStatus,
    }

    const isCopyForwardRequired =
      workOrderServices.isRecurringWorkOrder(newValues) &&
      newStatus === workOrderStatus.STATUS_CLOSED

    let newWorkOrderId

    if (isCopyForwardRequired) {
      newWorkOrderId = await dataServices.copyForwardClosedWorkOrder(
        workOrderId,
        accountId
      )

      await dataServices.updateWorkOrderCalendarInfo(
        newWorkOrderId,
        accountId,
        {},
        values.start_date
      )
    }

    // Save current work order
    // Don't clear schedule on old work order yet, (do that below), since we want to
    // copy the old work order forward (if it has a schedule) to be a new work order.
    await updateWorkOrder({
      status: newStatus,
      schedule: {},
      history: firebase.firestore.FieldValue.arrayUnion({
        status: newStatus,
        date: dataServices.localTimestamp(),
        user: getAuth().currentUser.email,
        comment: comment || "",
      }),
    })

    // Only call this if user is a centre user
    // and we're changing the work order status to allocated
    // we make sure all jobs are open, so the supplier can see them
    if (
      accountType === Roles.ACCOUNT_TYPE_CENTRE &&
      newStatus === workOrderStatus.STATUS_ALLOCATED
    ) {
      await jobServices.openAnyPendingJobs({ accountId, workOrderId })

      // Update the jobs list to reflect the new status
      setJobs((curr) =>
        curr.map((job) => {
          return {
            ...job,
            status: jobServices.JOB_STATUS_OPEN,
          }
        })
      )
    }

    if (accountType === Roles.ACCOUNT_TYPE_CENTRE) {
      supplierServices.updateSupplierAccess({
        workOrderId: workOrderId,
        loadedDocs: {
          jobs: jobs,
        },
        source: "Handle status change",
      })
    }

    await dataServices.deleteWorkOrderCalendarInfo(workOrderId, accountId)

    // If quote requested and there are quotes, then all suppliers with a quote can see the work order and jobs
    // Otherwise if work order is allocated only the supplier allocated to the work order can see the work order and jobs

    if (isCopyForwardRequired) {
      enqueueSnackbar("Previous work order closed. This is a new work order", {
        variant: "success",
      })

      history.push(`/WorkOrderEdit/${newWorkOrderId}`)
    } else {
      setValues(newValues)
    }
  }

  // Strip out various attributes required in the UI before we save.

  const removeAttributes = (attributesToRemove, items) => {
    const newItems = items.map((item) => {
      const newItem = { ...item }
      attributesToRemove.forEach((attr) => {
        delete newItem[attr]
      })
      return newItem
    })

    return newItems
  }

  // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted

  const hideDeletePrompt = () => {
    setYesNoConfig({
      title: "",
      openPrompt: false,
      description: "",
      handleConfirm: null,
    })
  }

  const handleDeleteWorkOrderConfirmed = async () => {
    hideDeletePrompt()

    if (
      workOrderId !== undefined &&
      workOrderId !== "" &&
      workOrderId !== null
    ) {
      // Delete any quotes synchronously for the work order first
      const quotes = await db
        .collection("quotes")
        .where("account_id", "==", accountId)
        .where("work_order_id", "==", workOrderId)
        .get()
      quotes.forEach(async (quote) => {
        await db.collection("quotes").doc(quote.id).delete()
      })

      // Delete the work order
      db.collection("work_orders")
        .doc(workOrderId)
        .delete()
        .then(history.goBack())
        .then(enqueueSnackbar("Deleted", { variant: "success" }))
    }
  }

  const updateJob = (updatedJob) => {
    const newJobs = jobs.map((job) => {
      return job.id === updatedJob.id ? updatedJob : job
    })
    setJobs(newJobs)
  }

  // Confirm if the user really wants to delete this work order

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    // if (jobs.length > 0 || jobIdsToRemove.length > 0) {
    if (jobs.length > 0) {
      enqueueSnackbar("Can only delete if all jobs are detached", {
        variant: "info",
      })
      return
    }
    setYesNoConfig({
      title: "Delete Work Order",
      openPrompt: true,
      description: "This delete is permanent",
      handleConfirm: () => handleDeleteWorkOrderConfirmed(),
    })
  }

  const getJobCentreIds = () => {
    return Array.from(new Set(jobs.map((job) => job.centre_id)))
  }

  const updateLastModifiedState = (modifiedTs) => {
    const newState = { modified: modifiedTs.seconds }
    dispatch(setWorkOrderLastModified(newState))
  }

  // save work order

  const handleSubmit = async (event, addMultiple) => {
    const logId = "[WORK ORDER EDIT > SAVE]"

    event.preventDefault()

    if (values.label === "") {
      enqueueSnackbar("Enter work order details", { variant: "info" })
    } else {
      if (isNew()) {
        getAuth()
          .currentUser.getIdTokenResult()
          .then(async (token) => {
            // Generate a work order no. if the user hasn't provided one
            let workOrderNo = values.work_order_no
            if (workOrderNo === "") {
              workOrderNo = await dataServices.getNextWorkOrderNo(
                token.claims.account_id
              )
            }

            const newWorkOrder = {
              ...values,
              account_id: token.claims.account_id,
              work_order_no: workOrderNo,
              created: dataServices.serverTimestamp(),
              modified: dataServices.serverTimestamp(),
              start_date: parseDate(values.start_date),
              centres: getJobCentreIds(),

              // To grant or remove access to the work order for the supplier
              //...supplierAccessValues,
            }

            const docRef = await db.collection("work_orders").add(newWorkOrder)

            setWorkOrderId(docRef.id)

            updateLastModifiedState(dataServices.localTimestamp())

            if (accountType === Roles.ACCOUNT_TYPE_CENTRE) {
              supplierServices.updateSupplierAccess({
                workOrderId: docRef.id,
                source: "Save work order",
              })
            }

            enqueueSnackbar("Created", {
              variant: "success",
            })

            if (addMultiple) {
              setValues(initialValues())
              setWorkOrderId("")
            } else {
              navigateAfterSubmit()
            }
          })
          .catch(function (error) {
            console.error("Error:" + error)
            enqueueSnackbar("Error", { variant: "error " })
          })
      } else {
        await updateWorkOrder({})

        enqueueSnackbar("Updated", {
          variant: "success",
          vertical: "bottom",
          horizontal: "right",
        })

        navigateAfterSubmit()
      }
    }
  }

  const updateWorkOrder = async (valueChanges) => {
    const updateRecord = {
      ...values,
      ...valueChanges,
      modified: dataServices.serverTimestamp(),
      start_date: values.start_date && parseDate(values.start_date),
      centres: getJobCentreIds(),
    }

    await db.collection("work_orders").doc(workOrderId).update(updateRecord)

    updateLastModifiedState(dataServices.localTimestamp())
  }

  const handleDateChange = (dateValue, propertyName) => {
    const newValues = {
      ...values,
      [propertyName]: dateValue ? dateValue.toDate() : null,
    }

    setValues(newValues)
  }

  // Blanks out the array of element references used for popup menu targets / where to show popup

  const createNullArray = (length) => new Array(length).fill(null)

  const handleSchedule = () => {
    setShowWorkOrderSchedule(true)
  }

  /**
   * Calculate the upcoming calendar dates for this work order based on its schedule
   *
   * @param {*} schedule
   */
  const handleUpdateSchedule = async (schedule) => {
    let newValues

    if (schedule.type) {
      const startDate = moment(values.start_date.getTime())
        .startOf("day")
        .toDate()

      newValues = {
        ...values,
        schedule: schedule,
      }
      await dataServices.updateWorkOrderCalendarInfo(
        workOrderId,
        accountId,
        schedule,
        startDate
      )
    } else {
      const emptySchedule = {}
      newValues = { ...values, schedule: emptySchedule }
      await dataServices.deleteWorkOrderCalendarInfo(workOrderId, accountId)
    }

    setValues(newValues)

    loadWorkOrdersCalendar(workOrderId, accountId)

    await updateWorkOrder(newValues)
  }

  const handleRefreshWorkOrder = async () => {
    loadJobsForWorkOrder(workOrderId)
  }

  const handlePrintWorkOrder = () => {
    history.push(`/PrintWorkOrder/${workOrderId}`)
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* Generic delete prompt, for parts, cost_estimate, cost_actuals */}

        <YesNo config={yesNoConfig} />

        <YesNo config={promptRemoveJobConfig} />

        <YesNo config={emailSupplierConfig} />

        <ProgressBackdrop open={isShowProgress} />

        {showSendWorkOrderEmailDialog && (
          <WorkOrderEmailDialog
            open={showSendWorkOrderEmailDialog}
            setOpen={setShowWorkOrderEmailDialog}
            workOrder={values}
            jobs={jobs}
            centres={centres}
          />
        )}

        <History
          open={showHistory}
          setOpen={setShowHistory}
          historyObj={values}
        />

        {showWorkOrderSchedule && (
          <WorkOrderSchedule
            open={showWorkOrderSchedule}
            setOpen={setShowWorkOrderSchedule}
            initialSchedule={values.schedule}
            updateSchedule={handleUpdateSchedule}
            startDate={values.start_date}
          />
        )}

        {isShowSelectJobsToClose && (
          <SelectJobsToCloseDialog
            open={isShowSelectJobsToClose}
            setOpen={setShowSelectJobsToClose}
            jobs={jobs}
            workOrderId={workOrderId}
            accountId={accountId}
            handleRefreshWorkOrder={handleRefreshWorkOrder}
          />
        )}

        <Box display="flex">
          <Stack
            direction="row"
            gap={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Controls.StatusChip
              status={values.status}
              showBadge={hasNonClosedJobActions}
            />
            <ChangeWorkOrderStatusButton
              handleStatusChange={handleStatusChange}
              values={values}
              id={props.id}
              jobs={jobs}
            />
          </Stack>

          <Box display="flex" sx={{ marginLeft: "auto" }}>
            <Controls.CreatedLabel
              value={values.created}
              history={values.history}
            />
          </Box>
        </Box>

        <Stack
          direction="column"
          gap={3}
          sx={{ marginTop: "20px", marginBottom: "20px", maxWidth: "400px" }}
        >
          <SubHeading title="Work Order Details" />

          <Controls.TextInput
            disabled={!isEditable}
            name="label"
            label="Work Order Summary"
            value={values.label}
            onChange={handleInputChange}
            icon={<AssignmentIcon />}
          />

          <Stack direction="row" gap={2}>
            <Controls.TextInput
              disabled={!isEditable}
              name="work_order_no"
              label="Work Order No"
              value={values.work_order_no}
              onChange={handleInputChange}
              sx={{ width: "160px" }}
              helperText={
                values.work_order_no === "" ? "Auto-added on save" : ""
              }
            />

            <Controls.TextInput
              disabled={!isEditable || !isFinanceRole}
              name="invoice_no"
              label="Invoice No"
              value={values.invoice_no}
              onChange={handleInputChange}
              sx={{ width: "160px" }}
            />
          </Stack>

          {!values.maint_user_id && (
            <Controls.SupplierCombobox
              disabled={!isEditable}
              name="supplier_id"
              label="Supplier"
              // Suppliers can't modify the supplier details
              // Can't modify the supplier once the work order is created
              readonly={
                isSupplierViewing || (props.id !== "" && props.id !== undefined)
              }
              value={values.supplier_id}
              // Need to load drop down based on account id of this work order record not the
              // current user, because the current user could either be a user from
              // this account, or a supplier from another account
              accountId={values.account_id}
              onChange={handleInputChange}
              isSaved={workOrderId}
              messageIfEmpty="No supplier allocated yet"
            />
          )}

          {values.maint_user_id && (
            <Controls.UserCombobox
              name="maint_user_id"
              label="Allocated To"
              value={values.maint_user_id}
              readonly={true}
              accountId={accountId}
              userIdsFilter={[values.maint_user_id]}
            />
          )}

          <Stack gap={1}>
            <FormControlLabel
              label={
                <Typography variant="caption" color="text.secondary">
                  Start date
                </Typography>
              }
              sx={{ marginLeft: 0, display: "flex", alignItems: "flex-start" }}
              labelPlacement="top"
              control={
                <DesktopDatePicker
                  disabled={!isEditable}
                  id="start-date"
                  inputFormat="DD/MM/YYYY"
                  value={values.start_date}
                  onChange={(selectedDate) =>
                    handleDateChange(selectedDate, "start_date")
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="Start Date (d/M/Y)"
                      helperText={getScheduleDescription(values.schedule)}
                      size={UIConstants.STANDARD_INPUT_SIZE}
                      variant={UIConstants.STANDARD_INPUT_VARIANT}
                      {...params}
                    />
                  )}
                />
              }
            />

            {workOrderStatus.isSchedulable(values.status) && isEditable && (
              <Controls.Button
                variant="contained"
                endIcon={<EventRepeatIcon />}
                onClick={handleSchedule}
                sx={{ width: "100px" }}
                text={
                  values.hasOwnProperty("schedule") &&
                  values.schedule.hasOwnProperty("type")
                    ? "Change"
                    : "Schedule"
                }
              />
            )}
          </Stack>

          {workOrdersCalendar && workOrdersCalendar.length > 0 && (
            <Box sx={{ ...styles.spacer, marginLeft: "10px" }}>
              <Box>
                <Typography
                  component={"span"}
                  variant="caption"
                  color="primary"
                >
                  Scheduled dates after this one
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">{scheduleLabel}</Typography>
              </Box>
              <Box>{nextScheduledDates}</Box>
            </Box>
          )}

          <Controls.TextInput
            name="quote_details"
            label="Quote Details"
            multiline={true}
            value={values.quote_details}
            onChange={handleInputChange}
            sx={{ width: "100%" }}
          />

          <Controls.TextInput
            name="notes"
            label="Notes"
            multiline={true}
            value={values.notes}
            onChange={handleInputChange}
            sx={{ width: "100%" }}
          />
        </Stack>

        <Stack>
          {approver && (
            <Alert severity="info">
              Quote awaiting approval by <b>{approver && approver.name}</b>
            </Alert>
          )}
          {isShowQuotesGrid && (
            <WorkOrderQuotesGrid
              workOrderId={workOrderId}
              status={values.status}
              workOrderAccountId={values.account_id}
              jobs={jobs}
              centres={centres}
              priorities={priorities}
              updateWorkOrder={updateWorkOrder}
            />
          )}

          <Stack gap={2}>
            <SubHeading title="Jobs" />
            <WorkOrderJobsGrid
              jobs={jobs}
              showRemoveJobPrompt={showRemoveJobPrompt}
              updateJob={updateJob}
              setShowProgress={setShowProgress}
              setJobToShowPhotos={setJobToShowPhotos}
              jobToShowPhotos={jobToShowPhotos}
              isEditable={isEditable}
              isSupplierViewing={isSupplierViewing}
              priorities={priorities}
              jobsActions={jobsActions}
              currentUser={currentUser}
            />

            <ViewJobPhotos
              jobToShowPhotos={jobToShowPhotos}
              setJobToShowPhotos={setJobToShowPhotos}
              selectedIndex={0}
            />
          </Stack>
        </Stack>

        <Box sx={{ marginTop: "20px" }}>
          <NavButtons>
            {workOrderId !== "" && !isSupplierViewing && (
              <Controls.Button
                text="Send Email"
                onClick={() => setShowWorkOrderEmailDialog(true)}
                endIcon={<icons.EmailIcon />}
                tooltip="Send email to supplier"
              />
            )}

            {workOrderId !== "" && (
              <Controls.Button
                text="Print"
                onClick={handlePrintWorkOrder}
                endIcon={<icons.PrintIcon />}
              />
            )}

            {workOrderId !== "" && !isSupplierViewing && (
              <Controls.Button
                text="History"
                onClick={() => setShowHistory(true)}
                tooltip="View history of changes to this work order"
              />
            )}

            {!isNew() && isEditable && !isSupplierViewing && (
              <Controls.Button
                text="Delete"
                type="button"
                onClick={(event) => handlePromptConfirmDelete(event)}
                endIcon={<icons.DeleteIcon />}
              />
            )}

            {/* {isEditable && !isSupplierViewing && (
              <Controls.Button
                type="button"
                text="Save and Add New"
                onClick={(event) => handleSubmit(event, true)}
              />
            )} */}

            <Controls.Button
              type="submit"
              text="Save"
              onClick={(event) => handleSubmit(event, false)}
              endIcon={<icons.SaveIcon />}
            />
          </NavButtons>
        </Box>
      </LocalizationProvider>
    </>
  )
}

const SelectJobsToCloseDialog = (props) => {
  const {
    open,
    setOpen,
    jobs,
    workOrderId,
    accountId,
    handleRefreshWorkOrder,
  } = props

  const [jobActions, setJobActions] = useState({})

  const [centres, setCentres] = useState()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (jobs) {
      const centreIds = jobs.map((job) => job.centre_id)
      dataServices.getCentresByIdChunks(centreIds).then((centres) => {
        setCentres(centres)
      })
    }
  }, [jobs])

  const handleOK = async () => {
    // Determine if all jobs requiring clarification have had clarification provided

    const nonClosedJobs = jobs.filter(
      (job) => job.status !== jobServices.JOB_STATUS_CLOSED
    )

    const jobWithoutAction = nonClosedJobs.find((job) => !jobActions[job.id])
    if (jobWithoutAction) {
      enqueueSnackbar(`Please select an action for ${jobWithoutAction.label}`, {
        variant: "info",
      })
      return
    }

    // Close all jobs that require closing

    const jobsToClose = nonClosedJobs.filter(
      (job) => jobActions[job.id] === "close"
    )

    jobsToClose.forEach(async (job) => {
      const result = await dataServices.changeJobStatus(
        job.id,
        job.account_id,
        workOrderId,
        jobServices.JOB_STATUS_CLOSED,
        false
      )
    })

    // Detach all jobs that require detaching

    const jobsToDetach = nonClosedJobs.filter(
      (job) => jobActions[job.id] === "detach"
    )

    jobsToDetach.map(async (job) => {
      await db
        .collection("jobs")
        .doc(job.id)
        .update(
          { work_order_id: "", modified: dataServices.serverTimestamp() },
          { merge: true }
        )
    })

    // Put all jobs in a new work order that were selected as 'workorder' action

    const jobsToWorkOrder = nonClosedJobs.filter(
      (job) => jobActions[job.id] === "workorder"
    )

    if (jobsToWorkOrder.length > 0) {
      const newWorkOrder = await dataServices.getBlankWorkOrderData(accountId)

      // Summary of centres of jobs in this work order
      newWorkOrder.centres = Array.from(
        new Set(jobsToWorkOrder.map((job) => job.centre_id))
      )
      newWorkOrder.label = await workOrderServices.calculateWorkOrderLabel(
        jobsToWorkOrder
      )

      const newWorkOrderRef = await db
        .collection("work_orders")
        .add(newWorkOrder)

      // Attach jobs to new work order
      jobsToWorkOrder.forEach(async (job) => {
        await db.collection("jobs").doc(job.id).update(
          {
            work_order_id: newWorkOrderRef.id,
            modified: dataServices.serverTimestamp(),
          },
          { merge: true }
        )
      })
    }

    // Finally close the work order
    await db
      .collection("work_orders")
      .doc(workOrderId)
      .update({ status: "closed", modified: dataServices.serverTimestamp() })

    setOpen(false)

    // Ask parent to refresh the work order, since we could have done a number of things to
    // the jobs in this work order, i.e. closed, detached, or moved to a new work order
    await handleRefreshWorkOrder()
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleUpdateJobActions = (jobId, action) => {
    const newJobActions = {
      ...jobActions,
      [jobId]: action,
    }
    setJobActions(newJobActions)
  }

  return (
    open && (
      <Dialog open={open}>
        <DialogTitle>Close Work Order</DialogTitle>
        <DialogContent>
          <Box>
            <Typography>
              Not all jobs are closed. Please select what to do with unclosed
              Jobs, so that this Work Order may be closed.
            </Typography>
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            {jobs &&
              jobs.map((job) => (
                <JobCloseout
                  key={job.id}
                  job={job}
                  jobAction={jobActions[job.id]}
                  setJobAction={(action) =>
                    handleUpdateJobActions(job.id, action)
                  }
                  centre={
                    centres &&
                    centres.find((centre) => centre.id === job.centre_id)
                  }
                />
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <NavButtons>
            <Controls.Button text="Cancel" onClick={handleCancel} />
            <Controls.Button
              text="Close Work Order"
              type="submit"
              onClick={handleOK}
            />
          </NavButtons>
        </DialogActions>
      </Dialog>
    )
  )
}

const JobCloseout = (props) => {
  const { job, jobAction, setJobAction, centre } = props

  const handleChange = (event) => {
    setJobAction(event.target.value)
  }

  return (
    <Box sx={styles.job}>
      <Box sx={styles.jobInfo}>
        <Box sx={styles.statusChip}>
          <JobStatusChip status={job.status} />
        </Box>
        <Box>
          <Typography>{job.label}</Typography>
          <Box sx={styles.jobValues}>
            <Typography variant="caption" color="text.secondary">
              {centre && centre.name}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {job.location}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {job.category}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.actions}>
        {job.status !== jobServices.JOB_STATUS_CLOSED && (
          <ToggleButtonGroup
            color="primary"
            size="small"
            value={jobAction}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton sx={styles.buttonLabel} value="close">
              Close Job
            </ToggleButton>
            <ToggleButton sx={styles.buttonLabel} value="detach">
              Detach Job
            </ToggleButton>
            <ToggleButton sx={styles.buttonLabel} value="workorder">
              New Work Order
            </ToggleButton>
          </ToggleButtonGroup>
        )}

        {job.status === jobServices.JOB_STATUS_CLOSED && (
          <Typography variant="caption">
            This job has been closed, and is not preventing the Work Order from
            being closed
          </Typography>
        )}
        {jobAction === "close" && (
          <Typography variant="caption">This job will be closed</Typography>
        )}
        {jobAction === "detach" && (
          <Typography variant="caption">
            This job will be detached from this work order and set to Open
          </Typography>
        )}
        {jobAction === "workorder" && (
          <Typography variant="caption">
            A new work order will be created, and this job will be added to it
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default withRouter(WorkOrderEditForm)
