import React from "react"
import { Badge, Tooltip, Typography } from "@mui/material"
import * as actionServices from "../../pages/services/actionServices"
import StyledChip from "./StyledChip"
import { blue, grey, purple } from "@mui/material/colors"

const statusColors = {
  [actionServices.STATUS_OPEN]: blue[600],
  [actionServices.STATUS_COMPLETED]: purple[700],
  [actionServices.STATUS_CLOSED]: grey[900],
}

const actionStatusLabels = {
  [actionServices.STATUS_OPEN]: "Open",
  [actionServices.STATUS_COMPLETED]: "Completed",
  [actionServices.STATUS_CLOSED]: "Closed",
}

const getChipProps = (status, theme, ...other) => {
  const result = {
    color: statusColors[status],
    borderColor: statusColors[status],
    borderRadius: 5,
  }

  return result
}

const ActionStatusChip = (props) => {
  const {
    status,
    size = "small",
    selected = true,
    showBadge = false,
    tooltip,
    ...other
  } = props

  const label = (
    <Typography variant="caption">{actionStatusLabels[status]}</Typography>
  )

  const wrapBadge = (wrap, child) => {
    if (wrap) {
      return (
        <Badge color="error" variant="dot">
          {child}
        </Badge>
      )
    }
    return child
  }

  const wrapTooltip = (tooltip, child) => {
    if (tooltip) {
      return (
        <Tooltip title={tooltip} placement="top">
          {child}
        </Tooltip>
      )
    }
    return child
  }

  return wrapTooltip(
    tooltip,
    wrapBadge(
      showBadge,
      <StyledChip
        {...other}
        size={size}
        label={label}
        status={selected ? status : "deselected"}
        getChipProps={getChipProps}
        variant="outlined"
      />
    )
  )
}

export default ActionStatusChip
