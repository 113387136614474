import * as React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import db from "../../Firestore"
import _ from "lodash"
import { useEffect } from "react"
import { useState } from "react"
import * as UIConstants from "./UIConstants"
import { getErrorProps } from "./FieldValidation"
import { Typography } from "@mui/material"
// import {
//   getCountFromServer,
//   collection,
//   query,
//   orderBy,
//   startAfter,
//   startAt,
//   limit,
//   getDocs,
// } from "firebase/firestore"

/**
 *
 * @param {string} accountId | The currently selected account, passed in to provide a default value
 * @param {string} userId | The currently selected centre manager, passed in to provide a default value
 * @param {function} handleChange | Called when the user selects a user
 * @returns
 */
const UserAutoComplete = ({
  accountId,
  userId,
  handleChange,
  handleClear,
  label,
  showError = false,
  errorFuncs = [],
  helperText,
  // what roles does the user need to have? empty array = any role
  roles = [],
}) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [timeoutRef, setTimeoutRef] = useState()
  const [value, setValue] = useState({ id: "", title: "" })

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const errorProps = React.useMemo(() => {
    if (showError && errorFuncs) {
      return getErrorProps({ value: value?.id, showError, errorFuncs })
    }
  }, [showError, errorFuncs, value])

  // Set the default value of the autocomplete to the currently selected user
  useEffect(() => {
    if (userId && accountId) {
      console.log("loading users", { userId, accountId })
      db.collection("users")
        .doc(userId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const { name, email } = doc.data()
            const user = {
              id: doc.id,
              title: email,
              name,
              email,
            }
            setOptions([user])
            setValue(user)
          }
        })
        .catch((err) => {
          console.error("Error getting user:", err, userId)
          handleClear()
        })
    }
  }, [userId, accountId])

  const getAssignToQueries = (searchVal, accountId) => {
    let nameQuery = db
      .collection("users")
      .where("account_id", "==", accountId)
      .where("name", ">=", searchVal)
      .orderBy("name")
      .startAt(searchVal)
      .endAt(searchVal + "\uf8ff")
      .limit(5)

    if (roles.length > 0) {
      nameQuery = nameQuery.where("roles", "array-contains-any", roles)
    }

    let emailQuery = db
      .collection("users")
      .where("account_id", "==", accountId)
      .where("email", ">=", searchVal)
      .orderBy("email")
      .startAt(searchVal)
      .endAt(searchVal + "\uf8ff")
      .limit(5)

    if (roles.length > 0) {
      emailQuery = emailQuery.where("roles", "array-contains-any", roles)
    }

    return [
      {
        query: nameQuery,
      },
      {
        query: emailQuery,
      },
    ]
  }

  const handleUpdateOptions = (newValue, accountId) => {
    if (!accountId) {
      return
    }

    if (timeoutRef) {
      clearTimeout(timeoutRef)
      setTimeoutRef(undefined)
    }

    if (newValue !== "") {
      const t = setTimeout(async () => {
        setOpen(true)

        const queries = getAssignToQueries(newValue, accountId)
        const results = await Promise.all(
          queries.map(async (q) => ({
            type: q.type,
            result: await q.query.get(),
          }))
        )

        const options = results.flatMap((queryResult) =>
          queryResult.result.docs.map((doc) => {
            const { name, email } = doc.data()
            return {
              id: doc.id,
              title: email,
              name,
              email,
            }
          })
        )

        setOptions((curr) => {
          const newOptions = _.uniqBy([...curr, ...options, value], "id")
          return newOptions
        })
      }, 700)

      setTimeoutRef(t)
    }
  }

  return (
    <Autocomplete
      id="user_id"
      sx={{ width: 300 }}
      freeSolo
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option?.title || ""}
      options={options}
      onChange={(event, value) => {
        setValue(value)
        handleChange(value)
      }}
      value={value}
      onInputChange={(event, value) => {
        handleUpdateOptions(value, accountId)
      }}
      renderOption={(props, option) => {
        const { key, ...rest } = props
        return (
          <li key={key} {...rest}>
            <div>
              <strong>{option.title}</strong>
              <div>{option.name}</div>
            </div>
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={UIConstants.STANDARD_INPUT_VARIANT}
          size={UIConstants.STANDARD_INPUT_SIZE}
          error={errorProps ? errorProps.error : false}
          helperText={
            <Typography variant="caption" component={"span"}>
              {errorProps ? errorProps.helperText : helperText}
            </Typography>
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default UserAutoComplete
