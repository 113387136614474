import React, { useState } from "react"
import SupplierEditForm from "../components/SupplierEditForm"
import Header from "../components/Header"
import { useId } from "./useId"

const SupplierEdit = (props) => {
  let { id } = useId(props)

  const [title, setTitle] = useState("Edit Supplier")

  return (
    <Header title={title}>
      <SupplierEditForm id={id} setTitle={setTitle} />
    </Header>
  )
}

export default SupplierEdit
