import React, { useEffect, useState, useMemo } from "react"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
} from "@mui/material"
import Controls from "./controls/Controls"
import DeleteIcon from "@mui/icons-material/Delete"
import { useForm, Form } from "./useForm"
import { useSnackbar } from "notistack"
import UserIcon from "@mui/icons-material/Person"
import * as Roles from "../pages/services/roleServices"
import { FormControl } from "@mui/material"
import { FormLabel } from "@mui/material"
import { FormGroup } from "@mui/material"
import { FormControlLabel } from "@mui/material"
import { Checkbox } from "@mui/material"
import { Typography } from "@mui/material"
import * as icons from "../icons"
import { spacing } from "../pages/services/styleServices"
import NavButtons from "./NavButtons"
import * as supplierServices from "../pages/services/supplierServices"
import SubHeading from "./SubHeading"
import { onAuthStateChanged, getAuth } from "firebase/auth"

const STAFF_INVITE = "staff"
const SUPPLIER_INVITE = "supplier"

const initialValues = () => {
  return {
    email: "",
    account_id: "",
    name: "",
    type: STAFF_INVITE,
    roles: [],
    centres: [],
    created: dataServices.localTimestamp(),
  }
}

const styles = {
  checkboxes: {
    paddingTop: spacing(2),
  },
  inputFields: {
    paddingTop: spacing(2),
  },
}

const InviteList = (props) => {
  const [invites, setInvites] = useState([])

  const COLLECTION_NAME = "invites"

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const [maxCreated, setMaxCreated] = useState()

  const [user, setUser] = useState()

  const [claims, setClaims] = useState()

  const [centres, setCentres] = useState([])

  const [accountId, setAccountId] = useState()

  const [accountType, setAccountType] = useState("")

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      setUser(user)
      user.getIdTokenResult(false).then((token) => {
        setAccountId(token.claims.account_id)
        setAccountType(token.claims.account_type)
        setClaims(token.claims)

        dataServices
          .getCentresByAccountId(token.claims.account_id, true)
          .then((centres) => {
            setCentres(centres.sort((a, b) => a.name.localeCompare(b.name)))
          })
      })
    })

    return unsub
  }, [])

  // Listen for invite changes

  useEffect(() => {
    if (claims === undefined) {
      return
    }

    const unsub = db
      .collection(COLLECTION_NAME)
      .where("account_id", "==", claims.account_id)
      .onSnapshot(
        (querySnapshot) => {
          let newMaxCreated = null

          querySnapshot.docChanges().forEach((change) => {
            if (
              newMaxCreated === null ||
              change.doc.data().created.seconds > newMaxCreated.seconds
            ) {
              newMaxCreated = change.doc.data().created
            }
          })
          if (newMaxCreated !== null) {
            setMaxCreated(newMaxCreated)
          }
        },
        (error) => console.error("error listening for invite changes", error)
      )

    return unsub // gets called on unmount
  }, [claims])

  const handleSelectCentre = (event) => {
    if (event.target.checked) {
      // Adding
      const newCentreIds = [...values.centres]
      newCentreIds.push(event.target.value)
      const newValues = {
        ...values,
        centres: newCentreIds,
      }
      setValues(newValues)
    } else {
      // Removing
      const newCentreIds = values.centres.filter(
        (centreId) => centreId !== event.target.value
      )
      const newValues = {
        ...values,
        centres: newCentreIds,
      }
      setValues(newValues)
    }
  }

  const handleSelectRole = (event) => {
    if (event.target.checked) {
      // Adding
      const newRoles = [...values.roles]
      newRoles.push(event.target.value)
      const newValues = {
        ...values,
        roles: newRoles,
      }
      setValues(newValues)
    } else {
      // Removing
      const newRoles = values.roles.filter(
        (roleId) => roleId !== event.target.value
      )
      const newValues = {
        ...values,
        roles: newRoles,
      }
      setValues(newValues)
    }
  }

  const handleCreateInvite = async (event) => {
    event.preventDefault()

    if (values.email === "") {
      enqueueSnackbar("Enter email", { variant: "info" })
      return
    }

    if (values.type === "") {
      enqueueSnackbar("Enter type", { variant: "info" })
      return
    }

    // Check email not already used by a user in this account
    const existingUser = await dataServices.getUser(values.email, accountId)
    if (existingUser) {
      enqueueSnackbar(`${values.email} is already a user in this account`, {
        variant: "warning",
      })
      return
    }

    // If this is a 'Staff' invite, check that the user doesn't already exist in *any* account.
    // On the other hand, suppliers can accept invites from multiple accounts so that they can do jobs for those accounts
    if (values.type === STAFF_INVITE) {
      const emailInUse = cloudFunctions.checkEmailInUse(values.email)
      if (emailInUse) {
        enqueueSnackbar("Email already in use. Cannot create staff invite", {
          variant: "warning",
        })
        return
      }
    }

    // Check invite doesn't already exist for the given email
    const existingInvites = await dataServices.findInviteByEmail(
      values.email,
      accountId
    )

    if (existingInvites.length > 0) {
      enqueueSnackbar("Invite already exists for " + values.email, {
        variant: "warning",
      })
    } else {
      await dataServices.createInvite(values, accountId)

      await sendEmailInvite(values.email, values.type)

      // Reset form for next invite
      setValues(initialValues())
    }
  }

  const sendEmailInvite = async (email, type) => {
    const accountDoc = await db.collection("accounts").doc(accountId).get()
    const account = accountDoc.data()

    let emailText

    switch (type) {
      case STAFF_INVITE:
        emailText = `You have been invited to join the ${account.name} Jobs For Joe account as a staff member. Please visit https://app.jobsforjoe.com/ to join.`
        break

      case SUPPLIER_INVITE:
        emailText = `You have been invited to join the ${account.name} Jobs For Joe account as a service provider. If you visit https://app.jobsforjoe.com/ you will be added as a service provider, so that you can be allocated work orders`
        break

      default:
        enqueueSnackbar(`Error: Unknown invite type: ${type}`, {
          variant: "error",
        })
        break
    }

    cloudFunctions
      .sendHtmlEmail({
        to: email,
        subject: "Invite to join Jobs For Joe",
        replyTo: user.email,
        text: emailText,
        html: emailText,
      })
      .then(enqueueSnackbar("Sent invite", { variant: "success" }))
      .catch((err) => console.error("Error calling sendEmail", err))
  }

  const handleDelete = (inviteId) => {
    dataServices.deleteInvite(inviteId)
  }

  // Load invites

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    let query = db.collection(COLLECTION_NAME)
    query = dataServices
      .modifyQuery(query, "description", "")
      .where("account_id", "==", accountId)

    dataServices.loadData("(Load invites)", query).then((invites) => {
      const inviteList = invites.map((invite) => {
        return { ...initialValues(), ...invite }
      })
      setInvites(inviteList)
    })
  }, [maxCreated, user, accountId])

  const getCentreNames = (invite) => {
    // For suppliers, they can work orders for any centre to which they've been allocated
    if (invite.type === SUPPLIER_INVITE) {
      return ""
    }

    if (invite.centres.length === 0) {
      return "All centres"
    }
    return (
      " at centres: " +
      centres
        .filter((centre) => invite.centres.includes(centre.id))
        .map((centre) => centre.name)
        .join(", ")
    )
  }

  return (
    <Grid container>
      <Grid item sm={5} xs={12}>
        <CreateInvite
          handleInputChange={handleInputChange}
          values={values}
          setValues={setValues}
          handleSelectRole={handleSelectRole}
          handleSelectCentre={handleSelectCentre}
          handleCreateInvite={handleCreateInvite}
          centres={centres}
          accountType={accountType}
        />
      </Grid>
      <Grid item sm={7} xs={12}>
        <OutstandingInvites
          handleDelete={handleDelete}
          getCentreNames={getCentreNames}
          invites={invites}
        />
      </Grid>
    </Grid>
  )
}

const CreateInvite = (props) => {
  const {
    handleInputChange,
    values,
    setValues,
    handleSelectRole,
    handleSelectCentre,
    handleCreateInvite,
    centres,
    accountType,
  } = props

  const userCentreOptions = useMemo(() => {
    if (values.centres && centres) {
      return centres
        .filter((c) => values.centres.includes(c.id))
        .map((c) => ({ value: c.id, label: c.name }))
    }
    return []
  }, [values.centres, centres])

  const inviteTypes = useMemo(() => {
    const result = [
      {
        id: STAFF_INVITE,
        title: "Staff",
      },
    ]

    // Centres can send invites for staff and suppliers
    // but suppliers can only send invites for their own staff, i.e. not invites to other suppliers
    if (accountType === Roles.ACCOUNT_TYPE_CENTRE) {
      result.push({
        id: SUPPLIER_INVITE,
        title: "Supplier",
      })
    }
    return result
  }, [accountType])

  return (
    <Form sx={styles.createInviteArea}>
      <Stack gap={2}>
        <Stack gap={2} sx={{ marginTop: "15px" }}>
          <SubHeading title="Create Invite" />
          <Controls.TextInput
            name="name"
            label="Name"
            value={values.name}
            icon={<icons.UserIcon />}
            onChange={handleInputChange}
            autoFocus
            helperText="Name of person invited to join this account"
          />

          <Controls.TextInput
            name="email"
            label="Email"
            value={values.email}
            icon={<icons.EmailIcon />}
            onChange={(event) =>
              handleInputChange({
                target: {
                  name: event.target.name,
                  value: event.target.value.toLowerCase(),
                },
              })
            }
          />

          <Controls.Select
            name="type"
            label="Type"
            value={values.type}
            onChange={handleInputChange}
            options={inviteTypes}
          />
        </Stack>
        {/* When issuing a supplier invite, they are automatically given a 'supplier' role, 
            which governs what they can do in an account for which they have been invited to do work */}
        {values.type === STAFF_INVITE && (
          <Box>
            <SubHeading title="Assign user role(s)" />
            <Stack gap={0}>
              <FormControl component="fieldset">
                <FormGroup>
                  {Object.keys(Roles.userAssignableRoles).map((roleId) => (
                    <FormControlLabel
                      key={roleId}
                      control={
                        <Checkbox
                          //color="primary"
                          checked={values.roles.indexOf(roleId) !== -1}
                          value={roleId}
                          name="role"
                          onChange={handleSelectRole}
                          sx={{
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {Roles.userAssignableRoles[roleId]}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Stack>
          </Box>
        )}
        {values.type === SUPPLIER_INVITE && (
          <FormControl>
            <SubHeading title="Roles and Centres" />
            <FormLabel>Roles and Centres</FormLabel>
            <FormGroup>
              <Typography>
                Suppliers do not need roles or centres assigned.
              </Typography>
              <Typography>
                They can only access work orders allocated to them in accounts
                to which they have been invited
              </Typography>
            </FormGroup>
          </FormControl>
        )}
        {/* When issuing a supplier invite, we don't need to select the centres 
            they can access, as this is governed by the work order assigned to them */}
        {/* {values.type === STAFF_INVITE && (
          <Box>
            <SubHeading title="Assign centre(s)" />
            <FormControl component="fieldset">
              <FormGroup>
                {centres.map((centre) => (
                  <FormControlLabel
                    key={centre.id}
                    control={
                      <Checkbox
                        sx={{
                          paddingTop: "2px",
                          paddingBottom: "2px",
                        }}
                        checked={values.centres.indexOf(centre.id) !== -1}
                        value={centre.id}
                        name="centre"
                        onChange={handleSelectCentre}
                      />
                    }
                    label={
                      <Typography variant="body2">{centre.name}</Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          */}
      </Stack>

      {values.type === STAFF_INVITE && (
        <Stack gap={2} sx={{ my: "20px" }}>
          <SubHeading title="Assign centre access" />
          <Autocomplete
            multiple
            id="user_centres"
            options={centres.map((centre) => ({
              value: centre.id,
              label: centre.name,
            }))}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.label}
            value={userCentreOptions}
            onChange={(event, newValues) => {
              setValues({ ...values, centres: newValues.map((v) => v.value) })
            }}
            renderInput={(params) => (
              <Controls.TextInput
                {...params}
                label="Centres"
                name="centres"
                onChange={(e, val) => {
                  console.log("change", { e, val })
                }}
              />
            )}
          />
        </Stack>
      )}

      <NavButtons>
        <Controls.Button
          type="submit"
          text="Send Invite"
          onClick={handleCreateInvite}
          endIcon={<icons.InviteIcon />}
        />
      </NavButtons>
    </Form>
  )
}

const OutstandingInvites = (props) => {
  const { invites, handleDelete, getCentreNames } = props

  const getRoleNames = (invite) => {
    // Suppliers don't get roles assigned. They automatically get assigned a 'supplier' role
    // and can only do a set number of things in their supplier account
    if (invite.type === SUPPLIER_INVITE) {
      return ""
    }

    if (invite.roles.length === 0) {
      return "No roles assigned"
    }

    return (
      " as " +
      invite.roles.map((roleId) => Roles.userAssignableRoles[roleId]).join(", ")
    )
  }

  return (
    <Box sx={{ marginTop: "15px" }}>
      <SubHeading title="Sent Invites" />
      {invites.find((invite) => invite.type === SUPPLIER_INVITE) && (
        <Alert severity="info" sx={{ marginTop: "10px", marginBottom: "10px" }}>
          Invited suppliers must first create their own supplier account via the
          'Sign Up New Organisation' link on the Sign In page. Then they will be
          presented with your invite on the Sign In page which they can accept.
          After they accept the invite they will gain access to work orders
          allocated to them in your account
        </Alert>
      )}
      <List>
        {invites.length === 0 && (
          <Typography>
            There are no invites created that have not yet been accepted
          </Typography>
        )}
        {invites.map((invite) => (
          <ListItem key={invite.id}>
            <ListItemAvatar>
              {invite.type === STAFF_INVITE ? (
                <Avatar invitetype={invite.type}>
                  <UserIcon />
                </Avatar>
              ) : (
                supplierServices.getSupplierAvatar({ online: true })
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="body2">
                  {invite.email} {getRoleNames(invite)} {getCentreNames(invite)}
                </Typography>
              }
              secondary={invite.type}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(invite.id)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default InviteList
