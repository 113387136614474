import React, { useEffect, useState } from "react"
import ComboBox from "./ComboBox"
import * as dataServices from "../../pages/services/dataServices"
import { tsToDateStr } from "../../pages/services/dateServices"
import { Box, Stack, Typography } from "@mui/material"
import * as workOrderServices from "../../pages/services/workOrderServices"
import LinkButton from "./LinkButton"
import StatusChip from "./StatusChip"

/**
 * Show open work orders
 */
const WorkOrderCombobox = (props) => {
  const {
    name,
    label,
    value,
    reloadValue,
    onChange,
    helperText,
    readonly,
    accountId,
    accountType,
    supplierId,
    isShowOpenLink = false,
    isSaved = false,
    allocatedSupplier,
    workOrderStatus,
  } = props

  const noneLabel = ""

  const [workOrderOptions, setWorkOrderOptions] = useState([
    {
      id: "",
      label: noneLabel,
    },
  ])

  const createWorkOrderOption = (workOrder) => {
    return {
      id: workOrder.id,
      label: `${
        workOrder.work_order_no ? workOrder.work_order_no + " | " : ""
      } ${workOrder.label}`.trim(),
    }
  }

  useEffect(() => {
    if (accountId) {
      if (readonly) {
        if (value !== "" && value !== undefined) {
          const workOrderIds = [value]

          dataServices
            .getWorkOrdersByIdChunks({
              workOrderIds,
              accountId,
            })
            .then((workOrders, accountId) => {
              const options = workOrders.map((workOrder) =>
                createWorkOrderOption(workOrder)
              )
              setWorkOrderOptions(options)
            })
        }
      } else {
        dataServices
          .getOpenWorkOrdersByAccountIdAndSupplierId(
            accountId,
            supplierId,
            true
          )
          .then((workOrders) => {
            const options = workOrders.map((workOrder) =>
              createWorkOrderOption(workOrder)
            )
            options.push({ id: "", label: noneLabel })
            setWorkOrderOptions(options)
          })
          .catch((err) => console.error("Unable to load work orders", err))
      }
    }
  }, [readonly, value, reloadValue, accountId, supplierId])

  return (
    <Stack direction="column" sx={{ width: "250px" }}>
      <ComboBox
        name={name}
        label={label}
        value={value}
        items={workOrderOptions}
        onChange={onChange}
        disabled={readonly}
        helperText={<>{helperText && <Typography>{helperText}</Typography>}</>}
      />
      {value && isSaved && isShowOpenLink && (
        <Stack direction="row" gap={1}>
          <LinkButton
            to={workOrderServices.getOpenWorkOrderUrl({
              accountType: accountType,
              workOrderId: value,
            })}
          >
            <Typography variant="caption">Go to</Typography>
          </LinkButton>
          {allocatedSupplier && (
            <LinkButton to={`/supplieredit/${allocatedSupplier.id}`}>
              <Typography variant="caption">
                {allocatedSupplier.name}
              </Typography>
            </LinkButton>
          )}
          {workOrderStatus && (
            <Box>
              <StatusChip status={workOrderStatus} />
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default WorkOrderCombobox
