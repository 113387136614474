import { Avatar, Tooltip } from "@mui/material"
import React from "react"
import * as workOrderStatus from "../workOrderStatus"

const StatusAvatar = (props) => {
    const { status } = props

    return (
        <Tooltip title={workOrderStatus.statusLabels[status]}>
            <Avatar
                style={{
                    backgroundColor: workOrderStatus.statusColors[status],
                }}
            >
                {workOrderStatus.statusAvatarAbbreviations[status]}
            </Avatar>
        </Tooltip>
    )
}

export default StatusAvatar
