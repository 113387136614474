import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { createRef } from "react"
import { useSnackbar } from "notistack"
import * as dataServices from "../pages/services/dataServices"
import * as jobServices from "../pages/services/jobServices"
import LinkButton from "./controls/LinkButton"
import Priority from "./Priority"
import Controls from "./controls/Controls"
import { Desktop, Mobile } from "./WindowSizes"
import CentreName from "./CentreName"
import JobMenu from "./JobMenu"
import JobTile from "./JobTile"
import TruncatedText from "./TruncatedText"

const styles = {
  cellSmall: {
    minWidth: "45px",
    padding: "4px 4px",
  },
  tableHead: {
    backgroundColor: "theme.palette.primary.light",
    color: "theme.palette.common.white",
    minWidth: "45px",
    padding: "4px 4px",
    fontWeight: "bold",
  },
}

const WorkOrderJobsGrid = ({
  jobs,
  showRemoveJobPrompt,
  updateJob,
  setShowProgress,
  setJobToShowPhotos,
  jobToShowPhotos,
  isEditable,
  isSupplierViewing,
  priorities,
  jobsActions,
  currentUser,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const changeJobState = (job, newStatus) => {
    updateJob({ ...job, status: newStatus })
  }

  const updateJobStatus = async (job, newStatus) => {
    const message = await dataServices.changeJobStatus(
      job.id,
      job.account_id,
      job.work_order_id,
      newStatus
    )

    if (message) {
      enqueueSnackbar(message, { variant: "info" })
    }
  }

  const handleCloseJob = async (job) => {
    changeJobState(job, jobServices.JOB_STATUS_CLOSED)
    updateJobStatus(job, jobServices.JOB_STATUS_CLOSED)
  }

  const handleOpenJob = async (job) => {
    changeJobState(job, jobServices.JOB_STATUS_OPEN)
    updateJobStatus(job, jobServices.JOB_STATUS_OPEN)
  }

  const handleCompleteJob = async (job) => {
    changeJobState(job, jobServices.JOB_STATUS_COMPLETED)
    updateJobStatus(job, jobServices.JOB_STATUS_COMPLETED)
  }

  const handleEditJob = (job) => {
    history.push({
      pathname: `/jobedit/${job.id}`,
      state: { context: { type: "work_order", id: job.work_order_id } },
    })
  }

  const handleFileUpload = async (job, event) => {
    const files = []
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i]
      files.push(file)
    }

    setShowProgress(true)

    const newJob = await jobServices.uploadFiles(
      job.id,
      job,
      files,
      currentUser
    )

    updateJob({ ...job, docs: newJob.docs, file_info: newJob.file_info })

    if (jobToShowPhotos) {
      setJobToShowPhotos({ ...newJob, id: job.id })
    }

    setShowProgress(false)

    // Clear file input field
    event.target.value = null
  }

  return (
    <>
      <Desktop width={675}>
        <JobsAsGrid
          jobs={jobs}
          showRemoveJobPrompt={showRemoveJobPrompt}
          updateJob={updateJob}
          setShowProgress={setShowProgress}
          setJobToShowPhotos={setJobToShowPhotos}
          jobToShowPhotos={jobToShowPhotos}
          isEditable={isEditable}
          isSupplierViewing={isSupplierViewing}
          priorities={priorities}
          jobsActions={jobsActions}
          handleCloseJob={handleCloseJob}
          handleOpenJob={handleOpenJob}
          handleCompleteJob={handleCompleteJob}
          handleFileUpload={handleFileUpload}
          handleEditJob={handleEditJob}
        />
      </Desktop>
      <Mobile width={675}>
        <JobsAsTiles
          jobs={jobs}
          showRemoveJobPrompt={showRemoveJobPrompt}
          updateJob={updateJob}
          setShowProgress={setShowProgress}
          setJobToShowPhotos={setJobToShowPhotos}
          jobToShowPhotos={jobToShowPhotos}
          isEditable={isEditable}
          isSupplierViewing={isSupplierViewing}
          priorities={priorities}
          jobsActions={jobsActions}
          handleCloseJob={handleCloseJob}
          handleOpenJob={handleOpenJob}
          handleCompleteJob={handleCompleteJob}
          handleFileUpload={handleFileUpload}
          handleEditJob={handleEditJob}
        />
      </Mobile>
    </>
  )
}

const JobsAsGrid = ({
  jobs,
  showRemoveJobPrompt,
  updateJob,
  setShowProgress,
  setJobToShowPhotos,
  jobToShowPhotos,
  isEditable,
  isSupplierViewing,
  priorities,
  jobsActions,
  handleCloseJob,
  handleOpenJob,
  handleCompleteJob,
  handleFileUpload,
  handleEditJob,
}) => {
  return (
    <Grid item>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={styles.tableHead}>
                Job
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Centre
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Category
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Priority
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Status
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Location
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Job Owner
              </TableCell>
              <TableCell align="right" sx={styles.tableHeadMenu}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
              <JobRow
                key={job.id}
                job={job}
                showRemoveJobPrompt={showRemoveJobPrompt}
                updateJob={updateJob}
                setShowProgress={setShowProgress}
                setJobToShowPhotos={setJobToShowPhotos}
                jobToShowPhotos={jobToShowPhotos}
                isEditable={isEditable}
                isSupplierViewing={isSupplierViewing}
                priorities={priorities}
                jobsActions={jobsActions}
                handleCloseJob={() => {
                  handleCloseJob(job)
                }}
                handleOpenJob={() => handleOpenJob(job)}
                handleCompleteJob={() => handleCompleteJob(job)}
                handleFileUpload={handleFileUpload}
                handleEditJob={() => handleEditJob(job)}
              />
            ))}

            {jobs.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="left" sx={styles.cellSmall}>
                  Add jobs via the Jobs page
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

const JobRow = ({
  job,
  showRemoveJobPrompt,
  setJobToShowPhotos,
  isEditable,
  isSupplierViewing,
  priorities,
  jobsActions,
  handleCloseJob,
  handleOpenJob,
  handleCompleteJob,
  handleFileUpload,
  handleEditJob,
}) => {
  const [anchorEl, setAnchorEl] = useState()

  const addFileRef = createRef()

  const handleClickJobMenu = (event) => {
    setAnchorEl(event.target)
  }

  // Close the job popup menu

  const handleJobMenuClose = () => {
    setAnchorEl(null)
  }

  // Prompt whether the selected job should be remove from this work order

  const handleRemoveJob = () => {
    handleJobMenuClose()
    showRemoveJobPrompt(job.id)
  }

  const handleClickAddPhoto = (event) => {
    event.preventDefault()
    addFileRef.current.click()
  }

  const handleViewPhotos = () => {
    handleJobMenuClose()
    setJobToShowPhotos(job)
  }

  const menuId = `xxx-job-menu-${job.id}`

  return (
    <TableRow>
      <TableCell align="left" sx={styles.cellSmall}>
        <LinkButton onClick={() => handleEditJob(job)}>
          <TruncatedText sx={{ width: "260px" }} variant="body2">
            {job.label}
          </TruncatedText>
        </LinkButton>
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        <Typography variant="body2">
          <CentreName
            centre={job.centre}
            isSupplierViewing={isSupplierViewing}
          />
        </Typography>

        <input
          accept="image/*"
          sx={styles.input}
          style={{ display: "none" }}
          id="selected-files"
          multiple
          type="file"
          ref={addFileRef}
          onChange={(e) => {
            handleFileUpload(job, e)
          }}
        />
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        {job.category}
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        <Priority priority={job.priority} priorities={priorities} />
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        <Controls.JobStatusChip
          status={job.status}
          showBadge={
            jobsActions &&
            jobsActions.find((action) => action.parent_id === job.id)
          }
        />
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        {job.location}
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        {job.user?.name}
      </TableCell>
      <TableCell align="right" sx={styles.cellSmall}>
        <IconButton
          size="small"
          aria-label="more"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={(event) => handleClickJobMenu(event)}
        >
          <MoreVertIcon />
        </IconButton>

        {anchorEl && (
          <JobMenu
            job={job}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            menuId={menuId}
            isEditable={isEditable}
            isSupplierViewing={isSupplierViewing}
            handleCloseJob={() => {
              setAnchorEl(null)
              handleCloseJob(job)
            }}
            handleOpenJob={() => {
              setAnchorEl(null)
              handleOpenJob(job)
            }}
            handleCompleteJob={() => {
              setAnchorEl(null)
              handleCompleteJob(job)
            }}
            handleClickAddPhoto={handleClickAddPhoto}
            handleViewPhotos={handleViewPhotos}
            handleEditJob={() => {
              setAnchorEl(null)
              handleEditJob(job)
            }}
            handleRemoveJob={handleRemoveJob}
          />
        )}
      </TableCell>
    </TableRow>
  )
}

const JobsAsTiles = ({
  jobs,
  isEditable,
  showRemoveJobPrompt,
  isSupplierViewing,
  handleCloseJob,
  handleOpenJob,
  handleCompleteJob,
  handleEditJob,
  priorities,
  jobsActions,
  handleFileUpload,
  setJobToShowPhotos,
}) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1 }}
    >
      {jobs.map((job) => {
        return (
          <JobTile
            key={job.id}
            job={job}
            isEditable={isEditable}
            isSupplierViewing={isSupplierViewing}
            handleCloseJob={handleCloseJob}
            handleOpenJob={handleOpenJob}
            handleCompleteJob={handleCompleteJob}
            handleEditJob={handleEditJob}
            showRemoveJobPrompt={showRemoveJobPrompt}
            priorities={priorities}
            jobsActions={jobsActions}
            handleFileUpload={handleFileUpload}
            setJobToShowPhotos={setJobToShowPhotos}
          />
        )
      })}
    </Box>
  )
}

export default WorkOrderJobsGrid
