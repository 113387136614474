import { min } from "lodash"
import db from "../../Firestore"
import {
  createChatCompletionWithFunctions,
  createEmbedding,
  createChatCompletion,
} from "./cloudFunctions"
import * as dataServices from "./dataServices"

//const GPT_4_1106_PREVIEW = "gpt-4-turbo-preview"
//const GPT_4_LATEST = GPT_4_1106_PREVIEW //"gpt-4-0613"
const GPT_4o_LATEST = "gpt-4o"
//const GPT_3_5_TURBO = "gpt-3.5-turbo"
//const GPT_3_5_TURBO_LATEST = "gpt-3.5-turbo-0613"

// For creating embeddings
const TEXT_EMBEDDING_ADA_002 = "text-embedding-ada-002"

const getChatCompletionResult = async ({
  messages,
  model = GPT_4o_LATEST,
  functions,
  function_call,
}) => {
  let result
  try {
    if (process.env.NODE_ENV === "development") {
      console.log("%cget completion", "color:pink", {
        messages,
        model,
        functions,
        function_call,
      })
    }
    result = await createChatCompletionWithFunctions({
      messages: messages,
      model,
      funcs: functions,
      function_call,
    })
    console.log("result", result)
  } catch (e) {
    console.log("error", e)
    return { error: e }
  }
  //const response = result.data.response

  if (result.data.error) {
    return { error: result.data.error }
  } else {
    const funcCallResultStr =
      result.data.response.choices[0].message.function_call.arguments
    return { jsonStr: funcCallResultStr }
  }
}

const createGptFunctionForSelfRepairAnalysis = ({ jobSelfRepairPrompt }) => {
  const functionName = "analyze_self_repairable"

  // Assess from the job description if the job could be done in a reasonably short amount of time by a non-tradesperson
  // Provide the reason why or why not.

  const parameters = {
    type: "object",
    properties: {
      self_repairable: {
        type: "string",
        description: jobSelfRepairPrompt,
        enum: ["yes", "no"],
      },
      requires_tools: {
        type: "string",
        description: "Indication if the job requires any tools at all.",
        enum: ["yes", "no"],
      },
      reason: {
        type: "string",
        description:
          "The reason why the job could be done in a reasonably short amount of time by a non-tradesperson, or why not.",
        minLength: 100,
        maxLength: 200,
      },
    },
    required: ["self-repairable"],
  }

  const functions = [
    {
      name: functionName,
      description:
        "Provide a recommendation on whether the job could be done in a reasonably short amount of time by a non-tradesperson",
      parameters: parameters,
    },
  ]

  return functions
}

const analyseIfSelfRepairable = async (job) => {
  const accountDoc = await db.collection("accounts").doc(job.account_id).get()
  const account = accountDoc.data()

  const functions = createGptFunctionForSelfRepairAnalysis({
    jobSelfRepairPrompt: account.job_self_repair_prompt,
  })

  const messages = [
    {
      role: "system",
      content: `You're an experienced facilities manager ${
        account.industry ? `in ${account.industry}` : ""
      }, and an expert in building repairs ${
        account.industry ? `and ${account.industry} related regulations` : ""
      }. Analyze the provided job details:\n`,
    },
    { role: "user", content: "The job information is:" },

    {
      role: "user",
      content: `- ${job.label}`,
    },
    {
      role: "user",
      content: `- ${job.description}`,
    },
    {
      role: "user",
      content: `- Location: ${job.location}`,
    },
    {
      role: "user",
      content: `- Category: ${job.category}`,
    },
    {
      role: "user",
      content: `- Priority: ${job.priority}`,
    },
  ]

  const result = await getChatCompletionResult({
    messages,
    functions,
    function_call: { name: functions[0].name },
  })

  console.log("result", result)

  return JSON.parse(result.jsonStr)
}

const createGptFunctionsForJobAnalysis = ({
  priorityLabels,
  locations,
  jobTypes,
  centreLocation, // state and country
  industry,
}) => {
  const functionName = "analyze_job"

  const defaultCountry = "Australia"

  const locationStr = [
    centreLocation.state,
    centreLocation.country || defaultCountry,
  ].join(", ")

  const parameters = {
    type: "object",
    properties: {
      label: {
        type: "string",
        description:
          "The new proposed value for the 'label' (no more than 10 words and should be phrased as a request/action), if the new proposed label is better than the current one. You do not need to mention the Location in the label as this is specified separately.",
      },
      description: {
        type: "string",
        description:
          "The new proposed value for the 'description'. Do not provide a new value if the current value is correct and satisfactory. If information is missing, e.g. measurements, quantities, locations, etc, then mention what necessary information is missing for a repair person to complete the job. Do not invent any information about the repair job. ",
      },
      location: {
        type: "string",
        description:
          "The new proposed value for the 'location'. Do not provide a new value if the current value is correct and satisfactory.",
        enum: locations.filter((l) => l.id).map((l) => l.label),
      },
      category: {
        type: "string",
        description:
          "The new proposed value for the 'category'. Do not provide a new value if the current value is correct and satisfactory.",
        enum: jobTypes.map((jt) => jt.title),
      },
      priority: {
        type: "string",
        description:
          "The new proposed value for the 'priority' which must exactly match one of the allowed values, Consider safety, urgency, and cost when assessing whether the current priority is correct and satisfactory.",
        enum: priorityLabels.map((pl) => pl.label),
      },
      priority_reason: {
        type: "string",
        description:
          "The reason why the new priority is being proposed. If the current priority is correct and satisfactory, then leave this blank. If the current priority is incorrect, then provide a reason why the new priority is being proposed. Consider safety, urgency, and cost when assessing whether the current priority is correct and satisfactory.",
      },
      prevention: {
        type: "string",
        description:
          "A suggestion for how this job could have been prevented, e.g. via a better design, or some other preventive measure to either completely avoid this job, reduce the severity, or incidence of it.",
      },
      risk_assessment: {
        type: "string",
        description:
          "A suggestion for what could go wrong if this job is not done, or not done correctly, or not done on time. Consider safety, legal, financial, and other risks. Explain what the risk category is and severity. List the specific steps that could occur, leading to an incident. e.g. if a step was slippery explain that someone could slip on the step and then fall down and hurt themselves, i.e. make it clear and obvious how the incident could occur.",
      },
      regulations: {
        type: "array",
        items: {
          type: "object",
          properties: {
            name: {
              type: "string",
              //TODO: allow the country of the centre to be passed in so they get appropriate regulations
              description: `The exact name of the regulations that apply in ${locationStr} to this repair job, or just say 'None found - please check' if no ${defaultCountry} regulations apply to this job`,
            },
            authority: {
              type: "string",
              description: `The authority that issued the regulation that applies in ${locationStr}, or 'N/A' if no regulations found that apply in ${locationStr}`,
            },
            description: {
              type: "string",
              description: `A description of what needs to be done to comply with the regulation, or leave blank if no regulations found that apply to ${locationStr}`,
            },
          },
        },
        description: `Specific ${defaultCountry} facility management related regulations ${
          industry ? `for the ${industry} industry` : ""
        } that apply to this job`,
      },
    },
    required: [
      "label",
      "description",
      "location",
      "category",
      "priority",
      "priority_reason",
      "risk_assessment",
      "regulations",
      "prevention",
    ],
  }

  const functions = [
    {
      name: functionName,
      description:
        "Provide a recommendation on how the fields in job can be specified better",
      parameters: parameters,
    },
  ]

  console.log("functions", functions)

  return functions
}

const getJobQualityAnalysisWithFunctions = async ({
  job,
  jobTypes,
  priorities,
  priorityLabels,
  locations,
  industry,
  centre,
}) => {
  const jobEmbeddingInputs = []

  // Get location for centre - state, country, i.e. sufficient info for AI to understand location

  const centreLocation = {
    country: centre.country || "",
    state: centre.state || "",
  }

  const functions = createGptFunctionsForJobAnalysis({
    priorityLabels,
    locations,
    jobTypes,
    centreLocation,
    industry,
  })

  if (job.priority) {
    const priorityValue = priorities.lookup_values.find(
      (item) => item.id === job.priority
    )
    if (priorityValue) {
      jobEmbeddingInputs.push({
        key: "priority",
        value: `${priorityValue.title} (${priorityValue.description})`,
      })
    }
  }

  const jobEmbeddingKeys = [
    "description",
    "label",
    "category",
    "location",
    "status",
  ]

  jobEmbeddingKeys.forEach((key) => {
    if (job[key]) {
      jobEmbeddingInputs.push({ key, value: job[key] })
    }
  })

  // .map((item) => `${item.key}=${item.value}`)
  // .join(", ")

  // console.log("job embedding inputs", jobEmbeddingInputs)
  // const embedding = await createEmbedding(jobEmbeddingInputs)
  // console.log("%cembedding", "color:lightgreen", embedding)

  // if(embedding.data.response.length === 1) {
  //     values.embedding = embedding.data.response[0].embedding
  // }

  const messages = [
    {
      role: "system",
      content: `You're an experienced facilities manager in ${industry}, and an expert in building repairs and ${industry} related regulations. Analyze the provided job details:\n`,
    },
    { role: "user", content: "The job information is:" },
    ...jobEmbeddingInputs.map((item) => ({
      role: "user",
      content: `- ${item.key}: ${item.value}`,
    })),
    {
      role: "user",
      content: `There are ${job.docs.length} photos attached to this job`,
    },
    // {
    //     role: "user",
    //     content: `Available jobs types :${jobTypes
    //         .map((jobType) => `'${jobType.title}'`)
    //         .join(", ")}`,
    // },
    {
      role: "user",
      content: `The only location values that can be used are: ${locations
        .filter((location) => location.id)
        .map((location) => `'${location.label}'`)
        .join(", ")}`,
    },
    {
      role: "system",
      content:
        "The rules are that a job has EXACTLY 1 location, 1 category, 1 priority, and 1 status. Location and Category values must be taken from the provided list exactly.",
    },
    {
      role: "system",
      content:
        "Fix any possible spelling errors in the job information, e.g. they're -> their, bored -> board, etc.",
    },
    {
      role: "system",
      content:
        "Do not invent any facts that are not already provided in the job information.",
    },
    {
      role: "system",
      content:
        "You have no visibility into the photos attached to the job, and so do not comment on the contents of photos.",
    },
    {
      role: "user",
      content: "If no description has been provided then please provide one.",
    },
    {
      role: "user",
      content: `Give a list of 'regulations' that apply to this job based on the ${industry} industry, and in the jurisdiction of state=${centreLocation.state} and country=${centreLocation.country}.`,
    },
    {
      role: "user",
      content:
        "Consider if meaningfully improved values can be given for 'subject', 'description', 'location', 'status', or 'priority'. If the existing values are mostly ok or the same as the new proposal, then exclude any suggestion for that attribute..",
    },
  ]

  console.log("messages", messages)

  const result = await createChatCompletionWithFunctions({
    messages,
    model: GPT_4o_LATEST,
    funcs: functions,
    function_call: { name: functions[0].name },
  })

  console.log("result", result)

  const funcCallResultStr =
    result.data.response.choices[0].message.function_call.arguments

  console.log("funcCallResultStr", funcCallResultStr)

  return JSON.parse(funcCallResultStr)
}

export {
  getChatCompletionResult,
  getJobQualityAnalysisWithFunctions,
  analyseIfSelfRepairable,
  //GPT_3_5_TURBO,
  //GPT_3_5_TURBO_LATEST,
  GPT_4o_LATEST,
  TEXT_EMBEDDING_ADA_002,
}
