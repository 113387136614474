const ADMIN = "admin"
const JOB_ADMIN = "job_admin"
const JOB_USER = "job_user"
const SUPPLIER = "supplier"
const WORK_ORDER_APPROVER = "work_order_approver"
const FINANCE = "finance"
const MAINTENANCE = "maintenance"

const ACCOUNT_TYPE_SUPPLIER = "supplier"
const ACCOUNT_TYPE_CENTRE = "centre"

const userAssignableRoles = {
  [ADMIN]: "Admin",
  [JOB_ADMIN]: "Job Admin",
  [JOB_USER]: "Job User",
  [WORK_ORDER_APPROVER]: "Work Order Approver",
  [FINANCE]: "Finance",
  [MAINTENANCE]: "Maintenance",
}

// 'roles' is from token.claims.roles and is an array of the above role ids
const isSupplierEditable = (roles) => {
  return roles.includes(ADMIN) || roles.includes(JOB_ADMIN)
}

const isCentreEditable = (roles) => {
  return roles.includes(ADMIN)
}

const isAdmin = (roles) => {
  return roles.includes(ADMIN)
}

const isLookupEditable = (roles) => {
  return roles.includes(ADMIN)
}

const isAccountEditable = (roles) => {
  return roles.includes(ADMIN)
}

const getRoleNames = (roles) => {
  return roles.map((role) => userAssignableRoles[role]).sort()
}

export {
  ADMIN,
  JOB_ADMIN,
  JOB_USER,
  WORK_ORDER_APPROVER,
  MAINTENANCE,
  FINANCE,
  SUPPLIER,
  userAssignableRoles,
  isSupplierEditable,
  isAdmin,
  isCentreEditable,
  isLookupEditable,
  isAccountEditable,
  getRoleNames,
  ACCOUNT_TYPE_SUPPLIER,
  ACCOUNT_TYPE_CENTRE,
}
