import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { getAuth, onAuthStateChanged } from "firebase/auth"

function ProtectedRoute(props) {
  const Component = props.component

  const { allowAccess } = props

  const [showComponent, setShowComponent] = useState(true)

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdTokenResult()
          .then((token) => {
            const show =
              allowAccess && token.claims.hasOwnProperty("account_id")

            setShowComponent(show)
          })
          .catch((err) => {
            console.error("error getting token in ProtectedRoute", err)
          })
      }
    })

    return unsub
  }, [])

  return showComponent ? (
    <Component {...props} />
  ) : (
    <Redirect to={{ pathname: "/SignIn" }} />
  )
}

export default ProtectedRoute
