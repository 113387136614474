import { Backdrop, Box, Modal } from "@mui/material"
import React, { useEffect } from "react"
import { styled } from "@mui/material/styles"

const styles = {
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
}))

const ImageModal = ({ selectedFile, setSelectedFile }) => {
    const [open, setOpen] = React.useState(selectedFile !== null)

    const handleOpen = () => {
        setOpen(true)
    }

    useEffect(() => {
        handleOpen()
    }, [selectedFile])

    const handleClose = () => {
        setOpen(false)
        setSelectedFile(null)
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            sx={styles.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <alpha in={open}>
                <StyledBox>
                    <img
                        src={selectedFile}
                        alt="alt"
                        style={{ display: "block", maxWidth: "600", maxHeight: "600" }}
                    />
                </StyledBox>
            </alpha>
        </Modal>
    )
}

export default ImageModal
