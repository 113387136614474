import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material"
import { useMemo, useState } from "react"
import ContactMenu from "./ContactMenu"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import TruncatedText from "./TruncatedText"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import * as icons from "../icons"
import * as colors from "@mui/material/colors"
import LinkButton from "./controls/LinkButton"

const styles = {
  iconField: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
}
const ContactCard = ({
  contact,
  supplier,
  handleEditContact,
  handleRemoveContact,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClickContactMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const supplierIconProps = useMemo(() => {
    console.log("supplier", supplier)
    if (supplier?.supplier_account_id) {
      return {
        color: colors.pink[500],
      }
    }
    return {}
  }, [supplier])

  return (
    <Card sx={{ padding: 1, width: 300 }}>
      {anchorEl && (
        <ContactMenu
          contact={contact}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          menuId={`contact-menu-${contact.id}`}
          handleRemoveContact={handleRemoveContact}
          handleEditContact={() => {
            setAnchorEl(null)
            handleEditContact(contact)
          }}
        />
      )}
      <CardActionArea
        onClick={(e) => {
          setAnchorEl(null)
          handleEditContact(contact)
        }}
      >
        <CardHeader
          title={
            <TruncatedText
              variant="body1"
              noWrap
              sx={{
                fontWeight: "bold",
                maxWidth: "240px",
              }}
            >{`${contact.first_name} ${contact.last_name}`}</TruncatedText>
          }
          subheader={contact.job_title}
          action={
            <IconButton
              component="span"
              size="small"
              aria-label="more"
              aria-controls={`contact-menu-${contact.id}`}
              aria-haspopup="true"
              onClick={(e) => {
                e.stopPropagation()
                handleClickContactMenu(e)
              }}
            >
              <MoreVertIcon />
            </IconButton>
          }
        />
        <CardContent>
          {supplier && (
            <IconField
              icon={
                <icons.SupplierIcon fontSize="small" sx={supplierIconProps} />
              }
              value={
                <LinkButton to={"/SupplierEdit/" + supplier.id}>
                  {supplier.name}
                </LinkButton>
              }
            />
          )}
          <IconField
            icon={<EmailIcon fontSize="small" />}
            value={contact.email}
          />
          <IconField
            icon={<PhoneIcon fontSize="small" />}
            value={contact.phone}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const IconField = ({ value, icon }) => {
  return (
    <Box sx={styles.iconField}>
      <Box sx={{ width: "35px" }}>{icon}</Box>
      <Typography variant="body2">{value}</Typography>
    </Box>
  )
}

export default ContactCard
