import React from "react"
import CentreGrid from "../components/CentreGrid"
import Header from "../components/Header"

function CentreFind() {
    return (
        <Header title="Centres">
            <CentreGrid />
        </Header>
    )
}

export default CentreFind
