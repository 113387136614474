import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import * as dataServices from "../pages/services/dataServices"
import * as contactServices from "../pages/services/contactServices"
import * as UIConstants from "./controls/UIConstants"
import db from "../Firestore"
import { format } from "date-fns"
import {
  Box,
  Typography,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  Chip,
  Autocomplete,
  TextField,
  Switch,
  Tooltip,
  colors,
} from "@mui/material"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectSupplierGridPagination } from "../redux/selectors"
import { setSupplierGridPagination } from "../redux/actions"
import { selectSupplierGridPageDocs } from "../redux/selectors"
import { setSupplierGridPageDocs } from "../redux/actions"
import { initialState as supplierGridPaginationInitialState } from "../redux/reducers/supplierGridPagination"
import Controls from "./controls/Controls"
import _ from "lodash"
import * as icons from "../icons"
import * as supplierServices from "../pages/services/supplierServices"
import { spacing } from "../pages/services/styleServices"
import MultiSelectLabel from "./controls/MultiSelectLabel"
import PageNo from "./PageNo"
import NavButtons from "./NavButtons"
import { COVERAGE, JOB_TYPES } from "../pages/services/colorServices"
import { Desktop, Mobile } from "./WindowSizes"
import SupplierCard from "./SupplierCard"
import * as xlsx from "xlsx"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import {
  getCountFromServer,
  collection,
  query,
  orderBy,
  startAfter,
  startAt,
  limit,
  getDocs,
} from "firebase/firestore"
import LinkButton from "./controls/LinkButton"
import useDocumentPagination from "./useDocumentPagination"
import FabButton from "./controls/FabButton"
import * as Roles from "../pages/services/roleServices"
import FilterItems from "./FilterItems"
import ContactCard from "./ContactCard"
import ContactDialog from "./ContactDialog"

const supplierHeadCells = [
  {
    id: "name_lower",
    numeric: false,
    disablePadding: true,
    label: "Supplier Name",
    sortable: true,
    direction: ["asc"],
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: true,
    label: "Phone",
    sortable: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    sortable: false,
  },
  {
    id: "jobtypes",
    numeric: false,
    disablePadding: true,
    label: "Job Types",
    sortable: false,
  },
  {
    id: "coverage",
    numeric: false,
    disablePadding: true,
    label: "Coverage",
    sortable: false,
  },
  {
    id: "active",
    numeric: false,
    disablePadding: true,
    label: "Active",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: true,
    label: "Created",
    sortable: true,
    direction: ["desc", "asc"],
  },
]

const supplierContactHeadCells = [
  {
    id: "supplier_name",
    numeric: false,
    disablePadding: true,
    label: "Supplier Name",
    sortable: false,
  },
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    sortable: false,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
    sortable: false,
  },
  {
    id: "job_title",
    numeric: false,
    disablePadding: true,
    label: "Job Title",
    sortable: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    sortable: false,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: true,
    label: "Phone",
    sortable: false,
  },
]

const SupplierContactGrid = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {supplierContactHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order : headCell.direction[0]
                }
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const SupplierGrid = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {supplierHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order : headCell.direction[0]
                }
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

SupplierGrid.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const styles = {
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: spacing(2),
  },
  table: {
    minWidth: 750,
  },
  rowChips: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: 0.5,
    marginTop: "2px",
    marginBottom: "2px",
    marginLeft: "2px",
    height: "24px",
  },
  tableRow: {
    "&:hover": {
      cursor: "hand",
    },
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = useState(true)

  const pag = useSelector(selectSupplierGridPagination)

  const pgDocs = useSelector(selectSupplierGridPageDocs)
  
  const {
    addPage,
    clearPageDocs,
    getFirstDocThisPage,
    getLastDocPreviousPage,
  } = useDocumentPagination(pgDocs)

  const dispatch = useDispatch()

  const history = useHistory()

  const [currentUser, setCurrentUser] = useState()

  const [rowsPerPage, setRowsPerPage] = useState(20)

  const [supplierCount, setSupplierCount] = useState()

  const [suppliersForContacts, setSuppliersForContacts] = useState([])

  const [supplierContactCount, setSupplierContactCount] = useState()

  // Autocomplete options
  const [jobTypeOptions, setJobTypeOptions] = useState([])

  // Filters
  const [jobTypesFilter] = useState(pag.job_types)
  const [nameFilter] = useState(pag.name)
  const [activeOnly] = useState(pag.active_only)
  const [supplierName, setSupplierName] = useState(pag.name)
  const [firstName, setFirstName] = useState(pag.first_name)
  const [lastName, setLastName] = useState(pag.last_name)

  // Leave as undefined til actually known, so we don't issue a query and trigger a firestore permission error
  const [userAccountType, setUserAccountType] = useState()

  // We're either doing a supplier search, or a contact search
  const [suppliers, setSuppliers] = useState([])
  const [contacts, setContacts] = useState([])

  const [accountId, setAccountId] = useState()

  const [editContact, setEditContact] = useState()

  const [supplierDocTypes, setSupplierDocTypes] = useState([])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          setCurrentUser(token)
          setAccountId(token.claims.account_id)
          setUserAccountType(token.claims.account_type)
        })
      }

      return unsub
    })
  }, [])

  useEffect(() => {
    if (accountId) {
      supplierServices
        .loadSupplierDocTypes(accountId)
        .then((supplierDocTypes) => {
          setSupplierDocTypes(supplierDocTypes)
        })
    }
  }, [accountId])

  useEffect(() => {
    if (accountId) {
      db.collection("lookups")
        .where("account_id", "==", accountId)
        .where("name", "==", "job_types")
        .get()
        .then((querySnapshot) => {
          const options = querySnapshot.docs.flatMap(
            (doc) => doc.data().lookup_values
          )
          setJobTypeOptions(options)
        })
    }
  }, [accountId])

  const setJobTypesFilterValue = (jobTypes) => {
    // Check if the job type changed
    if (_.isEqual(jobTypes, pag.job_types)) {
      return
    }

    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      job_types: jobTypes,
    }

    setDirection("")
    dispatch(setSupplierGridPagination(updatedPagination))
  }

  const setActiveOnlyFilterValue = (activeOnly) => {
    updateFilterValue({ name: "active_only", value: activeOnly })
  }

  const setSearchPeopleFilterValue = (searchPeople) => {
    updateFilterValue({ name: "search_people", value: searchPeople })
  }

  const setFirstNameFilterValue = (firstName) => {
    updateFilterValue({ name: "first_name", value: firstName })
  }

  const setLastNameFilterValue = (lastName) => {
    updateFilterValue({ name: "last_name", value: lastName })
  }

  const updateFilterValue = ({ name, value }) => {
    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      [name]: value,
    }

    setDirection("")
    dispatch(setSupplierGridPagination(updatedPagination))
  }

  const setNameFilterValue = (name) => {
    // Check if the name type changed
    if (_.isEqual(name, pag.name)) {
      return
    }

    updateFilterValue({ name: "name", value: name })
  }

  const updatePageDocs = ({ querySnapshot, pag, direction }) => {
    if (querySnapshot.docs.length > 0 && direction !== "prev") {
      const newPageDocs = addPage({ querySnapshot, page: pag.page })

      dispatch(setSupplierGridPageDocs(newPageDocs))
    }
  }

  // Load suppliers

  const [reloadState, setReloadState] = useState({})

  useEffect(() => {
    if (currentUser === undefined) {
      return
    }

    if (!userAccountType) {
      return
    }

    if (pag.search_people) {
      return
    }

    const newReloadState = {
      accountId: currentUser.claims.account_id,
      pag,
    }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    setReloadState(newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    const supplierQueryParams = supplierServices.getSupplierQueryParams({
      direction: "next",
      pag,
      currentUser,
    })

    let queryConstraints =
      supplierServices.createSupplierQuery(supplierQueryParams)

    const dataQueryClauses = []

    dataQueryClauses.push(orderBy(pag.orderBy, pag.order))

    if (pag.page > 0 && direction !== "prev") {
      const lastDoc = getLastDocPreviousPage(pag.page)
      if (lastDoc) {
        dataQueryClauses.push(startAfter(lastDoc))
      }
    } else if (direction === "prev") {
      const firstDoc = getFirstDocThisPage(pag.page)
      dataQueryClauses.push(startAt(firstDoc))
    }

    dataQueryClauses.push(limit(rowsPerPage))

    const dataQuery = query(
      collection(db, "suppliers"),
      ...[...queryConstraints, ...dataQueryClauses]
    )

    // Execute dataQuery to get the suppliers

    getDocs(dataQuery)
      .then((querySnapshot) => {
        const suppliers = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })

        console.log("%csuppliers", "color:orange", {
          suppliers,
          queryInfo: dataServices.queryConstraintsToString(queryConstraints),
        })

        setSuppliers(suppliers)
        updatePageDocs({ querySnapshot, pag, direction })

        if (suppliers.length === rowsPerPage || pag.page > 0) {
          getSupplierCount({ queryConstraints })
        } else {
          setSupplierCount(suppliers.length)
        }
      })
      .catch((err) => console.error(`Error loading suppliers`, err))
  }, [pag, currentUser, userAccountType])

  // Load supplier contacts

  const [contactsReloadState, setContactsReloadState] = useState({})

  useEffect(() => {
    if (currentUser === undefined) {
      return
    }

    if (!userAccountType) {
      return
    }

    if (!pag.search_people) {
      return
    }

    const newContactsReloadState = {
      accountId: currentUser.claims.account_id,
      pag,
    }

    const isContactsReloadStateChanged = !_.isEqual(
      contactsReloadState,
      newContactsReloadState
    )

    setContactsReloadState(newContactsReloadState)

    if (!isContactsReloadStateChanged) {
      return
    }

    const queryConstraints = supplierServices.createSupplierContactsQuery({
      accountId: currentUser.claims.account_id,
      firstName: pag.first_name,
      lastName: pag.last_name,
    })

    const dataQueryClauses = []

    dataQueryClauses.push(orderBy("last_name_lower", pag.order))

    if (pag.page > 0 && direction !== "prev") {
      const lastDoc = getLastDocPreviousPage(pag.page)
      if (lastDoc) {
        dataQueryClauses.push(startAfter(lastDoc))
        console.log("%cstartAfter", "color:orange", lastDoc)
      }
    } else if (direction === "prev") {
      const firstDoc = getFirstDocThisPage(pag.page)
      dataQueryClauses.push(startAt(firstDoc))
      console.log("%cstartAt", "color:orange", firstDoc)
    }

    dataQueryClauses.push(limit(rowsPerPage))

    console.log(
      "%cqueryConstraints",
      "color: pink",
      dataServices.queryConstraintsToString(queryConstraints),
      dataQueryClauses
    )

    const dataQuery = query(
      collection(db, "contacts"),
      ...[...queryConstraints, ...dataQueryClauses]
    )

    // Execute dataQuery to get the supplier contacts

    getDocs(dataQuery)
      .then((querySnapshot) => {
        const supplierContacts = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })

        console.log("%csupplierContacts", "color: pink", supplierContacts)

        setContacts(supplierContacts)
        updatePageDocs({ querySnapshot, pag, direction })

        // See what suppliers are missing in the suppliersForContacts cache

        const missingSupplierIds = Array.from(
          new Set(supplierContacts.map((contact) => contact.parent_id))
        )

        dataServices
          .getSuppliersByIdChunks(missingSupplierIds, accountId)
          .then((suppliers) => {
            console.log("%cloaded suppliers", "color:orange", { suppliers })
            setSuppliersForContacts((curr) => [...curr, ...suppliers])
          })

        if (supplierContacts.length === rowsPerPage || pag.page > 0) {
          getSupplierContactCount({ queryConstraints })
        } else {
          setSupplierContactCount(supplierContacts.length)
        }
      })
      .catch((err) => console.error(`Error loading supplier contacts`, err))
  }, [pag, currentUser, userAccountType])

  const handleExport = () => {
    const supplierQueryParams = supplierServices.getSupplierQueryParams({
      direction: "next",
      pag,
      currentUser,
    })

    console.log("%csupplierQueryParams", "color:pink", { supplierQueryParams })

    let queryConstraints =
      supplierServices.createSupplierQuery(supplierQueryParams)

    console.log("%cqueryMods", "color:pink", { queryConstraints })

    const dataQueryClauses = []

    dataQueryClauses.push(orderBy("name", "asc"))

    const dataQuery = query(
      collection(db, "suppliers"),
      ...[...queryConstraints, ...dataQueryClauses]
    )

    getDocs(dataQuery)
      .then((querySnapshot) => {
        const suppliers = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })
        createXlsx(suppliers)
      })
      .catch((err) => console.error(`Error loading suppliers`, err))
  }

  const createXlsx = (suppliers) => {
    const ws = xlsx.utils.json_to_sheet(
      suppliers.map((supplier) => {
        return {
          Name: supplier.name,
          Phone: supplier.phone,
          Email: supplier.email,
          Addr1: supplier.addr1,
          Addr2: supplier.addr2,
          City: supplier.city,
          State: supplier.state,
          Postcode: supplier.postcode,
        }
      })
    )

    // Add worksheet ws to a workbook
    const wb = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(wb, ws, "Suppliers")

    // Download a .xlsx file
    xlsx.writeFile(wb, "suppliers.xlsx")
  }

  const getSupplierCount = ({ queryConstraints }) => {
    const countSuppliers = query(
      collection(db, "suppliers"),
      ...queryConstraints
    )
    getCountFromServer(countSuppliers).then((result) => {
      setSupplierCount(result.data().count)
    })
  }

  const getSupplierContactCount = ({ queryConstraints }) => {
    const countSupplierContacts = query(
      collection(db, "contacts"),
      ...queryConstraints
    )
    console.log("%ccounting supplier contacts", "color:orange", {
      countSupplierContacts,
    })
    getCountFromServer(countSupplierContacts).then((result) => {
      setSupplierContactCount(result.data().count)
    })
  }

  const handleRequestSort = (event, property) => {
    const sortCol = supplierHeadCells.find((cell) => cell.id === property)
    const sortSameCol = pag.orderBy === property

    // If we clicked on the same column to sort it, then find the other sort order for that column (there will only be
    // 1 or 2 array entries, being 'asc' or 'desc' in some order),
    // Otherwise it means we're sorting on a new column so get the 1st sort order from the 'direction' attribute, which
    // could be either 'asc' or 'desc'.
    const sortOrder = sortSameCol
      ? sortCol.direction.find((dir) => dir !== pag.order) || pag.order
      : sortCol.direction[0]

    const updatedPagination = {
      ...supplierGridPaginationInitialState.value,
      order: sortOrder,
      orderBy: property,
    }

    dispatch(setSupplierGridPagination(updatedPagination))

    clearPageDocs()
    dispatch(setSupplierGridPageDocs([]))

    setDirection("")
  }

  const handleNewSupplier = (event) => {
    event.preventDefault()
    history.push("/supplieredit")
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }

      dispatch(setSupplierGridPagination(updatedPagination))
    }
  }

  const refreshContact = (contactId) => {
    db.collection("contacts")
      .doc(contactId)
      .get()
      .then((doc) => {
        const contact = {
          id: doc.id,
          ...doc.data(),
        }

        setContacts((curr) => {
          const newContacts = [...curr]
          const contactIndex = newContacts.findIndex((c) => c.id === contact.id)

          if (contactIndex !== -1) {
            newContacts[contactIndex] = contact
          } else {
            newContacts.push(contact)
          }

          return newContacts
        })
      })
  }

  return (
    <>
      <FilterItems>
        <Box sx={{ minWidth: "150px" }}>
          <Controls.TextInput
            name="supplier_name"
            label="Supplier"
            value={supplierName}
            type="search"
            onChange={(event) => {
              setSupplierName(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === "Tab") {
                event.preventDefault()
                setNameFilterValue(event.target.value)
              }
            }}
          />
        </Box>
        <Box sx={{ minWidth: "150px", mb: "4px" }}>
          <Autocomplete
            multiple
            limitTags={10}
            onChange={(event, newValue) => {
              setJobTypesFilterValue(newValue)
            }}
            options={jobTypeOptions}
            size={UIConstants.STANDARD_INPUT_SIZE}
            value={pag.job_types}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <MultiSelectLabel
                    count={jobTypesFilter?.length || 0}
                    multiSelectedLabel={"Job Types"}
                    oneSelectedLabel={"Job Types"}
                  />
                }
                placeholder="Job Type"
                variant={UIConstants.STANDARD_INPUT_VARIANT}
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                  sx={{ backgroundColor: JOB_TYPES }}
                />
              ))
            }
            variant={UIConstants.STANDARD_INPUT_VARIANT}
          />
        </Box>
        <Box sx={{ minWidth: "150px" }}>
          <Switch
            checked={pag.active_only}
            onChange={(event) => setActiveOnlyFilterValue(event.target.checked)}
          />
          <Typography variant="caption">Active Only</Typography>
        </Box>
        <Box sx={{ minWidth: "150px" }}>
          <Typography variant="caption">Suppliers</Typography>
          <Switch
            checked={pag.search_people}
            onChange={(event) =>
              setSearchPeopleFilterValue(event.target.checked)
            }
          />
          <Typography variant="caption">Contacts</Typography>
        </Box>

        <Controls.TextInput
          name="first_name"
          label="First Name"
          type="search"
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value)
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === "Tab") {
              event.preventDefault()
              setFirstNameFilterValue(event.target.value)
            }
          }}
        />

        <Controls.TextInput
          name="last_name"
          label="Last Name"
          type="search"
          value={lastName}
          onChange={(event) => {
            setLastName(event.target.value)
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === "Tab") {
              event.preventDefault()
              setLastNameFilterValue(event.target.value)
            }
          }}
        />
      </FilterItems>

      {editContact && (
        <ContactDialog
          open={Boolean(editContact)}
          onClose={() => setEditContact(undefined)}
          values={editContact}
          handleSaveContact={(contact) => {
            contactServices.handleSaveContact(contact).then((contactId) => {
              setEditContact(undefined)
              refreshContact(contactId)
            })
          }}
        />
      )}

      <Box>
        <Mobile width={675}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {!pag.search_people &&
              suppliers.map((supplier) => (
                <SupplierCard
                  key={supplier.id}
                  supplier={supplier}
                  supplierDocTypes={supplierDocTypes}
                />
              ))}

            {pag.search_people &&
              contacts.map((contact) => (
                <ContactCard
                  key={contact.id}
                  contact={contact}
                  supplier={suppliersForContacts.find(
                    (s) => s.id === contact.parent_id
                  )}
                  handleEditContact={() => {
                    setEditContact(contact)
                  }}
                />
              ))}
          </Box>
        </Mobile>
      </Box>

      <Box sx={styles.root}>
        <Desktop width={675}>
          <Box sx={{ marginTop: "20px" }}>
            <TableContainer>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="Suppliers"
              >
                {pag.search_people ? (
                  <SupplierContactGrid
                    order={pag.order}
                    orderBy={pag.orderBy}
                    onRequestSort={handleRequestSort}
                  />
                ) : (
                  <SupplierGrid
                    order={pag.order}
                    orderBy={pag.orderBy}
                    onRequestSort={handleRequestSort}
                  />
                )}
                {pag.search_people ? (
                  <SupplierContactTableBody
                    supplierContacts={contacts}
                    suppliersForContacts={suppliersForContacts}
                  />
                ) : (
                  <SupplierTableBody suppliers={suppliers} />
                )}
              </Table>
            </TableContainer>
          </Box>
        </Desktop>
        <Box sx={{ marginTop: "10px" }}>
          <NavButtons>
            {currentUser?.claims.roles.includes(Roles.JOB_ADMIN) &&
              !pag.search_people && (
                <Controls.Button
                  size="small"
                  onClick={handleExport}
                  text="Export"
                />
              )}
            <Controls.Button
              size="small"
              disabled={pag.page === 0}
              onClick={() => handlePageNav(-1)}
              text="Prev"
            />
            <Controls.Button
              size="small"
              disabled={suppliers.length < rowsPerPage}
              onClick={() => handlePageNav(1)}
              text="Next"
            />
          </NavButtons>
        </Box>
        <PageNo
          pageNo={pag.page + 1}
          count={pag.search_people ? supplierContactCount : supplierCount}
          type={pag.search_people ? "contacts" : "suppliers"}
        />
      </Box>

      <FabButton handleClick={handleNewSupplier} label="New Supplier" />
    </>
  )
}

const SupplierTableBody = ({ suppliers }) => {
  const history = useHistory()

  return (
    <TableBody>
      {suppliers.map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`

        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            onClick={(event) => history.push(`/SupplierEdit/${row.id}`)}
            sx={styles.tableRow}
          >
            <TableCell
              component="th"
              id={labelId}
              scope="row"
              padding="none"
              sx={{ width: "300px" }}
            >
              <LinkButton to={"/SupplierEdit/" + row.id}>
                <>
                  {row.name}
                  {row.supplier_account_id && (
                    <Tooltip title="Supplier can login and see work orders allocated to them">
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          marginLeft: "5px",
                          color: colors.pink[500],
                        }}
                      >
                        <icons.SupplierIcon
                          sx={{
                            fontSize: "18px",
                            marginTop: "2px",
                          }}
                        />
                      </Typography>
                    </Tooltip>
                  )}
                </>
              </LinkButton>
            </TableCell>

            <TableCell align="left" padding="none" sx={{ width: "150px" }}>
              <Typography variant="body2">{row.phone}</Typography>
            </TableCell>

            <TableCell align="left" padding="none" sx={{ width: "150px" }}>
              <Typography variant="body2">{row.email}</Typography>
            </TableCell>

            <TableCell align="left" padding="none">
              <Box sx={styles.rowChips}>
                {row.job_types &&
                  row.job_types
                    .sort((a, b) => a.localeCompare(b))
                    .map((jobType) => (
                      <Chip
                        key={jobType}
                        label={
                          <Typography variant="caption">{jobType}</Typography>
                        }
                        size="small"
                        sx={{
                          backgroundColor: JOB_TYPES,
                        }}
                      />
                    ))}
              </Box>
            </TableCell>

            <TableCell align="left" padding="none">
              <Box sx={styles.rowChips}>
                {row.coverage &&
                  row.coverage.map((coverage) => (
                    <Chip
                      key={`${coverage.area},${coverage.state}`}
                      label={
                        <Typography variant="caption">
                          {coverage.area}, {coverage.state}
                        </Typography>
                      }
                      size="small"
                      sx={{
                        backgroundColor: COVERAGE,
                      }}
                    />
                  ))}
              </Box>
            </TableCell>

            <TableCell align="left" padding="none">
              <Typography variant="body2">
                {row.active ? "Yes" : "No"}
              </Typography>
            </TableCell>

            <TableCell align="left" padding="none" sx={{ width: "100px" }}>
              <Typography component={"span"} variant="body2" noWrap={true}>
                {format(row.created.toDate(), "dd MMM yy")}
              </Typography>
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}

const SupplierContactTableBody = ({
  supplierContacts,
  suppliersForContacts,
}) => {
  const history = useHistory()

  return (
    <TableBody>
      {supplierContacts.map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`

        const supplier = suppliersForContacts.find(
          (s) => s.id === row.parent_id
        )
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            onClick={(event) => history.push(`/SupplierEdit/${supplier.id}`)}
            sx={styles.tableRow}
          >
            <TableCell
              component="th"
              id={labelId}
              scope="row"
              padding="none"
              sx={{ py: "5px", width: "300px" }}
            >
              <LinkButton to={"/SupplierEdit/" + supplier?.id}>
                <Typography variant="body2">{supplier?.name}</Typography>
              </LinkButton>
            </TableCell>
            <TableCell align="left" padding="none" sx={{ width: "150px" }}>
              <Typography variant="body2">{row.first_name}</Typography>
            </TableCell>
            <TableCell align="left" padding="none" sx={{ width: "150px" }}>
              <Typography variant="body2">{row.last_name}</Typography>
            </TableCell>
            <TableCell align="left" padding="none" sx={{ width: "150px" }}>
              <Typography variant="body2">{row.job_title}</Typography>
            </TableCell>
            <TableCell align="left" padding="none">
              <Typography variant="body2">{row.email}</Typography>
            </TableCell>
            <TableCell align="left" padding="none" sx={{ width: "150px" }}>
              <Typography variant="body2">{row.phone}</Typography>
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
