import React, { useEffect } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function YesNo(props) {
  const [open, setOpen] = React.useState(false)

  const { config } = props

  useEffect(() => {
    if (config.openPrompt) {
      handleClickOpen()
    }
  }, [config])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleYes = () => {
    setOpen(false)
    config.handleConfirm()
  }

  const handleNo = () => {
    setOpen(false)
  }

  return (
    <div>
      {config && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          // Provide a 'false' response if the user doesn't click either Yes or No
          onClose={handleNo}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {config.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {config.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNo} color="primary">
              No
            </Button>
            <Button onClick={handleYes} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
