import React from "react"
import Grid from "@mui/material/Grid"
import ViewListIcon from "@mui/icons-material/ViewList"
import { withRouter } from "react-router-dom"
import { green } from "@mui/material/colors"
import { Avatar, Card, CardHeader, Divider, Typography } from "@mui/material"
import _ from "lodash"
import { CardContent } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { getName } from "../pages/services/checkListServices"
import ChecklistCheckField from "./CheckListCheckField"
import CheckListSignatureField from "./CheckListSignatureField"
import ChecklistTextField from "./CheckListTextField"
import CheckListDateField from "./CheckListDateField"
import { spacing } from "../pages/services/styleServices"

const styles = {
  checkListSection: {
    marginBottom: spacing(1),
    padding: spacing(0.2),
  },
  sectionAvatar: {
    backgroundColor: green[300],
  },
  cardContent: {
    paddingLeft: spacing(0.25),
    paddingRight: spacing(0.25),
  },
}

function CheckListFillForm({
  width,
  checkList,
  handleUpdateCheckListData,
  handleUpdateCheckListItem,
  handleUpdatePhotos,
  handleUpdateComment,
  handleSaveAndCreateJob,
  values,
  valuesRef,
  checkListInstanceId,
  accountId,
  fileUrls,
  jobs,
}) {
  const handleDateChange = (name, date) => {
    const newCheckListData = {
      ...values.check_list_data,
      [name]: date && date.toDate(),
    }

    handleUpdateCheckListData(newCheckListData)
  }

  const handleChange = (event) => {
    const newCheckListData = {
      ...values.check_list_data,
      [event.target.name]: event.target.value,
    }

    handleUpdateCheckListData(newCheckListData)
  }

  const handleCheckChange = (event) => {
    handleUpdateCheckListItem(event)
  }

  // Each svg signature is an array of arrays, where the child array is a series of points, i.e. a polyline
  const handleSignatureChange = (name, lines) => {
    const newCheckListData = {
      ...values.check_list_data,
      [name]: lines,
    }

    handleUpdateCheckListData(newCheckListData)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <>
        {values && checkList && (
          <>
            {checkList.sections.map((section) => (
              <CheckListSection
                width={width}
                key={`section-${section.seq}`}
                section={section}
                values={values}
                valuesRef={valuesRef}
                checkListInstanceId={checkListInstanceId}
                handleChange={handleChange}
                handleCheckChange={handleCheckChange}
                handleDateChange={handleDateChange}
                handleUpdateComment={handleUpdateComment}
                handleSaveAndCreateJob={handleSaveAndCreateJob}
                handleSignatureChange={handleSignatureChange}
                handleUpdatePhotos={handleUpdatePhotos}
                accountId={accountId}
                fileUrls={fileUrls}
                jobs={jobs}
              />
            ))}
          </>
        )}
      </>
    </LocalizationProvider>
  )
}

const CheckListSection = ({
  width,
  section,
  values,
  valuesRef,
  handleChange,
  handleCheckChange,
  handleDateChange,
  handleUpdateComment,
  handleSaveAndCreateJob,
  handleSignatureChange,
  handleUpdatePhotos,
  checkListInstanceId,
  accountId,
  fileUrls,
  jobs,
}) => {
  const components = {
    text: ChecklistTextField,
    date: CheckListDateField,
    signature: CheckListSignatureField,
    check: ChecklistCheckField,
  }

  const isSectionComplete = (section, values) => {
    const sectionValues = section.elements.map((element) => {
      const name = getName(section, element)

      return {
        name: name,
        value: values.check_list_data[name],
        type: element.type,
      }
    })

    const empty = sectionValues
      .map((val) => {
        let isEmpty = false

        switch (val.type) {
          case "text":
            isEmpty =
              val.value === null || val.value === "" || val.value === undefined
            break

          case "date":
            isEmpty = val.value === null
            break

          case "check":
            isEmpty = val.value === ""
            break

          case "signature":
            isEmpty = val.value.length === 1 && val.value[0].length === 0
            break

          default:
            isEmpty = true
            break
        }

        return isEmpty
      })
      .filter((empty) => empty === true)

    return empty.length === 0
  }

  // Turn the avatar green when the whole section is complete
  const avatarProps = () => {
    if (isSectionComplete(section, values)) {
      return { sx: styles.sectionAvatar }
    }
    return {}
  }

  return (
    <Card sx={{ ...styles.checkListSection, width: `${width - 50}px` }}>
      <CardHeader
        title={
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {section.label}
          </Typography>
        }
        subheader={
          isSectionComplete(section, values)
            ? "Section complete"
            : "Section incomplete"
        }
        avatar={
          <Avatar {...avatarProps()}>
            <ViewListIcon />
          </Avatar>
        }
      />
      <CardContent sx={styles.cardContent}>
        <Grid container direction="column">
          {section &&
            section.elements.map((element) => {
              const Component = components[[element.type]]

              const key = `${section.seq}_${element.seq}`

              const fileNames = values.check_list_data.files[key] || []

              const fieldUrls = fileUrls.filter((entry) => {
                if (fileNames.length > 0) {
                  const isFound = fileNames.includes(entry.fileName)

                  return isFound
                }
                return false
              })

              return (
                <Grid item key={`S-${section.seq}-E-${element.seq}`}>
                  <Component
                    width={width - 40}
                    section={section}
                    element={element}
                    values={values}
                    valuesRef={valuesRef}
                    checkListInstanceId={checkListInstanceId}
                    handleChange={handleChange}
                    handleCheckChange={handleCheckChange}
                    handleDateChange={handleDateChange}
                    handleSignatureChange={handleSignatureChange}
                    handleUpdatePhotos={handleUpdatePhotos}
                    handleUpdateComment={handleUpdateComment}
                    handleSaveAndCreateJob={handleSaveAndCreateJob}
                    accountId={accountId}
                    fileUrls={fileUrls}
                    fieldUrls={fieldUrls}
                    isComplete={values.completed?.seconds !== undefined}
                    jobs={jobs}
                  />

                  {/* Don't put a divider after the last element */}
                  {element.seq < section.elements.length - 1 &&
                    element.type === "check" && (
                      <Divider style={{ marginTop: "10px" }} />
                    )}
                </Grid>
              )
            })}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withRouter(CheckListFillForm)
