import React, { useMemo, useState } from "react"
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material"
import TruncatedText from "./TruncatedText"
import LabelValue from "./LabelValue"
import CentreName from "./CentreName"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import LinkButton from "./controls/LinkButton"
import * as actionServices from "../pages/services/actionServices"
import ActionStatusChip from "./controls/ActionStatusChip"
import ReactTimeAgo from "react-time-ago"
import { uppercaseFirstLetter } from "../pages/services/formatting"

const ActionTile = ({
  action,
  centres,
  isSupplierViewing,
  handleEditAction,
  currentUserEmail,
  isJobAdmin,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleToggleMenu = (event) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const closeMenuAndExecute = (callback) => {
    setAnchorEl(null)
    callback(action)
  }

  const centre = useMemo(() => {
    return centres?.find((c) => c.id === action.centre_id)
  }, [action, centres])

  const allowedStatusChanges = useMemo(() => {
    return actionServices.getAllowedActionStatusValues({
      action,
      userEmail: currentUserEmail,
      isJobAdmin,
      isSupplierViewing,
    })
  }, [action, currentUserEmail, isJobAdmin, isSupplierViewing])

  return (
    <>
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {allowedStatusChanges &&
          allowedStatusChanges.map((status) => (
            <MenuItem
              key={status}
              onClick={() => {
                actionServices.updateActionStatus(action.id, action, status)
                setAnchorEl(null)
              }}
            >
              Mark as {uppercaseFirstLetter(status)}
            </MenuItem>
          ))}
        <MenuItem onClick={() => actionServices.deleteAction(action.id)}>
          Delete Action
        </MenuItem>
        <MenuItem onClick={handleEditAction}>Edit Action</MenuItem>
      </Menu>
      <Card sx={{ width: "300px" }}>
        <CardHeader
          title={
            <TruncatedText
              variant="body1"
              noWrap
              sx={{
                fontWeight: "bold",
                marginBottom: "5px",
                maxWidth: "240px",
              }}
            >
              {action.description}
            </TruncatedText>
          }
          subheader={
            <ActionStatusChip
              status={action.status}
              showBadge={actionServices.isShowActionBadge(
                action,
                currentUserEmail,
                isJobAdmin
              )}
            />
          }
          action={
            <IconButton aria-label="settings" onClick={handleToggleMenu}>
              <MoreVertIcon />
            </IconButton>
          }
        />
        <CardActionArea onClick={() => closeMenuAndExecute(handleEditAction)}>
          <CardContent sx={{ padding: "15px" }}>
            <Stack gap={0.5}>
              <LabelValue
                label="Centre"
                value={
                  <CentreName
                    truncateWidth="100px"
                    centre={centre}
                    isSupplierViewing={isSupplierViewing}
                    textAlign="right"
                  />
                }
              />
              <LabelValue
                label="Job"
                value={
                  <LinkButton
                    to={`${action.parentPath}`}
                    sx={{ justifyContent: "flex-end" }}
                  >
                    {action.parentLabel}
                  </LinkButton>
                }
              />
              <LabelValue
                label="Assigned To"
                value={
                  <TruncatedText
                    variant="body2"
                    sx={{ width: "180px" }}
                    textAlign={"right"}
                  >
                    {action.assigned_to}
                  </TruncatedText>
                }
              />
              <LabelValue
                label="Updated"
                value={
                  action.modified && (
                    <ReactTimeAgo
                      date={action.modified.toDate()}
                      locale="en-AU"
                    />
                  )
                }
              />
              <LabelValue
                label="Created By"
                value={
                  <TruncatedText
                    variant="body2"
                    sx={{ width: "180px" }}
                    textAlign={"right"}
                  >
                    {action.created_by}
                  </TruncatedText>
                }
              />
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

export default ActionTile
