import React, { useState, useEffect, useRef } from "react"
import { Alert, Stack } from "@mui/material"
import Controls from "./controls/Controls"
import { Form } from "./useForm"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import { useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import firebase from "firebase/compat/app"
import YesNo from "./YesNo"
import _ from "lodash"
import { Box } from "@mui/material"
import * as icons from "../icons"
import CheckListFillForm from "./CheckListFillForm"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {
  convertCheckListDataFromFirestore,
  getFileUrls,
} from "../pages/services/checkListServices"
import { spacing } from "../pages/services/styleServices"
import NavButtons from "./NavButtons"
import CheckListRating from "./CheckListRating"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const styles = {
  score: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *": {
      marginRight: spacing(1),
    },
  },
}

const CheckListInstanceEditForm = (props) => {
  const initialValues = () => {
    return {
      check_list_id: "",
      check_list_name: "", // redundant, but denormalized attribute for showing name on the check list instance grid
      check_list_data: {},
      created: dataServices.localTimestamp(),
      modified: dataServices.localTimestamp(),
      score: 0,
    }
  }

  const history = useHistory()

  const COLLECTION = "checklist_instances"

  const { width } = useWindowDimensions()

  const { setTitle } = props

  const [values, setValues] = useState(initialValues())

  const valuesRef = useRef({})
  valuesRef.current = values

  const [checkListInstanceId, setCheckListInstanceId] = useState(props.id)

  const isNew = () => {
    return checkListInstanceId === undefined || checkListInstanceId === null
  }

  // The list of available checklists to select from
  //const [checkLists, setCheckLists] = useState([])

  const { enqueueSnackbar } = useSnackbar()

  const [isCheckListOpen, setCheckListOpen] = useState(false)

  // Associated check list
  const [checkList, setCheckList] = useState(null)

  const [claims, setClaims] = useState()

  const [fileUrls, setFileUrls] = useState([])

  const [accountId, setAccountId] = useState()

  const [jobs, setJobs] = useState()

  const [user, setUser] = useState()

  const [centre, setCentre] = useState()

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult().then((token) => {
        setAccountId(token.claims.account_id)
        setClaims(token.claims)

        dataServices
          .getUsersById(token.claims.account_id, [user.uid])
          .then((users) => {
            if (users.length === 1) {
              setUser(users[0])
            }
          })

        let newValues = { ...values }

        // Default the user's centre if they belong to exactly 1 centre
        if (token.claims.centre_ids.length === 1) {
          if (values.centre_id === "") {
            newValues = {
              ...newValues,
              centre_id: token.claims.centre_ids[0],
            }
          }
        }

        if (isNew) {
          newValues = {
            ...newValues,
            account_id: token.claims.account_id,
          }
        }

        setValues(newValues)
      })
    })

    return unsub
  }, [])

  // Load jobs for checklist

  const loadJobs = async (checkListId) => {
    db.collection("jobs")
      .where("account_id", "==", accountId)
      .where("parent_id", "==", checkListId)
      .where("parent_type", "==", "checklist")
      .get()
      .then((querySnapshot) => {
        const jobs = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })

        const centreIds = _.uniq(jobs.map((job) => job.centre_id))
        dataServices.getCentresByIdChunks(centreIds).then((centres) => {
          const jobsWithCentres = jobs.map((job) => {
            const centre = centres.find((centre) => centre.id === job.centre_id)
            return { ...job, centre }
          })

          setJobs(jobsWithCentres)
        })
      })
  }

  // Load checklist

  const [loadState, setLoadState] = useState({ id: "", accountId: "" })

  useEffect(() => {
    if (!accountId || !checkListInstanceId) {
      return
    }

    const newLoadState = { checkListInstanceId, accountId }

    const isLoadStateChanged = !_.isEqual(newLoadState, loadState)

    if (!isLoadStateChanged) {
      return
    }

    setLoadState(newLoadState)

    if (checkListInstanceId) {
      loadCheckListInstance(checkListInstanceId)
      loadJobs(checkListInstanceId)
    }
  }, [checkListInstanceId, accountId])

  useEffect(() => {
    if (accountId && values.check_list_id) {
      db.collection("checklists")
        .where("account_id", "==", accountId)
        .where(
          firebase.firestore.FieldPath.documentId(),
          "==",
          values.check_list_id
        )
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size === 0) {
            return
          }

          const checkListRef = querySnapshot.docs[0]

          const result = { ...checkListRef.data(), id: checkListRef.id }

          const newValues = {
            ...values,
            check_list_name: result.name,
          }

          setValues(newValues)

          setTitle(result.name)
        })
    }
  }, [values.check_list_id, accountId])

  const loadCheckList = async (checkListId) => {
    const checkListRef = await db
      .collection("checklists")
      .doc(checkListId)
      .get()

    const result = { ...checkListRef.data(), id: checkListRef.id }

    return result
  }

  const loadCheckListInstance = async (checkListInstanceId) => {
    if (checkListInstanceId) {
      getAuth()
        .currentUser.getIdTokenResult()
        .then(async (token) => {
          setCheckListInstanceId(checkListInstanceId)

          const snapshot = await db
            .collection(COLLECTION)
            .doc(checkListInstanceId)
            .get()

          const data = {
            ...initialValues(),
            ...snapshot.data(),
          }

          if (data.centre_id) {
            dataServices
              .getCentreById(data.centre_id)
              .then((centreRec) => setCentre(centreRec))
          } else {
            setCentre({ name: "No centre selected" })
          }

          const checkListRec = await loadCheckList(data.check_list_id)

          const convertedCheckListData = convertCheckListDataFromFirestore(
            checkListRec,
            data.check_list_data
          )

          const newValues = {
            ...data,
            check_list_data: convertedCheckListData,
          }

          setValues(newValues)
          setCheckList(checkListRec)

          const fileNames = _.uniq(
            _.flatten(Object.values(newValues.check_list_data.files))
          )
          const fileUrls = await getFileUrls(
            token.claims.account_id,
            checkListInstanceId,
            fileNames
          )
          setFileUrls(fileUrls)
        })
    }
  }

  useEffect(() => {
    if (values.check_list_id === "") {
      return
    }

    const files = _.flatten(Object.values(values.check_list_data.files))
    // Work out if any file urls are missing, since we may have just added or removed a file.

    const filesWithUrls = fileUrls.map((item) => item.fileName)

    const addedFiles = _.difference(files, filesWithUrls)
    const removedFiles = _.difference(filesWithUrls, files)

    if (addedFiles.length > 0) {
      getFileUrls(accountId, checkListInstanceId, addedFiles).then(
        (addedUrls) => {
          if (addedUrls.length !== addedFiles.length) {
            console.log(
              `%cERROR: expecting ${addedFiles.length} URLs`,
              "color:red"
            )
          } else {
            console.log(
              "%cSUCCESS: received correct no. of URLs",
              "color:lightGreen",
              addedFiles.length
            )
          }

          const mergedUrls = [...fileUrls]
          addedUrls.forEach((entry) => {
            // Don't add the URL twice if the same picture is added to 2 or more checklist questions
            if (
              mergedUrls.findIndex(
                (item) => item.fileName === entry.fileName
              ) === -1
            ) {
              mergedUrls.push(entry)
            }
          })

          setFileUrls(mergedUrls)
        }
      )
    } else if (removedFiles.length > 0) {
      const newUrls = fileUrls.filter(
        (item) => !removedFiles.includes(item.fileName)
      )
      setFileUrls(newUrls)
    }
  }, [values])

  // Since firestore can't handle arrays of arrays we need to convert this into
  // an object, e.g. { 0: [], 1: [], etc... }
  const convertNestedArraysToObject = (lines) => {
    const linesObj = {}
    lines
      .filter((line) => line.length > 0)
      .forEach((line, index) => {
        linesObj[index] = line.map((point) => `${point.x},${point.y}`).join("|")
      })
    return linesObj
  }

  const handleUpdatePhotos = (newFiles) => {
    const newCheckListData = {
      ...values.check_list_data,
      files: newFiles,
    }

    handleUpdateCheckListData(newCheckListData)
  }

  const handleUpdateComment = ({ key, value }) => {
    // Add a comment, keyed on 'key' into check_list_data.comments

    const newCheckListData = _.cloneDeep(valuesRef.current.check_list_data)

    if (!newCheckListData.comments) {
      newCheckListData.comments = {}
    }

    newCheckListData.comments[key] = value

    const newValues = {
      ...values,
      check_list_data: newCheckListData,
    }

    setValues(newValues)
  }

  const handleUpdateCheckListItem = (event) => {
    const newCheckListData = _.cloneDeep(valuesRef.current.check_list_data)

    newCheckListData[event.target.name] = event.target.value

    const newScore = getScore({ check_list_data: newCheckListData })

    const newValues = {
      ...values,
      check_list_data: newCheckListData,
      score: newScore,
    }

    setValues(newValues)
  }

  const handleUpdateCheckListData = (newCheckListData) => {
    const newScore = getScore({ check_list_data: newCheckListData })

    const newValues = {
      ...valuesRef.current,
      check_list_data: newCheckListData,
      score: newScore,
    }

    setValues(newValues)
  }

  const handleDeleteCheckListInstanceConfirmed = async (
    checkListInstanceIdToDelete
  ) => {
    // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted
    const deleteConfig = {
      ...yesNoDeleteCheckListInstanceConfig,
      openPrompt: false,
    }
    setYesNoDeleteCheckListInstanceConfig(deleteConfig)

    getAuth()
      .currentUser.getIdTokenResult()
      .then(async (token) => {
        await db
          .collection(COLLECTION)
          .doc(checkListInstanceIdToDelete)
          .delete()
      })
      .then(history.goBack())
      .then(enqueueSnackbar("Deleted", { variant: "success" }))
      .catch((err) => console.error("Problem deleting checklist instance", err))
  }

  const [
    yesNoDeleteCheckListInstanceConfig,
    setYesNoDeleteCheckListInstanceConfig,
  ] = useState({
    openPrompt: false,
    description: "This delete is permanent",
    title: "Delete",
    handleConfirm: null,
  })

  const [yesNoCompleteCheckListConfig, setYesNoCompleteCheckListConfig] =
    useState({
      openPrompt: false,
      description: "Checklist will be locked",
      title: "Mark Complete?",
      handleConfirm: null,
    })

  const handlePromptCompleteCheckList = () => {
    const complete = checkList.sections.map((section) =>
      isSectionMandatoryQuestionsComplete(section, values.check_list_data)
    )

    if (complete.includes(false)) {
      enqueueSnackbar("Please complete all mandatory questions", {
        variant: "warning",
      })
      return
    }

    const newConfig = {
      ...yesNoCompleteCheckListConfig,
      openPrompt: true,
      handleConfirm: () => handleCompleteCheckList(),
    }

    setYesNoCompleteCheckListConfig(newConfig)
  }

  const handlePromptConfirmDeleteCheckListInstance = (event) => {
    const newConfig = {
      ...yesNoDeleteCheckListInstanceConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteCheckListInstanceConfirmed(props.id),
    }
    setYesNoDeleteCheckListInstanceConfig(newConfig)
  }

  const navigateAfterSubmit = () => {
    if (history.length > 0) {
      history.goBack()
    } else {
      history.push("/checklistinstances")
    }
  }

  // Convert check list data from where signatures are held as nested arrays
  // into an object with keys, since Firestore can't store nested arrays

  const convertCheckListDataToFirestore = (values) => {
    const signatureElementNames = _.flatten(
      checkList.sections.map((section) =>
        section.elements
          .map((element) => {
            return { section, element }
          })
          .filter((pair) => pair.element.type === "signature")
          .map((pair) => `${pair.section.seq}_${pair.element.seq}`)
      )
    )

    // We need to prepare signature data for storing in firestore
    // since firestore can't handle arrays of arrays
    let checkListDataWithOptimisedSignatures = {
      ...values.check_list_data,
    }

    signatureElementNames.forEach((name) => {
      checkListDataWithOptimisedSignatures[name] = convertNestedArraysToObject(
        values.check_list_data[name]
      )
    })

    const newValues = {
      ...values,
      check_list_data: checkListDataWithOptimisedSignatures,
    }

    return newValues
  }

  const getName = (section, element) => {
    return `${section.seq}_${element.seq}`
  }

  const isSectionMandatoryQuestionsComplete = (section, checkListData) => {
    const sectionValues = section.elements
      .filter((element) => element.mandatory)
      .map((element) => {
        const name = getName(section, element)
        return { name: name, value: checkListData[name], type: element.type }
      })

    const empty = sectionValues
      .map((val) => {
        let isEmpty = false

        switch (val.type) {
          case "text":
            isEmpty =
              val.value === null || val.value === "" || val.value === undefined
            break

          case "date":
            isEmpty = val.value === null
            break

          case "check":
            isEmpty = val.value === ""
            break

          case "signature":
            isEmpty = val.value.length === 1 && val.value[0].length === 0
            break

          default:
            isEmpty = true
            break
        }

        return isEmpty
      })
      .filter((empty) => empty === true)

    return empty.length === 0
  }

  const getScore = (checkListValues) => {
    // Perform scoring

    const elements = _.flatten(
      checkList.sections.map((section) =>
        section.elements
          .filter((element) => element.type === "check")
          .map((element) => {
            return { ...element, key: `${section.seq}_${element.seq}` }
          })
      )
    )

    const maxScore = _.sum(
      _.flatten(elements.map((element) => parseInt(element.score_value, 10)))
    )

    // Avoid divide by 0
    if (maxScore === 0) {
      return 0
    }

    // Check answers that were answered 'Y', not 'N', or 'N/A'
    const scoreableAnswers = Object.keys(checkListValues.check_list_data)
      .map((key) => {
        return { key, value: checkListValues.check_list_data[key] }
      })
      .filter(
        (answer) =>
          elements.findIndex(
            (element) => element.key === answer.key && answer.value === "Y"
          ) !== -1
      )

    const actualScore = _.sum(
      scoreableAnswers.map((answer) =>
        parseInt(
          elements.find((element) => element.key === answer.key).score_value,
          10
        )
      )
    )

    const percentageScore = parseFloat((actualScore / maxScore).toFixed(2))

    return percentageScore
  }

  const handleCompleteCheckList = () => {
    const newScore = getScore(values)

    const newValues = {
      ...values,
      score: newScore,
      completed: dataServices.localTimestamp(),
    }

    setValues(newValues)

    handleSaveCheckList(newValues)
  }

  const handleSaveAndCreateJob = async ({ stateInfo }) => {
    handleSaveCheckList(values).then((saveResult) => {
      history.push({
        pathname: `/JobEdit`,
        state: stateInfo,
      })
    })
  }

  const handleSaveCheckList = async (checkListValues) => {
    if (checkListValues.label === "") {
      enqueueSnackbar("Enter checklist instance details", { variant: "info" })
    } else if (
      checkListValues.centre_id === "" ||
      checkListValues.centre_id === null
    ) {
      enqueueSnackbar("Select a centre", { variant: "info" })
    } else {
      const convertedValues = convertCheckListDataToFirestore(checkListValues)

      const newScore = getScore(checkListValues)

      if (isNew()) {
        const newRecord = {
          ...convertedValues,
          score: newScore,
          account_id: claims.account_id,
          created: dataServices.serverTimestamp(),
          modified: dataServices.serverTimestamp(),
        }

        const docRef = await db.collection(COLLECTION).add(newRecord)

        setValues((curr) => ({
          ...curr,
          score: newScore,
        }))

        setCheckListInstanceId(docRef.id)

        enqueueSnackbar("Created", { variant: "success" })

        return true
      } else {
        const updateRecord = {
          ...convertedValues,
          score: newScore,
          modified: dataServices.serverTimestamp(),
        }

        await db
          .collection(COLLECTION)
          .doc(checkListInstanceId)
          .update(updateRecord)
        enqueueSnackbar("Saved", { variant: "success" })

        setValues((curr) => ({
          ...curr,
          score: newScore,
        }))

        return true
      }
    }
  }

  const handlePrint = () => {
    history.push(`/CheckListPrint/${checkListInstanceId}`)
  }

  // Save checklist instance

  const handleSubmit = async (event) => {
    event.preventDefault()

    handleSaveCheckList(values)
  }

  return (
    <>
      <YesNo config={yesNoDeleteCheckListInstanceConfig} />

      <YesNo config={yesNoCompleteCheckListConfig} />

      <Form>
        <Box
          display="flex"
          style={{
            verticalAlign: "middle",
          }}
        >
          <Box display="flex" p={1} flexShrink={0}>
            {values && values.created && (
              <Controls.CreatedLabel title="Created" value={values.created} />
            )}
          </Box>
        </Box>

        <Stack
          direction="column"
          gap={2}
          sx={{ mt: "20px", mb: "20px", maxWidth: `${width - 20}px` }}
        >
          {checkList && (
            <Controls.Readonly
              name="checklist"
              value={checkList.name}
              label="Checklist"
              helperText={`Uses version ${checkList.version || 1}`}
            />
          )}

          {user && (
            <Controls.Readonly name="user" value={user.name} label="User" />
          )}

          {centre && (
            <Controls.Readonly
              name="centre"
              value={centre.name}
              label="Centre"
            />
          )}

          <Box sx={styles.score}>
            <CheckListRating values={values} />
          </Box>
        </Stack>
      </Form>

      {jobs && jobs.length > 0 && (
        <Alert severity="info" sx={{ width: `${width - 50}px`, mb: "10px" }}>
          {jobs.length} job{jobs.length > 1 && "s"} created for this checklist.
          See below.
        </Alert>
      )}

      {checkList && values && (
        <CheckListFillForm
          width={width}
          open={isCheckListOpen}
          setOpen={setCheckListOpen}
          checkList={checkList}
          handleUpdateCheckListData={handleUpdateCheckListData}
          handleUpdateCheckListItem={handleUpdateCheckListItem}
          handleUpdatePhotos={handleUpdatePhotos}
          handleUpdateComment={handleUpdateComment}
          handleSaveAndCreateJob={handleSaveAndCreateJob}
          values={values}
          valuesRef={valuesRef}
          checkListInstanceId={checkListInstanceId}
          accountId={accountId}
          fileUrls={fileUrls}
          jobs={jobs}
        />
      )}

      <Box sx={{ marginTop: "15px" }}>
        <NavButtons>
          {!isNew() && !values.completed && (
            <Controls.Button
              text="Delete"
              type="button"
              onClick={handlePromptConfirmDeleteCheckListInstance}
              endIcon={<icons.DeleteIcon />}
            />
          )}

          {!values.completed && (
            <Controls.Button
              type="button"
              text="Complete"
              onClick={handlePromptCompleteCheckList}
              endIcon={<CheckCircleIcon />}
            />
          )}

          <Controls.Button
            type="button"
            text="Print"
            onClick={handlePrint}
            endIcon={<icons.PrintIcon />}
          />

          {!values.completed && (
            <Controls.Button
              type="submit"
              text="Save"
              onClick={(event) => handleSubmit(event)}
              endIcon={<icons.SaveIcon />}
            />
          )}
        </NavButtons>
      </Box>
    </>
  )
}

export default withRouter(CheckListInstanceEditForm)
