import { useState } from "react"

const useDocumentPagination = (initialValue) => {
  const [pageDocs, setPageDocs] = useState(initialValue)

  const addPage = ({ querySnapshot, page }) => {
    // Get first and last doc from query snapshot and set it as the page index in pageDocs

    const firstDoc = querySnapshot.docs[0]
    const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]

    const newPageDocs = [...pageDocs]
    newPageDocs[page] = { firstDoc, lastDoc }

    setPageDocs(newPageDocs)

    return newPageDocs
  }

  const clearPageDocs = () => {
    setPageDocs([])
  }

  const getFirstDocThisPage = (page) => {
    return pageDocs[page]?.firstDoc
  }

  const getLastDocPreviousPage = (page) => {
    // If page is 0, there is no previous page
    if (page === 0) return null

    // If page is not 0, return the last doc of the previous page
    return pageDocs[page - 1]?.lastDoc
  }

  return { addPage, clearPageDocs, getFirstDocThisPage, getLastDocPreviousPage }
}

export default useDocumentPagination
