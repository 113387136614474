import React, { useState, useEffect } from "react"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import db from "../Firestore"
import * as Icons from "../icons"
import * as dataServices from "../pages/services/dataServices"
import { useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import * as Roles from "../pages/services/roleServices"
import { Alert, Box, Stack } from "@mui/material"
import _ from "lodash"
import * as icons from "../icons"
import NavButtons from "./NavButtons"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const initialValues = () => {
  return {
    name: "",
    email: "",
    phone: "",
    industry: "",
    // default value
    job_self_repair_prompt:
      "Indication if the job could be fixed in a reasonably short amount of time by an employee at the facility without having to measure, cut, drill, paint, or do any tradeperson-like activities, and without a lot of preparation work.",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
    stripe_cust_id: "",
  }
}

const AccountEditForm = (props) => {
  const { setTitle } = props

  const [addMultiple, setAddMultiple] = React.useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const [accountId, setAccountId] = useState()

  const isNew = () => props.id === undefined && accountId === ""

  const history = useHistory()

  const [isEditable, setEditable] = useState(false)

  const { values, setValues, handleInputChange } = useForm(initialValues())

  // Load account

  const [loadState, setLoadState] = useState({})

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          if (token.claims.system_role) {
            if (props && props.id !== null && props.id !== undefined) {
              setAccountId(props.id)
              setEditable(true)
            }
          } else {
            // If user does not have system_role then
            // they can only open the account in their claims
            setAccountId(token.claims.account_id)
            setEditable(Roles.isAccountEditable(token.claims.roles))
          }
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    if (accountId === undefined) {
      return
    }

    const newLoadState = { accountId }

    const isLoadStateChanged = !_.isEqual(loadState, newLoadState)

    setLoadState(newLoadState)

    if (isLoadStateChanged) {
      db.collection("accounts")
        .doc(accountId)
        .get()
        .then((snapshot) => {
          setValues({
            ...initialValues(),
            ...snapshot.data(),
          })
        })
    }
  }, [accountId])

  useEffect(() => {
    setTitle(values.name)
  }, [values.name, setTitle])

  const handleSubmit = (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter name", { variant: "error" })
    } else {
      if (isNew()) {
        dataServices
          .getCurrentUser()
          .then((user) => {
            const newRecord = {
              ...values,
              created: dataServices.serverTimestamp(),
              modified: dataServices.serverTimestamp(),
            }

            db.collection("accounts")
              .add(newRecord)
              .then((docRef) => setAccountId(docRef.id))
              .then(enqueueSnackbar("Created", { variant: "success" }))
              .then(() => {
                if (addMultiple) {
                  setValues(initialValues())
                  setAccountId("")
                } else {
                  history.goBack()
                }
              })
          })
          .catch(function (error) {
            console.error("Error:" + error)
            enqueueSnackbar("Error", { variant: "error " })
          })
      } else {
        const updateRecord = {
          ...values,
          modified: dataServices.serverTimestamp(),
        }

        db.collection("accounts")
          .doc(accountId)
          .update(updateRecord)
          .then(enqueueSnackbar("Updated", { variant: "success" }))
          .then(history.goBack())
      }
    }
  }

  return (
    <>
      {isEditable || (
        <Alert severity="info">
          Organisation details are read only. Requires Admin role to edit
        </Alert>
      )}

      <Box sx={{ mt: "20px", maxWidth: "500px" }}>
        <Form>
          <Stack gap={2}>
            <Controls.TextInput
              name="name"
              label="Name"
              value={values.name}
              disabled={!isEditable}
              onChange={handleInputChange}
            />

            <Box sx={{ maxWidth: "250px" }}>
              <Controls.TextInput
                name="phone"
                label="Phone"
                value={values.phone}
                disabled={!isEditable}
                icon={<Icons.PhoneIcon />}
                onChange={handleInputChange}
              />
            </Box>

            <Controls.TextInput
              name="email"
              label="Email"
              value={values.email}
              disabled={!isEditable}
              icon={<Icons.EmailIcon />}
              onChange={handleInputChange}
            />

            <Controls.TextInput
              name="industry"
              label="Industry"
              value={values.industry}
              disabled={!isEditable}
              helperText={
                'e.g. "Retail", "Manufacturing", "Child Care" for use in jobs when using AI Assist'
              }
              onChange={handleInputChange}
            />

            <Controls.TextInput
              name="job_self_repair_prompt"
              label="Criteria for assessing if job can be perfored by staff"
              value={values.job_self_repair_prompt}
              disabled={!isEditable}
              multiline
              helperText={
                "OpenAI prompt criteria to determine a yes or no answer to the question 'Can this job be performed by staff?'."
              }
              onChange={handleInputChange}
            />

            <Box sx={{ maxWidth: "250px" }}>
              <Controls.CreatedDate
                name="created"
                label="Created"
                value={values.created}
              />
            </Box>
          </Stack>

          <NavButtons>
            {isEditable && (
              <Controls.Button
                type="submit"
                text="Save"
                onClick={handleSubmit}
                endIcon={<icons.SaveIcon />}
              />
            )}
          </NavButtons>

          <Controls.AddMultiple
            {...props}
            addMultiple={addMultiple}
            setAddMultiple={setAddMultiple}
          />
        </Form>
      </Box>
    </>
  )
}

export default withRouter(AccountEditForm)
