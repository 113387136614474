import React, { useEffect, useState, useMemo } from "react"
import {
  getEmptyStripeAccountInfo,
  getStripeCustomer,
} from "../pages/services/stripeServices"
import ProgressBackdrop from "./ProgressBackdrop"
import StripeInvoice from "./StripeInvoice"
import StripeCard from "./StripeCard"
import StripeSubscription from "./StripeSubscription"
import StripeCustomer from "./StripeCustomer"
import * as dataServices from "../pages/services/dataServices"
import { useSnackbar } from "notistack"
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material"
import StripeActions from "./StripeActions"
import Controls from "./controls/Controls"
import { Grid, Alert } from "@mui/material"
import { Form } from "./useForm"
import db from "../Firestore"
import firebase from "firebase/compat/app"
import StripeAddCard from "./StripeAddCard"
import * as stripeServices from "../pages/services/stripeServices"
import { Elements } from "@stripe/react-stripe-js"
import TabPanel, { a11yProps } from "./TabPanel"
import _ from "lodash"
import { formatAmount } from "../pages/services/formatting"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import { spacing } from "../pages/services/styleServices"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const styles = {
  headerFields: {
    margin: spacing(1),
  },
  pageContent: {
    marginTop: spacing(1),
    marginLeft: spacing(1),
    marginRight: spacing(2),
    padding: spacing(1),
    maxWidth: "320px",
  },
  accountTab: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "& > *": {
      minWidth: 330,
    },
  },
  tabLabel: {
    textTransform: "none",
  },
  productName: {
    fontWeight: 700,
    marginBottom: spacing(1),
  },
}

const BillingForm = (props) => {
  const { setTitle, accountId } = props

  const [isShowProgress, setShowProgress] = useState(true)

  const [stripeAccountInfo, setStripeAccountInfo] = useState(
    getEmptyStripeAccountInfo()
  )

  const [retrievedBilling, setRetrievedBilling] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const [maxModified, setMaxModified] = useState()

  const [tabIndex, setTabIndex] = useState(0)

  const isSubscriptionCreated = useMemo(() => {
    return stripeAccountInfo?.customer?.subscriptions?.length > 0
  }, [stripeAccountInfo])

  const isCardDefined = useMemo(() => {
    return stripeAccountInfo?.cards?.length > 0
  }, [stripeAccountInfo])

  const isUpcomingInvoiceForTrial = useMemo(() => {
    if (!stripeAccountInfo) {
      return false
    }

    if (!stripeAccountInfo.invoice) {
      return false
    }

    const { invoice } = stripeAccountInfo

    const found = stripeAccountInfo.customer.subscriptions.find(
      (sub) =>
        sub.current_period_start === invoice.period_start &&
        sub.current_period_end === invoice.period_end
    )

    console.log(
      "found trial subscription with same period as upcoming invoice",
      { found }
    )

    return found !== undefined
  }, [stripeAccountInfo])

  const stripePromise = stripeServices.getStripePromise()

  const [stripeProducts, setStripeProducts] = useState()

  const [stripePrices, setStripePrices] = useState()

  // This gets populated securely from the Google JWT, and also protected access server-side
  const [isSystemRole, setSystemRole] = useState(undefined)

  const [values, setValues] = useState({
    name: "",
    email: "",
    stripe_cust_id: "",
    has_subscription: false,
    stripe_status: "",
  })

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult(true).then((token) => {
          if (isSystemRole === undefined) {
            setSystemRole(token.claims.system_role)
          }
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    stripeServices.listStripeProducts().then((result) => {
      console.log("%cStripe products", "color:lightgreen", result)

      if (cloudFunctions.isError(result)) {
        enqueueSnackbar(cloudFunctions.getMessage(result), { variant: "error" })
        setShowProgress(false)
      } else {
        const products = result.data.filter(
          (product) => product.name === stripeServices.JOBS_MODULE
        )

        const latestProduct = products.sort((a, b) => b.created - a.created)[0]

        console.log("%cStripe products:Jobs module", "color:lightgreen", {
          products,
          latestProduct,
        })
        setStripeProducts([latestProduct])
        loadPrices([latestProduct])
      }
    })
  }, [])

  const loadPrices = async (products) => {
    const prices = products.map(async (product) => {
      console.log("%cget price for product", "color: lightgreen", {
        product: product.id,
      })

      const result = await stripeServices.getStripeProductPrices(product.id)

      if (cloudFunctions.isError(result)) {
        enqueueSnackbar(cloudFunctions.getMessage(result), { variant: "error" })
      }
      return result
    })
    Promise.all(prices).then(async (allPrices) => {
      console.log("%cRetrieved prices", "color:lightgreen", { allPrices })

      const pricesDetailPromises = allPrices.map(async (price, index) => {
        //     stripeServices.getStripePrice(price.data.data.id).then((result) => {
        const priceId = price.data[0].id
        console.log(`%cRetrieved price (${index})`, "color:lightgreen", {
          id: priceId,
        })
        const priceDetails = await stripeServices.getStripePrice(priceId)
        console.log("%cRetrieved price details", "color:lightgreen", {
          priceDetails,
        })
        return priceDetails
      })
      const priceDetails = await Promise.all(pricesDetailPromises)
      console.log("allPriceDetails", priceDetails)
      setStripePrices(_.flatten(priceDetails.map((item) => item.data)))
    })
  }

  const handleCreateSubscription = async (productName, accountId) => {
    // Check if there is a corresponding stripe customer

    setShowProgress(true)

    const stripeCustResult = await getStripeCustomer(accountId)

    if (!stripeCustResult.customer) {
      console.log("No Stripe Customer")
      // Create stripe customer

      const createResult = await stripeServices.createStripeCustomer(accountId)
      console.log("created stripe customer", createResult)
    }

    console.log("%ccreate subscription", "color: lightblue", {
      productName,
      accountId,
    })

    const result = await stripeServices.createStripeSubscription(
      productName,
      accountId
    )

    console.log("%ccreate subscription result", "color: lightblue", result)

    enqueueSnackbar(result.data.status.message, {
      variant: result.data.status.type,
    })

    setShowProgress(false)
  }

  const loadAccountData = async (accountId) => {
    let newValues = {
      ...values,
      has_subscription: undefined,

      // Do account and Stripe customer emails match?
      emails_match: undefined,
    }

    // Clear out the stripe_cust_id. If one exists, it will get re-loaded
    // in the following lines. Clearing the stripe_cust_id attribute
    // is needed when we use the 'Repair' action, to unlink a Jobs For Joe
    // account from a now-deleted Stripe customer.

    if (newValues.hasOwnProperty("stripe_cust_id")) {
      newValues.stripe_cust_id = ""
    }

    console.log("Retrieving account", accountId)
    const account = await dataServices.getAccountById(accountId)

    console.log("loaded account", { account, newValues })

    newValues = {
      ...newValues,
      ...account,
    }

    setTitle("Account: " + account.name)

    const result = await getStripeCustomer(accountId)

    console.log("%cRetrieved stripe customer", "color:yellow", result)

    if (result.status) {
      enqueueSnackbar(result.status.message, { variant: result.status.type })
    }

    const isStripeCustomerDeleted =
      result.customer &&
      result.customer.hasOwnProperty("deleted") &&
      result.customer.deleted === true

    const stripeStatus = isStripeCustomerDeleted
      ? "Deleted"
      : result.customer
      ? "Customer"
      : "No billing account"

    newValues.stripe_status = stripeStatus

    if (result.customer && !isStripeCustomerDeleted) {
      // Default 'customer.phone' to "" if null
      const resultWithDefaults = {
        ...result,
        customer: {
          ...result.customer,
          phone: result.customer.phone || "",
        },
      }

      setStripeAccountInfo(resultWithDefaults)
      setRetrievedBilling(true)

      newValues = {
        ...newValues,
        has_subscription: result.customer.subscriptions.length > 0,
        emails_match: account.email === result.customer.email,
      }
    } else {
      newValues = {
        ...newValues,
        has_subscription: false,
        emails_match: false,
      }
    }

    if (result.status) {
      enqueueSnackbar(result.status.message, { variant: result.status.type })
    }
    setShowProgress(false)

    console.log("account values", newValues)
    setValues(newValues)
  }

  const handleUpdateCard = (updatedCard) => {
    const newCards = stripeAccountInfo.cards.map((card) =>
      card.id === updatedCard.id ? updatedCard : card
    )

    const newStripeAccountInfo = {
      ...stripeAccountInfo,
      cards: newCards,
    }
    setStripeAccountInfo(newStripeAccountInfo)
  }

  useEffect(() => {
    if (accountId && maxModified) {
      console.log("loading account data", { accountId, maxModified })
      loadAccountData(accountId)
    }
  }, [accountId, setTitle, maxModified])

  // Listen for changes, so we can reload

  useEffect(() => {
    if (accountId === undefined) {
      return
    }

    const query = db
      .collection("accounts")
      .where(firebase.firestore.FieldPath.documentId(), "==", accountId)

    const unsub = query.onSnapshot(
      (querySnapshot) => {
        let newMaxModified = null
        querySnapshot.docChanges().forEach((change) => {
          console.log("changed", change)

          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            // trigger refresh
            newMaxModified = change.doc.data().modified
          }
        })
        if (newMaxModified !== null) {
          setMaxModified(newMaxModified)
        }
      },
      (error) => {
        console.error("error listening for account changes", error)
        enqueueSnackbar(error.message, { variant: "error" })
        setShowProgress(false)
      }
    )
    return unsub
  }, [accountId])

  const tabLabel = (label) => {
    return <Typography sx={styles.tabLabel}>{label}</Typography>
  }

  const subscriptionCount = useMemo(() => {
    console.log("subscription count", { stripeAccountInfo })
    return stripeAccountInfo?.customer?.subscriptions?.length || 0
  }, [stripeAccountInfo])

  return (
    <>
      <ProgressBackdrop open={isShowProgress} label={"Accessing Billing..."} />

      <Tabs
        value={tabIndex}
        onChange={(event, newIndex) => setTabIndex(newIndex)}
        aria-label="Account management"
        sx={styles.tabs}
      >
        <Tab label={tabLabel("Account")} {...a11yProps(0)} />
        <Tab
          label={tabLabel(`Subscriptions (${subscriptionCount})`)}
          {...a11yProps(1)}
        />
        <Tab
          label={tabLabel(`Cards (${stripeAccountInfo?.cards?.length || 0})`)}
          {...a11yProps(2)}
        />
        {/* {isSystemRole && <Tab label={tabLabel("Actions")} {...a11yProps(3)} />} */}
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        {!isSubscriptionCreated && (
          <Alert severity="info">
            Select the Subscriptions tab and create a subscription
          </Alert>
        )}
        {!isCardDefined && (
          <Alert severity="info">
            No cards found. Enter a payment card on the Cards tab
          </Alert>
        )}
        {isSubscriptionCreated && isCardDefined && (
          <Alert severity="success">Billing setup complete</Alert>
        )}
        <Box sx={styles.accountTab}>
          <Paper sx={styles.pageContent}>
            <Form>
              <Grid item>
                <Typography
                  variant="h6"
                  component={"span"}
                  gutterBottom={true}
                  paragraph={true}
                >
                  Account Details
                </Typography>
              </Grid>
              <Grid container direction="column">
                <Grid item>
                  <Controls.Readonly
                    name="name"
                    label="Account Name"
                    value={values.name}
                  />
                </Grid>
                <Grid item>
                  <Controls.Readonly
                    name="email"
                    label="Account Email"
                    value={values.email}
                  />
                </Grid>
                <Grid item>
                  <Controls.Readonly
                    name="has_stripe_customer"
                    label="Customer Billing Status"
                    value={values.stripe_status}
                  />
                </Grid>

                {isSystemRole && values.stripe_cust_id && (
                  <Grid item>
                    <Controls.Readonly
                      name="stripe_cust_id"
                      label="Customer Billing Id"
                      value={values.stripe_cust_id}
                    />
                  </Grid>
                )}

                <Grid item>
                  <Controls.Readonly
                    name="has_stripe_subscription"
                    label="Subscription Active?"
                    value={
                      values.has_subscription === undefined
                        ? "..."
                        : values.has_subscription
                        ? "Yes"
                        : "No"
                    }
                  />
                </Grid>

                {isSystemRole && values.stripe_cust_id && (
                  <Grid item>
                    <Controls.Readonly
                      name="emails_match"
                      label="Emails match?"
                      value={
                        values.stripe_status === "Deleted"
                          ? "N/A"
                          : values.emails_match === undefined
                          ? "..."
                          : values.emails_match
                          ? "Yes"
                          : "Different emails across User and Billing Accounts"
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          </Paper>

          {retrievedBilling && stripeAccountInfo && (
            <StripeCustomer customer={stripeAccountInfo.customer} />
          )}
        </Box>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        {retrievedBilling && stripeAccountInfo && (
          <Box
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {stripeAccountInfo.customer.subscriptions.map((sub) => (
              <StripeSubscription
                key={sub.id}
                subscription={sub}
                refreshSubscription={() => loadAccountData(accountId)}
                setShowProgress={setShowProgress}
              />
            ))}
            {stripeAccountInfo.invoice && !isUpcomingInvoiceForTrial && (
              <StripeInvoice invoice={stripeAccountInfo.invoice} />
            )}

            {stripeAccountInfo.customer.subscriptions.length === 0 &&
              stripePrices &&
              stripeProducts &&
              stripeProducts.map((product) => (
                <Paper
                  key={product.id}
                  style={{ padding: "10px", marginTop: "10px", maxWidth: 400 }}
                >
                  <Box>
                    <Box sx={styles.productName}>{product.name}</Box>
                    <Box>
                      <PriceSummary
                        price={stripePrices.find(
                          (price) => (price.product = product.id)
                        )}
                        product={product}
                        accountId={accountId}
                        allowCreate={true}
                        handleCreateSubscription={handleCreateSubscription}
                      />
                    </Box>
                  </Box>
                </Paper>
              ))}
          </Box>
        )}

        {!stripeAccountInfo &&
          stripeProducts &&
          stripePrices &&
          stripeProducts.map((product) => (
            <Paper key={product.id} sx={{ padding: "10px" }}>
              <PriceSummary
                price={stripePrices.find(
                  (price) => price.product === product.id
                )}
                product={product}
                accountId={accountId}
                allowCreate={true}
                handleCreateSubscription={handleCreateSubscription}
              />
            </Paper>
          ))}
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        {retrievedBilling && stripeAccountInfo && (
          <Box>
            {stripeAccountInfo.cards.length === 0 && (
              <Alert severity="info">
                No cards found. Enter a payment card to complete your billing
                setup
              </Alert>
            )}

            {stripeAccountInfo.cards.map((card) => (
              <StripeCard
                card={card}
                custId={values.stripe_cust_id}
                key={card.id}
                updateCard={handleUpdateCard}
                reload={async () => await loadAccountData(accountId)}
                setShowProgress={setShowProgress}
              />
            ))}

            <Elements stripe={stripePromise}>
              <StripeAddCard
                stripeCustId={values.stripe_cust_id}
                setShowProgress={setShowProgress}
                reload={() => loadAccountData(accountId)}
                accountId={accountId}
              />
            </Elements>
          </Box>
        )}
      </TabPanel>

      <TabPanel value={tabIndex} index={3}>
        {isSystemRole && (
          <StripeActions
            accountId={accountId}
            accountInfo={values}
            setShowProgress={setShowProgress}
          />
        )}
      </TabPanel>
    </>
  )
}

const PriceSummary = (props) => {
  const { price, product, accountId, allowCreate, handleCreateSubscription } =
    props

  const createSub = () => {
    console.log("Creating subscription", { product: product.name, accountId })
    handleCreateSubscription(product.name, accountId)
  }

  return (
    <Box>
      <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Typography variant="body1">{price.nickname}</Typography>
        {/* <Typography variant="body2">
                    {formatAmount(price.unit_amount)} per centre per month{" "}
                    {price.currency?.toUpperCase()}
                </Typography> */}

        {price.unit_amount && (
          <Typography variant="body2">
            {formatAmount(price.unit_amount)} per user per month{" "}
            {price.currency?.toUpperCase()}
          </Typography>
        )}
        {price.tiers && (
          <Typography variant="body2">
            Per user price tiering (ex GST)
          </Typography>
        )}
        {price.tiers &&
          price.tiers.map((tier, index) => (
            <Typography variant="body2" key={index}>
              {formatAmount(tier.unit_amount_decimal)}{" "}
              {stripeServices.getTierDescription(index, tier.up_to)}
            </Typography>
          ))}
        <Typography variant="body2">
          Australian accounts have 10% GST added to the total billable amount.
        </Typography>
        <Typography variant="body2">
          Usage fees begin after the 30 day free trial.
        </Typography>
        <Typography variant="body2">
          Adding centres will pro-rate the amount due.
        </Typography>
        <Typography variant="body2">
          Centres enabled in a given month are billed for that month
        </Typography>
        <Typography variant="body2">
          The account holder is responsible for paying the amount due for all
          centres.
        </Typography>
      </Box>

      {allowCreate && (
        <Box>
          <Box
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Controls.Button text="Create Subscription" onClick={createSub} />
          </Box>

          <Box sx={{ marginTop: "5px" }}>
            <Alert severity="info">
              After a subscription is created, a payment card needs to be
              entered on the Cards tab
            </Alert>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default BillingForm
