import { Box, Typography } from "@mui/material"
import TruncatedText from "./TruncatedText"

const LabelValue = ({ label, value, truncateWidth }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
      {label && (
        <Typography variant="body2" color="text.secondary" component="span">
          {label}
        </Typography>
      )}
      {truncateWidth && (
        <TruncatedText
          truncateWidth={truncateWidth}
          variant="body2"
          sx={{ marginLeft: "auto" }}
          component="span"
        >
          {value}
        </TruncatedText>
      )}
      {!truncateWidth && (
        <Typography
          variant="body2"
          sx={{ marginLeft: "auto" }}
          component="span"
        >
          {value}
        </Typography>
      )}
    </Box>
  )
}

export default LabelValue
