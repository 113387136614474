import {
  Box,
  IconButton,
  List,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import ViewCheckListPhotos from "./ViewCheckListPhotos"
import FileListItem from "./FileListItem"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"
import JobTile from "./JobTile"
import { createRef, useEffect, useMemo, useState } from "react"
import Controls from "./controls/Controls"
import { spacing } from "../pages/services/styleServices"
import { resizeFile } from "../pages/services/fileServices"
import { getStorage, ref, getDownloadURL, uploadString } from "firebase/storage"
import YesNo from "./YesNo"
import { getName } from "../pages/services/checkListServices"
import SelectableChip from "./SelectableChip"
import * as icons from "../icons"
import { useHistory } from "react-router-dom"

const styles = {
  labelAndInput: {
    display: "flex",
    flexWrap: "no-wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: spacing(1),
  },
  label: {
    marginRight: spacing(1),
    flexGrow: 1,
  },
  chips: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: spacing(0.5),
    marginTop: spacing(1),
  },
}

const ChecklistCheckField = ({
  width,
  section,
  element,
  values,
  valuesRef,
  handleCheckChange,
  handleUpdateComment,
  handleSaveAndCreateJob,
  handleUpdatePhotos,
  checkListInstanceId,
  fieldUrls,
  accountId,
  isComplete,
  jobs,
}) => {
  const [name] = useState(getName(section, element))

  const addFileRef = createRef()

  const history = useHistory()

  const [selectedIndex, setSelectedIndex] = useState(null)

  const [yesNoDeleteFileConfig, setYesNoDeleteFileConfig] = useState({
    openPrompt: false,
    description: "Delete file from checklist?",
    title: "Delete",
    handleConfirm: null,
  })

  useEffect(() => {
    if (fieldUrls && fieldUrls.length > 0) {
      setSelectedIndex(0)
    }
  }, [fieldUrls])

  const jobsForQuestion = useMemo(() => {
    if (jobs) {
      return jobs.filter(
        (job) =>
          job.parent_ref.element === element.seq &&
          job.parent_ref.section === section.seq
      )
    }
  }, [jobs])

  const handleClick = (value) => {
    const isSet = valuesRef.current.check_list_data[name] === value

    const valueToSet = isSet ? "" : value

    const event = { target: { name, value: valueToSet } }
    handleCheckChange(event)
  }

  const handleClickAddPhoto = (event) => {
    event.preventDefault()
    addFileRef.current.click()
  }

  const handleCreateJob = () => {
    const stateInfo = {
      parent: {
        parent_id: checkListInstanceId,
        parent_type: "checklist",
        parent_ref: { section: section.seq, element: element.seq },
        parent_label: `${section.label} : ${element.label}`,
        centre_id: values.centre_id,
      },
    }

    handleSaveAndCreateJob({ stateInfo })

    // history.push({
    //   pathname: `/JobEdit`,
    //   state: stateInfo,
    // })
  }

  const handleFileUpload = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]

      // Make sure we generate a unique file name to use before uploading
      //const uniqueFileName = jobs.getUniqueFileName(job, file.name)
      const uniqueFileName = file.name

      const resized = await resizeFile(file)

      const folderPath = `accounts/${accountId}/checklist_instances/${checkListInstanceId}`

      const fullFilePath = `${folderPath}/${uniqueFileName}`

      const storageRef = ref(getStorage(), fullFilePath)

      const result = await uploadString(storageRef, resized, "data_url")

      const filesInfo = values.check_list_data.files || {}
      const elementKey = getName(section, element)
      const elementFiles = filesInfo[elementKey] || []
      const newElementFiles = [...elementFiles, uniqueFileName]

      const newFilesInfo = {
        ...filesInfo,
        [elementKey]: newElementFiles,
      }

      // This pause is necessary, because otherwise we can try to retrieve the URL from firebase storage before it's ready and we get a 404 error
      setTimeout(() => {
        handleUpdatePhotos(newFilesInfo)
      }, 1500)
    }

    // Clear file input field
    event.target.value = null
  }

  const getPointsText = (element) => {
    if (element.type !== "check") {
      return ""
    }

    return ` (${element.score_value} ${
      element.score_value === 1 ? "point" : "points"
    })`
  }

  const handleDeleteFile = async (fileName) => {
    const filesInfo = values.check_list_data.files || {}

    const elementKey = getName(section, element)
    const elementFiles = filesInfo[elementKey] || []
    const newElementFiles = elementFiles.filter((item) => item !== fileName)
    const newFilesInfo = {
      ...filesInfo,
      [elementKey]: newElementFiles,
    }

    handleUpdatePhotos(newFilesInfo)
  }

  const handlePromptDeleteFile = (fileName) => {
    const newConfig = {
      ...yesNoDeleteFileConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteFile(fileName),
    }
    setYesNoDeleteFileConfig(newConfig)
  }

  const handleDownloadFile = async (fileName) => {
    const folderPath = `accounts/${values.account_id}/checklist_instances/${checkListInstanceId}`
    const fullFilePath = `${folderPath}/${fileName}`
    const storageRef = ref(getStorage(), fullFilePath)
    const url = await getDownloadURL(storageRef)
    window.open(url, "_blank")
  }

  const commentValue = useMemo(() => {
    const key = `${section.seq}_${element.seq}`
    const comments = values.check_list_data.comments || {}
    const comment = comments[key] || ""

    return comment
  }, [values, section, element])

  const [comment, setComment] = useState(commentValue)

  return (
    <Box sx={{ maxWidth: `${width - 20}px` }}>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="selected-files"
        multiple
        type="file"
        ref={addFileRef}
        onChange={(e) => handleFileUpload(e)}
      />
      <YesNo config={yesNoDeleteFileConfig} />
      <Box sx={styles.labelAndInput}>
        <Box sx={styles.label}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {element.label}
          </Typography>
          {!element.mandatory && (
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ fontStyle: "italic" }}
            >
              (Optional)
            </Typography>
          )}
        </Box>
        <Box sx={styles.chips}>
          {element.answers?.map((answer) => (
            <SelectableChip
              answer={answer}
              value={values.check_list_data[name]}
              readonly={isComplete}
              key={answer.label}
              handleClick={handleClick}
            />
          ))}
        </Box>
      </Box>
      <Box>
        <Typography variant="caption" color="primary">
          {element.text} {getPointsText(element)}
        </Typography>
      </Box>
      <Box sx={{ maxWidth: `${width}px`, marginTop: "15px" }}>
        <Controls.TextInput
          fullWidth
          multiline
          name="comment"
          label={"Comment"}
          value={comment}
          disabled={isComplete}
          onChange={(e) => setComment(e.target.value)}
          onBlur={(e) =>
            handleUpdateComment({
              key: `${section.seq}_${element.seq}`,
              value: e.target.value,
            })
          }
        />
      </Box>
      <Stack direction="row" sx={{ marginTop: "15px" }} gap={1}>
        {!isComplete && (
          <Tooltip title="Add photo">
            <IconButton onClick={handleClickAddPhoto}>
              <AddAPhotoIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Save and create Job">
          <IconButton onClick={handleCreateJob}>
            <icons.JobIcon />
          </IconButton>
        </Tooltip>
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {jobsForQuestion &&
          jobsForQuestion.map((job) => {
            return (
              <JobTile
                job={job}
                key={job.id}
                handleEditJob={() => history.push(`/JobEdit/${job.id}`)}
              />
            )
          })}
      </Box>

      <List dense={true}>
        {fieldUrls.map((item, index) => (
          <FileListItem
            key={item.fileName}
            handleViewPhotos={() => setSelectedIndex(index)}
            fileName={item.fileName}
            selected={selectedIndex === index}
            handlePromptDeleteFile={
              isComplete
                ? undefined
                : () => handlePromptDeleteFile(item.fileName)
            }
            handleDownloadFile={() => handleDownloadFile(item.fileName)}
          />
        ))}
      </List>
      <ViewCheckListPhotos
        fileInfo={fieldUrls}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
    </Box>
  )
}

export default ChecklistCheckField
