import { Alert, Box } from "@mui/material"
import { isEmailValid } from "../pages/services/emailServices"

const EmailInvalidAlert = ({ email }) => {
    return (
        <>
            {email && !isEmailValid(email) && (
                <Box sx={{ margin: "5px" }}>
                    <Alert severity="error">Invalid email address</Alert>
                </Box>
            )}
        </>
    )
}

export default EmailInvalidAlert
