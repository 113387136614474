import {
    Alert,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material"
import { useMemo, useState } from "react"
import Controls from "./controls/Controls"
import { useRef } from "react"
import * as xlsx from "xlsx"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import { IMPORT_FILE_HEADINGS } from "../pages/services/assetServices"

const AssetImportDialog = ({ open, onClose, onImport }) => {
    const [workbook, setWorkbook] = useState(null)

    const steps = ["Excel file", "Worksheet"]

    const addFileRef = useRef(null)

    const [activeStep, setActiveStep] = useState(0)

    const [excelFileName, setExcelFileName] = useState("")

    const handleSelectFile = (event) => {
        const reader = new FileReader()
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result)
            const workbook = xlsx.read(data, { type: "array" })
            setWorkbook(workbook)

            event.target.files = null
        }
        setExcelFileName(event.target.files[0].name)
        reader.readAsArrayBuffer(event.target.files[0])
    }

    const DESELECTED = -1

    const [selectedIndex, setSelectedIndex] = useState(DESELECTED)

    const sheetInfo = useMemo(() => {
        return (
            workbook &&
            workbook.SheetNames.map((name, index) => {
                const wsCells = workbook.Sheets[name]

                // Get 1st row of worksheet

                const headings = Object.keys(wsCells)
                    .filter((key) => key.match(/[A-Z]+1$/))
                    .map((key) => wsCells[key].v)

                // See if 1st row has any of the expected headings

                const hasSomeHeadings = headings.some((value) =>
                    IMPORT_FILE_HEADINGS.includes(value)
                )

                const hasAllHeadings = IMPORT_FILE_HEADINGS.every((value) =>
                    headings.includes(value)
                )

                const missingHeadings = IMPORT_FILE_HEADINGS.filter(
                    (value) => !headings.includes(value)
                ).join(", ")

                const headingsInfo = hasAllHeadings
                    ? "All expected headings found"
                    : `Missing headings - ${missingHeadings}`

                // Get number of rows in wsCells where the key is in the format A1, B1, C1, etc.
                // and the number after the letter represents the maxium row number in the worksheet

                const maxRows =
                    Object.keys(wsCells)
                        .filter((key) => key.match(/[A-Z]+[0-9]+/))
                        .map((key) => parseInt(key.match(/[0-9]+/)[0]))
                        .reduce((a, b) => Math.max(a, b), 0) - (hasSomeHeadings ? 1 : 0)

                return {
                    name,
                    headings,
                    headingsInfo,
                    maxRows,
                    hasSomeHeadings,
                    hasAllHeadings,
                }
            })
        )
    }, [workbook])

    const handleImport = () => {
        onImport({ workbook, selectedIndex, sheetInfo })
    }

    const canImport = useMemo(() => {
        const result =
            sheetInfo && selectedIndex !== DESELECTED && sheetInfo[selectedIndex].hasAllHeadings
        return result
    }, [sheetInfo, selectedIndex])

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Import Assets</DialogTitle>
            <DialogContent sx={{ minWidth: "400px" }}>
                <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

                <Box sx={{ marginBottom: "10px" }}>
                    <Divider />
                </Box>

                <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: "none" }}
                    ref={addFileRef}
                    multiple
                    id="icon-button-file"
                    type="file"
                    onChange={(e) => handleSelectFile(e)}
                />

                {activeStep === 0 && (
                    <Stack direction="column" gap={2}>
                        <Controls.Button
                            text="Select Excel file"
                            type="button"
                            onClick={() => addFileRef.current.click()}
                            endIcon={<FileUploadIcon />}
                        />
                        {excelFileName && (
                            <Typography>
                                Selected: <b>{excelFileName}</b>
                            </Typography>
                        )}
                    </Stack>
                )}

                {activeStep === 1 && (
                    <List
                        dense
                        component="nav"
                        sx={{ display: "flex", flexDirection: "column", gap: 0 }}
                    >
                        {sheetInfo &&
                            sheetInfo.map((info, index) => {
                                return (
                                    <Box key={info.name}>
                                        <ListItemButton
                                            selected={selectedIndex === index}
                                            onClick={() => {
                                                setSelectedIndex((curr) =>
                                                    curr === index ? DESELECTED : index
                                                )
                                            }}
                                            sx={{ margin: 0, padding: 0 }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{ fontWeight: "bold" }}>
                                                        {index + 1}. {info.name}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography
                                                        variant="caption"
                                                        color="text.secondary"
                                                    >
                                                        {info.maxRows} rows. {info.headingsInfo}
                                                    </Typography>
                                                }
                                                sx={{
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                }}
                                            />
                                        </ListItemButton>
                                    </Box>
                                )
                            })}
                    </List>
                )}

                {sheetInfo && selectedIndex === DESELECTED && activeStep === 1 && (
                    <Alert severity="info">
                        <Typography variant="caption">Select a worksheet to import</Typography>
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Controls.Button onClick={onClose} text="Cancel" />
                {activeStep === 1 && (
                    <Controls.Button
                        onClick={() => setActiveStep((curr) => curr - 1)}
                        text="Prev"
                    />
                )}
                {activeStep === 0 && (
                    <Controls.Button
                        onClick={() => setActiveStep((curr) => curr + 1)}
                        text="Next"
                        disabled={!workbook}
                    />
                )}

                {activeStep === 1 && (
                    <Controls.Button
                        onClick={handleImport}
                        text="Import Assets"
                        disabled={!canImport}
                    />
                )}
            </DialogActions>
        </Dialog>
    )
}

export default AssetImportDialog
