import * as actions from "../actionTypes"

// pageDocs is { first: <doc>, last: <doc> } for that page, and used by prev/next
const initialState = {
  value: [],
}

export default function quoteGridPageDocs(state = initialState, action) {
  switch (action.type) {
    case actions.SET_QUOTE_GRID_PAGE_DOCS: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
