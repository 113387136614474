import { Fab, Typography } from "@mui/material"
import { AddIcon } from "../../icons"

const FabButton = ({
  handleClick,
  label,
  rightOffset = 16,
  disabled = false,
}) => {
  const styles = {
    fab: {
      position: "fixed",
      bottom: 16,
      right: rightOffset,
      top: "auto",
      left: "auto",
    },
  }

  return (
    <Fab
      variant="extended"
      sx={styles.fab}
      size="small"
      color="primary"
      onClick={handleClick}
      disabled={disabled}
    >
      <AddIcon sx={{ mr: 1, fontSize: 18 }} />
      <Typography
        sx={{ textTransform: "none", paddingRight: "3px" }}
        variant="body2"
      >
        {label}
      </Typography>
    </Fab>
  )
}

export default FabButton
