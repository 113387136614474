import React, { useState } from "react"
import WorkOrderEditForm from "../components/WorkOrderEditForm"
import Header from "../components/Header"
import { useId } from "./useId"

const WorkOrderEdit = (props) => {
  const { id } = useId(props)

  const [title, setTitle] = useState("Edit Work Order")

  return (
    <Header title={title}>
      <WorkOrderEditForm id={id} setTitle={setTitle} />
    </Header>
  )
}

export default WorkOrderEdit
