import { getAuth } from "firebase/auth"
const PROVIDER_GOOGLE = "google.com"

// Authenticate via app-provided authentication options
const PROVIDER_FIREBASE = "firebase" // a password has not been defined
const PROVIDER_PASSWORD = "password" // a password has been defined

const setPassword = async ({ email, oldPassword, newPassword }) => {
  const user = getAuth().currentUser

  if (!isPasswordProviderFound(user)) {
    const newPasswordCredentials = getAuth().EmailAuthProvider.credential(
      email,
      newPassword
    )
    console.log("user has no password provider, adding one")
    const linkResult = await user.linkWithCredential(newPasswordCredentials)
  } else {
    const oldPasswordCredentials = getAuth().EmailAuthProvider.credential(
      email,
      oldPassword
    )
    await user
      .reauthenticateWithCredential(oldPasswordCredentials)
      .then(async () => {
        const updateResult = await user.updatePassword(newPassword)
      })
  }
}

const isPasswordDefined = (user) => {
  return (
    (user &&
      (user.providerId === PROVIDER_PASSWORD ||
        isPasswordProviderFound(user))) ||
    false
  )
}

const canChangePassword = (user) => {
  return (
    user &&
    (user.providerId === PROVIDER_FIREBASE ||
      user.providerId === PROVIDER_PASSWORD)
  )
}

const isPasswordProviderFound = (user) => {
  return (
    user &&
    user.providerData.find(
      (provider) => provider.providerId === PROVIDER_PASSWORD
    )
  )
}

const isGoogleProvider = (user) => {
  return (
    user &&
    user.providerData.find(
      (provider) => provider.providerId === PROVIDER_GOOGLE
    )
  )
}

export {
  setPassword,
  isPasswordProviderFound,
  isGoogleProvider,
  canChangePassword,
  isPasswordDefined,
}
