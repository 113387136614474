import firebase from "firebase/compat/app"
import { functions } from "../../Firestore"
import { httpsCallable } from "firebase/functions"
import ConfirmationCodeEmail from "../../emails/ConfirmationCodeEmail"
import { renderEmailComponent } from "./emailServices"
import { GPT_4o_LATEST, TEXT_EMBEDDING_ADA_002 } from "./chatGenerationServices"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const AUSTRALIA_SOUTHEAST1 = "australia-southeast1"

const acceptSupplierInvite = async (inviteId) => {
  const acceptInvite = httpsCallable(functions, "acceptSupplierInvite")

  const data = {
    inviteId: inviteId,
  }

  const result = await acceptInvite(data)
  return result
}

const checkUserExists = async () => {
  const checkExists = httpsCallable(functions, "checkUserExists")

  const result = await checkExists()

  return result.data.user_exists
}

// Valid return values are: 'ACTIVE', 'INACTIVE', or 'NOT FOUND'
const getUserStatus = async ({ email }) => {
  const checkActive = httpsCallable(functions, "getUserStatus")

  const result = await checkActive({ email })

  return result.data.status
}

const findLinkableSupplierAccount = async ({ email }) => {
  const func = httpsCallable(functions, "findLinkableSupplierAccount")
  const result = await func({ supplierEmail: email })
  return result.data
}

const linkToSupplierAccount = async ({ supplierId, supplierAccountId }) => {
  const linkSupplier = httpsCallable(functions, "linkToSupplierAccount")

  const result = await linkSupplier({
    supplierId: supplierId,
    supplierAccountId: supplierAccountId,
  })

  return result
}

const obtainCustomClaims = async () => {
  const obtainClaims = httpsCallable(functions, "getClaims")

  if (getAuth().currentUser) {
    return await getAuth()
      .currentUser.getIdTokenResult(true)
      .then(async (token) => {
        const payload = { idToken: token }
        if (process.env.NODE_ENV === "development") {
          console.log("obtainCustomClaims", { payload })
        }

        try {
          const claims = await obtainClaims(payload)
          return claims
        } catch (err) {
          return console.error("Error calling claims", err)
        }
      })
      .catch((err) => {
        console.error("Error in obtainCustomClaims", err)
      })
  } else {
    console.log("obtainCustomClaims", "no current user")
  }
  return {}
}

const sendHtmlEmail = async ({ to, replyTo, subject, html, text }) => {
  const sendEmail = httpsCallable(functions, "sendHtmlEmail")

  const result = await sendEmail({
    to: to,
    replyTo: replyTo,
    subject: subject,
    text: text,
    html: html,
  })

  return result
}

const validateOTPAndGetToken = async (email, otp, usePassword, password) => {
  const validateOTP = httpsCallable(functions, "validateOTPAndGetToken")

  const validateProps = { email, otp }

  if (usePassword) {
    validateProps.password = password
    validateProps.usePassword = usePassword
  }

  // seeking for result.data.token to be the Firebase token
  const result = await validateOTP(validateProps)

  return result
}

const getXeroOauthUrl = async () => {
  const getXeroOauthUrlFunc = httpsCallable(functions, "constructXeroOauthUrl")

  const result = await getXeroOauthUrlFunc()

  return result
}

const getSupplierOrgEmail = async ({ supplierAccountId }) => {
  const getSupplierOrgEmailFunc = httpsCallable(
    functions,
    "getSupplierOrgEmail"
  )

  const result = await getSupplierOrgEmailFunc({ accountId: supplierAccountId })

  return result
}

const getXeroSupplier = async ({ tenantId, supplierId }) => {
  const getXeroSupplierFunc = httpsCallable(functions, "getXeroSupplier")

  const result = await getXeroSupplierFunc({ tenantId, supplierId })

  return result
}

const getXeroTenants = async () => {
  const getXeroTenantsFunc = httpsCallable(functions, "getXeroTenants")

  const result = await getXeroTenantsFunc()

  return result
}

const getXeroRefreshToken = async () => {
  const refreshFunc = httpsCallable(functions, "refreshXeroToken")

  const result = await refreshFunc()

  return result
}

const getXeroPurchaseOrders = async ({ tenantId }) => {
  const getXeroPurchaseOrdersFunc = httpsCallable(
    functions,
    "getXeroPurchaseOrders"
  )

  const result = await getXeroPurchaseOrdersFunc({ tenantId })

  return result
}

const getXeroBillsByReference = async ({ tenantId, reference }) => {
  const getXeroBillsByReferenceFunc = httpsCallable(
    functions,
    "getXeroBillsByReference"
  )
  const result = await getXeroBillsByReferenceFunc({ tenantId, reference })
  return result
}

const getXeroContacts = async ({ tenantId, contactName }) => {
  const getXeroContactsFunc = httpsCallable(functions, "getXeroContacts")
  const result = await getXeroContactsFunc({ tenantId, contactName })
  return result
}

const createXeroSupplier = async ({ tenantId, supplier }) => {
  const createXeroSupplierFunc = httpsCallable(functions, "createXeroSupplier")

  const result = await createXeroSupplierFunc({ tenantId, supplier })

  return result
}

const checkEmailInUse = (email) => {
  const checkEmail = httpsCallable(functions, "checkEmailInUse")

  let inUse = true

  if (getAuth().currentUser) {
    try {
      const result = checkEmail({ email: email })

      inUse = result.email_used
    } catch (err) {
      console.error("Error checking if email used", err)
    }
  }
  return inUse
}

const refreshUserToken = () => {
  let callback = null
  let metadataRef = null

  const auth = getAuth()
  const unsub = onAuthStateChanged(auth, (user) => {
    if (callback) {
      metadataRef.off("value", callback)
    }
    if (user) {
      metadataRef = firebase
        .database()
        .ref("metadata/" + user.uid + "/refreshTime")
      callback = (snapshot) => {
        user.getIdToken(true)
      }
      metadataRef.on("value", callback)
    }
  })

  return unsub
}

const processInvite = async () => {
  const processInvite = httpsCallable(functions, "processInvite")

  const payload = {}

  const inviteResult = await processInvite(payload)

  return inviteResult
}

const isError = (funcResult) => {
  return funcResult.data?.status?.type === "error"
}

const getMessage = (funcResult) => {
  return funcResult.data?.status?.message
}

/**
 *
 * @param {*} input | The input to create the embedding for
 * @returns
 */
const createEmbedding = async (input) => {
  const createEmbeddingFunc = httpsCallable(functions, "createEmbedding")

  const payload = { input: input, model: TEXT_EMBEDDING_ADA_002 }

  try {
    const queryResult = await createEmbeddingFunc(payload)

    return queryResult
  } catch (err) {
    console.error("Error calling createEmbedding", err)
  }
}

const createChatCompletion = async ({ messages, model = GPT_4o_LATEST }) => {
  const func = httpsCallable(functions, "createGptChatCompletion", {
    timeout: 300000,
  })

  try {
    // Only print to console if in dev mode
    if (process.env.NODE_ENV === "development") {
      console.log("%ccreating chat completion with model", "color:lightblue", {
        model,
        messages,
      })
    }
    const queryResult = await func({ messages, model })

    return queryResult
  } catch (err) {
    console.error("Error calling createChatCompletion", err)
    throw err
  }
}

const createChatCompletionWithFunctions = async ({
  messages,
  model = GPT_4o_LATEST,
  funcs = [],
  function_call,
}) => {
  const func = httpsCallable(
    functions,
    "createGptChatCompletionWithFunctions",
    {
      timeout: 300000,
    }
  )

  try {
    if (process.env.NODE_ENV === "development") {
      console.log("%ccreating chat completion with model", "color:lightblue", {
        model,
        messages,
        funcs,
        function_call,
      })
    }
    const queryResult = await func({ messages, model, funcs, function_call })

    return queryResult
  } catch (err) {
    console.error("Error calling createChatCompletion", err)
    throw err
  }
}

const listJobs = async (params) => {
  const query = httpsCallable(functions, "listJobs")

  const queryResult = await query(params)

  return queryResult.data[0]
}

const queryJobs = async ({
  accountId,
  centreIds,
  cols,
  createdStart,
  createdEnd,
  statuses,
}) => {
  const query = httpsCallable(functions, "queryJobs")

  const validCols = [
    "year_month",
    "category",
    "location",
    "priority",
    "status",
    "centre_id",
    "supplier_id",
  ]

  if (!cols.every((col) => validCols.includes(col))) {
    throw new Error(`Invalid column name - ${cols.join(", ")}`)
  }

  const queryResult = await query({
    account_id: accountId,
    centreIds,
    cols,
    // TODO: legacy. remove. uses created_start and created_end
    months: 3,
    created_start: createdStart,
    created_end: createdEnd,
    statuses: statuses,
  })

  return queryResult
}

/**
 *
 * @param {*} viewName The name of the view to query in BigQuery
 * @returns data from BigQuery
 */
const queryViewByAccountId = async (viewName, params) => {
  // The account id value is determined bty the function server-side
  const query = httpsCallable(functions, "queryViewByAccountId")

  const payload = { viewName, ...params }

  const queryResult = await query(payload)

  return queryResult
}

const log = ({ accountId, userId, type, severity = "INFO", message }) => {
  const log = httpsCallable(functions, "log")

  const payload = {
    account_id: accountId,
    user_id: userId,
    type,
    message,
    severity,
  }

  log(payload)
}

const queryJobSnapshot = async (params) => {
  const query = httpsCallable(functions, "queryJobSnapshot")

  const payload = { ...params }

  const queryResult = await query(payload)

  const flattenedResult = queryResult.data[0].map((row) => ({
    ...row,
    snapshot_date: row.snapshot_date.value,
  }))

  return flattenedResult
}

/**
 *
 * @param {*} name The name of the view or table to query in BigQuery
 * @param {*} type Either 'views' or 'tables'
 * @returns data from BigQuery
 */
const queryByAccountId = async (name, type, params) => {
  // The account id value is determined bty the function server-side
  const query = httpsCallable(functions, "queryByAccountId")

  const payload = { name, type, ...params }

  const queryResult = await query(payload)

  return queryResult
}

const queryJobsByCentreId = async (centreId) => {
  // The account id value is determined bty the function server-side
  const query = httpsCallable(functions, "queryJobsByCentreId")

  const payload = { centreId }

  const queryResult = await query(payload)

  return queryResult
}

const isQrOTPUsed = async ({ email }) => {
  const isQRUsed = httpsCallable(functions, "isQrOTPUsed")

  const result = await isQRUsed({ email: email })

  return result
}

const isExistingOTP = async ({ email }) => {
  const isExistingOTP = httpsCallable(functions, "isExistingOTP")
  const result = await isExistingOTP({ email: email })

  return result
}

const sendMultipartEmailMessage = async ({
  from,
  to,
  subject,
  text,
  html,
  attachments,
}) => {
  const sendEmail = httpsCallable(functions, "sendMultipartEmailMessage")

  const result = await sendEmail({
    from: from,
    to: to,
    subject: subject,
    text: text,
    html: html,
    attachments: attachments,
  })

  return result
}

const createAndSendOTPViaEmail = async (email) => {
  const createAndSendOTP = httpsCallable(functions, "createAndSendOTPCode")

  renderEmailComponent(
    ConfirmationCodeEmail,
    // The cloud function will replace ***CODE*** and ***EXPIRY*** with the server-side generated values
    { code: "***CODE***", expiryDate: "***EXPIRY***" },
    async (html) => {
      const args = { email: email?.toLowerCase(), html: html }
      const result = await createAndSendOTP(args)
    }
  )
}

const setUserQrCodeUsed = async () => {
  const setQRCodeUsed = httpsCallable(functions, "setUserQrCodeUsed")

  setQRCodeUsed()
}

const isEmailOTPCorrect = async (email, otp) => {
  const isOTPCorrect = httpsCallable(functions, "isEmailOTPCorrect")

  const result = await isOTPCorrect({ email, otp })

  return result.data
}

const isQROTPCorrect = async (otp, email) => {
  const func = httpsCallable(functions, "isQROTPCorrect")

  const result = await func({ otp, email })

  return result.data
}

const createUserAndGetToken = async (email, password) => {
  const func = httpsCallable(functions, "createUserAndGetToken")

  const result = await func({ email, password })

  return result.data
}

const getUserAuthenticationMethods = async ({ email }) => {
  const geAuthenticationMethods = httpsCallable(
    functions,
    "getUserAuthenticationMethods"
  )

  const result = geAuthenticationMethods({ email })

  return result
}

const createTotpURI = async (email) => {
  const getTotpURI = httpsCallable(functions, "createTotpURI")

  const payload = { email }

  const result = await getTotpURI(payload)

  return result
}

const generateTotpKey = async (email) => {
  const func = httpsCallable(functions, "generateTotpKey")

  const payload = { email }

  const result = await func(payload)

  return result
}

export {
  createChatCompletion,
  createChatCompletionWithFunctions,
  createEmbedding,
  obtainCustomClaims,
  getXeroSupplier,
  getXeroTenants,
  getXeroPurchaseOrders,
  getXeroBillsByReference,
  getXeroContacts,
  getXeroRefreshToken,
  createXeroSupplier,
  refreshUserToken,
  processInvite,
  checkEmailInUse,
  acceptSupplierInvite,
  findLinkableSupplierAccount,
  linkToSupplierAccount,
  isExistingOTP,
  checkUserExists,
  getUserStatus,
  getXeroOauthUrl,
  isError,
  log,
  getMessage,
  createTotpURI,
  generateTotpKey,
  validateOTPAndGetToken,
  sendHtmlEmail,
  sendMultipartEmailMessage,
  createAndSendOTPViaEmail,
  createUserAndGetToken,
  getSupplierOrgEmail,
  isEmailOTPCorrect,
  isQROTPCorrect,
  getUserAuthenticationMethods,
  queryViewByAccountId,
  queryJobSnapshot,
  queryJobs,
  listJobs,
  queryByAccountId,
  queryJobsByCentreId,
  setUserQrCodeUsed,
  isQrOTPUsed,
  AUSTRALIA_SOUTHEAST1,
}
