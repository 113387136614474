import React, { useEffect, useState } from "react"
import Header from "../components/Header"
import { Link, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import { Alert, AlertTitle } from "@mui/material"
import { spacing } from "./services/styleServices"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const styles = {
  pageContent: {
    margin: spacing(2),
    padding: spacing(2),
  },
  leftPadded: {
    marginLeft: spacing(2),
  },
  link: {
    cursor: "pointer",
  },
}

function SignOut() {
  const history = useHistory()

  const handleSignIn = () => {
    history.push("/SignIn")
  }

  const [signedIn, setSignedIn] = useState(false)

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      setSignedIn(user !== undefined && user !== null)
    })

    try {
      getAuth()
        .signOut()
        .then(() => {
          history.push("/SignIn")
        })
        .catch((error) => {
          console.log("signing out", error)
        })

      return unsub
    } catch (error) {
      console.log("signing out", error)
    }
  }, [])

  return (
    <Header title="Sign Out">
      {signedIn && (
        <Alert severity="info" sx={styles.pageContent}>
          <AlertTitle>Sign Out Status</AlertTitle>
          You are still signed in
        </Alert>
      )}

      {!signedIn && (
        <Alert severity="success" sx={styles.pageContent}>
          <AlertTitle>Sign Out Status</AlertTitle>
          You are signed out
        </Alert>
      )}

      <Typography variant="caption" sx={styles.leftPadded}>
        <Link color="primary" sx={styles.link} onClick={handleSignIn}>
          Sign In again
        </Link>
      </Typography>
    </Header>
  )
}

export default SignOut
