import React, { useState, useEffect, useMemo } from "react"
import { styled } from "@mui/material/styles"
import Controls from "./controls/Controls"
import IconButton from "@mui/material/IconButton"
import { useForm, Form } from "./useForm"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import LocationIcon from "@mui/icons-material/LocationOn"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import * as colorServices from "../pages/services/colorServices"
import { useSnackbar } from "notistack"
import { Link, useHistory, withRouter } from "react-router-dom"
import PieChartIcon from "@mui/icons-material/PieChart"
import SubHeading from "./SubHeading"
import {
  getCountFromServer,
  collection,
  where,
  query,
} from "firebase/firestore"
import {
  Card,
  CardHeader,
  CardContent,
  Chip,
  List,
  ListItem,
  FormControl,
  ListItemText,
  ListItemSecondaryAction,
  Skeleton,
  Stack,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import YesNo from "./YesNo"
import ProgressBackdrop from "./ProgressBackdrop"
import * as Roles from "../pages/services/roleServices"
import { Alert } from "@mui/material"
import { functions } from "../Firestore"
import { httpsCallable } from "firebase/functions"
import { Typography } from "@mui/material"
import { Box } from "@mui/material"
import * as icons from "../icons"
import PieChart from "./PieChart"
import NavButtons from "./NavButtons"
import UserAutoComplete from "./controls/UserAutoComplete"
import { useSelector, useDispatch } from "react-redux"
import { setJobGridPagination } from "../redux/actions"
import { selectJobGridPagination } from "../redux/selectors"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const initialValues = () => {
  return {
    name: "",
    short_name: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    email: "",
    phone: "",
    locations: [],
    user_id_contact: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
  }
}

const CentreEditForm = (props) => {
  const { setTitle } = props

  const [location, setLocation] = useState("")

  const history = useHistory()

  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const jobPagination = useSelector(selectJobGridPagination)

  // Should we be showing a backdrop with progress indicator?
  // Used when we check if we can delete a supplier
  const [isShowProgress, setShowProgress] = useState(false)

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete Centre?",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  // Data for pie chart
  const [jobTypesByCentreData, setJobTypesByCentreData] = useState({
    labels: [],
    datasets: [],
  })
  const [jobsTotal, setJobsTotal] = useState(0)

  const [isRetrievingChart, setIsRetrievingChart] = useState(false)

  const [deleteJobLocationYesNoConfig, setDeleteJobLocationYesNoConfig] =
    useState({
      title: "Delete Job Location?",
      description: "You need to press Save to make this change permanent",
      openPrompt: false,

      // this method is set when we prompt for deletion
      handleConfirm: null,
    })

  const [centreId, setCentreId] = useState("")

  // Updates made to the centre manager. Will remain undefined if no change made.
  const [centreManager, setCentreManager] = useState()

  const isNew = useMemo(
    () => props.id === undefined && centreId === "",
    [props.id, centreId]
  )

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const [accountId, setAccountId] = useState()

  const [isEditable, setEditable] = useState(false)

  const [isShowLocationPicker, setShowLocationPicker] = useState(false)

  // For admin users, show the users who have access, and the outstanding invites

  const [users, setUsers] = useState([])

  const [invites, setInvites] = useState([])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult(false).then((token) => {
          setAccountId(token.claims.account_id)
          setEditable(Roles.isCentreEditable(token.claims.roles))
        })
      }
    })

    return unsub
  }, [])

  // For admin users, show the users who have access, and outstanding invites

  useEffect(() => {
    if (centreId !== "") {
      const auth = getAuth()
      const unsub = onAuthStateChanged(auth, (user) => {
        user.getIdTokenResult(false).then((token) => {
          if (token.claims.roles.includes(Roles.ADMIN)) {
            // Get users who have access

            const userQuery = db
              .collection("users")
              .where("account_id", "==", token.claims.account_id)
              .where("centres", "array-contains", centreId)

            dataServices
              .loadData("centre edit, get users", userQuery)
              .then((users) => {
                setUsers(users)
              })

            // Get outstanding invites

            const inviteQuery = db
              .collection("invites")
              .where("account_id", "==", token.claims.account_id)
              .where("centres", "array-contains", centreId)

            dataServices
              .loadData("centre edit, get invites", inviteQuery)
              .then((invites) => setInvites(invites))
          }
        })
      })
      return unsub
    }
  }, [centreId])

  const canDeleteCentre = async (centreId) => {
    const isDeleteCentreAllowed = httpsCallable(functions, "canDeleteCentre")

    const data = { centre_id: centreId }
    return await isDeleteCentreAllowed(data)
  }

  useEffect(() => {
    if (props.id) {
      setCentreId(props.id)

      // Load centre
      db.collection("centres")
        .doc(props.id)
        .get()
        .then((snapshot) => {
          const loadedValues = {
            ...initialValues(),
            ...snapshot.data(),
          }

          setValues(loadedValues)
        })
    }
  }, [props.id])

  useEffect(() => {
    setTitle(values.name)
  }, [values.name, setTitle])

  const handleAddLocations = (locations) => {
    const newValues = {
      ...values,
    }

    locations.forEach((item) => {
      if (!newValues.locations.includes(item)) {
        newValues.locations.push(item)
      }
    })
    newValues.locations.sort()

    setValues(newValues)
  }

  const handleNewLocationChange = (event) => {
    setLocation(event.target.value)
  }

  const handlePromptDeleteLocation = (location) => {
    const newConfig = {
      ...deleteJobLocationYesNoConfig,
      openPrompt: true,
      title: `Delete Job Location '${location}'?`,
      handleConfirm: () => handleDeleteLocation(location),
    }
    setDeleteJobLocationYesNoConfig(newConfig)
  }

  const handleDeleteLocation = (location) => {
    const newValues = {
      ...values,
      locations: values.locations.filter((item) => item !== location),
    }

    setValues(newValues)
  }

  const handleViewJobs = () => {
    const updatedPagination = {
      ...jobPagination,
      page: 0,
      centre_id: centreId,
    }
    dispatch(setJobGridPagination(updatedPagination))

    history.push(`/JobGrid/`)
  }

  const handleGetJobSummary = async () => {
    setIsRetrievingChart(true)

    const results = await cloudFunctions.queryJobsByCentreId(centreId)

    const labels = results.data[0].map((item) => item.category)
    const datasets = [
      {
        label: "Count",
        data: results.data[0].map((item) => item.count),
        backgroundColor: colorServices.getColors(results.data[0].length),
      },
    ]
    setJobsTotal(results.data[0].reduce((acc, item) => acc + item.count, 0))
    setJobTypesByCentreData({
      labels: labels,
      datasets: datasets,
    })

    setIsRetrievingChart(false)
  }

  const handleAddLocation = () => {
    if (location !== "") {
      const newValues = {
        ...values,
      }

      newValues.locations.push(location)
      newValues.locations.sort()

      setValues(newValues)
      setLocation("")
    }
  }

  // confirmed is true or false
  const handleDeleteConfirmed = async () => {
    // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)

    await db.collection("centres").doc(centreId).delete()

    // Subtract 1 from account centre_count
    await updateAccountCentreCount(-1)

    history.goBack()
    enqueueSnackbar("Deleted", { variant: "success" })
  }

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    setShowProgress(true)

    enqueueSnackbar("Checking if centre can be deleted", { variant: "info" })

    canDeleteCentre(centreId)
      .then((result) => {
        setShowProgress(false)
        if (result.data) {
          const newConfig = {
            ...yesNoConfig,
            openPrompt: true,
            handleConfirm: handleDeleteConfirmed,
          }
          setYesNoConfig(newConfig)
        } else {
          enqueueSnackbar("Centre referenced by other data. Cannot delete", {
            variant: "info",
          })
        }
      })
      .catch((err) => console.log("error deleting centre", err))
  }

  const navigateAfterSubmit = () => {
    if (history.length > 0) {
      history.goBack()
    } else {
      history.push("/centres")
    }
  }

  const handleSubmit = async (event, addMultiple) => {
    event.preventDefault()

    if (event.target.name === "new_location") {
      handleAddLocation()
      return
    }

    if (values.name === "") {
      enqueueSnackbar("Enter name", { variant: "error" })
      return
    }

    console.log("saving centre", { isNew })

    if (isNew) {
      const newRecord = {
        ...values,
        name_lower: values.name.toLowerCase(),
        account_id: accountId,
        created: dataServices.serverTimestamp(),
        modified: dataServices.serverTimestamp(),
      }

      if (centreManager) {
        newRecord.user_id_contact = centreManager.id
      }

      console.log("saving new record", newRecord)

      // Update centre count in the account record

      db.collection("centres")
        .add(newRecord)
        .then((docRef) => setCentreId(docRef.id))
        .then(enqueueSnackbar("Created", { variant: "success" }))
        .then(() => {
          if (addMultiple) {
            setValues(initialValues())
            setCentreId("")
          } else {
            navigateAfterSubmit()
          }
        })
        .then(async () => {
          await updateAccountCentreCount(1)
        })
    } else {
      const updateRecord = {
        ...values,
        name_lower: values.name.toLowerCase(),
        modified: dataServices.serverTimestamp(),
      }

      if (centreManager) {
        updateRecord.user_id_contact = centreManager.id
      }

      await db.collection("centres").doc(centreId).update(updateRecord)

      await updateAccountCentreCount(0)

      enqueueSnackbar("Updated", { variant: "success" })
      navigateAfterSubmit()
    }
  }

  /**
   *
   * @param {*} delta either 0 (centre modified), +1 (new centre) or -1 (centre deleted)
   * @returns
   */
  const getNewCentreCount = async ({ accountId }) => {
    const countCentres = query(
      collection(db, "centres"),
      where("account_id", "==", accountId)
    )
    const result = await getCountFromServer(countCentres)

    const centreCount = result.data().count

    return centreCount
  }

  /**
   * Update the centre_count attribute for this account. Note this is meant to be called AFTER any centre changes have been made, i.e. new centre added or existing centre deleted
   *
   * @param {*} delta Either +1 (adding a centre) or -1 (deleting a centre). If centre_count not defined in account then it will be calculated
   */
  const updateAccountCentreCount = async (delta) => {
    const newCentreCount = await getNewCentreCount({ accountId: accountId })

    const updateRec = {
      centre_count: newCentreCount,
      modified: dataServices.serverTimestamp(),
    }

    await db
      .collection("accounts")
      .doc(accountId)
      .update(updateRec, { merge: true })
  }

  const handleCentreManagerChange = (selectedUser) => {
    setCentreManager(selectedUser)
  }

  const handleCentreManagerClear = () => {
    setCentreManager(undefined)
    setValues({
      ...values,
      user_id_contact: "",
    })
  }

  return (
    <>
      {isEditable || (
        <Alert severity="info">
          Centre is read only. Requires Admin role to edit
        </Alert>
      )}

      <YesNo config={yesNoConfig} />

      <YesNo config={deleteJobLocationYesNoConfig} />

      <LocationPicker
        open={isShowLocationPicker}
        setOpen={setShowLocationPicker}
        accountId={accountId}
        handleAddLocations={handleAddLocations}
      />

      <ProgressBackdrop open={isShowProgress} />

      <SubHeading title="Centre Details" />

      <Stack sx={{ mt: "30px", maxWidth: "600px" }}>
        <Controls.TextInput
          name="name"
          label="Centre Name"
          value={values.name}
          disabled={!isEditable}
          onChange={handleInputChange}
          sx={{ width: "100%", mb: "20px" }}
        />
        <Controls.TextInput
          name="short_name"
          label="Short Name"
          value={values.short_name}
          disabled={!isEditable}
          onChange={handleInputChange}
          sx={{ width: "100%", mb: "20px" }}
        />
      </Stack>
      <Box
        sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2 }}
      >
        <Stack gap={2}>
          <Controls.TextInput
            name="email"
            label="Email"
            value={values.email}
            icon={<EmailIcon />}
            disabled={!isEditable}
            onChange={handleInputChange}
          />

          <Controls.TextInput
            name="phone"
            label="Phone"
            value={values.phone}
            icon={<PhoneIcon />}
            disabled={!isEditable}
            onChange={handleInputChange}
          />

          <Controls.CreatedDate
            name="created"
            label="Created"
            value={values.created}
          />

          <UserAutoComplete
            accountId={accountId}
            userId={values.user_id_contact}
            handleChange={handleCentreManagerChange}
            handleClear={handleCentreManagerClear}
            label="Centre Manager Email"
          />
        </Stack>

        <CentreAddress {...{ values, isEditable, handleInputChange }} />
      </Box>

      <JobLocations
        values={values}
        location={location}
        isEditable={isEditable}
        handlePromptDeleteLocation={handlePromptDeleteLocation}
        isShowLocationPicker={isShowLocationPicker}
        setShowLocationPicker={setShowLocationPicker}
        handleNewLocationChange={handleNewLocationChange}
        handleAddLocation={handleAddLocation}
      />

      <Box sx={{ marginTop: "30px" }}>
        <SubHeading title="Centre Users" />
      </Box>

      {users && users.length === 0 && (
        <Typography variant="body2" color="text.secondary">
          No users have been allocated to this centre. Admin users can access
          all centres.
        </Typography>
      )}

      <List dense>
        {users &&
          users.map((user) => (
            <ListItem key={user.id}>
              <ListItemText primary={user.name} secondary={user.email} />
            </ListItem>
          ))}
      </List>

      <Box sx={{ marginTop: "30px" }}>
        <SubHeading title="Invites" />
      </Box>

      {invites && invites.length === 0 && (
        <Typography variant="body2" color="text.secondary">
          No invites outstanding for users to join this centre
        </Typography>
      )}

      <List dense>
        {invites &&
          invites.map((invite) => (
            <ListItem key={invite.id}>
              <ListItemText primary={invite.email} />
            </ListItem>
          ))}
      </List>

      {isRetrievingChart && jobTypesByCentreData.labels.length === 0 && (
        <Skeleton
          variant="rectangular"
          sx={{ width: 200, height: 300, margin: 2 }}
        />
      )}

      {jobTypesByCentreData.labels.length > 0 && (
        <Card sx={{ maxWidth: 400, mt: "15px", mb: "15px" }}>
          <CardHeader
            title={<Typography variant="h6">Job Types</Typography>}
            subheader={`${jobsTotal} Jobs`}
          />
          <CardContent>
            <Box
              sx={{
                maxWidth: 330,
                maxHeight: 330,
              }}
            >
              <PieChart data={jobTypesByCentreData} />
            </Box>
          </CardContent>
        </Card>
      )}

      <NavButtons>
        {!isNew && isEditable && (
          <Controls.Button
            text="Job Types"
            type="button"
            onClick={handleGetJobSummary}
            endIcon={<PieChartIcon />}
          />
        )}

        <Controls.Button
          type="button"
          text="View Jobs"
          onClick={handleViewJobs}
          endIcon={<icons.JobIcon />}
          tooltip="Show jobs for this centre"
        />

        {!isNew && isEditable && (
          <Controls.Button
            text="Delete"
            type="button"
            onClick={handlePromptConfirmDelete}
            endIcon={<icons.DeleteIcon />}
          />
        )}

        {/* {isEditable && (
          <Controls.Button
            type="submit"
            text="Save and Add New"
            onClick={(event) => handleSubmit(event, true)}
          />
        )} */}

        {isEditable && (
          <Controls.Button
            type="submit"
            text="Save"
            onClick={(event) => handleSubmit(event, false)}
            endIcon={<icons.SaveIcon />}
          />
        )}
      </NavButtons>
    </>
  )
}

const JobLocations = ({
  values,
  location,
  isEditable,
  handlePromptDeleteLocation,
  isShowLocationPicker,
  setShowLocationPicker,
  handleNewLocationChange,
  handleAddLocation,
}) => {
  return (
    <>
      <Box sx={{ marginTop: "30px" }}>
        <SubHeading title="Job Locations" />
      </Box>

      {values.locations.length === 0 && (
        <Typography variant="body2" color="text.secondary">
          No locations defined
        </Typography>
      )}

      <Box sx={{ width: "350px" }}>
        <List dense>
          {values.locations.map((location) => (
            <ListItem key={location}>
              <ListItemText primary={location}></ListItemText>

              {isEditable && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handlePromptDeleteLocation(location)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}

          {isEditable && (
            <ListItem style={{ padding: "0", marginTop: "15px" }}>
              <FormControl style={{ padding: "0", margin: "0" }}>
                <Controls.TextInput
                  id="new_location"
                  name="new_location"
                  label="Add Job Location"
                  value={location}
                  helperText="Define a new location where jobs can be performed at this centre"
                  xs={7}
                  onChange={handleNewLocationChange}
                />
              </FormControl>
            </ListItem>
          )}
        </List>

        {isEditable && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Stack direction="row" gap={1}>
              <Controls.Button
                text="Choose..."
                onClick={() => setShowLocationPicker(!isShowLocationPicker)}
                tooltip="Add a new centre job location from the list of default locations"
              />
              <Controls.Button
                text="Add Location"
                onClick={handleAddLocation}
              />
            </Stack>
          </Box>
        )}
      </Box>
    </>
  )
}

const CentreAddress = ({ values, isEditable, handleInputChange }) => {
  return (
    <Stack gap={2}>
      <Controls.TextInput
        name="addr1"
        label="Addr1"
        value={values.addr1}
        icon={<LocationIcon />}
        disabled={!isEditable}
        onChange={handleInputChange}
      />

      <Controls.TextInput
        name="addr2"
        label="Addr2"
        value={values.addr2}
        disabled={!isEditable}
        onChange={handleInputChange}
      />

      <Controls.TextInput
        name="city"
        label="City"
        value={values.city}
        disabled={!isEditable}
        onChange={handleInputChange}
      />

      <Stack direction="row" gap={3}>
        <Controls.TextInput
          name="state"
          label="State"
          value={values.state}
          disabled={!isEditable}
          onChange={handleInputChange}
          sx={{ width: "100px" }}
        />

        <Controls.TextInput
          name="postcode"
          label="Postcode"
          value={values.postcode}
          disabled={!isEditable}
          onChange={handleInputChange}
          sx={{ width: "100px" }}
        />
      </Stack>

      <Controls.TextInput
        name="country"
        label="Country"
        value={values.country}
        disabled={!isEditable}
        onChange={handleInputChange}
      />
    </Stack>
  )
}

const StyledChips = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  flexWrap: "wrap",
  marginTop: theme.spacing(2),
  gap: theme.spacing(0.5),
}))

const LocationPicker = (props) => {
  const { open, setOpen, accountId, handleAddLocations } = props

  const [locationTypes, setLocationTypes] = useState([])

  const [selected, setSelected] = useState([])

  useEffect(() => {
    if (open) {
      setSelected([])
    }
  }, [open])

  useEffect(() => {
    if (accountId !== undefined) {
      let query = db
        .collection("lookups")
        .where("account_id", "==", accountId)
        .where("name", "==", "location_types")

      dataServices
        .loadData(`Load location types`, query)
        .then((locationTypes) => {
          if (locationTypes.length === 1) {
            setLocationTypes(locationTypes[0].lookup_values.sort())
          }
        })
    }
  }, [accountId])

  const handleClose = () => {
    setOpen(false)
  }

  const handleToggle = (type) => {
    if (selected.includes(type)) {
      const newSelected = selected.filter((item) => item !== type)
      setSelected(newSelected)
    } else {
      const newSelected = [...selected, type]
      setSelected(newSelected)
    }
  }

  const handleSelectAll = () => {
    setSelected(locationTypes)
  }

  const handleSelectNone = () => {
    setSelected([])
  }

  const handleOK = () => {
    handleAddLocations(selected)
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Select Locations</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select Locations then Add to the Centre
        </DialogContentText>
        <Form>
          <StyledChips>
            {locationTypes.map((type) => (
              <Chip
                size="small"
                variant={selected.includes(type) ? "filled" : "outlined"}
                key={type}
                label={type}
                color={selected.includes(type) ? "primary" : "default"}
                onClick={() => handleToggle(type)}
              />
            ))}
          </StyledChips>
        </Form>
      </DialogContent>
      <DialogActions>
        <Stack gap={1}>
          <Box
            sx={{ display: "flex", flexDirection: "row", marginLeft: "auto" }}
          >
            <NavButtons>
              <Controls.Button text="Cancel" onClick={handleClose} />
              <Controls.Button text="None" onClick={handleSelectNone} />
              <Controls.Button text="All" onClick={handleSelectAll} />
              <Controls.Button
                text="Add"
                onClick={handleOK}
                variant="contained"
              />
            </NavButtons>
          </Box>

          <Box sx={{ marginLeft: "auto" }}>
            <Typography variant="caption">
              <Link to="/LocationTypes">Configure</Link> new common location
              types
            </Typography>
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default withRouter(CentreEditForm)
