import React, { useEffect, useState } from "react"
import { useTheme } from "@mui/material/styles"
import { Card, CardHeader, Box, Stack, CardActions } from "@mui/material"
// doco: https://jquense.github.io/react-big-calendar/examples/index.html
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment-timezone"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { useHistory } from "react-router-dom"
import * as dateServices from "../pages/services/dateServices"
import { useDispatch, useSelector } from "react-redux"
import {
  selectWorkOrderCalendarDateRange,
  selectWorkOrderGridPagination,
} from "../redux/selectors"
import { setWorkOrderCalendarDateRange } from "../redux/actions"
import { useSnackbar } from "notistack"
import { selectWorkOrderLastModified } from "../redux/selectors"
import { setWorkOrderLastModified } from "../redux/actions"
import * as workOrderServices from "../pages/services/workOrderServices"
import { Typography } from "@mui/material"
import * as WorkOrder from "./workOrderStatus"
import { navigate } from "react-big-calendar/lib/utils/constants"
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"
import AutoRenewIcon from "@mui/icons-material/Autorenew"
import _ from "lodash"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import { Grid } from "@mui/material"
import Controls from "./controls/Controls"
import { CardContent } from "@mui/material"
import StatusChip from "./controls/StatusChip"
import { useMemo } from "react"
import LinkButton from "./controls/LinkButton"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const localizer = momentLocalizer(moment)

// doco: https://github.com/jquense/react-big-calendar/blob/master/examples/demos/dnd.js
const DragAndDropCalendar = withDragAndDrop(Calendar)

//const availableViews = Object.keys(Views).map((k) => Views[k])
//const availableViews = ["month", "day"]

const DaySummary = (args) => {
  const eventsGroupedByCentre = useMemo(
    () => _.groupBy(args.events, "centreName"),
    [args]
  )

  return (
    <Stack direction="column" gap={2}>
      {Object.keys(eventsGroupedByCentre)
        .sort((a, b) => a.localeCompare(b))
        .map((centreName) => (
          <Box key={centreName}>
            <Typography variant="h6">{centreName}</Typography>
            <Stack direction="column" gap={1}>
              {eventsGroupedByCentre[centreName].map((event) => (
                <Card key={event.id}>
                  <CardHeader
                    title={
                      <Typography variant="body1">
                        <b>{event.workOrder?.label || event.title}</b>
                      </Typography>
                    }
                    subheader={
                      <Typography>
                        {event.supplier?.name || "No supplier specified"}
                      </Typography>
                    }
                    avatar={<StatusChip status={event.status} />}
                  />
                  <CardContent>
                    <Stack direction="column" gap={1}>
                      {event.jobs &&
                        event.jobs.map((job) => (
                          <Box key={job.id}>
                            <Stack direction="column">
                              <Typography variant="body2">
                                {job.category} {job.location}
                              </Typography>
                              <Typography variant="caption">
                                {job.description}
                              </Typography>
                            </Stack>
                          </Box>
                        ))}
                    </Stack>
                  </CardContent>
                  <CardActions>
                    <LinkButton to={event.openWorkOrderUrl}>
                      Open Work Order
                    </LinkButton>
                  </CardActions>
                </Card>
              ))}
            </Stack>
          </Box>
        ))}
    </Stack>
  )
}

DaySummary.range = (date) => {
  return null
}

DaySummary.navigate = (date, action) => {
  const m = moment(date, "DD-MM-YYYY")

  switch (action) {
    case navigate.PREVIOUS:
      m.add("days", -1)
      return m.toDate()

    case navigate.NEXT:
      m.add("days", 1)
      return m.toDate()

    default:
      return date
  }
}

DaySummary.title = (date, { localizer }) => {
  const str = moment(date).format("DD MMM YYYY")
  return str
}

// The stylesheet for this is in index.html in /public/index.html
const WorkOrderCalendarForm = (props) => {
  const history = useHistory()

  const [events, setEvents] = useState([])

  //const [dateCountSummary, setDateCountSummary] = useState([])

  const theme = useTheme()

  const { enqueueSnackbar } = useSnackbar()

  const [accountId, setAccountId] = useState()

  // share the same pagination state across calendar and work order grid pages,
  // so if the centre is changed on one page, it changes on the other page
  const pag = useSelector(selectWorkOrderGridPagination)

  // Filter by centre
  const [centreId, setCentreId] = useState(pag.centre_id)

  const [centreUserLoggedIn, setCentreUserLoggedIn] = useState()

  const workOrderLastModified = useSelector(selectWorkOrderLastModified)

  const dispatch = useDispatch()

  const [userCentreIds, setUserCentreIds] = useState(undefined)

  const [accountType, setAccountType] = useState(undefined)

  const [calendarView, setCalendarView] = useState("month")

  const { height, width } = useWindowDimensions()

  const [dateRange, setDateRange] = useState(
    useSelector(selectWorkOrderCalendarDateRange)
  )

  const [selectedDay, setSelectedDay] = useState(dateRange.start)

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setAccountId(token.claims.account_id)
          setAccountType(token.claims.account_type)
          setCentreUserLoggedIn(token.claims.account_type === "centre")
        })
      } else {
        setCentreUserLoggedIn(false)
      }
    })

    return unsub
  }, [])

  // Just initialise this state if if no work orders have been updated.
  //TODO: review if this could cause a stale cache, e.g. if a record has changed BEFORE this code is called -- does it get loaded by the listener
  useEffect(() => {
    if (!workOrderLastModified) {
      updateLastModifiedState(dataServices.localTimestamp())
    }
  }, [])

  useEffect(() => {
    let range

    if (!dateRange || !dateRange.hasOwnProperty("start")) {
      const m = moment(new Date())
      m.set("date", 1).startOf("day")

      const start = m.toDate()

      m.endOf("month").endOf("day")

      const end = m.toDate()

      range = { start, end }
    } else {
      range = dateRange
    }
    setDateRange(range)
  }, [])

  const getMonthRange = (month, year) => {
    const d = new Date(year, month, 1)
    const m = moment(d)

    const startDate = m.startOf("month").startOf("day").toDate()
    const endDate = m.endOf("month").endOf("day").toDate()

    const newRange = {
      start: startDate,
      end: endDate,
    }

    return newRange
  }

  const handleRangeChange = (range) => {
    // Sometimes there will be a few days from the previous month shown greyed out
    // at the start of the month calendar display.
    // Work out what the 1st day of the month shown in the calendar is and use that
    // as the range, so if we leave/return to the calendar we're showing that same month,
    // and not the previous month.

    if (range) {
      // What month does this range primarily cover?
      // There could be a few days at the beginning or end from the previous/next month.

      let monthRange

      const m = moment(range.start)

      if (range.start.getDate() === 1) {
        monthRange = getMonthRange(m.get("month"), m.get("year"))
      } else {
        const month = m.get("month") === 11 ? 0 : m.get("month") + 1
        const year = m.get("month") === 11 ? m.get("year") + 1 : m.get("year")
        monthRange = getMonthRange(month, year)
      }

      setDateRange(monthRange)
      dispatch(setWorkOrderCalendarDateRange(monthRange))
    }
  }

  const handleOnView = (view) => {
    setCalendarView(view)
  }

  // const getDayProperties = (date) => {
  //     const style = {
  //         height: "150px",
  //     }

  //     return {
  //         style: style,
  //     }
  // }

  const getEventProperties = (event, start, end, isSelected) => {
    const backgroundColor = WorkOrder.statusColors[event.status] || "#64dd17"

    // if (event.future) {
    //     const color = grey[800]
    //     const style = {
    //         backgroundColor: green[50],
    //         border: `1px dotted ${blue[500]}`,
    //         color: color,
    //         fontSize: "10px",
    //         height: event.details === "" ? "40px" : "60px",
    //     }
    //     return {
    //         style: style,
    //     }
    // } else {
    const color = theme.palette.getContrastText(backgroundColor)

    const style = {
      backgroundColor: backgroundColor,
      color: color,
      fontSize: "10px",
      height: event.details === "" ? "40px" : "60px",
    }
    return {
      style: style,
    }
  }

  const handleDragStart = (dragEvent) => {}

  const handleOnDragStart = async (onDragStartEvent) => {}

  const handleEventResize = async (resizeEvent) => {}

  const handleEventDrop = async (dropEvent) => {
    // See if work order has been changed
    const to = moment(dropEvent.start).startOf("day")
    const from = moment(dropEvent.event.start).startOf("day")
    const moved = to.toDate().getTime() !== from.toDate().getTime()

    if (moved) {
      const mergeValues = {
        start_date: dataServices.timestampFromDate(to.toDate()),
        modified: dataServices.serverTimestamp(),
      }

      // Check work order has mandatory values
      const wo = await db
        .collection("work_orders")
        .doc(dropEvent.event.id)
        .get()

      if (wo.exists) {
        await db
          .collection("work_orders")
          .doc(dropEvent.event.id)
          .update(mergeValues, { merge: true })

        updateLastModifiedState(dataServices.localTimestamp())
      }
    }
  }

  const updateLastModifiedState = (modifiedTs) => {
    const newState = { modified: modifiedTs.seconds }
    dispatch(setWorkOrderLastModified(newState))
  }

  // Load work orders

  useEffect(() => {
    loadWorkOrders(dateRange, accountId, workOrderLastModified)
  }, [dateRange, accountId, workOrderLastModified])

  // Lister for 'work_orders_calendar' changes

  const [calendarReloadState, setCalendarReloadState] = useState({})

  useEffect(() => {
    if (accountId === undefined) {
      return
    }

    if (!dateRange.start) {
      return
    }

    const newCalendarReloadState = {
      accountId,
      dateRange,
      workOrderLastModified,
    }
    const isCalendarReloadStateChanged = !_.isEqual(
      calendarReloadState,
      newCalendarReloadState
    )

    setCalendarReloadState(newCalendarReloadState)

    if (!isCalendarReloadStateChanged) {
      return
    }

    const slot = moment(dateRange.start).format("YYYY-MM")

    const queryMods = []

    let query = db
      .collection("work_orders_calendar")
      .where("account_id", "==", accountId)
      .where("slot", "==", slot)

    queryMods.push(`account_id == ${accountId}`)
    queryMods.push(`slot == ${slot}`)

    if (workOrderLastModified !== null && workOrderLastModified !== undefined) {
      query = query.where("modified", ">=", workOrderLastModified)
      queryMods.push(`modified > ${workOrderLastModified}`)
    }

    const unsub = query.onSnapshot(
      (querySnapshot) => {
        let newMaxModified = null

        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            // trigger refresh
            newMaxModified = change.doc.data().modified
          }
        })

        if (newMaxModified !== null) {
          dispatch(setWorkOrderLastModified(newMaxModified))
        }
      },
      (error) => console.error("error listening for calendar changes", error)
    )

    return unsub
  }, [accountId, dateRange, workOrderLastModified])

  // Listen for 'work_orders' changes

  useEffect(() => {
    if (accountId === undefined) {
      return
    }

    // dateRange is empty, return
    if (!dateRange.start) {
      return
    }

    const queryMods = []

    const filterDate =
      workOrderLastModified === null || workOrderLastModified === undefined
        ? new Date()
        : workOrderLastModified

    let query = db
      .collection("work_orders")
      .where("modified", ">=", filterDate)
      .orderBy("modified", "desc")

    queryMods.push(`modified > ${filterDate}`)

    //queryMods.push(`modified > ${dataServices.timestampFromDate(listenAfter)}`)

    let unsub

    getAuth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        switch (token.claims.account_type) {
          case "centre":
            query = query.where("account_id", "==", token.claims.account_id)
            queryMods.push(`account_id == ${token.claims.account_id}`)
            break

          case "supplier":
            query = query.where(
              "supplier_access_account_ids",
              "array-contains",
              token.claims.account_id
            )
            queryMods.push(
              `supplier_access_account_ids array-contains ${token.claims.account_id}`
            )
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        let newMaxModified = null
        unsub = query.onSnapshot(
          (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
              if (
                newMaxModified === null ||
                change.doc.data().modified.seconds > newMaxModified.seconds
              ) {
                // trigger refresh
                newMaxModified = change.doc.data().modified
              }
            })

            if (newMaxModified !== null) {
              dispatch(setWorkOrderLastModified(newMaxModified))
            }
          },
          (error) =>
            console.error("error listening for celendar changes", error)
        )
      })
    return unsub
  }, [accountId, dateRange])

  // 'calEvent' is a calendar data event item, not a javascript event
  const handleSelectEvent = async (event) => {
    history.push(
      workOrderServices.getOpenWorkOrderUrl({
        accountType: accountType,
        workOrderId: event.id,
      })
    )
  }

  // Load work orders

  const [reloadState, setReloadState] = useState({})

  const loadWorkOrders = async (range, accountId, workOrderLastModified) => {
    if (accountId === null || accountId === undefined) {
      return
    }

    if (!range.start) {
      return
    }

    if (!getAuth().currentUser) {
      return
    }

    if (!workOrderLastModified) {
      return
    }

    const newReloadState = { range, accountId, workOrderLastModified }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    setReloadState(newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    getAuth()
      .currentUser.getIdTokenResult()
      .then(async (token) => {
        let query = db
          .collection("work_orders")
          .where(
            "start_date",
            ">=",
            dataServices.timestampFromDate(range.start)
          )
          .where("start_date", "<=", dataServices.timestampFromDate(range.end))

        switch (token.claims.account_type) {
          case "centre":
            query = query.where("account_id", "==", token.claims.account_id)
            break

          case "supplier":
            query = query.where(
              "supplier_access_account_ids",
              "array-contains",
              token.claims.account_id
            )
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        const workOrders = await dataServices.loadData("from calendar", query)
        const workOrderEvents = workOrders.map((workOrder) => {
          const startDate = moment(new Date(workOrder.start_date.toMillis()))
            .startOf("day")
            .toDate()

          const event = {
            id: workOrder.id,
            title: `${workOrder.work_order_no}/${workOrder.label}`,
            summary: `${workOrder.work_order_no}`,
            details: undefined,
            status: workOrder.status,
            start: startDate,
            end: startDate,
            scheduled:
              workOrder.schedule && workOrder.schedule.type ? true : false,

            // This is a real work order, not a future scheduled run date
            future: false,
          }

          return event
        })

        // Now load the calculated events on when recurring work orders will occur.
        // These exist in the 'work_orders_calendar' collection

        const slotsQuery = db
          .collection("work_orders_calendar")
          .where("slot", "==", moment(dateRange.start).format("YYYY-MM"))
          .where("account_id", "==", accountId)

        const slotsResult = await dataServices.loadData(
          "from calendar",
          slotsQuery
        )

        const recurringEvents = slotsResult.map((slot) => {
          return slot.dates.map((startDate) => {
            const d = dateServices.tsToDate(startDate)

            moment.tz.setDefault("Etc/GMT-10")

            const event = {
              id: slot.work_order_id,
              title: `Recurring`,
              summary: "...",
              details: undefined,
              status: WorkOrder.STATUS_SCHEDULED,
              start: dateServices.tsToDate(startDate),
              end: dateServices.tsToDate(startDate),
              scheduled: true,
              future: true,
            }

            return event
          })
        })

        const flattenedRecurringEvents = _.flatten(recurringEvents)

        const allEvents = [...workOrderEvents, ...flattenedRecurringEvents]

        const workOrderIds = Array.from(
          new Set(allEvents.map((event) => event.id))
        )

        const wos = await dataServices.getWorkOrdersByIdChunks({
          workOrderIds,
          accountId,
        })

        // Get suppliers for work orders
        const supplierIds = Array.from(
          new Set(wos.map((wo) => wo.supplier_id).filter((id) => id))
        )

        const suppliers = await dataServices.getSuppliersByIdChunks(
          supplierIds,
          accountId
        )

        const jobs = await dataServices.getJobsByWorkOrderIds(
          accountId,
          workOrderIds
        )

        const centres = await dataServices.getCentresByIdChunks(
          jobs.map((j) => j.centre_id).filter((id) => id !== undefined)
        )

        // Group jobs by work order, so we can get a summary of jobs for each work order
        const jobsGroupedByWorkOrderId = _.groupBy(jobs, "work_order_id")

        const allEventsWithJobSummary = allEvents.map((event) => {
          const workOrderJobs = jobsGroupedByWorkOrderId[event.id]

          const workOrder = wos.find((wo) => wo.id === event.id)

          if (workOrderJobs) {
            return {
              ...event,
              workOrder: workOrder,
              supplier: suppliers.find((s) => s.id === workOrder.supplier_id),
              openWorkOrderUrl: workOrderServices.getOpenWorkOrderUrl({
                accountType: accountType,
                workOrderId: event.id,
              }),
              centreName:
                workOrderJobs?.length > 0 &&
                centres.find((c) => c.id === workOrderJobs[0].centre_id)?.name,
              jobs: workOrderJobs,
              details: workOrderJobs
                .map((job) =>
                  `${job.category} ${job.location}`.replace(/\s{2,}/g, " ")
                )
                .join(", "),
            }
          } else {
            return {
              ...event,
              workOrder: workOrder,
              supplier: suppliers.find((s) => s.id === workOrder.supplier_id),
              openWorkOrderUrl: workOrderServices.getOpenWorkOrderUrl({
                accountType: accountType,
                workOrderId: event.id,
              }),
              centreName: "No centre",
              details: "No jobs",
            }
          }
        })

        setEvents(allEventsWithJobSummary)
      })
  }

  const handleSelectSlot = (slotInfo) => {}

  const handleNavigate = (date) => {
    setSelectedDay(date)
  }

  const getFilteredEvents = (calendarView, selectedDay, dateRange) => {
    switch (calendarView) {
      case "month":
        return events

      case "daysummary":
        // Get only events in the 'selectedDay'

        const startOfDaySeconds = moment(selectedDay)
          .startOf("day")
          .toDate()
          .getTime()
        const endOfDaySeconds = moment(selectedDay)
          .endOf("day")
          .toDate()
          .getTime()

        return events.filter(
          (event) =>
            event.start.getTime() >= startOfDaySeconds &&
            event.start.getTime() <= endOfDaySeconds
        )

      default:
        console.log("Unknown view", calendarView)
        return {}
    }
  }

  const handleCentreChange = (centreId) => {}

  const DayHeader = (props) => {
    const { label } = props

    return <div>{label}</div>
  }

  const DateHeader = (props) => {
    const { label, date } = props

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setSelectedDay(date)
          setCalendarView("daysummary")
        }}
      >
        {label}
      </div>
    )
  }

  // const MonthEvent = (props) => {
  //     console.log("MonthEvent", props)

  //     const { label } = props

  //     return <div>{label}</div>
  // }

  // const DayWrapper = (props) => {
  //     console.log("DayWrapper", props)

  //     const { children } = props

  //     return children
  // }

  // const DateCellWrapper = (props) => {
  //     console.log("DateCellWrapper", props)

  //     const { children } = props
  //     const style = {
  //         height: "40px",
  //     }

  //     return children
  // }

  const Event = ({ event }) => {
    // const handleMouseEnter = (event) => {
    //     console.log("mouseOver", { event })

    //     event.label = event.label + "."

    //     setEvents(events.map(ev => ev.id === event.id ? event : ev))
    // }

    // const handleMouseLeave = () => {}

    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Box style={{ display: "flex" }}>
          <Typography style={{ flexGrow: 1 }} variant="caption">
            {event.title}
          </Typography>
          {event.scheduled && (
            <AutoRenewIcon
              style={{
                marginTop: "1px",
                fontSize: 15,
                justifyContent: "flex-end",
              }}
            />
          )}
        </Box>
        {/* {!event.future && (
                    <Box>
                        <Typography variant='caption'>{event.summary}</Typography>
                    </Box>
                )} */}
        <Box>
          <Typography variant="caption">{event.details}</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={11} sm={4}>
          {userCentreIds && (
            <Controls.CentreCombobox
              name="centre_id"
              label="Centre"
              value={centreId}
              accountId={accountId}
              readonly={false}
              onChange={handleCentreChange}
              centreIdsFilter={userCentreIds}
            />
          )}
        </Grid>
      </Grid>

      <DragAndDropCalendar
        localizer={localizer}
        defaultDate={dateRange.start}
        view={calendarView}
        date={selectedDay}
        components={{
          //dayWrapper: DayWrapper,
          //dateCellWrapper: DateCellWrapper,
          event: Event,
          day: {
            header: DayHeader,
          },
          month: {
            //header: MonthHeader,
            //event: MonthEvent,
            dateHeader: DateHeader,
          },
        }}
        events={getFilteredEvents(calendarView, selectedDay, dateRange)}
        views={{
          month: true,
          daysummary: DaySummary,
        }}
        messages={{ daysummary: "Day" }}
        drilldownView={"daysummary"}
        startAccessor="start"
        endAccessor="end"
        //dayPropGetter={getDayProperties}
        eventPropGetter={getEventProperties}
        onSelectEvent={(calEvent) => handleSelectEvent(calEvent)}
        onSelectSlot={handleSelectSlot}
        onNavigate={handleNavigate}
        onRangeChange={(range) => handleRangeChange(range)}
        onView={(view) => handleOnView(view)}
        onDragStart={handleOnDragStart}
        handleDragStart={handleDragStart}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        resizable={false}
        style={{ height: height - 100, width: width - 40, paddingLeft: "5px" }}
      />
    </>
  )
}

export default WorkOrderCalendarForm
