import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material"
import { useMemo, useState } from "react"
import ActionDialog from "./ActionDialog"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import ReactTimeAgo from "react-time-ago"
import * as dateServices from "../pages/services/dateServices"
import * as actionServices from "../pages/services/actionServices"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import DoneIcon from "@mui/icons-material/Done"
import YesNo from "./YesNo"
import ActionStatusChip from "./controls/ActionStatusChip"
import TruncatedText from "./TruncatedText"

const ActionPanel = ({ action, currentUser, isJobAdmin }) => {
  const [isEdit, setEdit] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete Action",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const handlePromptDeleteAction = () => {
    setYesNoConfig((curr) => ({
      ...curr,
      openPrompt: true,
      handleConfirm: handleDeleteAction,
    }))
  }

  const createdDate = useMemo(() => {
    if (action) {
      return action.created
    }
  }, [action])

  const handleMarkAsCompleted = () => {
    actionServices.updateActionStatus(
      action.id,
      action,
      actionServices.STATUS_COMPLETED
    )
  }

  const handleDeleteAction = () => {
    setYesNoConfig((curr) => ({
      ...curr,
      openPrompt: false,
    }))

    actionServices.deleteAction(action.id)
  }

  return (
    <>
      <YesNo config={yesNoConfig} />

      {isEdit && (
        <ActionDialog
          id={action.id}
          parentId={action.parent_id}
          parentType={action.type}
          open={isEdit}
          setOpen={setEdit}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {action.status !== actionServices.STATUS_COMPLETED &&
          action.assigned_to === currentUser.email && (
            <Tooltip title="Mark as completed and send back to creator">
              <MenuItem
                onClick={() => {
                  setAnchorEl(null)
                  handleMarkAsCompleted()
                }}
              >
                <ListItemIcon>
                  <DoneIcon fontSize="small" />
                </ListItemIcon>
                Mark as Completed
              </MenuItem>
            </Tooltip>
          )}

        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            setEdit(true)
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>
        {(action.created_by === currentUser.email || isJobAdmin) && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              handlePromptDeleteAction()
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
        )}
      </Menu>

      <Card
        sx={{ padding: "5px", maxWidth: "300px", cursor: "hand" }}
        onClick={(e) => {
          e.stopPropagation()
          setEdit(true)
        }}
      >
        <CardHeader
          title={
            <TruncatedText
              variant="body1"
              noWrap
              sx={{ fontWeight: "bold", marginBottom: "5px", width: "220px" }}
            >
              {action.description}
            </TruncatedText>
          }
          subheader={
            <ActionStatusChip
              status={action.status}
              showBadge={actionServices.isShowActionBadge(
                action,
                currentUser.email,
                isJobAdmin
              )}
            />
          }
          action={
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                setAnchorEl(e.currentTarget)
              }}
            >
              <MoreVertIcon />
            </IconButton>
          }
        />

        <CardActionArea onClick={() => setEdit(true)}>
          <CardContent>
            <ActionField label="Assigned" value={action.assigned_to} />
            {createdDate && (
              <ActionField
                label="Created"
                value={
                  <ReactTimeAgo
                    date={dateServices.parseDate(createdDate)}
                    locale="en-AU"
                  />
                }
              />
            )}
            <ActionField label="By" value={action.created_by} />
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

const ActionField = ({ label, value }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ minWidth: "55px" }}
      >
        {label}
      </Typography>
      <Tooltip title={value}>
        <TruncatedText
          variant="body2"
          color="text.primary"
          sx={{ Width: "150px" }}
        >
          {value}
        </TruncatedText>
      </Tooltip>
    </Box>
  )
}

export default ActionPanel
