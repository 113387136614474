import * as actions from "../actionTypes"

// initial state is no items selected
// each item is:
// {
//    id: account document id
//    name: account name
// }

const initialState = {
  value: [],
}

export default function accountGridSelectedItems(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ACCOUNT_GRID_SELECTED_ITEMS: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
