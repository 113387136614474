import moment from "moment"

const WEEKLY = "W"
const DAY_OF_MONTH = "DOM"
const WEEK_OF_MONTH = "WOM"
const EVERY_X_DAYS = "DAYS"

const dayNames = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
}

const getNumberSuffix = (num) => {
  // All suffixes up to 31 that are NOT 'th', e.g. 4th, 5th, 28th, etc.
  const numberSuffixes = {
    1: "st",
    2: "nd",
    3: "rd",
    21: "st",
    22: "nd",
    23: "rd",
    31: "st",
  }

  const suffix = numberSuffixes[num]
  return suffix ? suffix : "th"
}

const calculateNextScheduleDate = (schedule, fromDate) => {
  if (!schedule) {
    return undefined
  }

  let result = undefined

  switch (schedule.type) {
    case EVERY_X_DAYS:
      result = calculateNextXDaysDate(schedule, fromDate)
      break

    case WEEKLY:
      result = calculateWeeklyNextDate(schedule, fromDate)
      break

    case DAY_OF_MONTH:
      result = calculateDayOfMonthNextDate(schedule, fromDate)
      break

    case WEEK_OF_MONTH:
      result = calculateWeekOfMonthNextDate(schedule, fromDate)
      break

    default:
    // no op
  }

  return result
}

const getScheduleDescription = (schedule) => {
  if (!schedule) {
    return ""
  }

  switch (schedule.type) {
    case EVERY_X_DAYS:
      return `Every ${schedule.days} days`

    case WEEKLY:
      //{"day":1,"type":"W"}
      return `Every ${dayNames[schedule.day]}`

    case DAY_OF_MONTH:
      // {"day_of_month":30,"type":"DOM"}
      return `${schedule.day_of_month}${getNumberSuffix(
        schedule.day_of_month
      )} of each month`

    case WEEK_OF_MONTH:
      // {"day":1,"week":4,"type":"WOM"}
      return `${schedule.week}${getNumberSuffix(schedule.week)} ${
        dayNames[schedule.day]
      } of each month`

    default:
      return ""
  }
}

const calculateDayOfMonthNextDate = (schedule, fromDate) => {
  if (schedule.day_of_month === "" || schedule.day_of_month === undefined) {
    return null
  }

  const m = moment(fromDate)

  if (m.date() > schedule.day_of_month) {
    // Put into next month
    m.add(1, "M")
  }
  m.set("date", schedule.day_of_month)

  return m.toDate()
}

const calculateNextRecurringDates = (startDate, schedule, months) => {
  if (schedule) {
    const dates = []

    // Calculate last date on which we can schedule dates
    const defaultEndSchedulableDate = moment(startDate)
      .add(months, "months")
      .endOf("month")
      .toDate()

    // Actual end date past which we do not schedule work orders
    const endDate = schedule.end_date || defaultEndSchedulableDate

    let refDate = moment(startDate).toDate()

    let sanity = 30

    while (true) {
      sanity = sanity - 1
      if (sanity < 0) {
        break
      }
      const scheduleDate = calculateNextScheduleDate(schedule, refDate)

      if (scheduleDate === undefined) {
        break
      }

      // Break out if the scheduled date is past our end date
      if (scheduleDate.getTime() > endDate.getTime()) {
        break
      }

      dates.push(scheduleDate)

      // Advance date before we calculate the next date
      // If we don't advance the refDate then we can end up with the same
      // date being scheduled multiple times.
      if (schedule.type === EVERY_X_DAYS) {
        refDate = moment(scheduleDate).add(1, "minute").toDate()
      } else {
        refDate = moment(scheduleDate).add(1, "day").toDate()
      }
    }

    return dates.map((date) => {
      const m = moment(date)

      // Obtaining the utcOffset each time automatically handles the case
      // where Sydney can be GMT+10 or GMT+11 (Daylight savings)
      // UTC offset is in minutes

      // TODO: if the date is at 0 hour, then just applying the utcOffset is correct in this case
      m.set("hour", m.utcOffset() / 60)
      console.error(
        "TODO: validate that this change above is correct, to *add* rather than *set* the UTC offset"
      )

      return m.toDate()
    })
  }
  return undefined
}

const calculateNextXDaysDate = (schedule, fromDate) => {
  if (schedule.days === "" || schedule.days === undefined) {
    return null
  }

  const m = moment(fromDate)

  // Add 'x' days
  m.add(schedule.days, "days")

  return m.toDate()
}

const calculateWeeklyNextDate = (schedule, fromDate) => {
  if (schedule.day === "") {
    return null
  }

  const m = moment(fromDate)

  // Calculate in how many days time the selected day number is
  let daysAhead = schedule.day - m.day()

  if (daysAhead < 0) {
    daysAhead = daysAhead + 7
  }

  m.add(daysAhead, "days")

  return m.toDate()
}

// 'refDate' is just some date in the month where we want to find the n'th specified day
const getNthDayInMonth = (refDate, schedule) => {
  const m = moment(refDate)

  m.set("date", 1)

  // To get to the specified week, e.g. week 2, we need to add (2 - 1) weeks
  const advanceToWeek = (schedule.week - 1) * 7
  m.add(advanceToWeek, "days")

  // Then we need to find the prior Sunday (start of week), and advance to the requested day.
  let daysAhead = schedule.day - m.day()
  if (daysAhead < 0) {
    daysAhead = daysAhead + 7
  }

  m.add(daysAhead, "days")

  return m
}

const calculateWeekOfMonthNextDate = (schedule, fromDate) => {
  if (schedule.day === "" || schedule.week === "") {
    return null
  }

  let nthDay = getNthDayInMonth(fromDate, schedule)

  // If that date is in the past, we need to add 1 month
  if (nthDay.isBefore(fromDate)) {
    nthDay.endOf("month").startOf("day")
    nthDay.add(1, "days")

    nthDay = getNthDayInMonth(nthDay.toDate(), schedule)
  }

  return nthDay.toDate()
}

export {
  calculateNextScheduleDate,
  getScheduleDescription,
  calculateNextRecurringDates,
  WEEKLY,
  DAY_OF_MONTH,
  WEEK_OF_MONTH,
  EVERY_X_DAYS,
}
