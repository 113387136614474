import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { lighten } from "@mui/material/styles"
import { styled } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import QuoteTile from "./QuoteTile"
import {
  Typography,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material"
import firebase from "firebase/compat/app"
import { useDispatch, useSelector } from "react-redux"
import { setQuoteGridPagination } from "../redux/actions"
import { selectQuoteGridPageDocs } from "../redux/selectors"
import { selectQuoteGridPagination } from "../redux/selectors"
import { setQuoteGridPageDocs } from "../redux/actions"
import Controls from "./controls/Controls"
import _ from "lodash"
import LinkButton from "./controls/LinkButton"
import { spacing } from "../pages/services/styleServices"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import YesNo from "./YesNo"
import * as quoteServices from "../pages/services/quoteServices"
import * as supplierServices from "../pages/services/supplierServices"
import * as workOrderServices from "../pages/services/workOrderServices"
import * as Roles from "../pages/services/roleServices"
import MultiSelectLabel from "./controls/MultiSelectLabel"
import PageNo from "./PageNo"
import NavButtons from "./NavButtons"
import QuoteDialog from "./QuoteDialog"
import { Desktop, Mobile } from "./WindowSizes"
import FilterItems from "./FilterItems"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const headCells = [
  {
    id: "supplier",
    numeric: false,
    disablePadding: true,
    label: "Supplier",
    sortable: false,
  },
  {
    id: "preferred",
    numeric: false,
    disablePadding: true,
    label: "Preferred",
    sortable: false,
  },
  {
    id: "work_order",
    numeric: false,
    disablePadding: false,
    label: "Work Order",
    sortable: false,
  },
  {
    id: "centre",
    numeric: false,
    disablePadding: true,
    label: "Centre(s)",
    sortable: false,
  },
  {
    id: "total",
    numeric: false,
    disablePadding: true,
    label: "Total",
    sortable: false,
  },
  {
    id: "terms",
    numeric: false,
    disablePadding: true,
    label: "Terms",
    sortable: false,
  },
  {
    id: "sent",
    numeric: false,
    disablePadding: true,
    label: "Sent",
    sortable: false,
  },
  {
    id: "received",
    numeric: false,
    disablePadding: true,
    label: "Received",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: true,
    label: "Created",
    sortable: false,
  },
  {
    id: "modified",
    numeric: false,
    disablePadding: true,
    label: "Modified",
    sortable: false,
  },
]

const QuoteGrid = (props) => {
  const { order, orderBy, onRequestSort, userAccountType } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  // If user account type is 'supplier' then filter out the 'preferred' column
  const filteredHeadCells = useMemo(() => {
    if (userAccountType !== "centre") {
      return headCells.filter(
        (cell) => !["preferred", "supplier"].includes(cell.id)
      )
    }

    return headCells
  }, [userAccountType])

  return (
    <TableHead>
      <TableRow>
        {filteredHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order : headCell.direction[0]
                }
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledToolbar = styled(Toolbar)(({ numselected, theme }) => {
  const result = {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  }

  if (numselected > 0) {
    result.color = theme.palette.secondary.main
    result.backgroundColor = lighten(theme.palette.secondary.light, 0.85)
  }

  return result
})

QuoteGrid.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props

  return <StyledToolbar numselected={numSelected}></StyledToolbar>
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
  },
  quoteGrid: {
    width: "100%",
    marginBottom: spacing(1),
    paddingLeft: spacing(0.5),
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    "&:hover": {
      cursor: "hand",
    },
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = useState(true)

  const pag = useSelector(selectQuoteGridPagination)

  const dispatch = useDispatch()

  const [rowsPerPage, setRowsPerPage] = useState(20)

  const [quotes, setQuotes] = useState([])

  const [userCentreIds, setUserCentreIds] = useState([])

  const [centres, setCentres] = useState([])

  const [suppliers, setSuppliers] = useState([])

  const [centreId, setCentreId] = useState()

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete Quote",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const [isEdit, setEdit] = useState(false)

  const [userEmail, setUserEmail] = useState()

  const [isSupplierViewing, setSupplierViewing] = useState(true)

  const [selectedQuoteId, setSelectedQuoteId] = useState()

  // Last time loaded. Default to seconds relative to UTC
  const [loadCount, setLoadCount] = useState(0)

  const [userAccountType, setUserAccountType] = useState()

  const pgDocs = useSelector(selectQuoteGridPageDocs)

  //const [maxModified, setMaxModified] = useState()

  const [accountId, setAccountId] = useState()

  const [claims, setClaims] = useState()

  const updateUserCentreIdsIfChanged = (newUserCentreIds) => {
    if (!_.isEqual(newUserCentreIds.sort(), userCentreIds.sort())) {
      setUserCentreIds(newUserCentreIds)
    }
  }

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setClaims(token.claims)
          setAccountId(token.claims.account_id)
          setUserAccountType(token.claims.account_type)
          setSupplierViewing(token.claims.account_type === "supplier")
          setUserEmail(token.claims.email)

          if (token.claims.roles.includes(Roles.JOB_ADMIN)) {
            updateUserCentreIdsIfChanged([]) // all centres
          } else {
            updateUserCentreIdsIfChanged(token.claims.centre_ids)

            if (token.claims.centre_ids.length === 1) {
              handleChangeCentreFilter(token.claims.centre_ids[0])
            }
          }
        })
      }
    })

    return unsub
  }, [])

  // Listen for changes

  useEffect(() => {
    if (claims === undefined) {
      return
    }

    const whereClauseLog = []

    let query = db.collectionGroup("quotes")

    whereClauseLog.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    switch (claims.account_type) {
      case "centre":
        query = query.where("account_id", "==", claims.account_id)
        whereClauseLog.push("account_id == " + claims.account_id)
        break

      case "supplier":
        query = query.where("supplier_account_id", "==", claims.account_id)
        whereClauseLog.push("supplier_account_id == " + claims.account_id)
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    query = query.where("modified", ">=", dataServices.localTimestamp())
    query = query.orderBy("modified", "asc")
    query = query.limit(20)

    const unsub = query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          setLoadCount((curr) => curr + 1)
        })
      },
      (error) => console.error("error listening for quote changes", error)
    )

    return unsub
  }, [claims])

  const updatePageDocs = () => {
    if (quotes.length > 0 && direction !== "prev") {
      const newPageDocs = [...pgDocs]

      const newPageDoc = {
        first: quotes[0].doc,
        last: quotes[quotes.length - 1].doc,
      }

      newPageDocs[pag.page] = newPageDoc
      dispatch(setQuoteGridPageDocs(newPageDocs))
    }
  }

  useEffect(() => {
    updatePageDocs(quotes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotes])

  // Try to load the work orders. Some work orders may not exist. Split the ids into groups of 10 and load them in chunks
  const loadWorkOrders = async ({ workOrderIds }) => {
    const idChunks = _.chunk(workOrderIds, 10)

    const promises = idChunks.flatMap(async (idChunk) => {
      const workOrderPromises = idChunk.flatMap(async (id) => {
        return db
          .collection("work_orders")
          .doc(id)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              return { id: doc.id, missing: true }
            } else {
              return { id: doc.id, ...doc.data() }
            }
          })
          .catch((err) => {
            console.error("Error fetching work order with ID:", id, err)
            return { id, error: true } // or however you want to represent this error state
          })
      })

      try {
        return await Promise.all(workOrderPromises)
      } catch (err) {
        console.error("Error getting work orders", err)
      }
    })

    const result = (await Promise.all(promises)).flat()
    const resultWithoutErrors = result.filter((workOrder) => !workOrder.error)
    return resultWithoutErrors
  }

  // Load quotes

  const [reloadState, setReloadState] = useState({})

  useEffect(() => {
    const logId = "[QUOTE GRID > LOAD QUOTES]"

    if (accountId === undefined) {
      return
    }

    const newReloadState = {
      accountId,
      lastLoaded: loadCount,
      pag,
    }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    setReloadState(newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    const queryMods = []

    let query = db.collectionGroup("quotes")

    // Get current page of quotes

    query = query.orderBy(pag.orderBy, pag.order)

    queryMods.push("order by " + pag.orderBy + " " + pag.order)
    query = query.orderBy(firebase.firestore.FieldPath.documentId(), pag.order)
    queryMods.push("order by doc id " + pag.order)

    if (pag.page > 0 && direction !== "prev") {
      // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
      // But if not found, it just means this is the first time we've clicked Next through the collection
      if (pgDocs[pag.page - 1]) {
        const lastDoc = pgDocs[pag.page - 1].last
        query = query.startAfter(lastDoc)
        queryMods.push("start after last doc on previous page " + lastDoc.id)
      }
    } else if (direction === "prev") {
      if (!pgDocs[pag.page]) {
        console.error("Can't find pagedocs for page", pag.page)
      }
      query = query.startAt(pgDocs[pag.page].first)
      queryMods.push("start at 1st doc on page " + pgDocs[pag.page].first.id)
    }

    switch (userAccountType) {
      case "centre":
        query = query.where("account_id", "==", accountId)
        queryMods.push(`account_id == ${accountId}`)
        break

      case "supplier":
        // For quotes, only 1 external supplier can ever have access, e.g. each supplier gets their own quote
        query = query.where("supplier_account_id", "==", accountId)
        queryMods.push(`supplier_account_id == ${accountId}`)
        break

      default:
        throw new Error("Unknown account type " + userAccountType)
    }

    if (pag.centreId !== "") {
      query = query.where("centre_ids", "array-contains", pag.centreId)
      queryMods.push(`centre_ids array-contains ${pag.centreId}`)
    }

    query = query.limit(rowsPerPage)

    dataServices
      .loadData("(Load quote grid)", query, false)
      .then(async (quotes) => {
        // Get parent data for each quote

        const workOrderIds = quotes.reduce((acc, quote) => {
          if (!acc.includes(quote.work_order_id)) {
            acc.push(quote.work_order_id)
          }
          return acc
        }, [])

        let workOrders = []

        if (userAccountType === "centre") {
          workOrders = await loadWorkOrders({ workOrderIds })
        } else {
          workOrders = await loadWorkOrders({ workOrderIds })
        }

        const quotesWithWorkOrders = quotes.map((quote) => {
          const workOrder = workOrders.find(
            (wo) => wo.id === quote.work_order_id
          )
          if (workOrder) {
            return {
              ...quote,
              workOrderNo: workOrder.work_order_no,
              workOrderPath: workOrderServices.getOpenWorkOrderUrl({
                accountType: userAccountType,
                workOrderId: workOrder.id,
              }),
              _work_order: workOrder,
            }
          }

          return quote
        })

        const centreIds = _.uniq(
          quotesWithWorkOrders.flatMap((quote) => quote.centre_ids)
        ).filter((id) => id)

        const missingCentreIds = centreIds.filter(
          (id) => centres.find((centre) => centre.id === id) === undefined
        )

        try {
          dataServices
            .getCentresByIdChunks(missingCentreIds)
            .then((centreInfo) =>
              setCentres((curr) => [...curr, ...centreInfo])
            )
        } catch (err) {
          console.error("Error getting centres", err)
        }

        try {
          const supplierIds = _.uniq(
            quotesWithWorkOrders.map((quote) => quote.supplier_id)
          ).filter((id) => id)

          dataServices
            .getSuppliersByIdChunks(supplierIds, accountId)
            .then((suppliers) => {
              setSuppliers(suppliers)
            })
        } catch (err) {
          console.error("Error getting suppliers", err)
        }

        setQuotes(quotesWithWorkOrders)
      })
      .then(updatePageDocs())
      .catch((err) => console.error(`${logId} Error loading quotes`, err))
  }, [accountId, pag, loadCount, direction, pgDocs, userAccountType])

  const handleCentreChange = (event) => {
    // if we click the 'x' icon in the drop down we get null instead of '' for the event.target.value
    const value = event.target.value === null ? "" : event.target.value

    handleChangeCentreFilter(value)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = pag.orderBy === property && pag.order === "asc"

    const sortCol = headCells.find((cell) => cell.id === property)
    const sortSameCol = pag.orderBy === property

    // If we clicked on the same column to sort it, then find the other sort order for that column (there will only be
    // 1 or 2 array entries, being 'asc' or 'desc' in some order),
    // Otherwise it means we're sorting on a new column so get the 1st sort order from the 'direction' attribute, which
    // could be either 'asc' or 'desc'.
    const sortOrder = sortSameCol
      ? sortCol.direction.find((dir) => dir !== pag.order) || pag.order
      : sortCol.direction[0]

    const updatedPagination = {
      ...pag,
      page: 0,
      order: sortOrder,
      orderBy: property,
    }

    dispatch(setQuoteGridPagination(updatedPagination))
    dispatch(setQuoteGridPageDocs([]))
    setDirection("")
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }

      dispatch(setQuoteGridPagination(updatedPagination))
    }
  }

  const handleDeleteConfirmed = (workOrderId, quoteId) => {
    const newPromptConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newPromptConfig)

    quoteServices.deleteQuote(workOrderId, quoteId).then(() => {
      try {
        supplierServices.updateSupplierAccess({
          workOrderId: workOrderId,
          source: "Quote grid",
        })
      } catch (err) {
        console.err(
          "Error updating supplier access - work order may not exist possibly",
          err
        )
      }
    })
  }

  const handlePromptConfirmDelete = (event, workOrderId, quoteId) => {
    event.preventDefault()

    const newPromptConfig = {
      ...yesNoConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteConfirmed(workOrderId, quoteId),
    }
    setYesNoConfig(newPromptConfig)
  }

  const handleSaveQuote = (quote) => {
    quoteServices.handleUpdateQuote({ quote, userAccountType, userEmail })

    setEdit(false)
  }

  const handleEditQuote = (quoteId) => {
    setSelectedQuoteId(quoteId)
    setEdit(true)
  }

  const handleChangeCentreFilter = (centreId) => {
    // Check if the centre changed
    if (centreId === pag.centreId) {
      return
    }

    setCentreId(centreId)

    const updatedPagination = {
      ...pag,
      page: 0,
      centreId: centreId,
    }

    setDirection("")
    dispatch(setQuoteGridPageDocs([]))
    dispatch(setQuoteGridPagination(updatedPagination))
  }

  return (
    <>
      {isEdit && (
        <QuoteDialog
          open={isEdit}
          onClose={setEdit}
          quote={quotes.find((quote) => quote.id === selectedQuoteId)}
          accountId={accountId}
          handleSaveQuote={handleSaveQuote}
          //handleSendRFQEmailWithoutPreview={handleSendRFQEmailWithoutPreview}
          jobTypes={[]}
        />
      )}

      {yesNoConfig && <YesNo config={yesNoConfig} />}

      {userAccountType === "centre" && (
        <FilterItems>
          <Box sx={{ width: "200px" }}>
            <Controls.CentreCombobox
              name="centre_id"
              label={
                <MultiSelectLabel
                  count={userCentreIds.length}
                  oneSelectedLabel="Centre"
                  multiSelectedLabel={`Centre (limit ${userCentreIds.length})`}
                />
              }
              value={centreId}
              accountId={accountId}
              readonly={false}
              onChange={handleCentreChange}
              centreIdsFilter={userCentreIds}
            />
          </Box>
        </FilterItems>
      )}

      <Box>
        <Mobile width={675}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {centres &&
              quotes &&
              quotes.map((quote) => (
                <QuoteTile
                  key={quote.id}
                  quote={quote}
                  isEditable={false}
                  suppliers={suppliers}
                  centres={centres}
                  isSupplierViewing={isSupplierViewing}
                  handleEditQuote={() => {
                    handleEditQuote(quote.id)
                  }}
                />
              ))}
          </Box>
        </Mobile>
      </Box>

      <Box sx={styles.root}>
        <Box sx={{ marginTop: "20px" }}>
          <Desktop width={675}>
            <Box sx={styles.quoteGrid}>
              {/* <EnhancedTableToolbar numSelected={0} /> */}
              <TableContainer>
                <Table
                  sx={styles.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="Quotes"
                >
                  <QuoteGrid
                    order={pag.order}
                    orderBy={pag.orderBy}
                    onRequestSort={handleRequestSort}
                    userAccountType={userAccountType}
                  />
                  <TableBody>
                    {quotes.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={false}
                          tabIndex={-1}
                          key={row.id}
                          selected={false}
                          onClick={(event) => {
                            // prevent opening the quote if we've just clicked on the morevert row menu
                            if (event.target.localName === "svg") return

                            setSelectedQuoteId(row.id)
                            setEdit(true)
                          }}
                          sx={styles.tableRow}
                        >
                          {userAccountType === "centre" && (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {suppliers &&
                                suppliers.find((s) => s.id === row.supplier_id)
                                  ?.name}
                            </TableCell>
                          )}

                          {/* Don't show suppliers if they're 'preferred' or not, only show centre users */}
                          {userAccountType === "centre" && (
                            <TableCell align="left" padding="none">
                              {row.preferred ? "Y" : "N"}
                            </TableCell>
                          )}

                          <TableCell
                            align="left"
                            padding="none"
                            sx={{ maxWidth: "50px" }}
                          >
                            {row.workOrderPath && (
                              <LinkButton to={row.workOrderPath}>
                                {row.workOrderNo}
                              </LinkButton>
                            )}
                          </TableCell>
                          <TableCell align="left" padding="none">
                            {row.centre_ids &&
                              centres
                                .filter((c) => row.centre_ids.includes(c.id))
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((c) => c.short_name || c.name)
                                .join(", ")}
                          </TableCell>

                          <TableCell align="left" padding="none">
                            {row.total}
                          </TableCell>

                          <TableCell align="left" padding="none">
                            <nobr>{row.terms}</nobr>
                          </TableCell>

                          <TableCell align="left" padding="none">
                            <Typography variant="body2" noWrap={true}>
                              {row.sent &&
                                format(row.sent.toDate(), "dd MMM yy")}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" padding="none">
                            <Typography variant="body2" noWrap={true}>
                              {row.received &&
                                format(row.received.toDate(), "dd MMM yy")}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" padding="none">
                            <Typography variant="body2" noWrap={true}>
                              {format(row.created.toDate(), "dd MMM yy")}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" padding="none">
                            <Typography variant="body2" noWrap={true}>
                              {row.modified &&
                                format(row.modified.toDate(), "dd MMM yy")}
                            </Typography>
                          </TableCell>

                          <TableCell padding="none">
                            <RowMenu
                              userAccountType={userAccountType}
                              handleEdit={(e) => {
                                handleEditQuote(row.id)
                              }}
                              handleDelete={(e) => {
                                handlePromptConfirmDelete(
                                  e,
                                  row.work_order_id,
                                  row.id
                                )
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <NavButtons>
              <Controls.Button
                size="small"
                disabled={pag.page === 0}
                onClick={() => handlePageNav(-1)}
                text="Prev"
              />
              <Controls.Button
                size="small"
                disabled={quotes.length < rowsPerPage}
                onClick={() => handlePageNav(1)}
                text="Next"
              />
            </NavButtons>
            <PageNo pageNo={pag.page + 1} />
          </Desktop>
        </Box>
      </Box>
    </>
  )
}

const RowMenu = (props) => {
  const { userAccountType, handleEdit, handleDelete } = props

  const [anchorEl, setAnchorEl] = React.useState(null)

  return (
    <>
      <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        <MenuItem
          onClick={(e) => {
            setAnchorEl(null)
            handleEdit(e)
          }}
        >
          Edit Quote
        </MenuItem>
        {userAccountType === "centre" && (
          <MenuItem
            onClick={(e) => {
              setAnchorEl(null)
              handleDelete(e)
            }}
          >
            Delete Quote
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
