import React from "react"
import { Chip, Typography } from "@mui/material"
import { Tooltip } from "@mui/material"
import * as colors from "@mui/material/colors"

function Button(props) {
  const {
    text,
    size = "small",
    variant,
    onClick,
    tooltip,
    sx = {},
    ...other
  } = props

  const getButton = () => {
    const iconProps = {}

    const { endIcon, startIcon, ...otherProps } = other

    if (endIcon) {
      iconProps.icon = endIcon
    }

    if (startIcon) {
      iconProps.icon = startIcon
    }

    return (
      <Chip
        size={size}
        label={<Typography variant="body2">{text}</Typography>}
        onClick={onClick}
        {...otherProps}
        {...iconProps}
        sx={{
          border: "1px solid #ddd",
          backgroundColor: variant === "outlined" ? '#fff' : colors.blue[50],
          borderColor: colors.blue[200],
          ":hover": {
            backgroundColor: colors.blue[100],
            borderColor: colors.blue[400],
          },
          borderRadius: "8px",
          padding: "12px 6px",
          ...sx,
        }}
      />
    )
  }

  return tooltip && !props?.disabled ? (
    <Tooltip title={tooltip}>{getButton()}</Tooltip>
  ) : (
    getButton()
  )
}

export default Button
