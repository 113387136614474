import db from "../../Firestore"
import { loadStripe } from "@stripe/stripe-js"
import { functions } from "../../Firestore"
import { httpsCallable } from "firebase/functions"
import { getAuth } from "firebase/auth"

const getEmptyStripeAccountInfo = () => {}

const stripePromise = loadStripe(
  "pk_live_51Il4rfBRExiK4XQlJG8DesPlcdi7Xyu0wmNtOLbXfgP0Xmh9R3kiDJuKwRIS7VnYQYHjEGFPKcrDKqmz7SHNCsKr00j6n50DyU"
)

// Name of product in Stripe that is used to create a subscription
const JOBS_MODULE = "Jobs module"

const getStripePromise = () => {
  return stripePromise
}

const listStripeProducts = async () => {
  const list = httpsCallable(functions, "listStripeProducts")

  const result = await list()

  return result
}

const addSubscriptionGST = async (accountId) => {
  const addGST = httpsCallable(functions, "addStripeGST")

  const result = await addGST({ accountId })

  return result
}

const getStripeProducts = async (productIds) => {
  const getStripeProducts = httpsCallable(functions, "getStripeProducts")

  return await getStripeProducts(productIds)
}

const getStripePlan = async (priceId) => {
  const getStripePlan = httpsCallable(functions, "getStripePlan")

  return await getStripePlan({ plan_id: priceId })
}

const setStripeCardAsDefault = async (paymentMethodId, customerId) => {
  const setDefaultCard = httpsCallable(functions, "setStripeCardAsDefault")
  return await setDefaultCard({ paymentMethodId, custId: customerId })
}

const getStripeProductPrices = async (productId) => {
  const getProductPrices = httpsCallable(functions, "getProductPrices")
  return await getProductPrices({ productId: productId })
}

const createStripeSetupIntent = async ({ customerId, paymentMethodId }) => {
  const createSetupIntent = httpsCallable(functions, "createStripeSetupIntent")
  return await createSetupIntent({ customerId, paymentMethodId })
}

/**
 * Cancels a Stripe subscription at the end of the period
 *
 * @param {*} productName
 * @param {*} accountId
 * @returns
 */
const changeStripeSubscriptionCancellationStatus = async (
  subscriptionId,
  cancellationStatus
) => {
  const cancelSubscriptionCancellationStatus = httpsCallable(
    functions,
    "changeStripeSubscriptionCancellationStatus"
  )

  const result = await cancelSubscriptionCancellationStatus({
    subscriptionId,
    cancellationStatus,
  })

  return result
}

const createStripeSubscription = async (productName, accountId) => {
  const accountRec = await db.collection("accounts").doc(accountId).get()
  const account = accountRec.data()

  const createSubscription = httpsCallable(
    functions,
    "createStripeSubscription"
  )

  const result = await createSubscription({
    accountId,
    productName,
    quantity: account.centre_count || 0,
  })

  return result
}

const createStripeCustomer = async (accountId) => {
  const accountDoc = await db.collection("accounts").doc(accountId).get()
  const account = accountDoc.data()
  const createStripeCust = httpsCallable(functions, "createStripeCustomer")

  const result = await createStripeCust({
    customer_account_id: accountId,

    // TODO: work out if we need to provide a billing address
    address: {
      city: "",
      country: "",
      line1: "",
      line2: "",
      postal_code: "",
      state: "",
    },
    email: account.email,
    name: account.name,
    phone: account.phone,
  })

  return result.data
}

/**
 *
 * @param {*} index 0 for first tier, 1 for second tier, etc
 * @param {*} upTo expect null for last tier
 * @returns
 */
const getTierDescription = (index, upTo) => {
  console.log("getTierDescription", index, upTo)
  if (index === 0) {
    return `per centre for the first ${upTo}`
  } else if (index === 1 && upTo !== null) {
    return `per centre for the next ${upTo}`
  } else if (upTo === null) {
    return "per centre thereafter"
  } else {
    return "???"
  }
}

const getStripePrice = async (priceId) => {
  const getPrice = httpsCallable(functions, "getStripePrice")

  const stripePrice = await getPrice({ price_id: priceId })

  return stripePrice
}

const getStripeCustomer = async (accountId) => {
  const result = {}

  if (getAuth().currentUser !== null) {
    const docRef = await db.collection("accounts").doc(accountId).get()

    const account = docRef.data()

    const getStripeCustomer = httpsCallable(functions, "getStripeCustomer")

    const stripeCust = await getStripeCustomer({ account_id: accountId })

    const isStripeCustomerDeleted =
      stripeCust.data.customer &&
      stripeCust.data.customer.hasOwnProperty("deleted") &&
      stripeCust.data.customer.deleted === true

    if (!stripeCust.data.customer || isStripeCustomerDeleted) {
      return stripeCust.data
    }

    const cust = stripeCust.data.customer

    const customer = {
      name: cust.name,
      phone: cust.phone,
      email: cust.email,
      address: {
        city: cust.address.city,
        country: cust.address.country,
        line1: cust.address.line1,
        line2: cust.address.line2,
        postal_code: cust.address.postal_code,
        state: cust.address.state,
      },
      created: new Date(cust.created),
      default_source: cust.default_source,
      subscriptions: cust.subscriptions.data,
    }

    result.customer = customer

    const invoice = stripeCust.data.invoice

    if (stripeCust.data.invoice) {
      const inv = {
        amount_due: invoice.amount_due,
        currency: invoice.currency,
        period_start: invoice.period_start,
        period_end: invoice.period_end,
        status: invoice.status,
        subtotal: invoice.subtotal,
        tax: invoice.tax,
        next_payment_attempt: invoice.next_payment_attempt,
      }

      result.invoice = inv
    }

    const cards = stripeCust.data.payment_methods.data.map((pm) => {
      const paymentMethod = {
        id: pm.id,
        billing_details: {
          ...pm.billing_details,
        },
        brand: pm.card.brand,
        last4: pm.card.last4,
        exp_month: pm.card.exp_month,
        exp_year: pm.card.exp_year,
        is_default:
          pm.id ===
          stripeCust.data.customer.invoice_settings.default_payment_method,
      }

      if (paymentMethod.billing_details.address.line2 === null) {
        paymentMethod.billing_details.address.line2 = ""
      }

      return paymentMethod
    })

    result.cards = cards
  }

  return result
}

export {
  getStripeCustomer,
  getEmptyStripeAccountInfo,
  changeStripeSubscriptionCancellationStatus,
  getStripeProducts,
  getStripePlan,
  getStripePromise,
  listStripeProducts,
  getStripeProductPrices,
  createStripeSubscription,
  setStripeCardAsDefault,
  createStripeSetupIntent,
  createStripeCustomer,
  addSubscriptionGST,
  getStripePrice,
  getTierDescription,
  JOBS_MODULE,
}
