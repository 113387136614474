import React, { useState, useEffect } from "react"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material"
import Controls from "./controls/Controls"
import { useSnackbar } from "notistack"
import EventRepeatIcon from "@mui/icons-material/EventRepeat"
import { Form } from "./useForm"
import {
  calculateNextScheduleDate,
  WEEKLY,
  DAY_OF_MONTH,
  WEEK_OF_MONTH,
  EVERY_X_DAYS,
} from "../pages/services/scheduleServices"
import moment from "moment"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import NavButtons from "./NavButtons"
import * as UIConstants from "./controls/UIConstants"

const WorkOrderSchedule = (props) => {
  const { open, setOpen, initialSchedule, updateSchedule, startDate } = props

  const [schedule, setSchedule] = useState(initialSchedule)

  const [nextDate, setNextDate] = useState()

  useEffect(() => {
    setScheduleAndCalculateNextDate(initialSchedule)
  }, [initialSchedule])

  const { enqueueSnackbar } = useSnackbar()

  const setScheduleAndCalculateNextDate = (newSchedule) => {
    setSchedule(newSchedule)

    const nextDate = calculateNextScheduleDate(newSchedule, startDate)

    if (nextDate) {
      setNextDate(moment(nextDate).format("DD/MM/YYYY"))
    } else {
      setNextDate(null)
    }
  }

  const scheduleTypeOptions = [
    {
      id: EVERY_X_DAYS,
      title: "Every X days",
      required: ["days", "end_date"],
    },
    {
      id: WEEKLY,
      title: "Weekly",
      required: ["day", "end_date"],
    },
    {
      id: DAY_OF_MONTH,
      title: "Day of month",
      required: ["day_of_month", "end_date"],
    },
    {
      id: WEEK_OF_MONTH,
      title: "Week of month",
      required: ["day", "week", "end_date"],
    },
  ]

  const dayOptions = [
    {
      id: 0,
      title: "Sunday",
    },
    {
      id: 1,
      title: "Monday",
    },
    {
      id: 2,
      title: "Tuesday",
    },
    {
      id: 3,
      title: "Wednesday",
    },
    {
      id: 4,
      title: "Thursday",
    },
    {
      id: 5,
      title: "Friday",
    },
    {
      id: 6,
      title: "Saturday",
    },
  ]

  const handleClose = (event) => {
    event.preventDefault()
    setOpen(false)
  }

  const handleDateChange = (newEndDate) => {
    const newSchedule = {
      ...schedule,
      end_date: newEndDate,
    }

    setScheduleAndCalculateNextDate(newSchedule)
  }

  const handleOK = (event) => {
    event.preventDefault()

    if (!schedule.type) {
      enqueueSnackbar("Select schedule type", { variant: "info" })
      return
    }

    const required = scheduleTypeOptions.find(
      (opt) => opt.id === schedule.type
    ).required
    const missing = required.find((field) => !schedule.hasOwnProperty(field))

    const empty = Object.keys(schedule).find(
      (field) =>
        field !== "end_date" &&
        (schedule[field] === "" || schedule[field] === undefined)
    )
    if (empty) {
      enqueueSnackbar(`Please enter all fields [${empty}]`, { variant: "info" })
      return
    }

    setOpen(false)
    updateSchedule(schedule)
  }

  const handleClear = (event) => {
    event.preventDefault()
    updateSchedule({}) // clear schedule
    enqueueSnackbar("Schedule cleared", { variant: "success" })
    setOpen(false)
  }

  const handleScheduleTypeChange = (event) => {
    const newScheduleType = event.target.value

    const newSchedule = {
      ...schedule,
      type: newScheduleType,
    }

    const required = scheduleTypeOptions.find(
      (opt) => opt.id === newScheduleType
    ).required

    // Remove any non-required fields

    Object.keys(newSchedule).forEach((field) => {
      if (field !== "type") {
        if (!required.includes(field)) {
          delete newSchedule[field]
        }
      }
    })

    // Add any required fields

    required.forEach((field) => {
      if (!newSchedule.hasOwnProperty(field)) {
        newSchedule[field] = ""
      }
    })

    // End date is initialised to null, so that the input field shows as blank
    newSchedule.end_date = null

    setScheduleAndCalculateNextDate(newSchedule)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Schedule work order</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Schedule when this work order is performed
        </DialogContentText>
        <Form>
          <Stack gap={2}>
            <Controls.Select
              name="scheduleType"
              label="Schedule Type"
              value={schedule.type}
              onChange={handleScheduleTypeChange}
              options={scheduleTypeOptions}
              isNoneOption={false}
            />

            {schedule.type === EVERY_X_DAYS && (
              <ScheduleEveryXDays
                schedule={schedule}
                setScheduleAndCalculateNextDate={
                  setScheduleAndCalculateNextDate
                }
                handleDateChange={handleDateChange}
              />
            )}

            {schedule.type === WEEKLY && (
              <ScheduleWeekly
                schedule={schedule}
                setScheduleAndCalculateNextDate={
                  setScheduleAndCalculateNextDate
                }
                dayOptions={dayOptions}
                handleDateChange={handleDateChange}
              />
            )}
            {schedule.type === DAY_OF_MONTH && (
              <ScheduleDayOfMonth
                schedule={schedule}
                setScheduleAndCalculateNextDate={
                  setScheduleAndCalculateNextDate
                }
                handleDateChange={handleDateChange}
              />
            )}
            {schedule.type === WEEK_OF_MONTH && (
              <ScheduleWeekOfMonth
                schedule={schedule}
                setScheduleAndCalculateNextDate={
                  setScheduleAndCalculateNextDate
                }
                dayOptions={dayOptions}
                handleDateChange={handleDateChange}
              />
            )}

            <Controls.Readonly
              name="startDate"
              label="Start Date"
              value={moment(startDate).format("DD/MM/YYYY")}
            />

            {schedule.type && (
              <Controls.Readonly
                name="nextDate"
                label="Next Scheduled Date (d/m/y)"
                value={nextDate ? nextDate : "..."}
                icon={<EventRepeatIcon />}
              />
            )}
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <Grid container direction="column">
          <Grid item>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <NavButtons>
                <Controls.Button
                  text="Cancel"
                  onClick={handleClose}
                  variant="outlined"
                />

                <Controls.Button
                  text="Clear"
                  onClick={handleClear}
                  variant="outlined"
                />

                <Controls.Button
                  text="Set"
                  onClick={handleOK}
                  variant="contained"
                />
              </NavButtons>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const ScheduleEveryXDays = (props) => {
  const { schedule, setScheduleAndCalculateNextDate, handleDateChange } = props

  const handleDayChange = (event) => {
    const newSchedule = {
      days: event.target.value,
      type: EVERY_X_DAYS,
      end_date: schedule.end_date,
    }

    setScheduleAndCalculateNextDate(newSchedule)
  }

  return (
    <>
      <Grid item>
        <Controls.TextInput
          name="day"
          label="Days Interval Between Jobs"
          value={schedule.days}
          onChange={handleDayChange}
        />
      </Grid>
      <Grid item>
        <DesktopDatePicker
          margin="normal"
          id="end-date"
          label="End Date (d/m/y)"
          inputFormat="dd/MM/yyyy"
          helperText={
            schedule.end_date === null
              ? "Enter optional end date"
              : "Work orders not scheduled past this date"
          }
          value={schedule.end_date}
          onChange={(selectedDate) => handleDateChange(selectedDate)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          renderInput={(params) => (
            <TextField
              variant={UIConstants.STANDARD_INPUT_VARIANT}
              size={UIConstants.STANDARD_INPUT_SIZE}
              {...params}
            />
          )}
        />
      </Grid>
    </>
  )
}

const ScheduleWeekly = (props) => {
  const {
    schedule,
    setScheduleAndCalculateNextDate,
    dayOptions,
    handleDateChange,
  } = props

  const handleDayChange = (event) => {
    const newSchedule = {
      day: event.target.value,
      type: WEEKLY,
      end_date: schedule.end_date,
    }

    setScheduleAndCalculateNextDate(newSchedule)
  }

  return (
    <>
      <Grid item>
        <Controls.Select
          name="day"
          label="Day of Week"
          value={schedule.day}
          onChange={handleDayChange}
          options={dayOptions}
        />
      </Grid>

      <Grid item>
        <DesktopDatePicker
          margin="normal"
          id="end-date"
          label="End Date (d/m/y)"
          inputFormat="dd/MM/yyyy"
          helperText={
            schedule.end_date === null
              ? "Enter optional end date"
              : "Work orders not scheduled past this date"
          }
          value={schedule.end_date}
          onChange={(selectedDate) => handleDateChange(selectedDate)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          renderInput={(params) => (
            <TextField
              variant={UIConstants.STANDARD_INPUT_VARIANT}
              size={UIConstants.STANDARD_INPUT_SIZE}
              {...params}
            />
          )}
        />
      </Grid>
    </>
  )
}

const ScheduleWeekOfMonth = (props) => {
  const {
    schedule,
    setScheduleAndCalculateNextDate,
    dayOptions,
    handleDateChange,
  } = props

  const handleDayChange = (event) => {
    const newSchedule = {
      day: event.target.value,
      week: schedule.week,
      type: WEEK_OF_MONTH,
      end_date: schedule.end_date,
    }

    setScheduleAndCalculateNextDate(newSchedule)
  }

  const handleWeekChange = (event) => {
    const newSchedule = {
      day: schedule.day,
      week: event.target.value,
      type: WEEK_OF_MONTH,
      end_date: schedule.end_date,
    }

    setScheduleAndCalculateNextDate(newSchedule)
  }

  const weekOptions = [
    {
      id: 1,
      title: "1st",
    },
    {
      id: 2,
      title: "2nd",
    },
    {
      id: 3,
      title: "3rd",
    },
    {
      id: 4,
      title: "4th",
    },
  ]

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item>
          <Controls.Select
            name="week"
            label="Week"
            value={schedule.week}
            onChange={handleWeekChange}
            options={weekOptions}
          />
        </Grid>
        <Grid item>
          <Controls.Select
            name="day"
            label="Day of Week"
            value={schedule.day}
            onChange={handleDayChange}
            options={dayOptions}
          />
        </Grid>
        <Grid item>
          <DesktopDatePicker
            id="end-date"
            label="End Date (d/m/y)"
            inputFormat="dd/MM/yyyy"
            helperText={
              schedule.end_date === null
                ? "Enter optional end date"
                : "Work orders not scheduled past this date"
            }
            value={schedule.end_date}
            onChange={(selectedDate) => handleDateChange(selectedDate)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            renderInput={(params) => (
              <TextField
                variant={UIConstants.STANDARD_INPUT_VARIANT}
                size={UIConstants.STANDARD_INPUT_SIZE}
                {...params}
              />
            )}
          />
        </Grid>
      </LocalizationProvider>
    </>
  )
}

const ScheduleDayOfMonth = (props) => {
  const { schedule, setScheduleAndCalculateNextDate, handleDateChange } = props

  const handleDayOfMonthChange = (event) => {
    var numberPattern = /\d+/g

    const result = event.target.value.match(numberPattern)

    if (result && result.length === 1 && parseInt(result[0]) <= 31) {
      const newSchedule = {
        day_of_month: parseInt(event.target.value),
        type: DAY_OF_MONTH,
        end_date: schedule.end_date,
      }

      setScheduleAndCalculateNextDate(newSchedule)
    } else {
      const newSchedule = {
        day_of_month: "",
        type: DAY_OF_MONTH,
        end_date: schedule.end_date,
      }
      setScheduleAndCalculateNextDate(newSchedule)
    }
  }

  return (
    <>
      <Grid item>
        <Controls.TextInput
          name="day_of_month"
          label="Day of month"
          value={schedule.day_of_month}
          onChange={handleDayOfMonthChange}
          type="number"
        />
      </Grid>

      <Grid item>
        <DesktopDatePicker
          margin="normal"
          id="end-date"
          label="End Date (d/m/y)"
          inputFormat="dd/MM/yyyy"
          helperText={
            schedule.end_date === null
              ? "Enter optional end date"
              : "Work orders not scheduled past this date"
          }
          value={schedule.end_date}
          onChange={(selectedDate) => handleDateChange(selectedDate)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          renderInput={(params) => (
            <TextField
              variant={UIConstants.STANDARD_INPUT_VARIANT}
              size={UIConstants.STANDARD_INPUT_SIZE}
              {...params}
            />
          )}
        />
      </Grid>
    </>
  )
}

export default WorkOrderSchedule
