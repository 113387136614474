import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { useState } from "react"
import DownloadIcon from "@mui/icons-material/Download"
import DeleteIcon from "@mui/icons-material/Delete"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import * as dateServices from "../pages/services/dateServices"
import * as icons from "../icons"

const FileListItem = ({
  handleViewPhotos,
  fileName,
  // Info about this individual file
  info,
  selected,
  handlePromptDeleteFile,
  handleDownloadFile,
  canDelete = true,
  // if true, show an icon on whether the file was provided by a centre or a supplier
  showSource = false,
  // the account id of the doc to which the file belongs
  sourceAccountId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const formatAsDDMMMYY = (timestamp) => {
    return timestamp && dateServices.tsToDateTimeStr(timestamp)
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            handleDownloadFile()
          }}
        >
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <Typography>Download</Typography>
        </MenuItem>
        {handlePromptDeleteFile && canDelete && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              handlePromptDeleteFile()
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <Typography>Delete</Typography>
          </MenuItem>
        )}
      </Menu>

      <ListItemButton
        key={fileName}
        component="a"
        onClick={handleViewPhotos}
        selected={selected}
      >
        {showSource && (
          <Tooltip
            title={
              info === undefined || sourceAccountId === info?.account_id
                ? "Centre photo"
                : "Supplier photo"
            }
          >
            {/* job.file_info array was added later to each job, and so not all jobs have a corresponding file_info array of meta-data for the photo, so we need to check for info === undefined */}
            <ListItemIcon>
              {info === undefined || sourceAccountId === info.account_id ? (
                <icons.CentreIcon />
              ) : (
                <icons.SupplierIcon />
              )}
            </ListItemIcon>
          </Tooltip>
        )}

        <ListItemText
          primary={fileName}
          secondary={
            <Stack component="span" direction="row" gap={2}>
              <Typography variant="caption" component="span">
                {info?.email}
              </Typography>
              <Typography variant="caption" component="span">
                {formatAsDDMMMYY(info?.created)}
              </Typography>
            </Stack>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="file-more"
            onClick={(event) => {
              event.stopPropagation()
              setAnchorEl(event.currentTarget)
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>
    </>
  )
}

export default FileListItem
