import {
  Box,
  CircularProgress,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material"
import Controls from "./controls/Controls"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

const JobQualityAnalysis = ({
  jobQualityAnalysis,
  jobRegulations,
  jobPrevention,
  jobRiskAssessment,
  isCheckingJobQuality,
  handleUpdateJobField,
  values,
}) => {
  return (
    <Stack direction="column" gap={2}>
      {jobQualityAnalysis && jobQualityAnalysis.length > 0 && (
        <Box sx={{ mt: "15px", mb: "15px" }}>
          <FormLabel>
            <Typography variant="h6" sx={{ mb: "3px" }}>
              Job Suggestions
            </Typography>
            {jobQualityAnalysis.length > 0 && !isCheckingJobQuality && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ mb: "20px" }}
              >
                You need to click Save after updating job fields with
                recommended values
              </Typography>
            )}
            {isCheckingJobQuality && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "middle",
                  gap: 1,
                }}
              >
                <CircularProgress size="20" />
                {jobQualityAnalysis.map((item) => (
                  <Typography key={item.key} variant="body1">
                    {item.key}: {item.value}
                  </Typography>
                ))}
              </Box>
            )}
          </FormLabel>
          {!isCheckingJobQuality && (
            <Stack direction="column" gap={3} sx={{ maxWidth: "600px" }}>
              {jobQualityAnalysis.map((item) => (
                <Stack direction="column" key={item.key} gap={1}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {item.label}
                  </Typography>
                  <Stack direction="row" gap={1} alignItems="flex-start">
                    <Box
                      sx={{
                        width: "70px",
                        minWidth: "70px",
                        display: "flex",
                        flexShrink: 0,
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        New
                      </Typography>
                    </Box>

                    <Typography variant="body1">{item.valueToShow}</Typography>
                  </Stack>
                  <Stack direction="row" gap={1} alignItems="flex-start">
                    <Box
                      sx={{
                        width: "70px",
                        minWidth: "70px",
                        display: "flex",
                        flexShrink: 0,
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        Current
                      </Typography>
                    </Box>

                    {!item.isBlank && (
                      <Typography variant="body1">
                        {item.existingValueToShow}
                      </Typography>
                    )}
                    {item.isBlank && (
                      <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                        No existing value
                      </Typography>
                    )}
                  </Stack>
                  {item.reason && (
                    <Typography
                      variant="caption"
                      sx={{
                        fontStyle: "italic",
                        ml: "75px",
                      }}
                      color="text.secondary"
                    >
                      Reason: {item.reason}
                    </Typography>
                  )}
                  {!["priority_reason"].includes(item.key) && (
                    <Stack direction="row" gap={2}>
                      <Controls.Button
                        tooltip={"Update job with new value"}
                        size="small"
                        type="button"
                        text={`Update ${item.label}`}
                        onClick={() => handleUpdateJobField({ item })}
                        disabled={item.value === values[item.key]}
                      />
                      {item.value === values[item.key] && (
                        <CheckCircleOutlineIcon
                          sx={{ color: "success.main" }}
                        />
                      )}
                    </Stack>
                  )}
                </Stack>
              ))}
              <Box>
                {jobPrevention && (
                  <Box sx={{ mb: "10px" }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Prevention
                    </Typography>
                    <Typography variant="body1">{jobPrevention}</Typography>
                  </Box>
                )}
                {jobRiskAssessment && (
                  <Box sx={{ mb: "10px" }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Risk Assessment
                    </Typography>
                    <Typography variant="body1">{jobRiskAssessment}</Typography>
                  </Box>
                )}
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Regulations
                </Typography>
                {jobRegulations?.length > 0 && (
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    sx={{ fontStyle: "italic" }}
                  >
                    Please check these. They may be out of date or incorrect.
                    Consider providing verified regulations information as job
                    instructions to the supplier.
                  </Typography>
                )}
              </Box>
              {jobRegulations &&
                jobRegulations.length > 0 &&
                jobRegulations.map((regulation) => (
                  <Box key={regulation.name} sx={{ ml: "10px" }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      {regulation.name}, {regulation.authority}:{" "}
                    </Typography>
                    <Typography variant="body2">
                      {regulation.description}
                    </Typography>
                  </Box>
                ))}
            </Stack>
          )}
        </Box>
      )}
    </Stack>
  )
}

export default JobQualityAnalysis
