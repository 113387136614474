import React, { Fragment, useState, useMemo } from "react"
import Controls from "../components/controls/Controls"
import {
  Paper,
  Grid,
  Divider,
  LinearProgress,
  Typography,
  Link,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  ListItemButton,
} from "@mui/material"
import { useHistory } from "react-router-dom"
import { FcGoogle } from "react-icons/fc"
import { AiOutlineMail } from "react-icons/ai"
import { FiLogIn } from "react-icons/fi"
import { useSnackbar } from "notistack"
import * as dataServices from "../pages/services/dataServices"
import { useEffect } from "react"
import * as cloudFunctions from "./services/cloudFunctions"
import {
  processInvite,
  acceptSupplierInvite,
  getUserAuthenticationMethods,
} from "../pages/services/cloudFunctions"
import * as Icons from "../icons"
import { Alert, AlertTitle } from "@mui/material"
import LoginDialog from "../components/LoginDialog"
import ProgressBackdrop from "../components/ProgressBackdrop"
import { isEmailValid } from "./services/emailServices"
import * as Roles from "../pages/services/roleServices"
import ForwardIcon from "@mui/icons-material/Forward"
import _ from "lodash"
import { List } from "@mui/material"
import { ListItem } from "@mui/material"
import { ListItemText } from "@mui/material"
import { ListItemIcon } from "@mui/material"
import { setJobGridPagination } from "../redux/actions"
import { useDispatch } from "react-redux"
import db from "../Firestore"
import { Tooltip } from "@mui/material"
import AcceptEULADialog from "../components/AcceptEULADialog"
import * as authenticationServices from "./services/authenticationServices"
import LinkButton from "../components/controls/LinkButton"
import { spacing } from "./services/styleServices"
import LogoFull from "../components/LogoFull"
import { JOB_STATUS_OPEN } from "./services/jobServices"
import { initialState as initialJobGridPaginationState } from "../redux/reducers/jobGridPagination"
import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  getIdTokenResult,
  sendPasswordResetEmail,
  getAuth
} from "firebase/auth"

const styles = {
  pageContent: {
    margin: spacing(2),
    padding: spacing(2),
    minWidth: 300,
  },
  signOut: {
    display: "flex",
    justifyContent: "center",
    marginTop: spacing(3),
  },
  versionBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  listItemIcon: {
    marginTop: "4px",
  },
  link: {
    cursor: "pointer",
  },
  loginBox: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
  },
  gridContainer: {
    display: "flex",
    alignContent: "flex-start",
  },
  versionNo: {
    marginRight: spacing(2),
  },
}

const version = "1.0.0"

function SignIn() {
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const [showProgress, setShowProgress] = useState(1)

  const [isShowLogin, setShowLogin] = useState(false)

  const dispatch = useDispatch()

  const [isEnterEmailDialogOpen, setEnterEmailDialogOpen] = useState(false)

  const [emailErrorMessage, setEmailErrorMessage] = useState("")

  const [email, setEmail] = useState("")

  const [signInInfo, setSignInInfo] = useState({
    accountId: undefined,
    lastSignInTime: undefined,
  })

  const [invites, setInvites] = useState([])

  const [claims, setClaims] = useState()

  const [logs, setLogs] = useState([])

  const [progressLabel, setProgressLabel] = useState(
    "Checking sign in status..."
  )

  const isGoogleEmail = useMemo(() => {
    return signInInfo?.user?.email?.toLowerCase().includes("@gmail.com")
  }, [signInInfo])

  const [user, setUser] = useState()

  const [loginInputs, setLoginInputs] = useState({})

  const hasPassword = useMemo(
    () => authenticationServices.isPasswordProviderFound(user),
    [user]
  )

  const [acceptEULAOpen, setAcceptEULAOpen] = useState(false)

  // If this is a new user, they will be prompted to set a password -- if it's a non-Google user.
  const [changePasswordValues, setChangePasswordValues] = useState({
    newPassword: "",
  })

  const hasProvidedNewPasswordValues = useMemo(() => {
    return changePasswordValues.newPassword !== ""
  }, [changePasswordValues])

  const indeterminateSignInStatus = {
    title: "Signing in...",
    severity: "info",
    message: "",
    canSignUp: false,
    canSignIn: true,
    canContinue: false,
    checkForInvite: false,
    canSignOut: false,
  }

  const createSignedInStatus = ({ name, email }) => ({
    title: "Signed in",
    severity: "success",
    message: (
      <>
        <strong>
          {name} {email}
        </strong>
      </>
    ),
    canSignUp: false,
    canSignIn: false,
    canContinue: true,
    checkForInvite: false,
    canSignOut: true,
  })

  const [signInStatus, setSignInStatus] = useState(indeterminateSignInStatus)

  // Watch for invites

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)

        getIdTokenResult(user, true)
          .then((token) => {
            cloudFunctions.log({
              type: "signin",
              message: `Signed in with Google - ${user.email} - account ${token?.claims?.account_id}`,
              userId: user.uid,
              accountId: token?.claims?.account_id || "not found",
            })

            setClaims(token.claims)

            console.log("%cclaims", "color:lightgreen", {
              claims: token.claims,
              user,
            })

            if (user.emailVerified) {
              if (!user.email) {
                return
              }
              const unsub = db
                .collection("invites")
                .where("email", "==", user.email?.toLowerCase())
                .onSnapshot(
                  (querySnapshot) => {
                    const newInvites = querySnapshot.docs.map((doc) => {
                      return { id: doc.id, ...doc.data() }
                    })

                    setInvites(newInvites)
                  },
                  (error) =>
                    console.error(
                      "%cError getting invites",
                      "color:orange",
                      error
                    )
                )

              return unsub
            } else {
              user.sendEmailVerification()
            }
          })
          .catch((error) => {
            console.error("Error getting token to check for invites", error)
            cloudFunctions.log({
              type: "signin",
              message: `Error getting token to check for invites ${error}`,
              severity: "ERROR",
            })
          })
      } else {
        setShowProgress(0)
      }
    })

    return unsub
  }, [])

  const isSignUpOrganisationAllowed = useMemo(() => {
    if (!user) {
      return true
    }

    if (claims?.account_id) {
      return false
    }

    return true
  }, [user, claims])

  // Check for new version

  const showProgressWithLabel = (label) => {
    setProgressLabel(label)
    setShowProgress((curr) => curr + 1)
  }

  useEffect(() => {
    const storedVersion = window.localStorage.getItem("app.version")
    if (storedVersion === null) {
      window.localStorage.setItem("app.version", version)
    }

    addLog(`stored ${storedVersion} current ${version}`)

    if (storedVersion !== version) {
      addLog("requires reload")
      window.localStorage.setItem("app.version", version)
      enqueueSnackbar("New version. Reloading...")

      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        cloudFunctions.log({
          type: "signin",
          message: `Getting id token - ${user.email}`,
          userId: user.uid,
        })

        getIdTokenResult(user, true)
          .then(async (token) => {
            const result = await cloudFunctions.obtainCustomClaims()

            setSignInInfo({
              accountId: token.claims.account_id,
              lastSignInTime: user.metadata.lastSignInTime,
            })

            showProgressWithLabel("Signing in...")

            // Suppliers have 'open' as the default job grid filter, not 'pending'
            setJobGridFilterToOpenForSupplier(token)

            if (token.claims.account_id) {
              console.log("%cshow dashboard", "color:lightgreen")
              showDashboard()
            }
          })
          .then(() => setShowProgress((curr) => curr - 1))
          .catch((error) => {
            console.error(`Error getting token for user - ${user}`, error)
            cloudFunctions.log({
              type: "signin",
              message: `Error getting token ${error}`,
              severity: "ERROR",
            })
          })
      } else {
        setSignInInfo({ accountId: undefined, lastSignInTime: undefined })
      }
    })

    return unsub
  }, [])

  const addLog = async (log) => {
    setLogs([...logs, log])
  }

  const handleSignUp = () => {
    history.push("/SignUp")
  }

  const showDashboard = () => {
    const auth = getAuth()
    getIdTokenResult(auth.currentUser, true)
      .then((token) => {
        // Get email from token
        const email = token.claims.email

        if (!email) {
          enqueueSnackbar("Email not found [1]", {
            variant: "error",
            autoHideDuration: 5000,
          })
          return
        }
        db.collection("invites")
          .where("email", "==", email.toLowerCase())
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              if (token.claims.account_type === Roles.SUPPLIER) {
                history.push("/workorders")
              } else {
                if (
                  token.claims.roles &&
                  (token.claims.roles.includes(Roles.JOB_ADMIN) ||
                    token.claims.roles.includes(Roles.JOB_USER))
                ) {
                  history.push("/jobgrid")
                } else {
                  history.push("/dashboard")
                }
              }
            }
          })
      })
      .catch((error) => {
        console.error("Error querying invites:", error)
      })
  }

  const handleSupplierSignUp = () => {
    history.push({ pathname: "/SignUp", state: { type: "supplier" } })
  }

  const isSupplierInviteReceived = () => {
    const result = invites.length > 0 && invites[0].type === "supplier"
    return result
  }
  const [isPopupOpen, setPopupOpen] = useState(false)

  const handleSignIn = () => {
    if (isPopupOpen) {
      return
    }

    console.log("%chandleSignIn", "color:lightgreen")

    setSignInStatus(indeterminateSignInStatus)
    setProgressLabel("Signing in...")
    setShowProgress((curr) => curr + 1)

    
    setPopupOpen(true)
    
    // sign out
    
    console.log("%csignOut", "color:lightgreen")
    const auth = getAuth()
    signOut(auth)

    const googleAuthProvider = new GoogleAuthProvider()
    signInWithPopup(auth, googleAuthProvider)
      .then((result) => {
        console.log("sign in result", result)

        const credential = GoogleAuthProvider.credentialFromResult(result)
        //console.log("credential", credential)

        //const token = credential.accessToken
        // The signed-in user info.
        const user = result.user

        cloudFunctions.log({
          type: "signin",
          message: `Signed in with Google - ${user.email}`,
          userId: result.user.uid,
        })

        if (!result.user) {
          enqueueSnackbar("Error signing in - user not found [2]", {
            variant: "error",
            autoHideDuration: 5000,
          })
          return
        }
      })
      .catch((error) => {
        console.error("Error signing in", error)
        // Log detailed error information
        console.error("Error code:", error.code)
        console.error("Error message:", error.message)
        console.error("Error stack:", error.stack)
        enqueueSnackbar(`Error getting token - ${error} [4]`, {
          variant: "error",
          autoHideDuration: 5000,
        })
        cloudFunctions.log({
          type: "signin",
          message: `Error getting token - ${error}`,
          severity: "ERROR",
        })
      })
      .finally(() => {
        setPopupOpen(false)
        setShowProgress((curr) => curr - 1)
      })
  }

  const setJobGridFilterToOpenForSupplier = (token) => {
    if (token.claims && token.claims.account_type === "supplier") {
      const newJobGridPagination = {
        ...initialJobGridPaginationState.value,
        job_status_filter: [JOB_STATUS_OPEN],
      }
      dispatch(setJobGridPagination(newJobGridPagination))
    }
  }

  const handleGetStarted = () => {
    const auth = getAuth()
    if (auth.currentUser) {
      getIdTokenResult(auth.currentUser).then((token) => {
        if (invites.length === 0) {
          if (
            token.claims.roles &&
            (token.claims.roles.includes(Roles.JOB_ADMIN) ||
              token.claims.roles.includes(Roles.JOB_USER))
          ) {
            history.push("/jobgrid")
          } else {
            showDashboard()
          }
        }
      })
    }
  }

  const handleSignOut = () => {
    setInvites([])
    setUser(undefined)

    const auth = getAuth()
    signOut(auth).then(async (result) => {
      await updateSignInStatus("from handleSignOut")
    })
  }

  const handleEmailOTPSignIn = () => {
    if (!email) {
      enqueueSnackbar("Please enter your email address", { variant: "info" })
    } else {
      if (isEmailValid(email)) {
        getEmailLoginInputs(email).then((result) => {
          setEmailErrorMessage("")
          setLoginInputs(result)
          setShowLogin(true)
        })
      } else {
        setEmailErrorMessage("Please enter a valid email address")
      }
    }
  }

  const getEmailLoginInputs = async () => {
    if (email === "") {
      return {}
    }
    const promises = [
      getUserAuthenticationMethods({ email: email }),
      //TODO: isQrOTPUsed is not used any more. Review and remove
      //isQrOTPUsed({ email: email }),
    ]

    const results = await Promise.all(promises)

    const loginInputs = {
      providerIds: results[0].data.providers,
      // user defined in Firebase GCP platform, i.e. does NOT mean a gmail address
      emailDefinedInGoogle: results[0].data.userFound,
      isQrCodeSecretDefined: results[0].data.isQrCodeSecretDefined,
    }

    return loginInputs
  }

  const [loadState, setLoadState] = useState()

  useEffect(() => {
    const newLoadState = { signInInfo }

    const isLoadStateChanged = !_.isEqual(loadState, newLoadState)

    setLoadState(newLoadState)

    if (isLoadStateChanged) {
      updateSignInStatus("useEffect").then((status) => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInInfo])

  const updateSignInStatus = async (source) => {
    setShowProgress((curr) => curr + 1)

    let signInStatus

    const auth = getAuth()
    if (auth.currentUser !== null) {
      const user = auth.currentUser
      const userRecFound = signInInfo.accountId !== undefined

      const email = user.email

      if (userRecFound) {
        const accountId = signInInfo.accountId

        if (!accountId) {
          return
        }

        const account = await dataServices.getAccountById(accountId)

        signInStatus = createSignedInStatus({ name: account.name, email })
      } else {
        signInStatus = {
          title: `Email Verified - ${email}`,
          severity: "info",
          message: (
            <>
              {invites.length > 0 ||
                (isSupplierInviteReceived() && (
                  <>
                    <Typography>
                      <strong>{email}</strong> is verified. Your options are:
                    </Typography>
                    <List>
                      {invites.length === 0 && (
                        <ListItem key="await-invite" alignItems="flex-start">
                          <ListItemIcon sx={styles.listItemIcon}>
                            <Icons.SupplierIcon />
                          </ListItemIcon>
                          <ListItemText primary="Await an invite from your organisation to join either as a staff member, or a supplier" />
                        </ListItem>
                      )}

                      {/* Don't show the option to create a new account if this user has just received a staff invite
                                    -- it would just confuse them */}
                      {isSupplierInviteReceived() && (
                        <ListItem key="sign-up" alignItems="flex-start">
                          <ListItemIcon sx={styles.listItemIcon}>
                            <Icons.AccountIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <>
                                <Link
                                  color="primary"
                                  sx={styles.link}
                                  onClick={handleSupplierSignUp}
                                >
                                  Sign Up
                                </Link>{" "}
                                for a new supplier account if you own an
                                organisation, or supply trade services
                              </>
                            }
                          />
                        </ListItem>
                      )}
                    </List>
                  </>
                ))}
            </>
          ),
          canSignUp: true,
          canSignIn: false,
          canContinue: false,
          checkForInvite: true,
          canSignOut: true,
        }
      }
    } else {
      signInStatus = {
        title: "",
        severity: "info",
        message: "",
        canSignUp: false,
        canSignIn: true,
        canContinue: false,
        checkForInvite: false,
        canSignOut: false,
      }
    }

    if (signInStatus) {
      setSignInStatus(signInStatus)
    }

    setShowProgress((curr) => curr - 1)

    return signInStatus
  }

  const handleClickAcceptInvite = (inviteId) => {
    // For supplier invites, check if they have a supplier account
    setAcceptEULAOpen(true)
  }

  const handleProcessInvite = async (inviteId) => {
    const isGoogleProvider = authenticationServices.isGoogleProvider(user)

    // Verify that if it's a non-Google user that they have a password set
    if (!isGoogleProvider && !hasPassword && !hasProvidedNewPasswordValues) {
      enqueueSnackbar(
        "Please use the Reset password link on the sign in page to set your password before accepting an invite",
        {
          variant: "error",
        }
      )
      return
    }

    if (!isGoogleEmail && hasProvidedNewPasswordValues) {
      await authenticationServices.setPassword({
        email: signInInfo.user.email,
        oldPassword: "",
        newPassword: changePasswordValues.newPassword,
      })
    }

    setShowProgress((curr) => curr + 1)

    enqueueSnackbar("Accepting invite...", { variant: "info" })

    // What type of invite is it?
    const invite = invites.find((i) => i.id === inviteId)

    if (invite.type === "staff") {
      const inviteResult = await processInvite()

      if (inviteResult.data.invite_processed === true) {
        await updateSignInStatus("from accept invite")
        enqueueSnackbar(`Success`, { variant: "success" })
      } else {
        enqueueSnackbar(inviteResult.data.response, {
          variant: "error",
          autoHideDuration: 5000,
        })
      }
    } else {
      const supplierInviteResult = await acceptSupplierInvite(inviteId)

      enqueueSnackbar(supplierInviteResult.data.response, { variant: "info" })
    }

    // Wait 2 seconds
    setTimeout(async () => {
      cloudFunctions
        .obtainCustomClaims()
        .then((result) => {
          getIdTokenResult(user, true).then(async (token) => {
            setClaims(token.claims)
            setSignInInfo({
              accountId: token.claims.account_id,
              lastSignInTime: user.metadata.lastSignInTime,
            })
            if (token.claims.account_id) {
              // Get Account
              const account = await dataServices.getAccountById(
                token.claims.account_id
              )
              const status = createSignedInStatus({
                name: account.name,
                email: user.email,
              })

              setSignInStatus(status)
              showDashboard()
            }
          })
        })
        .catch((error) => {
          console.error("Error obtaining custom claims", error)
          cloudFunctions.log({
            type: "signin",
            message: `Error obtaining custom claims ${error}`,
            severity: "ERROR",
          })
        })

      setShowProgress((curr) => curr - 1)
    }, 2000)
  }

  const handleSendResetPasswordLink = async (email) => {
    setEnterEmailDialogOpen(false)

    const auth = getAuth()
    sendPasswordResetEmail(auth, email).then((result) => {
      enqueueSnackbar("Reset password link sent to email", {
        variant: "success",
      })
      setInvites([])
      signOut(auth)
    })
  }

  const handleChangeShowLogin = (show) => {
    setShowLogin(show)
    if (!show) {
      updateSignInStatus("from handleChangeShowLogin")
    }
  }

  return (
    <>
      <ProgressBackdrop open={showProgress > 0} label={progressLabel} />

      <EnterEmailDialog
        open={isEnterEmailDialogOpen}
        setOpen={setEnterEmailDialogOpen}
        handleSendResetPasswordLink={handleSendResetPasswordLink}
      />

      {isShowLogin && (
        <LoginDialog
          open={isShowLogin}
          setOpen={handleChangeShowLogin}
          loginInputs={loginInputs}
          email={email}
        />
      )}

      <Box sx={styles.loginBox}>
        <Box sx={{ maxWidth: 400, marginTop: "20px" }}>
          <Paper sx={styles.pageContent} elevation={6}>
            {showProgress > 0 && <LinearProgress />}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                //marginTop: "20px",
                marginBottom: "30px",
              }}
            >
              <LogoFull type="white" width="220px" />
            </Box>
            <Grid container direction="column" sx={styles.gridContainer}>
              <Grid container spacing={2} direction="column">
                {signInStatus.title && (
                  <Grid item key="alert">
                    <Alert severity={signInStatus.severity}>
                      <AlertTitle>{signInStatus.title}</AlertTitle>
                      {signInStatus.message}
                    </Alert>
                  </Grid>
                )}

                {signInStatus.canSignIn && (
                  <>
                    <Grid item key="google-sign-in-button">
                      <Button
                        sx={{ textTransform: "none" }}
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                        onClick={handleSignIn}
                        disabled={!signInStatus.canSignIn}
                        startIcon={<FcGoogle />}
                      >
                        Continue with Google
                      </Button>
                    </Grid>

                    <Divider sx={{ marginTop: "10px" }} />

                    <Grid item>
                      <Controls.TextInput
                        variant="outlined"
                        value={email}
                        fullWidth
                        label="Email address"
                        helperText="Access your organisation's existing account"
                        onChange={(e) =>
                          setEmail(e.target.value.trim().toLowerCase())
                        }
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleEmailOTPSignIn()
                          }
                        }}
                      />
                    </Grid>

                    {emailErrorMessage !== "" && (
                      <Grid item>
                        <Typography variant="caption" color={"#f20000"}>
                          {emailErrorMessage}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item key="email-signin-button">
                      <Button
                        sx={{ textTransform: "none" }}
                        text="Continue"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        onClick={handleEmailOTPSignIn}
                        disabled={!signInStatus.canSignIn}
                        startIcon={<AiOutlineMail />}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </>
                )}

                <Grid item key="invites-list">
                  {invites.map((invite) => (
                    <Fragment key={invite.id}>
                      {signInStatus.canSignUp && invite.type === "supplier" ? (
                        <ListItem>
                          <ListItemIcon sx={styles.listItemIcon}>
                            <Icons.InviteIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={invite.account_name}
                            secondary={
                              <Typography variant="caption">
                                You have been invited to join this account but
                                you must first{" "}
                                <Link
                                  color="primary"
                                  sx={styles.link}
                                  onClick={handleSupplierSignUp}
                                >
                                  Sign Up
                                </Link>{" "}
                                and create a new <b>supplier</b> account
                              </Typography>
                            }
                          />
                        </ListItem>
                      ) : (
                        <Tooltip
                          title={`Accept invite to ${invite.account_name}`}
                        >
                          <ListItemButton
                            alignItems="flex-start"
                            onClick={() => handleClickAcceptInvite(invite.id)}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between", // This spreads out the children
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography fontWeight={"bold"}>
                                  {invite.account_name}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  {`Click to accept this ${invite.type} invite`}
                                </Typography>
                              }
                            />
                            <ListItemIcon
                              sx={{
                                minWidth: "auto",
                                mr: 0,
                                mb: "5px",
                                color: "#F6BE16",
                              }}
                            >
                              <ForwardIcon />
                            </ListItemIcon>
                          </ListItemButton>
                        </Tooltip>
                      )}
                      <AcceptEULADialog
                        open={acceptEULAOpen}
                        handleAccept={async () => {
                          setAcceptEULAOpen(false)
                          await handleProcessInvite(invite.id)
                          showDashboard()
                        }}
                        handleCancel={() => setAcceptEULAOpen(false)}
                      />
                    </Fragment>
                  ))}
                </Grid>

                {signInStatus.canContinue && (
                  <Grid item key="get-started-button">
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={() => handleGetStarted()}
                      fullWidth={true}
                      startIcon={<FiLogIn />}
                      variant="contained"
                      size="small"
                      disabled={!signInStatus.canContinue}
                    >
                      Get Started
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {isSignUpOrganisationAllowed && (
              <Grid>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="caption">
                    <Button onClick={handleSignUp}>
                      <Typography
                        variant="caption"
                        sx={{ textTransform: "none" }}
                      >
                        Sign Up New Organisation
                      </Typography>
                    </Button>
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="caption">
                  Lost your password?{" "}
                  <Button onClick={() => setEnterEmailDialogOpen(true)}>
                    <Typography
                      variant="caption"
                      sx={{ textTransform: "none" }}
                    >
                      Reset Password
                    </Typography>
                  </Button>
                </Typography>
              </Box>
            </Grid>
          </Paper>
        </Box>
      </Box>

      <Box sx={styles.signOut}>
        {signInStatus.canSignOut && (
          <Typography variant="caption">
            <LinkButton onClick={handleSignOut}>
              <Typography variant="caption">Sign Out</Typography>
            </LinkButton>
          </Typography>
        )}
      </Box>

      {/* <Box sx={styles.versionBox}>
                <Box>
                    <Typography variant="caption" sx={styles.versionNo}>
                        v{version}
                    </Typography>
                </Box>
            </Box> */}
    </>
  )
}

const EnterEmailDialog = (props) => {
  const { open, setOpen, handleSendResetPasswordLink } = props

  const [email, setEmail] = useState("")

  const handleClickOK = () => {
    if (isEmailValid(email)) {
      handleSendResetPasswordLink(email)
    }
  }

  useEffect(() => {
    if (open) {
      setEmail("")
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>Receive a password reset email</DialogContentText>
        <TextField
          autoFocus
          sx={{ marginTop: "20px" }}
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          value={email}
          fullWidth
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          <Typography sx={{ textTransform: "none" }}>Cancel</Typography>
        </Button>
        <Button
          onClick={() => handleClickOK(email)}
          color="primary"
          endIcon={<Icons.EmailIcon />}
        >
          <Typography sx={{ textTransform: "none" }}>Send Link</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SignIn
