/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useMemo, useState } from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import * as UIConstants from "./UIConstants"
import { getErrorProps } from "./FieldValidation"
import { Typography } from "@mui/material"

export default function ComboBox(props) {
  const {
    name,
    label,
    value,
    onChange,
    items,
    disabled = false,
    noneLabel = "-- Select --",
    helperText,
    variant = "outlined",
    showError = false,
    errorFuncs = [],
    ...other
  } = props

  const [inputValue, setInputValue] = useState("")

  const findInitialValue = () => {
    const matched = items.find((option) => option.id === value)
    if (matched) {
      return matched
    }
    return { id: "", label: noneLabel }
  }

  const updateVal = (val) => {
    const selectedValue = val ? val.id : ""
    const payload = { target: { name: name, value: selectedValue } }
    onChange(payload)
  }

  const getOptionSelected = (option, value) => {
    return option.id === value.id
  }

  const errorProps = useMemo(() => {
    if (showError && errorFuncs) {
      return getErrorProps({ value, showError, errorFuncs })
    }
  }, [showError, errorFuncs, value])

  return (
    <Autocomplete
      getOptionLabel={(option) => option.label || ""}
      isOptionEqualToValue={getOptionSelected}
      autoHighlight
      autoSelect
      autoComplete
      size={UIConstants.STANDARD_INPUT_SIZE}
      id={name}
      value={findInitialValue()}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onChange={(_, newValue) => updateVal(newValue)}
      options={items}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={disabled ? UIConstants.STANDARD_INPUT_VARIANT : variant}
          error={errorProps ? errorProps.error : false}
          helperText={
            <Typography variant="caption" component={"span"}>
              {errorProps ? errorProps.helperText : helperText}
            </Typography>
          }
        />
      )}
      {...other}
    />
  )
}
