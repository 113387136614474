import React, { useMemo } from "react"
import moment from "moment"
import { Stack, Typography } from "@mui/material"
import ReactTimeAgo from "react-time-ago"

export default function CreatedLabel({ title, value, history }) {
  const creatorEmail = useMemo(() => {
    if (history && history.length > 0 && history[0].status === "open") {
      return history[0].user
    }
    return ""
  }, [history])

  return (
    <Stack sx={{ alignContent: "flex-end" }}>
      <Typography variant="caption">
        {title} {moment(value.toDate()).format("D-MMM-yyyy hh:mm a")}
      </Typography>

      <Typography variant="caption" sx={{ fontWeight: "bold" }}>
        {creatorEmail}
      </Typography>

      <Typography variant="caption">
        (<ReactTimeAgo date={value.toDate()} locale="en-AU" />)
      </Typography>
    </Stack>
  )
}
