import React, { useState } from "react"
import CentreEditForm from "../components/CentreEditForm"
import Header from "../components/Header"
import { useId } from "./useId"

const CentreEdit = (props) => {
    let { id } = useId(props)

    const [title, setTitle] = useState("Edit Centre")

    return (
        <Header title={title}>
            <CentreEditForm id={id} setTitle={setTitle} />
        </Header>
    )
}

export default CentreEdit
