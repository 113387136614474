import React, { useMemo } from "react"
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
} from "@mui/material"
import TruncatedText from "./TruncatedText"
import LabelValue from "./LabelValue"
import CentreName from "./CentreName"
import { format } from "date-fns"

const AssetTile = ({ asset, handleEditAsset, centres }) => {

  const closeMenuAndExecute = (callback) => {
    callback(asset)
  }

  const centre = useMemo(() => {
    return centres?.find((c) => c.id === asset.centre_id)
  }, [asset, centres])

  return (
    <>
      <Card sx={{ width: "300px" }}>
        <CardHeader
          title={
            <TruncatedText
              variant="body1"
              noWrap
              sx={{
                fontWeight: "bold",
                marginBottom: "5px",
                maxWidth: "240px",
              }}
            >
              {asset.name}
            </TruncatedText>
          }
          subheader={
            <TruncatedText
              variant="body2"
              noWrap
              sx={{
                maxWidth: "240px",
              }}
            >
              {asset.make} {asset.model}
            </TruncatedText>
          }
        />
        <CardActionArea onClick={() => closeMenuAndExecute(handleEditAsset)}>
          <CardContent sx={{ padding: "15px" }}>
            <Stack gap={0.5}>
              <LabelValue
                label="Serial No."
                value={
                  <TruncatedText
                    variant="body2"
                    sx={{ width: "180px" }}
                    textAlign={"right"}
                  >
                    {asset.sn}
                  </TruncatedText>
                }
              />
              <LabelValue
                label="Centre"
                value={
                  <CentreName
                    truncateWidth="100px"
                    centre={centre}
                    textAlign="right"
                  />
                }
              />
              <LabelValue label="Status" value={asset.status} />
              <LabelValue
                label="Next Service"
                value={
                  asset.next_service &&
                  format(asset.next_service.toDate(), "dd MMM yy")
                }
              />
              <LabelValue
                label="Acquired"
                value={
                  asset.acquired && format(asset.acquired.toDate(), "dd MMM yy")
                }
              />
              <LabelValue
                label="Disposed"
                value={
                  asset.disposed && format(asset.disposed.toDate(), "dd MMM yy")
                }
              />
              <LabelValue
                label="Warranty End"
                value={
                  asset.warranty_end &&
                  format(asset.warranty_end.toDate(), "dd MMM yy")
                }
              />
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

export default AssetTile
