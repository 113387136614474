import firebase from "firebase/compat/app"
import * as dataServices from "./dataServices"
import db from "../../Firestore"
import { format } from "date-fns"
import * as dateServices from "./dateServices"
import * as workOrderStatus from "../../components/workOrderStatus"
import { where } from "firebase/firestore"
import { Avatar, colors } from "@mui/material"
import * as icons from "../../icons"

const isArraysMatch = (arr1, arr2, logMsg) => {
  if (!arr1 || !arr2) {
    return false
  }

  if (arr1.length !== arr2.length) return false

  return [...arr1]
    .sort()
    .every((value, index) => value === [...arr2].sort()[index])
}

const updateJobSupplierAccess = async ({
  jobs,
  supplierAccountIds,
  exclusive,
}) => {
  // Update the jobs

  console.log(
    "%c[Supplier access] update job supplier access",
    "color:yellow",
    { jobs, supplierAccountIds, exclusive }
  )

  const changes = []

  for (const [index, job] of jobs.entries()) {
    const newAccountIds = exclusive
      ? supplierAccountIds
      : firebase.firestore.FieldValue.arrayUnion(...supplierAccountIds)

    const jobUpdate = {
      modified: dataServices.serverTimestamp(),
      supplier_access_account_ids: newAccountIds,
    }

    await db.collection("jobs").doc(job.id).update(jobUpdate, { merge: true })

    changes.push(
      `[Supplier access] Job #${job.id} ${accessSummary(supplierAccountIds)}`
    )
  }

  return changes
}

const getSupplierQueryParams = ({ direction, pag, currentUser }) => {
  return {
    accountId: currentUser.claims.account_id,
    orderBy: pag.orderBy,
    // If order is 'prev' then swap the order
    order:
      direction === "next" ? pag.order : pag.order === "desc" ? "asc" : "desc",
    jobTypesFilter: pag.job_types,
    // supplier name
    name: pag.name,
    activeOnly: pag.active_only,
    first_name: pag.first_name,
    last_name: pag.last_name,
  }
}

const getSupplierAvatar = ({ online }) =>
  online ? (
    <Avatar
      sx={{
        backgroundColor: "#fff",
        border: `1px solid ${colors.pink[500]}`,
      }}
    >
      <icons.SupplierIcon sx={{ color: colors.pink[500] }} />
    </Avatar>
  ) : (
    <Avatar>
      <icons.SupplierIcon />
    </Avatar>
  )

const createSupplierQuery = ({
  accountId,
  jobTypesFilter,
  name,
  activeOnly,
}) => {
  const queryConstraints = []

  queryConstraints.push(where("account_id", "==", accountId))

  if (name) {
    console.log("%cadd name lower searcb", "color:orange", name)
    queryConstraints.push(
      ...dataServices.addStartsWithConstraint({
        value: name,
        fieldName: "name_lower",
      })
    )
  }

  if (activeOnly) {
    queryConstraints.push(where("active", "==", true))
  }

  if (jobTypesFilter && jobTypesFilter.length > 0) {
    queryConstraints.push(
      where("job_types", "array-contains-any", jobTypesFilter)
    )
  }

  console.log(
    "%csupplier query",
    "color:yellow",
    dataServices.queryConstraintsToString(queryConstraints)
  )

  return queryConstraints
}

const createSupplierContactsQuery = ({ accountId, firstName, lastName }) => {
  const queryConstraints = []

  queryConstraints.push(where("account_id", "==", accountId))
  queryConstraints.push(where("parent_type", "==", "supplier"))

  console.log("createSupplierContactsQuery", { accountId, firstName, lastName })

  if (firstName) {
    queryConstraints.push(
      ...dataServices.addStartsWithConstraint({
        value: firstName,
        fieldName: "first_name_lower",
      })
    )
  }

  if (lastName) {
    queryConstraints.push(
      ...dataServices.addStartsWithConstraint({
        value: lastName,
        fieldName: "last_name_lower",
      })
    )
  }

  return queryConstraints
}

const loadWorkOrder = async (workOrderId) => {
  const workOrderRef = await db.collection("work_orders").doc(workOrderId).get()
  if (!workOrderRef.exists) {
    return undefined
  }
  const workOrder = workOrderRef.data()
  return workOrder
}

const loadJobs = async ({ workOrderId, accountId }) => {
  const jobsQuerySnapshot = await db
    .collection("jobs")
    .where("account_id", "==", accountId)
    .where("work_order_id", "==", workOrderId)
    .get()

  const jobs = jobsQuerySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }))
  return jobs
}

const loadQuotes = async ({ workOrderId, accountId }) => {
  const quotesQuerySnapshot = await db
    .collection("work_orders")
    .doc(workOrderId)
    .collection("quotes")
    .where("account_id", "==", accountId)
    .get()

  const quotes = quotesQuerySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }))

  return quotes
}

const loadQuoteSuppliers = async ({ quotes, accountId }) => {
  const supplierIds = Array.from(
    new Set(quotes.map((q) => q.supplier_id).filter((id) => id))
  )

  const suppliers =
    supplierIds.length > 0
      ? await dataServices.getSuppliersById(supplierIds, accountId)
      : []

  return suppliers
}

const loadWorkOrderSupplier = async ({ workOrder, quoteSuppliers }) => {
  let workOrderSupplier
  if (workOrder.supplier_id) {
    workOrderSupplier = quoteSuppliers.find(
      (s) => s.id === workOrder.supplier_id
    )

    if (!workOrderSupplier) {
      const supplierDoc = await db
        .collection("suppliers")
        .doc(workOrder.supplier_id)
        .get()
      workOrderSupplier = supplierDoc.data()
    }
  }
  return workOrderSupplier
}

const accessSummary = (supplierAccountIds) => {
  return supplierAccountIds && supplierAccountIds.length > 0
    ? `accessed by ${supplierAccountIds.join(", ")}`
    : "not accessible"
}

/**
 *
 * @param {loadedDocs} contains any pre-loaded docs, can include workOrder, jobs, quotes, and/or suppliers (from the quotes) and these should be used if provided in preference to re-loading these docs in this function
 * @returns
 */
const updateSupplierAccess = async ({
  workOrderId,
  loadedDocs = {},
  source = "",
}) => {
  console.log("%c[Supplier access] update supplier access", "color:yellow", {
    workOrderId,
    source,
  })
  const changeLog = []

  const workOrder = loadedDocs.workOrder || (await loadWorkOrder(workOrderId))

  console.log("%c[Supplier access] work order", "color:yellow", { workOrder })

  // Work order could be undefined if it was deleted but the quote still exists and references that
  // now-deleted work order. This shouldn't happen so this is just defensive programming.
  if (workOrder === undefined) {
    console.log("%c[Supplier access] work order not found", "color:yellow", {
      workOrderId,
      source,
    })
    return changeLog
  }

  const jobs =
    loadedDocs.jobs ||
    (await loadJobs({ workOrderId, accountId: workOrder.account_id }))

  const quotes =
    loadedDocs.quotes ||
    (await loadQuotes({ workOrderId, accountId: workOrder.account_id }))

  const quoteSuppliers =
    loadedDocs.suppliers ||
    (await loadQuoteSuppliers({ quotes, accountId: workOrder.account_id }))

  // There will not be a workOrderSupplier if the work order was allocated to staff, i.e. work order has a maint_user_id value
  const workOrderSupplier =
    workOrder.supplier_id &&
    (await loadWorkOrderSupplier({
      workOrder,
      quoteSuppliers,
    }))

  console.log("%c[Supplier access] work order supplier", "color:yellow", {
    workOrderSupplier,
    supplier_id: workOrder.supplier_id,
    maint_user_id: workOrder.maint_user_id,
  })

  // Rules for what the supplier can access.
  // 1. If the work order is in a quoting state, then the supplier can see the work order, jobs, and only their quotes
  // 2. If the work order is in progress with a supplier, i.e. allocated to a supplier or another inprogress status, then only that supplier can see the work order and jobs, and no other suppliers can see the work order, jobs, or quotes

  const isInQuotingState = workOrderStatus.isQuotingStatus(workOrder.status)
  const isWithSupplierState = workOrderStatus.isWithSupplierStatus(
    workOrder.status
  )
  const isNewOpenState = workOrderStatus.isNewOrOpenStatus(workOrder.status)

  console.log("%c[Supplier access] work order status", "color:yellow", {
    isInQuotingState,
    isWithSupplierState,
    isNewOpenState,
  })

  if (isInQuotingState) {
    const quoteSupplierAccountIds = Array.from(
      new Set(
        quoteSuppliers.map((s) => s.supplier_account_id).filter((id) => id)
      )
    )
    // Update the work order

    console.log(
      "%c[Supplier access] quote SupplierAccountIds",
      "color:yellow",
      quoteSupplierAccountIds
    )
    if (
      !isArraysMatch(
        // Might not be a supplier assigned yet, hence will not be any suppler_access_account_ids
        workOrder.supplier_access_account_ids || [],
        quoteSupplierAccountIds,
        `work order ${workOrderId}`
      )
    ) {
      const workOrderUpdate = {
        supplier_access_account_ids: quoteSupplierAccountIds,
        modified: dataServices.serverTimestamp(),
      }

      await db
        .collection("work_orders")
        .doc(workOrderId)
        .update(workOrderUpdate)
    }
    changeLog.push(
      `[Supplier access] work order #${workOrderId} ${accessSummary(
        quoteSupplierAccountIds
      )}`
    )

    // Update the jobs

    console.log("%c[Supplier access] is in quoting state?", "color:yellow", {
      isInQuotingState,
    })

    const jobChanges = await updateJobSupplierAccess({
      jobs,
      supplierAccountIds: quoteSupplierAccountIds,
      exclusive: true,
    })
    changeLog.push(...jobChanges)

    // Update the quotes

    const quoteChanges = await grantAccessToQuotes({
      quotes,
      suppliers: quoteSuppliers,
    })
    changeLog.push(...quoteChanges)
  } else if (isWithSupplierState) {
    // Make sure only the supplier who is allocated to the work order can see the work order, jobs, and their quote (if any)

    // There will not be a workOrderSupplier if the work order is allocated to an internal staff member
    // in which case the workOrderSupplierAccountIds will be empty, i.e. []
    const workOrderSupplierAccountIds = workOrderSupplier?.supplier_account_id
      ? [workOrderSupplier.supplier_account_id]
      : []

    if (
      !isArraysMatch(
        workOrder.supplier_access_account_ids || [],
        workOrderSupplierAccountIds,
        `work order ${workOrderId}`
      )
    ) {
      console.log(
        "%c[Supplier access] work order supplier account ids",
        "color:yellow",
        {
          required: workOrderSupplierAccountIds,
          current: workOrder.supplier_access_account_ids,
        }
      )
      const workOrderUpdate = {
        supplier_access_account_ids: workOrderSupplierAccountIds,
        modified: dataServices.serverTimestamp(),
      }

      await db
        .collection("work_orders")
        .doc(workOrderId)
        .update(workOrderUpdate)
    }
    changeLog.push(
      `[Supplier access] #${workOrderId} ${accessSummary(
        workOrderSupplierAccountIds
      )}`
    )

    const jobChanges = await updateJobSupplierAccess({
      jobs,
      supplierAccountIds: workOrderSupplierAccountIds,
      exclusive: true,
    })
    changeLog.push(...jobChanges)

    const quoteGrantChanges = await grantAccessToQuotes({
      quotes,
      suppliers: quoteSuppliers.filter((s) => s.id === workOrder.supplier_id),
    })
    changeLog.push(...quoteGrantChanges)

    const quoteRevokeChanges = await removeAccessToQuotes({
      quotes,
      suppliers: quoteSuppliers.filter((s) => s.id !== workOrder.supplier_id),
    })
    changeLog.push(...quoteRevokeChanges)
  } else if (isNewOpenState) {
    // Supplier cannot see the work order, jobs, or quotes

    if (
      workOrder.supplier_access_account_ids &&
      workOrder.supplier_access_account_ids.length > 0
    ) {
      const workOrderUpdate = {
        supplier_access_account_ids: [],
        account_id: workOrder.account_id,
        modified: dataServices.serverTimestamp(),
      }

      await db
        .collection("work_orders")
        .doc(workOrderId)
        .update(workOrderUpdate, { merge: true })

      changeLog.push(`[Supplier access] #${workOrderId} accessed by []`)
    } else {
      changeLog.push(
        `[Supplier access] #${workOrderId} ${accessSummary(
          workOrder.supplier_access_account_ids
        )}`
      )
    }

    console.log(
      "%c[Supplier access] remove access to jobs from suppliers",
      "color:yellow",
      {
        jobs,
      }
    )

    const jobChanges = await updateJobSupplierAccess({
      jobs,
      supplierAccountIds: [],
      exclusive: true,
    })
    changeLog.push(...jobChanges)

    const quoteRevokeChanges = await removeAccessToQuotes({ quotes })
    changeLog.push(...quoteRevokeChanges)
  } else {
    console.log("unknown work order status", workOrder.status)
  }

  return changeLog
}

const formatExpiry = (doc) => {
  return format(doc.expiry.toDate(), "dd-MMM-yyyy")
}

const getSupplierDocStatuses = (supplier, supplierDocTypes) => {
  const existingDocsSummary =
    (supplier.docs && supplier.docs.map((doc) => getSupplierDocStatus(doc))) ||
    []

  // Then, see what docs they have that are no longer in the list of doc types

  const missingDocTypes = supplierDocTypes.filter(
    (docType) =>
      existingDocsSummary.find((doc) => doc.type === docType) === undefined
  )

  const missingDocsSummary = missingDocTypes.map((docType) => ({
    type: docType,
    severity: "error",
    text: `No ${docType} document provided`,
  }))

  return [...existingDocsSummary, ...missingDocsSummary]
}

// Each quote can only be accessed by 1 supplier, and that access mechanism depends on the supplier's 'supplier_account_id' attribute being in the quote
// We only need to do this processing for quotes that have a supplier_account_id attribute
// This is different to work orders and jobs, which allow 0 or more suppliers to have access, and that uses a different attribute: 'supplier_access_account_ids'
const removeAccessToQuotes = async ({ quotes }) => {
  const changes = []

  for (const [index, quote] of quotes
    .filter((quote) => quote.supplier_account_id)
    .entries()) {
    // See if quote needs to be updated

    if (quote.supplier_account_id) {
      const quoteUpdate = {
        supplier_account_id: firebase.firestore.FieldValue.delete(),
        modified: dataServices.serverTimestamp(),
      }

      changes.push(
        `Removed access to quote #${quote.id} from supplier ${quote.supplier_id}`
      )

      db.collection("work_orders")
        .doc(quote.work_order_id)
        .collection("quotes")
        .doc(quote.id)
        .update(quoteUpdate)
    }
  }

  return changes
}

// For suppliers that have a supplier_account_id, find their corresponding quote and update it if necessary to have that supplier_account_id value.
// This is the mechanism that allows the external supplier to see their quotes when they're logged in, and required for firestore.rules
const grantAccessToQuotes = async ({ quotes, suppliers }) => {
  const changes = []

  for (const [index, supplier] of suppliers.entries()) {
    const quote = quotes.find((quote) => quote.supplier_id === supplier.id)

    if (quote && supplier.supplier_account_id && !quote.supplier_account_id) {
      changes.push(
        `Updated quote #${quote.id} supplier access to ${supplier.supplier_account_id}`
      )

      const quoteUpdate = {
        supplier_account_id: supplier.supplier_account_id,
        modified: dataServices.serverTimestamp(),
      }

      db.collection("work_orders")
        .doc(quote.work_order_id)
        .collection("quotes")
        .doc(quote.id)
        .update(quoteUpdate)
    }
  }

  return changes
}

const getSupplierDocStatus = (doc) => {
  if (!doc.expiry) {
    return {
      type: doc.type || "No document type selected",
      severity: "error",
      text: `No expiry date provided`,
    }
  } else if (!dateServices.isFutureDate(doc.expiry.toDate())) {
    return {
      type: doc.type,
      severity: "error",
      text: `Expired ${formatExpiry(doc)}`,
    }
  } else if (dateServices.isDateWithin3Months(doc.expiry.toDate())) {
    return {
      type: doc.type,
      severity: "warning",
      text: `Expires ${formatExpiry(doc)}`,
    }
  } else {
    return {
      type: doc.type,
      severity: "success",
      text: `Expires ${formatExpiry(doc)}`,
    }
  }
}

const loadSupplierDocTypes = async (accountId) => {
  let query = db
    .collection("lookups")
    .where("account_id", "==", accountId)
    .where("name", "==", "supplier_doc_types")

  const supplierDocTypes = await dataServices.loadData(
    `Load supplier doc types`,
    query
  )

  return supplierDocTypes.length === 1 ? supplierDocTypes[0].lookup_values : []
}

export {
  loadSupplierDocTypes,
  getSupplierDocStatus,
  updateSupplierAccess,
  getSupplierDocStatuses,
  getSupplierQueryParams,
  createSupplierQuery,
  createSupplierContactsQuery,
  getSupplierAvatar,
}
