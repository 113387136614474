import { Card, CircularProgress, Stack } from "@mui/material"
import { Typography } from "@mui/material"
import {
  Alert,
  CardContent,
  CardHeader,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Box,
} from "@mui/material"
import React, { useState, useEffect, useMemo } from "react"
import StatusAvatar from "./controls/StatusAvatar"
import * as dataServices from "../pages/services/dataServices"
import * as scheduleServices from "../pages/services/scheduleServices"
import * as jobServices from "../pages/services/jobServices"
import * as icons from "../icons"
import moment from "moment"
import { tsToDate } from "../pages/services/dateServices"
import firebase from "firebase/compat/app"
import * as Roles from "../pages/services/roleServices"
import _ from "lodash"
import db from "../Firestore"
import { spacing } from "../pages/services/styleServices"
import JobStatusChip from "./controls/JobStatusChip"
import AutoSizingImage from "./AutoSizeImage"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const styles = {
  card: {
    margin: spacing(0.5),
    marginTop: "20px",
    // so that images do not overlap page boundaries
    pageBreakBefore: "auto",
    pageBreakAfter: "auto",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    backgroundColor: "#ddd",
    padding: spacing(0.5),
    display: "flex",
    flexDirection: "row",
  },
}

const WorkOrderFullListForm = (props) => {
  // supplierId is passed in the URL
  const [supplierId] = useState(props.computedMatch.params.supplierId)

  const [workOrderId] = useState(props.computedMatch.params.workOrderId)

  const [workOrders, setWorkOrders] = useState([])

  const [jobs, setJobs] = useState([])

  const [accountId, setAccountId] = useState()

  const [centres, setCentres] = useState([])

  const [supplier, setSupplier] = useState()

  const [isCentreAccount, setCentreAccount] = useState()

  const [priorities, setPriorities] = useState()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult().then((token) => {
        setAccountId(token.claims.account_id)
        setCentreAccount(
          token.claims.account_type === Roles.ACCOUNT_TYPE_CENTRE
        )
      })
    })

    return unsub
  }, [])

  useEffect(() => {
    if (isCentreAccount === undefined) {
      return
    }

    if (accountId && supplierId) {
      loadSupplierWorkOrders(accountId, supplierId, isCentreAccount)
    } else if (accountId && workOrderId) {
      loadSingleWorkOrder(accountId, workOrderId, isCentreAccount, supplierId)
    }
  }, [accountId, supplierId, workOrderId, isCentreAccount])

  const loadSingleWorkOrder = async (
    accountId,
    workOrderId,
    isCentreAccount
  ) => {
    if (isCentreAccount === undefined) {
      return
    }

    let workOrders = []

    if (isCentreAccount) {
      workOrders = await dataServices.getWorkOrdersById({
        workOrderIds: [workOrderId],
        accountId,
      })
    } else {
      const workOrder = await db
        .collection("work_orders")
        .where(firebase.firestore.FieldPath.documentId(), "==", workOrderId)
        .where("supplier_access_account_ids", "array-contains", accountId)
        .get()

      workOrders = workOrder.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    }

    if (!isCentreAccount) {
      db.collection("jobs")
        .where("supplier_access_account_ids", "array-contains", accountId)
        .where("work_order_id", "==", workOrderId)
        .get()
        .then((querySnapshot) => {
          const jobs = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          setJobs(jobs)
        })
    }

    const { jobs, centres } = await dataServices.getSupportingWorkOrderInfo({
      workOrders,
      userAccountId: accountId,
      isCentreAccount,
    })

    // There could be no supplier set if we have requested quotes from multiple suppliers
    if (isCentreAccount) {
      if (workOrders[0].supplier_id) {
        const suppliers = await dataServices.getSuppliersById(
          [workOrders[0].supplier_id],
          accountId
        )
        const supplier = suppliers[0]
        setSupplier(supplier)
      }
    } else {
      db.collection("suppliers")
        .where("supplier_account_id", "==", accountId)
        // should only get 1 record anyway...
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const supplier = { id: doc.id, ...doc.data() }
            setSupplier(supplier)
          })
        })
    }

    setWorkOrders(workOrders)
    setJobs(jobs)
    setCentres(centres)

    if (jobs.length > 0) {
      jobServices
        .getJobPriorities(jobs[0].account_id)
        .then((result) => setPriorities(result))
    }

    setIsLoading(false)
  }

  const getSubHeader = (wo) => {
    const desc = scheduleServices.getScheduleDescription(wo.schedule)

    // For date formatting...
    // 'Do' outputs the day number with st, th, nd appended correctly.
    // 'dddd' outputs the full day name
    return desc === ""
      ? `Start date: ${moment(tsToDate(wo.start_date)).format(
          "dddd, Do MMMM YYYY"
        )}`
      : `Occurring ${desc}`
  }

  const loadSupplierWorkOrders = async (
    accountId,
    supplierId,
    isCentreAccount
  ) => {
    if (isCentreAccount === undefined) {
      return
    }

    const { workOrders, jobs, centres } =
      await dataServices.getSupplierWorkOrders(
        accountId,
        supplierId,
        isCentreAccount
      )

    const suppliers = await dataServices.getSuppliersById(
      [supplierId],
      accountId
    )
    const supplier = suppliers[0]

    setWorkOrders(workOrders)
    setJobs(jobs)
    setCentres(centres)
    setSupplier(supplier)
  }

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full height of the viewport
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end", margin: "5px" }}>
        <Typography variant="caption">
          {jobs && jobs.length > 0 && `${jobs.length} jobs`}
        </Typography>
      </Box>
      {workOrders &&
        workOrders
          .sort((a, b) => a.work_order_no - b.work_order_no)
          .map((wo) => (
            <Card key={wo.id}>
              <CardHeader
                avatar={<StatusAvatar status={wo.status} />}
                title={
                  <Typography variant="h4">
                    Work Order {wo.work_order_no} | {wo.label}
                  </Typography>
                }
                subheader={
                  <Typography variant="h6">{getSubHeader(wo)}</Typography>
                }
              />
              <CardContent>
                <WorkOrder
                  key={wo.id}
                  maintUserId={wo.maint_user_id}
                  jobs={jobs.filter((job) => job.work_order_id === wo.id)}
                  centres={centres}
                  supplier={supplier}
                  priorities={priorities}
                />
              </CardContent>
            </Card>
          ))}
      {workOrders && workOrders.length === 0 && supplier && (
        <Alert severity="info">{`No open work orders for ${supplier.name}`}</Alert>
      )}
    </>
  )
}

const WorkOrder = ({ jobs, centres, supplier, priorities, maintUserId }) => {
  const [maintUser, setMaintUser] = useState()

  const jobsByCentre = useMemo(() => {
    if (!jobs) {
      return {}
    }
    return _.groupBy(jobs, "centre_id")
  }, [jobs])

  const centresForWorkOrder = useMemo(() => {
    if (!centres) {
      return []
    }
    return centres.filter((centre) => jobsByCentre[centre.id])
  }, [centres, jobsByCentre])

  useEffect(() => {
    if (maintUserId) {
      db.collection("users")
        .doc(maintUserId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setMaintUser(doc.data())
          }
        })
    }
  }, [maintUserId])

  const getCentreName = (centreId) => {
    const centre = centres.find((centre) => centre.id === centreId)

    return centre ? centre.name : "No centre"
  }

  const getImageTitle = (elements) => {
    return `${elements.filter((item) => item !== "").join(" | ")}`
  }

  const getFileInfo = ({ job, fileName }) => {
    return job?.file_info?.find((fi) => fi.file_name === fileName)
  }

  const isCentrePhoto = ({ job, fileName }) => {
    const fileInfo = getFileInfo({ job, fileName })
    return fileInfo === undefined || fileInfo.account_id === job.account_id
  }

  return (
    <>
      {supplier && <SupplierDetails supplier={supplier} />}
      {Object.keys(jobsByCentre).length === 0 && (
        <Typography variant="h5">No jobs</Typography>
      )}
      {maintUser && (
        <Box sx={{ mt: "20px" }}>
          <Typography variant="h6">Allocated To</Typography>
          <Typography variant="body1">{maintUser.name}</Typography>
          <Typography variant="body1">{maintUser.email}</Typography>
          <Typography variant="body1">{maintUser.phone}</Typography>
        </Box>
      )}
      {jobsByCentre &&
        centresForWorkOrder.map((centre) => (
          <Box key={centre.id}>
            <Card elevation={0}>
              <CardContent>
                <CentreDetails
                  centres={centres}
                  centre={centre}
                  jobsByCentre={jobsByCentre}
                />
                <Box sx={{ marginTop: "20px" }}>
                  <Typography variant="h6">Job Summary</Typography>
                </Box>
                <JobSummaryTable
                  jobsByCentre={jobsByCentre}
                  priorities={priorities}
                  centre={centre}
                />
                <>
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography variant="h6">Job Photos</Typography>
                  </Box>
                  {jobsByCentre[centre.id]?.map((job) => (
                    <Box sx={{ marginTop: "20px" }} key={job.id}>
                      <Stack direction="row" gap={1} sx={{ align: "centre" }}>
                        <icons.JobIcon />
                        <Typography variant="h6">{job.label}</Typography>
                      </Stack>
                      <Box sx={{ marginLeft: "32px" }}>
                        <Typography variant="caption" color="primary">
                          {getImageTitle([
                            getCentreName(job.centre_id),
                            job.category,
                            job.location,
                          ])}
                        </Typography>
                      </Box>
                      {job.description && (
                        <Box sx={{ marginLeft: "35px", marginTop: "10px" }}>
                          <JobDescription
                            description={job.description}
                            variant="body2"
                          />
                        </Box>
                      )}
                      {job.files
                        .filter((file) => file !== "")
                        .map((file) => {
                          const fileInfo = getFileInfo({
                            job,
                            fileName: file.name,
                          })

                          const photoDate = fileInfo?.created
                            ? moment(fileInfo.created.toDate()).format(
                                "DD MMM YYYY hh:mm a"
                              )
                            : ""

                          const photoUploader = fileInfo?.email || ""

                          const subHeader = [photoUploader, photoDate]
                            .filter((item) => item)
                            .join(" | ")

                          return (
                            <div
                              className="avoid-page-break"
                              key={`${job.id}-${file.url}`}
                            >
                              <Card sx={styles.card} variant="outlined">
                                <CardHeader
                                  title={file.name}
                                  subheader={subHeader}
                                  avatar={
                                    isCentrePhoto({
                                      job,
                                      fileName: file.name,
                                    }) ? (
                                      <icons.CentreIcon />
                                    ) : (
                                      <icons.SupplierIcon />
                                    )
                                  }
                                />
                                <CardContent>
                                  <Stack direction="column">
                                    <AutoSizingImage url={file.url} />
                                  </Stack>
                                </CardContent>
                              </Card>
                            </div>
                          )
                        })}
                    </Box>
                  ))}
                </>
              </CardContent>
            </Card>
          </Box>
        ))}
    </>
  )
}

// Split the description into multiple lines
const JobDescription = ({ description, variant = "body1" }) => {
  const lines = useMemo(() => description.split("\n"), [description])

  return (
    <>
      {lines &&
        lines.map((line, index) => (
          <Typography key={index} variant={variant}>
            {line}
          </Typography>
        ))}
    </>
  )
}

const CentreDetails = ({ centres, centre, jobsByCentre }) => {
  return (
    <>
      <Box sx={styles.header}>
        <Typography variant="h5">
          <b>{centres.find((c) => c.id === centre.id).name}</b>
        </Typography>
        <Typography sx={{ display: "flex", marginLeft: "auto" }}>
          {jobsByCentre[centre.id]?.length} job
          {jobsByCentre[centre.id]?.length > 1 && "s"}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "5px",
          marginLeft: "5px",
        }}
      >
        <Typography variant="body1">{centre.email}</Typography>
        <Typography variant="body1">{centre.phone}</Typography>
        <Typography variant="body1">{centre.addr1}</Typography>
        <Typography variant="body1">{centre.addr2}</Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <Typography variant="body1">{centre.city}</Typography>
          <Typography variant="body1">{centre.state}</Typography>
          <Typography variant="body1">{centre.postcode}</Typography>
        </Box>
      </Box>
    </>
  )
}

const JobSummaryTable = ({ jobsByCentre, priorities, centre }) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {jobsByCentre[centre.id]?.map((job, index) => (
            <TableRow key={job.id}>
              <TableCell sx={{ width: "15px" }}>
                <Typography variant="body1">{index + 1}</Typography>
              </TableCell>
              <TableCell sx={{ width: "220px" }}>
                <Stack>
                  <Typography variant="body1">{job.label}</Typography>
                  <Box sx={{ width: "250px" }}>
                    <JobDescription
                      description={job.description}
                      variant="caption"
                    />
                  </Box>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="column">
                  <Typography variant="body1">{job.location}</Typography>
                  <Typography variant="body1">{job.category}</Typography>
                </Stack>
              </TableCell>
              <TableCell sx={{ minWidth: "90px" }}>
                <Typography variant="body1">
                  {priorities &&
                    jobServices.getPriorityLabel(
                      priorities.lookup_values.find(
                        (v) => v.id === job.priority
                      )
                    )}
                </Typography>
              </TableCell>
              <TableCell>
                <JobStatusChip status={job.status} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const SupplierDetails = ({ supplier }) => {
  return (
    <Box
      sx={{
        marginBottom: "20px",
        marginTop: "5px",
        marginLeft: "5px",
      }}
    >
      <Typography variant="body1">{supplier.name}</Typography>
      <Typography variant="body1">{supplier.email}</Typography>
      <Typography variant="body1">{supplier.phone}</Typography>
      <Typography variant="body1">{supplier.addr1}</Typography>
      <Typography variant="body1">{supplier.addr2}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
        }}
      >
        <Typography variant="body1">{supplier.city}</Typography>
        <Typography variant="body1">{supplier.state}</Typography>
        <Typography variant="body1">{supplier.postcode}</Typography>
      </Box>
    </Box>
  )
}

export default WorkOrderFullListForm
