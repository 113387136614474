import React, { useState, useMemo, useEffect } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import { useSnackbar } from "notistack"
import {
  Button,
  Box,
  LinearProgress,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  TextField,
  Alert,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material"
import QrCode2Icon from "@mui/icons-material/QrCode2"
import EmailIcon from "@mui/icons-material/Email"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {
  isQROTPCorrect,
  createUserAndGetToken,
  createAndSendOTPViaEmail,
  isEmailOTPCorrect,
  isExistingOTP,
  getUserStatus,
} from "../pages/services/cloudFunctions"
import { isEmailValid } from "../pages/services/emailServices"
import PasswordFields from "./PasswordFields"
import { BLACK } from "../pages/services/styleServices"
import LogoFull from "./LogoFull"
import * as UIConstants from "./controls/UIConstants"
import {
  getAuth,
  EmailAuthProvider,
  signInWithCredential,
  signInWithCustomToken,
} from "firebase/auth"

const LoginDialog = ({ open, setOpen, loginInputs, email }) => {
  const [isShowProgress, setShowProgress] = useState(false)

  const QRCODE = "qrcode"

  const EMAIL = "email"

  const [deliveryMethod, setDeliveryMethod] = useState(EMAIL)

  const [isSendingOTP, setIsSendingOTP] = useState(false)

  const [showUserInactiveMessage, setShowUserInactiveMessage] = useState(false)

  const { values, setValues, handleInputChange } = useForm({
    otp: "",
    phone: { country: "au" },
    old_password: "",
    new_password_1: "",
    new_password_2: "",
  })

  const [showGoogleIsProvider, setShowGoogleIsProvider] = useState(false)

  const [isInvalidCredentials, setInvalidCredentials] = useState(false)

  const isDevelopmentMode = useMemo(
    () => process.env.NODE_ENV === "development",
    []
  )

  const [isEmailOTPSent, setEmailOTPSent] = useState(false)

  const isPasswordDefinedInFirebase = useMemo(() => {
    if (loginInputs.providerIds === undefined) {
      return undefined
    }
    return loginInputs.providerIds.includes("password")
  }, [loginInputs.providerIds])

  const isPasswordEntered = useMemo(() => {
    return isPasswordDefinedInFirebase
      ? values.old_password.length > 0
      : values.new_password_1.length > 0 && values.new_password_2.length > 0
  }, [isPasswordDefinedInFirebase, values])

  // Should the user be allowed to use a QR code to authenticate? Only if they're an existing user.
  // Need to use email based OTP initially to prove you have control over the email account.
  const isAllowQROTP = useMemo(() => {
    return loginInputs.emailDefinedInGoogle && loginInputs.isQrCodeSecretDefined
  }, [loginInputs])

  useEffect(() => {
    if (isAllowQROTP) {
      if (deliveryMethod === "") {
        setDeliveryMethod(QRCODE)
      }
    }
  }, [isAllowQROTP, deliveryMethod])

  useEffect(() => {
    if (email) {
      getUserStatus({ email }).then((result) => {
        if (result === "INACTIVE") {
          setShowUserInactiveMessage(true)
        }
      })
    }
  }, [email])

  const deliveryMethodLabel = useMemo(() => {
    if (deliveryMethod === QRCODE) {
      return "Get 6-digit code via authenticator app"
    } else {
      return "Get 6-digit code via email"
    }
  }, [deliveryMethod])

  const isCodeEntered = useMemo(() => values.otp.length > 0, [values.otp])

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setShowProgress(false)
    setOpen(false)
  }

  const handleLogIn = async () => {
    const userStatus = await getUserStatus({ email })
    if (userStatus === "INACTIVE") {
      enqueueSnackbar("User is not active", { variant: "warning" })
      return
    }

    if (deliveryMethod === QRCODE) {
      handleValidateQRCodeOTP()
    } else {
      handleValidateEmailOTP()
    }
  }

  const handleValidateQRCodeOTP = async () => {
    if (values.otp === null || values.otp.length !== 6) {
      enqueueSnackbar("Enter 6-digit code", { variant: "warning" })
      return
    }

    setShowProgress(true)

    setInvalidCredentials(false)

    let result

    // Check if user already defined in Firebase, i.e. does NOT mean a gmail address
    if (loginInputs.emailDefinedInGoogle) {
      const result = await isQROTPCorrect(values.otp, email)

      //console.log("%cQR OTP result", "color:lightgreen", { result })

      if (result.status === "success") {
        try {
          if (loginInputs.providerIds.includes("google.com")) {
            setShowGoogleIsProvider(true)
            setInvalidCredentials(true)
            setShowProgress(false)
            return
          }

          const credential = EmailAuthProvider.credential(
            email,
            values.old_password
          )

          const auth = getAuth()
          const loginPwdResult = await signInWithCredential(auth, credential)
        } catch (error) {
          if (error.code === "auth/wrong-password") {
            console.error("wrong password", { error })
            setInvalidCredentials(true)
            setShowProgress(false)
            return
          } else {
            console.error("error", { error })
            enqueueSnackbar(error.message, { variant: "error" })
            setShowProgress(false)
            return
          }
        }
      } else if (result.status === "error") {
        //console.log("%cQR OTP match incorrect", { result })
        setInvalidCredentials(true)
        setShowProgress(false)
        return
      }
      // This block cannot occur. Can only attempt a QR code login if you already exist as a user
      // } else {

      //   //TODO: can this code block be reached? Should only be using QR OTP codes if you already exist as a user, and only for login/pwd based logins
      //   result = await createUserAndGetToken(email, values.new_password_1)

      //   if (result.hasOwnProperty("token")) {
      //     enqueueSnackbar("Email authenticated. Please wait...", {
      //       variant: "success",
      //     })
      //     firebase
      //       .auth()
      //       .signInWithCustomToken(result.token)
      //       .then(async (userCredential) => {})
      //       .catch((error) => {
      //         console.log("Error signing in", error)
      //       })
      //   }
    }
    setShowProgress(false)
    setOpen(false)
  }

  const handleValidateEmailOTP = async () => {
    if (values.otp === null || values.otp.length !== 6) {
      enqueueSnackbar("Enter 6-digit code", { variant: "warning" })
      return
    }

    setShowProgress(true)

    setInvalidCredentials(false)

    let result

    if (loginInputs.emailDefinedInGoogle) {
      const result = await isEmailOTPCorrect(email, values.otp)

      if (result.status === "success") {
        try {
          if (loginInputs.providerIds.includes("google.com")) {
            setShowGoogleIsProvider(true)
            setInvalidCredentials(true)
            setShowProgress(false)
            return
          }

          const credential = EmailAuthProvider.credential(
            email,
            values.old_password
          )
          const auth = getAuth()
          const loginPwdResult = await signInWithCredential(auth, credential)
        } catch (error) {
          if (error.code === "auth/wrong-password") {
            console.error("wrong password", { error })
            setInvalidCredentials(true)
            setShowProgress(false)
            return
          } else {
            console.error("error", { error })
            enqueueSnackbar(error.message, { variant: "error" })
            setShowProgress(false)
            return
          }
        }
      } else {
        console.error("error", { result })
        setInvalidCredentials(true)
        setShowProgress(false)
        return
      }
    } else {
      const otpResult = await isEmailOTPCorrect(email, values.otp)
      if (otpResult.status === "success") {
        result = await createUserAndGetToken(email, values.new_password_1)

        if (result.hasOwnProperty("token")) {
          enqueueSnackbar("Email authenticated. Please wait...", {
            variant: "success",
          })

          signInWithCustomToken(getAuth(), result.token)
            .then(async (userCredential) => {
              console.log("signed in userCredential", { userCredential })
            })
            .catch((error) => {
              console.log("Error signing in", error)
            })
        }
      } else {
        setInvalidCredentials(true)
        setShowProgress(false)
        return
      }
    }

    setShowProgress(false)
    setOpen(false)
  }

  const submitOnEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleLogIn()
    }
  }

  const handleRequestOTPViaEmail = async () => {
    if (!isEmailValid(email)) {
      enqueueSnackbar("Enter valid email", { variant: "warning" })
      return
    }

    const checkExistingResult = await isExistingOTP({ email: email })
    if (checkExistingResult.data === true) {
      enqueueSnackbar(
        "OTP codes can only be requested every 10 mins. Please use the previous code requested.",
        { variant: "info" }
      )
      return
    }

    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const match = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)

    if (match !== null) {
      setIsSendingOTP(true)
      createAndSendOTPViaEmail(email).then((result) => {
        setEmailOTPSent(true)
        setIsSendingOTP(false)
      })
    } else {
      enqueueSnackbar("Enter a valid email address", { variant: "warning" })
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="email-signin"
      sx={{ margin: 0, padding: 0 }}
    >
      {isShowProgress && <LinearProgress />}
      <DialogTitle id="email-signin-dialog" sx={{ margin: 0, padding: 0 }}>
        <AppBar position="static" sx={{ backgroundColor: BLACK }}>
          <Toolbar>
            <LogoFull />
            <Box sx={{ marginLeft: "30px" }}>
              <Typography variant="h5" sx={{ flexGrow: 1 }}>
                Login
              </Typography>
            </Box>
            <Box sx={{ display: "flex", marginLeft: "auto" }}>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => setOpen(false)}
                size="large"
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent>
        {isSendingOTP && <LinearProgress />}
        <Box sx={{ marginTop: "20px", minWidth: 300 }}>
          {showGoogleIsProvider && (
            <Alert severity="warning">
              You have already signed up with Google. Please use the 'Continue
              with Google' button on the main page to login.
            </Alert>
          )}
          <Form>
            {values && (
              <>
                <Controls.TextInput
                  autoFocus
                  name="email"
                  label="Email"
                  type="search"
                  value={email}
                  disabled
                  onChange={(event) =>
                    handleInputChange({
                      target: {
                        name: event.target.name,
                        value: event.target.value.toLowerCase(),
                      },
                    })
                  }
                  onKeyPress={(event) => submitOnEnter(event)}
                />

                {showUserInactiveMessage && (
                  <Box sx={{ marginBottom: "5px" }}>
                    <Alert severity="warning">User is not active</Alert>
                  </Box>
                )}

                {isPasswordDefinedInFirebase && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Controls.TextInput
                      name="old_password"
                      label="Password"
                      type="password"
                      value={values.old_password}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </Box>
                )}

                {!isPasswordDefinedInFirebase && (
                  <PasswordFields
                    showOldPassword={false}
                    // If we're just logging in then we only need to show 1 password field
                    isPasswordDefined={isPasswordDefinedInFirebase}
                    handleInputChange={handleInputChange}
                    oldPassword={values.old_password}
                    newPassword1={values.new_password_1}
                    newPassword2={values.new_password_2}
                  />
                )}

                {isAllowQROTP && (
                  <Box>
                    <Typography variant="caption">
                      {deliveryMethodLabel}
                    </Typography>
                  </Box>
                )}

                <Box sx={{ marginTop: "15px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    {isAllowQROTP && (
                      <ToggleButtonGroup
                        orientation="horizontal"
                        value={deliveryMethod}
                        exclusive
                        onChange={(event, value) => {
                          if (value) {
                            setDeliveryMethod(value)
                          }
                        }}
                        color="primary"
                      >
                        <ToggleButton value={QRCODE} aria-label="qrcode">
                          <Tooltip title="Enter the 6-digit code from your Authenticator app">
                            <QrCode2Icon />
                          </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="email" aria-label="email">
                          <Tooltip title="Obtain 6-digit code via email">
                            <EmailIcon />
                          </Tooltip>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    )}
                    <Controls.Button
                      onClick={handleRequestOTPViaEmail}
                      text="Email 6-digit code"
                      size="small"
                      disabled={
                        isShowProgress ||
                        deliveryMethod !== "email" ||
                        showUserInactiveMessage
                      }
                    />
                  </Box>
                  {isEmailOTPSent && (
                    <Box sx={{ margin: "5px", mt: "15px" }}>
                      <Alert severity="success">
                        <Typography variant="caption">
                          6-digit code sent to you via email
                        </Typography>
                      </Alert>
                    </Box>
                  )}

                  <Box sx={{ width: "200px", mt: "15px" }}>
                    <TextField
                      label="Enter 6-digit code"
                      variant={UIConstants.STANDARD_INPUT_VARIANT}
                      size={UIConstants.STANDARD_INPUT_SIZE}
                      fullWidth={false}
                      name="otp"
                      value={values.otp}
                      onChange={handleInputChange}
                      onKeyPress={(event) => submitOnEnter(event)}
                      inputProps={{ maxLength: 6 }}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Form>
          {isInvalidCredentials && (
            <Box sx={{ marginTop: "20px" }}>
              <Alert severity="error">
                <Typography variant="caption">
                  Email, password, and 6-digit code do not match
                </Typography>
              </Alert>
            </Box>
          )}
          {/* <Box>showprogress:{isShowProgress ? "Y" : "N"}</Box>
                    <Box>isCodeEntered:{isCodeEntered ? "Y" : "N"}</Box>
                    <Box>isPasswordEntered:{isPasswordEntered ? "Y" : "N"}</Box>
                    <Box>deliveryMethod:{deliveryMethod}</Box> */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleLogIn}
          color="primary"
          variant="contained"
          disabled={
            isShowProgress ||
            !isCodeEntered ||
            !isPasswordEntered ||
            deliveryMethod === "" ||
            (isPasswordDefinedInFirebase && !isPasswordEntered) ||
            showUserInactiveMessage
          }
        >
          Log in
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LoginDialog
