import React from "react"
import UserGrid from "../components/UserGrid"
import Header from "../components/Header"

const UserFind = () => {
  return (
    <Header title="Users">
      <UserGrid />
    </Header>
  )
}

export default UserFind
