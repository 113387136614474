import * as colors from "@mui/material/colors"

const baseColors = [
  colors.red,
  colors.pink,
  colors.purple,
  colors.deepPurple,
  colors.indigo,
  colors.blue,
  colors.lightBlue,
  colors.cyan,
  colors.teal,
  colors.green,
  colors.lightGreen,
  colors.lime,
  colors.yellow,
  colors.amber,
  colors.orange,
  colors.deepOrange,
  colors.brown,
  colors.blueGrey,
  colors.grey,
]
const colorList = [
  ...baseColors.map((color) => color[500]),
  ...baseColors.map((color) => color[100]),
  ...baseColors.map((color) => color[900]),
]

const getColors = (count) => {
  return colorList.slice(0, count)
}

const J4J_YELLOW = "#000" // "#F6BE16"

const JOB_TYPES = colors.lightGreen[100]

const COVERAGE = colors.blue[50]

const CENTRE = colors.cyan[100]

const LOCATION = colors.orange[100]

export { getColors, J4J_YELLOW, JOB_TYPES, COVERAGE, CENTRE, LOCATION }
