import React, { useState } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  Tooltip,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Typography,
  IconButton,
} from "@mui/material"
import * as WorkOrder from "./workOrderStatus"
import Controls from "./controls/Controls"
import { Alert } from "@mui/material"
import HelpIcon from "@mui/icons-material/HelpOutlineOutlined"
import StatusAvatar from "./controls/StatusAvatar"
import { spacing } from "../pages/services/styleServices"
import NavButtons from "./NavButtons"

const styles = {
  buttons: {
    margin: spacing(1),
    padding: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
  subheading: {
    color: "theme.palette.text.secondary",
    marginTop: spacing(2),
  },
  helpLabel: {
    paddingTop: spacing(0.5),
    paddingLeft: spacing(0.5),
  },
}

const WorkOrderStatusOverview = (props) => {
  const {
    workOrder,
    open,
    setOpen,
    availableActions,
    handleStatusChange,
    jobs,
  } = props

  const [showHelp, setShowHelp] = useState(false)

  const [comment, setComment] = useState("")

  const [selectedIndex, setSelectedIndex] = useState()

  const handleClose = () => {
    setOpen(false)
  }

  const handleOK = () => {
    if (selectedIndex !== undefined) {
      const newStatus = availableActions[selectedIndex]?.to
      setOpen(false)
      handleStatusChange({ newStatus, comment, jobs })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Work Order Status - {workOrder.label}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Change the status of this work order
        </DialogContentText>

        <Grid container direction="column">
          <Grid item style={{ marginLeft: "20px", marginBottom: "10px" }}>
            <StatusAvatar status={workOrder.status} />
          </Grid>
          <Grid item>
            <Typography variant="caption">Current status</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.subheading}>
              Select an available action
            </Typography>
          </Grid>
          <Grid item>
            <List>
              {availableActions.map((action, index) => (
                <ListItem
                  key={action.to}
                  button
                  selected={index === selectedIndex}
                  onClick={() => setSelectedIndex(index)}
                >
                  <Tooltip title="New work order status">
                    <ListItemIcon>
                      <StatusAvatar status={action.to} />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={action.label}
                    secondary={
                      WorkOrder.actionLabels[action.action].description
                    }
                  />
                </ListItem>
              ))}

              {availableActions.length === 0 && (
                <Alert severity="info">
                  There are no actions you can perform at the moment.You may be
                  waiting on a Centre Manager or Supplier to perform an action
                </Alert>
              )}
            </List>
          </Grid>

          <Grid item>
            <Typography sx={styles.subheading}>
              Enter an optional comment for this status change
            </Typography>
          </Grid>
          <Grid item>
            <Controls.TextInput
              formcontrolprops={{ width: "100%" }}
              name="comment"
              multiline={true}
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />
          </Grid>

          <Grid item>
            <IconButton onClick={() => setShowHelp(!showHelp)} size="large">
              <HelpIcon color="primary" />
            </IconButton>
            <Typography variant="caption" sx={styles.helpLabel}>
              Show status descriptions
            </Typography>
          </Grid>

          {showHelp && (
            <>
              <Grid item>
                <Typography>Work Order Status Descriptions</Typography>
              </Grid>
              <Grid item>
                <List>
                  {WorkOrder.allStatuses.map((status) => (
                    <ListItem key={status}>
                      <ListItemAvatar>
                        <StatusAvatar status={status} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={WorkOrder.statusLabels[status]}
                        secondary={WorkOrder.statusDescriptions[status]}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="column">
          <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
            <NavButtons>
              <Controls.Button
                text="Cancel"
                onClick={handleClose}
                variant="outlined"
              />
              <Controls.Button text="OK" onClick={handleOK} />
            </NavButtons>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default WorkOrderStatusOverview
