import { Autocomplete, TextField } from "@mui/material"
import { useState } from "react"
import db from "../../Firestore"
import * as UIConstants from "./UIConstants"

const SupplierAutocomplete = ({ handleChange, value, accountId }) => {
  const [inputValue, setInputValue] = useState("")

  const [options, setOptions] = useState([])

  const handleInputChange = (event, value) => {
    setInputValue(value)

    const { gte, lt } = getSearchTerms({ searchText: value })
    db.collection("suppliers")
      .where("account_id", "==", accountId)
      .where("name", ">=", gte)
      .where("name", "<", lt)
      .get()
      .then((querySnapshot) => {
        const suppliers = []
        querySnapshot.forEach((doc) => {
          const supplier = doc.data()
          supplier.id = doc.id
          suppliers.push(supplier)
        })
        const newOptions = suppliers.map((supplier) => ({
          id: supplier.id,
          name: supplier.name,
        }))

        setOptions(newOptions)
      })
  }

  const getSearchTerms = ({ searchText }) => {
    const searchTerm = searchText //searchText.toLowerCase()
    const strlength = searchTerm.length
    const strFrontCode = searchTerm.slice(0, strlength - 1)
    const strEndCode = searchTerm.slice(strlength - 1, searchTerm.length)
    // This is an important bit..
    const endCode =
      strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)

    // gte = greater than or equal to, lte = less than
    return { gte: searchTerm, lt: endCode }
  }

  return (
    <Autocomplete
      id="supplier-autocomplete"
      options={options}
      getOptionLabel={(option) => option.name}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            value={inputValue}
            label="Supplier"
            variant={UIConstants.STANDARD_INPUT_VARIANT}
          />
        )
      }}
    />
  )
}

export default SupplierAutocomplete
