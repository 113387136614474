import { Box, Chip, CircularProgress, Stack, Typography } from "@mui/material"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import * as dataServices from "../pages/services/dataServices"
import Controls from "./controls/Controls"
import { useEffect, useMemo, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import DashboardPivotLineChart from "./DashboardPivotLineChart"
import RefreshIcon from "@mui/icons-material/Refresh"
import XLSX from "xlsx"
import { saveAs } from "file-saver"
import { RiFileExcel2Fill as ExcelIcon } from "react-icons/ri"
import * as icons from "../icons"
import * as jobServices from "../pages/services/jobServices"
import { BLANK_SELECT_VALUE } from "../redux/actionTypes"
import { selectCentres } from "../redux/selectors"
import { useSelector } from "react-redux"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const DashboardPivot = () => {
  const [claims, setClaims] = useState()

  const [rows, setRows] = useState()

  const centres = useSelector(selectCentres)

  const [suppliers, setSuppliers] = useState([])

  const [selectedRows, setSelectedRows] = useState([])

  const [startMonth, setStartMonth] = useState(3)

  const [isBusy, setBusy] = useState(false)

  const [status, setStatus] = useState(BLANK_SELECT_VALUE)

  const [endMonth, setEndMonth] = useState(0)

  const [jobs, setJobs] = useState([])

  const columns = [
    "year_month",
    "priority",
    "centre_id",
    "supplier_id",
    "category",
    "location",
    "status",
  ]

  const widths = [150, 150, 270, 300, 150, 150, 150]

  const dataGridColMapping = [
    "year_month",
    "priority",
    "centre_name",
    "supplier_name",
    "category",
    "location",
    "status",
  ]

  const columnLabels = [
    "Year/Month",
    "Priority",
    "Centre",
    "Supplier",
    "Category",
    "Location",
    "Status",
  ]

  // Make a list of <Select> options that a months, and include the current month, and past 5 months
  const monthOptions = useMemo(() => {
    const now = new Date()
    const months = []
    for (let i = 0; i < 6; i++) {
      const month = new Date(now.getFullYear(), now.getMonth() - i, 1)
      months.push({
        id: i,
        title: `${month.toLocaleString("default", {
          month: "short",
        })} ${month.getFullYear()}`,
      })
    }
    return months
  }, [])

  const [selectedColumns, setSelectedColumns] = useState([])

  const dataGridRows = useMemo(() => {
    if (rows) {
      return rows.map((row, index) => {
        const newRow = {}
        columns.forEach((column) => {
          if (column === "centre_id") {
            const centre = centres.find((centre) => centre.id === row[column])
            newRow["centre_name"] = centre?.short_name || centre?.name
          } else if (column === "supplier_id") {
            const supplier = suppliers.find(
              (supplier) => supplier.id === row[column]
            )
            newRow["supplier_name"] = supplier?.name
          }
          newRow[column] = row[column]
          newRow["count"] = row["count"]
          newRow["id"] = index + 1
        })
        return newRow
      })
    }
    return []
  }, [rows, centres, suppliers])

  // const formatDate = (date) => {
  //   // format with date and time
  //   return `${date.getDate()}-${date.toLocaleString("default", {
  //     month: "short",
  //   })}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  // }

  // Get an object with a created_start and create_end in seconds since Jan 1, 1970
  // where startMonth and endMonth are the number of months before the current month
  const createdRange = useMemo(() => {
    const now = new Date()
    const start = new Date(now.getFullYear(), now.getMonth() - startMonth, 1)
    const end = new Date(now.getFullYear(), now.getMonth() - endMonth + 1, 0)
    // Make end date 23:59:59 on the last day of the month
    end.setHours(23, 59, 59, 999)

    const created_start = Math.floor(start.getTime() / 1000)
    const created_end = Math.floor((end.getTime() + 86399999) / 1000)

    return { created_start, created_end }
  }, [startMonth, endMonth])

  const dataGridCols = useMemo(() => {
    if (selectedColumns.length > 0) {
      const result = selectedColumns.map((column) => {
        const colIndex = columns.indexOf(column)
        return {
          field: dataGridColMapping[colIndex],
          headerName: columnLabels[colIndex],
          width: widths[colIndex],
        }
      })

      return [...result, { field: "count", headerName: "Count", width: 150 }]
    }
  }, [selectedColumns])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          setClaims(token.claims)
        })
      }
    })
    return unsub
  }, [])

  const getMissingSuppliers = (newRows) => {
    if (newRows.length === 0) return

    const row0 = newRows[0]

    const row0Keys = Object.keys(row0)

    // Check if object has a 'supplier_id' value
    const hasSupplierId = row0Keys.includes("supplier_id")

    if (hasSupplierId) {
      // Get all the unique supplier ids
      const supplierIds = newRows
        .map((row) => row.supplier_id)
        .filter((id) => id !== undefined && id !== "")
      const missingSupplierIds = Array.from(
        new Set(
          supplierIds.filter(
            (supplierId) => !suppliers.find((s) => s.id === supplierId)
          )
        )
      )
      if (missingSupplierIds.length === 0) return
      // Get the suppliers
      dataServices
        .getSuppliersByIdChunks(missingSupplierIds, claims.account_id, 10)
        .then((newSuppliers) => {
          setSuppliers([...suppliers, ...newSuppliers])
        })
    }
  }

  const handleQuery = () => {
    setBusy(true)

    cloudFunctions
      .queryJobs({
        accountId: claims.account_id,
        cols: selectedColumns,
        centreIds: claims.centre_ids || [],
        createdStart: createdRange.created_start,
        createdEnd: createdRange.created_end,
        statuses: status === BLANK_SELECT_VALUE ? [] : [status],
      })
      .then((result) => {
        const newRows = result.data[0]

        getMissingSuppliers(newRows)

        setRows(newRows)
        setSelectedRows([])
        setBusy(false)
      })
  }

  const handleToggleColumnn = (column) => {
    if (selectedColumns.includes(column)) {
      setSelectedColumns(selectedColumns.filter((c) => c !== column))
    } else {
      setSelectedColumns([...selectedColumns, column])
    }
  }

  const handleListJobs = async () => {
    const rows = selectedRows.map((row) => dataGridRows[row - 1])
    setBusy(true)

    const rowParams = rows.map((row) => {
      return selectedColumns
        .map((column) => {
          const colIndex = columns.indexOf(column)
          const field = columns[colIndex]
          return {
            field: field,
            value: row[field],
          }
        })
        .filter((param) => param.value !== undefined)
    })

    const listParams = rowParams.map((item) => {
      const params = item.reduce((obj, item) => {
        obj[item.field] = item.value
        return obj
      }, {})
      params.account_id = claims.account_id

      // if collapsed contains a 'year_month' field, which might have a value like '2021-09' then
      // add 2 more attributes, created_start and created_end, which are the first and last seconds
      // of the month, as measured from the client's timezone and Jan 1, 1970
      if (params.year_month) {
        const [year, month] = params.year_month.split("-")
        const start = new Date(year, month - 1, 1)
        const end = new Date(year, month, 0)

        params.created_start = Math.floor(start.getTime() / 1000)
        params.created_end = Math.floor((end.getTime() + 86399999) / 1000)
      } else {
        // Use the start and end months from the UI
        params.created_start = createdRange.created_start
        params.created_end = createdRange.created_end
      }
      return params
    })

    const allResults = listParams.map((p) => cloudFunctions.listJobs({ ...p }))

    const resolved = await Promise.all(allResults)

    const ids = resolved.flatMap((idSet) =>
      idSet.map((jobRef) => jobRef.document_id)
    )

    dataServices
      .getJobsByIdChunks({
        accountId: claims.account_id,
        accountType: claims.account_type,
        jobIds: ids,
      })
      .then((result) => {
        const newJobs = result.map((job) => {
          const newJob = { ...job }
          const centre = centres.find((centre) => centre.id === job.centre_id)
          newJob.centre_name = centre?.short_name || centre?.name
          newJob.supplier_name = suppliers.find(
            (supplier) => supplier.id === job.supplier_id
          )?.name
          return newJob
        })
        setJobs(newJobs)
        setBusy(false)
      })
  }

  const handleExportToExcel = () => {
    const wb = XLSX.utils.book_new()

    wb.Props = {
      Title: `Jobs`,
      Subject: "Jobs",
      Author: "Jobs For Joe",
      CreatedDate: new Date(),
    }

    const SHEET_NAME = "Jobs"

    wb.SheetNames.push(SHEET_NAME)

    const headings = [dataGridCols.map((col) => col.headerName)]

    const ws_data = dataGridRows.map((row) => {
      return dataGridCols.map((col) => {
        return row[col.field]
      })
    })

    const ws = XLSX.utils.aoa_to_sheet(headings.concat(ws_data))

    wb.Sheets[SHEET_NAME] = ws

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" })

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `Jobs.xlsx`
    )
  }

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length) //convert s to arrayBuffer
    var view = new Uint8Array(buf) //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff //convert to octet
    return buf
  }

  return (
    <Box>
      <Stack
        direction="row"
        gap={1}
        sx={{ marginTop: "15px", alignItems: "center" }}
      >
        <Controls.Button
          text="Refresh"
          onClick={handleQuery}
          endIcon={<RefreshIcon />}
          disabled={selectedColumns.length === 0}
          tooltip="Show job summary information based on the selected columns"
        />
        <Controls.Button
          text="Export to Excel"
          onClick={handleExportToExcel}
          endIcon={<ExcelIcon />}
          disabled={dataGridRows?.length === 0}
        />

        <Controls.Button
          text="List Jobs"
          onClick={handleListJobs}
          endIcon={<icons.JobIcon />}
          disabled={selectedRows.length === 0}
          tooltip="Show the jobs that match the selected rows"
        />

        {isBusy && <CircularProgress size={20} thickness={5} color="primary" />}
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 1,
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {columns.map((column) => (
          <Chip
            key={column}
            label={columnLabels[columns.indexOf(column)]}
            onClick={(e) => handleToggleColumnn(column)}
            color={selectedColumns.includes(column) ? "primary" : "default"}
          />
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 1,
          marginBottom: "15px",
        }}
      >
        <Controls.Select
          name="startMonth"
          label="Start Month"
          value={startMonth}
          onChange={(e) => setStartMonth(e.target.value)}
          options={monthOptions}
          isNoneOption={false}
        />

        <Controls.Select
          name="endMonth"
          label="End Month"
          value={endMonth}
          onChange={(e) => setEndMonth(e.target.value)}
          options={monthOptions}
          isNoneOption={false}
        />

        <Controls.Select
          name="status"
          label="Status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          options={jobServices.jobStatusSelectOptions}
          isNoneOption={true}
          noneValue={BLANK_SELECT_VALUE}
          noneLabel="All"
        />
      </Box>

      {/* <Box>
        <DashboardPivotLineChart />
      </Box> */}
      <Box sx={{ cursor: "pointer" }}>
        {dataGridRows && dataGridCols && (
          <DataGrid
            density="compact"
            autoHeight
            rows={dataGridRows}
            columns={dataGridCols}
            pageSizeOptions={[10, 25, 50, 100]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            checkboxSelection
            onRowSelectionModelChange={(selected) => setSelectedRows(selected)}
          />
        )}
      </Box>

      <ShowCentres centres={centres} claims={claims} />

      {jobs.length > 0 && (
        <DataGrid
          sx={{ cursor: "pointer" }}
          density="compact"
          autoHeight
          rows={jobs}
          onRowClick={(params) => {
            // Open the job in a new window
            window.open(`#/jobedit/${params.row.id}`, "_blank")
          }}
          columns={[
            {
              field: "label",
              headerName: "Job",
              width: 300,
            },
            {
              field: "centre_name",
              headerName: "Centre",
              width: 150,
            },
            {
              field: "category",
              headerName: "Category",
              width: 150,
            },
            {
              field: "priority",
              headerName: "Priority",
              width: 150,
            },
            {
              field: "status",
              headerName: "Status",
              width: 150,
            },
            {
              field: "location",
              headerName: "Location",
              width: 150,
            },
            {
              field: "supplier_name",
              headerName: "Supplier",
              width: 150,
            },
          ]}
        />
      )}
    </Box>
  )
}

const ShowCentres = ({ centres, claims }) => {
  return (
    <Stack direction="row" gap={1} sx={{ marginTop: "15px" }}>
      <Typography variant="caption" color="text.secondary">
        Available centres:
      </Typography>
      {claims?.centre_ids?.length > 0 && centres.length > 0 && (
        <Typography variant="caption">
          {claims.centre_ids
            .map(
              (centre_id) =>
                centres.find((centre) => centre.id === centre_id)?.name
            )
            .join(", ")}
        </Typography>
      )}
      {claims?.centre_ids?.length === 0 && (
        <Typography variant="caption">All centres</Typography>
      )}
    </Stack>
  )
}

export default DashboardPivot
