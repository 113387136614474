import { Heading } from "@react-email/heading"
import BaseEmailTemplate, { h2, labelText } from "./BaseEmailTemplate"
import { h1, heroText, text } from "./BaseEmailTemplate"
import { Text } from "@react-email/text"
import { Section } from "@react-email/section"
import { Fragment } from "react"
import { Box } from "@mui/material"
import Priority from "../components/Priority"
import { Column } from "@react-email/column"
import { Container } from "@react-email/container"
import { Preview } from "@react-email/preview"
import { Head } from "@react-email/head"
import { Body } from "@react-email/body"

const RFQEmail = (props) => {
    const { workOrder, jobs, centres, priorities, centreContacts } = props

    return (
        <BaseEmailTemplate>
            <Head />
            <Preview>Request For Quote</Preview>
            <Body>
                <Heading style={h1}>Please provide a quote</Heading>
                <Text style={heroText}>We'd like to invite you to provide a quote:</Text>

                <Container>
                    <Text style={heroText}>Work Order #{workOrder.work_order_no}</Text>
                </Container>

                {centres &&
                    centres.map((centre) => (
                        <Fragment key={centre.id}>
                            <Section>
                                <Text style={text}>
                                    <CentreDetails
                                        centre={centre}
                                        centreContact={
                                            centreContacts.find((cc) => cc.centreId === centre.id)
                                                ?.user
                                        }
                                    />

                                    <JobDetails
                                        jobs={jobs.filter((job) => job.centre_id === centre.id)}
                                        priorities={priorities}
                                    />
                                </Text>
                            </Section>
                        </Fragment>
                    ))}
            </Body>
        </BaseEmailTemplate>
    )
}

const JobDetails = ({ jobs, priorities }) => {
    return (
        <>
            <Section>
                <div style={h2}>
                    <b>Jobs</b>
                </div>
            </Section>

            {jobs.map((job) => (
                <Section key={job.id}>
                    <div>
                        <Column style={text}>{job.label}</Column>
                        <Column style={text}>{job.location}</Column>
                        <Column style={text}>{job.category}</Column>
                        <Column style={text}>
                            <Priority priority={job.priority} priorities={priorities} />
                        </Column>
                    </div>
                </Section>
            ))}
        </>
    )
}

const CentreDetails = ({ centre, centreContact }) => {
    const renderUser = (user) => {
        const userValues = ((user && [user.name, user.phone, user.email]) || []).filter(
            (value) => value
        )
        return centreContact && <div style={text}>{userValues.join(" | ")}</div>
    }

    return (
        <>
            <Text style={h1}>{centre.name}</Text>
            {centre.addr1 !== "" && <div style={text}>{centre.addr1}</div>}
            {centre.addr2 !== "" && <div style={text}>{centre.addr2}</div>}
            <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                {centre.city !== "" && <div style={text}>{centre.city}</div>}
                {centre.state !== "" && <div style={text}>{centre.state}</div>}
                {centre.postcode !== "" && <div style={text}>{centre.postcode}</div>}
            </Box>
            {centre.email !== "" && <div style={text}>{centre.email}</div>}
            {centre.phone !== "" && <div style={text}>{centre.phone}</div>}
            {renderUser(centreContact)}
        </>
    )
}

export default RFQEmail
