import { useEffect, useState } from "react"

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const updateNetworkStatus = () => {
    setIsOnline(navigator.onLine)
  }

  useEffect(() => {
    window.addEventListener("online", updateNetworkStatus)
    window.addEventListener("offline", updateNetworkStatus)

    return () => {
      window.removeEventListener("online", updateNetworkStatus)
      window.removeEventListener("offline", updateNetworkStatus)
    }
  }, [])

  return isOnline
}

export default useNetworkStatus
