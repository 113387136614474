import React from "react"
import Switch from "@mui/material/Switch"
import Grid from "@mui/material/Grid"
import FormControlLabel from "@mui/material/FormControlLabel"
import Box from "@mui/material/Box"
import { spacing } from "../../pages/services/styleServices"

export default function AddMultiple({ addMultiple, setAddMultiple, id }) {
    const toggleChecked = () => {
        setAddMultiple((prev) => !prev)
    }

    return id === undefined ? (
        <Grid item sx={{ margin: spacing(1), padding: spacing(1) }}>
            <Box spacing={1}>
                <FormControlLabel
                    control={<Switch size="small" checked={addMultiple} onChange={toggleChecked} />}
                    label="Add Multiple"
                />
            </Box>
        </Grid>
    ) : null
}
