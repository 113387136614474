import { useDispatch, useSelector } from "react-redux"
import { setPriorities } from "../redux/actions"
import { selectPriorities } from "../redux/selectors"
import { useEffect } from "react"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"

const useMyAccountPriorities = ({ accountId }) => {
    const dispatch = useDispatch()

    const priorities = useSelector(selectPriorities)

    useEffect(() => {
        if (accountId) {
            db.collection("priorities")
                .where("account_id", "==", accountId)
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.docs.length === 0) {
                        const defaultPriorities = {
                            account_id: accountId,
                            lookup_values: [
                                {
                                    id: 1,
                                    title: "P1",
                                    description: "Health & Safety",
                                },
                                {
                                    id: 2,
                                    title: "P2",
                                    description: "Compliance",
                                },
                                {
                                    id: 3,
                                    title: "P3",
                                    description: "Regular Maintenance",
                                },
                                {
                                    id: 4,
                                    title: "P4",
                                    description: "Quality of Life",
                                },
                            ],
                            created: dataServices.serverTimestamp(),
                            modified: dataServices.serverTimestamp(),
                        }
                        db.collection("priorities").add(defaultPriorities)
                    }
                })
                .catch((error) => {
                    console.log("Error getting priorities: ", error)
                })
        }
    }, [accountId])

    useEffect(() => {
        if (accountId) {
            const unsub = db
                .collection("priorities")
                .where("account_id", "==", accountId)
                .onSnapshot(
                    (snapshot) => {
                        snapshot.docChanges().forEach((change) => {
                            if (change.type === "added") {
                                dispatch(setPriorities(change.doc.data()))
                            }
                            if (change.type === "modified") {
                                dispatch(setPriorities(change.doc.data()))
                            }
                            if (change.type === "removed") {
                                dispatch(setPriorities({}))
                            }
                        })
                    },
                    (error) => console.error("error listening for priority changes", error)
                )

            return unsub
        }
    }, [accountId])

    return priorities
}

export default useMyAccountPriorities
