import React, { useMemo } from "react"
import { InputAdornment, TextField, Typography } from "@mui/material"
import * as UIConstants from "./UIConstants"
import { getErrorProps } from "./FieldValidation"

export default function TextInput({
  name,
  label,
  value,
  onChange,
  autoFocus = false,
  helperText,
  multiline = false,
  disabled = false,
  adornment,
  icon,
  variant = "outlined",
  type = "text",
  sx = {},
  showError = false,
  errorFuncs = [],
  ...other
}) {
  const helperTextId = name + "_helper_text"

  const inputProps = icon
    ? {
        startAdornment: (
          <InputAdornment position="start">{icon}</InputAdornment>
        ),
      }
    : {}

  const errorProps = useMemo(() => {
    if (showError && errorFuncs) {
      return getErrorProps({ value, showError, errorFuncs })
    }
  }, [showError, errorFuncs, value])

  return (
    <TextField
      label={label}
      variant={variant}
      autoFocus={autoFocus}
      name={name}
      size={UIConstants.STANDARD_INPUT_SIZE}
      id={name}
      type={type}
      sx={sx}
      error={errorProps ? errorProps.error : false}
      InputProps={inputProps}
      value={value}
      multiline={multiline}
      disabled={disabled}
      onChange={onChange}
      aria-describedby={helperTextId}
      helperText={
        <Typography variant="caption" component={"span"}>
          {errorProps ? errorProps.helperText : helperText}
        </Typography>
      }
      {...other}
    />
  )
}
