import React, { useEffect } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  Elements,
} from "@stripe/react-stripe-js"
import { getStripePromise } from "../pages/services/stripeServices"
import { Paper } from "@mui/material"
import { Grid } from "@mui/material"
import { InputLabel } from "@mui/material"
import { Box } from "@mui/material"
import { spacing } from "../pages/services/styleServices"

const styles = {
  cardElementLabel: {
    marginTop: spacing(2),
    marginBottom: "4px",
  },
  cardElements: {
    marginLeft: spacing(1),
    paddingBottom: spacing(1),
  },
}

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#000",
      fontFamily: "Roboto, Helvetica, Arial",
      fontSize: "16px",
      fontWeight: "400",
      fontSmoothing: "antialiased",
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
}

const CreditCardEditDialog = (props) => {
  const { open, setOpen, updateCard } = props

  const { values, setValues, handleInputChange } = useForm({
    billing_details: {
      name: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      },
      phone: "",
      email: "",
    },
  })

  const stripePromise = getStripePromise()

  const handleClose = () => {
    setOpen(false)
  }

  const handleOK = () => {
    updateCard(values)
    setOpen(false)
  }

  useEffect(() => {
    setValues(props.values)
  }, [props])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Credit Card</DialogTitle>
        <DialogContent>
          <DialogContentText>Update Credit Card</DialogContentText>
          <Form>
            {values && values.id === "" && (
              <>
                {/* Only use CardElement to capture details if this is a new credit card */}
                <Elements stripe={stripePromise}>
                  <Paper>
                    <CardElement options={{ hidePostalCode: true }} />
                  </Paper>
                </Elements>
              </>
            )}
            {values.id !== "" && (
              <>
                <Controls.Readonly
                  name="last4Digits"
                  label="Last 4 Digits"
                  value={values.last4}
                />

                {/*
                                    Stripe ELements styling example.
                                    https://codesandbox.io/s/react-stripe-js-card-detailed-omfb3?file=/src/App.js
                                */}

                <Box sx={styles.cardElements}>
                  <Elements stripe={stripePromise}>
                    <Grid container direction="column">
                      <Grid item sx={styles.cardElementLabel}>
                        <InputLabel shrink={true}>Expiry</InputLabel>
                      </Grid>
                      <Grid item>
                        <CardExpiryElement
                          options={CARD_OPTIONS}
                          name="expiry"
                          id="expiry"
                        />
                      </Grid>

                      <Grid item sx={styles.cardElementLabel}>
                        <InputLabel shrink={true}>CVC</InputLabel>
                      </Grid>
                      <Grid item>
                        <CardCvcElement
                          options={CARD_OPTIONS}
                          name="cvc"
                          id="cvc"
                        />
                      </Grid>
                    </Grid>
                  </Elements>
                </Box>
              </>
            )}
            <Grid container direction="column">
              <Grid item>
                <Controls.TextInput
                  name="billing_details.name"
                  label="Cardholder Name"
                  value={values.billing_details.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <Controls.TextInput
                  name="billing_details.address.line1"
                  label="Address Line 1"
                  value={values.billing_details.address.line1}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <Controls.TextInput
                  name="billing_details.address.line2"
                  label="Address Line 2"
                  value={values.billing_details.address.line2}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <Controls.TextInput
                  name="billing_details.address.city"
                  label="City"
                  value={values.billing_details.address.city}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <Controls.TextInput
                  name="billing_details.address.state"
                  label="State"
                  value={values.billing_details.address.state}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <Controls.TextInput
                  name="billing_details.address.postal_code"
                  label="Postcode"
                  value={values.billing_details.address.postal_code}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <Controls.TextInput
                  name="billing_details.address.country"
                  label="Country (2 letter code)"
                  value={values.billing_details.address.country}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <Controls.TextInput
                  name="billing_details.email"
                  label="Email"
                  value={values.billing_details.email}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <Controls.TextInput
                  name="billing_details.phone"
                  label="Phone"
                  value={values.billing_details.phone}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOK}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreditCardEditDialog
