import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Desktop, Mobile } from "./WindowSizes"
import { useEffect, useState } from "react"
import ContactDialog from "./ContactDialog"
import Controls from "./controls/Controls"
import db from "../Firestore"
import * as contactServices from "../pages/services/contactServices"
import ContactCard from "./ContactCard"
import ContactMenu from "./ContactMenu"

const styles = {
  cellSmall: {
    minWidth: "45px",
    padding: "4px 4px",
  },
  tableHead: {
    backgroundColor: "theme.palette.primary.light",
    color: "theme.palette.common.white",
    minWidth: "45px",
    padding: "4px 4px",
    fontWeight: "bold",
  },
}

const Contacts = ({ accountId, parentId, parentType }) => {
  const [editContact, setEditContact] = useState()

  const [contacts, setContacts] = useState([])

  useEffect(() => {
    if (accountId && parentId && parentType) {
      // Set up contacts listener

      console.log(
        "%cloading contacts for account",
        "color: #bada55",
        accountId,
        parentId,
        parentType
      )

      const query = db
        .collection("contacts")
        .where("parent_id", "==", parentId)
        .where("account_id", "==", accountId)
        .where("parent_type", "==", parentType)

      const unsub = query.onSnapshot((querySnapshot) => {
        const contacts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        setContacts(contacts)
      })
      return unsub
    }
  }, [accountId, parentId, parentType])

  const handleEditContact = (contact) => {
    setEditContact(contact)
  }

  const handleAddContact = () => {
    setEditContact({
      parent_id: parentId,
      account_id: accountId,
      parent_type: parentType,
      first_name: "",
      last_name: "",
      job_title: "",
      email: "",
      phone: "",
    })
  }

  const handleRemoveContact = async (contactId) => {
    console.log("removing contact", contactId)
    contactServices.handleRemoveContact(contactId)
  }

  return (
    <>
      {editContact && (
        <ContactDialog
          open={Boolean(editContact)}
          onClose={() => setEditContact(undefined)}
          values={editContact}
          handleSaveContact={(values) => {
            contactServices.handleSaveContact(values).then(() => {
              setEditContact(undefined)
            })
          }}
        />
      )}

      <Stack gap={2}>
        <Desktop width={675}>
          <ContactsAsGrid
            contacts={contacts}
            handleEditContact={handleEditContact}
            handleRemoveContact={handleRemoveContact}
          />
        </Desktop>
        <Mobile width={675}>
          <ContactsAsTiles
            contacts={contacts}
            handleEditContact={handleEditContact}
            handleRemoveContact={handleRemoveContact}
          />
        </Mobile>
        <Box>
          <Controls.Button
            text="Add Contact"
            variant="outlined"
            onClick={handleAddContact}
          />
        </Box>
      </Stack>
    </>
  )
}

const ContactsAsGrid = ({
  contacts,
  handleEditContact,
  handleRemoveContact,
}) => {
  return (
    <Grid item>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={styles.tableHead}>
                First Name
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Last Name
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Title
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Email
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Phone
              </TableCell>
              <TableCell align="right" sx={styles.cellSmall}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts.map((contact) => (
              <ContactRow
                key={contact.id}
                contact={contact}
                handleEditContact={handleEditContact}
                handleRemoveContact={() => handleRemoveContact(contact.id)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

const ContactRow = ({ contact, handleEditContact, handleRemoveContact }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClickContactMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <TableRow>
      <TableCell align="left" sx={styles.cellSmall}>
        {contact.first_name}
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        {contact.last_name}
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        {contact.job_title}
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        {contact.email}
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        {contact.phone}
      </TableCell>
      <TableCell align="right" sx={styles.cellSmall}>
        <IconButton
          size="small"
          aria-label="more"
          aria-controls={`contact-menu-${contact.id}`}
          aria-haspopup="true"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleClickContactMenu(e)
          }}
        >
          <MoreVertIcon />
        </IconButton>

        {anchorEl && (
          <ContactMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            menuId={`contact-menu-${contact.id}`}
            handleRemoveContact={() => handleRemoveContact(contact.id)}
            handleEditContact={() => {
              setAnchorEl(null)
              handleEditContact(contact)
            }}
          />
        )}
      </TableCell>
    </TableRow>
  )
}

const ContactsAsTiles = ({
  contacts,
  handleEditContact,
  handleRemoveContact,
}) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1 }}
    >
      {contacts.map((contact) => (
        <ContactCard
          key={contact.id}
          contact={contact}
          handleEditContact={handleEditContact}
          handleRemoveContact={handleRemoveContact}
        />
      ))}
    </Box>
  )
}

export default Contacts
