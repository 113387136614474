import React, { useState, useEffect, useRef } from "react"
import Controls from "../components/controls/Controls"
import ProgressBackdrop from "../components/ProgressBackdrop"
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stepper,
  Step,
  StepContent,
  Button,
  StepButton,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  TextField,
  Stack,
} from "@mui/material"
import { useForm } from "../components/useForm"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { useHistory } from "react-router-dom"
import { Alert, AlertTitle } from "@mui/material"
import { useSnackbar } from "notistack"
import {
  createStripeSubscription,
  createStripeCustomer,
  getStripePromise,
  JOBS_MODULE,
} from "./services/stripeServices"
import { CardElement, Elements } from "@stripe/react-stripe-js"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import db, { functions } from "../Firestore"
import { Link as MuiLink } from "@mui/material"
import { httpsCallable } from "firebase/functions"
import AcceptEULADialog from "../components/AcceptEULADialog"
import { isEmailValid } from "./services/emailServices"
import PasswordFields from "../components/PasswordFields"
import { FcGoogle } from "react-icons/fc"
import { spacing, BLACK } from "./services/styleServices"
import LogoFull from "../components/LogoFull"
import * as UIConstants from "../components/controls/UIConstants"
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
} from "firebase/auth"

const styles = {
  root: {
    width: "100%",
    margin: spacing(1),
  },
  pageContent: {
    margin: spacing(2),
    padding: spacing(2),
  },
  button: {
    marginTop: spacing(1),
    marginRight: spacing(1),
    textTransform: "none",
  },
  actionsContainer: {
    marginBottom: spacing(2),
  },
  resetContainer: {
    padding: spacing(3),
  },
  stepUI: {
    padding: spacing(1),
    marginTop: spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  paddingBottom: {
    paddingBottom: spacing(2),
  },
  accountNotice: {
    marginBottom: spacing(1),
    marginTop: spacing(1),
  },
}

const initialValues = {
  account_type: "",
  account_name: "",
  name: "",
  email: "",
  phone: "",
  jobs_module: true,
  agree_to_license: false,
  new_password_1: "",
  new_password_2: "",
  otp: "",
}

const labels = {
  account_type: "Account Type",
  account_name: "Business Name",
  name: "Name",
  email: "Email",
  phone: "Phone",
  new_password_1: "Password",
  new_password_2: "Confirm Password",
}

const SignUp = (props) => {
  const [isShowProgress, setShowProgress] = useState(false)

  const stripePromise = getStripePromise()

  const history = useHistory()

  const [nameOnCard, setNameOnCard] = useState("")

  const [isSignedUp, setSignedUp] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const { values, setValues, handleInputChange } = useForm(initialValues)

  const [acceptEULAOpen, setAcceptEULAOpen] = useState(false)

  const [otpEmailSent, setOtpEmailSent] = useState(false)

  const [isUserAuthenticated, setUserAuthenticated] = useState(false)

  const [isAuthenticationStatusDefined, setAuthenticationStatusDefined] =
    useState(false)

  const [user, setUser] = useState()

  const [activeStep, setActiveStep] = useState(0)

  //const isDevelopmentMode = process.env.NODE_ENV === "development"

  useEffect(() => {
    if (props.location.state) {
      setValues((curr) => ({
        ...curr,
        account_type: props.location.state.type,
      }))
    }
  }, [props])

  const imgRef = useRef()

  const handleNext = async () => {
    // Validate current step
    const validateFunc = getStepInfo()[activeStep].validate

    let validateResult = true

    if (validateFunc) {
      validateResult = await validateFunc()
    }

    if (!validateResult) {
      return
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const validatePersonalDetailsStep = () => {
    let validated = true

    if (values.name === "") {
      validated = false
      enqueueSnackbar("Your name is required", { variant: "warning" })
    }

    if (values.account_name === "") {
      validated = false
      enqueueSnackbar("Account Name is required", { variant: "warning" })
    }

    return validated
  }

  const validateEmailStep = async () => {
    if (isUserAuthenticated) {
      return true
    }

    let validated = true
    setShowProgress(true)

    if (values.email === "") {
      enqueueSnackbar("Email is required", { variant: "warning" })
      setShowProgress(false)
      return false
    } else if (values.otp === "") {
      enqueueSnackbar("6-digit code is required", { variant: "warning" })
      setShowProgress(false)
      return false
    }

    const validateResult = await cloudFunctions.validateOTPAndGetToken(
      values.email,
      values.otp,
      true,
      values.new_password_1
    )

    if (validateResult?.data?.status === "error") {
      enqueueSnackbar(validateResult.data.message, { variant: "warning" })
      setShowProgress(false)
      return false
    }

    if (validateResult.data.hasOwnProperty("token")) {
      enqueueSnackbar("Email authenticated. Please wait...", {
        variant: "success",
      })
      const userCredential = await signInWithCustomToken(
        getAuth(),
        validateResult.data.token
      )

      validated = true
    } else {
      enqueueSnackbar(
        validateResult.data?.message || "One time password login failed",
        {
          variant: "warning",
        }
      )
    }

    if (!validated) {
      enqueueSnackbar("Please authenticate first", { variant: "warning" })
    }

    setShowProgress(false)
    return validated
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReturnToSignUp = () => {
    history.goBack()
  }

  const signUpNewAccount = async () => {
    // Check if account name already in use

    const signUp = httpsCallable(functions, "signUp2")

    const payload = {
      signUpDetails: {
        account_type: values.account_type,
        account_name: values.account_name,
        account_phone: values.phone, // user can change email+phone later if they want different values for their business email+phone
        account_email: values.email,
        email: values.email,
        name: values.name,
        phone: values.phone,
      },
    }

    const result = await signUp(payload)

    if (result.data.status === "error") {
      return result
    }

    await cloudFunctions.obtainCustomClaims()

    getAuth()
      .currentUser.getIdTokenResult(true)
      .then((token) => {
        createStripeCustomer(token.claims.account_id).then(
          async (custResult) => {
            const accountDoc = await db
              .collection("accounts")
              .doc(token.claims.account_id)
              .get()
            const stripeCustId = accountDoc.data().stripe_customer_id

            // Do this async, i.e. no await
            createStripeSubscription(JOBS_MODULE, token.claims.account_id).then(
              (subResult) => {
                console.log("%csub result", "color:pink", subResult)
              }
            )
          }
        )
      })

    setShowProgress(false)

    return result
  }

  // See if user is already signed up

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        cloudFunctions.obtainCustomClaims().then(() => {
          user.getIdTokenResult(true).then((token) => {
            setUserAuthenticated(true)
            setSignedUp(token.claims.account_id !== undefined)
            setValues((curr) => ({ ...curr, email: token.claims.email }))
            setAuthenticationStatusDefined(true)
          })
        })
      } else {
        setSignedUp(false)
        setAuthenticationStatusDefined(true)
      }
    })

    return unsub
  }, [])

  // See if user has already authenticated their email, but perhaps doesn't have an account

  useEffect(() => {
    const user = getAuth().currentUser

    if (!user) {
      return
    }

    setValues((curr) => ({
      ...curr,
      email: user.email,
      account_email: user.email,
    }))
  }, [])

  const handleSignUp = async () => {
    setShowProgress(true)

    const valuesToCheck = { ...values }

    // Phone not mandatory
    delete valuesToCheck.phone

    delete valuesToCheck.new_password_1
    delete valuesToCheck.new_password_2

    delete valuesToCheck.otp

    const emptyFieldName = Object.keys(valuesToCheck).find(
      (key) => values[key] === undefined || values[key] === ""
    )

    if (emptyFieldName !== undefined) {
      const label = labels[emptyFieldName]
      enqueueSnackbar("Enter " + label, { variant: "info" })
    } else {
      let result = await signUpNewAccount()

      if (result.data.status === "error") {
        enqueueSnackbar(result.data.response, { variant: "info" })
        setShowProgress(false)
        return
      }
      enqueueSnackbar("Account created. Welcome", { variant: "success" })

      // Send them to sign in page in case there is an invite to accept
      history.push("/signin")
    }
    setShowProgress(false)
  }

  const getAccountTypeUI = () => {
    return (
      <Paper sx={styles.stepUI}>
        <Alert severity="info" sx={styles.accountNotice}>
          <Typography variant="caption">
            If you want to add users, then your Admin should create an Invite to
            those users. You do not need to Sign Up again. Signing Up creates a
            completely blank new account for the owner of a centre, or a
            supplier wanting to perform jobs for a centre.
          </Typography>
        </Alert>

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="account type"
            name="account_type"
            value={values.account_type}
            onChange={handleInputChange}
          >
            <FormControlLabel
              value="centre"
              disabled={isSignedUp}
              control={<Radio />}
              label="New Organisation Account"
            />

            <Typography variant="caption" sx={styles.signUpCaption}>
              I operate an organisation with 1 or more offices/centres. I'd like
              to create a completely new account, which can then be used to
              manage those centres.
            </Typography>

            <FormControlLabel
              value="supplier"
              disabled={isSignedUp}
              control={<Radio />}
              label="New Supplier Account"
            />

            <Typography variant="caption" sx={styles.signUpCaption}>
              I am a service provider to centres. I am signing up so that I can
              work on jobs allocated to me by other centre accounts.
            </Typography>
          </RadioGroup>
        </FormControl>
      </Paper>
    )
  }

  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  const getStepInfo = () => {
    return [
      {
        title: "Email",
        label: "Authenticate via Google, or email/password",
        ui: getEmailUI(),
        validate: async () => {
          return await validateEmailStep()
        },
      },
      {
        title: `Account Type`,
        label: "Select account type",
        ui: getAccountTypeUI(),
      },
      {
        title: "User details",
        label: `Enter user details`,
        ui: getPersonalDetailsUI(),
        validate: async () => {
          return validatePersonalDetailsStep()
        },
      },
    ]
  }

  const getStepContent = (step) => {
    return getStepInfo()[step].label
  }

  const getStepUI = (step) => {
    return getStepInfo()[step].ui
  }

  const getStepTitles = () => {
    return getStepInfo().map((step) => step.title)
  }

  const handleGoogleAuthenticate = () => {
    const provider = new GoogleAuthProvider()
    const auth = getAuth()

    signInWithPopup(auth, provider).catch((err) =>
      console.error("Error authenticating with google", err)
    )
  }

  const handleRequestOTPViaEmail = () => {
    if (!isEmailValid(values.email)) {
      enqueueSnackbar("Enter valid email", { variant: "warning" })
      return
    }

    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const match = values.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)

    if (match !== null) {
      cloudFunctions.createAndSendOTPViaEmail(values.email)
      enqueueSnackbar(
        "A 6-digit code is being emailed to you. This can sometimes take a few minutes. Check your inbox",
        {
          variant: "success",
        }
      )
    } else {
      enqueueSnackbar("Enter a valid email address", { variant: "warning" })
    }
  }

  const getEmailUI = () => {
    return (
      <Paper sx={styles.stepUI}>
        <Stack gap={1}>
          {isUserAuthenticated && isAuthenticationStatusDefined && (
            <Grid item>
              You have authenticated your email <b>{user.email}</b>. Please
              continue the Sign Up process.
            </Grid>
          )}

          {!isUserAuthenticated && isAuthenticationStatusDefined && (
            <>
              <Button
                text="Continue with Google"
                variant="outlined"
                fullWidth={true}
                size="small"
                onClick={() => handleGoogleAuthenticate()}
                startIcon={<FcGoogle />}
              >
                Sign In with Google
              </Button>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6">or</Typography>
              </Box>

              <Stack gap={2}>
                <Controls.TextInput
                  autoFocus
                  name="email"
                  label="Enter email address"
                  fullWidth
                  value={values.email}
                  onChange={(event) =>
                    handleInputChange({
                      target: {
                        name: event.target.name,
                        value: event.target.value.toLowerCase(),
                      },
                    })
                  }
                />

                <PasswordFields
                  showOldPassword={false}
                  handleInputChange={handleInputChange}
                  newPassword1={values.new_password_1}
                  newPassword2={values.new_password_2}
                  isPasswordDefined={false}
                />

                <Stack gap={1}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        handleRequestOTPViaEmail()
                        setOtpEmailSent(true)
                      }}
                      text="Email 6-digit code"
                      variant="contained"
                      size="small"
                      disabled={isShowProgress || otpEmailSent}
                      sx={{ textTransform: "none" }}
                    >
                      Email 6-digit code
                    </Button>
                    {otpEmailSent && (
                      <MuiLink
                        onClick={() => {
                          setOtpEmailSent(false)
                        }}
                        component="button"
                      >
                        <Typography variant="caption">Resend email</Typography>
                      </MuiLink>
                    )}
                  </Box>

                  <Box>
                    <TextField
                      label="Enter 6-digit code"
                      variant={UIConstants.STANDARD_INPUT_VARIANT}
                      size={UIConstants.STANDARD_INPUT_SIZE}
                      name="otp"
                      value={values.otp}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: 6 }}
                    />
                  </Box>
                </Stack>
              </Stack>
            </>
          )}

          {/* {isDevelopmentMode && (
                    <Box sx={{ marginTop: "25px" }}>
                       

                        <Box sx={{ marginLeft: "10px", marginTop: "10px" }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={usePassword}
                                        onChange={() => setUsePassword(!usePassword)}
                                        size="small"
                                    />
                                }
                                label={<Typography variant="caption">Require password</Typography>}
                            />
                        </Box>
                    </Box>
                )} */}
        </Stack>
      </Paper>
    )
  }

  const getCreditCardUI = () => {
    // cards for testing: https://stripe.com/docs/testing#international-cards
    return (
      <Elements stripe={stripePromise}>
        <Box style={{ margin: "10px" }}>
          <CardElement options={{ hidePostalCode: true }} />
        </Box>
        <Box style={{ paddingTop: "15px", width: "300px" }}>
          <Controls.TextInput
            label="Name on card"
            name="name"
            value={nameOnCard}
            onChange={(event) => setNameOnCard(event.target.value)}
            fullWidth
          />
        </Box>
        <Box
          style={{ display: "flex", alignItems: "center", paddingTop: "10px" }}
        >
          <Typography variant="caption" color="textSecondary">
            Powered by{" "}
          </Typography>
          <img alt="Stripe" src="/stripe-logo.png" height="25px" />
        </Box>
        <Box style={{ marginTop: "20px" }}>
          <Alert severity="info">
            <Typography variant="caption" component={"span"}>
              Leave blank and click <strong>Finish</strong> if you'd prefer to
              enter payment details when your free trial ends. Credit card
              details are captured securely using{" "}
              <a href="https://stripe.com" target="_stripe">
                Stripe
              </a>{" "}
              credit card handling.
            </Typography>
          </Alert>
        </Box>
      </Elements>
    )
  }

  const getPersonalDetailsUI = () => {
    return (
      <Paper sx={styles.stepUI}>
        <Stack gap={2}>
          <Controls.TextInput
            autoFocus
            name="name"
            label="Your Name"
            value={values.name}
            disabled={isSignedUp}
            onChange={handleInputChange}
            fullWidth
          />

          <Controls.TextInput
            name="account_name"
            label="Business name"
            fullWidth
            value={values.account_name}
            disabled={isSignedUp}
            onChange={handleInputChange}
          />

          <Controls.TextInput
            name="phone"
            label="Phone"
            value={values.phone}
            disabled={isSignedUp}
            onChange={handleInputChange}
          />
        </Stack>
      </Paper>
    )
  }

  return (
    <Box>
      <ProgressBackdrop open={isShowProgress} />
      <AcceptEULADialog
        open={acceptEULAOpen}
        handleAccept={async () => {
          setAcceptEULAOpen(false)
          await handleSignUp()
        }}
        handleCancel={() => setAcceptEULAOpen(false)}
      />
      <AppBar position="static" sx={{ backgroundColor: BLACK }}>
        <Toolbar>
          <LogoFull />
          <Box sx={{ marginLeft: "30px" }}>
            <Typography variant="h5" sx={styles.title}>
              Sign Up
            </Typography>
          </Box>
          <Box sx={{ display: "flex", marginLeft: "auto" }}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleReturnToSignUp}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {isSignedUp && (
        <Alert severity="info" sx={styles.pageContent}>
          <AlertTitle>Existing Account</AlertTitle>
          You're already signed up
        </Alert>
      )}
      {!isSignedUp && (
        <Box sx={{ marginLeft: "20px", marginTop: "20px" }}>
          <Box maxWidth={500}>
            <div sx={styles.root}>
              <Stepper activeStep={activeStep} nonLinear orientation="vertical">
                {getStepTitles().map((label, index) => (
                  <Step key={label}>
                    <StepButton onClick={handleStep(index)}>{label}</StepButton>
                    <StepContent>
                      <Typography variant="caption">
                        {getStepContent(index)}
                      </Typography>

                      {activeStep === index && getStepUI(activeStep)}

                      <div sx={styles.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={styles.button}
                            size="small"
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            sx={styles.button}
                            size="small"
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === getStepTitles().length && (
                <Paper square elevation={0} sx={styles.resetContainer}>
                  <Box sx={{ marginBottom: "10px" }}>
                    <Box>{values.name}</Box>
                    <Box>{values.email}</Box>
                    <Box>{values.account_name}</Box>
                    <Box>{values.phone}</Box>
                  </Box>

                  <Box sx={styles.buttons}>
                    <Button
                      onClick={() => setAcceptEULAOpen(true)}
                      color="primary"
                      variant="contained"
                      sx={styles.button}
                      size="small"
                      endIcon={<NavigateNextIcon />}
                    >
                      Review License and Continue
                    </Button>
                  </Box>
                </Paper>
              )}
            </div>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default SignUp
