import * as actions from "../actionTypes"

// pageDocs is { first: <doc>, last: <doc> } for that page, and used by prev/next
const initialState = {
  value: {
    page: 0,
    order: "desc",
    orderBy: "created",
    centre_id: "",
    supplier_id: "",
    job_types: [], // category
    job_allocated: "",
    text_search: "",
    job_status_filter: [],
    priority: 0,
    // Have we applied any initialisation logic to this state?
    // This occurs once and sets this to true
    initialized: false,
  },
}

export default function jobGridPagination(state = initialState, action) {
  switch (action.type) {
    case actions.SET_JOB_GRID_PAGINATION: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}

export { initialState }
