import { Typography } from "@mui/material"
import LinkButton from "./controls/LinkButton"

const CentreName = ({
  centre,
  truncateWidth,
  isSupplierViewing,
  textAlign = "left",
}) => {
  return (
    <Typography variant="body2" component="span" textAlign={textAlign}>
      {centre?.id && !isSupplierViewing && (
        <LinkButton
          truncateWidth={truncateWidth}
          to={`/CentreEdit/${centre?.id}`}
          sx={{ justifyContent: "flex-start" }}
        >
          {centre?.short_name || centre?.name || "No Centre"}
        </LinkButton>
      )}
      {centre?.id && isSupplierViewing && (centre?.short_name || centre?.name)}
      {!centre?.id && "No centre"}
    </Typography>
  )
}

export default CentreName
