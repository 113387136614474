import React, { useState } from "react"
import Header from "../components/Header"
import WorkOrderCalendarForm from "../components/WorkOrderCalendarForm"

const WorkOrderCalendar = (props) => {
  const [title, setTitle] = useState("Calendar")

  return (
    <Header title={title}>
      <WorkOrderCalendarForm setTitle={setTitle} />
    </Header>
  )
}

export default WorkOrderCalendar
