import { Stack, Typography } from "@mui/material"
import Controls from "./controls/Controls"
import { useEffect, useState } from "react"
import { spacing } from "../pages/services/styleServices"
import { getName } from "../pages/services/checkListServices"

const styles = {
    textField: {
        marginBottom: spacing(3),
    },
}

const ChecklistTextField = ({ section, element, values, handleChange, isComplete }) => {
    const [name, setName] = useState()

    const [val, setVal] = useState()

    useEffect(() => {
        if (section && element && values) {
            const name = getName(section, element)
            setName(name)

            const val = values.check_list_data[name]
            setVal(val)
        }
    }, [section, element, values])

    const formControlProps = { width: "100%" }

    return (
        <Stack direction="column" gap={1} sx={{ width: "300px", marginTop: "15px" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {element.label}
            </Typography>
            {element && name && (
                <Controls.TextInput
                    fullWidth
                    multiline
                    sx={styles.textField}
                    name={name}
                    label={element.text}
                    disabled={isComplete}
                    value={val}
                    onChange={handleChange}
                    formcontrolprops={formControlProps}
                />
            )}
        </Stack>
    )
}

export default ChecklistTextField
