import React from "react"

const files = { white: "/j4j-logo-full-white.png", black: "/j4j-logo-full-black.png" }

const LogoFull = (props) => {
    const { type = "black", width = "90px" } = props

    return <img style={{ width: width }} src={files[type]} alt="logo" />
}

export default LogoFull
