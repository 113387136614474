import { Badge } from "@mui/material"
import { styled } from "@mui/material/styles"

const StyledBadge = styled(Badge)(({ theme }) => ({
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: 0,
    margin: 0,
}))

export default StyledBadge
