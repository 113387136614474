import {
  Box,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemSecondaryAction,
  Stack,
} from "@mui/material"
import React, { useRef } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import Controls from "./controls/Controls"
import * as icons from "../icons"
import * as fileServices from "../pages/services/fileServices"

const styles = {
  input: {
    display: "none",
  },
  fileName: {
    width: "250px",
  },
}

const FileStage = ({ files, setFiles }) => {
  const addFileRef = useRef()

  const handleAddFile = async (event) => {
    const inputFiles = event.target.files
    if (!inputFiles) return

    const newFiles = []
    for (let file of inputFiles) {
      if (!files.some((f) => f.name === file.name)) {
        newFiles.push(file)
      }
    }

    const resizedPromises = newFiles.map(async (file) => ({
      file: await fileServices.resizeFile(file),
      name: file.name,
      unique: file.name,
    }))

    const resized = await Promise.all(resizedPromises)

    const updatedFiles = [
      ...files,
      ...resized.map((r) => ({
        id: r.name,
        name: r.name,
        data: r.file,
      })),
    ]

    setFiles(updatedFiles)
  }

  const handleRemoveFile = (filename) => {
    const updatedFiles = files.filter((file) => file.name !== filename)
    setFiles(updatedFiles)
  }

  const handleFileNameChange = (event) => {
    const newFiles = files.map((file) => {
      if (file.id === event.target.id) {
        return {
          id: event.target.id,
          name: event.target.value,
          data: file.data,
        }
      }
      return file
    })

    setFiles(newFiles)
  }

  return (
    <Stack direction="column" gap={2}>
      <List dense sx={{ width: "300px" }}>
        {files.map((file) => (
          <ListItem key={file.id}>
            <Input
              sx={styles.fileName}
              name="image"
              id={file.id}
              value={file.name}
              onChange={handleFileNameChange}
            />

            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemoveFile(file.name)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <input
        accept="image/*"
        style={styles.input}
        ref={addFileRef}
        multiple
        id="icon-button-file"
        type="file"
        onChange={handleAddFile}
      />

      <Box sx={{ width: "150px" }}>
        <Controls.Button
          text="Upload Files"
          type="button"
          onClick={() => addFileRef.current.click()}
          endIcon={<icons.AttachFileIcon />}
        />
      </Box>

      <Stack gap={1}>
        {files.map((file) => (
          <Box sx={{ maxWidth: "200px" }} key={file.name}>
            <img key={file.id} src={file.data} alt={file.name} />
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}

export default FileStage
