import * as actions from "./actionTypes"

// Change the app bar search value
export const searchBar = (searchValue) => ({
  type: actions.SET_SEARCH_VALUE,
  payload: {
    value: searchValue,
  },
})

export const setPriorities = (priorities) => ({
  type: actions.SET_PRIORITIES,
  payload: {
    value: priorities,
  },
})

export const setCentreGridPagination = (pagination) => ({
  type: actions.SET_CENTRE_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setCentreGridPageDocs = (pageDocs) => ({
  type: actions.SET_CENTRE_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setCheckListTemplateGridPagination = (pagination) => ({
  type: actions.SET_CHECK_LIST_TEMPLATE_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setCheckListTemplateGridPageDocs = (pageDocs) => ({
  type: actions.SET_CHECK_LIST_TEMPLATE_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setAssetGridPagination = (pagination) => ({
  type: actions.SET_ASSET_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setAssetGridPageDocs = (pageDocs) => ({
  type: actions.SET_ASSET_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setUserGridPagination = (pagination) => ({
  type: actions.SET_USER_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setUserGridPageDocs = (pageDocs) => ({
  type: actions.SET_USER_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setActionGridPagination = (pagination) => ({
  type: actions.SET_ACTION_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setActionGridPageDocs = (pageDocs) => ({
  type: actions.SET_ACTION_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setQuoteGridPagination = (pagination) => ({
  type: actions.SET_QUOTE_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setQuoteGridPageDocs = (pageDocs) => ({
  type: actions.SET_QUOTE_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setSupplierShowDocuments = (showDocuments) => ({
  type: actions.SET_SUPPLIER_SHOW_DOCUMENTS,
  payload: {
    value: showDocuments,
  },
})

export const setSupplierSearch = (searchVal) => ({
  type: actions.SET_SUPPLIER_SEARCH,
  payload: {
    value: searchVal,
  },
})

export const setJobGridPagination = (pagination) => ({
  type: actions.SET_JOB_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setMenuStatus = (status) => ({
  type: actions.SET_MENU_OPEN,
  payload: {
    value: status,
  },
})

export const setConfigMenuStatus = (status) => ({
  type: actions.SET_CONFIG_MENU_OPEN,
  payload: {
    value: status,
  },
})

export const setMyAccountMenuStatus = (status) => ({
  type: actions.SET_MY_ACCOUNT_MENU_OPEN,
  payload: {
    value: status,
  },
})

// Set data from retrieving jobs data from BigQuery
export const setChartJobsByCentreAndCategory = (jobsByCentreAndCategory) => ({
  type: actions.SET_JOBS_BY_CENTRE_AND_CATEGORY,
  payload: {
    value: jobsByCentreAndCategory,
  },
})

export const setJobGridPageDocs = (pageDocs) => ({
  type: actions.SET_JOB_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setSupplierGridPagination = (pagination) => ({
  type: actions.SET_SUPPLIER_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setSupplierGridPageDocs = (pageDocs) => {
  return {
    type: actions.SET_SUPPLIER_GRID_PAGE_DOCS,
    payload: {
      value: pageDocs,
    },
  }
}

export const setJobGridSelectedItems = (jobGridSelectedItems) => ({
  type: actions.SET_JOB_GRID_SELECTED_ITEMS,
  payload: {
    value: jobGridSelectedItems,
  },
})

export const setCheckListInstanceGridPagination = (pagination) => ({
  type: actions.SET_CHECK_LIST_INSTANCE_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setCheckListInstanceGridPageDocs = (pageDocs) => ({
  type: actions.SET_CHECK_LIST_INSTANCE_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setJobSummarySearch = (searchValue) => ({
  type: actions.SET_JOB_SUMMARY_SEARCH,
  payload: {
    value: searchValue,
  },
})

export const setWorkOrderGridPagination = (pagination) => ({
  type: actions.SET_WORK_ORDER_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setWorkOrderGridPageDocs = (pageDocs) => ({
  type: actions.SET_WORK_ORDER_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setCarouselScale = (scale) => ({
  type: actions.SET_CAROUSEL_SCALE,
  payload: {
    value: scale,
  },
})

export const setCarouselVisible = (visible) => ({
  type: actions.SET_CAROUSEL_VISIBLE,
  payload: {
    value: visible,
  },
})

export const setWorkOrderGridSelectedItems = (workOrderGridSelectedItems) => ({
  type: actions.SET_WORK_ORDER_GRID_SELECTED_ITEMS,
  payload: {
    value: workOrderGridSelectedItems,
  },
})

export const setAccountGridPagination = (pagination) => ({
  type: actions.SET_ACCOUNT_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setAccountGridPageDocs = (pageDocs) => ({
  type: actions.SET_ACCOUNT_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setAccountGridSelectedItems = (accountGridSelectedItems) => ({
  type: actions.SET_ACCOUNT_GRID_SELECTED_ITEMS,
  payload: {
    value: accountGridSelectedItems,
  },
})

export const setActionsIndicator = (actionsIndicator) => ({
  type: actions.SET_HAS_ACTIONS,
  payload: {
    value: actionsIndicator,
  },
})

export const setCentres = ({ centre, id, change_type }) => {
  return {
    type: actions.SET_CENTRES,
    payload: {
      value: { name: centre.name, short_name: centre.short_name || "" },
      id: id,
      change_type: change_type,
    },
  }
}

export const setWorkOrderLastModified = (lastModified) => ({
  type: actions.SET_WORK_ORDER_LAST_MODIFIED,
  payload: {
    value: lastModified,
  },
})

export const setCommentsLastModified = (lastModified) => ({
  type: actions.SET_COMMENTS_LAST_MODIFIED,
  payload: {
    value: lastModified,
  },
})

export const setWorkOrderCalendarDateRange = (dateRange) => ({
  type: actions.SET_WORK_ORDER_CALENDAR_DATE_RANGE,
  payload: {
    value: dateRange,
  },
})

export const setJobs = (jobsCacheUpdate) => {
  return {
    type: actions.SET_JOBS,
    payload: {
      value: jobsCacheUpdate,
    },
  }
}
