import { combineReducers } from "redux"
import searchBar from "./searchbar"

import centreGridPagination from "./centreGridPagination"
import centreGridPageDocs from "./centreGridPageDocs"

import assetGridPagination from "./assetGridPagination"
import assetGridPageDocs from "./assetGridPageDocs"

import jobGridPagination from "./jobGridPagination"
import jobGridPageDocs from "./jobGridPageDocs"
import jobGridSelectedItems from "./jobGridSelectedItems"

import supplierGridPagination from "./supplierGridPagination"
import supplierGridPageDocs from "./supplierGridPageDocs"

import centres from "./centres"

import userGridPagination from "./userGridPagination"
import userGridPageDocs from "./userGridPageDocs"

import checkListTemplateGridPagination from "./checkListTemplateGridPagination"
import checkListTemplateGridPageDocs from "./checkListTemplateGridPageDocs"

import actionsIndicator from "./actionsIndicator"

import actionGridPagination from "./actionGridPagination"
import actionGridPageDocs from "./actionGridPageDocs"

import quoteGridPagination from "./quoteGridPagination"
import quoteGridPageDocs from "./quoteGridPageDocs"

import checkListInstanceGridPagination from "./checkListInstanceGridPagination"
import checkListInstanceGridPageDocs from "./checkListInstanceGridPageDocs"

import workOrderGridPagination from "./workOrderGridPagination"
import workOrderGridPageDocs from "./workOrderGridPageDocs"
import workOrderGridSelectedItems from "./workOrderGridSelectedItems"

import accountGridPagination from "./accountGridPagination"
import accountGridPageDocs from "./accountGridPageDocs"
import accountGridSelectedItems from "./accountGridSelectedItems"

import workOrderLastModified from "./workOrderLastModified"

import workOrderCalendarDateRange from "./workOrderCalendarDateRange"

import menu from "./menu"
import configMenu from "./configMenu"
import myAccountMenu from "./myAccountMenu"

import jobSummarySearch from "./jobSummarySearch"

import supplierShowDocuments from "./supplierShowDocuments"
import supplierSearch from "./supplierSearch"

import carouselScale from "./carouselScale"
import carouselVisible from "./carouselVisible"

import chartJobsByCentreAndCategory from "./chartJobsByCentreAndCategory"

import commentsLastModified from "./commentsLastModified"

import jobs from "./jobs"

import priorities from "./priorities"

export default combineReducers({
  searchBar,

  centreGridPagination,
  centreGridPageDocs,

  assetGridPagination,
  assetGridPageDocs,

  userGridPagination,
  userGridPageDocs,

  actionGridPagination,
  actionGridPageDocs,

  quoteGridPagination,
  quoteGridPageDocs,

  centres,

  // side menu open status
  menu,
  configMenu,
  myAccountMenu,

  actionsIndicator,

  jobGridPagination,
  jobGridPageDocs,
  jobGridSelectedItems,

  supplierGridPagination,
  supplierGridPageDocs,

  supplierShowDocuments,
  supplierSearch,

  // data from BigQuery
  chartJobsByCentreAndCategory,

  checkListTemplateGridPagination,
  checkListTemplateGridPageDocs,

  checkListInstanceGridPagination,
  checkListInstanceGridPageDocs,

  workOrderGridPagination,
  workOrderGridPageDocs,
  workOrderGridSelectedItems,
  workOrderLastModified,

  accountGridPagination,
  accountGridPageDocs,
  accountGridSelectedItems,

  jobSummarySearch,

  carouselScale,
  carouselVisible,

  priorities,

  workOrderCalendarDateRange,

  commentsLastModified,

  jobs,
})
