import React, { useState } from "react"
import { Alert, Typography } from "@mui/material"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Card } from "@mui/material"
import { CardHeader } from "@mui/material"
import { IconButton } from "@mui/material"
import { CardContent } from "@mui/material"
import { Menu } from "@mui/material"
import { ListItemIcon } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { MenuItem } from "@mui/material"
import CreditCardEditDialog from "./CreditCardEditDialog"
import { httpsCallable } from "firebase/functions"
import { functions } from "../Firestore"
import { useSnackbar } from "notistack"
import { spacing } from "../pages/services/styleServices"
import * as stripeServices from "../pages/services/stripeServices"

const styles = {
  pageContent: {
    marginTop: spacing(1),
    marginLeft: spacing(1),
    marginRight: spacing(2),
    padding: spacing(1),
    maxWidth: "380px",
  },
  defaultCardMsg: {
    marginTop: spacing(1),
  },
}

const StripeCard = (props) => {
  const { card, updateCard, reload, custId, setShowProgress } = props

  const { enqueueSnackbar } = useSnackbar()

  const [cardAnchorEl, setCardAnchorEl] = useState(null)

  const handleEditCard = () => {
    handleCardMenuClose()
    setShowCardEditDialog(true)
  }

  const handleSetCardDefault = async () => {
    handleCardMenuClose()

    setShowProgress(true)

    const result = await stripeServices.setStripeCardAsDefault(card.id, custId)

    await reload()

    setShowProgress(false)

    enqueueSnackbar("Card set as default", { variant: "success" })
  }

  const handleDeleteCard = async () => {
    handleCardMenuClose()

    //TODO: move this into stripeServices.js
    const deleteStripePaymentMethod = httpsCallable(
      functions,
      "deleteStripePaymentMethod"
    )

    const result = await deleteStripePaymentMethod({ paymentMethodId: card.id })

    reload()

    enqueueSnackbar("Card deleted", { variant: "success" })
  }

  const handleCardMenuClose = () => {
    setCardAnchorEl(null)
  }

  const [showCardEditDialog, setShowCardEditDialog] = useState(false)

  return (
    <>
      <CreditCardEditDialog
        open={showCardEditDialog}
        setOpen={setShowCardEditDialog}
        values={card}
        updateCard={updateCard}
      />

      <Card sx={styles.pageContent}>
        <CardHeader
          avatar={
            <CreditCardIcon color={card.is_default ? "secondary" : "inherit"} />
          }
          disableTypography={true}
          action={
            <IconButton
              aria-label="settings"
              aria-controls="card-menu"
              aria-haspopup="true"
              onClick={(event) => setCardAnchorEl(event.target)}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Typography variant="body2">
              {card.brand} {card.last4}
            </Typography>
          }
          subheader={
            <>
              <Typography variant="caption">
                Expiry {card.exp_month}/{card.exp_year}
              </Typography>
            </>
          }
        />

        <CardContent>
          <Typography variant="body2">{card.billing_details.name}</Typography>
          <Typography variant="body2">{card.billing_details.email}</Typography>
          <Typography variant="body2">{card.billing_details.phone}</Typography>
          <Typography variant="body2">
            {card.billing_details.address.line1}
          </Typography>
          <Typography variant="body2">
            {card.billing_details.address.line2}
          </Typography>
          <Typography variant="body2">
            {card.billing_details.address.city}
          </Typography>
          <Typography variant="body2">
            {card.billing_details.address.state}{" "}
            {card.billing_details.address.postal_code}{" "}
            {card.billing_details.address.country}
          </Typography>{" "}
          {card.is_default && (
            <Alert
              sx={{ paddingtop: "5px", marginTop: "5px", marginBottom: "5px" }}
              severity="info"
            >
              Default card
            </Alert>
          )}
        </CardContent>
      </Card>

      {cardAnchorEl && (
        <Menu
          id="card-menu"
          anchorEl={cardAnchorEl}
          keepMounted
          open={cardAnchorEl !== null}
          onClose={handleCardMenuClose}
        >
          <MenuItem onClick={(event) => handleEditCard(event)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={(event) => handleDeleteCard(event)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
          <MenuItem
            onClick={(event) => handleSetCardDefault(event)}
            disabled={card.is_default}
          >
            <ListItemIcon>
              <CreditCardIcon fontSize="small" />
            </ListItemIcon>
            Set card as default
          </MenuItem>
        </Menu>
      )}
    </>
  )
}

export default StripeCard
