import { Box, Tooltip } from "@mui/material"

const Priority = ({ priority, priorities }) => {
    const lookup = priority && priorities && priorities?.lookup_values?.find((v) => v.id === priority)

    return (
        <Tooltip title={lookup?.description} component='span'>
            <Box>{lookup?.title}</Box>
        </Tooltip>
    )
}

export default Priority
