import {
  ListItemIcon,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"

const QuoteTileMenu = ({
  anchorEl,
  setAnchorEl,
  menuId,
  handleEditQuote,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: isMobile ? "left" : "left",
      }}
      transformOrigin={{
        vertical: isMobile ? "top" : "top",
        horizontal: isMobile ? "left" : "left",
      }}
      sx={{
        left: isMobile ? 0 : undefined, // Position menu at the left edge of the screen on mobile devices
        width: isMobile ? "100%" : undefined, // Set menu width to 100% on mobile devices
      }}
    >
      <MenuItem onClick={handleEditQuote}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        Edit Quote
      </MenuItem>
    </Menu>
  )
}

export default QuoteTileMenu
