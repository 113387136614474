import React, { useState } from "react"
import AssetEditForm from "../components/AssetEditForm"
import Header from "../components/Header"
import { useId } from "./useId"

const AssetEdit = (props) => {
    let { id } = useId(props)

    const [title, setTitle] = useState("Edit Asset")

    return (
        <Header title={title}>
            <AssetEditForm id={id} setTitle={setTitle} />
        </Header>
    )
}

export default AssetEdit
