import { Box, Card, Divider, Stack } from "@mui/material"
import { Typography } from "@mui/material"
import { CardContent, CardHeader } from "@mui/material"
import React, { useState, useEffect, useMemo } from "react"
import db from "../Firestore"
import moment from "moment"
import CheckListRating from "./CheckListRating"
import { getName } from "../pages/services/checkListServices"
import CheckListSignatureField from "./CheckListSignatureField"
import {
  convertCheckListDataFromFirestore,
  getFileUrls,
} from "../pages/services/checkListServices"
import SelectableChip from "./SelectableChip"
import _ from "lodash"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const CheckListPrintForm = (props) => {
  const [accountId, setAccountId] = useState()

  const [id] = useState(props.computedMatch.params.id)

  const [values, setValues] = useState()

  const [checkList, setCheckList] = useState()

  const [centre, setCentre] = useState()

  const [user, setUser] = useState()

  const [fileUrls, setFileUrls] = useState()

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult().then((token) => {
        setAccountId(token.claims.account_id)
      })
    })

    return unsub
  }, [])

  const subheaderValue = useMemo(() => {
    if (values && centre && user) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={{ fontWeight: "bold" }}>{centre.name}</Typography>
          <Box sx={{ marginLeft: "auto" }}>
            <Typography>Created: {user.name}</Typography>
            <Typography>
              {moment(values.created.toDate()).format("D-MMM-yyyy hh:mm a")}
            </Typography>
            <Typography>
              Status: {values.completed ? "Completed" : "Not Completed"}
            </Typography>
          </Box>
        </Box>
      )
    }
  }, [values, centre, user])

  const loadData = async ({ id, accountId }) => {
    const checkListInstanceDoc = await db
      .collection("checklist_instances")
      .doc(id)
      .get()
    const initialCheckListInstanceData = {
      id: checkListInstanceDoc.id,
      ...checkListInstanceDoc.data(),
    }

    const checkListDoc = await db
      .collection("checklists")
      .doc(checkListInstanceDoc.data().check_list_id)
      .get()

    const checkListValues = { id: checkListDoc.id, ...checkListDoc.data() }
    setCheckList(checkListValues)

    const convertedCheckListInstanceData = convertCheckListDataFromFirestore(
      checkListValues,
      initialCheckListInstanceData.check_list_data
    )

    const newValues = {
      ...initialCheckListInstanceData,
      check_list_data: convertedCheckListInstanceData,
    }
    setValues(newValues)

    const centreDoc = await db
      .collection("centres")
      .doc(checkListInstanceDoc.data().centre_id)
      .get()
    setCentre({ id: centreDoc.id, ...centreDoc.data() })

    const userDoc = await db
      .collection("users")
      .doc(checkListInstanceDoc.data().user_id)
      .get()
    setUser({ id: userDoc.id, ...userDoc.data() })

    const fileNames = _.uniq(
      _.flatten(Object.values(newValues.check_list_data.files))
    )

    const fileUrls = await getFileUrls(accountId, id, fileNames)
    setFileUrls(fileUrls)
  }

  // Load checklist

  useEffect(() => {
    if (!accountId) return

    loadData({ id, accountId })
  }, [accountId, id])

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography variant="h4">{values?.check_list_name}</Typography>
          }
          subheader={<Typography variant="h6">{subheaderValue}</Typography>}
        />
        {user && centre && values && checkList && fileUrls && (
          <CardContent>
            <Box sx={{ marginBottom: "20px" }}>
              <CheckListRating values={values} />
            </Box>
            {checkList.sections
              .sort((s) => s.seq)
              .map((section, index) => (
                <Stack
                  key={index}
                  direction="column"
                  gap={2}
                  sx={{ marginTop: "20px" }}
                >
                  <Typography variant="h6" sx={{ marginBotton: "10px" }}>
                    {section.label}
                  </Typography>
                  {section.elements.map((element, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <Typography variant="body1">{element.label}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {element.text}
                        </Typography>
                      </Box>
                      <Box sx={{ marginLeft: "20px" }}>
                        <Answer
                          section={section}
                          element={element}
                          values={values}
                          fileUrls={fileUrls}
                        />
                      </Box>
                    </Box>
                  ))}
                </Stack>
              ))}

            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          </CardContent>
        )}
      </Card>
    </>
  )
}

const Answer = ({ section, element, values, fileUrls }) => {
  const name = useMemo(() => {
    return getName(section, element)
  }, [section, element])

  const getValue = (values, name) => {
    const newVal = values.check_list_data[name]
    return newVal
  }

  const renderValue = useMemo(() => {
    const val = getValue(values, name)

    switch (element.type) {
      case "date":
        return <Typography>{moment(val).format("D-MMM-yyyy")}</Typography>

      case "text":
        return <Typography>{val}</Typography>

      case "signature":
        return (
          <CheckListSignatureField
            section={section}
            element={element}
            values={values}
            isComplete={true}
          />
        )

      case "check":
        const key = `${section.seq}_${element.seq}`
        const comments = values.check_list_data.comments || {}
        const commentValue = comments[key] || ""

        const fileNames = values.check_list_data.files[key] || []

        const fieldUrls = fileUrls.filter((entry) => {
          if (fileNames.length > 0) {
            const isFound = fileNames.includes(entry.fileName)

            return isFound
          }
          return false
        })

        return (
          <Box>
            <Stack direction="row" gap={1}>
              {element.answers?.map((answer) => (
                <SelectableChip
                  answer={answer}
                  value={values.check_list_data[name]}
                  readonly={true}
                  key={answer.label}
                />
              ))}
            </Stack>
            {commentValue && (
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ marginTop: "5px" }}
              >
                {commentValue}
              </Typography>
            )}
            <Stack
              direction="column"
              gap={1}
              sx={{
                maxWidth: "500px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              {fieldUrls.map((item, index) => (
                <Box key={item.url}>
                  <img
                    src={item.url}
                    style={{ width: "100%" }}
                    alt={item.name}
                  />
                </Box>
              ))}
            </Stack>
          </Box>
        )

      default:
        return "x"
    }
  }, [element, section, name, values])

  return <Box>{renderValue}</Box>
}

export default CheckListPrintForm
