import React, { createRef } from "react"
import { Card, CardActions, CardContent, CardHeader, IconButton, Typography } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { spacing } from "../../pages/services/styleServices"

const styles = {
    imageCard: {
        margin: 0,
        marginTop: spacing(1),
        padding: 0,
    },
    imageCardContent: {
        margin: 0,
        padding: 0,
    },
}

const Image = (props) => {
    const imgRef = createRef()

    const { fileMeta, maxWidth, handleClickDelete } = props

    const handleOnLoad = () => {

        const scaledWidth = Math.min(maxWidth, imgRef.current.width)

        // Max width of image
        const widthScale = scaledWidth / imgRef.current.width

        // Scale height accordingly
        const scaledHeight = widthScale * imgRef.current.height

        // Update image dimensions
        imgRef.current.width = scaledWidth
        imgRef.current.height = scaledHeight
    }

    return (
        <Card sx={styles.imageCard}>
            <CardHeader title={<Typography>{fileMeta.fileName}</Typography>} />
            <CardContent sx={styles.imageCardContent}>
                <img ref={imgRef} src={fileMeta.url} onLoad={handleOnLoad} />
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="delete" onClick={handleClickDelete} size="large">
                    <DeleteIcon />
                </IconButton>
            </CardActions>
        </Card>
    )
}

export default Image
