import React, { useMemo, useRef, useState } from "react"
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  colors,
} from "@mui/material"
import TruncatedText from "./TruncatedText"
import LabelValue from "./LabelValue"
import CentreName from "./CentreName"
import Priority from "./Priority"
import Controls from "./controls/Controls"
import JobMenu from "./JobMenu"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import * as actionServices from "../pages/services/actionServices"
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService"

const JobTile = ({
  job,
  isEditable,
  isSupplierViewing,
  handleCloseJob,
  handleOpenJob,
  handleCompleteJob,
  handleEditJob,
  priorities,
  jobsActions,
  handleFileUpload,
  setJobToShowPhotos,
  showPhotos = true,
  showRemoveJobPrompt,
  // could be 'work_order' if opening job from work order edit page
  fromType = undefined,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpen = Boolean(anchorEl)

  const addFileRef = useRef()

  const handleToggleMenu = (event) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleAddPhotoClick = (event) => {
    event.preventDefault()
    addFileRef.current.click()
  }

  const closeMenuAndExecute = (callback) => {
    setAnchorEl(null)
    callback(job)
  }

  const actions = useMemo(() => {
    if (job && jobsActions) {
      return jobsActions.filter((action) => action.parent_id === job.id)
    }
  }, [job, jobsActions])

  const hasNonClosedActions = useMemo(() => {
    if (actions) {
      return actions.find(
        (action) => action.status !== actionServices.STATUS_CLOSED
      )
    }
  }, [actions])

  const jobMenuProps = useMemo(() => {
    const menuProps = {}

    if (handleCloseJob) {
      menuProps.handleCloseJob = () => closeMenuAndExecute(handleCloseJob)
    }

    if (handleOpenJob) {
      menuProps.handleOpenJob = () => closeMenuAndExecute(handleOpenJob)
    }

    if (handleCompleteJob) {
      menuProps.handleCompleteJob = () => closeMenuAndExecute(handleCompleteJob)
    }

    if (handleEditJob) {
      menuProps.handleEditJob = () => closeMenuAndExecute(handleEditJob)
    }

    if (handleFileUpload) {
      menuProps.handleFileUpload = (e) => handleFileUpload(job, e)
    }

    if (setJobToShowPhotos) {
      menuProps.handleViewPhotos = () => closeMenuAndExecute(setJobToShowPhotos)
    }

    return menuProps
  }, [
    handleCloseJob,
    handleOpenJob,
    handleCompleteJob,
    handleEditJob,
    handleFileUpload,
    setJobToShowPhotos,
  ])

  return (
    <>
      <Card sx={{ width: "300px" }}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="selected-files"
          multiple
          type="file"
          ref={addFileRef}
          onChange={(e) => {
            handleFileUpload(job, e)
          }}
        />
        <CardHeader
          title={
            <TruncatedText
              variant="body1"
              noWrap
              sx={{
                fontWeight: "bold",
                mb: "5px",
                maxWidth: "240px",
              }}
            >
              {job.label}
            </TruncatedText>
          }
          subheader={
            <Controls.JobStatusChip
              status={job.status}
              showBadge={hasNonClosedActions}
            />
          }
          action={
            <IconButton aria-label="settings" onClick={handleToggleMenu}>
              <MoreVertIcon />
            </IconButton>
          }
        />
        <CardActionArea onClick={() => closeMenuAndExecute(handleEditJob)}>
          <CardContent sx={{ padding: "15px" }}>
            <Stack gap={0.5}>
              <LabelValue
                label="Centre"
                value={
                  <CentreName
                    truncateWidth="100px"
                    centre={job.centre}
                    isSupplierViewing={isSupplierViewing}
                    textAlign="right"
                  />
                }
              />
              <LabelValue label="Category" value={job.category} />
              <LabelValue
                label="Priority"
                value={
                  <Priority priority={job.priority} priorities={priorities} />
                }
              />
              <LabelValue label="Location" value={job.location} />
              <LabelValue
                label="Job Owner"
                value={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      gap: 0.5
                    }}
                  >
                    {job.self_repairable && (
                      <Tooltip title="Self Repairable" arrow>
                      <HomeRepairServiceIcon
                        fontSize="small"
                        sx={{ color: colors.pink[200] }}
                      />
                      </Tooltip>
                    )}
                    {job.job_owner?.name}
                  </Box>
                }
              />
              <LabelValue label="Actions" value={actions?.length} />
              <LabelValue
                label="Self Repairable"
                value={job.self_repairable ? "Yes" : "No"}
              />
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      {menuOpen && (
        <JobMenu
          job={job}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          menuId={`menu-${job.id}`}
          isEditable={isEditable}
          isSupplierViewing={isSupplierViewing}
          // handleCloseJob={() => closeMenuAndExecute(handleCloseJob)}
          // handleOpenJob={() => closeMenuAndExecute(handleOpenJob)}
          // handleCompleteJob={() => closeMenuAndExecute(handleCompleteJob)}
          // handleClickAddPhoto={handleAddPhotoClick}
          // handleViewPhotos={() => closeMenuAndExecute(setJobToShowPhotos)}
          // handleEditJob={() => closeMenuAndExecute(handleEditJob)}
          handleRemoveJob={() => closeMenuAndExecute(showRemoveJobPrompt)}
          showPhotos={showPhotos}
          {...jobMenuProps}
        />
      )}
    </>
  )
}

export default JobTile
