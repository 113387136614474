import { FormLabel, Typography } from "@mui/material"

const SubHeading = ({ title }) => {
  return (
    <FormLabel>
      <Typography variant="h5" color="text.primary" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>
    </FormLabel>
  )
}

export default SubHeading
