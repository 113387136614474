import * as actions from "../actionTypes"

const initialState = []

export default function centres(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CENTRES:
      switch (action.payload.change_type) {
        case "add":
          const addedResult = [
            ...state.filter((centre) => centre.id !== action.payload.id),
            {
              id: action.payload.id,
              name: action.payload.value.name,
              short_name: action.payload.value.short_name,
            },
          ]

          return addedResult

        case "modify":
          const modifiedResult = state.map((centre) => {
            if (centre.id === action.payload.id) {
              return {
                ...centre,
                name: action.payload.value.name,
                short_name: action.payload.value.short_name,
              }
            }
            return centre
          })

          return modifiedResult

        case "remove":
          const removedResult = state.filter(
            (centre) => centre.id !== action.payload.id
          )

          return removedResult
        default:
          return state
      }

    default:
      return state
  }
}
