import React from "react"
import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  CardHeader,
} from "@mui/material"
import { Link } from "react-router-dom"
import TruncatedText from "./TruncatedText"
import LabelValue from "./LabelValue"

const styles = {
  root: {
    flexGrow: 1,
    minWidth: 175,
    maxWidth: 175,
  },
  sectionLabels: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
}

const CheckListCard = ({ checklist }) => {
  const url = () => "/CheckListEdit/" + checklist.id

  return (
    <Card sx={{ width: "300px" }}>
      <CardActionArea component={Link} to={url}>
        <CardHeader
          title={
            <TruncatedText
              variant="body1"
              noWrap
              sx={{
                fontWeight: "bold",
                marginBottom: "5px",
                maxWidth: "240px",
              }}
            >
              {checklist.name}
            </TruncatedText>
          }
          subheader={
            <Typography variant="caption" color="textSecondary">
              Version {checklist.version || 1}{" "}
              {checklist.archived && " | Archived"}
            </Typography>
          }
        />

        <CardContent>
          <Box sx={styles.sectionLabels}>
            <LabelValue label="Sections" value={checklist.sections.length} />
            {checklist.sections.map((section) => (
              <Typography variant="caption" key={section.seq}>
                {section.label}
              </Typography>
            ))}
            <Box sx={{ marginTop: "10px" }}>
              <LabelValue
                label="Archived"
                value={checklist.archived ? "Yes" : "No"}
              />
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default CheckListCard
