import React, { useEffect, useState } from "react"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import _ from "lodash"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  List,
  ListItem,
  Typography,
  FormControlLabel,
  Autocomplete,
  TextField,
  Chip,
  RadioGroup,
  Radio,
  Switch,
  Tooltip,
  Stack,
} from "@mui/material"
import Controls from "./controls/Controls"
import { useSnackbar } from "notistack"
import * as UIConstants from "./controls/UIConstants"
import { withRouter } from "react-router-dom"
import * as supplierServices from "../pages/services/supplierServices"
import * as colorServices from "../pages/services/colorServices"
import { Alert } from "@mui/material"
import { values } from "lodash"
import LinkButton from "./controls/LinkButton"
import { spacing } from "../pages/services/styleServices"
import JobStatusChip from "./controls/JobStatusChip"
import NavButtons from "./NavButtons"
import DraggablePaper from "../components/DraggablePaper"
import {
  ALLOCATE_NEW,
  allocationOptions,
  allocateJobs,
  ALLOCATE_NEW_FOR_QUOTES,
  ALLOCATE_EXISTING,
  ALLOCATE_INTERNAL,
} from "../pages/services/workOrderServices"
import * as jobServices from "../pages/services/jobServices"
import * as fileServices from "../pages/services/fileServices"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import { useMemo } from "react"
import SubHeading from "./SubHeading"
import { renderEmailComponent } from "../pages/services/emailServices"
import WorkOrderEmail from "../emails/WorkOrderEmail"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import * as Roles from "../pages/services/roleServices"

const styles = {
  buttons: {
    marginTop: spacing(2),
  },
  marginTop: {
    marginTop: spacing(2),
  },
  noPadding: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  workOrderDetails: {
    display: "flex",
    flexDirection: "column",
  },
  jobInfo: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: spacing(1),
    alignItems: "center",
  },
}

const AllocateJobsDialog = (props) => {
  const state = props.location.state

  const { open, handleAllocated, handleClose, jobsToAllocate } = props

  const [supplierId, setSupplierId] = useState(state ? state.supplier_id : "")

  const [docStatuses, setDocStatuses] = useState([])

  const [suppliers, setSuppliers] = useState()

  const [workOrders, setWorkOrders] = useState()

  const [jobTypes, setJobTypes] = useState([])

  const [coverage, setCoverage] = useState([])

  const [workOrderId, setWorkOrderId] = useState("")

  const [allocateOption, setAllocateOption] = useState(ALLOCATE_NEW)

  const [makeVisibleToSupplier, setMakeVisibleToSupplier] = useState(true)

  const { enqueueSnackbar } = useSnackbar()

  const [supplierDocTypes, setSupplierDocTypes] = useState()

  const [reallocate, setReallocate] = useState(false)

  const [userEmail, setUserEmail] = useState()

  const [jobs, setJobs] = useState([])

  const [userId, setUserId] = useState("")

  const [centres, setCentres] = useState([])

  const [accountId, setAccountId] = useState()

  const [isSendEmail, setSendEmail] = useState(true)

  const [jobFileUrls, setJobFileUrls] = useState()

  const [accountType, setAccountType] = useState()

  // All of the files for all jobs being allocated, which are used to create an email to the supplier
  const [loadedFiles, setLoadedFiles] = useState([])

  const isSupplierAlreadyOnWorkOrder = useMemo(() => {
    return (
      state &&
      state.hasOwnProperty("supplier_id") &&
      state.supplier_id !== "" &&
      state.supplier_id !== undefined
    )
  }, [state])

  const isSupplierComboReadOnly = useMemo(() => {
    return (
      isSupplierAlreadyOnWorkOrder || allocateOption === ALLOCATE_NEW_FOR_QUOTES
    )
  }, [isSupplierAlreadyOnWorkOrder, allocateOption])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        getAuth()
          .currentUser.getIdTokenResult()
          .then((token) => {
            setAccountId(token.claims.account_id)
            setAccountType(token.claims.account_type)
            setUserEmail(user.email)
          })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    if (isSendEmail && jobs.length > 0 && jobFileUrls === undefined) {
      jobServices.getJobFileUrls({ jobs }).then((result) => {
        setJobFileUrls(result)
      })
    }
  }, [isSendEmail, jobs])

  useEffect(() => {
    if (
      loadedFiles.length === 0 &&
      isSendEmail &&
      jobFileUrls?.length > 0 &&
      jobs?.length > 0 &&
      centres?.length > 0
    ) {
      jobServices.getJobFileInfo({
        jobFileUrls,
        jobs,
        centres,
        fileLoadedCallback: setLoadedFiles,
      })
    }
  }, [isSendEmail, centres, jobs, jobFileUrls])

  useEffect(() => {
    if (accountId) {
      supplierServices
        .loadSupplierDocTypes(accountId)
        .then((supplierDocTypes) => {
          setSupplierDocTypes(supplierDocTypes)
        })
    }
  }, [accountId])

  // Load jobs so we can create a summary label

  useEffect(() => {
    if (open && jobsToAllocate.length > 0 && accountId) {
      // Make sure checkbox is unchecked to begin with
      setReallocate(false)

      const jobIds = jobsToAllocate.map((item) => item.id)
      dataServices
        .getJobsByIdChunks({ accountId, accountType, jobIds })
        .then((jobs) => {
          setJobs(jobs)

          const centreIds = Array.from(
            new Set(jobs.map((job) => job.centre_id))
          ).filter((id) => id !== null)

          dataServices
            .getCentresByIdChunks(centreIds)
            .then((centres) => setCentres(centres))
        })

      dataServices
        .getJobsById({ accountId, accountType, jobIds })
        .then((jobs) => {
          const supplierIds = jobs
            .filter((job) => job.supplier_id !== "")
            .map((job) => job.supplier_id)

          if (supplierIds.length > 0) {
            dataServices
              .getSuppliersById(supplierIds, accountId)
              .then((suppliers) => {
                setSuppliers(suppliers)
              })
          }

          const workOrderIds = Array.from(
            new Set(
              jobs
                .filter((job) => job.work_order_id !== "")
                .map((job) => job.work_order_id)
            )
          )

          if (workOrderIds.length > 0) {
            dataServices
              .getWorkOrdersById({ workOrderIds, accountId })
              .then((workOrders) => {
                setWorkOrders(workOrders)
              })
          }
        })
    } else {
      setJobs([])
    }
  }, [jobsToAllocate, accountId, open])

  useEffect(() => {
    if (supplierDocTypes && accountId) {
      if (supplierId) {
        dataServices
          .getSuppliersById([supplierId], accountId)
          .then((suppliers) => {
            if (suppliers.length > 0) {
              const docStatuses = supplierServices.getSupplierDocStatuses(
                suppliers[0],
                supplierDocTypes
              )
              setDocStatuses(docStatuses)
            } else {
              setDocStatuses([])
            }
          })
      } else {
        setDocStatuses([])
      }
    }
  }, [supplierId, supplierDocTypes, accountId])

  // Should we offer to the user to make the work order (and jobs) visible to the supplier?
  const isMakeWorkOrderVisibleToSupplier = useMemo(() => {
    if (jobs) {
      // Are any jobs pending? i.e. that need to be made open to the supplier
      const isPending = jobs.some(
        (job) => job.status === jobServices.JOB_STATUS_PENDING
      )
      return isPending
    }
    return false
  }, [jobs])

  const createJobSummary = (job) => {
    let summary = job.label

    if (job.category) {
      summary = summary + " " + job.category
    }

    if (job.location) {
      summary = summary + " " + job.location
    }

    if (job.centre_id) {
      const centre = centres.find((centre) => centre.id === job.centre_id)
      if (centre) {
        summary = summary + " " + centre.name
      }
    }

    return summary
  }

  const handleAllocateJobs = async (event) => {
    event.preventDefault()

    // Pre-conditions - supplier must be selected for 'new', or otherwise supplier can be blank if requesting quotes

    if (supplierId === "" && allocateOption === ALLOCATE_NEW) {
      enqueueSnackbar("Select a supplier", { variant: "info" })
      return
    }

    const jobIds = jobsToAllocate.map((item) => item.id)

    const outWorkOrderId = await allocateJobs({
      jobIds,
      supplierId,
      // user id to allocate to. one of supplier id or user id should be specified, but not both
      userId,
      allocateOption,
      workOrderId,
      accountId,
      accountType,
      // We only make the jobs visible to the supplier if the user has
      // checked the switch and has the Allocate to new work order option selected
      makeVisibleToSupplier:
        makeVisibleToSupplier &&
        (allocateOption === ALLOCATE_NEW ||
          allocateOption === ALLOCATE_INTERNAL),
    })

    enqueueSnackbar("Jobs allocated", { variant: "success" })

    handleAllocated(outWorkOrderId)

    // Send work order email to supplier

    const priorities = await jobServices.getJobPriorities(accountId)

    const centreManagerIds = centres
      .map((centre) => centre.user_id_contact)
      .filter((item) => item)

    const centreManagers = await dataServices.getUsersByIdChunks(
      accountId,
      centreManagerIds
    )

    const workOrderDoc = await db
      .collection("work_orders")
      .doc(outWorkOrderId)
      .get()

    const workOrder = workOrderDoc.data()

    if (isSendEmail) {
      console.log("create email", {
        workOrder,
        jobs,
        centres,
        priorities,
        message: values.message,
        jobFileUrls,
        centreManagers,
      })

      renderEmailComponent(
        WorkOrderEmail,
        {
          workOrder,
          jobs,
          centres,
          priorities,
          message: values.message,
          jobFileUrls,
          centreManagers,
        },
        async (html) => {
          const attachments = loadedFiles.map((item) => {
            return {
              content: item.base64data,
              filename: item.fileName,
              type: fileServices.getMimeType(item.fileName),
              disposition: "inline",
              content_id: item.fileName, // includes job id in file name
            }
          })

          const getRecipientEmail = async ({ supplierId, userId }) => {
            if (supplierId) {
              const supplierDoc = await db
                .collection("suppliers")
                .doc(supplierId)
                .get()
              const supplier = supplierDoc.data()
              return supplier.email
            } else if (userId) {
              const userDoc = await db.collection("users").doc(userId).get()
              const user = userDoc.data()
              return user.email
            }
          }

          const recipientEmail = await getRecipientEmail({ supplierId, userId })

          const msg = {
            from: userEmail,
            to: recipientEmail,
            subject: `Work Order: ${workOrder.work_order_no} - ${workOrder.label}`,
            html: html,
            attachments: attachments,
          }

          console.log("%csending email", "color:pink", {
            from: msg.from,
            to: msg.to,
            subject: msg.subject,
            attachments: msg.attachments,
          })

          const result = await cloudFunctions.sendMultipartEmailMessage(msg)

          console.log("sent email", { result })
        }
      )
    }
  }

  const handleToggleReallocate = (event) => {
    setReallocate(!reallocate)
  }

  const handleSupplierChange = (event) => {
    const value = event.target.value === null ? "" : event.target.value
    setSupplierId(value)
  }

  const handleWorkOrderChange = (event) => {
    const value = event.target.value === null ? "" : event.target.value

    setWorkOrderId(value)

    if (value !== "") {
      dataServices
        .getWorkOrdersById({ workOrderIds: [value], accountId })
        .then((results) => {
          if (results.length > 0) {
            const workOrder = results[0]

            setSupplierId(workOrder.supplier_id)
          }
        })
    }
  }

  const handleChangeAllocateOption = (event) => {
    setAllocateOption(event.target.value)

    if (event.target.value === ALLOCATE_NEW_FOR_QUOTES) {
      setSupplierId("")
    }
  }

  const isJobsAlreadyAllocated = (jobs) => {
    return jobs && jobs.find((job) => job.work_order_id !== "")
  }

  return (
    <Dialog open={open} onClose={handleClose} PaperComponent={DraggablePaper}>
      <form>
        <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
          Allocate Jobs
        </DialogTitle>
        <DialogContent sx={styles.allocateDialog}>
          <Stack sx={{ mt: "20px" }} gap={2}>
            <RadioGroup
              aria-label="allocate"
              name="allocate"
              value={allocateOption}
              onChange={handleChangeAllocateOption}
            >
              {allocationOptions.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mb: "10px",
                  }}
                >
                  <FormControlLabel
                    key={item.id}
                    value={item.id}
                    control={<Radio />}
                    label={item.title}
                  />
                  {item.id === ALLOCATE_EXISTING && (
                    <Box sx={{ ml: "30px" }}>
                      <Controls.WorkOrderCombobox
                        name="work_order_id"
                        label="Existing Work Order"
                        value={workOrderId}
                        accountId={accountId}
                        accountType={accountType}
                        onChange={handleWorkOrderChange}
                        readonly={
                          allocateOption !== ALLOCATE_EXISTING ||
                          isSupplierAlreadyOnWorkOrder
                        }
                      />
                    </Box>
                  )}

                  {item.id === ALLOCATE_INTERNAL && (
                    <Box sx={{ ml: "30px", mt: "10px" }}>
                      <Controls.UserAutoComplete
                        accountId={accountId}
                        userId={userId}
                        handleChange={(user) => setUserId(user?.id || "")}
                        handleClear={() => setUserId("")}
                        label="Staff Member"
                        helperText="Enter email"
                        roles={[Roles.MAINTENANCE]}
                      />
                    </Box>
                  )}

                  {item.id === ALLOCATE_NEW && (
                    <AllocateNew
                      setMakeVisibleToSupplier={setMakeVisibleToSupplier}
                      makeVisibleToSupplier={makeVisibleToSupplier}
                      accountId={accountId}
                      supplierId={supplierId}
                      allocateOption={allocateOption}
                      handleSupplierChange={handleSupplierChange}
                      isSupplierComboReadOnly={isSupplierComboReadOnly}
                      coverage={coverage}
                      setCoverage={setCoverage}
                      jobTypes={jobTypes}
                      setJobTypes={setJobTypes}
                      docStatuses={docStatuses}
                    />
                  )}
                </Box>
              ))}
            </RadioGroup>

            <SubHeading title="Jobs selected to allocate" />

            <List>
              {jobs.map((job) => (
                <ListItem key={job.id}>
                  <Box sx={styles.workOrderDetails}>
                    <Box sx={styles.jobInfo}>
                      <LinkButton to={`/JobEdit/${job.id}`}>
                        {createJobSummary(job)}
                      </LinkButton>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                        mt: "5px",
                      }}
                    >
                      <Chip
                        label={job.category || "No category"}
                        size="small"
                        sx={{
                          cursor: job.category === "" ? "pointer" : "hand",
                          backgroundColor: colorServices.JOB_TYPES,
                        }}
                        onClick={() => {
                          if (job.category === "") return
                          setJobTypes((curr) => _.uniq([...curr, job.category]))
                        }}
                      />

                      <JobStatusChip status={job.status} />
                    </Box>
                    {job.work_order_id !== "" && (
                      <Typography variant="caption" color="text.secondary">
                        <Typography
                          sx={{
                            fontWeight: 700,
                          }}
                        >
                          On work order
                        </Typography>{" "}
                        -
                        {job.work_order_id !== "" &&
                          workOrders &&
                          workOrders.find((w) => w.id === job.work_order_id)
                            ?.work_order_no}{" "}
                        {suppliers &&
                          suppliers.find((s) => s.id === job.supplier_id)?.name}
                        {job.work_order_id && " - "}
                        {job.work_order_id !== "" &&
                          workOrders &&
                          workOrders.find((w) => w.id === job.work_order_id)
                            ?.status}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>

            <Box style={{ mt: "5px" }}>
              {isJobsAlreadyAllocated(jobs) && (
                <Box>
                  <Typography>
                    Reallocate jobs that are already on a work order?
                  </Typography>
                  <Controls.Checkbox
                    label="Reallocate Jobs"
                    value={values.reallocate}
                    onChange={handleToggleReallocate}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ marginLeft: "auto" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSendEmail}
                    onChange={(e, newValue) => setSendEmail(newValue)}
                  />
                }
                label="Send work order email to supplier"
                labelPlacement="start"
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box sx={{ mr: "15px" }}>
            <NavButtons>
              <Controls.Button text="Cancel" onClick={handleClose} />
              <Controls.Button
                type="submit"
                text="Allocate Jobs"
                onClick={handleAllocateJobs}
                disabled={isJobsAlreadyAllocated(jobs) && !reallocate}
              />
            </NavButtons>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const AllocateNew = ({
  setMakeVisibleToSupplier,
  makeVisibleToSupplier,
  accountId,
  supplierId,
  allocateOption,
  handleSupplierChange,
  isSupplierComboReadOnly,
  coverage,
  setCoverage,
  jobTypes,
  setJobTypes,
  docStatuses,
}) => {
  const [jobTypeOptions, setJobTypeOptions] = useState([])

  const [coverageOptions, setCoverageOptions] = useState([])

  useEffect(() => {
    if (accountId) {
      db.collection("lookups")
        .where("account_id", "==", accountId)
        .where("name", "==", "job_types")
        .get()
        .then((querySnapshot) => {
          const options = querySnapshot.docs.flatMap(
            (doc) => doc.data().lookup_values
          )
          setJobTypeOptions(options)
        })
    }
  }, [accountId])

  useEffect(() => {
    if (accountId) {
      db.collection("coverage")
        .where("account_id", "==", accountId)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length === 1) {
            const options = querySnapshot.docs[0].data().values
            setCoverageOptions(options)
          }
        })
    }
  }, [accountId])

  return (
    <Box sx={{ ml: "30px" }}>
      <Stack gap={2}>
        <Box>
          <Tooltip title="Change status of work order and jobs so supplier can see them">
            <span>
              <FormControlLabel
                control={
                  <Switch
                    disabled={allocateOption !== ALLOCATE_NEW}
                    checked={makeVisibleToSupplier}
                    onChange={(e) => setMakeVisibleToSupplier(e.target.checked)}
                  />
                }
                label={
                  <Typography variant="body2">
                    Make work order and jobs visible to supplier when you click
                    Allocate Jobs
                  </Typography>
                }
              />
            </span>
          </Tooltip>
          <Box sx={{ ml: "45px" }}>
            <Typography variant="caption" color="text.secondary">
              If unchecked, you will need to change the Work Order status to
              Allocated later on.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ maxWidth: "400px" }}>
          <Controls.SupplierCombobox
            name="supplier_id"
            label="Active Suppliers"
            accountId={accountId}
            value={supplierId}
            showChips={true}
            onChange={handleSupplierChange}
            // If we open this page passing a supplier id, then it means 1 or more of the jobs is already allocated
            // to that supplier -- so prevent the selected supplier from being changed
            readonly={isSupplierComboReadOnly}
            filterOptions={(supplier) => {
              if (jobTypes.length === 0 && coverage.length === 0) {
                return supplier
              }

              const foundCoverage =
                coverage.length === 0 ||
                (coverage.length > 0 &&
                  supplier.coverage?.some((c) =>
                    coverage.find(
                      (cov) => cov.state === c.state && cov.area === c.area
                    )
                  ))

              // Check if supplier.job_types contains any jobTypes
              const foundJobType =
                jobTypes.length === 0 ||
                supplier.job_types?.some((jobType) =>
                  jobTypes.includes(jobType)
                )

              return foundJobType && foundCoverage
            }}
          />
        </Box>

        <Box sx={{ marginLeft: "12px" }}>
          <FormControlLabel
            control={
              <Autocomplete
                multiple
                limitTags={10}
                onChange={(event, newValue) => {
                  setJobTypes(newValue)
                }}
                options={jobTypeOptions}
                value={jobTypes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter suppliers by job type"
                    placeholder="Job Type"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    sx={{ minWidth: "300px" }}
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      size="small"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                variant={UIConstants.STANDARD_INPUT_VARIANT}
                size={UIConstants.STANDARD_INPUT_SIZE}
              />
            }
          />
        </Box>

        <Box sx={{ marginLeft: "12px" }}>
          <FormControlLabel
            control={
              <Autocomplete
                multiple
                limitTags={10}
                onChange={(event, newValue) => {
                  setCoverage(newValue)
                }}
                isOptionEqualToValue={(option, value) =>
                  option.state === value.state && option.area === value.area
                }
                getOptionLabel={(option) => `${option.state}, ${option.area}`}
                options={coverageOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter suppliers by coverage"
                    placeholder="Coverage"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    sx={{ minWidth: "300px" }}
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      size="small"
                      label={`${option.state}, ${option.area}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                variant={UIConstants.STANDARD_INPUT_VARIANT}
                size={UIConstants.STANDARD_INPUT_SIZE}
              />
            }
          />
        </Box>

        {docStatuses && docStatuses.length > 0 && (
          <Alert severity="warning">
            Check supplier document validity -{" "}
            {docStatuses.map((doc) => doc.type).join(", ")}
          </Alert>
        )}
      </Stack>
    </Box>
  )
}

export default withRouter(AllocateJobsDialog)
