import React from "react"
import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material"

function Checkbox(props) {
  const { name, label, value, onChange, ...other } = props

  const convertToDefaultEventParams = (name, value) => ({
    target: {
      name,
      value,
    },
  })

  return (
    <FormControl>
      <FormControlLabel
        control={
          <MuiCheckbox
            name={name}
            color="primary"
            checked={value}
            {...other}
            onChange={(e) =>
              onChange(convertToDefaultEventParams(name, e.target.checked))
            }
          />
        }
        label={label}
      />
    </FormControl>
  )
}

export default Checkbox
