import {
  Body,
  Container,
  Column,
  Head,
  Heading,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Text,
} from "@react-email/components"
import * as React from "react"
import { baseUrl } from "./BaseEmailTemplate"

/**
 * @expiryDate is a formatted date/time string when the 6-digit code expires
 * @returns
 */
export const ConfirmationCodeEmail = ({ code = "", expiryDate = "" }) => (
  <Html>
    <Head />
    <Preview>Logging into Jobs for Joe</Preview>
    <Body style={main}>
      <Container style={container}>
        <Section style={logoContainer}>
          <Img
            src={`${baseUrl}/j4j-logo-full-white.png`}
            width="120"
            //height="96"
            alt="Jobs for Joe"
          />
        </Section>
        <Heading style={h1}>6-digit code</Heading>
        <Text style={heroText}>
          Your confirmation code is below - enter it in the login page and we'll
          help you get signed in.
        </Text>

        <Section style={codeBox}>
          <Text style={confirmationCodeText}>{code}</Text>
        </Section>

        <Text style={text}>
          If you didn't request this email, there's nothing to worry about - you
          can safely ignore it.
        </Text>

        <Text style={text}>
          This code is only valid for 10 minutes after it was generated, and
          expires at {expiryDate}
        </Text>

        <Text style={text}>
          If you request another 6-digit code, this code becomes invalid.
        </Text>

        <Text style={text}>
          Once you have logged in, you can go to My Account | My Profile on the
          left menu, then select Enable QR Code. This will display a QR Code
          that you can scan with your phone camera using Google Authenticator or
          Microsoft Authenticator. This will register Jobs for Joe in your
          authenticator app, and so allow you to then obtain a 6-digit code on
          the Login page from your authenticator instead of via email.
        </Text>

        {/* <Section>
                    <Row style={footerLogos}>
                        <Column style={{ width: "66%" }}>
                            <Img
                                src={`/j4j-logo-full-white.png`}
                                width="120"
                                //height="36"
                                alt="Jobs For Joe"
                            />
                        </Column>
                        <Column>
                            <Row>
                                <Column>
                                    <Link href="/">
                                        <Img
                                            src={`/static/twitter.png`}
                                            width="32"
                                            height="32"
                                            alt="Slack"
                                            style={socialMediaIcon}
                                        />
                                    </Link>
                                </Column>
                                <Column>
                                    <Link href="/">
                                        <Img
                                            src={`/static/facebook.png`}
                                            width="32"
                                            height="32"
                                            alt="Slack"
                                            style={socialMediaIcon}
                                        />
                                    </Link>
                                </Column>
                                <Column>
                                    <Link href="/">
                                        <Img
                                            src={`/static/linkedin.png`}
                                            width="32"
                                            height="32"
                                            alt="Slack"
                                            style={socialMediaIcon}
                                        />
                                    </Link>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Section> */}

        <Section>
          {/* <Link
                        style={footerLink}
                        href="https://jobsforjoe.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Our blog
                    </Link>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <Link
                        style={footerLink}
                        href="https://jobsforjoe.com/legal"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Policies
                    </Link>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <Link
                        style={footerLink}
                        href="https://jobsforjoe.com/help"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Help center
                    </Link>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <Link
                        style={footerLink}
                        href="https://jobsforjoe.com/community"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-auth="NotApplicable"
                        data-linkindex="6"
                    >
                        Jobs For Joe Community
                    </Link> */}
          <Text style={footerText}>
            ©2024 Jobs For Joe Pty Ltd. <br />
            {/* /// Address here.... <br />
                        <br /> */}
            All rights reserved.
          </Text>
        </Section>
      </Container>
    </Body>
  </Html>
)

export default ConfirmationCodeEmail

const footerText = {
  fontSize: "12px",
  color: "#b7b7b7",
  lineHeight: "15px",
  textAlign: "left",
  marginBottom: "50px",
}

const footerLink = {
  color: "#b7b7b7",
  textDecoration: "underline",
}

const footerLogos = {
  marginBottom: "32px",
  paddingLeft: "8px",
  paddingRight: "8px",
  width: "100%",
}

const socialMediaIcon = {
  display: "inline",
  marginLeft: "32px",
}

const main = {
  backgroundColor: "#ffffff",
  margin: "0 auto",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
}

const container = {
  maxWidth: "600px",
  margin: "0 auto",
}

const logoContainer = {
  marginTop: "32px",
}

const h1 = {
  color: "#1d1c1d",
  fontSize: "36px",
  fontWeight: "700",
  margin: "30px 0",
  padding: "0",
  lineHeight: "42px",
}

const heroText = {
  fontSize: "20px",
  lineHeight: "28px",
  marginBottom: "30px",
}

const codeBox = {
  background: "rgb(245, 244, 245)",
  borderRadius: "4px",
  marginRight: "50px",
  marginBottom: "30px",
  padding: "43px 23px",
}

const confirmationCodeText = {
  fontSize: "30px",
  textAlign: "center",
  verticalAlign: "middle",
}

const text = {
  color: "#000",
  fontSize: "14px",
  lineHeight: "24px",
}
