import React from "react"
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material"
import TruncatedText from "./TruncatedText"
import LabelValue from "./LabelValue"
import * as Roles from "../pages/services/roleServices"

const UserTile = ({ user, centres, handleEditUser }) => {
  return (
    <>
      <Card sx={{ width: "300px" }}>
        <CardActionArea onClick={handleEditUser}>
          <CardHeader
            title={
              <TruncatedText
                variant="body1"
                noWrap
                sx={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  maxWidth: "240px",
                }}
              >
                {user.name}
              </TruncatedText>
            }
            subheader={
              <TruncatedText
                noWrap
                variant="caption"
                sx={{
                  maxWidth: "240px",
                }}
              >
                {user.email}
              </TruncatedText>
            }
          />
          <CardContent sx={{ padding: "15px" }}>
            <Stack gap={0.5}>
              <LabelValue label="Phone" value={user.phone} />
              <LabelValue
                label="Roles"
                value={
                  <Stack>
                    {user?.roles.map((r) => (
                      <Typography key={r} variant="body2" textAlign={"right"}>
                        {Roles.userAssignableRoles[r]}
                      </Typography>
                    ))}
                  </Stack>
                }
              />

              <LabelValue
                label="Centres"
                value={
                  <Stack>
                    {user?.centres?.map((c) => (
                      <Typography
                        key={c}
                        variant="body2"
                        textAlign={"right"}
                      >
                        {centres.find((x) => x.id === c)?.name}
                      </Typography>
                    ))}
                    {user?.centres?.length === 0 && (
                      <Typography variant="body2" textAlign={"right"}>
                        None
                      </Typography>
                    )}
                  </Stack>
                }
              />
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

export default UserTile
