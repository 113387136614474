import React from "react"
import Header from "../components/Header"
import InviteList from "../components/InviteList"

const Invites = (props) => {
  return (
    <Header title="Invites">
      <InviteList />
    </Header>
  )
}

export default Invites
