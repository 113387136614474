import React, { useState, useEffect, useMemo } from "react"
import Controls from "./controls/Controls"
import { useForm } from "./useForm"
import * as dataServices from "../pages/services/dataServices"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import db from "../Firestore"
import { useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import DeleteIcon from "@mui/icons-material/Delete"
import LocationIcon from "@mui/icons-material/LocationOn"
import MergeIcon from "@mui/icons-material/Merge"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import LinkIcon from "@mui/icons-material/Link"
import * as WorkOrder from "../components/workOrderStatus"
import * as scheduleServices from "../pages/services/scheduleServices"
import * as dateServices from "../pages/services/dateServices"
import { RiFileExcel2Fill as ExcelIcon } from "react-icons/ri"
import * as UIConstants from "./controls/UIConstants"
import moment from "moment"
import XLSX from "xlsx"
import { saveAs } from "file-saver"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Chip,
  FormControl,
  IconButton,
  Link,
  Autocomplete,
  Switch,
  FormControlLabel,
  Stack,
  colors,
} from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { useDispatch, useSelector } from "react-redux"
import { setWorkOrderGridPagination } from "../redux/actions"
import { selectWorkOrderGridPagination } from "../redux/selectors"
import { setJobGridPagination } from "../redux/actions"
import { selectJobGridPagination } from "../redux/selectors"
import { isErrors, isNonEmptyString } from "./controls/FieldValidation"
import YesNo from "./YesNo"
import ProgressBackdrop from "./ProgressBackdrop"
import { parseDate } from "../pages/services/dateServices"
import { Typography } from "@mui/material"
import { Tooltip } from "@mui/material"
import * as Roles from "../pages/services/roleServices"
import { Alert } from "@mui/material"
import _ from "lodash"
import { functions } from "../Firestore"
import { httpsCallable } from "firebase/functions"
import * as icons from "../icons"
import ReactTimeAgo from "react-time-ago"
import * as workOrderStatus from "../components/workOrderStatus"
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  listAll,
  getBytes,
} from "firebase/storage"
import { spacing } from "../pages/services/styleServices"
import SupplierMergeDialog from "./SupplierMergeDialog"
import NavButtons from "./NavButtons"
import * as colorServices from "../pages/services/colorServices"
import EmailInvalidAlert from "./EmailInvalidAlert"
import SubHeading from "./SubHeading"
import Contacts from "./Contacts"
import LinkToSupplierAccount from "./LinkToSupplierAccount"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const initialValues = () => {
  return {
    name: "",
    reg_no: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    postcode: "",
    email: "",
    phone: "",
    job_types: [],
    docs: [],
    coverage: [],
    active: true,
    payment_terms: "",
    notes: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
  }
}

const styles = {
  fileButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    mr: spacing(1),
    mt: spacing(0),
  },

  input: {
    display: "none",
  },
  supplierFiles: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& > *": {
      mr: spacing(1),
      mb: spacing(1),
    },
    mt: "15px",
  },
  fileCard: {
    maxWidth: 275,
    width: "100%",
    pb: 0,
    ml: spacing(0.5),
  },
  docAlert: {
    mb: spacing(2),
    parseInt: spacing(0.3),
  },
}

const SupplierEditForm = (props) => {
  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  const workOrderPagination = useSelector(selectWorkOrderGridPagination)

  const jobPagination = useSelector(selectJobGridPagination)

  const [supplierId, setSupplierId] = useState(props.id || "")

  const [jobTypeOptions, setJobTypeOptions] = useState([])

  const [coverageOptions, setCoverageOptions] = useState([])

  const [progressLabel, setProgressLabel] = useState("")

  const [showLinkToSupplierAccount, setShowLinkToSupplierAccount] =
    useState(false)

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete?",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const [fileRemoveYesNoConfig, setFileRemoveYesNoConfig] = useState({
    title: "Remove file?",
    description: "This delete is permanent",
    openPrompt: false,

    // This method is set when we prompt for file removal
    handleConfirm: null,
  })

  const { setTitle } = props

  const history = useHistory()

  const [maxDocId, setMaxDocId] = useState(0)

  // Should we be showing a backdrop with progress indicator?
  // Used when we check if we can delete a supplier
  const [isShowProgress, setShowProgress] = useState(false)

  const isNew = useMemo(() => {
    const isSupplierNew =
      props.id === undefined && (supplierId === "" || supplierId === undefined)

    return isSupplierNew
  }, [props.id, supplierId])

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const [showMerge, setShowMerge] = useState(false)

  // e.g. WWCC, Insurance, etc.
  const [docTypes, setDocTypes] = useState([])

  const [isEditable, setEditable] = useState(undefined)

  const [isAdmin, setAdmin] = useState(false)

  const COLLECTION_NAME = "suppliers"

  const [accountId, setAccountId] = useState()

  const [isCentreAccount, setCentreAccount] = useState()

  const [showFieldErrors, setShowFieldErrors] = useState(false)

  const fieldValidation = {
    name: [isNonEmptyString],
  }

  // Account id of the supplier record.
  // Remember both the creator account and viewing supplier account
  // can view this supplier record, and they'll each have different account ids.

  const [supplierRecAccountId, setSupplierRecAccountId] = useState()

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult().then((token) => {
        setAccountId(token.claims.account_id)
        setEditable(Roles.isSupplierEditable(token.claims.roles))
        setAdmin(Roles.isAdmin(token.claims.roles))
        setCentreAccount(
          token.claims.account_type === Roles.ACCOUNT_TYPE_CENTRE
        )
      })
    })

    return unsub
  }, [])

  const canDeleteSupplier = async (supplierId) => {
    const isDeleteSupplierAllowed = httpsCallable(
      functions,
      "canDeleteSupplier"
    )

    const data = { supplier_id: supplierId }
    return await isDeleteSupplierAllowed(data)
  }

  // Load doc types, e.g. WWCC, Insurance, etc.
  // We use the supplierAccountId for loading, because both the creating centre and supplier
  // can view this supplier info, and we need to use the account id of the supplier record,
  // which is the supplierAccountId, to load doc types, and job types.

  useEffect(() => {
    if (supplierRecAccountId === undefined || isCentreAccount === undefined) {
      return
    }

    // For now, we're only loading documents, and supplier doc types if its the centre viewing the supplier,
    // not the supplier (from their own other account) viewing their details in the centre's account.
    if (!isCentreAccount) {
      return
    }

    let query = db
      .collection("lookups")
      .where("account_id", "==", supplierRecAccountId)
      .where("name", "==", "supplier_doc_types")

    dataServices
      .loadData(`Load supplier doc types`, query)
      .then((supplierDocTypes) => {
        if (supplierDocTypes.length === 1) {
          setDocTypes(supplierDocTypes[0].lookup_values)
        }
      })
  }, [supplierRecAccountId, isCentreAccount])

  useEffect(() => {
    if (isNew && accountId) {
      setValues((curr) =>
        curr.account_id === undefined
          ? { ...curr, account_id: accountId }
          : curr
      )
    }
  }, [isNew, accountId])

  useEffect(() => {
    if (values.account_id) {
      db.collection("lookups")
        .where("account_id", "==", values.account_id)
        .where("name", "==", "job_types")
        .get()
        .then((querySnapshot) => {
          const options = querySnapshot.docs.flatMap(
            (doc) => doc.data().lookup_values
          )
          setJobTypeOptions(options)
        })
    }
  }, [values.account_id])

  useEffect(() => {
    if (values.account_id) {
      db.collection("coverage")
        .where("account_id", "==", values.account_id)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length === 1) {
            const options = querySnapshot.docs[0].data().values
            setCoverageOptions(options)
          }
        })
    }
  }, [values.account_id])

  const loadSupplierDocTypes = async (accountId) => {
    let query = db
      .collection("lookups")
      .where("account_id", "==", accountId)
      .where("name", "==", "supplier_doc_types")

    await dataServices
      .loadData(`Load supplier doc types`, query)
      .then((supplierDocTypes) => {
        if (supplierDocTypes.length === 1) {
          setDocTypes(supplierDocTypes[0].lookup_values)
        }
      })
  }

  const getUniqueTargetFileName = (fileNames, fileName) => {
    let uniqueFileName

    // See if file name already used. If so, we'll rename the file just uploaded
    const found = fileNames.find((name) => name === fileName)

    if (found) {
      const parts = fileName.split(".")

      let foundUniqueName = false
      let counter = 1
      while (!foundUniqueName) {
        // Create a new file name to use. If the file name has no suffix just add a " (1)", " (2)", etc as required until we get a unique name
        // Otherwise if there is a suffix then make sure we strip the suffix out

        uniqueFileName =
          parts.length === 1
            ? fileName + ` {${counter})`
            : parts.slice(0, -1).join(".") +
              ` (${counter}).` +
              parts.slice(-1).pop()

        const isNewNameUsed = fileNames.find(
          (fileName) => uniqueFileName === fileName
        )
        if (!isNewNameUsed) {
          foundUniqueName = true
        } else {
          counter = counter + 1
        }
      }
    } else {
      uniqueFileName = fileName
    }
    return uniqueFileName
  }

  const handleMerge = async (keepSupplierId, mergeAndDeleteSupplierId) => {
    setShowMerge(false)
    setProgressLabel("Merging suppliers...")
    setShowProgress(true)

    // Work out what files need to be copied, and if they need a numeric suffix to avoid duplicates in the target

    const sourceSupplierRef = await db
      .collection("suppliers")
      .doc(mergeAndDeleteSupplierId)
      .get()
    const sourceSupplier = sourceSupplierRef.data()

    const targetSupplierRef = await db
      .collection("suppliers")
      .doc(keepSupplierId)
      .get()
    const targetSupplier = targetSupplierRef.data()

    const sourceFiles = sourceSupplier.docs.map((doc) => ({
      source: doc,
      target: getUniqueTargetFileName(
        targetSupplier.docs.map((doc) => doc.name),
        doc.name
      ),
    }))

    // Merge jobs

    const batch = db.batch()

    // Merge jobs
    const jobsSnapshot = await db
      .collection("jobs")
      .where("account_id", "==", accountId)
      .where("supplier_id", "==", mergeAndDeleteSupplierId)
      .get()

    jobsSnapshot.forEach((doc) => {
      batch.update(doc.ref, {
        supplier_id: keepSupplierId,
        modified: dataServices.serverTimestamp(),
      })
    })

    // Merge work orders
    const workOrdersSnapshot = await db
      .collection("work_orders")
      .where("account_id", "==", accountId)
      .where("supplier_id", "==", mergeAndDeleteSupplierId)
      .get()

    for (const doc of workOrdersSnapshot.docs) {
      batch.update(doc.ref, {
        supplier_id: keepSupplierId,
        modified: dataServices.serverTimestamp(),
      })
    }

    // Commit the batch
    await batch.commit()

    console.log("%c[merge] merged jobs and work orders", "color:yellow")

    // Merge supplier docs in cloud storage

    const fromPath = `/accounts/${accountId}/suppliers/${mergeAndDeleteSupplierId}`
    const fromStorageRef = ref(getStorage(), fromPath)
    const toStorageRef = ref(
      getStorage(),
      `accounts/${accountId}/suppliers/${keepSupplierId}`
    )

    const res = await listAll(fromStorageRef)

    console.log("%c[merge] listed source storage", "color:yellow", {
      fromPath,
      res,
    })
    for (const itemRef of res.items) {
      console.log("%c[merge] supplier doc file names", "color:yellow", {
        from: itemRef.fullPath,
        to: toStorageRef.fullPath,
      })
      const bytes = await getBytes(itemRef)
      console.log("%c[merge] bytes", "color:yellow", {
        from: itemRef.fullPath,
        bytes,
      })

      const targetFileName = sourceFiles.find(
        (file) => file.source.name === itemRef.name
      )
      const toPath = ref(
        getStorage(),
        `${toStorageRef.fullPath}/${targetFileName.target}`
      )
      await uploadBytes(toPath, bytes)

      console.log("%c[merge] uploaded bytes", "color:yellow", {
        source: itemRef.fullPath,
        target: targetFileName,
      })

      // Delete source file
      await deleteObject(itemRef).then(() => {
        console.log(
          "%c[merge] deleted source file",
          "color:yellow",
          itemRef.fullPath
        )
      })
    }

    // Update doc names in target supplier

    const newDocs = [
      ...targetSupplier.docs,
      ...sourceFiles.map((item) => ({
        name: item.target,
        type: item.source.type,
        expiry: item.source.expiry,
        notes: (item.source.notes + " (from merged supplier)").trim(),
      })),
    ]

    // Delete any fields with undefined values, e.g. if expiry is not set

    newDocs.forEach((doc) => {
      Object.keys(doc).forEach((key) => {
        if (doc[key] === undefined) {
          delete doc[key]
        }
      })
    })

    console.log("%c[merge] new merged docs", "color:yellow", newDocs)

    await db.collection("suppliers").doc(keepSupplierId).update(
      {
        docs: newDocs,
        modified: dataServices.serverTimestamp(),
      },
      { merge: true }
    )

    console.log("%c[merge] merged docs", "color:yellow")

    const newValues = {
      ...values,
      docs: newDocs.map((doc, index) => ({
        ...doc,
        expiry:
          doc.hasOwnProperty("expiry") && doc.expiry !== null
            ? parseDate(doc.expiry)
            : null,
        id: index,
      })),
    }

    console.log("%c[merge] new values", "color:yellow", { newValues })

    setValues(newValues)

    // Delete supplier

    await db.collection("suppliers").doc(mergeAndDeleteSupplierId).delete()

    console.log(
      "%c[merge] deleted supplier",
      "color:yellow",
      mergeAndDeleteSupplierId
    )
    setShowProgress(false)
    setProgressLabel("")

    setShowProgress(false)
  }

  // Load supplier

  const [loadState, setLoadState] = useState({})

  useEffect(() => {
    if (supplierId === null || supplierId === "" || supplierId === undefined) {
      return
    }

    const newLoadState = { supplierId: supplierId }

    const isLoadStateChanged = !_.isEqual(loadState, newLoadState)

    setLoadState(newLoadState)

    if (!isLoadStateChanged) {
      return
    }

    loadSupplier(supplierId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId])

  const loadSupplier = async (supplierId) => {
    const docRef = await db.collection(COLLECTION_NAME).doc(supplierId).get()

    const data = {
      ...initialValues(),
      ...docRef.data(),
    }

    await loadSupplierDocTypes(data.account_id)

    // Loading of subsequent supplier related data is based on the
    // account id for the supplier record, not the account id of
    // the user, who may be a supplier and have a different account id
    setSupplierRecAccountId(data.account_id)

    const newDocs = data.docs.map((doc, index) => {
      let exp = null

      if (doc.expiry !== undefined && doc.expiry !== null) {
        exp = parseDate(doc.expiry)
      }

      return {
        ...doc,
        expiry: exp,
        id: index,
      }
    })

    const loadedValues = {
      ...initialValues(),
      ...data,
      docs: newDocs,
    }

    setMaxDocId(newDocs.length)

    setValues(loadedValues)
  }

  // Get download URL for a file that is clicked on

  const getFileDownloadUrl = (event, fileName) => {
    event.preventDefault()

    getAuth()
      .currentUser.getIdTokenResult()
      .then(async (token) => {
        const path = `accounts/${token.claims.account_id}/suppliers/${props.id}/${fileName}`

        const storageRef = ref(getStorage(), path)

        const url = await getDownloadURL(storageRef)

        window.open(url, fileName)
      })
  }

  useEffect(() => {
    setTitle(values.name)
  }, [values.name, setTitle])

  // 'confirmed' is true or false
  const handleDeleteConfirmed = () => {
    // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted

    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)

    if (supplierId !== undefined && supplierId !== "" && supplierId !== null) {
      db.collection(COLLECTION_NAME)
        .doc(supplierId)
        .delete()
        .then(history.goBack())
        .then(enqueueSnackbar("Deleted", { variant: "success" }))
    }
  }

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    setProgressLabel("Deleting...")
    setShowProgress(true)

    enqueueSnackbar("Checking if delete allowed...one moment", {
      variant: "info",
    })

    canDeleteSupplier(supplierId).then((result) => {
      setShowProgress(false)

      if (result.data) {
        const newConfig = {
          ...yesNoConfig,
          openPrompt: true,
          handleConfirm: handleDeleteConfirmed,
        }
        setYesNoConfig(newConfig)
      } else {
        enqueueSnackbar(
          "Supplier referenced by other data. Cannot delete. Make inactive instead.",
          {
            variant: "info",
          }
        )
      }
    })
  }

  const navigateAfterSubmit = () => {
    if (history.length > 0) {
      history.goBack()
    } else {
      history.push("/suppliers")
    }
  }

  // Confirm if the user wants to remove a file

  const handlePromptRemoveDocument = (id) => {
    const newConfig = {
      ...fileRemoveYesNoConfig,
      openPrompt: true,
      handleConfirm: () => handleRemoveDocument(id),
    }

    setFileRemoveYesNoConfig(newConfig)
  }

  // Remove a file after the user has confirmed. The user then still needs to save the supplier

  const handleRemoveDocument = async (id) => {
    const newConfig = {
      ...fileRemoveYesNoConfig,
      openPrompt: false,
      handleConfirm: null,
    }

    setFileRemoveYesNoConfig(newConfig)

    const path = `accounts/${values.account_id}/suppliers/${supplierId}/`
    const docToDelete = values.docs.find((doc) => doc.id === id)
    if (docToDelete.name && docToDelete.name !== "") {
      const fileNameToDelete = docToDelete.name

      const storageRef = ref(getStorage(), `${path}${fileNameToDelete}`)

      await deleteObject(storageRef)
    }

    const newDocs = values.docs.filter((doc) => doc.id !== id)

    const newValues = {
      ...values,
      docs: newDocs,
    }

    setValues(newValues)

    const docsToSave = prepareDocsForSave(newDocs)

    await db.collection("suppliers").doc(supplierId).update(
      {
        docs: docsToSave,
      },
      { merge: true }
    )
  }

  const handleAddDocument = () => {
    setMaxDocId(maxDocId + 1)

    const newDoc = {
      type: "",
      id: maxDocId,
      open: false,
      expiry: null,
      name: "",
    }

    const newDocs = [...values.docs, newDoc]
    const newValues = {
      ...values,
      docs: newDocs,
    }

    setValues(newValues)
  }

  const handleDateChange = (selectedDate, key) => {
    const newDocs = values.docs.map((doc) =>
      doc.id === key
        ? {
            ...doc,
            expiry: selectedDate && selectedDate.toDate(),
          }
        : doc
    )

    const newValues = {
      ...values,
      docs: newDocs,
    }

    setValues(newValues)
  }

  const handleLinkToSupplierAccount = () => {
    setShowLinkToSupplierAccount(true)
  }

  const handleDocTypeChange = (event, key) => {
    const newDocs = values.docs.map((doc) =>
      doc.id === key
        ? {
            ...doc,
            type: event.target.value,
          }
        : doc
    )

    const newValues = {
      ...values,
      docs: newDocs,
    }

    setValues(newValues)
  }

  const handleDocNotesChange = (event, key) => {
    const newDocs = values.docs.map((doc) =>
      doc.id === key
        ? {
            ...doc,
            notes: event.target.value,
          }
        : doc
    )

    const newValues = {
      ...values,
      docs: newDocs,
    }
    setValues(newValues)
  }

  const handleSelectDocument = (id, event) => {
    if (supplierId === "") {
      console.error("Cannot save until supplier is saved")
    }

    const files = event.target.files

    if (files && files.length > 0) {
      const fileObj = files[0]

      const fileAlreadyExists = values.docs.find(
        (doc) => doc.name === fileObj.name
      )
      if (fileAlreadyExists) {
        enqueueSnackbar("File already exists. Did not upload file.", {
          variant: "warning",
        })
        return
      }

      getAuth()
        .currentUser.getIdTokenResult()
        .then(async (token) => {
          const path = `accounts/${token.claims.account_id}/suppliers/${supplierId}/`
          const fullPath = `${path}${fileObj.name}`
          const storageRef = ref(getStorage(), fullPath)

          await uploadBytes(storageRef, fileObj)

          // Update docs to include newly selected file name
          const newDocs = values.docs.map((doc) => {
            if (doc.id !== id) {
              return doc
            }
            return {
              ...doc,
              name: fileObj.name,
            }
          })

          const newValues = {
            ...values,
            docs: newDocs,
          }

          setValues(newValues)

          const docsToSave = prepareDocsForSave(newDocs)

          db.collection("suppliers")
            .doc(supplierId)
            .update({ docs: docsToSave }, { merge: true })
            .then(() => {
              enqueueSnackbar("File saved", { variant: "success" })
            })
        })
        .catch((err) => console.error("Error saving file", err))
    } else {
      console.log("no files to save")
    }
  }

  const prepareDocsForSave = (docs) => {
    const newDocs = docs.map((doc) => {
      const newDoc = {
        ...doc,
      }

      if (doc.hasOwnProperty("expiry") && doc.expiry !== null) {
        newDoc.expiry = parseDate(doc.expiry)
      }

      // These are only needed to drive the UI. We don't need to save them.
      delete newDoc.open
      delete newDoc.id

      if (newDoc.expiry === null) {
        delete newDoc.expiry
      }

      return newDoc
    })

    return newDocs
  }

  const handleDownloadWorkOrders = async () => {
    const { workOrders, jobs, scheduled, centres } =
      await dataServices.getSupplierWorkOrders(
        accountId,
        supplierId,
        isCentreAccount
      )

    if (workOrders.length === 0) {
      enqueueSnackbar("No open work orders", { variant: "info" })
      return
    }

    const wb = XLSX.utils.book_new()

    wb.Props = {
      Title: `${values.name} Work Orders`,
      Subject: "Work Orders",
      Author: "Jobs For Joe",
      CreatedDate: new Date(),
    }

    const SHEET_NAME = "Work Orders"

    wb.SheetNames.push(SHEET_NAME)

    const headings = [
      [
        "Id",
        "Work Order No",
        "Title",
        "Start (yyyy-mm-dd)",
        "Status",
        "Schedule",
        "Job",
        "Centre",
        "Description",
        "Location",
        "Category",
        "Job Status",
        "Supply Parts",
        "Type",
      ],
    ]

    const getCentreName = (job) =>
      job.centre_id
        ? centres.find((centre) => centre.id === job.centre_id).name
        : ""

    const ws_data = jobs.map((job) => {
      const wo = workOrders.find(
        (workOrder) => workOrder.id === job.work_order_id
      )

      return [
        wo.id,
        wo.work_order_no,
        wo.label,
        moment(dateServices.tsToDate(wo.start_date)).toDate(),
        WorkOrder.statusLabels[wo.status],
        scheduleServices.getScheduleDescription(wo.schedule),
        job.label,
        getCentreName(job),
        job.description,
        job.location,
        job.category,
        job.status,
        job.supply_parts ? "Y" : "N",
        "Created",
      ]
    })

    const ws_scheduled = scheduled.map((slot) => {
      const wo = workOrders.find(
        (workOrder) => workOrder.id === slot.work_order_id
      )

      if (wo) {
        const workOrderJobs = jobs.filter((job) => job.work_order_id === wo.id)

        return workOrderJobs.map((job) => {
          return [
            wo.id,
            wo.work_order_no,
            wo.label,
            moment(dateServices.tsToDate(wo.start_date)).toDate(),
            WorkOrder.statusLabels[wo.status],
            scheduleServices.getScheduleDescription(wo.schedule),
            job.label,
            getCentreName(job),
            job.description,
            job.location,
            job.category,
            job.status,
            job.supply_parts ? "Y" : "N",
            "Future",
          ]
        })
      } else {
        return []
      }
    })

    const data = [].concat(headings, ws_data, _.flatten(ws_scheduled))
    var ws = XLSX.utils.aoa_to_sheet(data, { cellDates: true })

    wb.Sheets["Work Orders"] = ws

    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" })

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `Work Orders.xlsx`
    )
  }

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length) //convert s to arrayBuffer
    var view = new Uint8Array(buf) //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff //convert to octet
    return buf
  }

  // const handleShowWorkOrderSummary = () => {
  //   history.push(`/SupplierWorkOrders/${supplierId}`)
  // }

  // const handleCreateDummyMergeData = async () => {
  //     const centreRefs = await db
  //         .collection("centres")
  //         .where("account_id", "==", accountId)
  //         .limit(1)
  //         .get()

  //     const centreId = centreRefs.docs[0].id

  //     const workOrderId = await dataServices.getNextWorkOrderNo(accountId)

  //     const workOrderData = {
  //         account_id: accountId,
  //         supplier_id: supplierId,
  //         centres: [centreId],
  //         created: dataServices.serverTimestamp(),
  //         modified: dataServices.serverTimestamp(),
  //         notes: "",
  //         label: "Test work order for merging",
  //         schedule: {},
  //         start_date: dataServices.serverTimestamp(),
  //         status: "open",
  //         work_order_no: workOrderId,
  //         cost_estimate: [],
  //         cost_actuals: [],
  //         parts: [],
  //     }

  //     const jobData = {
  //         account_id: accountId,
  //         supplier_id: supplierId,
  //         category: "Maintenance",
  //         centre_id: centreId,
  //         created: dataServices.serverTimestamp(),
  //         modified: dataServices.serverTimestamp(),
  //         label: "Test job for merging",
  //         location: "Kitchen",
  //         status: "open",
  //         supply_parts: false,
  //         priority: 2,
  //     }

  //     console.log("creating dummy merge data", { workOrderData, jobData })

  //     db.collection("work_orders")
  //         .add(workOrderData)
  //         .then((woRef) => {
  //             jobData.work_order_id = woRef.id

  //             db.collection("jobs")
  //                 .add(jobData)
  //                 .then((jobRef) => {
  //                     enqueueSnackbar("Dummy merge data created", { variant: "success" })
  //                 })
  //         })
  // }

  // save supplier

  const handleSubmit = (event, addMultiple) => {
    event.preventDefault()

    if (isErrors({ values, fieldValidation })) {
      setShowFieldErrors(true)
      enqueueSnackbar("Check all information has been provided", {
        variant: "error",
      })
      return
    }

    setShowFieldErrors(false)

    if (isNew) {
      dataServices
        .getCurrentUser()
        .then(async (user) => {
          // Check supplier doesn't exist
          const existing = await db
            .collection(COLLECTION_NAME)
            .where("account_id", "==", user.account_id)
            .where("name", "==", values.name)
            .get()

          if (existing.docs.length > 0) {
            enqueueSnackbar("Supplier already exists", { variant: "warning" })
            return
          }

          const newRecord = {
            ...values,
            docs: prepareDocsForSave(values.docs),
            name_lower: values.name.toLowerCase(),
            account_id: user.account_id,
            created: dataServices.serverTimestamp(),
            modified: dataServices.serverTimestamp(),
          }

          db.collection(COLLECTION_NAME)
            .add(newRecord)
            .then((docRef) => setSupplierId(docRef.id))
            .then(enqueueSnackbar("Created", { variant: "success" }))
            .then(() => {
              if (addMultiple) {
                setValues(initialValues())
                setSupplierId("")
              } else {
                navigateAfterSubmit()
              }
            })
        })
        .catch(function (error) {
          console.error("Error:" + error)
          enqueueSnackbar("Error", { variant: "error " })
        })
    } else {
      const updateRecord = {
        ...values,
        docs: prepareDocsForSave(values.docs),
        name_lower: values.name.toLowerCase(),
        modified: dataServices.serverTimestamp(),
      }

      db.collection(COLLECTION_NAME)
        .doc(supplierId)
        .update(updateRecord)
        .then(enqueueSnackbar("Updated", { variant: "success" }))
        .then(navigateAfterSubmit())
    }
  }

  const handleViewWorkOrders = () => {
    const updatedPagination = {
      ...workOrderPagination,
      page: 0,
      supplier_id: supplierId,
      statuses: workOrderStatus.getAllStatusesExceptClosed(),
    }
    dispatch(setWorkOrderGridPagination(updatedPagination))

    history.push(`/WorkOrders/`)
  }

  const handleViewJobs = () => {
    const updatedPagination = {
      ...jobPagination,
      page: 0,
      supplier_id: supplierId,
    }
    dispatch(setJobGridPagination(updatedPagination))

    history.push(`/JobGrid/`)
  }

  const supplierIconProps = useMemo(() => {
    if (values?.supplier_account_id) {
      return { color: colors.pink[500] }
    }
    return {}
  }, [values])

  return (
    <>
      <YesNo config={yesNoConfig} />

      <YesNo config={fileRemoveYesNoConfig} />

      <ProgressBackdrop open={isShowProgress} label={progressLabel} />

      {showLinkToSupplierAccount && (
        <LinkToSupplierAccount
          open={showLinkToSupplierAccount}
          supplierId={supplierId}
          handleClose={() => setShowLinkToSupplierAccount(false)}
        />
      )}

      {showMerge && (
        <SupplierMergeDialog
          open={showMerge}
          onClose={() => setShowMerge(false)}
          supplier={values}
          supplierId={supplierId}
          handleMerge={handleMerge}
        />
      )}

      {isEditable === false && (
        <Alert severity="info">
          Supplier is read only. Requires Admin role to edit
        </Alert>
      )}

      {isCentreAccount !== undefined &&
        isCentreAccount === true &&
        values.supplier_account_id && (
          <Alert severity="info" sx={{ marginBottom: "20px" }}>
            Supplier is externally managed and can login to view work orders
          </Alert>
        )}

      <Box display={"flex"}>
        <SubHeading title="Supplier Details" />
        <Box sx={{ ml: "auto" }}>
          <Controls.CreatedLabel
            value={values.created}
            history={values.history}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        <Stack direction="column" gap={2}>
          <Controls.TextInput
            name="name"
            label="Business Name"
            value={values.name}
            disabled={!isEditable}
            icon={
              <icons.SupplierIcon fontSize="small" sx={supplierIconProps} />
            }
            onChange={handleInputChange}
            autoFocus
            fullWidth={false}
            sx={{ width: "250px" }}
            showError={showFieldErrors}
            errorFuncs={fieldValidation["name"]}
          />

          <Controls.TextInput
            name="reg_no"
            label="Registration No."
            value={values.reg_no}
            disabled={!isEditable}
            onChange={handleInputChange}
            autoFocus
            fullWidth={false}
            sx={{ width: "250px" }}
          />

          <Controls.TextInput
            name="email"
            label="Email"
            value={values.email}
            type="email"
            disabled={!isEditable}
            icon={<EmailIcon fontSize="small" />}
            onChange={handleInputChange}
          />

          <EmailInvalidAlert email={values.email} />

          <Controls.TextInput
            name="phone"
            label="Phone"
            value={values.phone}
            disabled={!isEditable}
            icon={<PhoneIcon fontSize="small" />}
            onChange={handleInputChange}
          />
          {isCentreAccount && (
            <Controls.TextInput
              name="payment_terms"
              label="Payment Terms"
              value={values.payment_terms}
              disabled={!isEditable}
              onChange={handleInputChange}
            />
          )}

          {isCentreAccount && (
            <Box>
              <ActiveSwitch
                isEditable={isEditable}
                values={values}
                setValues={setValues}
              />
            </Box>
          )}
        </Stack>

        <Stack direction="column" gap={2}>
          <Controls.TextInput
            name="addr1"
            label="Addr1"
            value={values.addr1}
            icon={<LocationIcon fontSize="small" />}
            disabled={!isEditable}
            onChange={handleInputChange}
            fullWidth={false}
            sx={{ width: "250px" }}
          />

          <Controls.TextInput
            name="addr2"
            label="Addr2"
            value={values.addr2}
            disabled={!isEditable}
            onChange={handleInputChange}
            fullWidth={false}
          />

          <Controls.TextInput
            name="city"
            label="City"
            value={values.city}
            disabled={!isEditable}
            onChange={handleInputChange}
            fullWidth={false}
          />

          <Controls.TextInput
            name="state"
            label="State"
            value={values.state}
            disabled={!isEditable}
            onChange={handleInputChange}
            fullWidth={false}
          />

          <Controls.TextInput
            name="postcode"
            label="Postcode"
            value={values.postcode}
            disabled={!isEditable}
            onChange={handleInputChange}
            fullWidth={false}
          />
        </Stack>
      </Box>

      {isCentreAccount && (
        <Box sx={{ mt: "20px", width: "100%" }}>
          <Controls.TextInput
            name="notes"
            label="Notes"
            value={values.notes}
            multiline={true}
            disabled={!isEditable}
            onChange={handleInputChange}
            sx={{ width: "100%" }}
          />
        </Box>
      )}

      {isCentreAccount && (
        <Stack gap={2} sx={{ mt: "30px" }}>
          <SubHeading title="Contacts" />

          <Box>
            {supplierId && values.account_id && (
              <Contacts
                accountId={values.account_id}
                parentId={supplierId}
                parentType="supplier"
                isEditable={isEditable}
              />
            )}
          </Box>
        </Stack>
      )}

      {isCentreAccount && (
        <Stack gap={2} sx={{ mt: "30px" }}>
          <SubHeading title="Job Types" />

          <Box>
            <Autocomplete
              multiple
              limitTags={10}
              disabled={!isEditable}
              onChange={(event, newValue) => {
                setValues((curr) => ({ ...curr, job_types: newValue }))
              }}
              options={jobTypeOptions}
              value={values.job_types}
              size={UIConstants.STANDARD_INPUT_SIZE}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Job Type"
                  variant={UIConstants.STANDARD_INPUT_VARIANT}
                  sx={{ width: "330px" }}
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                    key={option}
                    sx={{ backgroundColor: colorServices.JOB_TYPES }}
                  />
                ))
              }
              variant={UIConstants.STANDARD_INPUT_VARIANT}
            />
          </Box>
        </Stack>
      )}

      <Stack gap={2} sx={{ marginTop: "30px" }}>
        <SubHeading title="Coverage Areas" />

        <Box>
          <Autocomplete
            multiple
            limitTags={10}
            disabled={!isEditable}
            size={UIConstants.STANDARD_INPUT_SIZE}
            onChange={(event, newValue) => {
              setValues((curr) => ({ ...curr, coverage: newValue }))
            }}
            getOptionLabel={(option) => `${option.state}, ${option.area}`}
            isOptionEqualToValue={(option, value) =>
              option.state === value.state && option.area === value.area
            }
            options={coverageOptions}
            value={values.coverage}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Coverage"
                variant={UIConstants.STANDARD_INPUT_VARIANT}
                sx={{ width: "330px" }}
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  label={`${option.state}, ${option.area}`}
                  sx={{ backgroundColor: colorServices.COVERAGE }}
                  {...getTagProps({ index })}
                  key={`${option.state}-${option.area}`}
                />
              ))
            }
            variant={UIConstants.STANDARD_INPUT_VARIANT}
          />
        </Box>
      </Stack>

      {isCentreAccount && isEditable && (
        <Stack gap={2} sx={{ marginTop: "30px" }}>
          <SubHeading title="Documents" />
          {isEditable && isCentreAccount && (
            <Box>
              <Controls.Button
                text="Add Document"
                onClick={handleAddDocument}
                size="small"
                variant="outlined"
                disabled={supplierId === ""}
              />
            </Box>
          )}
        </Stack>
      )}

      {isCentreAccount && docTypes && values.docs && (
        <Box sx={styles.supplierFiles}>
          {values.docs.map((doc) => (
            <SupplierFile
              key={doc.id}
              doc={doc}
              handleDateChange={handleDateChange}
              handleDocTypeChange={handleDocTypeChange}
              handleDocNotesChange={handleDocNotesChange}
              handlePromptRemoveDocument={handlePromptRemoveDocument}
              isEditable={isEditable}
              docTypes={docTypes}
              getFileDownloadUrl={getFileDownloadUrl}
              handleSelectDocument={handleSelectDocument}
            />
          ))}
        </Box>
      )}

      <NavButtons>
        {isCentreAccount &&
          isAdmin &&
          values.supplier_account_id === undefined && (
            <Controls.Button
              type="button"
              text="Link to Supplier"
              onClick={handleLinkToSupplierAccount}
              endIcon={<LinkIcon />}
              disabled={!isEditable}
              tooltip="Enable a supplier to see jobs allocated to them in their own J4J account."
            />
          )}

        <Controls.Button
          type="button"
          text="Download Work Orders"
          onClick={handleDownloadWorkOrders}
          endIcon={<ExcelIcon />}
          disabled={!supplierId}
          tooltip="Download Work Orders as Excel file"
        />

        {/* <Controls.Button
          type="button"
          text="Print Work Orders"
          onClick={handleShowWorkOrderSummary}
          endIcon={<icons.PrintIcon />}
          disabled={!supplierId}
          tooltip="Create a browser-printable view of open work orders"
        /> */}

        <Controls.Button
          type="button"
          text="View Work Orders"
          onClick={handleViewWorkOrders}
          endIcon={<icons.WorkOrderIcon />}
          disabled={!supplierId}
          tooltip="Show work orders for this supplier"
        />

        <Controls.Button
          type="button"
          text="View Jobs"
          onClick={handleViewJobs}
          endIcon={<icons.JobIcon />}
          disabled={!supplierId}
          tooltip="Show jobs for this supplier"
        />

        {!isNew && isCentreAccount && isEditable && (
          <Controls.Button
            text="Delete"
            type="button"
            onClick={handlePromptConfirmDelete}
            endIcon={<icons.DeleteIcon />}
          />
        )}

        {!isNew && isCentreAccount && isEditable && (
          <Controls.Button
            text="Merge"
            type="button"
            onClick={() => setShowMerge(true)}
            endIcon={<MergeIcon />}
            tooltip="Merge this supplier with another supplier that has the same name"
          />
        )}

        {/* {isEditable && isCentreAccount && (
          <Controls.Button
            type="button"
            text="Save and Add New"
            onClick={(event) => handleSubmit(event, true)}
          />
        )} */}

        {isEditable && (
          <Controls.Button
            type="button"
            text="Save"
            onClick={(event) => handleSubmit(event, false)}
            endIcon={<icons.SaveIcon />}
          />
        )}
      </NavButtons>
    </>
  )
}

const ActiveSwitch = ({ isEditable, values, setValues }) => {
  return (
    <FormControlLabel
      sx={{ marginLeft: 0 }}
      control={
        <Tooltip title="Deactivate suppliers that you no longer use">
          <span>
            <Switch
              checked={values.active}
              size="small"
              onChange={(e) =>
                setValues((curr) => ({
                  ...curr,
                  active: e.target.checked,
                }))
              }
              disabled={!isEditable}
            />
          </span>
        </Tooltip>
      }
      label={
        <Typography variant="caption" color="text.secondary">
          Active
        </Typography>
      }
      labelPlacement="top"
    />
  )
}

const SupplierFile = (props) => {
  const {
    doc,
    handleDateChange,
    handleDocTypeChange,
    handleDocNotesChange,
    handlePromptRemoveDocument,
    isEditable,
    docTypes,
    getFileDownloadUrl,
    handleSelectDocument,
  } = props

  const [docTypeMenuOpen, setDocTypeMenuOpen] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card>
        <CardHeader
          style={{ paddingBottom: 0 }}
          title={<Typography variant="h6">Document</Typography>}
          subheader={
            <>
              <Link
                onClick={(event) => getFileDownloadUrl(event, doc.name)}
                href="#"
                target="_open_supplier_file"
              >
                {doc.name}
              </Link>
              {!doc.name && <Typography>[No file uploaded]</Typography>}
            </>
          }
        />
        <CardContent sx={styles.fileCard} style={{ paddingBottom: 0 }}>
          {doc.expiry && !dateServices.isFutureDate(doc.expiry) && (
            <Alert sx={styles.docAlert} severity="error">
              Document has expired
            </Alert>
          )}
          {doc.expiry && dateServices.isDateWithin3Months(doc.expiry) && (
            <Alert sx={styles.docAlert} severity="warning">
              Document expires soon
            </Alert>
          )}
          <Stack gap={2}>
            <FormControl fullWidth>
              <DesktopDatePicker
                id={"expiry-date-" + doc.id}
                label="Expiry"
                helperText={
                  doc.expiry && (
                    <ReactTimeAgo date={doc.expiry} locale="en-AU" />
                  )
                }
                inputFormat="DD/MM/YYYY"
                value={doc.expiry}
                disabled={!isEditable}
                onChange={(selectedDate) =>
                  handleDateChange(selectedDate, doc.id)
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                renderInput={(params) => (
                  <TextField
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    {...params}
                  />
                )}
              />
            </FormControl>

            <Controls.Select
              name="type"
              label="Type"
              value={doc.type}
              onChange={(event) => handleDocTypeChange(event, doc.id)}
              options={docTypes.map((docType) => ({
                id: docType,
                title: docType,
              }))}
              disabled={!isEditable}
            />

            <Controls.TextInput
              name="notes"
              label="Notes"
              multiline
              value={doc.notes}
              onChange={(event) => handleDocNotesChange(event, doc.id)}
            />
          </Stack>

          <Box sx={styles.fileButtons}>
            {isEditable && (
              <Tooltip title="Delete file">
                <IconButton
                  aria-label="delete"
                  onClick={() => handlePromptRemoveDocument(doc.id)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}

            {!doc.name && (
              <>
                <input
                  accept="*/*"
                  sx={styles.input}
                  id={"upload-" + doc.id}
                  multiple
                  type="file"
                  onChange={(event) => handleSelectDocument(doc.id, event)}
                  style={{ display: "none" }}
                />
                <label htmlFor={"upload-" + doc.id}>
                  <Tooltip title="Upload file">
                    <IconButton component="span" size="large">
                      <CloudUploadIcon />
                    </IconButton>
                  </Tooltip>
                </label>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </LocalizationProvider>
  )
}

export default withRouter(SupplierEditForm)
