import * as actions from "../actionTypes"

// pageDocs is { first: <doc>, last: <doc> } for that page, and used by prev/next

const initialState = {
  value: {
    page: 0,
    order: "desc",
    orderBy: "created",
    centre_id: "",
    supplier_id: "",
    // Set this based on whether centre or supplier in WorkOrderGrid
    statuses: undefined, //[workOrderStatus.STATUS_OPEN],
    work_order_no: "",
    invoice_no: "",
    allocated: "",
    show_my_work_orders: false,
  },
}

export default function workOrderGridPagination(state = initialState, action) {
  switch (action.type) {
    case actions.SET_WORK_ORDER_GRID_PAGINATION: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
