import { Box } from "@mui/material"
import { spacing } from "../pages/services/styleServices"

const NavButtons = ({ children, paddingTop = true }) => {
  const navButtons = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: spacing(1),
    alignItems: "center",
  }

  const sx = paddingTop ? { ...navButtons, paddingTop: "10px" } : navButtons

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={sx}>{children}</Box>
    </Box>
  )
}

export default NavButtons
