const isNonEmptyString = (value) => {
  return {
    error: value?.trim() === "" || value === null || value === undefined,
    helperText: "Required",
  }
}

const isNonZero = (value) => {
  return {
    error: value === 0,
    helperText: "Required",
  }
}

const isErrors = ({ values, fieldValidation }) => {
  const errors = Object.keys(fieldValidation).reduce((acc, key) => {
    const value = values[key]
    const validators = fieldValidation[key]
    const error = validators.reduce((acc, validator) => {
      // Only treat it as an error if the return value from validator has an error=true property
      const result = validator(value)
      return result.error ? result : acc
    }, false)
    return { ...acc, [key]: error }
  }, {})
  const hasErrors = Object.values(errors).some((error) => error)
  return hasErrors
}

/**
 * Get error props for a field
 *
 * @returns
 */
const getErrorProps = ({ value, showError, errorFuncs }) => {
  if (showError && errorFuncs) {
    // find first error func that returns an error
    // if no errors, return null
    // contains error and helperText props

    for (let i = 0; i < errorFuncs.length; i++) {
      const errorFunc = errorFuncs[i]
      const error = errorFunc(value)
      if (error.error) {
        return error
      }
    }
  }
}

export { isNonEmptyString, isNonZero, isErrors, getErrorProps }
