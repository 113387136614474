import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material"
import Controls from "./controls/Controls"
import { useState } from "react"
import { isEmailValid } from "../pages/services/emailServices"
import ResponsiveDialog from "./ResponsiveDialog"
import EmailInvalidAlert from "./EmailInvalidAlert"

const PreviewEmailDialog = (props) => {
  const { open, onClose, msgProps, handleSendEmail } = props

  const [toAddress, setToAddress] = useState(msgProps.to)

  const [subject, setSubject] = useState(msgProps.subject)

  return (
    <ResponsiveDialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>Preview Email</DialogTitle>
      <DialogContent>
        <Stack sx={{ marginTop: "20px" }} gap={2}>
          <Controls.TextInput
            label="To"
            value={toAddress}
            onChange={(e) => setToAddress(e.target.value)}
          />
          <EmailInvalidAlert email={toAddress} />
          <Controls.TextInput
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            width: "320px",
          }}
        >
          <Box dangerouslySetInnerHTML={{ __html: msgProps.html }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Controls.Button onClick={onClose} text="Cancel" />
        <Controls.Button
          disabled={toAddress === "" || !isEmailValid(toAddress)}
          onClick={() => {
            handleSendEmail({
              html: msgProps.html,
              text: msgProps.text,
              subject: subject,
              to: toAddress,
              replyTo: msgProps.replyTo,
            })
          }}
          text="Send"
        />
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default PreviewEmailDialog
