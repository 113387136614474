import React, { useState, useMemo } from "react"
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  MenuItem,
  Select,
  TextField,
  Stack,
  Autocomplete,
  Chip,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material"
import * as jobServices from "../pages/services/jobServices"
import * as dataServices from "../pages/services/dataServices"
import Controls from "./controls/Controls"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import _ from "lodash"
import * as UIConstants from "./controls/UIConstants"
import * as colorServices from "../pages/services/colorServices"
import DraggablePaper from "../components/DraggablePaper"
import DashboardWidgetGroupBy from "./DashboardWidgetGroupBy"
import DashboardWidgetGroupByFilter from "./DashboardWidgetGroupByFilter"

const DashboardWidgetConfigDialog = ({
  open,
  onClose,
  widgetConfig,
  centres,
  jobTypes,
  locations,
  priorities,
  setWidgetConfig,
}) => {
  const [jobStatus, setJobStatus] = useState(widgetConfig.config.job_status)

  const [jobStatuses, setJobStatuses] = useState(
    widgetConfig.config.job_statuses
  )

  const [jobAllocation, setJobAllocation] = useState(
    widgetConfig.config.job_allocation
  )

  const [title, setTitle] = useState(widgetConfig.title)

  const [closedDateType, setClosedDateType] = useState(
    widgetConfig.config.closed_date_type
  )

  const [closedFilterDate, setClosedFilterDate] = useState(
    widgetConfig.config.closed_filter_date
  )

  const [closedDaysMin, setClosedDaysMin] = useState(
    widgetConfig.config.closed_days_min
  )

  const [closedDaysMax, setClosedDaysMax] = useState(
    widgetConfig.config.closed_days_max
  )

  const [openDateType, setOpenDateType] = useState(
    widgetConfig.config.open_date_type
  )

  const [openFilterDate, setOpenFilterDate] = useState(
    widgetConfig.config.open_filter_date
  )

  const [openDaysMin, setOpenDaysMin] = useState(
    widgetConfig.config.open_days_min
  )

  const [openDaysMax, setOpenDaysMax] = useState(
    widgetConfig.config.open_days_max
  )

  const [priority, setPriority] = useState(widgetConfig.config.priority)

  const [selectedCentres, setSelectedCentres] = useState(
    widgetConfig.config.centre_ids
  )

  const [selectedJobTypes, setSelectedJobTypes] = useState(
    widgetConfig.config.job_types
  )

  const [selectedLocations, setSelectedLocations] = useState(
    widgetConfig.config.locations
  )

  const [openPriorMonth, setOpenPriorMonth] = useState(
    widgetConfig.config.open_prior_month
  )

  const [closedPriorMonth, setClosedPriorMonth] = useState(
    widgetConfig.config.closed_prior_month
  )

  const [groupBy, setGroupBy] = useState(widgetConfig.config.group_by)

  const [groupByFilter, setGroupByFilter] = useState(
    widgetConfig.config.group_by_filter
  )

  // After a group by, take the top n
  const [groupByFilterTop, setGroupByFilterTop] = useState(
    widgetConfig.config.group_by_filter_top
  )

  // After a group by, take the bottom n
  const [groupByFilterBottom, setGroupByFilterBottom] = useState(
    widgetConfig.config.group_by_filter_bottom
  )

  // After a group by, take any group by value up to n
  const [groupByFilterMax, setGroupByFilterMax] = useState(
    widgetConfig.config.group_by_filter_max
  )

  // After a group by, take any group by value down to n
  const [groupByFilterMin, setGroupByFilterMin] = useState(
    widgetConfig.config.group_by_filter_min
  )

  const centreOptions = useMemo(() => {
    const options = centres
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((centre) => ({
        id: centre.id,
        label: centre.short_name || centre.name,
      }))
    return options
  }, [centres])

  return (
    <Dialog open={open} onClose={onClose} PaperComponent={DraggablePaper}>
      <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
        Settings
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" gap={1} sx={{ marginTop: "20px" }}>
          <Controls.TextInput
            label="Title"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
          <Tooltip title="Automatically update the title to reflect this config">
            <FormControlLabel
              control={
                <Switch
                  checked={widgetConfig.config.auto_title}
                  onChange={(event, value) =>
                    setWidgetConfig({
                      ...widgetConfig,
                      config: { ...widgetConfig.config, auto_title: value },
                    })
                  }
                />
              }
              label={<Typography variant="caption">Auto</Typography>}
            />
          </Tooltip>
        </Stack>
        <Box sx={{ minWidth: "300px", marginTop: "20px" }}>
          <Stack gap={2}>
            {selectedCentres && centres && centreOptions && (
              <Autocomplete
                multiple
                limitTags={10}
                onChange={(event, newValue) => {
                  console.log("newValue", { newValue, widgetConfig })
                  setSelectedCentres(newValue.map((option) => option.id))
                }}
                getOptionLabel={(option) => option.label}
                defaultValue={centreOptions.filter((option) =>
                  widgetConfig.config.centre_ids.includes(option.id)
                )}
                options={centreOptions}
                value={widgetConfig.config.centres}
                size={UIConstants.STANDARD_INPUT_SIZE}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Centres"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    sx={{ width: "300px" }}
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    return (
                      <Chip
                        size="small"
                        label={option.label}
                        {...getTagProps({ index })}
                        sx={{ backgroundColor: colorServices.CENTRE }}
                      />
                    )
                  })
                }
                variant={UIConstants.STANDARD_INPUT_VARIANT}
              />
            )}

            <Autocomplete
              multiple
              limitTags={10}
              onChange={(event, newValue) => {
                setSelectedJobTypes(newValue)
              }}
              options={jobTypes}
              defaultValue={widgetConfig.config.job_types}
              size={UIConstants.STANDARD_INPUT_SIZE}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Job Type"
                  variant={UIConstants.STANDARD_INPUT_VARIANT}
                  sx={{ width: "300px" }}
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                    sx={{ backgroundColor: colorServices.JOB_TYPES }}
                  />
                ))
              }
              variant={UIConstants.STANDARD_INPUT_VARIANT}
            />

            <Autocomplete
              multiple
              limitTags={10}
              onChange={(event, newValue) => {
                setSelectedLocations(newValue)
              }}
              options={locations}
              defaultValue={widgetConfig.config.locations}
              size={UIConstants.STANDARD_INPUT_SIZE}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Location"
                  variant={UIConstants.STANDARD_INPUT_VARIANT}
                  sx={{ width: "300px" }}
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                    sx={{ backgroundColor: colorServices.LOCATION }}
                  />
                ))
              }
              variant={UIConstants.STANDARD_INPUT_VARIANT}
            />
          </Stack>

          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <Box>
                <Typography variant="body2" color="primary">
                  Job Statuses
                </Typography>

                <Stack direction="column">
                  {jobServices.jobStatusSelectOptions.map((option) => (
                    <Box
                      key={option.id}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Checkbox
                        name="job_statuses"
                        label={option.title}
                        checked={jobStatuses.includes(option.id)}
                        sx={{
                          padding: 0,
                          margin: 0,
                          color: colorServices.J4J_YELLOW,
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setJobStatuses((curr) => [...curr, option.id])
                          } else {
                            setJobStatuses((curr) =>
                              curr.filter((id) => id !== option.id)
                            )
                          }
                        }}
                      />
                      <Typography sx={{ marginRight: "10px" }}>
                        {option.title}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </Box>

              <Box>
                <Typography variant="body2" color="primary">
                  Job Allocation
                </Typography>

                <Select
                  sx={{ minWidth: "120px" }}
                  labelId="job-allocation-label"
                  id="jobAllocation"
                  value={jobAllocation}
                  variant={UIConstants.STANDARD_INPUT_VARIANT}
                  size={UIConstants.STANDARD_INPUT_SIZE}
                  label="Job Allocation"
                  onChange={(event) => setJobAllocation(event.target.value)}
                >
                  <MenuItem value="">Any</MenuItem>
                  <MenuItem value={"allocated"}>Allocated</MenuItem>
                  <MenuItem value={"unallocated"}>Unallocated</MenuItem>
                </Select>
              </Box>
            </Box>

            <Box sx={{ marginTop: "20px" }}>
              <Typography variant="body2" color="primary">
                Priority
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Select
                  labelId="priority-label"
                  id="priority"
                  value={priority}
                  variant={UIConstants.STANDARD_INPUT_VARIANT}
                  size={UIConstants.STANDARD_INPUT_SIZE}
                  label="Date"
                  onChange={(event) => setPriority(event.target.value)}
                >
                  <MenuItem value={0}>None</MenuItem>
                  {priorities &&
                    priorities.map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.title} - {p.description}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginTop: "20px" }}>
            <Typography variant="body2" color="primary">
              Job Opened
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Select
                sx={{ width: "120px" }}
                labelId="open-date-type-label"
                id="openDateType"
                value={openDateType}
                variant={UIConstants.STANDARD_INPUT_VARIANT}
                size={UIConstants.STANDARD_INPUT_SIZE}
                label="Date"
                onChange={(event) => {
                  setOpenDateType(event.target.value)
                  if (event.target.value === "") {
                    setOpenFilterDate(null)
                    setOpenDaysMin(0)
                    setOpenDaysMax(0)
                  }
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="open-before-date">Before</MenuItem>
                <MenuItem value="open-after-date">After</MenuItem>
                <MenuItem value="open-days-min">Up To</MenuItem>
                <MenuItem value="open-days-max">More Than </MenuItem>
                <MenuItem value="open-this-month">This Month</MenuItem>
                <MenuItem value="open-last-month">Last Month</MenuItem>
                <MenuItem value="open-prior-month">Prior Month</MenuItem>
              </Select>
              {openDateType &&
                ["open-before-date", "open-after-date"].includes(
                  openDateType
                ) && (
                  <Box sx={{ maxWidth: "120px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        id="filter-date"
                        inputFormat="DD/MM/YYYY"
                        value={openFilterDate}
                        onChange={(selectedDate) => {
                          const dateAsDDMMYYYY =
                            selectedDate.format("DD/MMM/YYYY")
                          setOpenFilterDate(dateAsDDMMYYYY)
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Filter Date (d/M/Y)"
                            variant={UIConstants.STANDARD_INPUT_VARIANT}
                            size={UIConstants.STANDARD_INPUT_SIZE}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                )}
              {openDateType === "open-days-min" && (
                <Box sx={{ width: "90px" }}>
                  <TextField
                    id="open-days-min"
                    label="Days ago"
                    type="number"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    value={openDaysMin}
                    onChange={(e) => setOpenDaysMin(e.target.value)}
                  />
                </Box>
              )}
              {openDateType === "open-days-max" && (
                <Box sx={{ width: "90px" }}>
                  <TextField
                    id="open-days-max"
                    label="Days ago"
                    type="number"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    value={openDaysMax}
                    onChange={(e) => setOpenDaysMax(e.target.value)}
                  />
                </Box>
              )}
              {openDateType === "open-prior-month" && (
                <Box sx={{ width: "90px" }}>
                  <TextField
                    id="open-prior-month"
                    label="0=this month, 1=last month, ..."
                    type="number"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    value={openPriorMonth}
                    onChange={(e) => setOpenPriorMonth(e.target.value)}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ marginTop: "20px" }}>
            <Typography variant="body2" color="primary">
              Job Closed
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Select
                sx={{ width: "120px" }}
                labelId="closed-date-type-label"
                id="closedDateType"
                value={closedDateType}
                variant={UIConstants.STANDARD_INPUT_VARIANT}
                size={UIConstants.STANDARD_INPUT_SIZE}
                label="Date"
                onChange={(event) => {
                  setClosedDateType(event.target.value)
                  if (event.target.value === "") {
                    setClosedFilterDate(null)
                    setClosedDaysMin(0)
                    setClosedDaysMax(0)
                  }
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="closed-before-date">Before</MenuItem>
                <MenuItem value="closed-after-date">After</MenuItem>
                <MenuItem value="closed-days-min">Up To</MenuItem>
                <MenuItem value="closed-days-max">More Than </MenuItem>
                <MenuItem value="closed-this-month">This Month</MenuItem>
                <MenuItem value="closed-last-month">Last Month</MenuItem>
                <MenuItem value="closed-prior-month">Prior Month</MenuItem>
              </Select>
              {closedDateType &&
                ["closed-before-date", "closed-after-date"].includes(
                  closedDateType
                ) && (
                  <Box sx={{ maxWidth: "120px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        id="closed-filter-date"
                        inputFormat="DD/MM/YYYY"
                        value={closedFilterDate}
                        onChange={(selectedDate) => {
                          const dateAsDDMMYYYY =
                            selectedDate.format("DD/MMM/YYYY")

                          setClosedFilterDate(dateAsDDMMYYYY)
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Filter Date (d/M/Y)"
                            variant={UIConstants.STANDARD_INPUT_VARIANT}
                            size={UIConstants.STANDARD_INPUT_SIZE}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                )}
              {closedDateType === "closed-days-min" && (
                <Box sx={{ width: "90px" }}>
                  <TextField
                    id="closed-days-min"
                    label="Days ago"
                    type="number"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    value={closedDaysMin}
                    onChange={(e) => setClosedDaysMin(e.target.value)}
                  />
                </Box>
              )}
              {closedDateType === "closed-days-max" && (
                <Box sx={{ width: "90px" }}>
                  <TextField
                    id="closed-days-max"
                    label="Days ago"
                    type="number"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    value={closedDaysMax}
                    onChange={(e) => setClosedDaysMax(e.target.value)}
                  />
                </Box>
              )}
              {closedDateType === "closed-prior-month" && (
                <Box sx={{ width: "90px" }}>
                  <TextField
                    id="closed-prior-month"
                    label="0=this month, 1=last month, ..."
                    type="number"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    value={closedPriorMonth}
                    onChange={(e) => setClosedPriorMonth(e.target.value)}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ marginTop: "20px" }}>
            <Typography variant="body2" color="primary">
              Group By
            </Typography>

            <DashboardWidgetGroupBy groupBy={groupBy} setGroupBy={setGroupBy} />
          </Box>

          <Box sx={{ marginTop: "20px" }}>
            <Typography variant="body2" color="primary">
              Group By Filter
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <DashboardWidgetGroupByFilter
                groupByFilter={groupByFilter}
                setGroupByFilter={setGroupByFilter}
              />

              {groupByFilter === "range" && (
                <Box sx={{ width: "90px" }}>
                  <TextField
                    id="group-by-filter-min"
                    label="Min"
                    type="number"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    value={groupByFilterMin}
                    onChange={(e) => setGroupByFilterMin(e.target.value)}
                  />
                </Box>
              )}
              {groupByFilter === "range" && (
                <Box sx={{ width: "90px" }}>
                  <TextField
                    id="group-by-filter-max"
                    label="Max"
                    type="number"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    value={groupByFilterMax}
                    onChange={(e) => setGroupByFilterMax(e.target.value)}
                  />
                </Box>
              )}
              {groupByFilter === "top" && (
                <Box sx={{ width: "90px" }}>
                  <TextField
                    id="group-by-filter-top"
                    label="Top"
                    type="number"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    value={groupByFilterTop}
                    onChange={(e) => setGroupByFilterTop(e.target.value)}
                  />
                </Box>
              )}
              {groupByFilter === "bottom" && (
                <Box sx={{ width: "90px" }}>
                  <TextField
                    id="group-by-filter-bottom"
                    label="Bottom"
                    type="number"
                    variant={UIConstants.STANDARD_INPUT_VARIANT}
                    size={UIConstants.STANDARD_INPUT_SIZE}
                    value={groupByFilterBottom}
                    onChange={(e) => setGroupByFilterBottom(e.target.value)}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Controls.Button
          type="button"
          text="Cancel"
          variant="outlined"
          onClick={onClose}
        />
        <Controls.Button
          type="button"
          text="Save"
          onClick={(e) => {
            const newWidgetConfig = {
              ...widgetConfig,
              title: title,
              modified: dataServices.localTimestamp(),
              config: {
                ...widgetConfig.config,
                job_types: selectedJobTypes,
                locations: selectedLocations,
                centre_ids: selectedCentres,
                job_status: jobStatus,
                job_statuses: jobStatuses,
                job_allocation: jobAllocation,
                open_date_type: openDateType,
                open_filter_date: openFilterDate,
                open_days_min: openDaysMin,
                open_days_max: openDaysMax,
                open_prior_month: openPriorMonth,
                closed_date_type: closedDateType,
                closed_filter_date: closedFilterDate,
                closed_days_min: closedDaysMin,
                closed_days_max: closedDaysMax,
                closed_prior_month: closedPriorMonth,
                priority: priority,
                group_by: groupBy,
                group_by_filter: groupByFilter,
                group_by_filter_top: groupByFilterTop,
                group_by_filter_bottom: groupByFilterBottom,
                group_by_filter_max: groupByFilterMax,
                group_by_filter_min: groupByFilterMin,
              },
              result: [],
            }
            setWidgetConfig(newWidgetConfig)
            onClose()
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default DashboardWidgetConfigDialog
