import * as actions from "../actionTypes"

// pageDocs is { first: <doc>, last: <doc> } for that page, and used by prev/next
const initialState = {
  value: {
    page: 0,
    order: "desc",
    orderBy: "created",
    allocated: "",
  },
}

export default function checkListTemplateGridPagination(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CHECK_LIST_TEMPLATE_GRID_PAGINATION: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
