import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import { getAuth } from "firebase/auth"
import { getFunctions } from "firebase/functions"
import { getStorage } from "firebase/storage"
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import { getAnalytics } from "firebase/analytics"

// Docs: https://source.corp.google.com/piper///depot/google3/third_party/devsite/firebase/en/docs/auth/web/email-link-auth.md

const config = {
  apiKey: "AIzaSyCdC0IvyKDH8SObHgi9s1xpdge9RNhGz0M",
  authDomain: "app-02-df417.firebaseapp.com",
  projectId: "app-02-df417",
  storageBucket: "app-02-df417.appspot.com",
  messagingSenderId: "181356989012",
  appId: "1:181356989012:web:1a79c4d23bc694dd793bc1",
  measurementId: "G-7LH04JB12L",
}

const app = firebase.initializeApp(config)

// Functions emulator
// const funcPort = 5001
// firebase.functions().useEmulator("localhost", funcPort);
// console.log(`%cUsing functions emulator, port ${funcPort}`, 'color:yellow')

// Auth emulator
//firebase.auth().useEmulator("localhost", 9099);

//firebase.firestore().useEmulator('localhost', 8080);

const auth = getAuth(app)
const functions = getFunctions(app, "australia-southeast1")
const analytics = getAnalytics(app)
export const db = firebase.firestore()
export const firebaseConfig = config
const storage = getStorage()

// console.log("Clearing persistence")
// db.clearPersistence()
// console.log("Cleared persistence")
// db.enablePersistence()

console.log("%cprocess.env", "color:chartreuse", process.env)

if (process.env.NODE_ENV === "development") {
  // Bypass recaptcha appcheck if in development, so we can call cloud functions
  // This line must be uncommented for the app to work in development.
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
  console.log("%c[Firebase] Using appcheck debug token", "color:yellow")
}

const appCheck = initializeAppCheck(app, {
  // This key doesn't need to be protected - it's the 'Site key' from Google.com recaptcha setup
  provider: new ReCaptchaV3Provider("6LdWRRAeAAAAAC2_5bGPUsGKFhsjXe1mfmCwVDVk"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
})

// We seem to be able to connect to google.com,
// however if the emulator is being used uncomment
// this code and start the emulator with:
// firebase emulators:start

// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }

export const fieldval = firebase.firestore.FieldValue
export default db
export { storage, functions, auth, analytics }
