import ReactDOM from "react-dom/client"

const isEmailValid = (email) => {
  if (!email) {
    return false
  }

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email)?.toLowerCase())
}

const renderEmailComponent = (Component, componentProps = {}, callback) => {
  const offscreenContainer = document.createElement("div")

  // Temporarily override console.error
  const originalConsoleError = console.error
  console.error = (...args) => {
    if (
      args.length > 0 &&
      typeof args[0] === "string" &&
      (args[0].includes("cannot appear as a child of") ||
        args[0].includes("cannot appear as a descendant of"))
    ) {
      // Suppress the specific warning
      return
    } else {
      console.log("%cconsole.errors", "color:lightgreen", ...args)
    }
    originalConsoleError(...args)
  }

  const root = ReactDOM.createRoot(offscreenContainer)
  root.render(<Component {...componentProps} />)

  // Wait for the rendering to be committed
  setTimeout(() => {
    const html = offscreenContainer.innerHTML
    root.unmount()

    // Restore the original console.error function
    console.error = originalConsoleError

    callback(html)
  }, 500)
}

export { isEmailValid, renderEmailComponent }
