import db from "../../Firestore"
import firebase from "firebase/compat/app"
import * as Roles from "./roleServices"
import * as workOrderStatus from "../../components/workOrderStatus"
import * as jobServices from "./jobServices"
import * as fileServices from "./fileServices"
import { tsToDate, parseTimestamp } from "./dateServices"
import * as scheduleServices from "./scheduleServices"
import moment from "moment"
import _ from "lodash"
import {
  getStorage,
  ref,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage"
import {
  serverTimestamp,
  Timestamp,
  documentId,
  collection,
  limit,
  where,
  query,
  getDocs,
} from "firebase/firestore"
import { getAuth } from "firebase/auth"

const FROM_CACHE = { source: "cache" }

// call this when you are sure the user exists
const getUserByUid = async (uid) => {
  let userDoc = await db.collection("users").doc(uid).get()
  if (userDoc.exists) {
    return userDoc.data()
  }
  return null
}

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

const guid = () => {
  return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4()
}

const getInviteForUser = (email) => {
  return new Promise((resolve, reject) => {
    const query = db.collection("invites").where("email", "==", email).limit(1)

    query.get().then((querySnapshot) => {
      let data = querySnapshot.docs.map(function (doc) {
        return {
          id: doc.id,
          ...doc.data(),
        }
      })

      if (data.length === 1) {
        resolve(data[0])
      }
    })
  })
}

const getAccountById = async (accountId) => {
  const accountDoc = await db.collection("accounts").doc(accountId).get()

  if (accountDoc.exists) {
    return accountDoc.data()
  }

  return null
}

const createWorkOrderHistory = ({
  comment,
  dateTimestamp,
  status,
  userEmail,
}) => {
  const history = {
    comment: comment,
    date: dateTimestamp,
    status: status,
    user: userEmail,
  }

  return history
}

const deleteJobFiles = async ({ jobId, accountId }) => {
  const folderToDelete = `accounts/${accountId}/jobs/${jobId}`
  const folderPath = `${folderToDelete}/`
  const storageRef = ref(getStorage(), folderPath)

  await listAll(storageRef).then(async (listResult) => {
    listResult.items.forEach(async (itemRef) => {
      await deleteObject(itemRef)
    })
  })
}

// Make sure jobs are not attached to a work order
const deleteJobs = async ({ accountId, jobIds, accountType }) => {
  console.log("Deleting jobs", jobIds)

  // See if any of the jobs are attached to a work order
  const jobs = await getJobsById({ accountId, jobIds, accountType })

  const workOrderIds = jobs.map((job) => job.work_order_id).filter((id) => id)

  if (workOrderIds.length > 0) {
    return {
      message: "Cannot delete jobs that are attached to a work order",
      severity: "warning",
    }
  }

  // Delete the jobs
  const deletePromises = jobIds.map((jobId) =>
    db.collection("jobs").doc(jobId).delete()
  )

  // Delete images for the jobs
  const deleteImagePromises = jobIds.map((jobId) =>
    deleteJobFiles({ jobId, accountId })
  )

  await Promise.all(deletePromises)

  await Promise.all(deleteImagePromises)

  // No message to display
  return { message: "Jobs deleted", severity: "success" }
}

// resultMessage is optional, and shown to the user if any message is provided
const changeJobStatus = async (
  jobId,
  accountId,
  workOrderId,
  newJobStatus,
  updateWorkOrderStatus = true
) => {
  const token = await getAuth().currentUser.getIdTokenResult()

  const jobMergeValues = {
    status: newJobStatus,
    modified: serverTimestamp(),
    closed: newJobStatus === "closed" ? serverTimestamp() : null,
    history: firebase.firestore.FieldValue.arrayUnion({
      status: newJobStatus,
      date: localTimestamp(),
      user: token.claims.email,
    }),
  }

  const isSupplierViewing =
    token.claims.account_type === Roles.ACCOUNT_TYPE_SUPPLIER

  if (isSupplierViewing) {
    // Add token.claims.account_id into jobMergeValues.supplier_access_account_ids if it doesn't exist
    // This is to ensure that the supplier can see the job in their list of jobs
    jobMergeValues.supplier_access_account_ids =
      firebase.firestore.FieldValue.arrayUnion(token.claims.account_id)
  } else {
    jobMergeValues.account_id = token.claims.account_id
  }

  await db.collection("jobs").doc(jobId).update(jobMergeValues, { merge: true })

  // If we're completing or closing a job, then check if all other jobs in the
  // work order are also completed or closed. If so, we should complete the
  // work order to which this job belongs.

  const canCompleteWorkOrder =
    updateWorkOrderStatus &&
    (newJobStatus === jobServices.JOB_STATUS_CLOSED ||
      newJobStatus === jobServices.JOB_STATUS_COMPLETED)

  if (canCompleteWorkOrder) {
    let isOtherOpenJobsInWorkOrder = false

    // We need to run 2 different queries based on whether the centre or supplier
    // is checking if there are any other open jobs in the work order.
    // This is due to firebase security rules.
    if (isSupplierViewing) {
      const otherOpenJobsQuery = query(
        collection(db, "jobs"),
        where("work_order_id", "==", workOrderId),
        where(
          "supplier_access_account_ids",
          "array-contains",
          token.claims.account_id
        ),
        where(documentId(), "!=", jobId),
        where("status", "==", jobServices.JOB_STATUS_OPEN),
        limit(1)
      )

      const otherOpenJobsSnapshot = await getDocs(otherOpenJobsQuery)

      isOtherOpenJobsInWorkOrder = otherOpenJobsSnapshot.docs.length > 0
    } else {
      const otherOpenJobsQuery = query(
        collection(db, "jobs"),
        where("work_order_id", "==", workOrderId),
        where("account_id", "==", accountId),
        where(documentId(), "!=", jobId),
        where("status", "==", jobServices.JOB_STATUS_OPEN),
        limit(1)
      )

      const otherOpenJobsSnapshot = await getDocs(otherOpenJobsQuery)

      isOtherOpenJobsInWorkOrder = otherOpenJobsSnapshot.docs.length > 0
    }

    if (!isOtherOpenJobsInWorkOrder) {
      const completeWorkOrderMergeData = {
        status: workOrderStatus.STATUS_COMPLETED,
        modified: serverTimestamp(),
        history: firebase.firestore.FieldValue.arrayUnion({
          status: newJobStatus,
          date: localTimestamp(),
          user: token.claims.email,
        }),
      }

      await db
        .collection("work_orders")
        .doc(workOrderId)
        .update(completeWorkOrderMergeData, { merge: true })

      return "All jobs closed. Work order status changed to 'Completed'"
    } else {
      return `Job ${newJobStatus}`
    }
  } else {
    return ""
  }
}

const createInvite = async (invite, accountId) => {
  const accountDoc = await db.collection("accounts").doc(accountId).get()

  const inviteRec = {
    ...invite,
    created: serverTimestamp(),
    account_id: accountId,
    account_name: accountDoc.data().name,
  }

  await db
    .collection("invites")
    .add(inviteRec)
    .then((result) => console.log("added invite"))
    .catch((err) => console.error("error adding invite"))
}

const deleteInvite = async (inviteId) => {
  await db.collection("invites").doc(inviteId).delete()
}

const getUser = async (emailAddr, accountId) => {
  if (emailAddr) {
    const query = db
      .collection("users")
      .where("email", "==", emailAddr.toLowerCase())
      .where("account_id", "==", accountId)
      .limit(1)

    const snapshot = await query.get()

    if (snapshot.docs.length === 1) {
      const doc = snapshot.docs[0]
      return {
        ...doc.data(),
        id: doc.id,
      }
    }
  }
  return undefined
}

const findInviteByEmail = async (email, accountId) => {
  const query = db
    .collection("invites")
    .where("account_id", "==", accountId)
    .where("email", "==", email)
    .limit(1)

  const invites = await find(query)

  return invites
}

const getCentre = async (centreId) => {
  const centreDoc = await db.collection("centres").doc(centreId).get()
  return { id: centreDoc.id, ...centreDoc.data() }
}

const getCentreById = async (centreId, cacheFirst) => {
  let query = db.collection("centres").doc(centreId)

  let centreDoc

  if (cacheFirst) {
    centreDoc = await query.get(FROM_CACHE)
  }

  if (!cacheFirst || !centreDoc.exists) {
    centreDoc = await query.get()
  }

  return {
    id: centreDoc.id,
    ...centreDoc.data(),
  }
}

const queryConstraintsToString = (queryConstraints) => {
  return queryConstraints
    .map((c) => {
      const isString = c._value && typeof c._value === "string"
      return `${c._field.segments[0]} ${c._op} ${
        isString ? `'${c._value}'` : c._value
      }`
    })
    .join(" and ")
}

const getCurrentUser = async () => {
  const uid = getAuth().currentUser.uid

  const userDoc = await db.collection("users").doc(uid).get()
  return userDoc.data()
}

// centreIds is the optional array of centre ids to limit the query by

const getCentresByAccountId = async (
  accountId,
  cacheFirst = false,
  centreIds = []
) => {
  const logId = "[getCentresByAccountId]"

  if (accountId === undefined) {
    return []
  }

  if (centreIds.length > 10) {
    console.error(
      `${logId} Expecting centreIds param to be <= 10 elements, as per firestore query constraint`
    )
  }

  let query = db.collection("centres").where("account_id", "==", accountId)
  if (centreIds.length > 0) {
    query = query.where(
      firebase.firestore.FieldPath.documentId(),
      "in",
      centreIds
    )
  }

  let centreDocs

  if (cacheFirst) {
    centreDocs = await query.get(FROM_CACHE)
  }

  // It can occur that we specify some centreIds to load, but they're not all in the cache
  // so if we 1) specify centreIds, but 2) don't get the right count, we need to
  // reload from firestore, not the cache.
  const isLoadedExpectedCount =
    (centreDocs && centreDocs.size === centreIds.length) ||
    centreIds.length === 0

  if (!cacheFirst || centreDocs.size === 0 || !isLoadedExpectedCount) {
    centreDocs = await query.get()
  }

  const centres = centreDocs.docs.map((centreDoc) => {
    return {
      id: centreDoc.id,
      ...centreDoc.data(),
    }
  })

  return centres
}

const getSuppliers = async (accountId) => {
  const supplierSnapshot = await db
    .collection("suppliers")
    .where("account_id", "==", accountId)
    .get()

  const suppliers = supplierSnapshot.docs.map((supplierDoc) => {
    return {
      id: supplierDoc.id,
      ...supplierDoc.data(),
    }
  })

  return suppliers
}

const getImageNamesAndUrls = async (job) => {
  const folder = fileServices.getJobFilePath(job.account_id, job.id)
  const baseFileNames = await jobServices.getJobFileNames(job, job.id)

  const fileRefs = baseFileNames.map(async (fileName) => {
    const path = `${folder}/${fileName}`
    const storageRef = ref(getStorage(), path)
    try {
      return { name: fileName, url: await getDownloadURL(storageRef) }
    } catch (error) {
      // This shouldn't happen. If there is some error where the
      // job stores a file name but the actual file is missing from GCP storage
      // then this occurs.
      console.error(`getImageNamesAndUrls: ${error}`)
      return { name: fileName, url: null }
    }
  })

  const result = await Promise.all(fileRefs)

  const filteredResult = result.filter((item) => item.url)

  return filteredResult
}

const getSupportingWorkOrderInfo = async ({
  workOrders,
  userAccountId,
  isCentreAccount,
}) => {
  const workOrdersGroupedByAccountId = _.groupBy(workOrders, "account_id")

  const accountIds = Object.keys(workOrdersGroupedByAccountId)

  const getJobsPromises = accountIds.map((workOrderAccountId) => {
    if (isCentreAccount) {
      const woIds = workOrdersGroupedByAccountId[workOrderAccountId].map(
        (wo) => wo.id
      )
      return getJobsByWorkOrderIds(workOrderAccountId, woIds)
    } else {
      const woIds = workOrders.map((wo) => wo.id)

      return db
        .collection("jobs")
        .where("supplier_access_account_ids", "array-contains", userAccountId)
        .where("work_order_id", "in", woIds)
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        })
    }
  })

  const jobs = _.flatten(await Promise.all(getJobsPromises))

  let scheduled = []
  if (isCentreAccount) {
    const getSlotsPromises = accountIds.map((accountId) => {
      const woIds = workOrdersGroupedByAccountId[accountId].map((wo) => wo.id)

      const slotsQuery = db
        .collection("work_orders_calendar")
        .where("work_order_id", "in", woIds)
        .where("account_id", "==", accountId)

      return loadData("work orders calendar", slotsQuery)
    })

    scheduled = await Promise.all(getSlotsPromises)
  }

  // Create promises to retrieve data...
  const centreIds = Array.from(
    new Set(jobs.map((job) => job.centre_id))
  ).filter((id) => id)

  const getCentres = getCentresByIdChunks(centreIds)

  const [centres] = await Promise.all([getCentres])

  // Load image URLs
  const getJobImageUrls = jobs.map(async (job) => {
    return { id: job.id, files: await getImageNamesAndUrls(job) }
  })

  const imageUrls = await Promise.all(getJobImageUrls)

  // Merge job URLs into each job
  const jobsWithUrls = jobs.map((job) => {
    const urlEntry = imageUrls.find((entry) => entry.id === job.id)
    return { ...job, files: urlEntry ? urlEntry.files : [] }
  })
  return { jobs: jobsWithUrls, scheduled, centres, workOrders }
}

const getSupplierWorkOrders = async (
  accountId,
  supplierId,
  isCentreAccount
) => {
  let woQuery = db
    .collection("work_orders")
    .where("supplier_id", "==", supplierId)

  if (isCentreAccount) {
    woQuery = woQuery.where("account_id", "==", accountId)
  } else {
    woQuery = woQuery.where(
      "supplier_access_account_ids",
      "array-contains",
      accountId
    )
  }

  if (!isCentreAccount) {
    // Limit what work orders are shown if the user is not a centre user, i.e. is a supplier user
    woQuery = woQuery.where("status", "in", workOrderStatus.supplierStatuses)
  }

  const workOrders = await loadData("work orders/supplieredit", woQuery)

  if (workOrders.length === 0) {
    return { workOrders: [] }
  }

  return await getSupportingWorkOrderInfo({
    workOrders,
    userAccountId: accountId,
    isCentreAccount,
  })
}

const getNextStartDate = async (workOrderId) => {
  const scheduledDates = await getFutureScheduledWorkOrderDates(workOrderId)

  let nextStartDate = new Date() // default value, but expecting to find next scheduled date
  if (scheduledDates.length > 0) {
    if (scheduledDates[0].dates.length > 0) {
      nextStartDate = tsToDate(scheduledDates[0].dates[0])
    }
  }

  return nextStartDate
}

const getBlankWorkOrderData = async (accountId) => {
  const newWorkOrder = {
    label: "",
    account_id: accountId,
    cost_estimate: [],
    cost_actuals: [], // reset cost actuals
    parts: [], // reset parts
    status: workOrderStatus.STATUS_OPEN,
    created: serverTimestamp(),
    modified: serverTimestamp(),
    work_order_no: await getNextWorkOrderNo(accountId),
    notes: "",
    quote_details: "",
    schedule: {},
    history: [],
    supplier_id: "",
    start_date: parseTimestamp(localTimestamp()),
  }

  return newWorkOrder
}

// Create a new work order from the specified one. This happens when the
// specified work order is closed, and also recurring.
// The caller does everything else required to manage the work order being
// closed, e.g. delete any work_orders_calendar info, etc.
const copyForwardClosedWorkOrder = async (workOrderId, accountId) => {
  const workOrder = (
    await getWorkOrdersById({ workOrderIds: [workOrderId], accountId })
  )[0]

  const nextStartDate = await getNextStartDate(workOrderId)

  const newWorkOrder = _.cloneDeep(workOrder)
  delete newWorkOrder.id
  newWorkOrder.cost_actuals = []
  newWorkOrder.parts = []
  newWorkOrder.status = workOrderStatus.STATUS_ALLOCATED
  newWorkOrder.created = serverTimestamp()
  newWorkOrder.modified = serverTimestamp()
  newWorkOrder.work_order_no = await getNextWorkOrderNo(workOrder.account_id)
  newWorkOrder.start_date = nextStartDate
  newWorkOrder.history = []

  const newWorkOrderDocRef = await db
    .collection("work_orders")
    .add(newWorkOrder)

  const newWorkOrderId = newWorkOrderDocRef.id

  // Determine new scheduled dates for new work order

  await updateWorkOrderCalendarInfo(
    newWorkOrderId,
    newWorkOrder.account_id,
    newWorkOrder.schedule,
    newWorkOrder.start_date
  )

  const oldJobsQuery = db
    .collection("jobs")
    .where("work_order_id", "==", workOrderId)
    .where("account_id", "==", workOrder.account_id)

  const oldJobs = await loadData("closing work order", oldJobsQuery)

  const newJobs = oldJobs.map((job) => {
    const newJob = {
      ...job,
      status: jobServices.JOB_STATUS_OPEN,

      // initially there will be no files uploaded, so clear this attribute
      // since the corresponding Firebase storage bucket will also be empty
      docs: [],
      created: serverTimestamp(),
      modified: serverTimestamp(),
      work_order_id: newWorkOrderId,
    }

    // These attribute are added to the record by loadData as a convenience, so we need to remove them
    // to get back the base attribute set
    delete newJob.doc
    delete newJob.id

    return newJob
  })

  newJobs.forEach(async (job) => await db.collection("jobs").add(job))

  // Change old work order to be non-recurring, now that we've copied it forward
  // to the new recurring work order
  //
  // !!!!THIS IS DONE IN THE CALLING FUNCTION, SINCE IT RELATES TO THE WORK ORDER BEING CLOSED
  //
  // const removeRecurring = {
  //     schedule: {},
  //     modified: serverTimestamp(),
  // }

  // await db.collection("work_orders").doc(workOrderId).update(removeRecurring, { merge: true })

  // Delete 'work_orders_calendar' info for old work order, and recreate for new work order

  return newWorkOrderId
}

const updateWorkOrderCalendarInfo = async (
  workOrderId,
  accountId,
  schedule,
  startDate
) => {
  const nextDates = scheduleServices.calculateNextRecurringDates(
    startDate,
    schedule,
    3
  )

  // Group by year/month

  const byMonthYear = nextDates.map((date) => {
    return { date: date, slot: moment(date).format("YYYY-MM") }
  })

  const groupedBySlot = _.groupBy(byMonthYear, "slot")

  // Update work order calendar

  await deleteWorkOrderCalendarInfo(workOrderId, accountId)

  Object.keys(groupedBySlot).map(async (slot) => {
    const dates = groupedBySlot[slot].map((entry) => entry.date)

    const slotRec = {
      work_order_id: workOrderId,
      account_id: accountId,
      slot: slot,
      dates: dates,
      created: serverTimestamp(),
      modified: serverTimestamp(),
    }

    db.collection("work_orders_calendar").add(slotRec)
  })
}

/***
 * Add a new comment to a job
 *
 * A comment passed in can be new in which case it only has limited fields,
 * but if it's being modified it'll have all the fields
 *
 * comment = { comment: "blah" }
 */

const addCommentToJob = async ({
  comment,
  supplierId,
  accountId,
  jobId,
  email,
}) => {
  if (comment.id) {
    db.collection("comments")
      .doc(comment.id)
      .update({ comment: comment.comment }, { merge: true })
  } else {
    let supplier = undefined

    if (supplierId !== "") {
      const supplierDoc = await db.collection("suppliers").doc(supplierId).get()

      supplier = supplierDoc.data()
    }

    const newComment = {
      account_id: accountId,
      parent_id: jobId,
      type: "job",

      created: serverTimestamp(),
      created_by: email,
      ...comment,
      comment: comment.comment,
    }

    if (supplier && supplier.supplier_account_id) {
      newComment.supplier_account_id = supplier.supplier_account_id
    }

    db.collection("comments").add(newComment)

    db.collection("jobs").doc(jobId).update(
      {
        modified: serverTimestamp(),
      },
      { merge: true }
    )
  }
}

const deleteWorkOrderCalendarInfo = async (workOrderId, accountId) => {
  const existingSlotsQuery = db
    .collection("work_orders_calendar")
    .where("work_order_id", "==", workOrderId)
    .where("account_id", "==", accountId)

  const existingRecs = await loadData("calc next dates", existingSlotsQuery)

  existingRecs.forEach(async (rec) => {
    await db.collection("work_orders_calendar").doc(rec.id).delete()
  })
}

const getFutureScheduledWorkOrderDates = async (
  workOrderId,
  limit = undefined
) => {
  let query = db
    .collection("work_orders_calendar")
    .where("work_order_id", "==", workOrderId)
    .orderBy("slot")

  if (limit) {
    query = query.limit(limit)
  }

  const existingRecs = await loadData("calc next dates", query)

  return existingRecs
}

const getNextWorkOrderNo = async (accountId) => {
  let nextWorkOrderNo

  await db
    .collection("counters")
    .where("account_id", "==", accountId)
    .get()
    .then(async (countersSnapshot) => {
      const counterRef = countersSnapshot.docs[0]
      const counter = counterRef.data()

      nextWorkOrderNo = counter.counter_value + 1

      const newCounter = {
        ...counter,
        counter_value: nextWorkOrderNo,
      }

      await db.collection("counters").doc(counterRef.id).set(newCounter)
    })

  return nextWorkOrderNo
}

const getCentresById = async (centreIds) => {
  if (centreIds.length === 0) {
    return []
  }

  //TODO: split centreIds array into multiple arrays of size 10 -- firebase limitation
  // Trim ids list to 10 (max allowed by firestore)
  if (centreIds.length > 10) {
    centreIds = centreIds.slice(0, 10)
  }

  let centres = await getCentresByIdWithSource(centreIds, FROM_CACHE)

  if (centres.length !== centreIds.length) {
    centres = await getCentresByIdWithSource(centreIds, {})
  }

  return centres || []
}

const getCentresByIdWithSource = async (centreIds, source) => {
  let centreDocs = await db
    .collection("centres")
    .where(firebase.firestore.FieldPath.documentId(), "in", centreIds)
    .get(source)

  const centres = centreDocs.docs.map((centreDoc) => {
    return {
      id: centreDoc.id,
      ...centreDoc.data(),
    }
  })

  return centres
}

const getJobsById = async ({ accountId, accountType, jobIds }) => {
  // Trim ids list to 10 (max allowed by firestore)
  if (jobIds.length > 10) {
    jobIds = jobIds.slice(0, 10)
  }

  const jobs = await getJobsByIdWithSource({ accountId, accountType, jobIds })

  return jobs
}

/**
 * @param {*} accountId | Account id of user attempting to retrieve jobs
 * @param {*} accountType | Account type of user attempting to retrieve jobs, e.g. 'supplier', or 'centre'
 * @param {*} jobIds | Array of job ids to retrieve
 * @returns
 */
const getJobsByIdChunks = async ({ accountId, accountType, jobIds }) => {
  const jobIdChunks = splitIdsIntoChunks(jobIds, MAX_FIRESTORE_IN_CLAUSE_SIZE)

  const loadJobPromises = jobIdChunks.map((chunk) =>
    getJobsById({ accountId, accountType, jobIds: chunk })
  )

  const jobChunks = await Promise.all(loadJobPromises)

  // concat arrays
  const jobs = [].concat(...jobChunks)

  return jobs
}

const getJobsByIdWithSource = async ({
  accountId,
  accountType,
  jobIds,
  source,
}) => {
  let query = db
    .collection("jobs")
    .where(firebase.firestore.FieldPath.documentId(), "in", jobIds)

  switch (accountType) {
    case "supplier":
      query = query.where(
        "supplier_access_account_ids",
        "array-contains",
        accountId
      )
      break

    case "centre":
      query = query.where("account_id", "==", accountId)
      break

    default:
      throw new Error(`Unsupported account type: ${accountType}`)
  }

  let jobDocs = await query.get(source)

  const jobs = jobDocs.docs.map((jobDoc) => {
    return {
      id: jobDoc.id,
      ...jobDoc.data(),
    }
  })

  return jobs
}

const getJobsByWorkOrderIdWithSource = async (
  accountId,
  workOrderIds,
  source
) => {
  let jobDocs = await db
    .collection("jobs")
    .where("account_id", "==", accountId)
    .where("work_order_id", "in", workOrderIds)
    .get(source)

  const jobs = jobDocs.docs.map((jobDoc) => {
    return {
      id: jobDoc.id,
      ...jobDoc.data(),
    }
  })

  return jobs
}

const createJobTypesOptions = (jobTypes) => {
  const options = jobTypes.lookup_values.map((lookup_value) => ({
    id: lookup_value,
    label: lookup_value,
  }))
  return options
}

const loadJobTypeOptions = async (accountId) => {
  if (accountId !== undefined && accountId !== "") {
    let query = db
      .collection("lookups")
      .where("account_id", "==", accountId)
      .where("name", "==", "job_types")

    const jobTypes = await loadData(
      "(Load job type lookup values)",
      query,
      false
    )
    if (jobTypes.length === 1) {
      const types = jobTypes[0]
      return createJobTypesOptions(types)
    }
    return []
  }
}

const getSuppliersByIdChunks = async (supplierIds, accountId) => {
  const supplierIdChunks = splitIdsIntoChunks(
    supplierIds,
    MAX_FIRESTORE_IN_CLAUSE_SIZE
  )

  const loadSupplierPromises = supplierIdChunks.map((chunk) =>
    getSuppliersById(chunk, accountId)
  )

  const supplierChunks = await Promise.all(loadSupplierPromises)

  // concat arrays
  const suppliers = [].concat(...supplierChunks)

  return suppliers
}

const getCheckListById = async (checkListId) => {
  const checkListDoc = await db.collection("checklists").doc(checkListId).get()
  return checkListDoc.data()
}

const getSuppliersById = async (supplierIds, accountId) => {
  // Trim ids list to 10 (max allowed by firestore)
  if (supplierIds.length > 10) {
    console.error(
      "WARNING: trimming supplier IDs to 10. max allowed by firestore"
    )
    supplierIds = supplierIds.slice(0, 10)
  }

  try {
    const suppliers = await getSuppliersByIdWithSource(
      supplierIds,
      accountId,
      {}
    )
    return suppliers
  } catch (err) {
    console.error("Error loading suppliers", { supplierIds, accountId, err })
    return []
  }
}

const splitIdsIntoChunks = (ids, chunkSize) => {
  const idsCopy = [...ids] // take a copy, otherwise this function modifies the underlying 'ids' array as a side-effect
  const chunks = []
  while (idsCopy.length) {
    chunks.push(idsCopy.splice(0, chunkSize))
  }
  return chunks
}

const MAX_FIRESTORE_IN_CLAUSE_SIZE = 10

const getJobsByWorkOrderIds = async (accountId, workOrderIds) => {
  const idChunks = splitIdsIntoChunks(
    workOrderIds,
    MAX_FIRESTORE_IN_CLAUSE_SIZE
  )

  const loadJobsPromises = idChunks.map((chunk) =>
    INTERNAL_getJobsByWorkOrderId(accountId, chunk)
  )

  const jobChunks = await Promise.all(loadJobsPromises)

  const jobs = [].concat(...jobChunks)

  return jobs
}

const getUsersById = async (accountId, userIds) => {
  const idChunks = splitIdsIntoChunks(userIds, MAX_FIRESTORE_IN_CLAUSE_SIZE)

  const loadUsersPromises = idChunks.map((chunk) =>
    INTERNAL_getUsersById(accountId, chunk)
  )

  const userChunks = await Promise.all(loadUsersPromises)

  const users = [].concat(...userChunks)

  return users
}

const getUsersByIdChunks = async (accountId, userIds) => {
  const userIdChunks = splitIdsIntoChunks(userIds, MAX_FIRESTORE_IN_CLAUSE_SIZE)

  const loadUserPromises = userIdChunks.map((chunk) =>
    getUsersById(accountId, chunk)
  )

  const userChunks = await Promise.all(loadUserPromises)

  // concat arrays
  const users = [].concat(...userChunks)

  return users
}

const INTERNAL_getUsersById = async (accountId, userIds) => {
  const users = await getUsersByIdWithSource(accountId, userIds, {})

  return users
}

const INTERNAL_getJobsByWorkOrderId = async (accountId, workOrderIds) => {
  // Assume list of ids is <= 10, so Firestore doesn't complain.

  const workOrders = await getJobsByWorkOrderIdWithSource(
    accountId,
    workOrderIds
  )

  return workOrders
}

const getCentresByIdChunks = async (centreIds) => {
  if (centreIds.length === 0) {
    return []
  }

  const centreIdsChunks = splitIdsIntoChunks(
    centreIds,
    MAX_FIRESTORE_IN_CLAUSE_SIZE
  )

  const loadCentrePromises = centreIdsChunks.map((chunk) =>
    getCentresById(chunk)
  )

  const centreChunks = await Promise.all(loadCentrePromises)

  // concat arrays
  const centres = [].concat(...centreChunks)

  return centres
}

const getWorkOrdersByIdChunks = async ({ workOrderIds, accountId }) => {
  const idChunks = splitIdsIntoChunks(
    workOrderIds,
    MAX_FIRESTORE_IN_CLAUSE_SIZE
  )

  const loadWorkOrderPromises = idChunks.map((chunk) =>
    getWorkOrdersById({ workOrderIds: chunk, accountId })
  )

  const workOrderChunks = await Promise.all(loadWorkOrderPromises)

  // concat arrays
  const workOrders = [].concat(...workOrderChunks)

  return workOrders
}

const getWorkOrdersById = async ({ workOrderIds, accountId }) => {
  // Trim ids list to 10 (max allowed by firestore)
  if (workOrderIds.length > 10) {
    console.error(
      "WARNING: trimming supplier IDs to 10. max allowed by firestore"
    )
    workOrderIds = workOrderIds.slice(0, 10)
  }

  const workOrders = await getWorkOrdersByIdWithSource({
    workOrderIds,
    accountId,
    source: {},
  })

  return workOrders
}

const createSearchIndex = (textItems) => {
  const commonWords = [
    "the",
    "for",
    "which",
    "was",
    "too",
    "and",
    "with",
    "from",
    "that",
    "this",
  ]

  console.log("%ctextItems", "color:pink", textItems)

  // Get all words from the subject, additional job details, and comments and create an array of unique lower case words
  // Strip out any common words, e.g. 'the', 'and', 'or', etc.

  const words = Array.from(
    new Set(
      textItems.flatMap((item) =>
        item
          // split by space or dash
          .split(/[\s-]/)
          .map((w) => w.toLowerCase().trim())
          .filter((w) => w)
          .filter((w) => !commonWords.includes(w))
          // Remove any words 2 characters or less
          .filter((w) => w.length > 2)
      )
    )
  )

  // For a word like 'painting', stripe the last character off the word and add that.
  // Repeat until whats left is 3 characters.
  // This is to allow for partial word searches, e.g. 'paint' will match 'painting'
  const partialWords = words.flatMap((word) => {
    const partials = []
    let w = word
    while (w.length > 3) {
      w = w.slice(0, -1)
      partials.push(w)
    }
    return partials
  })

  const allWords = words.concat(partialWords)

  // Make sure allWords is unique

  const uniqueWords = Array.from(new Set(allWords))

  console.log("%callWords", "color:pink", { uniqueWords })
  return uniqueWords
}

const getWorkOrdersByIdWithSource = async ({
  workOrderIds,
  accountId,
  source,
}) => {
  // Load each work order 1 at a time via a different promise

  const promises = workOrderIds.map(async (id) => {
    try {
      const doc = await db.collection("work_orders").doc(id).get(source)
      return { id: doc.id, ...doc.data() }
    } catch (error) {
      console.error("error loading", { id, error, accountId })
      return undefined
    }
  })

  // Filter to remove any undefined
  const workOrders = await Promise.all(promises)

  const filtered = workOrders.filter((wo) => wo !== undefined)

  return filtered
}

const getUsersByIdWithSource = async (accountId, userIds, source) => {
  let userDocs = await db
    .collection("users")
    .where("account_id", "==", accountId)
    .where(firebase.firestore.FieldPath.documentId(), "in", userIds)
    .get()

  const users = userDocs.docs.map((userDoc) => {
    if (userDoc.exists) {
      return {
        id: userDoc.id,
        ...userDoc.data(),
      }
    } else {
      return null
    }
  })

  return users
}

const getSuppliersByIdWithSource = async (supplierIds, accountId, source) => {
  let supplierDocs = await db
    .collection("suppliers")
    .where("account_id", "==", accountId)
    .where(firebase.firestore.FieldPath.documentId(), "in", supplierIds)
    .get(source)

  const suppliers = supplierDocs.docs.map((supplierDoc) => {
    return {
      id: supplierDoc.id,
      ...supplierDoc.data(),
    }
  })

  return suppliers
}

const createUserIfRequired = (uid, email, displayName, phone) => {
  let names = []
  if (displayName !== undefined) {
    names = displayName.split(" ")
  }

  return new Promise((resolve, reject) => {
    db.collection("users")
      .doc(uid)
      .get()
      .then((user) => {
        if (user.exists) {
          reject({
            reason: "user already exists",
            uid: uid,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
          })
        } else {
          const newUser = {
            email: email,
            phone: phone === undefined ? "" : phone,
            first_name: names.length > 0 ? names[0] : "",
            last_name: names.length > 1 ? names[1] : "",
          }

          db.collection("users")
            .doc(uid)
            .set(newUser)
            .then((user) => {
              resolve(newUser)
            })
            .catch((err) => console.log("Unable to create user", err))
        }
      })
  })
}

const getOpenWorkOrdersByAccountIdAndSupplierId = async (
  accountId,
  supplierId,
  cacheFirst
) => {
  if (accountId === undefined) {
    console.error("(1) Expecting accountId to be defined", accountId)
    return []
  }

  let query = db.collection("work_orders").where("account_id", "==", accountId)

  if (supplierId !== null && supplierId !== "" && supplierId !== undefined) {
    query = query.where("supplier_id", "==", supplierId)
  }

  query = query.where("status", "in", [
    workOrderStatus.STATUS_OPEN,
    workOrderStatus.STATUS_CHANGE,
    workOrderStatus.STATUS_INPROGRESS,
  ])

  query = query.orderBy("created", "desc")

  let workOrderDocs

  if (cacheFirst) {
    workOrderDocs = await query.get(FROM_CACHE)
  }

  if (!cacheFirst || workOrderDocs.size === 0) {
    workOrderDocs = await query.get()
  }

  const workOrders = workOrderDocs.docs.map((workOrderDoc) => {
    return {
      id: workOrderDoc.id,
      ...workOrderDoc.data(),
    }
  })

  return workOrders
}

const getSuppliersByAccountId = async (
  accountId,
  cacheFirst,
  activeOnly = true
) => {
  if (accountId === undefined) {
    return []
  }

  let query = db
    .collection("suppliers")
    .where("account_id", "==", accountId)
    .orderBy("name")

  if (activeOnly) {
    query = query.where("active", "==", true)
  }

  let supplierDocs

  if (cacheFirst) {
    supplierDocs = await query.get(FROM_CACHE)
  }

  if (!cacheFirst || supplierDocs.size === 0) {
    supplierDocs = await query.get()
  }

  const suppliers = supplierDocs.docs.map((supplierDoc) => {
    return {
      id: supplierDoc.id,
      ...supplierDoc.data(),
    }
  })

  return suppliers
}

const getUsersByAccountId = async (accountId, cacheFirst) => {
  if (accountId === undefined) {
    return []
  }

  const query = db.collection("users").where("account_id", "==", accountId)

  const userDocs = await query.get()

  const users = userDocs.docs.map((userDoc) => {
    return {
      id: userDoc.id,
      ...userDoc.data(),
    }
  })

  return users.sort((a, b) => a.name.localeCompare(b.name))
}

const dataFix_removeWorkOrderEndDate = (startAtSeconds) => {
  const data = { start_at_seconds: startAtSeconds }

  const removeEndDate = firebase
    .functions()
    .httpsCallable("dataFixRemoveEndDateFromWorkOrders")

  return removeEndDate(data)
}

const find = async (query, from) => {
  return await query.get().then((querySnapshot) => {
    const data = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      }
    })

    return data
  })
}

const getQueryAccountId = async () => {
  let accountId = undefined

  return await getAuth()
    .currentUser.getIdTokenResult()
    .then((token) => {
      switch (token.claims.account_type) {
        case "centre":
          accountId = token.claims.account_id
          break

        case "supplier":
          accountId = token.claims.account_id
          break

        default:
          throw new Error("Unknown account type " + token.claims.account_type)
      }

      return accountId
    })
}

/**
 * Check that all the centre names are valid
 *
 * @param uniqueCentreNames | array of centre names
 * @param accountId | account id of the account that the centres belong to
 * @returns array of centre names that are not valid
 */
const validateCentreNames = async ({ uniqueCentreNames, accountId }) => {
  // Split centre names into batches of 10
  const namesCopy = [...uniqueCentreNames] // take a copy, otherwise this function modifies the underlying 'ids' array as a side-effect
  const nameChunks = []
  while (namesCopy.length) {
    nameChunks.push(namesCopy.splice(0, 10))
  }

  // Now retrieve centres by name and account id per batch. Do it in parallel using promises
  const centrePromises = nameChunks.map(async (nameChunk) => {
    const querySnapshot = await db
      .collection("centres")
      .where("account_id", "==", accountId)
      .where("name", "in", nameChunk)
      .get()

    const centres = querySnapshot.docs.map((doc) => {
      const centre = doc.data()
      centre.id = doc.id
      return centre
    })
    return centres
  })

  // When all the promises have resolved, we'll have an array of arrays of centres, so flatten it into a single array
  const centres = (await Promise.all(centrePromises)).flat()

  // See if any centres unknown, i.e. centre.name not found in uniqueCentreNames
  const unknownCentres = uniqueCentreNames.filter(
    (name) => !centres.find((centre) => centre.name === name)
  )

  return { foundCentres: centres, unknownCentres }
}

const loadData = async (source, query, useCache = false) => {
  let docs

  if (useCache) {
    const cacheQuerySnapshot = await query.get(FROM_CACHE)

    docs = cacheQuerySnapshot.docs
  }

  if (docs === undefined || docs.length === 0) {
    try {
      const serverQuerySnapshot = await query.get()
      docs = serverQuerySnapshot.docs
    } catch (err) {
      console.error("loadData error", { err, source })
    }
  }

  return docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
      doc: doc,
    }
  })
}

const addStartsWithConstraint = ({ value, fieldName }) => {
  const queryConstraints = []

  if (value !== "") {
    const searchTerm = value.toLowerCase()
    const strlength = searchTerm.length
    const strFrontCode = searchTerm.slice(0, strlength - 1)
    const strEndCode = searchTerm.slice(strlength - 1, searchTerm.length)
    // This is an important bit..
    const endCode =
      strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)

    queryConstraints.push(where(fieldName, ">=", searchTerm))
    queryConstraints.push(where(fieldName, "<", endCode))
    //queryMods.push(`${fieldName} >= ${name} [${searchTerm}-${endCode}]`)
  }
  return queryConstraints
}

// for initializing the UI with a value, typically overwritten when we save
const localTimestamp = () => Timestamp.fromDate(new Date())

const localTimestampTruncTime = () => {
  const truncDate = new Date(localTimestamp().toDate().setHours(0, 0, 0))
  return firebase.firestore.Timestamp.fromDate(truncDate)
}

// use to ensure server based timetamps for created and modifed attributes
//const serverTimestamp = () => firebase.firestore.FieldValue.serverTimestamp()

//const timestampFromDate = (date) => firebase.firestore.Timestamp.fromDate(date)
const timestampFromDate = (date) => Timestamp.fromDate(date)

const modifyQuery = (query, searchField, searchValue) => {
  if (searchValue !== "") {
    /*
        let startSearchValue = searchValue
        const lastChar = searchValue.charAt(searchValue.length - 1)
        const nextChar = String.fromCharCode(lastChar.charCodeAt(0) + 1)
        let endSearchValue = searchValue.slice(0,-1) + nextChar         
        query = query.where(searchField, '>=', startSearchValue).where(searchField, '<=', endSearchValue)
        */

    query = query
      .orderBy(searchField)
      .startAt(searchValue)
      .endAt(searchValue + "~")
  }
  return query
}

export {
  FROM_CACHE,
  // Lookups
  loadJobTypeOptions,
  // Accounts
  getAccountById,
  getQueryAccountId, // based on whether a centre or supplier is logged in, determine which account id to use when querying
  // Work Orders
  getOpenWorkOrdersByAccountIdAndSupplierId,
  getSupportingWorkOrderInfo,
  getWorkOrdersById,
  getWorkOrdersByIdChunks,
  getNextWorkOrderNo,
  getSupplierWorkOrders,
  copyForwardClosedWorkOrder,
  updateWorkOrderCalendarInfo,
  deleteWorkOrderCalendarInfo,
  getBlankWorkOrderData,
  createWorkOrderHistory,
  // Checklists
  getCheckListById,
  // Comments
  addCommentToJob,
  // Jobs
  getJobsById,
  getJobsByIdChunks,
  getJobsByWorkOrderIds,
  changeJobStatus,
  deleteJobs,
  deleteJobFiles,
  // Users
  getUser,
  getUserByUid,
  getUsersById,
  getUsersByIdChunks,
  getUsersByAccountId,
  getCurrentUser,
  // Suppliers
  getSuppliers,
  getSuppliersByAccountId,
  getSuppliersById,
  getSuppliersByIdChunks,
  // Centres
  getCentresByAccountId,
  getCentresById,
  getCentresByIdChunks,
  getCentre,
  getCentreById,
  loadData,
  localTimestamp,
  localTimestampTruncTime,
  serverTimestamp,
  timestampFromDate,
  modifyQuery,
  createUserIfRequired,
  validateCentreNames,
  // Invites
  createInvite,
  deleteInvite,
  getInviteForUser,
  findInviteByEmail,
  // Data fixes
  dataFix_removeWorkOrderEndDate,
  guid,
  createSearchIndex,
  queryConstraintsToString,
  addStartsWithConstraint,
}
