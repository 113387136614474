import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
} from "@mui/material"
import { useState } from "react"
import LabelValue from "./LabelValue"
import QuoteTileMenu from "./QuoteTileMenu"
import { format } from "date-fns"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import TruncatedText from "./TruncatedText"
import LinkButton from "./controls/LinkButton"
import { useMemo } from "react"

const QuoteTile = ({
  quote,
  isEditable,
  suppliers,
  centres,
  isSupplierViewing,
  handleEditQuote,
}) => {
  const [anchorEl, setAnchorEl] = useState()

  const menuId = `menu-${quote.id}`

  const handleToggleMenu = (event) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const supplier = useMemo(() => {
    return suppliers.find((s) => s.id === quote.supplier_id)
  }, [quote, suppliers])

  return (
    <>
      <Card sx={{ width: "240px" }}>
        <CardHeader
          title={
            <TruncatedText noWrap sx={{ fontWeight: "bold" }}>
              Work Order {quote.workOrderNo}
            </TruncatedText>
          }
          action={
            <IconButton aria-label="settings" onClick={handleToggleMenu}>
              <MoreVertIcon />
            </IconButton>
          }
        />
        <CardActionArea onClick={() => handleEditQuote(quote)}>
          <CardContent sx={{ padding: "15px" }}>
            <Stack gap={0.5}>
              <LabelValue
                label="Supplier"
                value={
                  <LinkButton
                    sx={{ justifyContent: "flex-end" }}
                    to={`/supplieredit/${supplier?.id}`}
                  >
                    {supplier?.name}
                  </LinkButton>
                }
              />

              {!isSupplierViewing && (
                <LabelValue
                  label="Preferred"
                  value={quote.preferred ? "Yes" : "No"}
                />
              )}
              <LabelValue
                label="Work Order"
                value={
                  <LinkButton
                    sx={{ justifyContent: "flex-end" }}
                    to={`/workorderedit/${quote.work_order_id}`}
                  >
                    {quote.workOrderNo}
                  </LinkButton>
                }
              />

              <LabelValue
                label="Centres"
                value={
                  quote.centre_ids &&
                  centres
                    .filter((c) => quote.centre_ids.includes(c.id))
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((c) => c.name)
                    .join(", ")
                }
              />
              <LabelValue label="Total" value={quote.total} />
              <LabelValue label="Terms" value={quote.terms} />
              <LabelValue
                label="Sent"
                value={
                  quote.sent && format(quote.sent.toDate(), "dd MMM yy hh:mm a")
                }
              />
              <LabelValue
                label="Received"
                value={
                  quote.received &&
                  format(quote.received.toDate(), "dd MMM yy hh:mm a")
                }
              />
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      {anchorEl && (
        <QuoteTileMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          menuId={menuId}
          handleEditQuote={() => {
            handleEditQuote(quote)
            setAnchorEl(null)
          }}
        />
      )}
    </>
  )
}

export default QuoteTile
