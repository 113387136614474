export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE"

export const SET_CENTRE_GRID_PAGINATION = "SET_CENTRE_GRID_PAGINATION"

export const SET_CENTRE_GRID_PAGE_DOCS = "SET_CENTRE_GRID_PAGE_DOCS"

export const SET_ASSET_GRID_PAGINATION = "SET_ASSET_GRID_PAGINATION"

export const SET_ASSET_GRID_PAGE_DOCS = "SET_ASSET_GRID_PAGE_DOCS"

export const SET_CHECK_LIST_TEMPLATE_GRID_PAGINATION =
  "SET_CHECKLIST_TEMPLATE_GRID_PAGINATION"

export const SET_CHECK_LIST_TEMPLATE_GRID_PAGE_DOCS =
  "SET_CHECKLIST_TEMPLATE_GRID_PAGE_DOCS"

export const SET_PRIORITIES = "SET_PRIORITIES"

export const SET_HAS_ACTIONS = "SET_HAS_ACTIONS"

export const SET_USER_GRID_PAGINATION = "SET_USER_GRID_PAGINATION"

export const SET_USER_GRID_PAGE_DOCS = "SET_USER_GRID_PAGE_DOCS"

export const SET_ACTION_GRID_PAGINATION = "SET_ACTION_GRID_PAGINATION"

export const SET_ACTION_GRID_PAGE_DOCS = "SET_ACTION_GRID_PAGE_DOCS"

export const SET_QUOTE_GRID_PAGINATION = "SET_QUOTE_GRID_PAGINATION"

export const SET_QUOTE_GRID_PAGE_DOCS = "SET_QUOTE_GRID_PAGE_DOCS"

export const SET_JOB_GRID_PAGINATION = "SET_JOB_GRID_PAGINATION"

export const SET_JOB_GRID_PAGE_DOCS = "SET_JOB_GRID_PAGE_DOCS"

export const SET_JOB_GRID_SELECTED_ITEMS = "SET_JOB_GRID_SELECTED_ITEMS"

export const SET_JOB_SUMMARY_SEARCH = "SET_JOB_SUMMARY_SEARCH"

export const SET_SUPPLIER_GRID_PAGINATION = "SET_SUPPLIER_GRID_PAGINATION"

export const SET_SUPPLIER_GRID_PAGE_DOCS = "SET_SUPPLIER_GRID_PAGE_DOCS"

export const SET_MENU_OPEN = "SET_MENU_OPEN"

export const SET_CONFIG_MENU_OPEN = "SET_CONFIG_MENU_OPEN"

export const SET_MY_ACCOUNT_MENU_OPEN = "SET_MY_ACCOUNT_MENU_OPEN"

export const SET_CENTRES = "SET_CENTRES"

export const SET_SUPPLIER_SEARCH = "SET_SUPPLIER_SEARCH"

export const SET_SUPPLIER_SHOW_DOCUMENTS = "SET_SUPPLIER_SHOW_DOCUMENTS"

export const SET_JOBS_BY_CENTRE_AND_CATEGORY = "SET_JOBS_BY_CENTRE_AND_CATEGORY"

export const SET_CHECK_LIST_INSTANCE_GRID_PAGINATION =
  "SET_CHECK_LIST_INSTANCE_GRID_PAGINATION"

export const SET_CHECK_LIST_INSTANCE_GRID_PAGE_DOCS =
  "SET_CHECK_LIST_INSTANCE_GRID_PAGE_DOCS"

export const SET_WORK_ORDER_GRID_PAGINATION = "SET_WORK_ORDER_GRID_PAGINATION"

export const SET_WORK_ORDER_GRID_PAGE_DOCS = "SET_WORK_ORDER_GRID_PAGE_DOCS"

export const SET_WORK_ORDER_GRID_SELECTED_ITEMS =
  "SET_WORK_ORDER_GRID_SELECTED_ITEMS"

export const SET_WORK_ORDER_LAST_MODIFIED = "SET_WORK_ORDER_LAST_MODIFIED"

export const SET_CAROUSEL_SCALE = "SET_CAROUSEL_SCALE"

export const SET_CAROUSEL_VISIBLE = "SET_CAROUSEL_VISIBLE"

export const SET_ACCOUNT_GRID_PAGINATION = "SET_ACCOUNT_GRID_PAGINATION"

export const SET_ACCOUNT_GRID_PAGE_DOCS = "SET_ACCOUNT_GRID_PAGE_DOCS"

export const SET_ACCOUNT_GRID_SELECTED_ITEMS = "SET_ACCOUNT_GRID_SELECTED_ITEMS"

export const SET_COMMENTS_LAST_MODIFIED = "SET_COMMENTS_LAST_MODIFIED"

export const SET_WORK_ORDER_CALENDAR_DATE_RANGE =
  "SET_WORK_ORDER_CALENDAR_DATE_RANGE"

export const SET_JOBS = "SET_JOBS"

export const BLANK_SELECT_VALUE = "------"
