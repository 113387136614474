import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import {
  Typography,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  Box,
} from "@mui/material"
import firebase from "firebase/compat/app"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setCentreGridPagination } from "../redux/actions"
import { selectCentreGridPageDocs } from "../redux/selectors"
import { selectCentreGridPagination } from "../redux/selectors"
import { setCentreGridPageDocs } from "../redux/actions"
import Controls from "./controls/Controls"
import _ from "lodash"
import LinkButton from "./controls/LinkButton"
import { spacing } from "../pages/services/styleServices"
import NavButtons from "./NavButtons"
import PageNo from "./PageNo"
import { Desktop, Mobile } from "./WindowSizes"
import CentreTile from "./CentreTile"
import {
  getCountFromServer,
  collection,
  where,
  query,
} from "firebase/firestore"
import FabButton from "./controls/FabButton"
import FilterItems from "./FilterItems"
import * as Roles from "../pages/services/roleServices"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sortable: true,
    direction: ["asc"],
  },
  {
    id: "short_name",
    numeric: false,
    disablePadding: false,
    label: "Short Name",
    sortable: false,
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
    sortable: false,
  },

  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    sortable: false,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    sortable: false,
  },
  {
    id: "contact_person",
    numeric: false,
    disablePadding: false,
    label: "Centre Manager",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created",
    sortable: false,
  },
  {
    id: "modified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    sortable: false,
  },
]

const CentreGrid = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order : headCell.direction[0]
                }
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

CentreGrid.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  navButtons: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: spacing(1),
    marginTop: spacing(2),
  },
  filterGrid: {
    paddingLeft: "15px",
  },
  tableRow: {
    "&:hover": {
      cursor: "hand",
    },
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = useState(true)

  const pag = useSelector(selectCentreGridPagination)

  const dispatch = useDispatch()

  const history = useHistory()

  const [rowsPerPage, setRowsPerPage] = useState(20)

  const [centreCount, setCentreCount] = useState(0)

  const [contacts, setContacts] = useState([])

  const [centres, setCentres] = useState([])

  const pgDocs = useSelector(selectCentreGridPageDocs)

  const COLLECTION_NAME = "centres"

  const [maxModified, setMaxModified] = useState()

  const [accountId, setAccountId] = useState()

  const [accountType, setAccountType] = useState()

  const [centreNameFilter, setCentreNameFilter] = useState("")

  const [centreNameField, setCentreNameField] = useState("")

  const [claims, setClaims] = useState()

  const isAdmin = useMemo(() => {
    return claims && claims.roles?.includes(Roles.ADMIN)
  }, [claims])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setAccountId(token.claims.account_id)
          setAccountType(token.claims.account_type)
          setClaims(token.claims)
        })
      }
    })

    return unsub
  }, [])

  // Listen for changes

  useEffect(() => {
    if (accountId === undefined || accountType === undefined) {
      return
    }

    const whereClauseLog = []

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())

    whereClauseLog.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    switch (accountType) {
      case "centre":
        query = query.where("account_id", "==", accountId)
        whereClauseLog.push("account_id == " + accountId)
        break

      default:
        throw new Error("Unknown account type " + accountType)
    }

    let newMaxModified = null
    const unsub = query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            // trigger refresh
            newMaxModified = change.doc.data().modified
          }
        })
      },
      (error) => console.error("error listening for centre changes", error)
    )

    if (newMaxModified !== null) {
      setMaxModified(newMaxModified)
    }

    return unsub // gets called on unmount
  }, [accountId, accountType])

  useEffect(() => {
    // Get unique 'user_id_contact' values from centres

    const userContactIds = centres.reduce((acc, centre) => {
      if (centre.user_id_contact && !acc.includes(centre.user_id_contact)) {
        acc.push(centre.user_id_contact)
      }
      return acc
    }, [])

    // Get contacts one at a time, taking into account their id might not exist

    const centreManagersPromises = userContactIds.map((id) => {
      return db
        .collection("users")
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            return { id: doc.id, ...doc.data() }
          } else {
            return undefined
          }
        })
        .catch((err) => {
          // User record not found, probably means it was deleted but this centre doc not cleaned up by removing the user
          console.error(err, id)
          return undefined
        })
    })

    Promise.all(centreManagersPromises).then((centreManagers) => {
      setContacts(centreManagers.filter((cm) => cm !== undefined))
    })
  }, [centres])

  useEffect(() => {
    if (accountId === undefined || claims === undefined) {
      return
    }

    let query = db.collection(COLLECTION_NAME)

    let unsub

    switch (claims.account_type) {
      case "centre":
        query = query.where("account_id", "==", claims.account_id)
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    query = query.orderBy("modified", "desc").limit(30)

    unsub = query.onSnapshot(
      (querySnapshot) => {
        let newMaxModified = null
        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            newMaxModified = change.doc.data().modified
          }
        })
        if (newMaxModified !== null) {
          setMaxModified(newMaxModified)
        }
      },
      (error) => console.error("error listening for centre changes", error)
    )

    return unsub
  }, [accountId, claims])

  const updatePageDocs = () => {
    if (centres.length > 0 && direction !== "prev") {
      const newPageDocs = [...pgDocs]

      const newPageDoc = {
        first: centres[0].doc,
        last: centres[centres.length - 1].doc,
      }

      newPageDocs[pag.page] = newPageDoc
      dispatch(setCentreGridPageDocs(newPageDocs))
    }
  }

  useEffect(() => {
    updatePageDocs(centres)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centres])

  // Load centres

  const [reloadState, setReloadState] = useState({})

  useEffect(() => {
    const logId = "[CENTRE GRID > LOAD CENTRES]"

    if (accountId === undefined || maxModified === undefined) {
      return
    }

    const newReloadState = {
      accountId,
      maxModified,
      pag,
      centreNameFilter,
    }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    setReloadState(newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    const queryMods = []

    let query = db.collection(COLLECTION_NAME)

    const queryConstraints = []

    getAuth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        if (token.claims.centre_ids.length === 0) {
          // Get current page of centres

          if (pag.orderBy === "name") {
            query = query.orderBy("name_lower", pag.order)
          } else {
            query = query.orderBy(pag.orderBy, pag.order)
          }
          queryMods.push("order by " + pag.orderBy + " " + pag.order)

          query = query.orderBy(
            firebase.firestore.FieldPath.documentId(),
            pag.order
          )
          queryMods.push("order by doc id " + pag.order)

          if (pag.page > 0 && direction !== "prev") {
            // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
            // But if not found, it just means this is the first time we've clicked Next through the collection
            if (pgDocs[pag.page - 1]) {
              const lastDoc = pgDocs[pag.page - 1].last
              query = query.startAfter(lastDoc)
              queryMods.push(
                "start after last doc on previous page " + lastDoc.id
              )
            }
          } else if (direction === "prev") {
            if (!pgDocs[pag.page]) {
              console.error("Can't find pagedocs for page", pag.page)
            }
            query = query.startAt(pgDocs[pag.page].first)
            queryMods.push(
              "start at 1st doc on page " + pgDocs[pag.page].first.id
            )
          }

          if (centreNameFilter !== "") {
            const searchTerm = centreNameFilter.toLowerCase()
            const strlength = searchTerm.length
            const strFrontCode = searchTerm.slice(0, strlength - 1)
            const strEndCode = searchTerm.slice(
              strlength - 1,
              searchTerm.length
            )
            // This is an important bit..
            const endCode =
              strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)

            query = query
              .where("name_lower", ">=", searchTerm)
              .where("name_lower", "<", endCode)
              .limit(10)

            //query = query.where("name", ">=", centreNameFilter)
            queryMods.push(`name_lower >= ${centreNameFilter}`)
            queryMods.push(`name_lower < ${endCode}`)

            queryConstraints.push(where("name_lower", ">=", searchTerm))
            queryConstraints.push(where("name_lower", "<", endCode))
          }

          switch (token.claims.account_type) {
            case "centre":
              query = query.where("account_id", "==", token.claims.account_id)
              queryMods.push(`account_id == ${token.claims.account_id}`)
              queryConstraints.push(where("account_id", "==", accountId))
              break

            default:
              throw new Error(
                "Unknown account type " + token.claims.account_type
              )
          }

          query = query.limit(rowsPerPage)

          dataServices
            .loadData("(Load centre grid)", query, false)
            .then((centres) => {
              setCentres(centres)
            })
            .then(updatePageDocs())
            .then(setCentreCount(getCentreCount({ queryConstraints })))
            .catch((err) =>
              console.error(`${logId} Error loading centres`, err)
            )
        } else {
          dataServices
            .getCentresByIdChunks(token.claims.centre_ids)
            .then((centres) => {
              const sortedCentres = _.sortBy(centres, "name")
              setCentres(sortedCentres)
            })
            .then(updatePageDocs())
            .catch((err) =>
              console.error(`${logId} Error loading centres`, err)
            )
        }
      })
  }, [accountId, maxModified, pag, centreNameFilter])

  const getCentreCount = ({ queryConstraints }) => {
    const countCentres = query(collection(db, "centres"), ...queryConstraints)
    getCountFromServer(countCentres).then((result) => {
      setCentreCount(result.data().count)
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = pag.orderBy === property && pag.order === "asc"

    const sortCol = headCells.find((cell) => cell.id === property)
    const sortSameCol = pag.orderBy === property

    // If we clicked on the same column to sort it, then find the other sort order for that column (there will only be
    // 1 or 2 array entries, being 'asc' or 'desc' in some order),
    // Otherwise it means we're sorting on a new column so get the 1st sort order from the 'direction' attribute, which
    // could be either 'asc' or 'desc'.
    const sortOrder = sortSameCol
      ? sortCol.direction.find((dir) => dir !== pag.order) || pag.order
      : sortCol.direction[0]

    const updatedPagination = {
      page: 0,
      order: sortOrder,
      orderBy: property,
    }

    dispatch(setCentreGridPagination(updatedPagination))
    dispatch(setCentreGridPageDocs([]))
    setDirection("")
  }

  const handleCentreNameFieldChange = (event) => {
    const centreName = event.target.value
    setCentreNameField(centreName)
  }

  const handleNewCentre = (event) => {
    event.preventDefault()
    history.push("/centreedit")
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }

      dispatch(setCentreGridPagination(updatedPagination))
    }
  }

  const handleNameSearch = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault()
      setCentreNameFilter(event.target.value)
    }
  }

  return (
    <>
      <FilterItems>
        <Controls.TextInput
          name="centre_name"
          label="Centre Name (starts with)"
          type="search"
          value={centreNameField}
          onChange={handleCentreNameFieldChange}
          onKeyDown={(event) => handleNameSearch(event)}
        />
      </FilterItems>

      <Box>
        <Mobile width={675}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {centres &&
              centres.map((centre) => (
                <CentreTile
                  key={centre.id}
                  centre={centre}
                  handleEditCentre={(e) => {
                    history.push(`/CentreEdit/${centre.id}`)
                  }}
                />
              ))}
          </Box>
        </Mobile>
      </Box>

      <Box sx={styles.root}>
        <Desktop width={675}>
          <Box sx={{ marginTop: "20px", marginBottom: "15px" }}>
            <TableContainer>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="Centres"
              >
                <CentreGrid
                  numSelected={0}
                  selected={[]}
                  centres={centres}
                  order={pag.order}
                  orderBy={pag.orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={centres.length}
                />
                <TableBody>
                  {centres.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={false}
                        tabIndex={-1}
                        key={row.id}
                        selected={false}
                        onClick={(event) =>
                          history.push(`/CentreEdit/${row.id}`)
                        }
                        sx={styles.tableRow}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ minWidth: "200px" }}
                        >
                          <LinkButton
                            to={`/CentreEdit/${row.id}`}
                            sx={{ justifyContent: "flex-start" }}
                          >
                            {row.name}
                          </LinkButton>
                        </TableCell>

                        <TableCell align="left">{row.short_name}</TableCell>

                        <TableCell align="left">{row.city}</TableCell>

                        <TableCell align="left">{row.email}</TableCell>

                        <TableCell align="left">{row.phone}</TableCell>

                        <TableCell align="left">
                          {contacts &&
                            contacts.find((c) => c.id === row.user_id_contact)
                              ?.email}
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {format(row.created.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {format(row.modified.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Desktop>
        <Box sx={{ marginTop: "10px" }}>
          <NavButtons>
            <Controls.Button
              size="small"
              disabled={pag.page === 0}
              onClick={() => handlePageNav(-1)}
              text="Prev"
            />
            <Controls.Button
              size="small"
              disabled={centres.length < rowsPerPage}
              onClick={() => handlePageNav(1)}
              text="Next"
            />
          </NavButtons>
        </Box>
        <PageNo pageNo={pag.page + 1} count={centreCount} type="centres" />
      </Box>

      <FabButton
        handleClick={handleNewCentre}
        label="New Centre"
        disabled={isAdmin === false}
      />

      {/* <Button
                onClick={() => {
                    renderEmailComponent(ConfirmationCodeEmail, { code: "XYZ123" }, (html) => {
                        console.log("%crendered HTML", "color:lightgreen", html)
                    })
                }}
            >
                Email Test
            </Button>

            <ConfirmationCodeEmail code="ABC123" /> */}
    </>
  )
}
