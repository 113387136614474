import db from "../../Firestore"
import * as dataServices from "./dataServices"
import * as Roles from "./roleServices"
import * as workOrderServices from "./workOrderServices"
import * as supplierServices from "./supplierServices"


// Delete quote
const deleteQuote = async (workOrderId, quoteId) => {
  await db
    .collection("work_orders")
    .doc(workOrderId)
    .collection("quotes")
    .doc(quoteId)
    .delete()

  supplierServices.updateSupplierAccess({ workOrderId })
}

const handleCreateOrUpdateQuote = async ({
  quote,
  jobs,
  accountId,
  workOrderId,
}) => {
  // Look up the supplier to get their supplier_account_id value

  const supplierDoc = await db
    .collection("suppliers")
    .doc(quote.supplier_id)
    .get()
  const supplier = supplierDoc.data()

  const quoteValues = {
    account_id: accountId,
    work_order_id: workOrderId,
    created: dataServices.serverTimestamp(),
    modified: dataServices.serverTimestamp(),
    centre_ids: Array.from(new Set(jobs.map((job) => job.centre_id))),
    ...quote,
  }

  if (supplier.supplier_account_id) {
    quoteValues.supplier_account_id = supplier.supplier_account_id
  } else {
    delete quoteValues.supplier_account_id
  }

  let quoteId

  if (quote.id) {
    const { id, ...rest } = quoteValues
    await db
      .collection("work_orders")
      .doc(workOrderId)
      .collection("quotes")
      .doc(quote.id)
      .update(rest)
    quoteId = quote.id
  } else {
    const quoteRef = await db
      .collection("work_orders")
      .doc(workOrderId)
      .collection("quotes")
      .add(quoteValues)

    quoteId = quoteRef.id

    await supplierServices.updateSupplierAccess({
      workOrderId: quote.work_order_id,
    })
  }

  return quoteId
}

const handleUpdateQuote = async ({ quote, userAccountType, userEmail }) => {
  const quoteDoc = await db
    .collection("work_orders")
    .doc(quote.work_order_id)
    .collection("quotes")
    .doc(quote.id)
    .get()
  const quoteData = quoteDoc.data()

  const updatedQuote = {
    ...quoteData,
    terms: quote.terms,
    total: quote.total,
    preferred: quote.preferred,
    modified: dataServices.serverTimestamp(),
  }

  if (userAccountType === Roles.ACCOUNT_TYPE_SUPPLIER) {
    updatedQuote.received = dataServices.serverTimestamp()
  }

  const workOrderDoc = await db
    .collection("work_orders")
    .doc(quote.work_order_id)
    .get()
  const workOrderData = workOrderDoc.data()

  await db
    .collection("work_orders")
    .doc(quote.work_order_id)
    .collection("quotes")
    .doc(quote.id)
    .set(updatedQuote)

  // Don't need to update supplier access in this case, since only updating a few fields, and not doing anything that impacts supplier access
  // if (userAccountType === Roles.ACCOUNT_TYPE_CENTRE) {
  //     await supplierServices.updateSupplierAccess({ workOrderId: quote.work_order_id })
  // }

  await workOrderServices.appendWorkOrderHistory({
    workOrderId: quote.work_order_id,
    status: workOrderData.status,
    userEmail: userEmail,
    comment: `Quote updated: Total ${quote.total}, Terms: ${quote.terms}`,
  })
}

export { deleteQuote, handleUpdateQuote, handleCreateOrUpdateQuote }
