import TextInput from "./TextInput"
import RadioGroup from "./RadioGroup"
import Select from "./Select"
import Checkbox from "./Checkbox"
import DatePicker from "./DatePicker"
import Button from "./Button"
import Readonly from "./Readonly"
import ComboBox from "./ComboBox"
import CreatedDate from "./CreatedDate"
import CreatedLabel from "./CreatedLabel"
import CentreCombobox from "./CentreCombobox"
import SupplierCombobox from "./SupplierCombobox"
import SupplierAutocomplete from "./SupplierAutocomplete"
import WorkOrderCombobox from "./WorkOrderCombobox"
import StatusChip from "./StatusChip"
import JobStatusChip from "./JobStatusChip"
import UserCombobox from "./UserCombobox"
import JobTypeComboBox from "./JobTypeComboBox"
import AddMultiple from "./AddMultiple"
import FabButton from "./FabButton"
import UserAutoComplete from "./UserAutoComplete"

const Controls = {
  TextInput,
  RadioGroup,
  Select,
  Checkbox,
  DatePicker,
  Button,
  Readonly,
  ComboBox,
  CreatedDate,
  CreatedLabel,
  CentreCombobox,
  UserCombobox,
  SupplierCombobox,
  SupplierAutocomplete,
  WorkOrderCombobox,
  UserAutoComplete,
  StatusChip,
  JobStatusChip,
  JobTypeComboBox,
  AddMultiple,
  FabButton,
}

export default Controls
