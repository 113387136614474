import { useRef, useState } from "react"

/**
 * For printing images, e.g. in work orders or checklists
 *
 * @param {*} props
 * @returns
 */
const AutoSizingImage = (props) => {
  const { url } = props

  const [dimensions, setDimensions] = useState({
    width: undefined,
    height: undefined,
  })

  const MM_PER_PIXEL = 0.26458333

  const MARGIN = 40

  const A4_PAGE_WIDTH_IN_MM = 210 - MARGIN

  const SCALE_FACTOR = 80 // 80%

  const converPixelsToMM = (pixels) => {
    return pixels * MM_PER_PIXEL
  }

  const imgRef = useRef()

  return (
    <img
      ref={imgRef}
      src={url}
      alt={"job"}
      width={dimensions.width}
      height={dimensions.height}
      onLoad={(event) => {
        const { width, height } = event.target

        const newWidthMM = converPixelsToMM(width)
        //const newHeightMM = converPixelsToMM(height)

        const widthScaleFactor =
          newWidthMM < A4_PAGE_WIDTH_IN_MM
            ? 1
            : A4_PAGE_WIDTH_IN_MM / newWidthMM

        const scaledWidth = (widthScaleFactor * width * SCALE_FACTOR) / 100
        const scaledHeight = (widthScaleFactor * height * SCALE_FACTOR) / 100

        setDimensions({ width: scaledWidth, height: scaledHeight })
      }}
    />
  )
}

export default AutoSizingImage
