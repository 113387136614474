import React from "react"
import Header from "../components/Header"
import CommentGrid from "../components/CommentGrid"

const Comments = () => {
  return (
    <Header title="Comments">
      <CommentGrid />
    </Header>
  )
}

export default Comments
