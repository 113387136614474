import React, { useState, useEffect, useMemo } from "react"
import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material"
import { Form } from "./useForm"
import Controls from "./controls/Controls"
import { formatAmount } from "../pages/services/formatting"
import { secsToDateStr } from "../pages/services/dateServices"
import {
  getStripeProducts,
  getStripePlan,
  changeStripeSubscriptionCancellationStatus,
} from "../pages/services/stripeServices"
import { spacing } from "../pages/services/styleServices"

const styles = {
  pageContent: {
    marginTop: spacing(1),
    marginLeft: spacing(1),
    marginRight: spacing(2),
    padding: spacing(1),
    maxWidth: "320px",
  },
  product: {
    margin: spacing(1),
  },
}

const StripeSubscription = (props) => {
  const { subscription, refreshSubscription, setShowProgress } = props

  const [products, setProducts] = useState([])

  const [subscriptionItems, setSubscriptionItems] = useState([])

  const [plans, setPlans] = useState([])

  useEffect(() => {
    const planIds = subscription.items.data.map((item) => item.plan.id)

    const plans = planIds.map((planId) => getStripePlan(planId))

    Promise.all(plans)
      .then((planResult) => {
        setPlans(planResult.map((plan) => plan.data))
      })
      .then(() => {
        const productIds = subscription.items.data.map(
          (item) => item.plan.product
        )

        getStripeProducts({ product_ids: productIds }).then((products) => {
          setProducts(products.data)
        })
      })
  }, [subscription])

  const handleCancelSubscription = async (subscriptionId) => {
    setShowProgress(true)

    const result = await changeStripeSubscriptionCancellationStatus(
      subscriptionId,
      true
    )

    refreshSubscription()

    setShowProgress(false)
  }

  const handleRestartSubscription = async (subscriptionId) => {
    setShowProgress(true)

    const result = await changeStripeSubscriptionCancellationStatus(
      subscriptionId,
      false
    )

    refreshSubscription()

    setShowProgress(false)
  }

  // const getSubscriptionItemDescription = (item) => {

  //   switch (item.price.billing_scheme) {
  //     case "tiered":
  //       const plan = plans.find((plan) => plan.id === item.price.id)

  //       if (plan === undefined) {
  //         return "tiered"
  //       }

  //       const tierLabels = plan.tiers.map(
  //         (tier, index) =>
  //           `${formatAmount(tier.unit_amount_decimal)} ${getTierDescription(
  //             index,
  //             tier.up_to
  //           )}`
  //       )

  //       const label = tierLabels.join(", ")

  //       const tiered = `qty ${item.quantity} with tiered pricing - ${label} (ex GST)`
  //       return tiered

  //     case "per_unit":
  //       return `qty ${item.quantity} @ ${formatAmount(
  //         item.price.unit_amount
  //       )} per unit (ex GST)`

  //     default:
  //       return `Unknown billing scheme: ${item.price.billing_scheme}`
  //   }
  // }

  useEffect(() => {
    const subItems = (
      <Grid container>
        <Grid item>
          <Typography
            variant="caption"
            color="textSecondary"
            component={"span"}
          >
            Product(s)
          </Typography>
        </Grid>
        {subscription.items.data.map((item) => {
          const product = products.find((pr) => pr.id === item.price.product)
          const productName = product ? product.name : ""

          return (
            <Grid item xs={12} key={item.id}>
              <Card elevation={0}>
                <CardContent sx={styles.product}>
                  <Box>
                    <Typography variant="body1" component={"span"}>
                      {productName}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" component={"span"}>
                      <SubscriptionItemDescription item={item} plans={plans} />
                    </Typography>
                  </Box>
                  {subscription.cancel_at_period_end && (
                    <Alert severity="warning" sx={{ marginTop: "5px" }}>
                      This subscription will be cancelled at period end and no
                      further payments/invoices produced after that.
                    </Alert>
                  )}
                  {!subscription.cancel_at_period_end && (
                    <Alert severity="info" sx={{ marginTop: "5px" }}>
                      If you want to Cancel your subscription it will cancel at
                      end of month and no further payments/invoices will be
                      processed. If you cancel, you can also undo this
                      cancellation / re-start the subscription before end of
                      month.
                    </Alert>
                  )}
                </CardContent>
                <CardActionArea>
                  {!subscription.cancel_at_period_end && (
                    <Controls.Button
                      text="Cancel Subscription"
                      onClick={() =>
                        handleCancelSubscription(item.subscription)
                      }
                    />
                  )}
                  {subscription.cancel_at_period_end && (
                    <Controls.Button
                      text="Restart Subscription"
                      onClick={() =>
                        handleRestartSubscription(item.subscription)
                      }
                    />
                  )}
                </CardActionArea>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    )

    setSubscriptionItems(subItems)
  }, [products, plans, subscription])

  return (
    <Paper sx={styles.pageContent}>
      <Form>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h6" gutterBottom={true} paragraph={true}>
              Subscription
            </Typography>
          </Grid>

          <Grid item>
            <Controls.Readonly
              name="subscription_period"
              label="Period"
              value={`${secsToDateStr(
                subscription.current_period_start
              )} - ${secsToDateStr(subscription.current_period_end)}`}
            />
          </Grid>

          <Grid item>
            <Controls.Readonly
              name="status"
              label="Status"
              value={subscription.status}
            />
          </Grid>

          <Grid item>{subscriptionItems}</Grid>

          {/*               
                <Grid item>
                    <Controls.Readonly
                        name="unit_amount"
                        label="Unit Amount"
                        value={`${subscription.currency} ${formatAmount(subscription.amount)} ${subscription.billing_scheme}`}
                        />
                </Grid>

                <Grid item>
                    <Controls.Readonly
                        name="tax_rate"
                        label="Tax Rate"
                        value={`${subscription.tax_rate}%`}
                        />
                </Grid>

                <Grid item>
                    <Controls.Readonly
                        name="quantity"
                        label="Quantity"
                        value={subscription.quantity}
                        />
                </Grid>


                <Grid item>
                    <Controls.Readonly
                        name="trial_period"
                        label="Trial Period"
                        value={`${secsToDateStr(subscription.trial_start)} - ${secsToDateStr(subscription.trial_end)}`}
                        />
                </Grid>
                  */}
        </Grid>
      </Form>
    </Paper>
  )
}

const SubscriptionItemDescription = (props) => {
  const { item, plans } = props

  const getSubscriptionItemDescription = (item) => {
    const taxes = item.tax_rates
      .map((rate) => `${rate.country} ${rate.percentage}% ${rate.display_name}`)
      .join(", ")

    switch (item.price.billing_scheme) {
      case "tiered":
        const plan = plans.find((plan) => plan.id === item.price.id)

        if (plan === undefined) {
          return "tiered"
        }

        const tierLabels = plan.tiers.map((tier) => {
          if (tier.up_to === null) {
            return `and then ${formatAmount(
              tier.unit_amount_decimal
            )} each from then on`
          } else {
            return `${formatAmount(tier.unit_amount_decimal)} each up to ${
              tier.up_to
            }`
          }
        })
        const label = tierLabels.join(", ")

        const tiered = `qty ${item.quantity} with tiered pricing - ${label}`
        return `${tiered} plus taxes: ${taxes}`

      case "per_unit":
        return `qty ${item.quantity} @ ${formatAmount(
          item.price.unit_amount
        )} per unit, plus taxes: ${taxes}`

      default:
        console.log("unknown billing scheme", item.price.billing_scheme)
        return `Unknown billing scheme: ${item.price.billing_scheme}`
    }
  }

  const itemDescription = useMemo(
    () => getSubscriptionItemDescription(item),
    [item, plans]
  )

  return itemDescription
}

export default StripeSubscription
