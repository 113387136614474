import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material"
import Controls from "./controls/Controls"
import * as icons from "../icons"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import { useState } from "react"

const ContactDialog = ({ open, onClose, values, handleSaveContact }) => {
  const [contactValues, setContactValues] = useState(values)

  const handleInputChange = (e) => {
    setContactValues({
      ...contactValues,
      [e.target.name]: e.target.value,
    })
  }

  const { browserWidth, width } = useWindowDimensions()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Contact</DialogTitle>
      <DialogContent>
        <Stack
          gap={2}
          sx={{ mt: "15px", width: `${Math.min(300, browserWidth - 120)}` }}
        >
          <Controls.TextInput
            autoFocus
            name="first_name"
            label="First Name"
            value={contactValues.first_name}
            onChange={handleInputChange}
          />
          <Controls.TextInput
            name="last_name"
            label="Last Name"
            value={contactValues.last_name}
            onChange={handleInputChange}
          />
          <Controls.TextInput
            name="phone"
            label="Phone"
            value={contactValues.phone}
            icon={<icons.PhoneIcon fontSize="small" />}
            onChange={handleInputChange}
          />
          <Controls.TextInput
            name="email"
            label="Email"
            value={contactValues.email}
            icon={<icons.EmailIcon fontSize="small" />}
            onChange={handleInputChange}
          />
          <Controls.TextInput
            name="job_title"
            label="Job Title"
            value={contactValues.job_title}
            onChange={handleInputChange}
          />
          <Controls.TextInput
            name="notes"
            label="Notes"
            multiline
            value={contactValues.notes}
            onChange={handleInputChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Controls.Button
          text="Cancel"
          color="default"
          variant="outlined"
          onClick={onClose}
        />
        <Controls.Button
          text="Save"
          onClick={() => handleSaveContact(contactValues)}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ContactDialog
