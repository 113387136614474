import { Menu, MenuItem } from "@mui/material"

const ContactMenu = ({
  anchorEl,
  setAnchorEl,
  menuId,
  handleRemoveContact,
  handleEditContact,
}) => {
  return (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem key="edit-contact" onClick={handleEditContact}>
        Edit Contact
      </MenuItem>
      <MenuItem key="remove-contact" onClick={handleRemoveContact}>
        Delete Contact
      </MenuItem>
    </Menu>
  )
}

export default ContactMenu
