// For use with checklists only

import { useEffect, useState } from "react"
import * as colors from "@mui/material/colors"
import { Chip } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"

const chipColors = { Y: colors.lightGreen[200], "N/A": colors.blue[200], N: colors.red[200] }

const styles = {
    chip: {
        "&:hover": {
            cursor: "hand",
        },
    },
}

const SelectableChip = (props) => {
    const { value, answer, handleClick, readonly } = props

    const [backgroundColor, setBackgroundColor] = useState("")

    const [icon, setIcon] = useState()

    const [ChipProps, setChipProps] = useState({})

    useEffect(() => {
        const backgroundColor = chipColors[value]

        if (answer.value === value) {
            setBackgroundColor(backgroundColor)
            setIcon(<CheckIcon />)
        } else {
            setBackgroundColor("")
            setIcon(undefined)
        }
    }, [value, answer])

    // Can only change answer if not readonly
    useEffect(() => {
        if (!readonly) {
            setChipProps({
                onClick: () => {
                    handleClick(answer.value)
                },
            })
        }
    }, [readonly])

    return (
        <Chip
            clickable
            size="small"
            label={answer.label}
            sx={styles.chip}
            style={{ backgroundColor: backgroundColor }}
            icon={icon}
            {...ChipProps}
        />
    )
}

export default SelectableChip
