import * as actions from "../actionTypes"

const initialState = {
  value: {
    data: [],
    sortCentres: false,
  },
}

/**
 * Store state for retrieving jobs by centre and category from BigQuery for use in ChartJS
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function chartJobsByCentreAndCategory(
  state = initialState,
  action
) {
  switch (action.type) {
    case actions.SET_JOBS_BY_CENTRE_AND_CATEGORY: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
