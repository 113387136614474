import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import db from "../Firestore"
import ActionPanel from "./ActionPanel"
import * as Roles from "../pages/services/roleServices"
import SubHeading from "./SubHeading"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const ActionPanels = ({ parentId, parentType, parentAccountId }) => {
  const [actions, setActions] = useState([])

  const [currentUser, setCurrentUser] = useState()

  const [claims, setClaims] = useState()

  const [isJobAdmin, setIsJobAdmin] = useState(false)

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult().then((token) => {
        setCurrentUser(user)
        setClaims(token.claims)
        setIsJobAdmin(token.claims.roles.includes(Roles.JOB_ADMIN))
      })
    })
    return unsub
  }, [])

  useEffect(() => {
    if (parentId && parentType && parentAccountId && claims) {
      let query = db
        .collection("actions")
        .where("account_id", "==", parentAccountId)
        .where("parent_id", "==", parentId)
        .where("type", "==", parentType)

      if (claims.account_type === Roles.SUPPLIER) {
        query = query.where("supplier_account_id", "==", claims.account_id)
      }

      const unsub = query.onSnapshot(
        (querySnapshot) => {
          const newActions = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          setActions(newActions)
        },
        (error) =>
          console.error("error listening for action changes", {
            error,
            parentId,
            parentType,
            parentAccountId,
          })
      )

      return unsub
    }
  }, [parentId, parentType, parentAccountId, claims])

  return (
    <Box>
      <SubHeading title="Actions" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {actions?.length === 0 && (
          <Typography variant="body2" color="text.secondary">
            None found. Send actions to your team or suppliers
          </Typography>
        )}
        {actions &&
          actions.map((action) => (
            <Box key={action.id}>
              <ActionPanel
                action={action}
                currentUser={currentUser}
                isJobAdmin={isJobAdmin}
              />
              {/* <ActionTile
                action={action}
                centres={[]}
                isSupplierViewing={false}
                handleEditAction={() => {}}
                currentUserEmail={currentUser.email}
                isJobAdmin={isJobAdmin}
              /> */}
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default ActionPanels
