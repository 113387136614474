import React from "react"
import AssetGrid from "../components/AssetGrid"
import Header from "../components/Header"

const Assets = () => {
    return (
        <Header title="Assets">
            <AssetGrid />
        </Header>
    )
}

export default Assets
