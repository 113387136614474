import React, { useState, useEffect } from "react"
import {
  Box,
  CardActions,
  FormControl,
  IconButton,
  Tooltip,
  TextField,
  Stack,
  Chip,
} from "@mui/material"
import Controls from "./controls/Controls"
import { Typography } from "@mui/material"
import { Card } from "@mui/material"
import { CardContent } from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import * as colors from "@mui/material/colors"
import _ from "lodash"
import { Alert } from "@mui/material"
import * as UIConstants from "./controls/UIConstants"
import { spacing } from "../pages/services/styleServices"
import CheckListElementAnswers from "./CheckListElementAnswers"
import { chipColors, chipTooltips } from "../pages/services/checkListServices"

// values to indicate if a particular label type indicates a yes, no, or n/a type answer
const defaultAnswerTypes = ["Y", "N", "N/A"]

const styles = {
  card: {
    margin: spacing(1),
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
    width: "500px",
  },
  elementAvatar: {
    backgroundColor: colors.lightBlue[500],
    width: 24,
    height: 24,
  },
  cardContent: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    paddingTop: "10px",
    paddingBottom: 0,
    marginRight: spacing(1),
    marginLeft: spacing(1),
  },
  cardActions: {
    margin: 0,
    paddingBottom: 0,
  },
  chips: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: spacing(0.5),
    marginTop: spacing(1),
  },
}

const CheckListEditFormListElement = (props) => {
  const {
    section,
    element,
    handleMoveElementUp,
    handleMoveElementDown,
    setEditId,
    isEditing,

    // Change a field value
    handleChange,

    // Change an entire element
    handleChangeElement,

    handlePromptDeleteItem,
    isCheckListUsed,
  } = props

  const [newAnswerLabel, setNewAnswerLabel] = useState("")

  const [isDuplicateValue, setDuplicateValue] = useState(false)

  const [showDuplicateWarning, setShowDuplicateWarning] = useState(false)

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      setEditId(undefined)
    }
  }

  useEffect(() => {
    const answersGrouped = _.groupBy(element.answers, "value")
    const isDuplicate = Object.values(answersGrouped).find(
      (value) => value.length > 1
    )
    setDuplicateValue(isDuplicate)
  }, [element])

  const handleChipClick = (answer) => {
    const currIndex = defaultAnswerTypes.indexOf(answer.value)
    const newIndex = (currIndex + 1) % defaultAnswerTypes.length
    const newValue = defaultAnswerTypes[newIndex]
    const newAnswer = { label: answer.label, value: newValue }

    const newAnswers = element.answers.map((currAnswer) =>
      currAnswer.label === answer.label ? newAnswer : currAnswer
    )

    const newElement = {
      ...element,
      answers: newAnswers,
    }

    handleChangeElement(newElement, getSrcId())
  }

  const getSrcId = () => {
    return {
      section: section.seq,
      element: element.seq,
    }
  }

  const handleChipDelete = (label) => {
    const newElement = {
      ...element,
      answers: element.answers.filter((answer) => answer.label !== label),
    }

    handleChangeElement(newElement, getSrcId())
  }

  const handleNewAnswer = (newAnswerLabel) => {
    setNewAnswerLabel("")

    if (
      newAnswerLabel !== "" &&
      (element.answers || []).find(
        (answer) => answer.label.toUpperCase() === newAnswerLabel.toUpperCase()
      ) === undefined
    ) {
      const newAnswer = { label: newAnswerLabel, value: "Y" }

      const newAnswers = [...(element.answers || []), newAnswer]

      const newElement = {
        ...element,
        answers: newAnswers,
      }

      handleChangeElement(newElement, getSrcId())
    }
  }

  const typeOptions = [
    { id: "check", title: "Check" },
    { id: "date", title: "Date" },
    { id: "signature", title: "Signature" },
    { id: "text", title: "Text" },
  ]

  const getSubHeader = (element) => {
    const items = [`${element.type} field`]

    if (element.mandatory) {
      items.push("Mandatory")
    }

    if (element.type === "check") {
      items.push(`Score ${element.score_value}`)
    }

    return items.join(" | ")
  }

  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <FormControl>
          {isEditing ? (
            <Stack gap={2}>
              <Controls.TextInput
                autoFocus
                name="label"
                label="Question Label"
                value={element.label}
                disabled={isCheckListUsed}
                onChange={(event) => handleChange(event, getSrcId())}
                onKeyPress={(event) => handleKeyPress(event)}
              />

              <Box sx={{ width: "100px" }}>
                <Controls.Select
                  name="type"
                  label="Type"
                  value={element.type}
                  disabled={isCheckListUsed}
                  onChange={(event) => handleChange(event, getSrcId())}
                  options={typeOptions}
                />
              </Box>

              <Controls.TextInput
                name="text"
                label="Acceptance Criteria"
                value={element.text}
                disabled={isCheckListUsed}
                multiline
                onChange={(event) => handleChange(event, getSrcId())}
                onKeyPress={(event) => handleKeyPress(event)}
                helperText="Use shift-enter to add new lines"
              />

              <Controls.Checkbox
                name="mandatory"
                label="Mandatory question"
                value={element.mandatory}
                disabled={isCheckListUsed}
                onChange={(event) => handleChange(event, getSrcId())}
              />

              {element.type === "check" && (
                <Box sx={{ width: "100px" }}>
                  <Controls.TextInput
                    name="score_value"
                    label="Score Value"
                    type="number"
                    value={element.score_value}
                    disabled={isCheckListUsed}
                    onChange={(event) => handleChange(event, getSrcId())}
                  />
                </Box>
              )}
            </Stack>
          ) : (
            <Typography variant="body2">{element.text}</Typography>
          )}

          {element.type === "check" && (
            <Box sx={styles.chips}>
              {element.type === "check" && (
                <CheckListElementAnswers
                  element={element}
                  readonly={isCheckListUsed}
                  handleChipDelete={handleChipDelete}
                  handleChipClick={handleChipClick}
                />
              )}

              {isEditing && element.type === "check" && !isCheckListUsed && (
                <TextField
                  name="newLabel"
                  value={newAnswerLabel}
                  type="search"
                  onChange={(event) => setNewAnswerLabel(event.target.value)}
                  onBlur={(event) => handleNewAnswer(event.target.value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault()
                      handleNewAnswer(event.target.value)
                    }
                  }}
                  helperText="Add new answer type"
                  variant={UIConstants.STANDARD_INPUT_VARIANT}
                  size={UIConstants.STANDARD_INPUT_SIZE}
                />
              )}
            </Box>
          )}
          {isDuplicateValue && showDuplicateWarning && (
            <Alert severity="warning">
              At most 1 answer of each type allowed
            </Alert>
          )}
        </FormControl>
      </CardContent>

      {!isCheckListUsed && isEditing && (
        <CardActions disableSpacing sx={styles.cardActions}>
          <Box sx={{ marginLeft: "auto" }}>
            {!isEditing && (
              <IconButton
                aria-label="edit"
                onClick={() => setEditId(getSrcId())}
                size="large"
              >
                <Tooltip title="Edit question">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            )}
            {isEditing && (
              <IconButton
                aria-label="close"
                onClick={() => {
                  if (isDuplicateValue) {
                    setShowDuplicateWarning(true)
                  } else {
                    setShowDuplicateWarning(false)
                    setEditId(undefined)
                  }
                }}
                size="large"
              >
                <Tooltip title="Close">
                  <ExpandLessIcon />
                </Tooltip>
              </IconButton>
            )}
            <IconButton
              aria-label="move up"
              onClick={() => handleMoveElementUp(getSrcId())}
              size="large"
            >
              <Tooltip title="Move this question up">
                <ArrowUpwardIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              aria-label="move down"
              onClick={() => handleMoveElementDown(getSrcId())}
              size="large"
            >
              <Tooltip title="Move this question down">
                <ArrowDownwardIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => handlePromptDeleteItem(getSrcId())}
              size="large"
            >
              <Tooltip title="Delete question">
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </Box>
        </CardActions>
      )}
    </Card>
  )
}

export default CheckListEditFormListElement
