import {
    AppBar,
    Box,
    Dialog,
    IconButton,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import React from "react"
import { CloseIcon } from "../icons"

const ResponsiveDialog = ({ open, handleClose, title, children }) => {
    const theme = useTheme()

    const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={isMobile} // Make the dialog fullWidth on mobile devices
            maxWidth="xs" // Set the maxWidth of the dialog
            sx={{
                "& .MuiDialog-paper": {
                    marginLeft: isMobile ? 5 : undefined, // Remove the left margin on mobile devices
                },
            }}
        >
            <>
                {isMobile && (
                    <AppBar sx={{ position: "relative" }}>
                        <Toolbar>
                            <Box sx={{ marginLeft: "5px" }}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            {title && (
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    {title}
                                </Typography>
                            )}
                            {/* <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button> */}
                        </Toolbar>
                    </AppBar>
                )}
                {children}
            </>
        </Dialog>
    )
}

export default ResponsiveDialog
