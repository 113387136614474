import React from "react"
import Header from "../components/Header"
import { withRouter } from "react-router-dom"
import CoverageList from "../components/CoverageList"

const CoverageEdit = (props) => {
  return (
    <Header title={"Supplier Coverage Areas"}>
      <CoverageList />
    </Header>
  )
}

export default withRouter(CoverageEdit)
