import React, { useState } from "react"
import Header from "../components/Header"
import { useId } from "./useId"
import CheckListInstanceEditForm from "../components/CheckListInstanceEditForm"

const CheckListInstanceEdit = (props) => {
  let { id } = useId(props)

  const [title, setTitle] = useState("Edit CheckList")

  return (
    <Header title={title}>
      <CheckListInstanceEditForm id={id} setTitle={setTitle} />
    </Header>
  )
}

export default CheckListInstanceEdit
