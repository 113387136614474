
const ensureAllFieldsExist = (users) => {
  // 'active' is a new field which determines if the user can access the app
  return users.map((user) => ({
    active: true,
    ...user,
  }))
}

export { ensureAllFieldsExist }
