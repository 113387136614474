import React, { useState, useMemo } from "react"
import StatusChip from "./StatusChip"
import { Alert, Box } from "@mui/material"
import { Typography } from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { IconButton } from "@mui/material"
import { Dialog } from "@mui/material"
import { DialogTitle } from "@mui/material"
import { DialogContent } from "@mui/material"
import { Grid } from "@mui/material"
import * as workOrder from "../workOrderStatus"
import { DialogActions } from "@mui/material"
import Controls from "./Controls"
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline"
import _ from "lodash"
import { Tooltip } from "@mui/material"
import { spacing } from "../../pages/services/styleServices"
import * as roleServices from "../../pages/services/roleServices"

const styles = {
    statusChip: {
        marginLeft: spacing(1),
    },
    statusBox: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            marginTop: spacing(0.5),
        },
    },
    moreButton: {
        marginLeft: spacing(1),
        marginTop: 0,
        paddingTop: spacing(0.5),
    },
}

const WorkOrderStatusSelector = ({ statusFilter, setStatusFilter, userCentreIds, accountType }) => {
    const [statusFilterOpen, setStatusFilterOpen] = useState(false)

    const isExcludingClosedOnly = () => {
        const diff = _.differenceWith(workOrder.allStatuses, statusFilter, _.isEqual)
        return diff.length === 1 && diff[0] === workOrder.STATUS_CLOSED
    }

    const isAll = () => {
        return statusFilter.length === workOrder.allStatuses.length
    }

    return (
        <>
            {statusFilterOpen && (
                <SelectStatus
                    open={statusFilterOpen}
                    setOpen={setStatusFilterOpen}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    userCentreIds={userCentreIds}
                    accountType={accountType}
                />
            )}

            <Typography variant="caption" sx={styles.label}>
                Status
            </Typography>
            <Box sx={styles.statusBox}>
                {isExcludingClosedOnly() ? (
                    <Typography sx={{ marginTop: "5px" }} variant="body2">
                        All except Closed
                    </Typography>
                ) : isAll() ? (
                    <Typography sx={{ marginTop: "5px" }} variant="body2">
                        All
                    </Typography>
                ) : (
                    statusFilter &&
                    statusFilter.map((status) => (
                        <Box key={status} sx={styles.statusChip}>
                            <StatusChip status={status} selected={true} />
                        </Box>
                    ))
                )}
                {statusFilter && statusFilter.length === 0 && (
                    <Typography variant="body1">(All)</Typography>
                )}
                <IconButton
                    onClick={() => setStatusFilterOpen(true)}
                    size="small"
                    sx={styles.moreButton}
                >
                    <Tooltip title="Filter by status">
                        <MoreHorizIcon />
                    </Tooltip>
                </IconButton>
            </Box>
        </>
    )
}

const selectStatusStyles = {
    statusChip: {
        marginLeft: spacing(1),
    },
    buttons: {
        margin: spacing(1),
        padding: 0,
        display: "flex",
        justifyContent: "flex-end",
        gap: spacing(1),
    },
    chips: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: spacing(1),
    },
}

const SelectStatus = (props) => {
    const { open, setOpen, statusFilter, setStatusFilter, userCentreIds, accountType } = props

    const [selected, setSelected] = useState(statusFilter)

    // Due to firebase query limitations, if a user has >1 centre id allocated them they can only select 1 status
    // so that we don't end up with an invalid work order query with multiple 'in' clauses and array contains clauses
    const isSelectOneOnly = useMemo(() => {
        return userCentreIds.length > 1
    }, [userCentreIds])

    const handleClose = () => {
        setOpen(false)
    }

    const handleSelectAll = () => {
        setSelected(selectableStatuses)
    }

    const handleSelectNone = () => {
        setSelected([])
    }

    const handleToggleStatus = (status) => {
        if (isSelectOneOnly) {
            if (selected.includes(status)) {
                setSelected([])
            } else {
                setSelected([status])
            }
        } else {
            if (selected.includes(status)) {
                setSelected(selected.filter((item) => item !== status))
            } else {
                setSelected([...selected, status])
            }
        }
    }

    const handleOK = () => {
        setStatusFilter(selected)
        setOpen(false)
    }

    const selectableStatuses = useMemo(() => {
        if (accountType === roleServices.SUPPLIER) {
            return workOrder.supplierViewStatuses
        }
        return workOrder.allStatuses
    }, [accountType])

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Set status filter</DialogTitle>
            <DialogContent>
                {isSelectOneOnly && (
                    <Box>
                        <Alert sx={{ padding: "5px", marginBottom: "10px" }} severity="info">
                            Only 1 status may be selected
                        </Alert>
                    </Box>
                )}
                <Box sx={selectStatusStyles.chips}>
                    {selectableStatuses?.map((status) => (
                        <StatusChip
                            key={status}
                            size="medium"
                            status={status}
                            selected={selected.includes(status)}
                            onClick={() => handleToggleStatus(status)}
                            icon={
                                selected.includes(status) ? (
                                    <CheckCircleIcon
                                        style={{ color: workOrder.statusColors[status] }}
                                    />
                                ) : undefined
                            }
                            variant="default"
                        />
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Grid container direction="column">
                    <Grid item>
                        <Box sx={selectStatusStyles.buttons}>
                            <Controls.Button
                                text="Cancel"
                                variant="outlined"
                                onClick={handleClose}
                            />
                            {!isSelectOneOnly && (
                                <>
                                    <Controls.Button text="None" onClick={handleSelectNone} />
                                    <Controls.Button text="All" onClick={handleSelectAll} />
                                </>
                            )}
                            <Controls.Button text="OK" onClick={handleOK} variant="contained" />
                        </Box>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default WorkOrderStatusSelector
