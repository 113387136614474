import React, { useState } from "react"
import {
  Box,
  Typography,
  CardHeader,
  Card,
  CardContent,
  Button,
} from "@mui/material"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import * as stripeServices from "../pages/services/stripeServices"
import Controls from "./controls/Controls"
import { useSnackbar } from "notistack"
import { spacing } from "../pages/services/styleServices"
import { J4J_YELLOW } from "../pages/services/colorServices"

const styles = {
  pageContent: {
    marginTop: spacing(1),
    marginLeft: spacing(1),
    marginRight: spacing(2),
    padding: spacing(1),
    maxWidth: "380px",
  },
}

const StripeAddCard = (props) => {
  const { stripeCustId, setShowProgress, reload, accountId } = props

  const elements = useElements()

  const stripe = useStripe()

  const { enqueueSnackbar } = useSnackbar()

  const [nameOnCard, setNameOnCard] = useState("")

  const handleAddCard = async (event) => {
    event.preventDefault()

    if (nameOnCard === undefined || nameOnCard.trim() === "") {
      enqueueSnackbar("Please enter a name on the card", { variant: "error" })
      return
    }

    setShowProgress(true)

    const cardElement = elements.getElement(CardElement)

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    })

    if (error) {
      console.log("[error]", error)
      enqueueSnackbar(
        `An error occurred attempting to add a new card: ${error.message}`,
        { variant: "error" }
      )
    } else {
      const setupIntentData = {
        customerId: stripeCustId,
        paymentMethodId: paymentMethod.id,
      }

      const setupIntent = await stripeServices.createStripeSetupIntent(
        setupIntentData
      )

      const cardSetupPayload = {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: nameOnCard,
          },
        },
      }

      const confirmCardResult = await stripe.confirmCardSetup(
        setupIntent.data.client_secret,
        cardSetupPayload
      )

      if (confirmCardResult.error) {
        enqueueSnackbar(confirmCardResult.error.message, { variant: "error" })
        return
      }

      // Now make card default

      const setCardDefaultResult = await stripeServices.setStripeCardAsDefault(
        confirmCardResult.setupIntent.payment_method,
        stripeCustId
      )

      await stripeServices.addSubscriptionGST(accountId)
    }

    // Clear Stripe card element UI
    cardElement.clear()
    setNameOnCard("")

    reload()
    setShowProgress(false)
  }

  return (
    <Card sx={styles.pageContent}>
      <CardHeader
        avatar={<CreditCardIcon color="primary" />}
        disableTypography={true}
        title={
          <Typography variant="body2" component={"span"}>
            Add new card
          </Typography>
        }
      />

      <CardContent>
        <form onSubmit={handleAddCard}>
          <CardElement
            id="stripe-card-element"
            options={{ hidePostalCode: true }}
          />

          <Box style={{ paddingTop: "15px", width: "300px" }}>
            <Controls.TextInput
              label="Name on card"
              name="name"
              value={nameOnCard}
              onChange={(event) => setNameOnCard(event.target.value)}
              fullWidth
            />
          </Box>

          <Box style={{ paddingTop: "15px" }}>
            {/* MUST be a submit button to work properly with Stripe inputs and form */}
            <Button
              variant="outlined"
              sx={{ border: `1px solid ${J4J_YELLOW}`, textTransform: "none" }}
              type="submit"
              disabled={!stripe}
            >
              Save new card
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  )
}

export default StripeAddCard
