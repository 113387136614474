import React, { useEffect, useState } from "react"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material"
import Controls from "./controls/Controls"
import DeleteIcon from "@mui/icons-material/Delete"
import { withRouter } from "react-router-dom"
import { Form } from "./useForm"
import { useSnackbar } from "notistack"
import * as Roles from "../pages/services/roleServices"
import { Alert } from "@mui/material"
import YesNo from "./YesNo"
import * as icons from "../icons"
import NavButtons from "./NavButtons"
import { getAuth, onAuthStateChanged } from "firebase/auth"

// single form value
const initialValues = () => {
  return {
    state: "",
    area: "",
  }
}

const CoverageList = (props) => {
  // The full coverage record with all coverage values in a 'values' attribute
  const [coverage, setCoverage] = useState({ values: [] })

  // A single coverage entry with 'state' and 'area'
  const [values, setValues] = useState({ state: "", area: "" })

  const [isEditable, setEditable] = useState(false)

  const [id, setId] = useState(undefined)

  const { enqueueSnackbar } = useSnackbar()

  const [accountId, setAccountId] = useState()

  const [yesNoConfig, setYesNoConfig] = useState({
    openPrompt: false,
    description:
      "Remove coverage? You will still need to click Save after delete to update this list",
    title: "Delete",
    handleConfirm: null,
  })

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult(false).then((token) => {
        setAccountId(token.claims.account_id)
        setEditable(Roles.isLookupEditable(token.claims.roles))
      })
    })

    return unsub
  }, [])

  useEffect(() => {
    if (accountId) {
      db.collection("coverage")
        .where("account_id", "==", accountId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const d = doc.data()
            setCoverage(d)
            setId(doc.id)
          })
        })
    }
  }, [accountId])

  const handlePromptDeleteCoverageValue = (value) => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: true,
      title: `Delete '${value}'?`,
      handleConfirm: () => handleDeleteCoverageValue(value),
    }
    setYesNoConfig(newConfig)
  }

  const handleAddNewLookupValue = (event) => {
    event.preventDefault()

    if (values.state.trim() === "") {
      enqueueSnackbar("Enter state", { variant: "info" })
      return
    }

    if (values.area.trim() === "") {
      enqueueSnackbar("Enter area", { variant: "info" })
      return
    }

    if (
      coverage.values.find(
        (c) => c.state === values.state && c.area === values.area
      )
    ) {
      enqueueSnackbar("Coverage already exists", { variant: "info" })
      return
    }

    // Sort on state then area
    const newValues = [...coverage.values, values].sort(
      (a, b) => a.state.localeCompare(b.state) || a.area.localeCompare(b.area)
    )

    setCoverage({ ...coverage, values: newValues })

    // Clear out entry form ready for next lookup to be entered
    setValues(initialValues)
  }

  // Save

  const handleSaveCoverage = async (event) => {
    event.preventDefault()

    const newCoverage = {
      account_id: accountId,
      created: dataServices.serverTimestamp(),
      ...coverage,
      modified: dataServices.serverTimestamp(),
    }

    if (id) {
      await db.collection("coverage").doc(id).set(newCoverage)
    } else {
      const docRef = await db.collection("coverage").add(newCoverage)
      setId(docRef.id)
    }

    setCoverage(newCoverage)

    enqueueSnackbar("Updated", { variant: "success" })
  }

  const handleDeleteCoverageValue = (value) => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)

    const newValues = coverage.values.filter(
      (item) => !(item.state === value.state && item.area === value.area)
    )

    const newCoverage = {
      ...coverage,
      values: newValues,
    }

    setCoverage(newCoverage)
  }

  return (
    <>
      {isEditable || (
        <Alert severity="info">
          Coverage is read only. Requires Admin role to edit
        </Alert>
      )}

      <YesNo config={yesNoConfig} />

      <Form>
        {isEditable && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Box>
              <Controls.TextInput
                fullWidth={false}
                autoFocus
                name="state"
                label="State"
                value={values.state}
                onChange={(e) => {
                  setValues((curr) => ({ ...curr, state: e.target.value }))
                }}
              />
            </Box>

            <Box>
              <Controls.TextInput
                fullWidth={false}
                autoFocus
                name="area"
                label="Area"
                value={values.area}
                onChange={(e) => {
                  setValues((curr) => ({ ...curr, area: e.target.value }))
                }}
              />
            </Box>

            <Controls.Button
              text="Add Coverage Area"
              onClick={handleAddNewLookupValue}
            />
          </Box>
        )}

        <Box sx={{ maxWidth: "500px" }}>
          <List dense>
            {coverage &&
              coverage.values &&
              coverage.values.map((cov) => (
                <ListItem key={`${cov.state}-${cov.area}`}>
                  <ListItemText primary={`${cov.state}, ${cov.area}`} />
                  {isEditable && (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="start"
                        aria-label="delete"
                        onClick={() => handlePromptDeleteCoverageValue(cov)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
          </List>

          <NavButtons>
            {isEditable && (
              <Controls.Button
                text="Save"
                onClick={(event) => handleSaveCoverage(event)}
                endIcon={<icons.SaveIcon />}
              />
            )}
          </NavButtons>
        </Box>
      </Form>
    </>
  )
}

export default withRouter(CoverageList)
