import { Stack, Typography } from "@mui/material"

const PageNo = ({ pageNo, count, type }) => {
    return (
        <Stack direction="row" gap={2} sx={{ marginTop: "10px", marginLeft: "5px" }}>
            <Typography variant="caption" component={"span"} sx={{ color: "#888" }}>
                Page {pageNo}
            </Typography>
            {count !== undefined && (
                <Typography variant="caption" component={"span"} sx={{ color: "#888" }}>
                    {count} {type}
                </Typography>
            )}
        </Stack>
    )
}

export default PageNo
