import * as actions from "../actionTypes"

// pageDocs is { first: <doc>, last: <doc> } for that page, and used by prev/next
const initialState = {
  value: {
    page: 0,
    order: "desc",
    orderBy: "modified",
    personFilter: actions.BLANK_SELECT_VALUE,
    statusFilter: actions.BLANK_SELECT_VALUE,
    centreId: "",
  },
}

export default function userGridPagination(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ACTION_GRID_PAGINATION: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
