import React, { useState } from "react"
import UserEditForm from "../components/UserEditForm"
import Header from "../components/Header"
import { useId } from "./useId"

const UserEdit = (props) => {
  let { id } = useId(props)

  const [title, setTitle] = useState("Edit User")

  return (
    <Header title={title}>
      <UserEditForm id={id} setTitle={setTitle} />
    </Header>
  )
}

export default UserEdit
