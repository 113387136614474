import * as xlsx from "xlsx"
import moment from "moment"

const getWorksheetRows = (sheet) => {
    const rows = []
    const range = xlsx.utils.decode_range(sheet["!ref"])

    const worksheetRange = getWorksheetRange(sheet)

    const columnTypes = getColumnTypes(sheet)

    for (let R = range.s.r; R <= worksheetRange.row.to; ++R) {
        const row = []

        for (let C = range.s.c; C <= worksheetRange.col.to; ++C) {
            const cell = sheet[xlsx.utils.encode_cell({ c: C, r: R })]

            // Getting the 'w' attribute gets a string, and correctly gets date values
            // whereas getting the 'v' attribute gets the underlying value, e.g. dates as 5-digit integers
            const type = columnTypes[C]

            const parsedDate = tryParseDate(cell)
            if (parsedDate) {
                row.push(formatDateValue(parsedDate))
            } else {
                switch (type) {
                    case "date":
                        row.push(cell ? excelDateValueToDDMMMYYYY(cell.v) : undefined)
                        break

                    case "text":
                        row.push(cell ? cell.w : undefined)
                        break

                    case "number":
                        row.push(cell ? cell.v : undefined)
                        break

                    default:
                        row.push(cell ? cell.w : undefined)
                        break
                }
            }
        }

        rows.push(row)
    }

    // Remove 1st row since it's headings

    rows.shift()

    return rows
}

const getColumnTypes = (sheet) => {
    const worksheetRange = getWorksheetRange(sheet)

    const range = xlsx.utils.decode_range(sheet["!ref"])

    const columnTypes = []

    for (let C = range.s.c; C <= worksheetRange.col.to; ++C) {
        const cell = sheet[xlsx.utils.encode_cell({ c: C, r: 1 })]
        if (cell) {
            // See if cell is date

            let type = cell.t

            // Regexp to match a date in the format 01-Jan-23

            const dateRegexps = [
                // 01-Jan-23
                /^[0-9]{2}-[a-zA-Z]{3}-[0-9]{2}$/,
                // 01-Jan-2023
                /^[0-9]{2}-[a-zA-Z]{3}-[0-9]{4}$/,
                // 23-10-2021
                /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/,
                // 2021-10-23
                /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
                // 01/Jan/23
                /^[0-9]{2}\/[a-zA-Z]{3}\/[0-9]{2}$/,
                // 01/Jan/2023
                /^[0-9]{2}\/[a-zA-Z]{3}\/[0-9]{4}$/,
            ]

            if (cell.w && dateRegexps.find((regexp) => cell.w.match(regexp))) {
                type = "d"
            }

            columnTypes.push(type)
        } else {
            columnTypes.push("s") // default to string
        }
    }

    // Return AIM codes for column types
    const convertedColumnTypes = columnTypes.map((x) => {
        if (x === "n") {
            return "number"
        } else if (x === "d") {
            return "date"
        } else {
            return "text"
        }
    })

    return convertedColumnTypes
}

const getWorksheetRange = (sheet) => {
    const range = xlsx.utils.decode_range(sheet["!ref"])

    let maxColCount = undefined
    let maxRowCount = undefined

    for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = sheet[xlsx.utils.encode_cell({ c: C, r: range.s.r })]
        if (cell === undefined) {
            maxColCount = C - 1
            break
        }
    }
    if (maxColCount === undefined) {
        maxColCount = range.e.c
    }

    // Work out max row count based on first row to have an undefined value

    for (let R = range.s.r; R <= range.e.r; ++R) {
        const cell = sheet[xlsx.utils.encode_cell({ c: range.s.c, r: R })]
        if (cell === undefined) {
            maxRowCount = R - 1
            break
        }
    }

    if (maxRowCount === undefined) {
        maxRowCount = range.e.r
    }

    return {
        row: { from: range.s.r, to: maxRowCount },
        col: { from: range.s.c, to: maxColCount },
    }
}

const excelDateValueToDDMMMYYYY = (excelDateValue) => {
    const date = new Date((excelDateValue - (25567 + 2)) * 86400 * 1000)
    // Format as dd-mmm-yyyy
    return formatDateValue(date)
}

const formatDateValue = (date) => {
    // return `${date.getDate()}-${date.toLocaleString("default", {
    //     month: "short",
    // })}-${date.getFullYear()}`
    return moment(date).format("DD-MMM-YYYY")
}

const tryParseDate = (cell) => {
    if (!cell) {
        return null
    }

    if (cell.t === "n") {
        // Try converting the Excel date to JavaScript date
        const date = xlsx.SSF.parse_date_code(cell.v)
        if (date && moment([date.y, date.m - 1, date.d]).isValid()) {
            // The value is a valid date
            return moment([date.y, date.m - 1, date.d]).toDate()
        }
    } else if (cell.t === "s") {
        // Try parsing the string as a date
        const date = moment(cell.v, moment.ISO_8601, true)
        if (date.isValid()) {
            // The value is a valid date string
            return date.toDate()
        }
    }
    // Not a valid date
    return null
}

export { getWorksheetRows }
