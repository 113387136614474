import styled from "@emotion/styled";
import { Chip } from "@mui/material";

const StyledChipBase = styled(Chip)(({ theme }) => {
    // Default styling if any
    return {
        // ...some styles
    }
});

const StyledChip = ({ status, getChipProps, ...otherProps }) => {
    let chipStyles = {};

    try {
        chipStyles = getChipProps ? getChipProps(status) : {};
    } catch (e) {
        console.error("Error getting chip props for status", status);
    }

    return <StyledChipBase style={chipStyles} {...otherProps} />;
};

export default StyledChip;
