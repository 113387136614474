import React from "react"
import { Grid, Paper, Typography } from "@mui/material"
import { Form } from "./useForm"
import Controls from "./controls/Controls"
import { formatAmount } from "../pages/services/formatting"
import { secsToDateStr } from "../pages/services/dateServices"
import { spacing } from "../pages/services/styleServices"

const styles = {
    pageContent: {
        marginTop: spacing(1),
        marginLeft: spacing(0),
        marginRight: spacing(2),
        padding: spacing(1),
        maxWidth: 400,
        minWidth: 320,
    },
}

const StripeInvoice = (props) => {
    const { invoice } = props

    return (
        <Paper sx={styles.pageContent}>
            <Form>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h6">
                            Upcoming Invoice {formatAmount(invoice.amount_due)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" gutterBottom={true} paragraph={true}>
                            {invoice.status}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Controls.Readonly
                            name="subtotal"
                            label="Subtotal"
                            value={`${invoice.currency} ${formatAmount(invoice.subtotal)}`}
                        />
                    </Grid>

                    <Grid item>
                        <Controls.Readonly
                            name="tax"
                            label="Tax"
                            value={`${invoice.currency} ${formatAmount(invoice.tax)}`}
                        />
                    </Grid>

                    <Grid item>
                        <Controls.Readonly
                            name="amount_due"
                            label="Amount Due"
                            value={`${invoice.currency} ${formatAmount(invoice.amount_due)}`}
                        />
                    </Grid>

                    <Grid item>
                        <Controls.Readonly
                            name="invoice_period"
                            label="Period"
                            value={`${secsToDateStr(invoice.period_start)} - ${secsToDateStr(
                                invoice.period_end
                            )}`}
                        />
                    </Grid>

                    <Grid item>
                        <Controls.Readonly
                            name="invoice_next_payment_attempt"
                            label="Next Payment Attempt"
                            value={secsToDateStr(invoice.next_payment_attempt)}
                        />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    )
}

export default StripeInvoice
