import React, { useEffect, useState } from "react"
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js"
import firebase from "firebase/compat/app"
import { Button, Paper } from "@mui/material"
import {
  getEmptyStripeAccountInfo,
  getStripeCustomer,
} from "../pages/services/stripeServices"
import StripeCustomer from "./StripeCustomer"
import StripeCard from "./StripeCard"
import StripeInvoice from "./StripeInvoice"
import ProgressBackdrop from "./ProgressBackdrop"
import { spacing } from "../pages/services/styleServices"

const styles = {
  pageContent: {
    marginTop: spacing(3),
    marginLeft: spacing(2),
    marginRight: spacing(3),
    padding: spacing(2),
    maxWidth: "320px",
  },
}

const CheckoutForm = () => {
  const stripe = useStripe()

  const elements = useElements()

  const [isShowProgress, setShowProgress] = useState(true)

  const [stripeCustomer, setStripeCustomer] = useState(
    getEmptyStripeAccountInfo()
  )

  useEffect(() => {
    getStripeCustomer().then((cust) => {
      setStripeCustomer(cust)
      setShowProgress(false)
    })
  }, [])

  const createStripeCustomer = async () => {
    const createStripeCustomer = firebase
      .functions()
      .httpsCallable("createStripeCustomer")
  }

  const handlePay = async () => {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    })

    if (error) {
      console.log("[error]", error)
    }
  }

  return (
    <>
      <ProgressBackdrop open={isShowProgress} />

      <Paper sx={styles.pageContent}>
        <CardElement options={{ hidePostalCode: true }} />
        <Button onClick={handlePay}>Pay</Button>
        <Button onClick={getStripeCustomer}>Get Customer</Button>
        <Button onClick={createStripeCustomer}>Create Stripe Customer</Button>
      </Paper>

      <StripeInvoice invoice={stripeCustomer.invoice} />

      {stripeCustomer.cards.map((card) => (
        <StripeCard card={card} key={card.id} />
      ))}

      <StripeCustomer customer={stripeCustomer.customer} />

      {/*
            <StripeSubscription 
                subscription={stripeCustomer.customer.subscription}/>
            */}
    </>
  )
}

export default CheckoutForm
