import React, { useState, useMemo, useRef, useCallback } from "react"
import Header from "../components/Header"
import { Box, Tab, Typography } from "@mui/material"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js"
import BarChartIcon from "@mui/icons-material/BarChart"
import QueryStatsIcon from "@mui/icons-material/QueryStats"
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart"
import _ from "lodash"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import useMessages from "../components/useMessages"
import DashboardWidgets from "../components/DashboardWidgets"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import JobsByCentreAndCategoryChart from "../components/JobsByCentreAndCategoryChart"
import DashboardPivot from "../components/DashboardPivot"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const Dashboard = () => {
  const [isSupplierAccount, setSupplierAccount] = useState(false)

  const isShowJobReports = useMemo(() => {
    return !isSupplierAccount
  }, [isSupplierAccount])

  const { width } = useWindowDimensions()

  const [selectedTab, setSelectedTab] = useState("0")

  const [
    dailyJobsByCentreAndCategoryData,
    setDailyJobsByCentreAndCategoryData,
  ] = useState()

  const dailyChartDataByCentreAndCategory = useMemo(() => {
    if (dailyJobsByCentreAndCategoryData) {
      const selected = dailyJobsByCentreAndCategoryData.map((item) => ({
        date: item.date.value,
        centre_id: item.centre_id,
        centre_name: item.name,
        category: item.category,
        count: item.count,
      }))

      return {
        labels: [],
      }
    }
  }, [dailyJobsByCentreAndCategoryData])

  const BAR_HEIGHT = 20

  const calcAspectRatio = useCallback(
    (data) => {
      if (data) {
        const labelCount = data.labels.length || 15
        const expectedHeight = labelCount * BAR_HEIGHT + 100

        const ratio = (width - 200) / expectedHeight

        return ratio
      }
    },
    [width]
  )

  const dailyCentreAndCategoryAspectRatio = useMemo(() => {
    return calcAspectRatio(dailyChartDataByCentreAndCategory)
  }, [dailyChartDataByCentreAndCategory, calcAspectRatio])

  return (
    <Header title="Dashboard">
      <TabContext value={selectedTab}>
        <TabList onChange={(e, value) => setSelectedTab(value)}>
          <Tab
            icon={<QueryStatsIcon />}
            iconPosition="end"
            label={
              <Typography sx={{ textTransform: "none" }}>Stats</Typography>
            }
            value="0"
          />
          <Tab
            icon={<PivotTableChartIcon />}
            iconPosition="end"
            label={
              <Typography sx={{ textTransform: "none" }}>Analyse</Typography>
            }
            value="1"
          />
          {isShowJobReports && (
            <Tab
              icon={<BarChartIcon />}
              iconPosition="end"
              label={
                <Typography sx={{ textTransform: "none" }}>
                  Jobs Chart
                </Typography>
              }
              value="2"
            />
          )}
        </TabList>

        <TabPanel value={"0"} sx={{ margin: 0, padding: 0 }}>
          <DashboardWidgets />
        </TabPanel>

        <TabPanel value={"1"} sx={{ margin: 0, padding: 0 }}>
          <DashboardPivot />
        </TabPanel>

        <TabPanel
          value={"2"}
          sx={{ marginLeft: 0, paddingLeft: 0, paddingRight: 0 }}
        >
          <Box sx={{ padding: 2 }}>
            <JobsByCentreAndCategoryChart />
          </Box>
        </TabPanel>
      </TabContext>
    </Header>
  )
}

export default Dashboard
