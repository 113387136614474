import {
  blue,
  grey,
  lightGreen,
  pink,
  purple,
  teal,
  orange,
  yellow,
} from "@mui/material/colors"
import * as Roles from "../pages/services/roleServices"

// Work order statuses

const STATUS_NEW = "new" // only new unsaved work orders have this status
const STATUS_OPEN = "open"
const STATUS_ALLOCATED = "allocated"
const STATUS_ACCEPTED = "accepted"
const STATUS_INPROGRESS = "inprogress"
const STATUS_COMPLETED = "completed"
const STATUS_QUOTE_REQUESTED = "quote_requested"
const STATUS_QUOTE_PROVIDED = "quote_provided"
const STATUS_CHANGE = "change"
const STATUS_CLOSED = "closed"
const STATUS_SCHEDULED = "scheduled"

const statusLabels = {
  [STATUS_NEW]: "New",
  [STATUS_OPEN]: "Open",
  [STATUS_QUOTE_REQUESTED]: "Quote Requested",
  [STATUS_QUOTE_PROVIDED]: "Quote Provided",
  [STATUS_CHANGE]: "Change",
  [STATUS_ALLOCATED]: "Allocated",
  [STATUS_ACCEPTED]: "Accepted",
  [STATUS_INPROGRESS]: "In Progress",
  [STATUS_COMPLETED]: "Completed",
  [STATUS_CLOSED]: "Closed",
  [STATUS_SCHEDULED]: "Scheduled",
}

const statusAvatarAbbreviations = {
  [STATUS_NEW]: "N",
  [STATUS_OPEN]: "O",
  [STATUS_ALLOCATED]: "Al",
  [STATUS_ACCEPTED]: "Ac",
  [STATUS_INPROGRESS]: "IP",
  [STATUS_COMPLETED]: "Co",
  [STATUS_QUOTE_REQUESTED]: "QR",
  [STATUS_QUOTE_PROVIDED]: "QP",
  [STATUS_CHANGE]: "Ch",
  [STATUS_CLOSED]: "Cl",
}

const statusDescriptions = {
  [STATUS_NEW]: "New work order not assigned to anyone",
  [STATUS_OPEN]:
    "The centre has Opened the work order, but it is not yet allocated to a supplier",
  [STATUS_QUOTE_REQUESTED]:
    "The Centre has requested a quote from the supplier",
  [STATUS_QUOTE_PROVIDED]:
    "The Supplier has provided a quote to the centre. Centre should review, and allocated work order to supplier is quote is acceptable",
  [STATUS_CHANGE]:
    "A change is required to the work order, to perhaps change the start date, work to be performed, or agree up front costs",
  [STATUS_ALLOCATED]:
    "The Centre has Allocated the work order to a supplier, who can then Accept it and begin work",
  [STATUS_ACCEPTED]:
    "The Supplier has Accepted the work order, and will perform the work on the specified start date",
  [STATUS_INPROGRESS]: "The Supplier has started work",
  [STATUS_COMPLETED]:
    "The Supplier has completed work, but is not yet verified by the centre",
  [STATUS_CLOSED]:
    "The centre has reviewed a completed work order and agreed it can now be closed",
}

// Work order statuses visible to a supplier
const supplierStatuses = [
  STATUS_ALLOCATED,
  STATUS_ACCEPTED,
  STATUS_INPROGRESS,
  STATUS_QUOTE_REQUESTED,
  STATUS_QUOTE_PROVIDED,
  STATUS_COMPLETED,
]

// Statuses that a supplier can see
const supplierViewStatuses = [...supplierStatuses, STATUS_CLOSED]

const allStatuses = [
  STATUS_OPEN,
  STATUS_QUOTE_REQUESTED,
  STATUS_QUOTE_PROVIDED,
  STATUS_CHANGE,
  STATUS_ALLOCATED,
  STATUS_ACCEPTED,
  STATUS_INPROGRESS,
  STATUS_COMPLETED,
  STATUS_CLOSED,
]

const ACTION_CREATE = "create"
const ACTION_REQUEST_QUOTE = "request_quote"
const ACTION_REOPEN = "reopen"
const ACTION_PROVIDE_QUOTE = "provide_quote"
const ACTION_ACCEPT_QUOTE = "accept_quote"
const ACTION_ALLOCATE = "allocate"
const ACTION_UNASSIGN = "unassign"
const ACTION_REQUEST_CHANGE = "request_change"
const ACTION_ACCEPT = "accept"
const ACTION_START = "start"
const ACTION_COMPLETE = "complete"
const ACTION_CLOSE = "close"
const ACTION_REVERT = "revert"

const actionLabels = {
  [ACTION_CREATE]: { label: "Create", description: "Create a new work order" },
  [ACTION_REQUEST_QUOTE]: {
    label: "Request Quote",
    description: "Request a quote from the supplier",
  },
  [ACTION_REOPEN]: { label: "Re-open", description: "Re-open this work order" },
  [ACTION_PROVIDE_QUOTE]: {
    label: "Provide Quote",
    description: "Provide a time and cost estimate for this work order",
  },
  [ACTION_ACCEPT_QUOTE]: {
    label: "Accept Quote",
    description: "Accept the quote provided by the supplier",
  },
  [ACTION_ALLOCATE]: {
    label: "Allocate",
    description:
      "Allocate this work order to a supplier so that they may begin work",
  },
  [ACTION_UNASSIGN]: {
    label: "Unassign",
    description: "Unassign this work order from the supplier",
  },
  [ACTION_REQUEST_CHANGE]: {
    label: "Request Change",
    description:
      "Review and change some aspect of this work order, incl. start date, cost, or scope",
  },
  [ACTION_ACCEPT]: {
    label: "Accept",
    description: "The supplier accepts this work order, and will begin work",
  },
  [ACTION_START]: {
    label: "Start",
    description: "Indicate that work has started",
  },
  [ACTION_COMPLETE]: {
    label: "Complete",
    description:
      "Indicate that the work order is complete, and ready to be verified",
  },
  [ACTION_CLOSE]: {
    label: "Close",
    description:
      "Verify that work has been completed satisfactorily, and the supplier can now be paid",
  },
  [ACTION_REVERT]: {
    label: "Revert",
    description: "Reopen a job if found to not be completed satisfactorily",
  },
}

const statusChanges = [
  {
    action: ACTION_CREATE,
    label: actionLabels[ACTION_CREATE].label,
    from: STATUS_NEW,
    to: STATUS_OPEN,
    roles: [Roles.JOB_ADMIN, Roles.JOB_USER, Roles.ADMIN],
    requires_id: false,
  },
  {
    action: ACTION_REQUEST_QUOTE,
    label: actionLabels[ACTION_REQUEST_QUOTE].label,
    from: STATUS_OPEN,
    to: STATUS_QUOTE_REQUESTED,
    roles: [Roles.JOB_ADMIN, Roles.JOB_USER, Roles.ADMIN],
    requires_id: false,
  },
  {
    action: ACTION_REQUEST_QUOTE,
    label: actionLabels[ACTION_REQUEST_QUOTE].label,
    from: STATUS_QUOTE_PROVIDED,
    to: STATUS_QUOTE_REQUESTED,
    roles: [Roles.JOB_ADMIN, Roles.JOB_USER, Roles.ADMIN],
    requires_id: false,
  },
  {
    // Take the work order off the supplier if a quote has been requested. Make the work order 'Open' again
    action: ACTION_REOPEN,
    label: actionLabels[ACTION_REOPEN].label,
    from: STATUS_QUOTE_REQUESTED,
    to: STATUS_OPEN,
    roles: [Roles.JOB_ADMIN, Roles.ADMIN],
    requires_id: false,
  },
  {
    action: ACTION_PROVIDE_QUOTE,
    label: actionLabels[ACTION_PROVIDE_QUOTE].label,
    from: STATUS_QUOTE_REQUESTED,
    to: STATUS_QUOTE_PROVIDED,
    roles: [Roles.SUPPLIER],
    requires_id: false,
  },
  {
    action: ACTION_ACCEPT_QUOTE,
    label: actionLabels[ACTION_ACCEPT_QUOTE].label,
    from: STATUS_QUOTE_PROVIDED,
    to: STATUS_ALLOCATED,
    roles: [Roles.JOB_ADMIN, Roles.ADMIN],
    requires_id: true,
  },
  {
    action: ACTION_ALLOCATE,
    label: actionLabels[ACTION_ALLOCATE].label,
    from: STATUS_OPEN,
    to: STATUS_ALLOCATED,
    roles: [Roles.JOB_ADMIN, Roles.ADMIN],
    requires_id: false,
  },
  {
    action: ACTION_UNASSIGN,
    label: actionLabels[ACTION_UNASSIGN].label,
    from: STATUS_ALLOCATED,
    to: STATUS_OPEN,
    roles: [Roles.JOB_ADMIN, Roles.ADMIN],
    requires_id: true,
  },
  {
    action: ACTION_REQUEST_CHANGE,
    label: actionLabels[ACTION_REQUEST_CHANGE].label,
    from: STATUS_ALLOCATED,
    to: STATUS_CHANGE,
    roles: [Roles.SUPPLIER],
    requires_id: true,
  },
  {
    action: ACTION_ALLOCATE,
    label: actionLabels[ACTION_ALLOCATE].label,
    from: STATUS_CHANGE,
    to: STATUS_ALLOCATED,
    roles: [Roles.JOB_ADMIN, Roles.ADMIN],
    requires_id: true,
  },
  {
    action: ACTION_ACCEPT,
    label: actionLabels[ACTION_ACCEPT].label,
    from: STATUS_ALLOCATED,
    to: STATUS_ACCEPTED,
    roles: [Roles.SUPPLIER],
    requires_id: true,
  },
  {
    action: ACTION_START,
    label: actionLabels[ACTION_START].label,
    from: STATUS_ACCEPTED,
    to: STATUS_INPROGRESS,
    roles: [Roles.SUPPLIER],
    requires_id: true,
  },
  {
    action: ACTION_COMPLETE,
    label: actionLabels[ACTION_COMPLETE].label,
    from: STATUS_ACCEPTED,
    to: STATUS_COMPLETED,
    roles: [Roles.SUPPLIER],
    requires_id: true,
  },
  {
    action: ACTION_COMPLETE,
    label: actionLabels[ACTION_COMPLETE].label,
    from: STATUS_ALLOCATED,
    to: STATUS_COMPLETED,
    roles: [Roles.ADMIN],
    requires_id: true,
  },
  {
    action: ACTION_COMPLETE,
    label: actionLabels[ACTION_COMPLETE].label,
    from: STATUS_INPROGRESS,
    to: STATUS_COMPLETED,
    roles: [Roles.SUPPLIER, Roles.ADMIN],
    requires_id: true,
  },
  {
    action: ACTION_COMPLETE,
    label: actionLabels[ACTION_COMPLETE].label,
    from: STATUS_ALLOCATED,
    to: STATUS_COMPLETED,
    roles: [Roles.JOB_ADMIN, Roles.JOB_USER, Roles.ADMIN],
    requires_id: true,
  },
  {
    action: ACTION_CLOSE,
    label: actionLabels[ACTION_CLOSE].label,
    from: STATUS_INPROGRESS,
    to: STATUS_CLOSED,
    roles: [Roles.JOB_ADMIN, Roles.JOB_USER, Roles.ADMIN],
    requires_id: true,
  },
  {
    action: ACTION_CLOSE,
    label: actionLabels[ACTION_CLOSE].label,
    from: STATUS_COMPLETED,
    to: STATUS_CLOSED,
    roles: [Roles.JOB_ADMIN, Roles.JOB_USER, Roles.ADMIN],
    requires_id: true,
  },
  {
    action: ACTION_REOPEN,
    label: actionLabels[ACTION_REOPEN].label,
    from: STATUS_CLOSED,
    to: STATUS_COMPLETED,
    roles: [Roles.JOB_ADMIN, Roles.ADMIN],
    requires_id: true,
  },
  {
    // Send the work order back to the contractor
    action: ACTION_REVERT,
    label: actionLabels[ACTION_REVERT].label,
    from: STATUS_COMPLETED,
    to: STATUS_INPROGRESS,
    roles: [Roles.JOB_ADMIN, Roles.JOB_USER, Roles.ADMIN],
    requires_id: true,
  },
]

const isCostEstimateEditable = (status) => {
  return [STATUS_OPEN, STATUS_CHANGE, STATUS_QUOTE_REQUESTED].includes(status)
}

const isQuotingStatus = (status) => {
  return [STATUS_QUOTE_REQUESTED, STATUS_QUOTE_PROVIDED].includes(status)
}

const isWithSupplierStatus = (status) => {
  return [
    STATUS_ALLOCATED,
    STATUS_ACCEPTED,
    STATUS_INPROGRESS,
    STATUS_COMPLETED,
    STATUS_CHANGE,
    STATUS_CLOSED,
  ].includes(status)
}

const isNewOrOpenStatus = (status) => {
  return [STATUS_NEW, STATUS_OPEN].includes(status)
}

// What actions can be performed?
// Find all allows actions, considering the current user might have multiple roles.
// Then deduplicate those roles based on the action code, so we only put each
// action button once.
// @param isManagedSupplier true => supplier is managed by the centre, false => supplier logs in and manages jobs themselves
// @param hasId true => The work order has been saved, and therefore has an id
const getAvailableActions = (
  userRoles,
  isSupplierViewing,
  currentStatus,
  hasId
) => {
  // If it's a supplier logged in, then we treat then as role_supplier, otherwise
  // we use the user's actual roles stored in their user record

  const adjustedRoles = isSupplierViewing ? ["supplier"] : userRoles

  const availableActions = statusChanges.filter((statusChange) => {
    const rolesIntersect = statusChange.roles.filter(
      (role) => adjustedRoles.indexOf(role) !== -1
    )

    const isIncluded =
      statusChange.from === currentStatus &&
      rolesIntersect.length > 0 &&
      (!statusChange.requires_id || hasId)

    return isIncluded
  })

  const uniqueActions = new Set(availableActions.map((action) => action.action))
  const result = []
  uniqueActions.forEach((action) => {
    result.push(availableActions.find((item) => item.action === action))
  })

  return result
}

const statusColors = {
  [STATUS_ALLOCATED]: lightGreen[500],
  [STATUS_ACCEPTED]: teal[500],
  [STATUS_OPEN]: blue[600],
  [STATUS_COMPLETED]: purple[700],
  [STATUS_INPROGRESS]: blue[800],
  [STATUS_QUOTE_REQUESTED]: pink[600],
  [STATUS_QUOTE_PROVIDED]: pink[700],
  [STATUS_CHANGE]: yellow[900],
  [STATUS_CLOSED]: grey[900],
  [STATUS_SCHEDULED]: orange[600],
}

// List of statuses for when the supplier can see quotes on a work order
const supplierViewQuotesStatuses = [
  STATUS_OPEN,
  STATUS_QUOTE_REQUESTED,
  STATUS_QUOTE_PROVIDED,
]

const isSchedulable = (status) => {
  return status !== STATUS_CLOSED
}

const getAllStatusesExceptClosed = () => {
  return allStatuses.filter((status) => status !== STATUS_CLOSED)
}

export {
  statusChanges,
  getAvailableActions,
  isCostEstimateEditable,
  isQuotingStatus,
  isWithSupplierStatus,
  isNewOrOpenStatus,
  getAllStatusesExceptClosed,
  supplierViewQuotesStatuses,
  isSchedulable,
  STATUS_OPEN,
  STATUS_ALLOCATED,
  STATUS_ACCEPTED,
  STATUS_INPROGRESS,
  STATUS_COMPLETED,
  STATUS_QUOTE_REQUESTED,
  STATUS_QUOTE_PROVIDED,
  STATUS_CHANGE,
  STATUS_CLOSED,
  STATUS_SCHEDULED,
  supplierStatuses,
  supplierViewStatuses,
  allStatuses,
  statusColors,
  statusLabels,
  statusDescriptions,
  actionLabels,
  statusAvatarAbbreviations,
}
