import { Typography } from "@mui/material"

const MultiSelectLabel = ({ count, oneSelectedLabel, multiSelectedLabel }) => {
    return count > 1 ? (
        <Typography color="text.secondary" sx={{ fontWeight: "bold" }}>
            {multiSelectedLabel}
        </Typography>
    ) : (
        <Typography color="text.secondary">{oneSelectedLabel}</Typography>
    )
}

export default MultiSelectLabel
