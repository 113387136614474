import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import {
  Typography,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material"
import firebase from "firebase/compat/app"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setCheckListTemplateGridPagination } from "../redux/actions"
import { selectCheckListTemplateGridPageDocs } from "../redux/selectors"
import { selectCheckListTemplateGridPagination } from "../redux/selectors"
import * as Roles from "../pages/services/roleServices"
import { setCheckListTemplateGridPageDocs } from "../redux/actions"
import Controls from "./controls/Controls"
import _ from "lodash"
import LinkButton from "./controls/LinkButton"
import NavButtons from "./NavButtons"
import PageNo from "./PageNo"
import FabButton from "./controls/FabButton"
import { Desktop, Mobile } from "./WindowSizes"
import FilterItems from "./FilterItems"
import CheckListCard from "./CheckListCard"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sortable: true,
    direction: ["asc"],
  },
  {
    id: "archived",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created",
    sortable: false,
  },
]

const CheckListTemplateGrid = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order : headCell.direction[0]
                }
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

CheckListTemplateGrid.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    "&:hover": {
      cursor: "hand",
    },
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = useState(true)

  const pag = useSelector(selectCheckListTemplateGridPagination)

  const dispatch = useDispatch()

  const history = useHistory()

  const [rowsPerPage, setRowsPerPage] = useState(20)

  // checklist templates
  const [checklists, setChecklists] = useState([])

  const pgDocs = useSelector(selectCheckListTemplateGridPageDocs)

  const COLLECTION_NAME = "checklists"

  const [currentUser, setCurrentUser] = useState()

  const [accountId, setAccountId] = useState()

  const [hideArchived, setHideArchived] = useState(true)

  const [templateNameFilter, setTemplateNameFilter] = useState("")

  const [templateNameField, setTemplateNameField] = useState("")

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setCurrentUser(token)
          setAccountId(token.claims.account_id)
        })
      }
    })

    return unsub
  }, [])

  const updatePageDocs = () => {
    if (checklists.length > 0 && direction !== "prev") {
      const newPageDocs = [...pgDocs]

      const newPageDoc = {
        first: checklists[0].doc,
        last: checklists[checklists.length - 1].doc,
      }

      newPageDocs[pag.page] = newPageDoc
      dispatch(setCheckListTemplateGridPageDocs(newPageDocs))
    }
  }

  useEffect(() => {
    updatePageDocs(checklists)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklists])

  // Load checklists

  const [reloadState, setReloadState] = useState({})

  useEffect(() => {
    const logId = "[CHECKLIST TEMPLATE GRID > LOAD TEMPLATES]"

    if (accountId === undefined) {
      return
    }

    const newReloadState = {
      accountId,
      pag,
      templateNameFilter,
      hideArchived,
    }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    setReloadState(newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    const queryMods = []

    let query = db.collection(COLLECTION_NAME)

    const isJobAdmin = currentUser.claims.roles.includes(Roles.JOB_ADMIN)

    getAuth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        // Get current page of checklists

        if (templateNameFilter !== "") {
          query = query.orderBy("name")
          queryMods.push("order by name")
        }

        if (templateNameFilter !== "" && pag.orderBy !== "name") {
          query = query.orderBy(pag.orderBy, pag.order)
          queryMods.push("order by " + pag.orderBy + " " + pag.order)
        }

        query = query.orderBy(
          firebase.firestore.FieldPath.documentId(),
          pag.order
        )
        queryMods.push("order by doc id " + pag.order)

        if (pag.page > 0 && direction !== "prev") {
          // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
          // But if not found, it just means this is the first time we've clicked Next through the collection
          if (pgDocs[pag.page - 1]) {
            const lastDoc = pgDocs[pag.page - 1].last
            query = query.startAfter(lastDoc)
            queryMods.push(
              "start after last doc on previous page " + lastDoc.id
            )
          }
        } else if (direction === "prev") {
          if (!pgDocs[pag.page]) {
            console.error("Can't find pagedocs for page", pag.page)
          }
          query = query.startAt(pgDocs[pag.page].first)
          queryMods.push(
            "start at 1st doc on page " + pgDocs[pag.page].first.id
          )
        }

        if (templateNameFilter !== "") {
          const searchTerm = templateNameFilter // earchTerm.toLowerCase();
          const strlength = searchTerm.length
          const strFrontCode = searchTerm.slice(0, strlength - 1)
          const strEndCode = searchTerm.slice(strlength - 1, searchTerm.length)
          // This is an important bit..
          const endCode =
            strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)

          query = query
            .where("name", ">=", searchTerm)
            .where("name", "<", endCode)
            .limit(10)

          //query = query.where("name", ">=", centreNameFilter)
          queryMods.push(`name >= ${templateNameFilter}`)
        }

        if (hideArchived === true) {
          query = query.where("archived", "==", false)
          queryMods.push("archived == false")
        }

        switch (token.claims.account_type) {
          case "centre":
            query = query.where("account_id", "==", token.claims.account_id)
            queryMods.push(`account_id == ${token.claims.account_id}`)
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        query = query.limit(rowsPerPage)

        console.log("%cqueryMods", "color: lightgreen", queryMods)

        dataServices
          .loadData("(Load checklist template grid)", query, false)
          .then(async (checklists) => {
            console.log("checklists", checklists)
            setChecklists(checklists)
          })
          .then(updatePageDocs())
          .catch((err) =>
            console.error(`${logId} Error loading checklist templates`, err)
          )
      })
  }, [accountId, pag, templateNameFilter, hideArchived])

  const handleRequestSort = (event, property) => {
    const isAsc = pag.orderBy === property && pag.order === "asc"

    const sortCol = headCells.find((cell) => cell.id === property)
    const sortSameCol = pag.orderBy === property

    // If we clicked on the same column to sort it, then find the other sort order for that column (there will only be
    // 1 or 2 array entries, being 'asc' or 'desc' in some order),
    // Otherwise it means we're sorting on a new column so get the 1st sort order from the 'direction' attribute, which
    // could be either 'asc' or 'desc'.
    const sortOrder = sortSameCol
      ? sortCol.direction.find((dir) => dir !== pag.order) || pag.order
      : sortCol.direction[0]

    const updatedPagination = {
      page: 0,
      order: sortOrder,
      orderBy: property,
    }

    dispatch(setCheckListTemplateGridPagination(updatedPagination))
    dispatch(setCheckListTemplateGridPageDocs([]))
    setDirection("")
  }

  const handleTemplateNameFieldChange = (event) => {
    const templateName = event.target.value
    setTemplateNameField(templateName)
  }

  const handleNewCheckList = (event) => {
    event.preventDefault()
    history.push("/checklistedit")
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }

      dispatch(setCheckListTemplateGridPagination(updatedPagination))
    }
  }

  const handleNameSearch = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault()
      setTemplateNameFilter(event.target.value)
    }
  }

  return (
    <>
      <FilterItems>
        <Controls.TextInput
          name="template_name"
          label="Name"
          type="search"
          value={templateNameField}
          onChange={handleTemplateNameFieldChange}
          onKeyDown={(event) => handleNameSearch(event)}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "15px",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                size="small"
                color="primary"
                checked={hideArchived}
                onChange={() => setHideArchived((curr) => !curr)}
              />
            }
            label={<Typography variant="caption">Hide Archived</Typography>}
          />
        </Box>
      </FilterItems>

      <Box>
        <Mobile width={675}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {checklists &&
              checklists.map((checklistTemplate) => (
                <CheckListCard
                  key={checklistTemplate.id}
                  checklist={checklistTemplate}
                />
              ))}
          </Box>
        </Mobile>
      </Box>

      <Box sx={styles.root}>
        <Desktop width={675}>
          <Box sx={{ marginTop: "20px" }}>
            <TableContainer>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="Checklist templates"
              >
                <CheckListTemplateGrid
                  numSelected={0}
                  selected={[]}
                  checklists={checklists}
                  order={pag.order}
                  orderBy={pag.orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={checklists.length}
                />
                <TableBody>
                  {checklists.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={false}
                        tabIndex={-1}
                        key={row.id}
                        selected={false}
                        onClick={(event) =>
                          history.push(`/CheckListEdit/${row.id}`)
                        }
                        sx={styles.tableRow}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <LinkButton
                            to={`/CheckListEdit/${row.id}`}
                            sx={{ justifyContent: "flex-start" }}
                          >
                            {row.name}
                          </LinkButton>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {row.archived ? "Archived" : "Active"}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {format(row.created.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Desktop>
        <Box sx={{ marginTop: "10px" }}>
          <NavButtons>
            <Controls.Button
              size="small"
              disabled={pag.page === 0}
              onClick={() => handlePageNav(-1)}
              text="Prev"
            />
            <Controls.Button
              size="small"
              disabled={checklists.length < rowsPerPage}
              onClick={() => handlePageNav(1)}
              text="Next"
            />
          </NavButtons>
        </Box>
        <PageNo pageNo={pag.page + 1} />
      </Box>

      <FabButton handleClick={handleNewCheckList} label="New Template" />
    </>
  )
}
