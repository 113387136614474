import React from "react"
import ProfileForm from "./ProfileForm"
import Header from "../components/Header"

function ProfilePage() {
  return (
    <Header title="My Profile">
      <ProfileForm />
    </Header>
  )
}

export default ProfilePage
