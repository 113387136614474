import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import { Box } from "@mui/material"
import firebase from "firebase/compat/app"
import { useHistory } from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useDispatch, useSelector } from "react-redux"
import { selectAccountGridPagination } from "../redux/selectors"
import { setAccountGridPagination } from "../redux/actions"
import { selectAccountGridPageDocs } from "../redux/selectors"
import { setAccountGridPageDocs } from "../redux/actions"
import { selectAccountGridSelectedItems } from "../redux/selectors"
import { setAccountGridSelectedItems } from "../redux/actions"
import { IconButton } from "@mui/material"
import { MenuItem } from "@mui/material"
import { Menu } from "@mui/material"
import { ListItemIcon } from "@mui/material"
import * as Roles from "../pages/services/roleServices"
import Controls from "./controls/Controls"
import { spacing } from "../pages/services/styleServices"
import PageNo from "./PageNo"
import NavButtons from "./NavButtons"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sortable: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
    sortable: true,
  },
  {
    id: "stripe_cust_id",
    numeric: false,
    disablePadding: true,
    label: "Stripe",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: true,
    label: "Created",
    sortable: true,
  },
  {
    id: "menu",
    numeric: false,
    disablePadding: true,
    label: "Menu",
    sortable: false,
  },
]

const AccountGrid = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    selected,
    accounts,
    rowCount,
    onRequestSort,
  } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const accountsSelectedCount = accounts.filter((item) =>
    selected.find((s) => item.id === s.id)
  ).length
  const allAccountsOnPageSelected =
    accountsSelectedCount === rowCount && rowCount > 0
  const someAccountsOnPageSelected =
    accountsSelectedCount > 0 && accountsSelectedCount < rowCount

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={someAccountsOnPageSelected || rowCount === 0}
            checked={allAccountsOnPageSelected}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all accounts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

AccountGrid.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: spacing(2),
  },
  table: {
    minWidth: 750,
  },
}

export default function EnhancedTable() {
  const selectedAccountItems = useSelector(selectAccountGridSelectedItems)
  const [selected, setSelected] = React.useState(selectedAccountItems)

  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = React.useState(true)

  const pag = useSelector(selectAccountGridPagination)

  const dispatch = useDispatch()

  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [accounts, setAccounts] = useState([])

  const [user, setUser] = useState()

  const [accountId, setAccountId] = useState()

  const [canSearchCentres, setCanSearchCentres] = useState(true)

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          const isJobAdmin = token.claims.roles.includes(Roles.JOB_ADMIN)
          const centreIds = token.claims.centre_ids

          const canSearch =
            isJobAdmin || (centreIds !== undefined && centreIds.length > 0)

          setCanSearchCentres(canSearch)
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      setUser(user)

      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setAccountId(token.claims.account_id)
        })
      } else {
      }
    })

    return unsub
  }, [])

  const pgDocs = useSelector(selectAccountGridPageDocs)

  const history = useHistory()

  const COLLECTION_NAME = "accounts"

  const [maxModified, setMaxModified] = useState()

  // Popup menu for accounts grid
  const [accountRowAnchorEls, setAccountRowAnchorEls] = useState([])

  // Listen for changes

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    const whereClauseLog = []

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())
      .orderBy("modified", "desc")

    whereClauseLog.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    let newMaxModified = null
    const unsub = query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            // trigger refresh
            newMaxModified = change.doc.data().modified
          }
        })
      },
      (error) => console.error("error getting account max modified", error)
    )

    if (newMaxModified !== null) {
      setMaxModified(newMaxModified)
    }

    return unsub
  }, [user, accountId])

  // Get most recent modified record

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    let query = db.collection(COLLECTION_NAME)

    query = query.orderBy("modified", "desc").limit(30)

    const unsub = query.onSnapshot(
      (querySnapshot) => {
        let newMaxModified = null
        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            newMaxModified = change.doc.data().modified
          }
        })
        if (newMaxModified !== null) {
          setMaxModified(newMaxModified)
        }
      },
      (error) =>
        console.error("error getting most recent modified account", error)
    )

    return unsub
  }, [user, accountId])

  const updatePageDocs = () => {
    if (accounts.length > 0 && direction !== "prev") {
      const newPageDocs = [...pgDocs]

      const newPageDoc = {
        first: accounts[0].doc,
        last: accounts[accounts.length - 1].doc,
      }

      newPageDocs[pag.page] = newPageDoc
      dispatch(setAccountGridPageDocs(newPageDocs))
    }
  }

  useEffect(() => {
    updatePageDocs(accounts)
  }, [accounts])

  const addUIAttributes = (items) => {
    if (items === undefined) {
      return []
    }

    return items.map((item, index) => {
      return {
        ...item,
        index: index,
      }
    })
  }

  // Load accounts

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    if (maxModified === undefined) {
      return
    }

    const queryMods = []

    let query = db.collection(COLLECTION_NAME)

    if (!getAuth().currentUser) {
      return
    }

    getAuth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        const isSystem = token.claims.system_role

        // Get current page of accounts

        if (isSystem) {
          query = query.orderBy(pag.orderBy, pag.order)
          queryMods.push("order by " + pag.orderBy + " " + pag.order)
          query = query.orderBy(
            firebase.firestore.FieldPath.documentId(),
            pag.order
          )
          queryMods.push("order by doc id " + pag.order)

          if (pag.page > 0 && direction !== "prev") {
            // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
            // But if not found, it just means this is the first time we've clicked Next through the collection
            if (pgDocs[pag.page - 1]) {
              const lastDoc = pgDocs[pag.page - 1].last
              query = query.startAfter(lastDoc)
              queryMods.push(
                "start after last doc on previous page " + lastDoc.id
              )
            }
          } else if (direction === "prev") {
            if (!pgDocs[pag.page]) {
              console.error("Cant find pagedocs for page", pag.page)
            }
            query = query.startAt(pgDocs[pag.page].first)
            queryMods.push(
              "start at 1st doc on page " + pgDocs[pag.page].first.id
            )
          }

          console.groupEnd()

          query = query.limit(rowsPerPage)

          if (canSearchCentres) {
            dataServices
              .loadData("(Load account grid)", query)
              .then((accounts) => {
                // Add 'index' attribute required to popup menu per account in the grid
                const accountsWithIndex = addUIAttributes(accounts)
                setAccounts(accountsWithIndex)

                updatePageDocs()
              })
          } else {
            setAccounts([])
          }

          console.groupEnd()
        }
      })
  }, [accountId, user, maxModified, pag])

  // const applyAccounts = (accounts) => {
  //     setAccounts(accounts)
  // }

  const handleRequestSort = (event, property) => {
    const isAsc = pag.orderBy === property && pag.order === "asc"

    const updatedPagination = {
      page: 0,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }

    dispatch(setAccountGridPagination(updatedPagination))
    dispatch(setAccountGridPageDocs([]))

    setDirection("")
  }

  // Select all on account grid

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allSelected = accounts.map((n) => {
        return {
          id: n.id,
          label: n.name,
        }
      })

      // Find newly selected ids

      const newSelected = [...selected]

      const newAdditions = allSelected.filter(
        (item) => selected.find((s) => item.id === s.id) === undefined
      )
      newAdditions.forEach((item) => newSelected.push(item))

      setSelected(newSelected)
      dispatch(setAccountGridSelectedItems(newSelected))
    } else {
      // Remove all accounts from selected
      const allDeselected = accounts.map((n) => {
        return {
          id: n.id,
          label: n.label,
        }
      })
      const newSelected = selected.filter(
        (item) => allDeselected.find((s) => item.id === s.id) === undefined
      )
      setSelected(newSelected)
      dispatch(setAccountGridSelectedItems(newSelected))
    }
  }

  const deselectAccount = (id) => {
    const existing = selected.find((item) => item.id === id)
    if (existing) {
      const newSelected = selected.filter((item) => item.id !== id)
      setSelected(newSelected)
      dispatch(setAccountGridSelectedItems(newSelected))
    }
  }

  // Blanks out the array of element references used for popup menu targets / where to show popup

  const createNullArray = (length) => new Array(length).fill(null)

  const getMaxIndexValue = (arr) => {
    // It's important we return -1 if the array is empty, because the caller
    // will add 1 to determine the size of a HTML elements array to create
    // i.e. -1 + 1 = 0 sized array if arr.length === 0, which is correct.
    if (arr.length === 0) {
      return -1
    }

    const maxIndexItem = arr.reduce((max, item) =>
      max.index > item.index ? max : item
    )
    return maxIndexItem.index
  }

  const handleAccountMenuClose = () => {
    const arrSizeToCreate = getMaxIndexValue(accounts) + 1
    const anchorEls = createNullArray(arrSizeToCreate)
    setAccountRowAnchorEls(anchorEls)
  }

  const handleClickAccountMenu = (event, index) => {
    const arrSizeToCreate = getMaxIndexValue(accounts) + 1
    const anchorEls = createNullArray(arrSizeToCreate)
    anchorEls[index] = event.target
    setAccountRowAnchorEls(anchorEls)
  }

  const handleAccountMenuEdit = (accountId) => {
    history.push(`/billing/${accountId}`)
  }

  const handleClick = (event, label, id) => {
    if (event.target.checked) {
      const newSelected = [...selected]
      newSelected.push({
        id: id,
        label: label,
      })
      setSelected(newSelected)
      dispatch(setAccountGridSelectedItems(newSelected))
    } else {
      deselectAccount(id)
    }
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }

      dispatch(setAccountGridPagination(updatedPagination))
    }
  }

  const isSelected = (id) =>
    selected.find((item) => item.id === id) !== undefined

  return (
    <>
      <Box sx={styles.root}>
        <Box>
          <TableContainer>
            <Table
              sx={styles.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="Accounts"
            >
              <AccountGrid
                numSelected={selected.length}
                selected={selected}
                accounts={accounts}
                order={pag.order}
                orderBy={pag.orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={accounts.length}
              />
              <TableBody>
                {accounts.map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  const menuId = `account-menu-${row.id}`

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <Typography noWrap={true} variant="body2">
                          {row.name}
                        </Typography>
                      </TableCell>

                      <TableCell align="left" padding="none">
                        <Typography variant="body2" noWrap={true}>
                          {row.type}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography variant="body2" noWrap={true}>
                          {row.stripe_cust_id && row.stripe_cust_id !== ""
                            ? "Y"
                            : "N"}
                        </Typography>
                      </TableCell>

                      <TableCell align="left" padding="none">
                        <Typography variant="body2" noWrap={true}>
                          {format(row.created.toDate(), "dd/MM/yy")}
                        </Typography>
                      </TableCell>

                      <TableCell padding="none">
                        <IconButton
                          aria-label="more"
                          aria-controls={menuId}
                          aria-haspopup="true"
                          onClick={(event) =>
                            handleClickAccountMenu(event, row.index)
                          }
                          size="large"
                        >
                          <MoreVertIcon />
                        </IconButton>

                        {accountRowAnchorEls[row.index] && (
                          <Menu
                            id={menuId}
                            anchorEl={accountRowAnchorEls[row.index]}
                            open={accountRowAnchorEls[row.index] !== null}
                            onClose={handleAccountMenuClose}
                          >
                            <MenuItem
                              onClick={() => handleAccountMenuEdit(row.id)}
                            >
                              <ListItemIcon>
                                <EditIcon fontSize="small" />
                              </ListItemIcon>
                              Billing
                            </MenuItem>
                          </Menu>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <NavButtons>
          <Controls.Button
            variant="contained"
            size="small"
            disabled={pag.page === 0}
            onClick={() => handlePageNav(-1)}
            text="Prev"
          />
          <Controls.Button
            variant="contained"
            size="small"
            disabled={accounts.length < rowsPerPage}
            onClick={() => handlePageNav(1)}
            text="Next"
          />
        </NavButtons>
        <PageNo pageNo={pag.page + 1} />
      </Box>
    </>
  )
}
