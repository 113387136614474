import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Typography from "@mui/material/Typography"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material"
import firebase from "firebase/compat/app"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectCheckListInstanceGridPagination } from "../redux/selectors"
import { setCheckListInstanceGridPagination } from "../redux/actions"
import { selectCheckListInstanceGridPageDocs } from "../redux/selectors"
import { setCheckListInstanceGridPageDocs } from "../redux/actions"
import Controls from "./controls/Controls"
import * as Roles from "../pages/services/roleServices"
import _ from "lodash"
import { Form } from "./useForm"
import { getInitialCheckListValues } from "../pages/services/checkListServices"
import PageNo from "./PageNo"
import NavButtons from "./NavButtons"
import { Desktop, Mobile } from "./WindowSizes"
import CheckListInstanceTile from "./CheckListInstanceTile"
import { BLANK_SELECT_VALUE } from "../redux/actionTypes"
import FabButton from "./controls/FabButton"
import FilterItems from "./FilterItems"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: false,
  },
  {
    id: "centre_id",
    numeric: false,
    disablePadding: false,
    label: "Centre",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created",
    sortable: false,
  },
  {
    id: "completed",
    numeric: false,
    disablePadding: false,
    label: "Completed",
    sortable: false,
  },
  {
    id: "score",
    numeric: false,
    disablePadding: false,
    label: "Score (%)",
    sortable: false,
  },
]

function CheckListInstanceGrid(props) {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order : headCell.direction[0]
                }
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

CheckListInstanceGrid.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
    marginTop: "20px",
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    "&:hover": {
      cursor: "hand",
    },
  },
  allocateDialog: {
    minWidth: 250,
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = useState(true)

  const pag = useSelector(selectCheckListInstanceGridPagination)

  const dispatch = useDispatch()

  const history = useHistory()

  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [centres, setCentres] = useState([])

  const [users, setUsers] = useState([])

  // Leave as undefined til actually known, so we don't issue a query and trigger a firestore permission error
  const [userAccountType, setUserAccountType] = useState()

  const [checkListInstances, setCheckListInstances] = useState([])

  const [checkLists, setCheckLists] = useState()

  const [showSelectTemplate, setShowSelectTemplate] = useState(false)

  const pgDocs = useSelector(selectCheckListInstanceGridPageDocs)

  const COLLECTION_NAME = "checklist_instances"

  const [maxModified, setMaxModified] = useState()

  const [accountId, setAccountId] = useState()

  const [claims, setClaims] = useState()

  const [userCentreIds, setUserCentreIds] = useState([])

  // If a new user logs into the same browser, we don't want the previous user's centre filter
  // remaining intact and allowing the user to see check list instances they're not supposed to have access to.

  const shouldClearCentreFilter = (token) => {
    const isJobAdmin = token.claims.roles.includes(Roles.JOB_ADMIN)
    const isAdmin = token.claims.roles.includes(Roles.ADMIN)
    const isSystem = token.claims.system_role === true

    if (!isJobAdmin && !isAdmin && !isSystem) {
      // Clear the current centre filter if it is not in their allowed list of centres
      return (
        token.claims.centre_ids.length > 0 &&
        !token.claims.centre_ids.includes(pag.centre_id)
      )
    }
    return false
  }

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          if (shouldClearCentreFilter(token)) {
            setCentreFilterValue(null)
          }

          setClaims(token.claims)
          setAccountId(token.claims.account_id)
          setUserAccountType(token.claims.account_type)
          setUserCentreIds(token.claims.centre_ids)

          if (token.claims.roles.includes(Roles.JOB_ADMIN)) {
          } else {
            if (token.claims.centre_ids.length === 1) {
              setCentreFilterValue(token.claims.centre_ids[0])
            }
          }
        })
      }
    })

    return unsub
  }, [])

  // We've split out this function since it can be called from the UI if the user
  // selects a centre from the combobox, or if the user only has 1 centre allocated
  // we automatically set the centre value.
  const setCentreFilterValue = (centreId) => {
    // Check if the centre changed
    if (centreId === pag.centre_id) {
      return
    }

    const updatedPagination = {
      ...pag,
      page: 0,
      centre_id: centreId,
    }

    setDirection("")
    dispatch(setCheckListInstanceGridPageDocs([]))
    dispatch(setCheckListInstanceGridPagination(updatedPagination))
  }

  const setSelectedChecklist = (checklistTemplateId) => {
    const updatedPagination = {
      ...pag,
      page: 0,
      checklist_template_id: checklistTemplateId,
    }

    setDirection("")
    dispatch(setCheckListInstanceGridPageDocs([]))
    dispatch(setCheckListInstanceGridPagination(updatedPagination))
  }

  const handleCentreChange = (event) => {
    // if we click the 'x' icon in the drop down we get null instead of '' for the event.target.value
    const value = event.target.value === null ? "" : event.target.value

    setCentreFilterValue(value)
  }

  useEffect(() => {
    if (accountId) {
      db.collection("checklists")
        .where("account_id", "==", accountId)
        .where("archived", "==", false)
        .get()
        .then((querySnapshot) => {
          const checkLists = []
          querySnapshot.forEach((doc) => {
            checkLists.push({ id: doc.id, ...doc.data() })
          })
          setCheckLists(checkLists)
        })
    }
  }, [accountId])

  // Listen for changes

  useEffect(() => {
    if (claims === undefined) {
      return
    }

    const whereClauseLog = []

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())

    whereClauseLog.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    query = query.where("account_id", "==", claims.account_id)
    whereClauseLog.push("account_id == " + claims.account_id)

    let newMaxModified = null
    const unsub = query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            // trigger refresh
            newMaxModified = change.doc.data().modified
          }
        })
      },
      (error) =>
        console.error("error listening for checklist instance changes", error)
    )

    if (newMaxModified !== null) {
      setMaxModified(newMaxModified)
    }

    return unsub // gets called on unmount
  }, [claims])

  // Get most recent modified record

  useEffect(() => {
    if (accountId === undefined) {
      return
    }

    let query = db.collection(COLLECTION_NAME)

    query = query.where("account_id", "==", accountId)
    query = query.orderBy("modified", "desc").limit(30)
    const unsub = query.onSnapshot(
      (querySnapshot) => {
        let newMaxModified = null
        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxModified === null ||
            change.doc.data().modified.seconds > newMaxModified.seconds
          ) {
            newMaxModified = change.doc.data().modified
          }
        })
        if (newMaxModified !== null) {
          setMaxModified(newMaxModified)
        }
      },
      (error) =>
        console.error("error listening for checklist instance changes", error)
    )

    return unsub
  }, [accountId])

  // Check if the user is able to see any check list instance information based on their roles and centres assigned
  // const isCheckListInstancesViewable = (isJobAdmin, centreIds) => {
  //     return isJobAdmin || (centreIds !== undefined && centreIds.length > 0)
  // }

  const updatePageDocs = () => {
    if (checkListInstances.length > 0 && direction !== "prev") {
      const newPageDocs = [...pgDocs]

      const newPageDoc = {
        first: checkListInstances[0].doc,
        last: checkListInstances[checkListInstances.length - 1].doc,
      }

      newPageDocs[pag.page] = newPageDoc
      dispatch(setCheckListInstanceGridPageDocs(newPageDocs))
    }
  }

  const distinct = (value, index, self) => {
    return self.indexOf(value) === index
  }

  useEffect(() => {
    updatePageDocs(checkListInstances)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkListInstances])

  // Lookup and cache the labels for centres

  useEffect(() => {
    const centreIds = checkListInstances
      .map((checkListInstance) => checkListInstance.centre_id)
      .filter(distinct)
      .filter((item) => item !== "" && item !== null && item !== undefined)

    if (centreIds.length === 0) {
      return
    }

    // See if centres already contain the required keys
    const loadedIds = centres.map((centre) => centre.id)

    const containsAll = centreIds.every((id) => loadedIds.includes(id))

    if (!containsAll) {
      const missingCentreIds = centreIds.filter((id) => !loadedIds.includes(id))

      if (missingCentreIds.length > 0) {
        dataServices
          .getCentresByIdChunks(missingCentreIds)
          .then((extraCentres) => {
            const newCentres = [...centres, ...extraCentres]
            setCentres(newCentres)
          })
      }
    }
  }, [checkListInstances])

  // Cache users for checklists

  useEffect(() => {
    if (checkListInstances.length > 0 && claims) {
      const userIds = checkListInstances
        .map((checkListInstance) => checkListInstance.user_id)
        .filter((id) => id)
        .filter(distinct)

      if (userIds.length === 0) {
        return
      }

      const loadedIds = users.map((user) => user.id)

      const containsAll = userIds.every((id) => loadedIds.includes(id))

      if (!containsAll) {
        const missingUserIds = userIds.filter((id) => !loadedIds.includes(id))

        if (missingUserIds.length > 0) {
          dataServices
            .getUsersByIdChunks(accountId, missingUserIds)
            .then((extraUsers) => {
              const newUsers = [...users, ...extraUsers]
              setUsers(newUsers)
            })
        }
      }
    }
  }, [checkListInstances, claims])

  // Load check list instances

  const [reloadState, setReloadState] = useState({})

  useEffect(() => {
    const logId = "[CHECK LIST INSTANCE GRID > LOAD CHECK LIST INSTANCES]"

    if (maxModified === undefined || claims === undefined) {
      return
    }

    const newReloadState = {
      accountId: claims.account_id,
      maxModified,
      pag,
      userCentreIds,
    }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    setReloadState(newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    const queryMods = []

    let query = db.collection(COLLECTION_NAME)

    const isJobAdmin = claims.roles.includes(Roles.JOB_ADMIN)

    // See if centre filter is set
    if (pag.centre_id !== "") {
      if (
        claims.centre_ids.length === 0 ||
        claims.centre_ids.includes(pag.centre_id)
      ) {
        query = query.where("centre_id", "==", pag.centre_id)
        queryMods.push(`centre_id == '${pag.centre_id}'`)
      } else {
        console.log(
          `${logId} %ccentreId not in user's centres`,
          "color:pink",
          pag.centre_id
        )
        setCheckListInstances([])
        return
      }
    }

    if (claims.centre_ids.length > 0) {
      // If single centre selected, filter by that center
      if (!isJobAdmin) {
        query = query.where("centre_id", "in", claims.centre_ids)
        queryMods.push(
          `filter by centres [${claims.centre_ids
            .map((id) => `'${id}'`)
            .join(", ")}]`
        )
        console.log(
          `${logId} limit access to user's centres`,
          claims.centre_ids
        )
      } else {
        console.log(`${logId} job admin user can see all centres`)
      }
    }

    // Get current page of check list instances

    query = query.orderBy(pag.orderBy, pag.order)

    queryMods.push("order by " + pag.orderBy + " " + pag.order)
    query = query.orderBy(firebase.firestore.FieldPath.documentId(), pag.order)
    queryMods.push("order by doc id " + pag.order)

    if (pag.page > 0 && direction !== "prev") {
      // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
      // But if not found, it just means this is the first time we've clicked Next through the collection
      if (pgDocs[pag.page - 1]) {
        const lastDoc = pgDocs[pag.page - 1].last
        query = query.startAfter(lastDoc)
        queryMods.push("start after last doc on previous page " + lastDoc.id)
      }
    } else if (direction === "prev") {
      if (!pgDocs[pag.page]) {
        console.error("Cant find pagedocs for page", pag.page)
      }
      query = query.startAt(pgDocs[pag.page].first)
      queryMods.push("start at 1st doc on page " + pgDocs[pag.page].first.id)
    }

    query = query.where("account_id", "==", claims.account_id)
    queryMods.push(`account_id == ${claims.account_id}`)

    if (pag.checklist_template_id !== BLANK_SELECT_VALUE) {
      query = query.where("check_list_id", "==", pag.checklist_template_id)
      queryMods.push(`check_list_id == ${pag.checklist_template_id}`)
    }

    console.log(`${logId} %cqueryMods`, "color:yellow", queryMods)

    query = query.limit(rowsPerPage)

    //if (isCheckListInstancesViewable(isJobAdmin, userCentreIds)) {
    dataServices
      .loadData("(Load check list instances grid)", query, false)
      .then((checkListInstances) => {
        setCheckListInstances(checkListInstances)
      })
      .then(updatePageDocs())
      .catch((err) =>
        console.error(`${logId} Error loading check list instances`, err)
      )
  }, [claims, maxModified, pag, userCentreIds])

  const handleRequestSort = (event, property) => {
    const isAsc = pag.orderBy === property && pag.order === "asc"

    const sortCol = headCells.find((cell) => cell.id === property)
    const sortSameCol = pag.orderBy === property

    // If we clicked on the same column to sort it, then find the other sort order for that column (there will only be
    // 1 or 2 array entries, being 'asc' or 'desc' in some order),
    // Otherwise it means we're sorting on a new column so get the 1st sort order from the 'direction' attribute, which
    // could be either 'asc' or 'desc'.
    const sortOrder = sortSameCol
      ? sortCol.direction.find((dir) => dir !== pag.order) || pag.order
      : sortCol.direction[0]

    const updatedPagination = {
      ...pag,
      page: 0,
      order: sortOrder,
      orderBy: property,
    }

    dispatch(setCheckListInstanceGridPagination(updatedPagination))
    dispatch(setCheckListInstanceGridPageDocs([]))
    setDirection("")
  }

  const handleCreateCheckList = async (userId, centreId, checkListId) => {
    // Close dialog
    setShowSelectTemplate(false)

    const newCheckList = await getInitialCheckListValues(
      accountId,
      checkListId,
      userId
    )

    if (centreId) {
      newCheckList.centre_id = centreId
    }

    db.collection("checklist_instances")
      .add(newCheckList)
      .then((docRef) => {
        const id = docRef.id
        history.push(`/checklistinstanceedit/${id}`)
      })
  }

  const handleAllocateCheckList = (event) => {
    // Open dialog to select user and checklist template
    setShowSelectTemplate(true)
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }

      dispatch(setCheckListInstanceGridPagination(updatedPagination))
    }
  }

  const handleClick = (event, label, id) => {
    history.push(`/checklistinstanceedit/${id}`)
  }

  return (
    <>
      <SelectCheckListTemplateDialog
        open={showSelectTemplate}
        handleClose={() => setShowSelectTemplate(false)}
        handleCreateCheckList={handleCreateCheckList}
        accountId={accountId}
      />

      <FilterItems>
        {userAccountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <>
            {userCentreIds && (
              <Box sx={{ width: "280px" }}>
                <Controls.CentreCombobox
                  name="centre_id"
                  label="Centre"
                  value={pag.centre_id}
                  accountId={accountId}
                  readonly={false}
                  onChange={handleCentreChange}
                  centreIdsFilter={userCentreIds}
                />
              </Box>
            )}
            {checkLists && (
              <Box sx={{ width: "280px" }}>
                <Controls.Select
                  name="Checklist"
                  label="Checklist"
                  noneValue={BLANK_SELECT_VALUE}
                  noneLabel="All"
                  value={pag.checklist_template_id}
                  onChange={(event) => setSelectedChecklist(event.target.value)}
                  options={checkLists.map((cl) => ({
                    id: cl.id,
                    title: cl.name,
                  }))}
                />
              </Box>
            )}
          </>
        )}
      </FilterItems>

      <Box>
        <Mobile width={675}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {checkListInstances?.map((checkListInstance) => (
              <CheckListInstanceTile
                key={checkListInstance.id}
                checkListInstance={checkListInstance}
                centres={centres}
                users={users}
                handleEditCheckList={(event) => {
                  handleClick(
                    event,
                    checkListInstance.name,
                    checkListInstance.id
                  )
                }}
              />
            ))}
          </Box>
        </Mobile>
      </Box>

      <Box sx={styles.root}>
        <Desktop width={675}>
          <Box>
            <TableContainer>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="Checklist Instances"
              >
                <CheckListInstanceGrid
                  checkListInstances={checkListInstances}
                  order={pag.order}
                  orderBy={pag.orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={checkListInstances.length}
                />
                <TableBody>
                  {checkListInstances.map((row, index) => {
                    let centreName = ""
                    if (row.centre_id !== "") {
                      const centre = centres.find(
                        (centre) => centre.id === row.centre_id
                      )
                      if (centre) {
                        centreName = centre.short_name || centre.name
                      }
                    }

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.label, row.id)
                        }
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        sx={styles.tableRow}
                      >
                        <TableCell align="left">
                          {row.check_list_name}
                        </TableCell>
                        <TableCell align="left">{centreName}</TableCell>

                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {format(row.created.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {row.completed &&
                              format(row.completed.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2" noWrap={true}>
                            {row.score && Math.round(row.score * 100)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Desktop>
        <Box sx={{ marginTop: "10px" }}>
          <NavButtons>
            <Controls.Button
              size="small"
              disabled={pag.page === 0}
              onClick={() => handlePageNav(-1)}
              text="Prev"
            />
            <Controls.Button
              size="small"
              disabled={checkListInstances.length < rowsPerPage}
              onClick={() => handlePageNav(1)}
              text="Next"
            />
          </NavButtons>
        </Box>
        <PageNo pageNo={pag.page + 1} />
      </Box>

      <FabButton
        handleClick={handleAllocateCheckList}
        label={"New Inspection"}
      />

      <FabButton
        handleClick={() => {
          history.push("/checklistedit")
        }}
        label={"New Template"}
        rightOffset={160}
      />
    </>
  )
}

const SelectCheckListTemplateDialog = (props) => {
  const { open, handleClose, handleCreateCheckList, accountId } = props

  const [values, setValues] = useState({ centre_id: "", user_id: "" })

  const [checkLists, setCheckLists] = useState()

  const [selectedIndex, setSelectedIndex] = useState(-1)

  const [userRoles, setUserRoles] = useState()

  const [userId, setUserId] = useState()

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          setUserId(user.uid)
          setUserRoles(token.claims.roles)

          const newValues = {
            ...values,
            user_id: user.uid,
          }
          setValues(newValues)
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    if (accountId && userRoles) {
      let query = db
        .collection("checklists")
        .where("account_id", "==", accountId)
        .where("archived", "==", false)

      if (!userRoles.includes(Roles.ADMIN)) {
        query = query.where("users", "array-contains", userId)
      }

      dataServices
        .loadData({}, query, false)
        .then((checkLists) =>
          setCheckLists(checkLists.sort((a, b) => a.name.localeCompare(b.name)))
        )
    }
  }, [accountId, userRoles])

  const handleOK = (event) => {
    event.preventDefault()
    handleCreateCheckList(
      values.user_id,
      values.centre_id,
      checkLists[selectedIndex].id
    )
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    const newValues = {
      ...values,
      [name]: value || "",
    }
    setValues(newValues)
  }

  return (
    <Dialog open={open}>
      <Form>
        <DialogTitle>Select Centre and Checklist</DialogTitle>
        <DialogContent sx={styles.allocateDialog}>
          <Grid container direction="column">
            <Grid item>
              <Controls.CentreCombobox
                name="centre_id"
                label="Select Centre"
                readonly={false}
                value={values.centre_id}
                accountId={accountId}
                onChange={handleChange}
              />
            </Grid>
            <Grid item style={{ marginTop: "15px" }}>
              <Typography variant="caption" color="textSecondary">
                Select Checklist
              </Typography>
            </Grid>
            <Grid item>
              {checkLists && (
                <List dense>
                  {checkLists.map((checkList, index) => (
                    <ListItem
                      button
                      key={checkList.id}
                      selected={selectedIndex === index}
                      onClick={() => setSelectedIndex(index)}
                    >
                      <ListItemText>{checkList.name}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" gap={2} sx={{ mr: "10px" }}>
            <Controls.Button text="Cancel" onClick={handleClose} />
            <Controls.Button
              text="OK"
              type="submit"
              onClick={handleOK}
              disabled={!values.centre_id || selectedIndex === -1}
            />
          </Stack>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
