import React from "react"
import { Badge, Tooltip, Typography } from "@mui/material"
import * as jobServices from "../../pages/services/jobServices"
import StyledChip from "./StyledChip"

const getChipProps = (status, theme) => {
    return {
        // backgroundColor: jobServices.statusColors[status],
        // color: theme.palette.getContrastText(jobServices.statusColors[status]),
        color: jobServices.statusColors[status],
        borderColor: jobServices.statusColors[status],
        borderRadius: 5,
        // "&&:hover": {
        //     backgroundColor: jobServices.statusColors[status],
        //     color: theme.palette.getContrastText(jobServices.statusColors[status]),
        // },
    }
}

function JobStatusChip(props) {
    const { status, size = "small", selected = true, showBadge = false, tooltip, ...other } = props

    const label = <Typography variant="caption" component='span'>{jobServices.jobStatusLabels[status]}</Typography>

    const wrapBadge = (wrap, child) => {
        if (wrap) {
            return (
                <Badge color="error" variant="dot">
                    {child}
                </Badge>
            )
        }
        return child
    }

    const wrapTooltip = (tooltip, child) => {
        if (tooltip) {
            return (
                <Tooltip title={tooltip} placement="top" component='span'>
                    {child}
                </Tooltip>
            )
        }
        return child
    }

    return wrapTooltip(
        tooltip,
        wrapBadge(
            showBadge,
            <StyledChip
                size={size}
                label={label}
                status={selected ? status : "deselected"}
                getChipProps={getChipProps}
                variant="outlined"
                {...other}
            />
        )
    )
}

export default JobStatusChip
