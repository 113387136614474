import React, { useId } from "react"
import { Box } from "@mui/material"
import CheckListPrintForm from "../components/CheckListPrintForm"

const CheckListPrint = (props) => {
  return (
    <Box padding={0}>
      <CheckListPrintForm {...props} />
    </Box>
  )
}

export default CheckListPrint
