import { MenuItem, Select } from "@mui/material"
import * as UIConstants from "./controls/UIConstants"

const DashboardWidgetGroupByFilter = ({ groupByFilter, setGroupByFilter }) => {
  return (
    <Select
      labelId="group-by-fiter-label"
      id="groupbyfilter"
      value={groupByFilter}
      variant={UIConstants.STANDARD_INPUT_VARIANT}
      size={UIConstants.STANDARD_INPUT_SIZE}
      label="Group By Filter"
      onChange={(event) => setGroupByFilter(event.target.value)}
    >
      <MenuItem value={""}>None</MenuItem>
      <MenuItem value={"top"}>Top</MenuItem>
      <MenuItem value={"bottom"}>Bottom</MenuItem>
      <MenuItem value={"range"}>Range</MenuItem>
    </Select>
  )
}

export default DashboardWidgetGroupByFilter
