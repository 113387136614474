import React, { Fragment, useState } from "react"
import Header from "../components/Header"
import { Paper } from "@mui/material"
import WorkOrderJobs from "../components/WorkOrderJobs"
import { useId } from "./useId"
import WorkOrderHeader from "../components/WorkOrderHeader"
import { spacing } from "./services/styleServices"

const styles = {
  outerPaper: {
    margin: spacing(0),
    padding: spacing(0),
  },
  innerPaper: {
    margin: spacing(1),
    padding: spacing(1),
  },
  jobsPaper: {
    marginTop: spacing(2),
    marginLeft: spacing(0),
    padding: spacing(0),
  },
}

const WorkOrderSummary = (props) => {
  const [title, setTitle] = useState("Edit Work Order")

  const { id } = useId(props)

  return (
    <>
      <Header title={title}>
        <Paper sx={styles.outerPaper} elevation={0}>
          <Paper sx={styles.innerPaper} elevation={0}>
            <WorkOrderHeader props={props} id={id}>
              <Paper sx={styles.jobsPaper} elevation={0}>
                <WorkOrderJobs workOrderId={id} setTitle={setTitle} />
              </Paper>
            </WorkOrderHeader>
          </Paper>
        </Paper>
      </Header>
    </>
  )
}

export default WorkOrderSummary
