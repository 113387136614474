import React, { Fragment, useEffect, useState, useRef } from "react"
import Controls from "../components/controls/Controls"
import { useForm, Form } from "../components/useForm"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import db from "../Firestore"
import { useSnackbar } from "notistack"
import * as Roles from "../pages/services/roleServices"
import * as icons from "../icons"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import ProgressBackdrop from "../components/ProgressBackdrop"
import QRCode from "qrcode"
import { QrCode2Outlined } from "@mui/icons-material"
import {
  Alert,
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import { spacing } from "../pages/services/styleServices"
import NavButtons from "../components/NavButtons"
import { getAuth, onAuthStateChanged } from "firebase/auth"

function ProfileForm() {
  const initialValues = {
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    phone: "",
  }

  const [user, setUser] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const { values, setValues, handleInputChange } = useForm(initialValues)

  const [roles, setRoles] = useState([])

  const [isQRCodeEnabled, setQRCodeEnabled] = useState(false)

  const [isShowProgress, setShowProgress] = useState(false)

  const [isShowQRCode, setShowQRCode] = useState(false)

  // For Google.com email addresses we don't need to allow the user to use a QR code, since Google would
  // handle that if the user had turned it on.
  // QR code enablement is only required for when the app handles login/password
  const [isPasswordProvider, setPasswordProvider] = useState(false)

  const [isGoogleProvider, setGoogleProvider] = useState(false)

  const [qrCodeUrl, setQrCodeUrl] = useState("")

  const imgRef = useRef()

  const BLANK_IMAGE = "/blank.png"

  const [accountType, setAccountType] = useState()

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      setUser(user)
      if (user) {
        user
          .getIdTokenResult()
          .then((token) => {
            setRoles(token.claims.roles)
            setAccountType(token.claims.account_type)
          })
          .then(() => {
            db.collection("users")
              .doc(user.uid)
              .get()
              .then((docRef) => {
                const userRec = docRef.data()

                setValues(userRec)
              })
          })

        cloudFunctions
          .getUserAuthenticationMethods({ email: user.email })
          .then((result) => {
            setPasswordProvider(result.data.providers.includes("password"))
            setGoogleProvider(result.data.providers.includes("google.com"))
            setQRCodeEnabled(result.data.isQrCodeSecretDefined)

            if (result.data.isQrCodeSecretDefined) {
              handleCreateTotpURI(user.email)
            }
          })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    if (imgRef && imgRef.current) {
      if (isShowQRCode && isPasswordProvider && qrCodeUrl !== "") {
        imgRef.current.src = qrCodeUrl
      } else {
        imgRef.current.src = BLANK_IMAGE
      }
    }
  }, [imgRef, isShowQRCode, isPasswordProvider, qrCodeUrl])

  const handleCreateTotpURI = (email) => {
    cloudFunctions.createTotpURI(email).then((result) => {
      QRCode.toDataURL(result.data.uri, { errorCorrectionLevel: "L" }).then(
        (url) => {
          setQrCodeUrl(url)
        }
      )
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter name", { variant: "error" })
    } else {
      await db
        .collection("users")
        .doc(user.uid)
        .update(
          {
            name: values.name,
            email: values.email,
            phone: values.phone,
          },
          { merge: true }
        )
        .then(enqueueSnackbar("Saved", { variant: "success" }))
        .catch(function (error) {
          console.error("Error:" + error)
          enqueueSnackbar("Error", { variant: "error " })
        })
    }
  }

  const getRoleNames = (roles) => {
    switch (accountType) {
      case Roles.ACCOUNT_TYPE_SUPPLIER:
        return "Supplier"

      case Roles.ACCOUNT_TYPE_CENTRE:
        return Roles.getRoleNames(roles).join(", ")

      default:
        return "Unknown"
    }
  }

  return (
    <Fragment>
      <ProgressBackdrop open={isShowProgress} />
      <Form>
        <Stack gap={2} sx={{ maxWidth: "400px" }}>
          <Controls.TextInput
            name="name"
            label="Name"
            value={values.name}
            onChange={handleInputChange}
          />

          <Controls.Readonly
            name="email"
            label="Email"
            value={values.email}
            icon={<EmailIcon />}
          />

          <Controls.TextInput
            name="phone"
            label="Phone"
            value={values.phone}
            icon={<PhoneIcon />}
            onChange={handleInputChange}
          />

          <Controls.Readonly
            name="roles"
            label="Roles"
            value={getRoleNames(roles)}
            helperText="These are set by your administrator"
          />

          {isPasswordProvider && (
            <Controls.Readonly
              name="qrCode"
              label="QR Code Enabled for Multi-Factor Authentication"
              value={
                isQRCodeEnabled
                  ? "Enabled"
                  : "Not Enabled - Select Enable QR Code to enable it"
              }
              helperText="If not enabled then you will use email based One Time Passwords"
            />
          )}

          {isGoogleProvider && (
            <Alert severity="info">
              To use Google Multi-Factor Authentication, please configure this
              in the Google Account you signed up with
            </Alert>
          )}

          {isQRCodeEnabled && (
            <Box sx={{ marginLeft: spacing(2) }}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    color="primary"
                    checked={isShowQRCode}
                    onChange={() => setShowQRCode((curr) => !curr)}
                  />
                }
                label={<Typography variant="caption">Show QR Code</Typography>}
              />
            </Box>
          )}

          <Box>
            <img alt="QRCode" ref={imgRef} src="/blank.png" />
          </Box>

          {isQRCodeEnabled && isShowQRCode && (
            <Alert severity="warning">
              Do not share this QR Code with anyone. If anyone was able to take
              a photo of this QR code then they could obtain your 6-digit code
            </Alert>
          )}
        </Stack>

        <Box sx={{ mt: "15px" }}>
          <NavButtons>
            <Controls.Button
              type="submit"
              text="Save"
              onClick={handleSubmit}
              endIcon={<icons.SaveIcon />}
            />

            {isPasswordProvider && !isQRCodeEnabled && (
              <Controls.Button
                text="Enable QR Code"
                onClick={() => {
                  setShowProgress(true)
                  cloudFunctions.generateTotpKey(user.email).then((result) => {
                    setQRCodeEnabled(true)
                    handleCreateTotpURI(user.email)
                    setShowQRCode(true)
                    setShowProgress(false)
                  })
                }}
                endIcon={<QrCode2Outlined />}
              />
            )}
          </NavButtons>
        </Box>
      </Form>
    </Fragment>
  )
}

export default ProfileForm
