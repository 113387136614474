import {
  Box,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material"
import Controls from "./controls/Controls"
import { useState } from "react"
import SupplierCombobox from "./controls/SupplierCombobox"
import { useEffect } from "react"
import * as colors from "@mui/material/colors"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import * as Roles from "../pages/services/roleServices"
import { format } from "date-fns"
import ResponsiveDialog from "./ResponsiveDialog"
import db from "../Firestore"
import EmailInvalidAlert from "./EmailInvalidAlert"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const QuoteDialog = ({
  open,
  onClose,
  quote,
  accountId,
  handleSaveQuote,
  handleSendRFQEmailWithoutPreview,
  jobTypes,
}) => {
  const [values, setValues] = useState(quote)

  const [selectedJobTypes, setSelectedJobTypes] = useState([])

  const [accountType, setAccountType] = useState()

  const [supplierEmail, setSupplierEmail] = useState("")

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setAccountType(token.claims.account_type)
        })
      }
    })

    return unsub
  }, [])

  const handleSupplierChange = (event) => {
    setValues((curr) => ({ ...curr, supplier_id: event.target.value }))
  }

  const handleClose = () => {
    setValues({})
    onClose()
  }

  const handleChange = (event) => {
    const newValues = { ...values, [event.target.name]: event.target.value }

    setValues(newValues)
  }

  useEffect(() => {
    if (values.supplier_id) {
      db.collection("suppliers")
        .doc(values.supplier_id)
        .get()
        .then((supplierDoc) => {
          const supplier = supplierDoc.data()
          setSupplierEmail(supplier.email)
        })
        .catch((err) => {
          console.log("getSupplier error", err)
        })
    }
  }, [values.supplier_id])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ResponsiveDialog open={open} handleClose={handleClose}>
        <DialogTitle>Quote</DialogTitle>
        <DialogContent sx={{ minWidth: "300px" }}>
          <Stack gap={2} sx={{ marginTop: "15px" }}>
            {!quote.id && (
              <Box sx={{ marginBottom: "15px" }}>
                <JobTypesFilter
                  jobTypes={jobTypes}
                  accountType={accountType}
                  selectedJobTypes={selectedJobTypes}
                  setSelectedJobTypes={setSelectedJobTypes}
                />
              </Box>
            )}
            {accountType === Roles.ACCOUNT_TYPE_CENTRE && (
              <Stack gap={2}>
                <SupplierCombobox
                  name="supplier_id"
                  label="Supplier"
                  value={values.supplier_id}
                  readonly={quote.id !== undefined}
                  accountId={accountId}
                  onChange={handleSupplierChange}
                  showChips={true}
                  filterOptions={(supplier) => {
                    if (selectedJobTypes.length === 0) {
                      return supplier
                    }

                    // Check if supplier.job_types contains any jobTypes
                    const foundJobType =
                      selectedJobTypes.length === 0 ||
                      supplier.job_types?.some((jobType) =>
                        selectedJobTypes.includes(jobType)
                      )

                    return foundJobType
                  }}
                />
                <Controls.Readonly
                  label="Supplier Email"
                  name="supplier_email"
                  value={supplierEmail}
                />
                <EmailInvalidAlert email={supplierEmail} />
              </Stack>
            )}

            {accountType === Roles.ACCOUNT_TYPE_CENTRE && (
              <Stack gap={1}>
                <Typography variant="caption" color="text.secondary">
                  Preferred Supplier
                </Typography>

                <Box
                  sx={{ display: "flex", flexDirection: "row", marginLeft: 0 }}
                >
                  <Checkbox
                    name="preferred"
                    checked={values.preferred}
                    onChange={(event) =>
                      handleChange({
                        target: {
                          name: "preferred",
                          value: event.target.checked,
                        },
                      })
                    }
                  />
                </Box>
              </Stack>
            )}

            {/* Only show total + terms for existing quotes */}
            {quote?.id && (
              <>
                <Controls.TextInput
                  label="Total"
                  name="total"
                  value={values.total}
                  onChange={handleChange}
                />
                <Controls.TextInput
                  label="Terms"
                  name="terms"
                  onChange={handleChange}
                  value={values.terms}
                />

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="caption" color="text.secondary">
                    Sent (d/M/Y)
                  </Typography>
                  {values.sent && format(values.sent.toDate(), "dd MMM yy hh:mm a")}
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="caption" color="text.secondary">
                    Received (d/M/Y)
                  </Typography>
                  {values.received &&
                    format(values.received.toDate(), "dd MMM yy hh:mm a")}
                  {!values.received && (
                    <Typography>No quote received from supplier</Typography>
                  )}
                </Box>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            sx={{ marginBottom: "5px", marginRight: "5px" }}
            direction="row"
            gap={1}
          >
            <Controls.Button onClick={handleClose} text="Cancel" />
            <Controls.Button
              onClick={() => handleSaveQuote(values)}
              text="Save"
            />

            {accountType === Roles.ACCOUNT_TYPE_CENTRE &&
              handleSendRFQEmailWithoutPreview !== undefined && (
                <Controls.Button
                  onClick={async () => {
                    const quoteId = await handleSaveQuote(values)

                    //TODO: add this function - called from 2 places, and in 1 place this function is not provided
                    if (handleSendRFQEmailWithoutPreview) {
                      db.collection("suppliers")
                        .doc(values.supplier_id)
                        .get()
                        .then((doc) => {
                          const supplier = doc.data()
                          handleSendRFQEmailWithoutPreview({
                            supplier,
                            quoteId: quoteId,
                          })
                        })
                    }
                  }}
                  text="Save and send"
                  disabled={!supplierEmail}
                />
              )}
          </Stack>
        </DialogActions>
      </ResponsiveDialog>
    </LocalizationProvider>
  )
}

const JobTypesFilter = ({
  jobTypes,
  accountType,
  selectedJobTypes,
  setSelectedJobTypes,
}) => {
  return (
    <Stack gap={1}>
      <Typography variant="caption" color="text.secondary">
        Select a Job Type to Filter Suppliers
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          flexWrap: "wrap",
        }}
      >
        {accountType === Roles.ACCOUNT_TYPE_CENTRE &&
          jobTypes.map((jobType) => (
            <Chip
              key={jobType}
              size="small"
              label={<Typography variant="caption">{jobType}</Typography>}
              sx={{
                backgroundColor: selectedJobTypes.includes(jobType)
                  ? colors.lightGreen[300]
                  : "#fff",
                border: `1px solid ${
                  selectedJobTypes.includes(jobType)
                    ? colors.lightGreen[300]
                    : colors.lightGreen[600]
                }`,
              }}
              onClick={() => {
                // Toggle the job type
                if (selectedJobTypes.includes(jobType)) {
                  setSelectedJobTypes((curr) =>
                    curr.filter((item) => item !== jobType)
                  )
                } else {
                  setSelectedJobTypes((curr) => [...curr, jobType])
                }
              }}
            />
          ))}
      </Box>
    </Stack>
  )
}

export default QuoteDialog
