import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { lighten } from "@mui/material/styles"
import { styled } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import {
  Typography,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material"
import firebase from "firebase/compat/app"
import { useDispatch, useSelector } from "react-redux"
import { setActionGridPagination } from "../redux/actions"
import { selectActionGridPageDocs } from "../redux/selectors"
import { selectActionGridPagination } from "../redux/selectors"
import { setActionGridPageDocs } from "../redux/actions"
import Controls from "./controls/Controls"
import _ from "lodash"
import LinkButton from "./controls/LinkButton"
import { spacing } from "../pages/services/styleServices"
import ActionDialog from "./ActionDialog"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import YesNo from "./YesNo"
import * as Roles from "../pages/services/roleServices"
import MultiSelectLabel from "./controls/MultiSelectLabel"
import * as actionServices from "../pages/services/actionServices"
import PageNo from "./PageNo"
import NavButtons from "./NavButtons"
import ActionStatusChip from "./controls/ActionStatusChip"
import TruncatedText from "./TruncatedText"
import { BLANK_SELECT_VALUE } from "../redux/actionTypes"
import { Desktop, Mobile } from "./WindowSizes"
import ActionTile from "./ActionTile"
import { uppercaseFirstLetter } from "../pages/services/formatting"
import {
  where,
  query,
  getCountFromServer,
  collection,
} from "firebase/firestore"
import useDocumentPagination from "./useDocumentPagination"
import FilterItems from "./FilterItems"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const headCells = [
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Action",
    sortable: false,
  },
  {
    id: "for",
    numeric: false,
    disablePadding: true,
    label: "For",
    sortable: false,
  },
  {
    id: "centre",
    numeric: false,
    disablePadding: false,
    label: "Centre",
    sortable: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Action Status",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: true,
    label: "Created",
    sortable: false,
  },
  {
    id: "created_by",
    numeric: false,
    disablePadding: false,
    label: "Creator",
    sortable: false,
  },
  {
    id: "modified",
    numeric: false,
    disablePadding: true,
    label: "Modified",
    sortable: false,
  },
  {
    id: "assigned_to",
    numeric: false,
    disablePadding: true,
    label: "Assigned To",
    sortable: false,
  },
]

const ActionGrid = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order : headCell.direction[0]
                }
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledToolbar = styled(Toolbar)(({ numselected, theme }) => {
  const result = {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  }

  if (numselected > 0) {
    result.color = theme.palette.secondary.main
    result.backgroundColor = lighten(theme.palette.secondary.light, 0.85)
  }

  return result
})

ActionGrid.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props

  return <StyledToolbar numselected={numSelected}></StyledToolbar>
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
  },
  actionGrid: {
    width: "100%",
    marginBottom: spacing(1),
    paddingLeft: spacing(0.5),
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    "&:hover": {
      cursor: "hand",
    },
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = useState(true)

  const pag = useSelector(selectActionGridPagination)

  const dispatch = useDispatch()

  const [rowsPerPage, setRowsPerPage] = useState(20)

  const [actions, setActions] = useState([])

  const [userCentreIds, setUserCentreIds] = useState([])

  const [centres, setCentres] = useState([])

  const [centreId, setCentreId] = useState()

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete Action",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const [currentUserEmail, setCurrentUserEmail] = useState("")

  const [isEdit, setEdit] = useState(false)

  const [selectedActionId, setSelectedActionId] = useState()

  const [actionCount, setActionCount] = useState()

  const pgDocs = useSelector(selectActionGridPageDocs)

  const { addPage } = useDocumentPagination(pgDocs)

  const COLLECTION_NAME = "actions"

  const [accountId, setAccountId] = useState()

  const [accountType, setAccountType] = useState()

  const [claims, setClaims] = useState()

  // Accounts that supplier can access
  const [supplierAccountIds, setSupplierAccountIds] = useState([])

  const [isJobAdmin, setIsJobAdmin] = useState(false)

  const updateUserCentreIdsIfChanged = (newUserCentreIds) => {
    if (!_.isEqual(newUserCentreIds.sort(), userCentreIds.sort())) {
      setUserCentreIds(newUserCentreIds)
    }
  }

  const selectedAction = useMemo(() => {
    if (selectedActionId && actions.length > 0) {
      return actions.find((action) => action.id === selectedActionId)
    }
  }, [selectedActionId, actions])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setAccountId(token.claims.account_id)
          setAccountType(token.claims.account_type)
          setClaims(token.claims)
          setCurrentUserEmail(token.claims.email)
          setSupplierAccountIds(token.claims.supplier_account_ids)
          setIsJobAdmin(token.claims.roles.includes(Roles.JOB_ADMIN))

          if (token.claims.roles.includes(Roles.JOB_ADMIN)) {
            updateUserCentreIdsIfChanged([]) // all centres
          } else {
            updateUserCentreIdsIfChanged(token.claims.centre_ids)

            if (token.claims.centre_ids.length === 1) {
              handleChangeCentreFilter(token.claims.centre_ids[0])
            }
          }
        })
      }
    })

    return unsub
  }, [])

  const getParentLabelProps = (actions, changedId) => {
    const existing = actions.find((action) => action.id === changedId)

    const existingParentProps = {}
    if (existing) {
      existingParentProps.parentLabel = existing.parentLabel
      existingParentProps.parentPath = existing.parentPath
    }

    return existingParentProps
  }

  const updateCentres = (actions) => {
    const centreIds = _.uniq(actions.map((action) => action.centre_id)).filter(
      (id) => id !== undefined
    )

    const missingCentreIds = centreIds.filter(
      (id) => centres.find((centre) => centre.id === id) === undefined
    )

    dataServices.getCentresByIdChunks(missingCentreIds).then((centreInfo) => {
      setCentres((curr) => [...curr, ...centreInfo])
    })
  }

  const getActionCount = ({ queryConstraints }) => {
    const countActions = query(collection(db, "actions"), ...queryConstraints)
    getCountFromServer(countActions).then((result) => {
      setActionCount(result.data().count)
    })
  }

  // Listen for changes

  useEffect(() => {
    if (
      accountId === undefined ||
      accountType === undefined ||
      pag === undefined ||
      claims === undefined
    ) {
      return
    }

    const { actionQuery, queryConstraints } = getActionsQuery({ claims, pag })

    getActionCount({ queryConstraints })

    const unsub = actionQuery.onSnapshot(
      (querySnapshot) => {
        updatePageDocs({ querySnapshot, pagination: pag, direction })

        querySnapshot.docChanges().forEach(async (change) => {
          // Get extra job data to display in the grid
          const jobs = await getJobsForActions({ actions: [change.doc.data()] })

          switch (change.type) {
            case "added":
              setActions((curr) => {
                const newActions = combineJobsIntoActions({
                  actions: [
                    ...curr.filter((action) => action.id !== change.doc.id),
                    {
                      id: change.doc.id,
                      ...change.doc.data(),
                      ...getParentLabelProps(curr, change.doc.id),
                    },
                  ],
                  jobs,
                })

                updateCentres(newActions)

                return newActions
              })
              break

            case "modified":
              setActions((curr) => {
                const updatedAction = {
                  id: change.doc.id,
                  ...change.doc.data(),
                  ...getParentLabelProps(curr, change.doc.id),
                }
                if (!updatedAction.modified) {
                  updatedAction.modified = dataServices.localTimestamp()
                }

                // Merge action into list in same place as it is now
                const combinedActions = curr.map((action) => {
                  if (action.id === change.doc.id) {
                    return updatedAction
                  } else {
                    return action
                  }
                })

                const newActions = combineJobsIntoActions({
                  actions: combinedActions,
                  jobs,
                })

                updateCentres(newActions)

                return newActions
              })
              break

            case "removed":
              setActions((curr) => {
                const newActions = [...curr]
                const index = newActions.findIndex(
                  (action) => action.id === change.doc.id
                )
                newActions.splice(index, 1)
                return newActions
              })
              break

            default:
              throw new Error("Unknown change type " + change.type)
          }
        })
      },
      (error) => console.error("error listening for action changes", error)
    )

    return unsub // gets called on unmount
  }, [accountId, accountType, pag, claims, supplierAccountIds])

  const updatePageDocs = ({ querySnapshot, pagination, direction }) => {
    if (querySnapshot.docs.length > 0 && direction !== "prev") {
      const newPageDocs = addPage({ querySnapshot, page: pagination.page })

      dispatch(setActionGridPageDocs(newPageDocs))
    }
  }

  const getJobsForActions = async ({ actions }) => {
    const jobPromises = actions
      .filter((action) => action.type === "job")
      .map(async (action) => {
        try {
          const doc = await db.collection("jobs").doc(action.parent_id).get()

          if (doc.exists) {
            return { id: doc.id, ...doc.data() }
          } else {
            console.log("No such document!")
          }
        } catch (err) {
          console.error("failed to load job " + action.parent_id, err)
        }
      })

    const jobs = (await Promise.all(jobPromises)).filter(
      (job) => job !== undefined
    )

    return jobs
  }

  const combineJobsIntoActions = ({ actions, jobs }) => {
    const actionsWithJobs = actions.map((action) => {
      if (action.type === "job") {
        const job = jobs.find((job) => job.id === action.parent_id)
        if (job) {
          return {
            ...action,
            parentLabel: job.label,
            parentPath: `/jobedit/${job.id}`,
          }
        }
      }
      return action
    })

    return actionsWithJobs
  }

  const getActionsQuery = ({ claims, pag }) => {
    const queryMods = []

    let actionQuery = db.collection(COLLECTION_NAME)

    const queryConstraints = []

    // Get current page of actions

    actionQuery = actionQuery.orderBy(pag.orderBy, pag.order)

    queryMods.push("order by " + pag.orderBy + " " + pag.order)
    actionQuery = actionQuery.orderBy(
      firebase.firestore.FieldPath.documentId(),
      pag.order
    )
    queryMods.push("order by doc id " + pag.order)

    if (pag.page > 0 && direction !== "prev") {
      // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
      // But if not found, it just means this is the first time we've clicked Next through the collection
      if (pgDocs[pag.page - 1]) {
        const lastDoc = pgDocs[pag.page - 1].lastDoc
        queryMods.push(`start after last doc on previous page ${lastDoc?.id}`)
        actionQuery = actionQuery.startAfter(lastDoc)
      }
    } else if (direction === "prev") {
      if (!pgDocs[pag.page]) {
        console.error("Can't find pagedocs for page", pag.page)
      }
      actionQuery = actionQuery.startAt(pgDocs[pag.page].firstDoc)
      queryMods.push(`start at 1st doc on page ${pgDocs[pag.page].firstDoc.id}`)
    }

    switch (claims.account_type) {
      case "centre":
        actionQuery = actionQuery.where("account_id", "==", claims.account_id)
        queryConstraints.push(where("account_id", "==", claims.account_id))
        queryMods.push(`account_id == ${claims.account_id}`)
        break

      case "supplier":
        actionQuery = actionQuery.where("supplier_account_id", "==", accountId)
        queryConstraints.push(where("supplier_account_id", "==", accountId))
        queryMods.push(`supplier_account_id == ${accountId}`)
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    switch (pag.personFilter) {
      case "created-by-me":
        actionQuery = actionQuery.where("created_by", "==", claims.email)
        queryConstraints.push(where("created_by", "==", claims.email))
        queryMods.push(`created_by == ${claims.email}`)
        break

      case "assigned-to-me":
        actionQuery = actionQuery.where("assigned_to", "==", claims.email)
        queryConstraints.push(where("assigned_to", "==", claims.email))
        queryMods.push(`assigned_to == ${claims.email}`)
        break

      default:
    }

    if (pag.centreId !== "") {
      actionQuery = actionQuery.where("centre_id", "==", pag.centreId)
      queryConstraints.push(where("centre_id", "==", pag.centreId))
      queryMods.push(`centre_id == ${pag.centreId}`)
    } else {
      if (claims.centre_ids.length > 0) {
        actionQuery = actionQuery.where("centre_id", "in", claims.centre_ids)
        queryConstraints.push(where("centre_id", "in", claims.centre_ids))
        queryMods.push(`centre_id in ${claims.centre_ids}`)
      }
    }

    if (pag.statusFilter !== BLANK_SELECT_VALUE) {
      actionQuery = actionQuery.where("status", "==", pag.statusFilter)
      queryConstraints.push(where("status", "==", pag.statusFilter))
      queryMods.push(`status == ${pag.statusFilter}`)
    }

    actionQuery = actionQuery.limit(rowsPerPage)

    return { actionQuery, queryConstraints }
  }

  const handleCentreChange = (event) => {
    // if we click the 'x' icon in the drop down we get null instead of '' for the event.target.value
    const value = event.target.value === null ? "" : event.target.value

    handleChangeCentreFilter(value)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = pag.orderBy === property && pag.order === "asc"

    const sortCol = headCells.find((cell) => cell.id === property)
    const sortSameCol = pag.orderBy === property

    // If we clicked on the same column to sort it, then find the other sort order for that column (there will only be
    // 1 or 2 array entries, being 'asc' or 'desc' in some order),
    // Otherwise it means we're sorting on a new column so get the 1st sort order from the 'direction' attribute, which
    // could be either 'asc' or 'desc'.
    const sortOrder = sortSameCol
      ? sortCol.direction.find((dir) => dir !== pag.order) || pag.order
      : sortCol.direction[0]

    const updatedPagination = {
      ...pag,
      page: 0,
      order: sortOrder,
      orderBy: property,
    }

    dispatch(setActionGridPagination(updatedPagination))

    dispatch(setActionGridPageDocs([]))

    setDirection("")
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }
      setActions([])
      dispatch(setActionGridPagination(updatedPagination))
    }
  }

  const handleDeleteConfirmed = (actionId) => {
    const newPromptConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newPromptConfig)

    actionServices.deleteAction(actionId)
  }

  const handlePromptConfirmDelete = (event, actionId) => {
    event.preventDefault()

    const newPromptConfig = {
      ...yesNoConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteConfirmed(actionId),
    }
    setYesNoConfig(newPromptConfig)
  }

  const handleChangeCentreFilter = (centreId) => {
    // Check if the centre changed
    if (centreId === pag.centreId) {
      return
    }

    setCentreId(centreId)

    const updatedPagination = {
      ...pag,
      page: 0,
      centreId: centreId,
    }

    setActions([])
    setDirection("")
    dispatch(setActionGridPagination(updatedPagination))
    dispatch(setActionGridPageDocs([]))
  }

  const handleChangePersonFilter = (value) => {
    const updatedPagination = {
      ...pag,
      page: 0,
      personFilter: value,
    }

    setActions([])
    dispatch(setActionGridPagination(updatedPagination))
    dispatch(setActionGridPageDocs([]))
  }

  const handleChangeStatusFilter = (value) => {
    const updatedPagination = {
      ...pag,
      page: 0,
      statusFilter: value,
    }

    setActions([])
    dispatch(setActionGridPagination(updatedPagination))
    dispatch(setActionGridPageDocs([]))
  }

  const centreLabel = (centre) => centre?.short_name || centre?.name

  return (
    <>
      {isEdit && selectedAction && selectedActionId && (
        <ActionDialog
          id={selectedActionId}
          parentId={selectedAction.parent_id}
          parentType={selectedAction.type}
          open={isEdit}
          setOpen={setEdit}
        />
      )}

      {yesNoConfig && <YesNo config={yesNoConfig} />}

      <FilterItems>
        {accountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <Box>
            <Controls.Select
              name="personType"
              label="Person"
              noneValue={BLANK_SELECT_VALUE}
              noneLabel="All"
              value={pag.personFilter}
              onChange={(event) => handleChangePersonFilter(event.target.value)}
              options={[
                { id: "created-by-me", title: "Created by me" },
                { id: "assigned-to-me", title: "Assigned to me" },
              ]}
            />
          </Box>
        )}

        {accountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <Box sx={{ width: "200px" }}>
            <Controls.CentreCombobox
              name="centre_id"
              label={
                <MultiSelectLabel
                  count={userCentreIds.length}
                  oneSelectedLabel="Centre"
                  multiSelectedLabel={`Centre (limit ${userCentreIds.length})`}
                />
              }
              value={centreId}
              accountId={accountId}
              readonly={false}
              onChange={handleCentreChange}
              centreIdsFilter={userCentreIds}
            />
          </Box>
        )}

        <Box>
          <Controls.Select
            name="statusFilter"
            label="Status"
            noneValue={BLANK_SELECT_VALUE}
            noneLabel="All"
            value={pag.statusFilter}
            onChange={(event) => handleChangeStatusFilter(event.target.value)}
            options={[
              { id: "open", title: "Open" },
              { id: "completed", title: "Completed" },
              { id: "closed", title: "Closed" },
            ]}
          />
        </Box>
      </FilterItems>

      <Box sx={{ marginBottom: "20px" }} />

      <Box>
        <Mobile width={675}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {actions &&
              actions.map((action) => (
                <ActionTile
                  key={action.id}
                  action={action}
                  centres={centres}
                  handleEditAction={(e) => {
                    setSelectedActionId(action.id)
                    setEdit(true)
                  }}
                  isSupplierViewing={
                    accountType === Roles.ACCOUNT_TYPE_SUPPLIER
                  }
                  currentUserEmail={currentUserEmail}
                  isJobAdmin={isJobAdmin}
                />
              ))}
          </Box>
        </Mobile>
      </Box>
      <Box sx={styles.root}>
        <Desktop width={675}>
          <Box sx={styles.actionGrid}>
            <TableContainer>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="Actions"
              >
                <ActionGrid
                  numSelected={0}
                  selected={[]}
                  actions={actions}
                  order={pag.order}
                  orderBy={pag.orderBy}
                  onSelectAllClick={() => {}}
                  onRequestSort={handleRequestSort}
                  rowCount={actions.length}
                />
                <TableBody>
                  {actions.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={false}
                        tabIndex={-1}
                        key={row.id}
                        selected={false}
                        onClick={(event) => {
                          setSelectedActionId(row.id)
                          setEdit(true)
                        }}
                        sx={styles.tableRow}
                      >
                        <TableCell align="left" padding="none">
                          <LinkButton
                            onClick={() => {
                              setSelectedActionId(row.id)
                              setEdit(true)
                            }}
                          >
                            <TruncatedText
                              variant="body2"
                              sx={{ width: "140px" }}
                            >
                              {row.description}
                            </TruncatedText>
                          </LinkButton>
                        </TableCell>

                        <TableCell align="left" padding="none">
                          {row.parentPath && (
                            <LinkButton to={row.parentPath}>
                              <TruncatedText
                                variant="body2"
                                sx={{ width: "100px" }}
                              >
                                {row.parentLabel}
                              </TruncatedText>
                            </LinkButton>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          <TruncatedText
                            variant="body2"
                            sx={{ width: "100px" }}
                          >
                            {row.centre_id &&
                              centreLabel(
                                centres.find((c) => c.id === row.centre_id)
                              )}
                          </TruncatedText>
                        </TableCell>

                        <TableCell align="left" padding="none">
                          <ActionStatusChip
                            status={row.status}
                            showBadge={actionServices.isShowActionBadge(
                              row,
                              currentUserEmail,
                              isJobAdmin
                            )}
                          />
                        </TableCell>

                        <TableCell align="left" padding="none">
                          <Typography variant="body2" noWrap={true}>
                            {format(row.created.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <TruncatedText
                            variant="body2"
                            sx={{ width: "120px" }}
                          >
                            {row.created_by}
                          </TruncatedText>
                        </TableCell>

                        <TableCell align="left" padding="none">
                          <Typography variant="body2" noWrap={true}>
                            {row.modified &&
                              format(row.modified.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" padding="none">
                          <TruncatedText
                            variant="body2"
                            sx={{ width: "120px" }}
                          >
                            {row.assigned_to}
                          </TruncatedText>
                        </TableCell>
                        <TableCell padding="none">
                          <RowMenu
                            action={row}
                            userEmail={currentUserEmail}
                            isJobAdmin={isJobAdmin}
                            isSupplierViewing={
                              accountType === Roles.ACCOUNT_TYPE_SUPPLIER
                            }
                            handleEdit={(e) => {
                              e.stopPropagation()
                              setSelectedActionId(row.id)
                              setEdit(true)
                            }}
                            handleDelete={(e) => {
                              e.stopPropagation()
                              handlePromptConfirmDelete(e, row.id)
                            }}
                            accountType={accountType}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <NavButtons>
            <Controls.Button
              size="small"
              disabled={pag.page === 0}
              onClick={() => handlePageNav(-1)}
              text="Prev"
            />
            <Controls.Button
              size="small"
              disabled={actions.length < rowsPerPage}
              onClick={() => handlePageNav(1)}
              text="Next"
            />
          </NavButtons>
          <PageNo pageNo={pag.page + 1} count={actionCount} type={"actions"} />
        </Desktop>
      </Box>
    </>
  )
}

const RowMenu = ({
  action,
  userEmail,
  isJobAdmin,
  isSupplierViewing,
  handleEdit,
  handleDelete,
  accountType,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const allowedStatusChanges = useMemo(() => {
    return actionServices.getAllowedActionStatusValues({
      action,
      userEmail,
      isJobAdmin,
      isSupplierViewing,
    })
  }, [action, userEmail, isJobAdmin, isSupplierViewing])

  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => {
          e.stopPropagation()
          setAnchorEl(null)
        }}
      >
        {allowedStatusChanges &&
          allowedStatusChanges.map((status) => (
            <MenuItem
              key={status}
              onClick={(e) => {
                e.stopPropagation()
                actionServices.updateActionStatus(action.id, action, status)
                setAnchorEl(null)
              }}
            >
              Mark as {uppercaseFirstLetter(status)}
            </MenuItem>
          ))}
        {accountType === Roles.ACCOUNT_TYPE_CENTRE && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              setAnchorEl(null)
              handleDelete(e)
            }}
          >
            Delete Action
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setAnchorEl(null)
            handleEdit(e)
          }}
        >
          Edit Action
        </MenuItem>
      </Menu>
    </>
  )
}
