import React from "react"
import Header from "../components/Header"
import WorkOrderGrid from "../components/WorkOrderGrid"

const WorkOrders = () => {
  return (
    <Header title="Work Orders">
      <WorkOrderGrid />
    </Header>
  )
}

export default WorkOrders
