import React, { useState, useEffect } from "react"
import { getAvailableActions } from "../workOrderStatus"
import { Divider, MenuItem } from "@mui/material"
import { Menu } from "@mui/material"
import { IconButton } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Tooltip } from "@mui/material"
import WorkOrderStatusOverview from "../WorkOrderStatusOverview"
import * as roleServices from "../../pages/services/roleServices"
import _ from "lodash"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const ChangeWorkOrderStatusButton = ({
  handleStatusChange,
  values,
  id,
  jobs,
}) => {
  // true => if a supplier is logged in and viewing this page. They only see their actions
  const [isSupplierViewing, setSupplierViewing] = useState(false)

  const [availableActions, setAvailableActions] = useState([])

  const [statusMenuItems, setStatusMenuItems] = useState([])

  const [userRoles, setUserRoles] = useState([])

  const [statusMenuEl, setStatusMenuEl] = useState(null)

  const isStatusMenuOpen = Boolean(statusMenuEl)

  const [isOverviewOpen, setOverviewOpen] = useState(false)

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          setUserRoles(token.claims.roles)
          setSupplierViewing(
            token.claims.account_type === roleServices.SUPPLIER
          )
        })
      }
    })

    return unsub
  }, [])

  const [recalcActionsState, setRecalcActionsState] = useState({})

  useEffect(() => {
    const newRecalcActionState = {
      userRoles,
      isSupplierViewing,
      status: values.status,
      id,
      jobs,
    }

    const isChanged = !_.isEqual(newRecalcActionState, recalcActionsState)

    setRecalcActionsState(newRecalcActionState)

    if (!isChanged && availableActions.length !== 0) {
      return
    }

    if (values) {
      const actions = getAvailableActions(
        userRoles,
        isSupplierViewing,
        values.status,
        id
      )

      setAvailableActions(actions)

      const menuItems = actions.map((action) => (
        <MenuItem
          key={action.label}
          onClick={() => {
            handleStatusMenuClose()
            handleStatusChange({ newStatus: action.to, jobs: jobs })
          }}
        >
          {action.label}
        </MenuItem>
      ))

      if (menuItems.length > 0) {
        menuItems.push(<Divider key="divider-1" />)
      }
      menuItems.push(
        <MenuItem
          key="overview"
          onClick={() => {
            handleStatusMenuClose()
            setOverviewOpen(true)
          }}
        >
          Choose ...
        </MenuItem>
      )

      if (menuItems.length === 0) {
        menuItems.push(
          <MenuItem key="none">No status changes available</MenuItem>
        )
      }

      setStatusMenuItems(menuItems)
    }
  }, [values, id, jobs, isSupplierViewing])

  const handleStatusMenuOpen = (event) => {
    setStatusMenuEl(event.currentTarget)
  }

  const handleStatusMenuClose = () => {
    setStatusMenuEl(null)
  }

  return (
    <>
      <WorkOrderStatusOverview
        workOrder={values}
        open={isOverviewOpen}
        setOpen={setOverviewOpen}
        handleStatusChange={handleStatusChange}
        availableActions={availableActions}
        jobs={jobs}
      />

      <Tooltip title="Change work order status">
        <IconButton onClick={handleStatusMenuOpen} size="large">
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={statusMenuEl}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        id={"status-menu"}
        keepMounted
        open={isStatusMenuOpen}
        onClose={handleStatusMenuClose}
      >
        {statusMenuItems}
      </Menu>
    </>
  )
}

export default ChangeWorkOrderStatusButton
