import React from "react"
import SupplierGrid from "../components/SupplierGrid"
import Header from "../components/Header"

const SupplierFind = () => {
  return (
    <Header title="Suppliers">
      <SupplierGrid />
    </Header>
  )
}

export default SupplierFind
