import React from "react"
import Grid from "@mui/material/Grid"
import { CardActions, IconButton, Tooltip, Box } from "@mui/material"
import Controls from "./controls/Controls"
import { Card } from "@mui/material"
import { CardContent } from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import * as colors from "@mui/material/colors"
import { spacing } from "../pages/services/styleServices"

const styles = {
  root: {
    maxWidth: 500,
  },
  card: {
    margin: spacing(1),
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
    width: "100%",
  },
  sectionAvatar: {
    backgroundColor: colors.green[300],
    width: 24,
    height: 24,
  },

  cardContent: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    paddingTop: "10px",
    paddingBottom: 0,
    marginRight: spacing(1),
    marginLeft: spacing(1),
  },
  cardActions: {
    margin: 0,
    paddingBottom: 0,
  },
}

const CheckListEditFormSection = ({
  section,
  handleMoveSectionUp,
  handleMoveSectionDown,
  setEditId,
  isEditing,
  // Change a single field value
  handleChange,
  handlePromptDeleteItem,
  isCheckListUsed,
}) => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setEditId(undefined)
    }
  }

  return (
    <>
      <Grid item>
        <Card sx={styles.card}>
          {isEditing && (
            <CardContent sx={styles.cardContent}>
              <Controls.TextInput
                autoFocus
                name="label"
                label="Section Label"
                type="search"
                value={section.label}
                disabled={isCheckListUsed}
                fullWidth
                onChange={(event) =>
                  handleChange(event, { section: section.seq })
                }
                onKeyPress={(event) => handleKeyPress(event)}
              />
            </CardContent>
          )}

          {!isCheckListUsed && isEditing && (
            <CardActions disableSpacing sx={styles.cardActions}>
              <Box sx={{ marginLeft: "auto" }}>
                {!isEditing && (
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      setEditId({ section: section.seq })
                    }}
                    size="large"
                  >
                    <Tooltip title="Edit Section">
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
                )}
                {isEditing && (
                  <IconButton
                    aria-label="accept"
                    onClick={(e) => {
                      e.stopPropagation()
                      console.log("clear editId")
                      setEditId(undefined)
                    }}
                    size="large"
                  >
                    <Tooltip title="Close">
                      <ExpandLessIcon />
                    </Tooltip>
                  </IconButton>
                )}
                <IconButton
                  aria-label="move section up"
                  onClick={() => handleMoveSectionUp(section.seq)}
                  size="large"
                >
                  <Tooltip title="Move this section up">
                    <ArrowUpwardIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  aria-label="move section down"
                  onClick={() => handleMoveSectionDown(section.seq)}
                  size="large"
                >
                  <Tooltip title="Move this section down">
                    <ArrowDownwardIcon />
                  </Tooltip>
                </IconButton>

                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    handlePromptDeleteItem({ section: section.seq })
                  }
                  size="large"
                >
                  <Tooltip title="Delete Section">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </Box>
            </CardActions>
          )}
        </Card>
      </Grid>
    </>
  )
}

export default CheckListEditFormSection
