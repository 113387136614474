import _ from "lodash"
import { parseTimestamp } from "./dateServices"
import * as dataServices from "./dataServices"
import * as fileServices from "./fileServices"
import * as colors from "@mui/material/colors"

const getName = (section, element) => {
  console.assert(section !== undefined, "Expecting section value")
  return `${section.seq}_${element.seq}`
}

const isTextComplete = (val) => val !== ""

const isDateComplete = (val) => val !== null

const isCheckComplete = (val) => val !== ""

const isSignatureComplete = (val) => !_.isEqual([], val)

const chipColors = {
  Y: colors.lightGreen[200],
  "N/A": colors.blue[200],
  N: colors.red[200],
}

const chipTooltips = {
  Y: "Positive answer",
  N: "Negative answer",
  "N/A": "N/A answer",
}

const completionFuncs = {
  text: isTextComplete,
  date: isDateComplete,
  check: isCheckComplete,
  signature: isSignatureComplete,
}

// Check if 1 or more check list values have been entered
const isAnyCheckListDataEntered = (checkList, checkListData) => {
  let isDataEntered = false

  checkList.sections.forEach((section) => {
    if (isDataEntered) {
      return
    }

    section.elements.forEach((element) => {
      if (isDataEntered) {
        return
      }

      const name = getName(section, element)

      const isCompleteFunc = completionFuncs[element.type]

      if (isCompleteFunc(checkListData[name])) {
        isDataEntered = true
      }
    })
  })

  return isDataEntered
}

const getInitialCheckListValues = async (accountId, checkListId, userId) => {
  const checkList = await dataServices.getCheckListById(checkListId)

  const initialValues = {
    account_id: accountId,
    check_list_id: checkListId,
    check_list_name: checkList.name,
    check_list_data: {},
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
    user_id: userId,
  }

  return initialValues
}

const getFileUrls = async (accountId, checkListInstanceId, fileNames) => {
  const urls = []

  const promises = []

  fileNames.forEach(async (fileName) => {
    promises.push(
      fileServices
        .getCheckListInstanceFileUrl(accountId, checkListInstanceId, fileName)
        .then((url) => {
          urls.push({ fileName, url })
        })
    )
  })

  await Promise.all(promises)

  return urls
}

// We have to store check list data in an object format, not nested arrays
// since Firestore can't do that
const convertCheckListDataFromFirestore = (checkList, checkListData) => {
  if (!checkList) {
    return
  }

  const keyValuePairs = _.flatten(
    checkList.sections.map((section) =>
      section.elements.map((element) => {
        let defaultValue

        const name = getName(section, element)

        switch (element.type) {
          case "text":
            defaultValue = checkListData[name] || ""
            break

          case "check":
            defaultValue = checkListData[name] || ""
            break

          case "date":
            defaultValue =
              checkListData[name] !== null
                ? parseTimestamp(checkListData[name])
                : parseTimestamp(dataServices.localTimestamp())
            break

          case "signature":
            // Get the signature data as we must store it in firestore, e.g. {0: [x,y|x,y|x,y|....], 1:[x,y|x,y|x,y|....], etc)
            // being a nested object, keyed on sequence number, and each value being a string of x/y co-ords
            // separated by the pipe (|) symbol.
            // We need to parse this into an array of arrays for rendering as a svg view of the signature
            if (!checkListData[name]) {
              defaultValue = []
            } else {
              const signatureObj = _.cloneDeep(checkListData[name])

              const lines = []
              Object.keys(signatureObj).forEach((key) => {
                if (signatureObj[key] !== "") {
                  const lineArr = signatureObj[key]
                    .split("|")
                    .map((pointStr) => {
                      // contains x,y co-ordinates separated by a comma (,)
                      const point = pointStr.split(",")
                      return { x: parseInt(point[0]), y: parseInt(point[1]) }
                    })
                  lines.push(lineArr)
                }
              })

              defaultValue = lines
            }
            break

          default:
            console.log(
              `unknown element type ${element.type} for question ${name}`
            )
        }
        return {
          name: getName(section, element),
          type: element.type,
          defaultValue: defaultValue,
        }
      })
    )
  )

  let newValues = {}

  // Assign checklist sequence numbers to 'newValues' with a default value
  // assigned depending on type.
  keyValuePairs.forEach((key) => {
    newValues = Object.assign({ [key.name]: key.defaultValue }, newValues)
  })

  // Copy over the nested 'files' attribute, which holds file names that have been uploaded per question
  newValues.files = checkListData.files || []
  newValues.comments = checkListData.comments || {}

  return newValues
}

export {
  convertCheckListDataFromFirestore,
  getName,
  isAnyCheckListDataEntered,
  getFileUrls,
  getInitialCheckListValues,
  chipColors,
  chipTooltips,
}
