import React, { useState, useEffect, createRef } from "react"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItemText from "@mui/material/ListItemText"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import * as UIConstants from "./controls/UIConstants"
import { useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import firebase from "firebase/compat/app"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"
import DownloadIcon from "@mui/icons-material/Download"
import YesNo from "./YesNo"
import FileStage from "./FileStage"
import * as fileServices from "../pages/services/fileServices"
import * as assetServices from "../pages/services/assetServices"
import { parseTimestamp, parseDate } from "../pages/services/dateServices"
import {
  Alert,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import _ from "lodash"
import { Box } from "@mui/material"
import * as icons from "../icons"
import {
  isNonEmptyString,
  isErrors,
  isNonZero,
} from "./controls/FieldValidation"
import ProgressBackdrop from "./ProgressBackdrop"
import {
  getStorage,
  ref,
  deleteObject,
  getDownloadURL,
  listAll,
} from "firebase/storage"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import NavButtons from "./NavButtons"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import SubHeading from "./SubHeading"
import JobTile from "./JobTile"
import ScrollingImageList from "./ScrollingImageList"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const initialValues = () => {
  return {
    name: "",
    description: "",
    type: "",
    make: "",
    model: "",
    supplier_id: "",
    sn: "",
    centre_id: "",
    status: "",
    location: "",
    acquired: null,
    maintenance_schedule: "",
    warranty: "",
    warranty_end: null,
    // disposed (date) initially not set
    disposed: null,
    docs: [],
    next_service: null,
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
  }
}

const AssetEditForm = (props) => {
  const history = useHistory()

  const [isShowProgress, setShowProgress] = useState(false)

  const { setTitle } = props

  const addFileRef = createRef()

  const { enqueueSnackbar } = useSnackbar()

  const [assetToShowPhotos, setAssetToShowPhotos] = useState(null)

  const [initialCarouselIndex, setInitialCarouselIndex] = useState()

  const [assetId, setAssetId] = useState("")

  const [imageLoadInfo, setImageLoadInfo] = useState()

  // For uploading files before the new asset has been saved
  const [files, setFiles] = useState([])

  const isNew = () => props.id === undefined && assetId === ""

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const [selectedCarouselIndex, setSelectedCarouselIndex] = useState()

  const [jobs, setJobs] = useState()

  // counter to enable triggering reload of files for this asset.
  // increment to trigger the useEffect to reload files
  const [fileActions, setFileActions] = useState(0)

  const [claims, setClaims] = useState()

  const [showFieldErrors, setShowFieldErrors] = useState(false)

  const fieldValidation = {
    name: [isNonEmptyString],
    centre_id: [isNonEmptyString],
  }

  useEffect(() => {
    if (assetToShowPhotos && values) {
      const currentFiles = assetToShowPhotos.docs.length
      const newFiles = values.docs.length

      const isDocsChanged = currentFiles !== newFiles
      setAssetToShowPhotos({ ...values, id: assetId })
      if (isDocsChanged) {
        setInitialCarouselIndex(newFiles - 1)
      }
    }
  }, [values, assetId])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult().then((token) => {
        setClaims(token.claims)

        let newValues = { ...values }

        if (isNew) {
          newValues = {
            ...newValues,
            account_id: token.claims.account_id,
          }
        }

        setValues(newValues)
      })
    })

    return unsub
  }, [])

  useEffect(() => {
    if (claims && assetId) {
      db.collection("jobs")
        .where("account_id", "==", claims.account_id)
        .where("parent_id", "==", assetId)
        .where("parent_type", "==", "asset")
        .onSnapshot((querySnapshot) => {
          const jobs = []
          querySnapshot.forEach((doc) => {
            jobs.push({ ...doc.data(), id: doc.id })
          })
          setJobs(jobs)
        })
    }
  }, [claims, assetId])

  // Initialise account if it a new record

  useEffect(() => {
    if (props.id === null || props.id === undefined) {
      if (claims === undefined) {
        return
      }

      const newValues = {
        ...values,
        account_id: claims.account_id,
      }

      setValues(newValues)
    }
  }, [claims, props])

  const [loadState, setLoadState] = useState({ id: "", accountId: "" })

  useEffect(() => {
    if (claims === undefined) {
      return
    }

    const newLoadState = { id: props.id, accountId: claims.account_id }

    const isLoadStateChanged = !_.isEqual(newLoadState, loadState)

    if (!isLoadStateChanged) {
      return
    }

    setLoadState(newLoadState)

    if (props.id !== null && props.id !== undefined) {
      loadAsset(props.id)
    }
  }, [props, claims])

  // Load asset

  const loadAsset = async (assetId) => {
    const logId = "[ASSET EDIT > LOAD ASSET]"

    if (assetId) {
      setAssetId(assetId)

      const snapshot = await db.collection("assets").doc(assetId).get()

      const data = {
        ...initialValues(),
        ...snapshot.data(),
        acquired: parseTimestamp(snapshot.data().acquired),
        disposed: parseTimestamp(snapshot.data().disposed),
        warranty_end: parseTimestamp(snapshot.data().warranty_end),
        next_service: parseTimestamp(snapshot.data().next_service),
      }

      // Check if there are an empty strings in the 'docs' array and remove them
      if (data.docs) {
        data.docs = data.docs.filter((doc) => doc !== "")
      }

      setValues(data)

      setImageLoadInfo({
        docId: assetId,
        docType: "asset",
        accountId: data.account_id,
        images: fileServices.getImageFiles(data.docs),
        fileInfo: [],
      })
    }
  }

  useEffect(() => {
    if (props.id !== null && props.id !== undefined) {
      setTitle(values.name)
    } else {
      setTitle("New Asset")
    }
  }, [props.id, values, setTitle])

  const handleUploadFile = () => {
    addFileRef.current.click()
  }

  const uploadFiles = async (values, files) => {
    setShowProgress(true)

    const newAsset = await assetServices.uploadFiles(assetId, values, files)

    setValues(newAsset)

    setImageLoadInfo({
      docId: assetId,
      docType: "asset",
      accountId: newAsset.account_id,
      images: fileServices.getImageFiles(newAsset.docs),
      fileInfo: [],
    })

    setShowProgress(false)
  }

  const handleStoreFile = async (event) => {
    const files = []
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i]
      files.push(file)
    }
    await uploadFiles(values, files)

    // Clear file input field
    event.target.value = null
  }

  const handleDeleteAssetConfirmed = async (assetIdToDelete) => {
    // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted
    const deleteConfig = {
      ...yesNoDeleteAssetConfig,
      openPrompt: false,
    }
    setYesNoDeleteAssetConfig(deleteConfig)

    getAuth()
      .currentUser.getIdTokenResult()
      .then(async (token) => {
        const folderToDelete = `accounts/${token.claims.account_id}/assets/${assetIdToDelete}`

        const folderPath = `${folderToDelete}/`

        const storageRef = ref(getStorage(), folderPath)

        await listAll(storageRef).then(async (listResult) => {
          listResult.items.forEach(async (itemRef) => {
            await deleteObject(itemRef)
          })
        })

        await db.collection("assets").doc(assetIdToDelete).delete()
      })
      .then(history.goBack())
      .then(enqueueSnackbar("Deleted", { variant: "success" }))
      .catch((err) => console.error("Problem deleting asset", err))
  }

  const [yesNoDeleteAssetConfig, setYesNoDeleteAssetConfig] = useState({
    openPrompt: false,
    description: "This delete is permanent",
    title: "Delete",
    handleConfirm: null,
  })

  const [yesNoDeleteFileConfig, setYesNoDeleteFileConfig] = useState({
    openPrompt: false,
    description: "Delete file from asset?",
    title: "Delete",
    handleConfirm: null,
  })

  const handlePromptConfirmDeleteAsset = (event) => {
    const newConfig = {
      ...yesNoDeleteAssetConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteAssetConfirmed(props.id),
    }
    setYesNoDeleteAssetConfig(newConfig)
  }

  const handlePromptDeleteFile = (fileName) => {
    const newConfig = {
      ...yesNoDeleteFileConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteFile(fileName),
    }
    setYesNoDeleteFileConfig(newConfig)
  }

  const handleDownloadFile = async (fileName) => {
    const folderPath = `accounts/${values.account_id}/assets/${assetId}`
    const fullFilePath = `${folderPath}/${fileName}`
    const storageRef = ref(getStorage(), fullFilePath)
    const url = await getDownloadURL(storageRef)
    window.open(url, "_blank")
  }

  const handleDeleteFile = async (fileName) => {
    const folder = assetServices.getAssetFilePath(values.account_id, props.id)
    const fileRef = ref(getStorage(), `${folder}/${fileName}`)
    await deleteObject(fileRef).then((result) => {
      setFileActions(fileActions + 1)
      enqueueSnackbar(`Removed file ${fileName}`, { variant: "success" })
    })

    const newFiles = values.docs.filter((name) => name !== fileName)

    const mergeValues = {
      docs: newFiles,
    }

    await db
      .collection("assets")
      .doc(props.id)
      .update(mergeValues, { merge: true })

    const newValues = {
      ...values,
      docs: newFiles,
    }
    setValues(newValues)

    setImageLoadInfo({
      docId: assetId,
      docType: "asset",
      accountId: values.account_id,
      images: fileServices.getImageFiles(newFiles),
      fileInfo: [],
    })
  }

  const handleViewPhotos = (initialIndex) => {
    // The args for this need a asset id provided, whereas normally 'values' does not contain the id
    setInitialCarouselIndex(initialIndex)
    setAssetToShowPhotos({ ...values, id: assetId })
  }

  // Remove null or undefined values, which cannot be saved in Firebase.
  const removeEmptyValues = (obj) => {
    const newObj = {}
    Object.keys(obj).forEach((prop) => {
      if (obj[prop] !== null && obj[prop] !== undefined) {
        newObj[prop] = obj[prop]
      }
    })
    return newObj
  }

  // Save asset

  const handleSubmit = async (event, addMultiple) => {
    event.preventDefault()

    const del = firebase.firestore.FieldValue.delete()

    if (isErrors({ values, fieldValidation })) {
      setShowFieldErrors(true)
      enqueueSnackbar("Please update field values", { variant: "warning" })
      return
    }

    setShowFieldErrors(false)

    if (isNew()) {
      const newRecord = removeEmptyValues({
        ...values,
        account_id: claims.account_id,
        created: dataServices.serverTimestamp(),
        modified: dataServices.serverTimestamp(),
        name_lower: values.name.toLowerCase(),
        type_lower: values.type.toLowerCase(),

        // convert to timestamp so we can sort it
        next_service: parseDate(values.next_service),
        acquired: parseDate(values.acquired),
        disposed: parseDate(values.disposed),
        warranty_end: parseDate(values.warranty_end),

        docs: files.map((file) => file.name),
      })

      const docRef = await db.collection("assets").add(newRecord)

      setAssetId(docRef.id)

      const filePath = `accounts/${claims.account_id}/assets/${docRef.id}`

      await fileServices.saveAttachments(files, filePath)

      setImageLoadInfo({
        docId: docRef.id,
        docType: "asset",
        accountId: claims.account_id,
        images: fileServices.getImageFiles(newRecord.docs),
        fileInfo: [],
      })
      enqueueSnackbar("Created", { variant: "success" })

      if (addMultiple) {
        setValues(initialValues())

        setAssetId("")
      }
    } else {
      const updateRecord = removeEmptyValues({
        ...values,
        name_lower: values.name.toLowerCase(),
        type_lower: values.type.toLowerCase(),
        next_service: values.next_service
          ? parseDate(values.next_service)
          : del,
        acquired: values.acquired ? parseDate(values.acquired) : del,
        disposed: values.disposed ? parseDate(values.disposed) : del,
        warranty_end: values.warranty_end
          ? parseDate(values.warranty_end)
          : del,
        modified: dataServices.serverTimestamp(),
      })

      await db
        .collection("assets")
        .doc(assetId)
        .update(updateRecord)
        .then(enqueueSnackbar("Updated", { variant: "success" }))
    }
  }

  const handleCreateJob = () => {
    const label = [values.name]
    if (values.make) {
      label.push(values.make)
    }
    if (values.model) {
      label.push(values.model)
    }
    if (values.sn) {
      label.push(values.sn)
    }
    const stateInfo = {
      parent: {
        parent_id: assetId,
        parent_type: "asset",
        // no 'parent_ref' attribute in this case, only required if creating job from checklist since we need to know which checklist question the job applies to
        parent_label: label.join(" : "),
        centre_id: values.centre_id,
      },
    }

    history.push({
      pathname: `/JobEdit`,
      state: stateInfo,
    })
  }

  const handleDateChange = (dateValue, propertyName) => {
    const newValues = {
      ...values,
      [propertyName]: dateValue ? dateValue.toDate() : null,
    }

    setValues(newValues)
  }

  return (
    <>
      <ProgressBackdrop open={isShowProgress} />

      <YesNo config={yesNoDeleteAssetConfig} />

      <YesNo config={yesNoDeleteFileConfig} />

      <Form>
        <input
          accept="*/*"
          style={{ display: "none" }}
          id="selected-files"
          multiple
          type="file"
          ref={addFileRef}
          onChange={async (e) => {
            setShowProgress(true)
            await handleStoreFile(e)
          }}
        />

        <Box display="flex">
          <SubHeading title="Asset Details" />
          <Box sx={{ display: "flex", marginLeft: "auto" }}>
            <Controls.CreatedLabel
              value={values.created}
              history={values.history}
            />
          </Box>
        </Box>

        <Stack
          direction="column"
          gap={2}
          sx={{ marginTop: "20px", marginBottom: "20px", maxWidth: "400px" }}
        >
          <Controls.TextInput
            name="name"
            label="Asset Name"
            value={values.name}
            onChange={handleInputChange}
            autoFocus
            showError={showFieldErrors}
            errorFuncs={fieldValidation["name"]}
          />

          <Controls.CentreCombobox
            name="centre_id"
            label="Centre"
            value={values.centre_id}
            accountId={values.account_id}
            onChange={handleInputChange}
            helperText={"Location of asset"}
            showError={showFieldErrors}
            errorFuncs={fieldValidation["centre_id"]}
          />

          <Controls.TextInput
            name="type"
            label="Type"
            value={values.type}
            onChange={handleInputChange}
          />

          <Controls.TextInput
            name="make"
            label="Make"
            value={values.make}
            onChange={handleInputChange}
          />

          <Controls.TextInput
            name="model"
            label="Model"
            value={values.model}
            onChange={handleInputChange}
          />

          <Box sx={{ minWidth: "300px" }}>
            <Controls.SupplierCombobox
              name="supplier_id"
              label="Supplier"
              value={values.supplier_id}
              accountId={claims?.account_id}
              onChange={handleInputChange}
            />
          </Box>

          <Controls.TextInput
            name="sn"
            label="Serial No."
            value={values.sn}
            onChange={handleInputChange}
          />

          <DateInput
            dateVal={values.next_service}
            name="next_service"
            handleDateChange={handleDateChange}
            label="Next Service"
          />

          <Controls.TextInput
            name="status"
            label="Status"
            value={values.status}
            onChange={handleInputChange}
          />

          <Controls.TextInput
            name="description"
            label="Description"
            multiline={true}
            value={values.description}
            onChange={handleInputChange}
          />

          <Controls.TextInput
            name="maintenance_schedule"
            label="Maintenance Schedule"
            multiline={true}
            value={values.maintenance_schedule}
            onChange={handleInputChange}
            helperText={"e.g. 6 monthly service"}
          />

          <DateInput
            dateVal={values.acquired}
            name="acquired"
            handleDateChange={handleDateChange}
            label="Acquired"
          />

          <DateInput
            dateVal={values.disposed}
            name="disposed"
            handleDateChange={handleDateChange}
            label="Disposed"
          />

          <DateInput
            dateVal={values.warranty_end}
            name="warranty_end"
            handleDateChange={handleDateChange}
            label="Warranty End"
          />
        </Stack>

        {!assetId && (
          <Box>
            <FileStage files={files} setFiles={setFiles} />
          </Box>
        )}

        <Stack gap={2} sx={{ mt: "20px" }}>
          <SubHeading title="Asset Jobs" />

          {jobs && jobs.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              No jobs
            </Typography>
          )}
          {jobs && jobs.map((job) => <JobTile job={job} key={job.id} />)}

          <Box>
            <Controls.Button
              text="Create Job"
              type="button"
              onClick={handleCreateJob}
              endIcon={<icons.JobIcon />}
              disabled={isNew()}
            />
          </Box>
        </Stack>

        <Stack gap={2} sx={{ mt: "20px" }}>
          <SubHeading title="Asset Photos and Files" />

          {values.docs.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              No photos uploaded yet
            </Typography>
          )}

          <List dense={true}>
            {values.docs.map((fileName, index) => (
              <AssetFileListItem
                key={fileName}
                claims={claims}
                handleViewPhotos={() => handleViewPhotos(index)}
                fileName={fileName}
                selected={selectedCarouselIndex === index}
                handlePromptDeleteFile={() => handlePromptDeleteFile(fileName)}
                handleDownloadFile={() => handleDownloadFile(fileName)}
              />
            ))}
          </List>

          <Stack direction="row" gap={1}>
            {/* {!isNew() && (
              <Controls.Button
                text="View Photos"
                type="button"
                onClick={() => handleViewPhotos(0)}
                endIcon={<icons.ImageSearchIcon />}
                disabled={values.docs.length === 0}
              />
            )} */}

            {props.id && (
              <Controls.Button
                text="Upload"
                type="button"
                onClick={handleUploadFile}
                endIcon={<icons.AttachFileIcon />}
                tooltip="Upload photos/files for this asset"
              />
            )}
          </Stack>

          {/* <ViewAssetPhotos
            assetToShowPhotos={assetToShowPhotos}
            setAssetToShowPhotos={setAssetToShowPhotos}
            selectedIndex={initialCarouselIndex}
            setSelectedIndex={setSelectedCarouselIndex}
          /> */}

          {imageLoadInfo && (
            <ScrollingImageList
              imageLoadInfo={imageLoadInfo}
              selectedFileName={values.docs[selectedCarouselIndex]}
            />
          )}
        </Stack>

        <Grid item>
          <NavButtons>
            {!isNew() && (
              <Controls.Button
                text="Delete"
                type="button"
                onClick={handlePromptConfirmDeleteAsset}
                endIcon={<icons.DeleteIcon />}
              />
            )}

            {/* <Controls.Button
              type="submit"
              text="Save and Add New"
              onClick={(event) => handleSubmit(event, true)}
            /> */}

            <Controls.Button
              type="submit"
              text="Save"
              onClick={(event) => handleSubmit(event, false)}
              endIcon={<icons.SaveIcon />}
            />
          </NavButtons>
        </Grid>
      </Form>
    </>
  )
}

const DateInput = ({ dateVal, name, label, handleDateChange }) => {
  return (
    <Box sx={{ width: "175px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          margin="normal"
          id={`${name}-date-picker`}
          label={label}
          inputFormat="DD/MM/YYYY"
          value={dateVal}
          onChange={(selectedDate) => handleDateChange(selectedDate, name)}
          renderInput={(params) => (
            <TextField
              variant={UIConstants.STANDARD_INPUT_VARIANT}
              size={UIConstants.STANDARD_INPUT_SIZE}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  )
}

const AssetFileListItem = (props) => {
  const {
    handleViewPhotos,
    fileName,
    selected,
    handlePromptDeleteFile,
    handleDownloadFile,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            handleDownloadFile()
          }}
        >
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <Typography>Download</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            handlePromptDeleteFile()
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography>Delete</Typography>
        </MenuItem>
      </Menu>

      <ListItemButton
        key={fileName}
        component="a"
        onClick={handleViewPhotos}
        selected={selected}
      >
        <ListItemText primary={fileName} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="file-more"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>
    </>
  )
}

export default withRouter(AssetEditForm)
