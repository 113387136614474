import * as actions from "../actionTypes"

// pageDocs is { first: <doc>, last: <doc> } for that page, and used by prev/next
const initialState = {
  value: {
    page: 0,
    order: "asc",
    orderBy: "name_lower",
    job_types: [],
    name: "",
    active_only: true,
    search_people: false,
    first_name: "",
    last_name: "",
  },
}

export default function supplierGridPagination(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SUPPLIER_GRID_PAGINATION: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}

export { initialState }
