import React, { useState, useMemo, useRef, useCallback } from "react"
import { Box, Button, Skeleton, Switch, Typography } from "@mui/material"
import { Bar, getDatasetAtEvent, getElementAtEvent } from "react-chartjs-2"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import { useDispatch, useSelector } from "react-redux"
import {
  selectChartJobsByCentreAndCategory,
  selectJobGridPagination,
} from "../redux/selectors"
import {
  setChartJobsByCentreAndCategory,
  setJobGridPagination,
} from "../redux/actions"
import BarChartIcon from "@mui/icons-material/BarChart"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import * as colorServices from "../pages/services/colorServices"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import { JOB_STATUS_OPEN } from "../pages/services/jobServices"
import { spacing } from "../pages/services/styleServices"

const styles = {
  skeletonChart: {
    display: "flex",
    flexDirection: "column",
    gap: spacing(1),
    padding: spacing(2),
    maxWidth: 800,
  },
}

const JobsByCentreAndCategoryChart = (props) => {
  const initialChartState = useSelector(selectChartJobsByCentreAndCategory)

  const { width } = useWindowDimensions()

  const [labelsOverride, setLabelsOverride] = useState([])

  const [jobsByCentreAndCategoryData, setJobsByCentreAndCategoryData] =
    useState(initialChartState.data)

  const jobGridPagination = useSelector(selectJobGridPagination)

  const chartRef = useRef()

  const dispatch = useDispatch()

  const history = useHistory()

  const [isShowSkeleton, setShowSkeleton] = useState(false)

  const [sortCentres, setSortCentres] = useState(initialChartState.sortCentres)

  const BAR_HEIGHT = 20

  const calcAspectRatio = useCallback(
    (data) => {
      if (data) {
        const labelCount = data.labels.length || 15
        const expectedHeight = labelCount * BAR_HEIGHT + 100

        const ratio = (width - 200) / expectedHeight

        return ratio
      }
    },
    [width]
  )

  const chartDataByCentreAndCategory = useMemo(() => {
    if (jobsByCentreAndCategoryData) {
      const selected = jobsByCentreAndCategoryData.map((item) => ({
        centre_id: item.centre_id,
        centre_name: item.name,
        category: item.category,
        count: item.count,
      }))

      const centresGrouped = _.groupBy(jobsByCentreAndCategoryData, "name")

      const centreCounts = Object.keys(centresGrouped).map((key) => ({
        centre_id: centresGrouped[key][0].centre_id,
        centre_name: centresGrouped[key][0].name,
        count: centresGrouped[key].reduce((acc, value) => acc + value.count, 0),
      }))

      const centreCountsSorted =
        labelsOverride.length > 0
          ? centreCounts.sort(
              (a, b) =>
                labelsOverride.indexOf(a.centre_name) -
                labelsOverride.indexOf(b.centre_name)
            )
          : centreCounts.sort((a, b) => b.count - a.count)

      const centres = centreCountsSorted.map((item) => item.centre_name)

      const categories = _.groupBy(selected, "category")

      // For datasets, each object is a category of job and a count.
      // The sub objects are the centre names and the counts
      // in the same order as the 'centres' object
      const datasets = Object.keys(categories).map((key, index) => {
        const data = centres.map((centre) => {
          const found = selected.find(
            (item) => item.centre_name === centre && item.category === key
          )
          return found?.count || 0
        })

        return {
          label: key,
          data,
          backgroundColor: colorServices.getColors(categories.length)[index],
          barThickness: 20,
        }
      })

      const result = {
        labels: centres,
        datasets: datasets,
      }

      return result
    }
  }, [jobsByCentreAndCategoryData, labelsOverride])

  const centreAndCategoryAspectRatio = useMemo(() => {
    return calcAspectRatio(chartDataByCentreAndCategory)
  }, [chartDataByCentreAndCategory, calcAspectRatio])

  const chartOptionsByCentreAndCategory = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: centreAndCategoryAspectRatio,
      scales: {
        x: {
          stacked: true,
          ticks: {
            // For a category axis, the val is the index so the lookup via getLabelForValue is needed
            callback: function (val, index) {
              // Hide label if not integer

              const label = this.getLabelForValue(val)
              return Number.isInteger(Number(label)) ? label : ""
            },
          },
        },
        y: {
          stacked: true,
        },
      },
      indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      plugins: {
        legend: {
          position: "right",
          onClick: (evt, legendItem, legend) => {
            const index = legendItem.datasetIndex
            const ci = legend.chart

            legendItem.hidden = !legendItem.hidden
            if (legendItem.hidden) {
              ci.hide(index)
            } else {
              ci.show(index)
            }

            // Recalculate total scores per centre.

            // determine visible legend items
            const visibleLegendItems = legend.legendItems.filter(
              (item) => !item.hidden
            )

            // Add up centre scores for visible legend items
            const datasetLabels = visibleLegendItems.map((item) => item.text)

            const visibleDatasets =
              chartDataByCentreAndCategory.datasets.filter((item) =>
                datasetLabels.includes(item.label)
              )

            // Recalculate total scores per centre.

            const totalScoresByCentre =
              chartDataByCentreAndCategory.labels.reduce(
                (acc, item, labelIndex) => {
                  const score = visibleDatasets.reduce((acc, dataset) => {
                    acc.push(dataset.data[labelIndex])
                    return acc
                  }, [])

                  acc.push(score)
                  return acc
                },
                []
              )

            const totalScoresByCentreSummed = totalScoresByCentre.map(
              (scores) => {
                return scores.reduce((acc, score) => acc + score, 0)
              }
            )

            const totalScoresAndCentreIndexSorted = totalScoresByCentreSummed
              .map((score, index) => ({
                score,
                index,
                label: chartDataByCentreAndCategory.labels[index],
              }))
              .sort((a, b) => {
                const sort1 = b.score - a.score
                if (sort1 !== 0) {
                  return sort1
                }

                return a.label.localeCompare(b.label)
              })

            // Change the labels order and resequence the data to match

            if (sortCentres) {
              const newLabels = totalScoresAndCentreIndexSorted.map(
                (item) => item.label
              )
              setLabelsOverride(newLabels)
            }
          },
        },
        title: {
          display: true,
          text: "Open Jobs by Centre, Category, and Location",
        },
      },
    }
  }, [centreAndCategoryAspectRatio, chartDataByCentreAndCategory, sortCentres])

  const getCentreId = useCallback(
    (centreName) => {
      const found = jobsByCentreAndCategoryData.find(
        (item) => item.name === centreName
      )
      return found?.centre_id || null
    },
    [jobsByCentreAndCategoryData]
  )

  const getDatasetName = (dataset) => {
    if (!dataset.length) return
    const datasetIndex = dataset[0].datasetIndex
    return chartDataByCentreAndCategory.datasets[datasetIndex].label
  }

  const handleQueryOpenJobsByCentreAndCategory = async () => {
    // Clear data and show skeleton
    setJobsByCentreAndCategoryData([])
    setShowSkeleton(true)

    // Retrieve and set refreshed data
    const results = await cloudFunctions.queryViewByAccountId(
      "jobs_by_centre_and_category",
      {
        applyUserCentres: true,
      }
    )
    setShowSkeleton(false)
    setJobsByCentreAndCategoryData(results.data[0])

    dispatch(
      setChartJobsByCentreAndCategory({
        data: results.data[0],
        sortCentres: sortCentres,
      })
    )
  }

  const handleChartClick = (event) => {
    const { current: chart } = chartRef

    if (!chart) {
      return
    }

    const elementAtEvent = getElementAtEvent(chart, event)
    if (elementAtEvent.length > 0) {
      const centreName =
        chartDataByCentreAndCategory.labels[elementAtEvent[0].index]
      const category = getDatasetName(getDatasetAtEvent(chart, event))

      const newJobGridPagination = {
        ...jobGridPagination,
        centre_id: getCentreId(centreName),
        job_type: category,
        supplier_id: "",
        job_status_filter: [JOB_STATUS_OPEN],
      }

      dispatch(setJobGridPagination(newJobGridPagination))

      history.push("/JobGrid")
    }
  }

  const hideAll = (data, ref) => {
    for (let i = 0; i < data.datasets.length; i++) {
      ref.current.setDatasetVisibility(i, false)
    }
    ref.current.update()
  }

  return (
    <>
      <Box sx={{ marginBottom: "20px" }}>
        <Button
          onClick={handleQueryOpenJobsByCentreAndCategory}
          startIcon={<BarChartIcon />}
        >
          Jobs By Centre And Category
        </Button>
      </Box>
      <Box>
        {isShowSkeleton && <SkeletonChart />}

        {jobsByCentreAndCategoryData?.length > 0 && (
          <>
            <Switch
              checked={sortCentres}
              onChange={(e) => {
                setSortCentres(!sortCentres)
                dispatch(
                  setChartJobsByCentreAndCategory({
                    data: jobsByCentreAndCategoryData,
                    sortCentres: !sortCentres,
                  })
                )
              }}
            />
            <Typography variant="caption">Sort Centres</Typography>

            <Button
              onClick={() => hideAll(chartDataByCentreAndCategory, chartRef)}
            >
              Hide All
            </Button>

            <Button
              onClick={() => {
                for (
                  let i = 0;
                  i < chartDataByCentreAndCategory.datasets.length;
                  i++
                ) {
                  chartRef.current.setDatasetVisibility(i, true)
                }
                chartRef.current.update()
              }}
            >
              Show All
            </Button>
          </>
        )}
        {chartDataByCentreAndCategory.labels.length > 0 && (
          <Bar
            options={chartOptionsByCentreAndCategory}
            data={chartDataByCentreAndCategory}
            onClick={handleChartClick}
            ref={chartRef}
          />
        )}
      </Box>
    </>
  )
}

const SkeletonChart = () => {
  return (
    <>
      <Box sx={styles.skeletonChart}>
        <Skeleton variant="rectangular" sx={{ maxWidth: "800px" }} />
        <Skeleton variant="rectangular" sx={{ maxWidth: "600px" }} />
        <Skeleton variant="rectangular" sx={{ maxWidth: "400px" }} />
        <Skeleton variant="rectangular" sx={{ maxWidth: "200px" }} />
      </Box>
    </>
  )
}

export default JobsByCentreAndCategoryChart
