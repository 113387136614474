import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import db from "../Firestore"
import { useSnackbar } from "notistack"
import { Typography } from "@mui/material"
import { CardContent } from "@mui/material"
import { Card } from "@mui/material"
import { CardActions } from "@mui/material"
import { Button } from "@mui/material"
import CreateIcon from "@mui/icons-material/Create"
import LinkIcon from "@mui/icons-material/Link"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import { CardHeader } from "@mui/material"
import * as stripeServices from "../pages/services/stripeServices"
import { functions } from "../Firestore"
import { httpsCallable } from "firebase/functions"
import { spacing } from "../pages/services/styleServices"

const styles = {
  action: {
    marginTop: spacing(3),
    marginLeft: spacing(0),
    marginRight: spacing(3),
    padding: spacing(2),
    maxWidth: "320px",
  },
}

const StripeActions = ({ accountInfo, accountId, setShowProgress }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [actions, setActions] = useState([])

  const findStripeCustomerByEmail = async (email) => {
    const getStripeCustomerByEmail = httpsCallable(
      functions,
      "getStripeCustomerByEmail"
    )

    const result = await getStripeCustomerByEmail({ email: email })

    return result.data.customers
  }

  const handleCreateStripeSubscription = async (productName) => {
    const result = stripeServices.createStripeSubscription(
      productName,
      accountId
    )

    if (result.data.status) {
      const status = result.data.status

      enqueueSnackbar(status.message, { variant: status.type })
    }
  }

  // Get the actions that need to be performed on this account

  const getAccountActions = async (accountInfo) => {
    if (accountInfo.name === "") {
      return
    }

    const newActions = []

    const hasStripeCustId =
      accountInfo.hasOwnProperty("stripe_cust_id") &&
      accountInfo.stripe_cust_id !== ""

    // Check if there's a Stripe account with a matching email address, but it's not
    // linked to the Jobs for Joe account

    let linkToStripeAccount = false

    if (!hasStripeCustId) {
      const customers = await findStripeCustomerByEmail(accountInfo.email)
      if (customers.length === 1) {
        newActions.push({
          title: "Link Stripe Account",
          description: `Link to existing Stripe account with same email`,
          name: "Link",
          icon: <LinkIcon color="primary" />,
          func: handleLinkToStripeCustomerByEmail,
        })

        linkToStripeAccount = true
      }
    }

    // Check if the Jobs For Joe account has a Stripe Customer defined

    if (!hasStripeCustId && !linkToStripeAccount) {
      newActions.push({
        title: "Create Stripe Account",
        description: "Create a Stripe account to enable billing",
        name: "Create",
        icon: <CreateIcon color="primary" />,
        func: handleCreateStripeCustomer,
      })
    }

    let stripeCustomerDeleted = false

    const stripeCustomerInfo = await stripeServices.getStripeCustomer(accountId)

    if (hasStripeCustId) {
      if (stripeCustomerInfo.customer && stripeCustomerInfo.customer.deleted) {
        stripeCustomerDeleted = true

        newActions.push({
          title: "Remove Stripe Link",
          description:
            "Unlink from this deleted Stripe account. Once this is done, a new Stripe account can be created",
          name: "Unlink",
          icon: <LinkOffIcon color="primary" />,
          func: handleRepair,
        })
      }
    }

    // Check if we need to create a subscription

    const hasSubscription =
      stripeCustomerInfo.customer &&
      stripeCustomerInfo.customer.subscriptions &&
      stripeCustomerInfo.customer.subscriptions.length > 0

    if (hasStripeCustId && !stripeCustomerDeleted) {
      if (!hasSubscription) {
        newActions.push({
          title: "Create Subscription",
          description: "Create a trial subscription in this account",
          name: "Create",
          icon: <CreateIcon />,
          // 'Jobs module' must match exactly the name of the product in Stripe
          // TODO: expand subscription management to allow for multiple subscriptions
          func: () =>
            handleCreateStripeSubscription(stripeServices.JOBS_MODULE),
        })
      }
    }

    // Check if any payment methods are defined

    const hasPaymentMethods =
      stripeCustomerInfo.customer &&
      stripeCustomerInfo.cards &&
      stripeCustomerInfo.cards.length > 0

    if (hasSubscription && !hasPaymentMethods) {
      newActions.push({
        title: "No payment methods",
        description:
          "No payment methods are defined for this account. Contact the customer",
        name: null,
        icon: <CreditCardIcon />,
        func: null,
      })
    }

    setActions(newActions)
  }

  useEffect(() => {
    getAccountActions(accountInfo)
  }, [accountInfo])

  const getAccount = async (accountId) => {
    const accountDoc = await db.collection("accounts").doc(accountId).get()

    const account = accountDoc.data()

    return account
  }

  const handleCreateStripeCustomer = async () => {
    const createStripeCustomer = httpsCallable(
      functions,
      "createStripeCustomer"
    )

    const account = await getAccount(accountId)

    const result = await createStripeCustomer({
      customer_account_id: accountId,

      // TODO: work out if we need to provide a billing address
      address: {
        city: "",
        country: "",
        line1: "",
        line2: "",
        postal_code: "",
        state: "",
      },
      email: account.email,
      name: account.name,
      phone: account.phone,
    })

    const status = result.data.status

    enqueueSnackbar(status.message, { variant: status.type })
  }

  const handleRepair = async () => {
    const repairStripeConfig = httpsCallable(functions, "repairStripeConfig")

    const result = await repairStripeConfig({ account_id: accountId })

    if (result.data.status) {
      const status = result.data.status

      enqueueSnackbar(status.message, { variant: status.type })
    }
  }

  const handleLinkToStripeCustomerByEmail = async () => {
    const linkToStripeCustomerByEmail = httpsCallable(
      functions,
      "linkToStripeCustomerByEmail"
    )

    const account = await getAccount(accountId)

    const result = await linkToStripeCustomerByEmail({
      account_id: accountId,
      email: account.email,
    })

    const status = result.data.status

    enqueueSnackbar(status.message, { variant: status.type })
  }

  return (
    <>
      <Grid container direction="row">
        {actions.map((action) => (
          <Grid key={action.name} sx={styles.action}>
            <Card>
              <CardHeader avatar={action.icon} title={action.title} />
              <CardContent>
                <Typography variant="body2">{action.description}</Typography>
              </CardContent>
              <CardActions>
                {action.func && (
                  <Button
                    size="small"
                    onClick={async () => {
                      setShowProgress(true)
                      await action.func()
                      setShowProgress(false)
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    {action.name}
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default StripeActions
