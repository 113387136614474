import React, { useEffect, useState, useMemo } from "react"
import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  List,
  Tooltip,
  Chip,
  CardHeader,
  Stack,
} from "@mui/material"
import { Link } from "react-router-dom"
import * as icons from "../icons"
import * as supplierServices from "../pages/services/supplierServices"
import { Alert, AlertTitle } from "@mui/material"
import { spacing } from "../pages/services/styleServices"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import LocationIcon from "@mui/icons-material/LocationOn"
import ReactTimeAgo from "react-time-ago"
import * as colors from "@mui/material/colors"
import * as colorServices from "../pages/services/colorServices"
import TruncatedText from "./TruncatedText"

const styles = {
  supplierDocTypes: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginTop: spacing(1),
      padding: 0,
      paddingLeft: spacing(1),
    },
  },
  supplierField: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
}

const SupplierCard = ({ supplier, supplierDocTypes, showDocuments }) => {
  const [url] = useState("/SupplierEdit/" + supplier.id)

  const [docStatus, setDocStatus] = useState()

  // Create status of docs for this supplier
  useEffect(() => {
    if (supplierDocTypes) {
      // First, see what of the existing docs they have

      const existingDocsSummary =
        (supplier.docs &&
          supplier.docs.map((doc) =>
            supplierServices.getSupplierDocStatus(doc)
          )) ||
        []

      const missingDocTypes = supplierDocTypes.filter(
        (docType) =>
          existingDocsSummary.find((doc) => doc.type === docType) === undefined
      )

      const missingDocsSummary = missingDocTypes.map((docType) => ({
        type: docType,
        severity: "error",
        text: `Not provided`,
      }))

      setDocStatus([...existingDocsSummary, ...missingDocsSummary])
    }
  }, [supplierDocTypes, supplier])

  const addrFields = useMemo(() => {
    const result = []
    if (supplier.addr1) {
      result.push({ name: "addr1", value: supplier.addr1 })
    }
    if (supplier.addr2) {
      result.push({ name: "addr2", value: supplier.addr2 })
    }
    if (supplier.city) {
      result.push({ name: "city", value: supplier.city })
    }
    if (supplier.state || supplier.postcode) {
      result.push({
        name: "state_postcode",
        value: `${supplier.state} ${supplier.postcode}`.trim(),
      })
    }
    return result
  }, [supplier])

  return (
    <Card sx={{ width: "300px" }}>
      <CardActionArea component={Link} to={url}>
        <CardHeader
          title={
            <Tooltip
              title={
                supplier.supplier_account_id
                  ? "This supplier can login to see jobs allocated to them"
                  : "Your organisation manages jobs for this supplier internally"
              }
            >
              <TruncatedText
                variant="body1"
                noWrap
                sx={{
                  fontWeight: "bold",
                  maxWidth: "240px",
                }}
              >
                {supplier.name}
              </TruncatedText>
            </Tooltip>
          }
          avatar={
            supplier.supplier_account_id && (
              <icons.SupplierIcon sx={{ color: colors.pink[500] }} />
            )
          }
        />
        <CardContent>
          <Stack gap={1}>
            {supplier.email && (
              <Box sx={styles.supplierField}>
                <Box sx={{ width: "35px" }}>
                  <icons.EmailIcon fontSize="small" />
                </Box>
                <Box>
                  <TruncatedText variant="body2" sx={{ maxWidth: "220px" }}>
                    {supplier.email}
                  </TruncatedText>
                </Box>
              </Box>
            )}
            {supplier.phone && (
              <Box sx={styles.supplierField}>
                <Box sx={{ width: "35px" }}>
                  <icons.PhoneIcon fontSize="small" />
                </Box>
                <Box>
                  <Typography variant="body2">{supplier.phone}</Typography>
                </Box>
              </Box>
            )}
            {supplier.payment_terms && (
              <Box sx={styles.supplierField}>
                <Box sx={{ width: "35px" }}>
                  <Tooltip title="Payment terms">
                    <CalendarMonthIcon fontSize="small" />
                  </Tooltip>
                </Box>
                <Box>
                  <Typography variant="body2">
                    {supplier.payment_terms}
                  </Typography>
                </Box>
              </Box>
            )}
            {addrFields && addrFields.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                }}
              >
                <Box sx={{ width: "35px" }}>
                  <LocationIcon />
                </Box>
                <Box>
                  {addrFields &&
                    addrFields.map((field) => (
                      <Box key={field.name}>
                        <Typography variant="body2">{field.value}</Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            )}
          </Stack>

          {showDocuments && (
            <Box>
              <List dense sx={styles.supplierDocTypes}>
                {docStatus &&
                  docStatus.map((item) => (
                    <Alert
                      key={`${item.type}-${item.text}`}
                      severity={item.severity}
                    >
                      <AlertTitle>
                        <Typography variant="body2">{item.type}</Typography>
                      </AlertTitle>
                      <Typography variant="body2">{item.text}</Typography>
                    </Alert>
                  ))}
              </List>
            </Box>
          )}
          <Box
            sx={{
              my: "10px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            {supplier.job_types &&
              supplier.job_types.map((jobType) => (
                <Chip
                  key={jobType}
                  label={<Typography variant="caption">{jobType}</Typography>}
                  size="small"
                  sx={{ backgroundColor: colorServices.JOB_TYPES }}
                />
              ))}

            {supplier.coverage &&
              supplier.coverage.map((cov) => (
                <Chip
                  key={`${cov.state}-${cov.area}`}
                  label={
                    <Typography variant="caption">
                      {cov.state}, {cov.area}
                    </Typography>
                  }
                  size="small"
                  sx={{ backgroundColor: colorServices.COVERAGE }}
                />
              ))}
          </Box>

          <Box sx={{ marginTop: "5px", display: "flex", marginLeft: "auto" }}>
            <Typography variant="caption">
              Created{" "}
              <ReactTimeAgo date={supplier.created.toDate()} locale="en-AU" />
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default SupplierCard
