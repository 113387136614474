import * as actions from "../actionTypes"

const initialState = {
  value: "",
}

export default function jobSummarySearch(state = initialState, action) {
  switch (action.type) {
    case actions.SET_JOB_SUMMARY_SEARCH: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
