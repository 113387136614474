import Resizer from "react-image-file-resizer"
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  listAll,
} from "firebase/storage"

// Convert base64 into JavaScript file, so we can save it into firebase storage
const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

// Return all file names that are likely to be images based on their suffix
const getImageFiles = (fileNames) => {
  const imageFileNames = fileNames.filter((fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase()
    return imageMimeTypes[fileExtension]
  })

  return imageFileNames
}

const getHeightAndWidthFromDataUrl = (dataURL) =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      })
    }
    img.src = dataURL
  })

const resizeFile = async (file) => {
  const fileAsDataURL = window.URL.createObjectURL(file)
  let dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL)

  const maxWidth = 900
  const maxHeight = 600

  const isLandscapeOrientation = dimensions.width > dimensions.height

  if (isLandscapeOrientation && dimensions.width > maxWidth) {
    const scaleFactor = maxWidth / dimensions.width

    dimensions = {
      height: dimensions.height * scaleFactor,
      width: dimensions.width * scaleFactor,
    }
  } else if (dimensions.height > maxHeight) {
    const scaleFactor = maxHeight / dimensions.height

    dimensions = {
      height: dimensions.height * scaleFactor,
      width: dimensions.width * scaleFactor,
    }
  }

  return new Promise((resolve) => {
    const quality = 70 // percent
    Resizer.imageFileResizer(
      file,
      dimensions.width,
      dimensions.height,
      "JPEG",
      quality,
      0,
      (uri) => {
        resolve(uri)
      },
      "base64"
    )
  })
}

const getJobFilePath = (accountId, jobId) => {
  return `accounts/${accountId}/jobs/${jobId}/`
}

const getCheckListFilePath = (accountId, jobId) => {
  return `accounts/${accountId}/checklist_instances/${jobId}/`
}

const getUrl = async (path, fileName) => {
  const fullPath = `${path}/${fileName}`
  const storageRef = ref(getStorage(), fullPath)
  try {
    const url = await getDownloadURL(storageRef)
    return url
  } catch (error) {
    console.error("%cError getting url", "color:red", { error, fullPath })
    return undefined
  }
}

const getJobDocFileNames = async (accountId, jobId) => {
  const path = getJobFilePath(accountId, jobId)

  const storageRef = ref(getStorage(), path)

  const fileRefs = await listAll(storageRef)

  const promises = fileRefs.items.map((fileRef) => fileRef.getMetadata())

  const result = await Promise.all(promises)

  const fileNames = result.map((fileMeta) => fileMeta.name)

  return fileNames
}

const getCheckListInstanceFileUrl = async (
  accountId,
  checkListInstanceId,
  fileName
) => {
  const path = `accounts/${accountId}/checklist_instances/${checkListInstanceId}/`
  return await getUrl(path, fileName)
}

const getJobFileUrl = async (jobId, accountId, fileName) => {
  const path = `${getJobFilePath(accountId, jobId)}`
  return getUrl(path, fileName)
}

const getCheckListFileUrl = async (
  checkListInstanceId,
  accountId,
  fileName
) => {
  const path = `${getCheckListFilePath(accountId, checkListInstanceId)}`
  return getUrl(path, fileName)
}

const imageMimeTypes = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  bmp: "image/bmp",
  tiff: "image/tiff",
  tif: "image/tiff",
  svg: "image/svg+xml",
  ico: "image/x-icon",
  webp: "image/webp",
}

const getMimeType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase()
  return imageMimeTypes[fileExtension] || "application/octet-stream"
}

// TODO: delete this and use saveAttachments (below) instead in the job edit page
// const saveJobAttachments = async (files, filePath) => {
//   const pathRef = ref(getStorage(), filePath)
//   files.forEach(async (file, index) => {
//     const fileRef = ref(pathRef, file.name)
//     const fileObj = dataURLtoFile(file.data, file.name)
//     await uploadBytes(fileRef, fileObj)
//   })
// }

// const saveAttachments = async (files, filePath) => {
//   const pathRef = ref(getStorage(), filePath)
//   for (const file of files) {
//     const fileRef = ref(pathRef, file.name)
//     const fileObj = dataURLtoFile(file.data, file.name)
//     await uploadBytes(fileRef, fileObj)
//   }
// }

const saveJobAttachments = async (files, filePath) => {
  const pathRef = ref(getStorage(), filePath);
  for (const file of files) {
    const fileRef = ref(pathRef, file.name);
    const fileObj = dataURLtoFile(file.data, file.name);
    await uploadBytes(fileRef, fileObj);
  }
}

const saveAttachments = async (files, filePath) => {
  const pathRef = ref(getStorage(), filePath);
  for (const file of files) {
    const fileRef = ref(pathRef, file.name);
    const fileObj = dataURLtoFile(file.data, file.name);
    await uploadBytes(fileRef, fileObj);
  }
}


export {
  dataURLtoFile,
  resizeFile,
  saveJobAttachments,
  saveAttachments,
  getJobFileUrl,
  getCheckListFileUrl,
  getJobDocFileNames,
  getJobFilePath,
  getUrl,
  getImageFiles,
  getHeightAndWidthFromDataUrl,
  getCheckListInstanceFileUrl,
  getMimeType
}
