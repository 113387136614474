import React, { useMemo } from "react"
import { BarChart, PieChart, pieArcLabelClasses } from "@mui/x-charts"
import { Box } from "@mui/material"

const DashboardWidgetChart = ({
  type = "",
  groupedContent,
  expanded,
  setItemToRetrieveRows,
}) => {
  const data = useMemo(() => {
    if (groupedContent) {
      switch (type) {
        case "pie":
          return groupedContent.map((gc, index) => ({
            id: gc.id,
            value: gc.count,
            label: gc.value || "(None)",
            sql: gc.sql,
          }))

        case "bar":
          return {
            yLabels: groupedContent.map((gc) => gc.value || "(None)"),
            values: groupedContent.map((gc) => gc.count),
            ids: groupedContent.map((gc) => gc.id),
            sql: groupedContent.map((gc) => gc.sql),
          }

        default:
          return {
            xLabels: groupedContent.map((gc) => gc.value),
            values: groupedContent.map((gc) => gc.count),
          }
      }
    }
  }, [type, groupedContent])

  return (
    <Box sx={{ border: "1px dotted #ddd" }}>
      {data && (
        <Box>
          {type === "bar" && (
            <Bar
              data={data}
              expanded={expanded}
              setItemToRetrieveRows={setItemToRetrieveRows}
            />
          )}
          {type === "pie" && (
            <Pie
              data={data}
              expanded={expanded}
              setItemToRetrieveRows={setItemToRetrieveRows}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

const Pie = ({ data, expanded, setItemToRetrieveRows }) => {
  return (
    data && (
      <PieChart
        margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
        padding={0}
        series={[
          {
            data: data,
            innerRadius: 2,
            outerRadius: expanded ? 120 : 40,
            paddingAngle: 2,
            cornerRadius: 2,
            startAngle: -90,
            arcLabel: (item) => item.label,
            arcLabelMinAngle: 30,
          },
        ]}
        width={expanded ? 280 : 100}
        height={expanded ? 280 : 100}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontSize: expanded ? 12 : 8,
            //fontWeight: "bold",
          },
        }}
        colors={[
          "#708090",
          "#708238",
          "#5F9EA0",
          "#483C32",
          "#605856",
          "#5F8A8B",
          "#A9A9A9",
          "#9EA79D",
          "#36454F",
          "#C19A6B",
          "#367588",
          "#3E6257",
          "#658E67",
          "#8A3324",
        ]}
        slotProps={{
          legend: { hidden: true },
        }}
        onItemClick={(event, d) => {
          const item = {
            count: data[d.dataIndex].value,
            value: data[d.dataIndex].label,
            sql: data[d.dataIndex].sql,
          }

          setItemToRetrieveRows(item)
        }}
      />
    )
  )
}

const Bar = ({ data, expanded, setItemToRetrieveRows }) => {
  return (
    <Box>
      <BarChart
        yAxis={[{ scaleType: "band", data: data.yLabels }]}
        xAxis={[{ tickMinStep: 1 }]}
        series={[{ data: data.values }]}
        width={expanded ? 280 : 150}
        height={
          expanded
            ? Math.max(80, 50 + data.values.length * 20)
            : Math.min(280, 50 + data.values.length * 20)
        }
        colors={["#5F8A8B"]}
        layout="horizontal"
        slotProps={{
          legend: { hidden: true },
        }}
        margin={{ left: 100, right: 15, top: 10, bottom: 25 }}
        onItemClick={(event, d) => {
          const item = {
            count: data.values[d.dataIndex],
            value: data.yLabels[d.dataIndex],
            sql: data.sql[d.dataIndex],
          }

          setItemToRetrieveRows(item)
        }}
      />
    </Box>
  )
}

export default DashboardWidgetChart
