import { useEffect, useState } from "react"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import {
  Alert,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import Controls from "./controls/Controls"
import * as icons from "../icons"
import EditIcon from "@mui/icons-material/Edit"
import * as Roles from "../pages/services/roleServices"
import { useSnackbar } from "notistack"
import NavButtons from "./NavButtons"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const PriorityList = (props) => {
  const [accountId, setAccountId] = useState(null)

  const [priorities, setPriorities] = useState({})

  const [name, setName] = useState("")

  const [editId, setEditId] = useState(undefined)

  const [isEditable, setEditable] = useState(false)

  const [reload, setReload] = useState(0)

  const { enqueueSnackbar } = useSnackbar()

  const { width } = useWindowDimensions()

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      user.getIdTokenResult(false).then((token) => {
        setAccountId(token.claims.account_id)
        setEditable(Roles.isLookupEditable(token.claims.roles))
      })
    })

    return unsub
  }, [])

  useEffect(() => {
    if (accountId) {
      const unsub = db
        .collection("priorities")
        .where("account_id", "==", accountId)
        .onSnapshot(
          (querySnapshot) => {
            const priorities = []
            querySnapshot.forEach((doc) => {
              priorities.push({ id: doc.id, ...doc.data() })
            })
            if (priorities.length > 0) {
              setPriorities(priorities[0])
            }
          },
          (error) =>
            console.error("error listening for priority changes", error)
        )

      return unsub
    }
  }, [accountId, reload])

  const handleSavePriorities = () => {
    const { id, ...data } = priorities

    db.collection("priorities")
      .doc(id)
      .update({ ...data, modified: dataServices.serverTimestamp() })

    enqueueSnackbar("Priorities saved", { variant: "success" })

    setEditId(undefined)
  }

  const handleAddPriority = () => {
    if (name.trim() === "") {
      enqueueSnackbar("Priority name cannot be empty", { variant: "error" })
      return
    }

    setPriorities((curr) => {
      const newPriorities = { ...curr }
      // Get the next max id from the lookup_values list of items from the id attribute
      const nextId =
        (Math.max(...newPriorities.lookup_values.map((item) => item.id)) || 0) +
        1
      newPriorities.lookup_values.push({ id: nextId, title: name })
      return newPriorities
    })
  }

  const handleDeletePriority = (id) => {
    // Check if priority id value is used in a job
    db.collection("jobs")
      .where("account_id", "==", accountId)
      .where("priority", "==", id)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // Delete the priority
          setPriorities((curr) => {
            const newPriorities = { ...curr }
            newPriorities.lookup_values = newPriorities.lookup_values.filter(
              (item) => item.id !== id
            )
            return newPriorities
          })
        } else {
          enqueueSnackbar("Priority is in use and cannot be deleted", {
            variant: "info",
          })
        }
      })
  }

  return (
    <Box>
      {isEditable && (
        <Stack gap={2} sx={{ marginBottom: "30px", maxWidth: "500px" }}>
          <Controls.TextInput
            fullWidth={width < 400}
            label="Add Priority"
            sx={{ width: `${Math.min(width - 30, 350)}px` }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Box sx={{ maxWidth: "400px", marginLeft: "auto" }}>
            <Controls.Button text="Add Priority" onClick={handleAddPriority} />
          </Box>
        </Stack>
      )}
      {isEditable || (
        <Alert severity="info">
          Priorities are read only. Requires Admin role to edit
        </Alert>
      )}
      <Box sx={{ maxWidth: "500px", marginTop: "15px", marginLeft: "25px" }}>
        {priorities &&
          priorities.lookup_values?.map((priority) => {
            return (
              <Box
                key={priority.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {priority.id === editId && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "10px",
                    }}
                  >
                    <Controls.TextInput
                      autoFocus
                      sx={{ width: "80px" }}
                      fullWidth={false}
                      value={priority.title}
                      onChange={(e) => {
                        // Update the priority title

                        setPriorities((curr) => {
                          const newPriorities = { ...curr }
                          newPriorities.lookup_values =
                            newPriorities.lookup_values.map((item) => {
                              if (item.id === priority.id) {
                                item.title = e.target.value
                              }
                              return item
                            })
                          return newPriorities
                        })
                      }}
                    />
                    <Controls.TextInput
                      autoFocus
                      fullWidth={false}
                      value={priority.description}
                      onChange={(e) => {
                        // Update the priority description

                        setPriorities((curr) => {
                          const newPriorities = { ...curr }
                          newPriorities.lookup_values =
                            newPriorities.lookup_values.map((item) => {
                              if (item.id === priority.id) {
                                item.description = e.target.value
                              }
                              return item
                            })
                          return newPriorities
                        })
                      }}
                    />
                  </Box>
                )}
                {priority.id !== editId && (
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "5px" }}
                  >
                    <Box sx={{ width: "80px" }}>
                      <Typography variant="body2">{priority.title}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        {priority.description}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {isEditable && (
                  <Box sx={{ marginLeft: "auto" }}>
                    <Tooltip
                      title="Delete"
                      onClick={() => handleDeletePriority(priority.id)}
                    >
                      <IconButton>
                        <icons.DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => setEditId(priority.id)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            )
          })}
        {isEditable && (
          <NavButtons>
            <Controls.Button
              text="Save"
              onClick={(event) => handleSavePriorities(event)}
              endIcon={<icons.SaveIcon />}
            />
          </NavButtons>
        )}
      </Box>
    </Box>
  )
}

export default PriorityList
