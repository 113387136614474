import * as actions from "../actionTypes"

// last modified timestamp
const initialState = {
  modified: undefined,
}

export default function workOrderLastModified(state = initialState, action) {
  switch (action.type) {
    case actions.SET_WORK_ORDER_LAST_MODIFIED: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
