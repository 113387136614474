import React, { useEffect, useState } from "react"
import ComboBox from "./ComboBox"
import * as dataServices from "../../pages/services/dataServices"
import _ from "lodash"

const JobTypeComboBox = (props) => {
  const { name, label, value, onChange, helperText, readonly, accountId } =
    props

  const [reloadState, setReloadState] = useState({
    readonly: false,
    value: "",
    accountId: "",
  })

  const NONE_LABEL = "-- Select --"

  const [jobTypeOptions, setJobTypeOptions] = useState([
    {
      id: "",
      label: NONE_LABEL,
    },
  ])

  function createJobTypeOption(jobType) {
    return {
      id: jobType.id,
      label: jobType.label,
    }
  }

  const isValueChangedOnly = (newReloadState) => {
    const isValueChanged =
      newReloadState.readonly === reloadState.readonly &&
      newReloadState.value !== reloadState.value &&
      newReloadState.accountId === reloadState.accountId

    return isValueChanged
  }

  useEffect(() => {
    const logId = "[JOB TYPE COMBO]"
    if (accountId === undefined) {
      return
    }

    const newReloadState = { readonly, value, accountId }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    setReloadState(newReloadState)

    if (readonly) {
      if (value !== "") {
        dataServices.loadJobTypeOptions(accountId).then((jobTypes) => {
          const options = jobTypes.map((jobType) =>
            createJobTypeOption(jobType)
          )
          setJobTypeOptions(options)
        })
      }
    } else {
      if (!isValueChangedOnly(newReloadState)) {
        dataServices.loadJobTypeOptions(accountId, true).then((jobTypes) => {
          const options = jobTypes
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((jobType) => createJobTypeOption(jobType))
          options.push({ id: "", label: NONE_LABEL })
          setJobTypeOptions(options)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readonly, value, accountId])

  return (
    <ComboBox
      name={name}
      label={label}
      value={value}
      items={jobTypeOptions}
      onChange={onChange}
      helperText={helperText}
      disabled={readonly}
    />
  )
}

export default JobTypeComboBox
