import * as icons from "../../icons"
import { blue, grey, purple, amber } from "@mui/material/colors"
import * as fileServices from "./fileServices"
import { deleteObject, getStorage, ref, uploadString } from "firebase/storage"
import db from "../../Firestore"
import * as Roles from "./roleServices"
import { where } from "firebase/firestore"
import * as dataServices from "./dataServices"
import _ from "lodash"
import { getAuth } from "firebase/auth"

const JOB_STATUS_PENDING = "pending"
const JOB_STATUS_OPEN = "open"
const JOB_STATUS_CLOSED = "closed"
const JOB_STATUS_COMPLETED = "completed"

const statusColors = {
  [JOB_STATUS_OPEN]: blue[600],
  [JOB_STATUS_COMPLETED]: purple[700],
  [JOB_STATUS_CLOSED]: grey[900],
  [JOB_STATUS_PENDING]: amber[500],
}

const jobStatusLabels = {
  [JOB_STATUS_OPEN]: "Open",
  [JOB_STATUS_COMPLETED]: "Completed",
  [JOB_STATUS_CLOSED]: "Closed",
  [JOB_STATUS_PENDING]: "Pending",
}

const jobStatusSelectOptions = [
  { title: "Open", id: JOB_STATUS_OPEN },
  { title: "Closed", id: JOB_STATUS_CLOSED },
  { title: "Completed", id: JOB_STATUS_COMPLETED },
  { title: "Pending", id: JOB_STATUS_PENDING },
]

const ensureMandatoryFields = (job) => {
  return {
    docs: [],
    ...job,
  }
}

// Create file_info for a job for single file
const createFileInfo = ({ claims, fileName }) => {
  return {
    email: claims.email,
    user_id: claims.user_id,
    account_id: claims.account_id,
    created: dataServices.localTimestamp(),
    file_name: fileName,
  }
}

const uploadFiles = async (jobId, job, files, uploadingUser) => {
  const resizedPromises = files.map(async (file) => ({
    file: await fileServices.resizeFile(file),
    name: file.name,
    unique: getUniqueFileName(job, file.name),
  }))

  const resized = await Promise.all(resizedPromises)

  const folderPath = `accounts/${job.account_id}/jobs/${jobId}`

  for (const item of resized) {
    const fullFilePath = `${folderPath}/${item.unique}`
    const storageRef = ref(getStorage(), fullFilePath)
    const result = await uploadString(storageRef, item.file, "data_url")
  }

  const newFileNames = [...job.docs, ...resized.map((item) => item.unique)]

  const newFileInfo = [
    ...(job.file_info || []),
    ...resized.map((item) =>
      createFileInfo({ claims: uploadingUser.claims, fileName: item.unique })
    ),
  ]

  // Strip out any temp attributes added to the job: id, doc, index
  const { id, doc, centre, index, ...rest } = job

  const updatedJob = {
    ...rest,
    docs: newFileNames,
    file_info: newFileInfo,
    modified: dataServices.serverTimestamp(),
  }

  await db.collection("jobs").doc(jobId).update(updatedJob, { merge: true })

  return updatedJob
}

/**
 * Load job files
 *
 * @param {fileLoadedCallback} The callback to pass the loaded file info to
 */
const getJobFileInfo = async ({
  jobFileUrls,
  jobs,
  centres,
  fileLoadedCallback,
}) => {
  const xhrs = jobFileUrls.map((fileInfo) => {
    const xhr = new XMLHttpRequest()
    let base64data

    // This event occurs asynchonously
    xhr.onload = (event) => {
      var reader = new FileReader()
      reader.readAsDataURL(xhr.response)
      reader.onloadend = () => {
        base64data = reader.result

        // Prepare new base64 file entry, and use callback to store this.

        const base64strippedPrefix = base64data.split("base64,")[1]

        const job = jobs.find((job) => job.id === fileInfo.job_id)
        const centre = centres.find((centre) => centre.id === job.centre_id)

        const fileDataItem = {
          fileName: `${centre.short_name || centre.name}_${fileInfo.fileName}`,
          base64data: base64strippedPrefix,
        }
        fileLoadedCallback((curr) => [...curr, fileDataItem])
      }
    }
    xhr.responseType = "blob"
    xhr.open("GET", fileInfo.url, true)
    xhr.send()

    return xhr
  })
}

// Older jobs won't have the 'docs' attribute. So need to populate this from querying Firebase storage

const getJobFileNames = async (job, jobId) => {
  // July 1st was when we added the 'docs' attribute to jobs
  const JULY_1ST = 1625107001

  if (job.modified?.seconds < JULY_1ST) {
    const fileNames = await Storage.getJobDocFileNames(job.account_id, jobId)
    return fileNames
  } else {
    return ensureMandatoryFields(job).docs
  }
}

const getJobFileUrls = async ({ jobs }) => {
  const newFileInfo = _.flatten(
    jobs.map((job) =>
      job.docs.map(async (fileName) => {
        return {
          job_id: job.id,
          fileName: fileName,
          url: await fileServices.getJobFileUrl(
            job.id,
            job.account_id,
            fileName
          ),
        }
      })
    )
  )

  return await Promise.all(newFileInfo)
}

const getUniqueFileName = (job, fileName) => {
  let uniqueFileName

  // See if file name already used. If so, we'll rename the file just uploaded
  const found = job.docs.find((name) => name === fileName)

  if (found) {
    const parts = fileName.split(".")

    let foundUniqueName = false
    let counter = 1
    while (!foundUniqueName) {
      // Create a new file name to use. If the file name has no suffix just add a " (1)", " (2)", etc as required until we get a unique name
      // Otherwise if there is a suffix then make sure we strip the suffix out

      uniqueFileName =
        parts.length === 1
          ? fileName + ` {${counter})`
          : parts.slice(0, -1).join(".") +
            ` (${counter}).` +
            parts.slice(-1).pop()

      const isNewNameUsed = job.docs.find(
        (fileName) => uniqueFileName === fileName
      )
      if (!isNewNameUsed) {
        foundUniqueName = true
      } else {
        counter = counter + 1
      }
    }
  } else {
    uniqueFileName = fileName
  }
  return uniqueFileName
}

const getJobQueryParams = ({ direction, pagination, currentUser }) => {
  return {
    centreId: pagination.centre_id,
    accountType: currentUser.claims.account_type,
    accountId: currentUser.claims.account_id,
    centreIds: currentUser.claims.centre_ids,
    priority: pagination.priority,
    supplierId: pagination.supplier_id,
    jobStatusFilter: pagination.job_status_filter,
    isJobAdmin: currentUser.claims.roles.includes(Roles.JOB_ADMIN),
    jobAllocated: pagination.job_allocated,
    textSearch: pagination.text_search,
    orderBy: pagination.orderBy,
    // If order is 'prev' then swap the order
    order:
      direction === "next"
        ? pagination.order
        : pagination.order === "desc"
        ? "asc"
        : "desc",
    jobTypesFilter: pagination.job_types,
  }
}

/**
 * For the given work order, set any Pending jobs to Open, so that the supplier can see them
 *
 * This function should only be called by centre users, not supplier users
 *
 * @param {string} accountId The account id in the user's claims
 * @param {string} workOrderId The work order id to open any pending jobs for
 */
const openAnyPendingJobs = async ({ accountId, workOrderId }) => {
  const jobs = await db
    .collection("jobs")
    .where("account_id", "==", accountId)
    .where("work_order_id", "==", workOrderId)
    .where("status", "==", JOB_STATUS_PENDING)
    .get()

  const promises = jobs.docs.map(async (doc) => {
    return db.collection("jobs").doc(doc.id).update({ status: JOB_STATUS_OPEN })
  })

  await Promise.all(promises)
}

/**
 * @param {string[]} centreIds The centre ids in the user's claims
 * @param {string} accountType The account type in the user's claims
 * @param {string} accountId The account id in the user's claims
 * @param {string} supplierId The selected supplier to filter by, if any.
 * @param {string[]} jobStatusFilter The selected job statuses to filter by, if any.
 * @param {int} priority The selected priority to filter by, if any.
 * @param {boolean} isJobAdmin Is the user a job admin
 * @param {string} centreId The currently selected centre to filter by, if any.
 * @param {string} jobAllocated Filter by whether the job is allocated to a supplier or not
 * @param {string} textSearch The text to search for in the job label or description
 * @param {string} orderBy The field to order by and the order (asc/desc)
 * @param {string} order The order to sort by (asc/desc)
 * @param {string[]} jobTypesFilter The job types to filter by
 * @param {*} param0
 */
const createJobQuery = ({
  centreId,
  accountType,
  accountId,
  centreIds,
  priority,
  supplierId,
  jobStatusFilter,
  isJobAdmin,
  jobAllocated,
  textSearch,
  orderBy,
  order,
  jobTypesFilter,
}) => {
  const queryMods = []

  const queryConstraints = []

  const addAccountIdQuery = () => {
    switch (accountType) {
      case "centre":
        queryConstraints.push(where("account_id", "==", accountId))
        queryMods.push(`account_id == ${accountId}`)
        break

      case "supplier":
        queryConstraints.push(
          where("supplier_access_account_ids", "array-contains", accountId)
        )
        queryMods.push(
          `supplier_access_account_ids array-contains ${accountId}`
        )
        break

      default:
        throw new Error("Unknown account type " + accountType)
    }
  }

  const addCentreToQuery = () => {
    if (centreIds.length > 0) {
      // If single centre selected, filter by that center
      if (!isJobAdmin) {
        if (centreIds.length === 1) {
          // Mininise use of 'in' operator as we're only allowed 1 per query
          queryConstraints.push(where("centre_id", "==", centreIds[0]))
          queryMods.push(`centre_id == ${centreIds[0]}`)
        } else {
          queryConstraints.push(where("centre_id", "in", centreIds))
          queryMods.push(`filter by centres ${centreIds}`)
        }
      }
    } else if (centreId !== "") {
      if (isJobAdmin || centreIds.includes(centreId)) {
        queryConstraints.push(where("centre_id", "==", centreId))
        queryMods.push(`centre_id == ${centreId}`)
      } else if (!isJobAdmin) {
      }
    }
  }

  const addJobStatusToQuery = () => {
    switch (accountType) {
      case "centre":
        if (jobStatusFilter && jobStatusFilter.length !== 0) {
          if (jobStatusFilter.length === 1) {
            queryConstraints.push(where("status", "==", jobStatusFilter[0]))
            queryMods.push(`status == ${jobStatusFilter[0]}`)
          } else {
            queryConstraints.push(where("status", "in", jobStatusFilter))
            queryMods.push(`status in ${jobStatusFilter}`)
          }
        }
        break

      case "supplier":
        // No filter means the supplier can only access open, closed, and completed jobs
        if (!jobStatusFilter || jobStatusFilter.length === 0) {
          queryConstraints.push(
            where("status", "in", [
              JOB_STATUS_OPEN,
              JOB_STATUS_CLOSED,
              JOB_STATUS_COMPLETED,
            ])
          )
          queryMods.push(
            `status in ${[
              JOB_STATUS_OPEN,
              JOB_STATUS_CLOSED,
              JOB_STATUS_COMPLETED,
            ]}`
          )
        } else {
          if (jobStatusFilter.length === 1) {
            queryConstraints.push(where("status", "==", jobStatusFilter[0]))
            queryMods.push(`status == ${jobStatusFilter[0]}`)
          } else {
            queryConstraints.push(where("status", "in", jobStatusFilter))
            queryMods.push(`status in ${jobStatusFilter}`)
          }
        }
        break

      default:
        throw new Error("Unknown account type " + accountType)
    }
  }

  if (textSearch && accountType === "centre") {
    // split the text search into individual words and make lowercase
    const words = textSearch.toLowerCase().split(" ")
    queryConstraints.push(where("search_index", "array-contains-any", words))
    queryMods.push(`search_index contains ${words.join(", ")}`)

    addAccountIdQuery()

    addCentreToQuery()

    addJobStatusToQuery()

    // if doing a text search, cannot use any other fields
    return { queryMods, queryConstraints }
  }

  addCentreToQuery()

  if (jobAllocated === "Y") {
    queryConstraints.push(where("work_order_id", "!=", ""))
    queryMods.push("work_order_id not empty")
  } else if (jobAllocated === "N") {
    queryConstraints.push(where("work_order_id", "==", ""))
    queryMods.push("work_order_id is empty")
  } else {
    queryMods.push("include jobs both on/off a work order")
  }

  addAccountIdQuery()

  if (priority !== undefined && priority !== 0 && priority !== "") {
    queryConstraints.push(where("priority", "==", priority))
    queryMods.push(`priority == ${priority}`)
  }

  if (supplierId) {
    queryConstraints.push(where("supplier_id", "==", supplierId))
    queryMods.push(`supplier_id == ${supplierId}`)
  }

  addJobStatusToQuery()

  if (jobTypesFilter && jobTypesFilter.length === 1) {
    queryConstraints.push(where("category", "==", jobTypesFilter[0]))
    queryMods.push(`category == ${jobTypesFilter[0]}`)
  } else if (jobTypesFilter && jobTypesFilter.length > 1) {
    queryConstraints.push(where("category", "in", jobTypesFilter))
    queryMods.push(`category in ${jobTypesFilter}`)
  }

  return { queryMods, queryConstraints }
}

const getPriorityLabel = (priority) => {
  return priority && `${priority.title} - ${priority.description}`
}

const getJobFilePath = ({ job, jobId }) => {
  return `accounts/${job.account_id}/jobs/${jobId}`
}

const hasNonClosedJobActions = async (jobId, accountId) => {
  const token = await getAuth().currentUser.getIdTokenResult()

  if (token.claims.account_type === "centre") {
    const actions = await db
      .collection("actions")
      .where("parent_id", "==", jobId)
      .where("type", "==", "job")
      .where("account_id", "==", accountId)
      .where("status", "!=", JOB_STATUS_CLOSED)
      .limit(1)
      .get()

    return actions.docs.length > 0
  } else if (token.claims.account_type === "supplier") {
    console.log("%cget supplier actions", "color:pink")
    const actions = await db
      .collection("actions")
      .where("parent_id", "==", jobId)
      .where("type", "==", "job")
      .where("supplier_account_id", "==", token.claims.account_id)
      .where("status", "!=", JOB_STATUS_CLOSED)
      .limit(1)
      .get()

    return actions.docs.length > 0
  }
  return false
}

const deleteJobFile = async ({ job, jobId, fileName }) => {
  const path = `${getJobFilePath({ job, jobId })}/${fileName}`

  const fileRef = ref(getStorage(), path)

  try {
    await deleteObject(fileRef)
  } catch (error) {
    console.error("Error deleting file", { error })
  }
  const newFiles = job.docs.filter((doc) => doc !== fileName)

  // Ensure newInfo only contains files that are in docs
  const newInfo =
    job.file_info?.filter((info) => newFiles.includes(info.file_name)) || []

  const mergeValues = {
    ...job,
    docs: newFiles,
    file_info: newInfo,
    modified: dataServices.serverTimestamp(),
  }

  await db.collection("jobs").doc(jobId).update(mergeValues, { merge: true })

  return mergeValues
}

const getAvailableJobStatuses = (status, isSupplierViewing) => {
  const close = {
    label: "Mark as Closed",
    value: JOB_STATUS_CLOSED,
    icon: icons.JobCloseIcon,
  }
  const reopen = {
    label: "Mark as Open",
    value: JOB_STATUS_OPEN,
    icon: icons.JobOpenIcon,
  }
  const complete = {
    label: "Mark as Completed",
    value: JOB_STATUS_COMPLETED,
    icon: icons.JobCompleteIcon,
  }
  const pending = {
    label: "Mark as Pending",
    value: JOB_STATUS_PENDING,
    icon: icons.JobPendingIcon,
  }

  const statuses = []

  if (status === JOB_STATUS_OPEN) {
    if (!isSupplierViewing) {
      statuses.push(close)
      statuses.push(pending)
    }
    statuses.push(complete)
  } else if (status === JOB_STATUS_CLOSED) {
    statuses.push(reopen)
  } else if (status === JOB_STATUS_COMPLETED) {
    statuses.push(reopen)
    if (!isSupplierViewing) {
      statuses.push(close)
    }
  } else if (status === JOB_STATUS_PENDING) {
    statuses.push(reopen)
    statuses.push(complete)
  }

  return statuses
}

// Get priorities configs for a single account
const getJobPriorities = async (accountId) => {
  const priorities = await db
    .collection("priorities")
    .where("account_id", "==", accountId)
    .get()

  if (priorities.size > 0) {
    return priorities.docs[0].data()
  }
}

// Get priorities configs for multiple accounts
const getJobPrioritiesForAccounts = async (accountIds) => {
  if (accountIds.length === 0) {
    return []
  }

  const prioritiesForAccounts = await db
    .collection("priorities")
    .where("account_id", "in", accountIds)
    .get()

  return prioritiesForAccounts.docs.map((doc) => doc.data())
}

export {
  ensureMandatoryFields,
  getJobFileNames,
  getUniqueFileName,
  getAvailableJobStatuses,
  getJobQueryParams,
  createJobQuery,
  getJobPriorities,
  getJobFileInfo,
  getJobPrioritiesForAccounts,
  hasNonClosedJobActions,
  uploadFiles,
  getJobFileUrls,
  getPriorityLabel,
  openAnyPendingJobs,
  createFileInfo,
  deleteJobFile,
  jobStatusLabels,
  statusColors,
  JOB_STATUS_OPEN,
  JOB_STATUS_CLOSED,
  JOB_STATUS_COMPLETED,
  JOB_STATUS_PENDING,
  jobStatusSelectOptions,
}
