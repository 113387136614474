import { Line } from "react-chartjs-2"

const DashboardPivotLineChart = () => {
  const data = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
      {
        label: "Dataset 2",
        data: [28, 48, 40, 19, 86, 27, 90],
        fill: false,
        borderColor: "rgb(255, 99, 132)",
        tension: 0.1,
      },
    ],
  }

  return (
    <Line
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      }}
      data={data}
    />
  )
}

export default DashboardPivotLineChart
