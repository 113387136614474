import React, { useState } from "react"
import _ from "lodash"
import { styled } from "@mui/material/styles"

export function useForm(initialValues) {
    const [values, setValues] = useState(initialValues)

    const handleInputChange = (e) => {
        const { name, value } = e.target

        const newValues = {
            ...values,
        }
        // handle input field names with a dot in them, allowing updates to nested JSON object
        _.set(newValues, name, value)
        setValues(newValues)
    }

    return {
        values,
        setValues,
        handleInputChange,
    }
}

const StyledForm = styled("form")(({ theme }) => ({
    "& .MuiFormControl-root": {
        width: "90%",
        margin: theme.spacing(0.8),
    },
}))

export function Form(props) {
    return <StyledForm autoComplete="off">{props.children}</StyledForm>
}
