import Header from "../components/Header"
import Controls from "../components/controls/Controls"
import * as cloudFunctions from "./services/cloudFunctions"
import { useEffect, useState } from "react"
import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import db from "../Firestore"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { useMemo } from "react"
import SupplierCombobox from "../components/controls/SupplierCombobox"
import SubHeading from "../components/SubHeading"
import TruncatedText from "../components/TruncatedText"
import LabelValue from "../components/LabelValue"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const XeroLoginPage = ({ title }) => {
  const [isConnecting, setConnecting] = useState(false)

  const [isAccessTokenFound, setAccessTokenFound] = useState(false)

  const [tokenSet, setTokenSet] = useState()

  const [claims, setClaims] = useState()

  const [tenants, setTenants] = useState()

  const [selectedTenant, setSelectedTenant] = useState()

  const [purchaseOrders, setPurchaseOrders] = useState()

  const [selectedPOReference, setSelectedPOReference] = useState("")

  const [bills, setBills] = useState()

  const [supplierId, setSupplierId] = useState("")

  const [supplierName, setSupplierName] = useState("")

  const [contacts, setContacts] = useState()

  useEffect(() => {
    if (supplierId) {
      const unsub = db
        .collection("suppliers")
        .doc(supplierId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const supplier = doc.data()
            setSupplierName(supplier.name)
          }
        })

      return unsub
    }
  }, [supplierId])

  const expires = useMemo(() => {
    if (tokenSet) {
      // convert tokenSet.expires_at (e.g. 1700560166790) to a datetime str in the format dd/mm/yyyy hh:mm:ss
      return new Date(tokenSet.expires_at).toLocaleString()
    }
  }, [tokenSet])

  const isExpired = useMemo(() => {
    if (tokenSet) {
      // convert tokenSet.expires_at (e.g. 1700560166790) to a datetime str in the format dd/mm/yyyy hh:mm:ss
      return new Date(tokenSet.expires_at) < new Date()
    }
  }, [tokenSet])

  useEffect(() => {
    if (claims) {
      // Listen for changes to access_tokens collection for this user id

      console.log("claims", claims)
      const unsub = db
        .collection("access_tokens")
        .where("user_id", "==", claims.user_id)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log("doc", doc.data())
            setAccessTokenFound(true)
            setTokenSet(doc.data())
          })
        })

      return unsub
    }
  }, [claims])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdTokenResult()
          .then((token) => {
            setClaims(token.claims)
          })
          .catch((error) => {
            console.log("Error getting token in AppMenu", error)
          })
      }
    })

    return unsub
  }, [])

  const handleLogin = () => {
    setConnecting(true)
    cloudFunctions.getXeroOauthUrl().then((result) => {
      console.log("url", { url: result.data })
      window.location.href = result.data
      setConnecting(false)
    })
  }

  const handleRefresh = () => {
    setConnecting(true)
    cloudFunctions.getXeroRefreshToken().then((result) => {
      console.log("result", result)
      setConnecting(false)
    })
  }

  const handleGetTenants = () => {
    cloudFunctions
      .getXeroTenants()
      .then((result) => {
        console.log("result", result)
        setTenants(result.data.tenants)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  const handleGetBillsByReference = () => {
    cloudFunctions
      .getXeroBillsByReference({
        tenantId: selectedTenant.tenantId,
        reference: selectedPOReference,
      })
      .then((result) => {
        console.log("result", { result, selectedPOReference })
        setBills(result.data.bills)
      })
  }

  const handleGetPurchaseOrders = () => {
    cloudFunctions
      .getXeroPurchaseOrders({ tenantId: selectedTenant.tenantId })
      .then((result) => {
        console.log("result", result)
        setPurchaseOrders(result.data.purchaseOrders)
      })
  }

  // Get the supplier details from all
  const handleGetSupplier = () => {
    cloudFunctions
      .getXeroSupplier({ tenantId: selectedTenant.tenantId, supplierId })
      .then((result) => {
        console.log("result", result)
      })
  }

  const handleGetContacts = () => {
    cloudFunctions
      .getXeroContacts({
        tenantId: selectedTenant.tenantId,
        contactName: supplierName,
      })
      .then((result) => {
        console.log("result", result)
        setContacts(result.data.contacts)
      })
  }

  const handleCreateSupplier = async () => {
    const supplierDoc = await db.collection("suppliers").doc(supplierId).get()

    const supplier = supplierDoc.data()

    const supplierData = {
      name: supplier.name,
      contactStatus: "ACTIVE",
    }

    if (supplier.email) {
      supplierData.emailAddresses = [{ emailAddress: supplier.email }]
    }

    if (supplier.phone) {
      supplierData.phones = [
        { phoneNumber: supplier.phone, phoneType: "DEFAULT" },
      ]
    }

    if (supplier.address) {
      supplierData.addresses = [
        {
          addressType: "STREET",
          addressLine1: supplier.addr1,
          addressLine2: supplier.addr2,
          city: supplier.city,
          region: supplier.state,
          postalCode: supplier.postcode,
          country: supplier.country,
        },
      ]
    }

    console.log("creating supplier", {
      tenantId: selectedTenant.tenantId,
      supplier: supplierData,
    })

    cloudFunctions
      .createXeroSupplier({
        tenantId: selectedTenant.tenantId,
        supplier: supplierData,
      })
      .then((result) => {
        console.log("result", result)
      })
  }

  return (
    <Header title={title}>
      <Stack gap={2}>
        <Stack direction="row" gap={1}>
          <Controls.Button onClick={handleLogin} text="Connect" />
          {isAccessTokenFound && (
            <Controls.Button onClick={handleRefresh} text="Refresh Access" />
          )}
        </Stack>
        {isConnecting && <CircularProgress />}
        <Stack direction="row" gap={1}>
          {isAccessTokenFound && !isExpired && (
            <Typography>Connected to Xero</Typography>
          )}
        </Stack>
        {isExpired && (
          <Alert severity="warning">
            Token has expired. Click Refresh Access
          </Alert>
        )}
        <TruncatedText sx={{ maxWidth: "700px" }} component="span">
          {tokenSet && <pre>{JSON.stringify(tokenSet, null, 2)}</pre>}
        </TruncatedText>
        <Typography>Expires: {expires}</Typography>

        <Stack direction="row" gap={1}>
          <Controls.Button
            onClick={handleGetTenants}
            text="Get Tenants"
            disabled={isExpired}
          />
        </Stack>
        <Stack gap={2}>
          <Stack gap={2}>
            <SubHeading title="Tenants" />
            <Box
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
              gap={1}
            >
              {tenants?.map((tenant) => (
                <TenantCard
                  key={tenant.tenantId}
                  tenant={tenant}
                  selectedTenant={selectedTenant}
                  setSelectedTenant={setSelectedTenant}
                />
              ))}
            </Box>
          </Stack>
          <Stack gap={2}>
            <SubHeading title="Purchase Orders" />
            <Box>
              <Controls.Button
                onClick={handleGetPurchaseOrders}
                text="Get Purchase Orders"
                disabled={isExpired || !selectedTenant}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
              gap={1}
            >
              {purchaseOrders?.map((po) => (
                <PurchaseOrderCard
                  key={po.purchaseOrderID}
                  po={po}
                  selectedPOReference={selectedPOReference}
                  setSelectedPOReference={setSelectedPOReference}
                />
              ))}
            </Box>
          </Stack>
          <Stack gap={2}>
            <SubHeading title="Bills" />
            <Box>
              <Controls.Button
                onClick={handleGetBillsByReference}
                text="Get Bills"
                disabled={isExpired || !selectedTenant}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
              gap={1}
            >
              {/* {bills?.map((bill) => (
                <BillCard
                  key={bill.purchaseOrderID}
                  po={po}
                  selectedPOReference={selectedPOReference}
                  setSelectedPOReference={setSelectedPOReference}
                />
              ))} */}
            </Box>
          </Stack>
        </Stack>
        <Stack gap={2}>
          <SubHeading title="Suppliers" />
          <Box sx={{ width: "300px" }}>
            {claims && claims.account_id && (
              <SupplierCombobox
                name="supplier_id"
                label="Supplier"
                value={supplierId}
                accountId={claims.account_id}
                onChange={(e) => setSupplierId(e.target.value)}
              />
            )}
          </Box>
          <Stack direction="row" gap={2}>
            <Controls.Button
              text="Get Supplier"
              onClick={handleGetContacts}
              disabled={supplierId === "" || selectedTenant === undefined}
            />
            <Controls.Button
              text="Create Supplier"
              onClick={handleCreateSupplier}
              disabled={supplierId === "" || selectedTenant === undefined}
            />
          </Stack>

          <Box
            sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            gap={1}
          >
            {contacts?.map((contact) => (
              <ContactCard key={contact.contactID} contact={contact} />
            ))}
          </Box>
        </Stack>
      </Stack>
    </Header>
  )
}

const ContactCard = ({ contact }) => {
  return (
    <Card sx={{ width: "400px" }}>
      <CardActionArea>
        <CardHeader
          title={
            <Typography sx={{ fontWeight: "bold" }}>{contact.name}</Typography>
          }
          subheader={
            <Typography variant="body2" color="text.secondary">
              {contact.contactID}
            </Typography>
          }
        />
        <CardContent>
          <LabelValue label="Status" value={contact.contactStatus} />
          <LabelValue label="Email" value={contact.emailAddress} />
          <LabelValue label="Phone" value={contact.phoneNumber} />
          <LabelValue
            label="Address"
            value={contact.addresses[0].addressLine1}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const TenantCard = ({ tenant, selectedTenant, setSelectedTenant }) => {
  return (
    <Card sx={{ width: "400px" }}>
      <CardActionArea onClick={() => setSelectedTenant(tenant)}>
        <CardHeader
          title={
            <Typography
              sx={
                tenant.tenantId === selectedTenant?.tenantId
                  ? {
                      fontWeight: "bold",
                    }
                  : {}
              }
            >
              {tenant.tenantName}
            </Typography>
          }
        />
        <CardContent>
          <LabelValue label="Id" value={tenant.id} />
          <LabelValue label="Tenant Id" value={tenant.tenantId} />
          <LabelValue label="Base currency" value={tenant.baseCurrency} />
          <LabelValue
            label="Default sales tax"
            value={tenant.orgData.defaultSalesTax}
          />
          <LabelValue
            label="Default purchases tax"
            value={tenant.orgData.defaultPurchasesTax}
          />
          <LabelValue
            label="Line of business"
            value={tenant.orgData.lineOfBusiness}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const PurchaseOrderCard = ({
  po,
  selectedPOReference,
  setSelectedPOReference,
}) => {
  return (
    <Card key={po.purchaseOrderID} sx={{ width: "400px" }}>
      <CardActionArea onClick={() => setSelectedPOReference(po.reference)}>
        <CardHeader
          title={
            <Typography
              sx={
                po.reference === selectedPOReference
                  ? {
                      fontWeight: "bold",
                    }
                  : {}
              }
            >
              {po.purchaseOrderNumber}
            </Typography>
          }
          subheader={
            <Typography variant="body2" color="text.secondary">
              {po.reference}
            </Typography>
          }
        />
        <CardContent>
          <LabelValue label="Status" value={po.status} />
          <LabelValue label="Supplier" value={po.contact.name} />
          <LabelValue label="Supplier ID" value={po.contact.contactID} />
          <LabelValue label="Subtotal" value={po.subTotal.toFixed(2)} />
          <LabelValue label="Total Tax" value={po.totalTax.toFixed(2)} />
          <LabelValue label="Total" value={po.total.toFixed(2)} />
          <LabelValue label="Currency" value={po.currencyCode} />
          <Box sx={{ marginTop: "10px" }}>
            <Typography sx={{ fontWeight: "bold" }}>Line Items</Typography>
            {po.lineItems.map((lineItem) => (
              <LabelValue
                key={lineItem.lineItemID}
                label={`${lineItem.description} ${lineItem.quantity} x ${lineItem.unitAmount}`}
                value={lineItem.lineAmount.toFixed(2)}
              />
            ))}
          </Box>
          <Box sx={{ marginTop: "5px" }}>
            <Divider />
          </Box>
        </CardContent>
      </CardActionArea>
      <Expander>
        <pre>{JSON.stringify(po, null, 2)}</pre>
      </Expander>
    </Card>
  )
}

const Expander = ({ children }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Stack>
      <Box sx={{ justifyContent: "center" }}>
        <IconButton
          aria-label="expand"
          onClick={() => setExpanded((curr) => !curr)}
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {expanded && children}
    </Stack>
  )
}

export default XeroLoginPage
