import { useEffect, useState } from "react"
import db from "../Firestore"
import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import TruncatedText from "./TruncatedText"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import SendIcon from "@mui/icons-material/Send"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import ApprovalIcon from "@mui/icons-material/Approval"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import EventBusyIcon from "@mui/icons-material/EventBusy"
import StatusChip from "./controls/StatusChip"
import { format } from "date-fns"
import QuoteDialog from "./QuoteDialog"
import * as dataServices from "../pages/services/dataServices"
import * as workOrderServices from "../pages/services/workOrderServices"
import * as workOrderStatus from "./workOrderStatus"
import * as quoteServices from "../pages/services/quoteServices"
import { renderEmailComponent } from "../pages/services/emailServices"
import RFQEmail from "../emails/RFQEmail"
import { sendHtmlEmail } from "../pages/services/cloudFunctions"
import PreviewEmailDialog from "./PreviewEmailDialog"
import * as icons from "../icons"
import * as roleServices from "../pages/services/roleServices"
import * as supplierServices from "../pages/services/supplierServices"
import * as jobServices from "../pages/services/jobServices"
import { useMemo } from "react"
import { Desktop, Mobile } from "./WindowSizes"
import LabelValue from "./LabelValue"
import Controls from "./controls/Controls"
import SubHeading from "./SubHeading"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const styles = {
  tableHead: {
    backgroundColor: "theme.palette.primary.light",
    color: "theme.palette.common.white",
    minWidth: "45px",
    padding: "4px 4px",
    fontWeight: "bold",
  },
  tableHeadMenu: {
    backgroundColor: "theme.palette.primary.light",
    color: "theme.palette.common.white",
    maxWidth: "20px",
    padding: "2px 2px",
  },
  cellSmall: {
    minWidth: "45px",
    padding: "4px 4px",
  },
}

// Quotes grid displayed on the work order page
const WorkOrderQuotesGrid = (props) => {
  const {
    workOrderId,
    // work order status
    status,
    workOrderAccountId,
    jobs,
    centres,
    priorities,
    updateWorkOrder,
  } = props

  const [accountId, setAccountId] = useState()

  const [claims, setClaims] = useState()

  const [showQuoteDialog, setShowQuoteDialog] = useState(false)

  const [showPreviewEmailDialog, setShowPreviewEmailDialog] = useState(false)

  const [suppliers, setSuppliers] = useState([])

  const [userEmail, setUserEmail] = useState()

  const [accountType, setAccountType] = useState()

  // to, subject, html
  const [msgProps, setMsgProps] = useState({})

  const [approvers, setApprovers] = useState([])

  // Quote being edited
  const [editQuote, setEditQuote] = useState({})

  const [quotes, setQuotes] = useState([])

  useEffect(() => {
    if (workOrderAccountId && quotes) {
      const supplierIds = quotes.reduce((acc, quote) => {
        if (quote.supplier_id && !acc.includes(quote.supplier_id)) {
          acc.push(quote.supplier_id)
        }
        return acc
      }, [])

      dataServices
        .getSuppliersByIdChunks(supplierIds, workOrderAccountId)
        .then((suppliersResult) => {
          setSuppliers(suppliersResult)
        })
    }
  }, [quotes])

  const isMakeVisibleToSupplierRequired = useMemo(() => {
    const isExternalSupplier =
      suppliers.find((s) => s.supplier_account_id) !== undefined

    if (status && suppliers) {
      return status === workOrderStatus.STATUS_OPEN && isExternalSupplier
    }
    return false
  }, [status, suppliers])

  useEffect(() => {
    if (accountId) {
      try {
        db.collection("users")
          .where("account_id", "==", accountId)
          .where("roles", "array-contains", roleServices.WORK_ORDER_APPROVER)
          .get()
          .then((querySnapshot) => {
            const approvers = querySnapshot.docs.map((doc) => {
              return { id: doc.id, ...doc.data() }
            })
            setApprovers(approvers)
          })
      } catch (error) {
        console.error("Error getting approvers", error)
      }
    }
  }, [accountId])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          setClaims(token.claims)
          setAccountId(token.claims.account_id)
          setAccountType(token.claims.account_type)
          setUserEmail(user.email)
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    if (workOrderId && claims) {
      let unsub

      switch (claims.account_type) {
        case "centre":
          unsub = db
            .collection("work_orders")
            .doc(workOrderId)
            .collection("quotes")
            .where("account_id", "==", claims.account_id)
            .onSnapshot(
              (querySnapshot) => {
                const quotes = querySnapshot.docs.map((doc) => {
                  return { id: doc.id, ...doc.data() }
                })

                setQuotes(quotes)
              },
              (error) => console.error("Error getting quotes", error)
            )

          break

        case "supplier":
          unsub = db
            .collection("work_orders")
            .doc(workOrderId)
            .collection("quotes")
            .where("supplier_account_id", "==", claims.account_id)
            .onSnapshot(
              (querySnapshot) => {
                const quotes = querySnapshot.docs.map((doc) => {
                  return { id: doc.id, ...doc.data() }
                })

                setQuotes(quotes)
              },
              (error) => console.error("Error getting quotes", error)
            )

          break

        default:
          console.error("Unknown account type", claims.account_type)
          break
      }

      return unsub
    }
  }, [workOrderId, claims])

  const handleSendRFQEmailWithoutPreview = async ({ supplier, quoteId }) => {
    const workOrderDoc = await db
      .collection("work_orders")
      .doc(workOrderId)
      .get()
    const workOrder = workOrderDoc.data()

    const centreContactIds = Array.from(
      new Set(
        centres.map((centre) => centre.user_id_contact).filter((id) => id)
      )
    )

    dataServices
      .getUsersByIdChunks(workOrder.account_id, centreContactIds)
      .then((users) => {
        const centreContacts = centres.map((centre) => ({
          centreId: centre.id,
          user: users.find((contact) => contact.id === centre.user_id_contact),
        }))

        renderEmailComponent(
          RFQEmail,
          { workOrder, jobs, centres, priorities, centreContacts },
          (html) => {
            const sendParams = {
              html: html,
              to: supplier?.email,
              replyTo: userEmail,
              text: `Please provide a quote for ${workOrder.work_order_no}`,
              subject: `RFQ for ${workOrder.work_order_no}`,
              quoteId: quoteId,
            }

            handleSendEmailAndMarkSent(sendParams)
            updateQuoteAsSent({ workOrderId, quoteId })
          }
        )
      })
  }

  const handlePreviewRFQEmail = async ({ quoteId, supplier }) => {
    const workOrderDoc = await db
      .collection("work_orders")
      .doc(workOrderId)
      .get()
    const workOrder = workOrderDoc.data()

    const centreContactIds = Array.from(
      new Set(
        centres.map((centre) => centre.user_id_contact).filter((id) => id)
      )
    )

    dataServices
      .getUsersByIdChunks(workOrder.account_id, centreContactIds)
      .then((users) => {
        const centreContacts = centres.map((centre) => ({
          centreId: centre.id,
          user: users.find((contact) => contact.id === centre.user_id_contact),
        }))

        renderEmailComponent(
          RFQEmail,
          { workOrder, jobs, centres, priorities, centreContacts },
          (html) => {
            setMsgProps({
              to: supplier?.email,
              subject: `RFQ for ${workOrder.work_order_no}`,
              html: html,
              text: `Please provide a quote for ${workOrder.work_order_no}`,
              replyTo: userEmail,
              // We need this on the return trip from previewing the email, so we can mark the quote as sent
              quoteId: quoteId,
            })
            setShowPreviewEmailDialog(true)
          }
        )
      })
  }

  const handleSendEmailAndMarkSent = ({ html, to, replyTo, text, subject }) => {
    setShowPreviewEmailDialog(false)

    sendHtmlEmail({
      to: to,
      replyTo: replyTo,
      subject: subject,
      html: html,
      text: text,
    })
  }

  const updateQuoteAsSent = ({ workOrderId, quoteId }) => {
    db.collection("work_orders")
      .doc(workOrderId)
      .collection("quotes")
      .doc(quoteId)
      .update(
        {
          sent: dataServices.serverTimestamp(),
          modified: dataServices.serverTimestamp(),
        },
        { merge: true }
      )
  }

  /**
   * Create a new quote, or update an existing quote
   *
   * @param {*} quote
   */
  const handleSaveQuote = async (quote) => {
    setShowQuoteDialog(false)

    const quoteId = await quoteServices.handleCreateOrUpdateQuote({
      quote,
      jobs,
      accountId,
      workOrderId,
    })

    return quoteId
  }

  /***
   * Send quote to approver for approval
   * @param quoteId
   * @param approver User who is being requested to approve the quote
   */
  const handleSendForApproval = (approver) => {
    db.collection("work_orders").doc(workOrderId).update({
      approver: approver.id,
      modified: dataServices.serverTimestamp(),
    })
  }

  /**
   * Mark the selected quote as approved - see 'approved' and 'approved_by' fields, and update work order history
   * Allocate the quote to the supplier in the selected quote
   * Mark the work order as Allocated so it becomes visible to the supplier.
   * Send emails to suppliers, both the selected and other suppliers to inform them of the outcome.
   *
   * @param {*} quoteId
   */
  const handleApproveQuote = async (quoteId) => {
    const quote = quotes.find((quote) => quote.id === quoteId)

    // Update quote 'approved' (timestamp) and 'approved_by' (email) fields

    await db
      .collection("work_orders")
      .doc(workOrderId)
      .collection("quotes")
      .doc(quoteId)
      .update({
        account_id: accountId,
        approved: dataServices.serverTimestamp(),
        approved_by: userEmail,
        modified: dataServices.serverTimestamp(),
      })

    // Get supplier

    const supplierDoc = await db
      .collection("suppliers")
      .doc(quote.supplier_id)
      .get()
    const supplier = supplierDoc.data()

    // Get work order

    // const workOrderDoc = await db.collection("work_orders").doc(workOrderId).get()
    // const workOrder = workOrderDoc.data()

    // Set work order to allocated, and allocate to supplier on quote

    const workOrderUpdate = {
      account_id: accountId,
      status: workOrderStatus.STATUS_ALLOCATED,
      supplier_id: quote.supplier_id,
      supplier_access_account_ids: [],
      modified: dataServices.serverTimestamp(),
    }
    if (supplier.supplier_account_id) {
      // Ensure work order only accessible by 1 supplier
      workOrderUpdate.supplier_access_account_ids = [
        supplier.supplier_account_id,
      ]
    }

    await updateWorkOrder(workOrderUpdate)

    await workOrderServices.appendWorkOrderHistory({
      workOrderId,
      status: workOrderStatus.STATUS_ALLOCATED,
      userEmail: getAuth().currentUser.email,
      comment: `Work order allocated to supplier ${supplier.name}`,
    })

    // Change all jobs in work order to Open if they are Pending

    const jobs = await db
      .collection("jobs")
      .where("work_order_id", "==", workOrderId)
      .where("account_id", "==", accountId)
      .where("status", "==", jobServices.JOB_STATUS_PENDING)
      .get()

    jobs.forEach((job) => {
      job.ref.update({
        status: jobServices.JOB_STATUS_OPEN,
        modified: dataServices.serverTimestamp(),
      })
    })

    //TODO: Send emails to suppliers, both the selected and other suppliers to inform them of the outcome.
  }

  const handleAddQuote = () => {
    setEditQuote({
      account_id: accountId,
      work_order_id: workOrderId,
      terms: "",
      preferred: false,
      total: "",
      supplier_id: "",
    })
    setShowQuoteDialog(true)
  }

  const handleEditQuote = (quoteId) => {
    setEditQuote(quotes.find((quote) => quote.id === quoteId))
    setShowQuoteDialog(true)
  }

  const handleDeleteQuote = (workOrderId, quoteId) => {
    quoteServices.deleteQuote(workOrderId, quoteId).then(() => {
      supplierServices.updateSupplierAccess({
        workOrderId: workOrderId,
        source: "Delete quote",
      })
    })
  }

  return (
    <Box>
      {showPreviewEmailDialog && (
        <PreviewEmailDialog
          open={showPreviewEmailDialog}
          onClose={() => setShowPreviewEmailDialog(false)}
          msgProps={msgProps}
          handleSendEmail={({ html, to, replyTo, text, subject }) => {
            handleSendEmailAndMarkSent({ html, to, replyTo, text, subject })
            updateQuoteAsSent({ workOrderId, quoteId: msgProps.quoteId })
          }}
        />
      )}

      {showQuoteDialog && (
        <QuoteDialog
          open={showQuoteDialog}
          onClose={() => setShowQuoteDialog(false)}
          quote={editQuote}
          accountId={accountId}
          handleSaveQuote={handleSaveQuote}
          handleSendRFQEmailWithoutPreview={handleSendRFQEmailWithoutPreview}
          jobTypes={
            (jobs &&
              Array.from(
                new Set(jobs.map((job) => job.category).filter((item) => item))
              )) ||
            []
          }
        />
      )}

      <Stack gap={2}>
        <SubHeading title="Quotes" />

        {isMakeVisibleToSupplierRequired && (
          <Box sx={{ padding: "5px" }}>
            <Alert severity="warning">
              Change status to{" "}
              <StatusChip status={workOrderStatus.STATUS_QUOTE_REQUESTED} /> to
              make this work order visible to external suppliers.
            </Alert>
          </Box>
        )}

        <Box>
          <Desktop width={675}>
            <QuotesAsGrid
              quotes={quotes}
              suppliers={suppliers}
              accountType={accountType}
              workOrderId={workOrderId}
              approvers={approvers}
              handleApproveQuote={handleApproveQuote}
              handleEditQuote={handleEditQuote}
              handleDeleteQuote={handleDeleteQuote}
              handleSaveQuote={handleSaveQuote}
              handlePreviewRFQEmail={handlePreviewRFQEmail}
              handleSendForApproval={handleSendForApproval}
            />
          </Desktop>
          <Mobile width={675}>
            <QuotesAsTiles
              quotes={quotes}
              suppliers={suppliers}
              accountType={accountType}
              workOrderId={workOrderId}
              approvers={approvers}
              handleApproveQuote={handleApproveQuote}
              handleEditQuote={handleEditQuote}
              handleDeleteQuote={handleDeleteQuote}
              handleSaveQuote={handleSaveQuote}
              handlePreviewRFQEmail={handlePreviewRFQEmail}
              handleSendForApproval={handleSendForApproval}
            />
          </Mobile>
        </Box>

        {accountType === roleServices.ACCOUNT_TYPE_CENTRE && (
          <Box sx={{ marginBottom: "20px" }}>
            <Controls.Button
              aria-label="add"
              aria-haspopup="false"
              onClick={handleAddQuote}
              text="Add Quote"
            />
          </Box>
        )}
      </Stack>
    </Box>
  )
}

const QuotesAsTiles = ({
  quotes,
  suppliers,
  accountType,
  workOrderId,
  approvers,
  handleApproveQuote,
  handleEditQuote,
  handleDeleteQuote,
  handleSaveQuote,
  handlePreviewRFQEmail,
  handleSendForApproval,
}) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1 }}
    >
      {quotes.length === 0 && (
        <Typography variant="body2" color="text.secondary">
          No quotes
        </Typography>
      )}
      {quotes.map((quote) => {
        const supplier = suppliers.find((s) => s.id === quote.supplier_id)
        return (
          <WorkOrderQuoteTile
            key={quote.id}
            workOrderId={workOrderId}
            quote={quote}
            supplier={supplier}
            accountType={accountType}
            approvers={approvers}
            handleEditQuote={handleEditQuote}
            handleDeleteQuote={handleDeleteQuote}
            handleSaveQuote={handleSaveQuote}
            handlePreviewRFQEmail={handlePreviewRFQEmail}
            handleSendForApproval={handleSendForApproval}
            handleApproveQuote={handleApproveQuote}
          />
        )
      })}
    </Box>
  )
}

const WorkOrderQuoteTile = ({
  workOrderId,
  quote,
  supplier,
  accountType,
  approvers,
  handleEditQuote,
  handleDeleteQuote,
  handleSaveQuote,
  handlePreviewRFQEmail,
  handleSendForApproval,
  handleApproveQuote,
}) => {
  const [anchorEl, setAnchorEl] = useState()

  const menuId = `menu-${quote.id}`

  const handleToggleMenu = (event) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleToggleQuotePreferred = () => {
    handleSaveQuote({ id: quote.id, ...quote, preferred: !quote.preferred })
  }

  return (
    <>
      <Card sx={{ width: "300px" }}>
        <CardHeader
          title={
            <TruncatedText noWrap sx={{ fontWeight: "bold" }}>
              {supplier?.name} {quote.preferred === "Y" && `(*)`}
            </TruncatedText>
          }
          action={
            <IconButton aria-label="settings" onClick={handleToggleMenu}>
              <MoreVertIcon />
            </IconButton>
          }
        />
        <CardActionArea onClick={() => handleEditQuote(quote.id)}>
          <CardContent sx={{ padding: "15px" }}>
            <Stack gap={0.5}>
              <LabelValue label="Total" value={quote.total} />
              <LabelValue label="Terms" value={quote.terms} />
              <LabelValue
                label="Sent"
                value={quote.sent && format(quote.sent.toDate(), "dd MMM yy")}
              />
              <LabelValue
                label="Received"
                value={
                  quote.received && format(quote.received.toDate(), "dd MMM yy")
                }
              />
              <LabelValue
                label="Preferred"
                value={quote.preferred ? "Yes" : "No"}
              />
            </Stack>
          </CardContent>
        </CardActionArea>
        {anchorEl && (
          <QuoteMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            menuId={menuId}
            quote={quote}
            supplier={supplier}
            accountType={accountType}
            approvers={approvers}
            handleEditQuote={() => handleEditQuote(quote.id)}
            handleDeleteQuote={() => handleDeleteQuote(workOrderId, quote.id)}
            handleToggleQuotePreferred={handleToggleQuotePreferred}
            handlePreviewRFQEmail={handlePreviewRFQEmail}
            handleSendForApproval={handleSendForApproval}
            handleApproveQuote={handleApproveQuote}
          />
        )}
      </Card>
    </>
  )
}

const QuotesAsGrid = ({
  quotes,
  suppliers,
  accountType,
  workOrderId,
  approvers,
  handleApproveQuote,
  handleEditQuote,
  handleDeleteQuote,
  handleSaveQuote,
  handlePreviewRFQEmail,
  handleSendForApproval,
}) => {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="quotes">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={styles.tableHead}>
                Supplier
              </TableCell>
              {accountType === roleServices.ACCOUNT_TYPE_CENTRE && (
                <TableCell align="left" sx={styles.tableHead}>
                  Preferred
                </TableCell>
              )}
              <TableCell align="left" sx={styles.tableHead}>
                Total
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Terms
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Sent
              </TableCell>
              <TableCell align="left" sx={styles.tableHead}>
                Received
              </TableCell>

              <TableCell align="right" sx={styles.tableHeadMenu}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quotes.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={7}>
                  <Typography variant="body2" color="text.secondary">
                    No quotes
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {quotes.map((quote) => (
              <QuoteRow
                key={quote.id}
                quote={quote}
                handleEditQuote={() => handleEditQuote(quote.id)}
                handleDeleteQuote={() =>
                  handleDeleteQuote(workOrderId, quote.id)
                }
                handleSaveQuote={handleSaveQuote}
                suppliers={suppliers}
                handlePreviewRFQEmail={handlePreviewRFQEmail}
                handleSendForApproval={handleSendForApproval}
                handleApproveQuote={handleApproveQuote}
                approvers={approvers}
                accountType={accountType}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const QuoteMenu = ({
  anchorEl,
  setAnchorEl,
  menuId,
  handleEditQuote,
  accountType,
  handleDeleteQuote,
  handleToggleQuotePreferred,
  handlePreviewRFQEmail,
  handleSendForApproval,
  handleApproveQuote,
  quote,
  supplier,
  approvers,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: isMobile ? "left" : "left",
      }}
      transformOrigin={{
        vertical: isMobile ? "top" : "top",
        horizontal: isMobile ? "left" : "left",
      }}
      sx={{
        left: isMobile ? 0 : undefined, // Position menu at the left edge of the screen on mobile devices
        width: isMobile ? "100%" : undefined, // Set menu width to 100% on mobile devices
      }}
    >
      <MenuItem
        onClick={() => {
          setAnchorEl(null)
          handleEditQuote()
        }}
      >
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        Edit Quote
      </MenuItem>
      {accountType === roleServices.ACCOUNT_TYPE_CENTRE && (
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            handleDeleteQuote()
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Delete Quote
        </MenuItem>
      )}
      {accountType === roleServices.ACCOUNT_TYPE_CENTRE && (
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            handleToggleQuotePreferred()
          }}
        >
          <ListItemIcon>
            <CheckBoxIcon fontSize="small" />
          </ListItemIcon>
          Toggle Preferred
        </MenuItem>
      )}
      {accountType === roleServices.ACCOUNT_TYPE_CENTRE && (
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            handlePreviewRFQEmail({ quoteId: quote.id, supplier })
          }}
        >
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Send RFQ" secondary={supplier?.email} />
        </MenuItem>
      )}
      {accountType === roleServices.ACCOUNT_TYPE_CENTRE && (
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            handleApproveQuote(quote.id)
          }}
        >
          <ListItemIcon>
            <icons.SupplierIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary="Approve"
            secondary={
              <Typography variant="body2" color="text.secondary">
                Allocate to {supplier?.name}
              </Typography>
            }
          />
        </MenuItem>
      )}
      {accountType === roleServices.ACCOUNT_TYPE_SUPPLIER && (
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
          }}
        >
          <ListItemIcon>
            <EventBusyIcon fontSize="small" />
          </ListItemIcon>
          Decline
        </MenuItem>
      )}
      {accountType === roleServices.ACCOUNT_TYPE_CENTRE &&
        approvers &&
        approvers.map((approver) => (
          <MenuItem
            key={approver.id}
            onClick={() => {
              setAnchorEl(null)
              handleSendForApproval(approver)
            }}
          >
            <ListItemIcon>
              <ApprovalIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary="Send for Approval"
              secondary={approver.email}
            />
          </MenuItem>
        ))}
    </Menu>
  )
}

const QuoteRow = (props) => {
  const {
    quote,
    suppliers,
    handleEditQuote,
    handleDeleteQuote,
    handleSaveQuote,
    handlePreviewRFQEmail,
    handleApproveQuote,
    handleSendForApproval,
    approvers,
    accountType,
  } = props

  const [anchorEl, setAnchorEl] = useState()

  const menuId = `menu-${quote.id}`

  const handleToggleQuotePreferred = () => {
    handleSaveQuote({ id: quote.id, ...quote, preferred: !quote.preferred })
  }

  const supplier = useMemo(() => {
    return suppliers && suppliers.find((s) => s.id === quote.supplier_id)
  }, [suppliers, quote])

  return (
    <TableRow key={quote.id}>
      <TableCell align="left" sx={styles.cellSmall}>
        {supplier?.name}
      </TableCell>
      {accountType === roleServices.ACCOUNT_TYPE_CENTRE && (
        <TableCell align="left" sx={styles.cellSmall}>
          {quote.preferred ? "Yes" : "No"}
        </TableCell>
      )}
      <TableCell align="left" sx={styles.cellSmall}>
        {quote.total}
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        {quote.terms}
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        <Typography variant="body2" noWrap={true}>
          {quote.sent && format(quote.sent.toDate(), "dd/MM/yy")}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={styles.cellSmall}>
        <Typography variant="body2" noWrap={true}>
          {quote.received && format(quote.received.toDate(), "dd/MM/yy")}
        </Typography>
      </TableCell>
      <TableCell align="right" sx={styles.cellSmall}>
        <IconButton
          size="small"
          aria-label="more"
          aria-controls={`menu-${quote.id}`}
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>

        {anchorEl && (
          <QuoteMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            menuId={menuId}
            quote={quote}
            supplier={supplier}
            accountType={accountType}
            approvers={approvers}
            handleEditQuote={handleEditQuote}
            handleDeleteQuote={handleDeleteQuote}
            handleToggleQuotePreferred={handleToggleQuotePreferred}
            handlePreviewRFQEmail={handlePreviewRFQEmail}
            handleSendForApproval={handleSendForApproval}
            handleApproveQuote={handleApproveQuote}
          />
        )}
      </TableCell>
    </TableRow>
  )
}

export default WorkOrderQuotesGrid
