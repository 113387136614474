import * as actions from "../actionTypes"

// initial state is no items selected
// each item is:
// {
//    id: work order document id
//    label: work order name
// }

const initialState = {
  value: [],
}

export default function workOrderGridSelectedItems(
  state = initialState,
  action
) {
  switch (action.type) {
    case actions.SET_WORK_ORDER_GRID_SELECTED_ITEMS: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
