import React from "react"
import Header from "../components/Header"
import { withRouter } from "react-router-dom"
import PriorityList from "../components/PriorityList"

const PriorityEdit = (props) => {
  return (
    <Header title={"Job Priorities"}>
      <PriorityList />
    </Header>
  )
}

export default withRouter(PriorityEdit)
