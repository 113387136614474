import * as actions from "../actionTypes"

// range is { start: <date>, end: <date> }
const initialState = {
  value: {},
}

export default function workOrderCalendarDateRange(
  state = initialState,
  action
) {
  switch (action.type) {
    case actions.SET_WORK_ORDER_CALENDAR_DATE_RANGE: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
