import { Box, IconButton, Stack } from "@mui/material"
import { useState } from "react"
import Controls from "./controls/Controls"
import Button from "./controls/Button"
import SendIcon from "@mui/icons-material/Send"

const AddComment = (props) => {
  const {
    handleAddComment,
    // for replies, the parent comment id is provided
    parentCommentId,
    parentAccountId,
    label = "Type a message",
    buttonLabel = "Add Message",
    showCancel = false,
    setShowReply,
  } = props

  const [newComment, setNewComment] = useState("")

  return (
    <Stack direction="column" gap={1}>
      <Stack direction="row" gap={1}>
        <Controls.TextInput
          name="comment"
          label={label}
          fullWidth
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (newComment === "") return
              handleAddComment({
                commentText: e.target.value,
                parentCommentId,
                parentAccountId,
              })
              setNewComment("")
              setShowReply && setShowReply(false)
            }
          }}
        />
        <Box>
          <IconButton
            onClick={(e) => {
              if (newComment === "") return
              handleAddComment({
                commentText: newComment,
                parentCommentId,
                parentAccountId,
              })
              setNewComment("")
              setShowReply && setShowReply(false)
            }}
          >
            <SendIcon fontSize="small" />
          </IconButton>
        </Box>
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          marginTop: "5px",
          marginLeft: "auto",
          justifyContent: "flex-end",
          marginBottom: "15px",
        }}
      >
        <Box sx={{ display: "flex", alignSelf: "flex-end", gap: 2 }}>
          {showCancel && (
            <Button
              size="small"
              text="Cancel"
              variant="outlined"
              onClick={() => {
                setNewComment("")
                setShowReply && setShowReply(false)
              }}
            />
          )}
          {/* <Button
            size="small"
            text={buttonLabel}
            variant="outlined"
            onClick={(e) => {
              if (newComment === "") return
              handleAddComment({
                commentText: newComment,
                parentCommentId,
                parentAccountId,
              })
              setNewComment("")
              setShowReply && setShowReply(false)
            }}
          /> */}
        </Box>
      </Box>
    </Stack>
  )
}

export default AddComment
