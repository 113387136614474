import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { lighten } from "@mui/material/styles"
import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"
import AnnouncementIcon from "@mui/icons-material/Announcement"
import EventRepeatIcon from "@mui/icons-material/EventRepeat"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import { Box, Chip, IconButton, Menu, MenuItem } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectWorkOrderGridPagination } from "../redux/selectors"
import { setWorkOrderGridPagination } from "../redux/actions"
import { selectWorkOrderGridPageDocs } from "../redux/selectors"
import { setWorkOrderGridPageDocs } from "../redux/actions"
import { selectWorkOrderGridSelectedItems } from "../redux/selectors"
import { setWorkOrderGridSelectedItems } from "../redux/actions"
import { useSnackbar } from "notistack"
import Controls from "./controls/Controls"
import { ListItem } from "@mui/material"
import { ListItemText } from "@mui/material"
import { ListItemIcon } from "@mui/material"
import * as WorkOrder from "../components/workOrderStatus"
import * as Roles from "../pages/services/roleServices"
import _ from "lodash"
import WorkOrderStatusSelector from "./controls/WorkOrderStatusSelector"
import LinkButton from "./controls/LinkButton"
import { MoreVertIcon } from "../icons"
import StatusChip from "./controls/StatusChip"
import * as workOrderServices from "../pages/services/workOrderServices"
import * as workOrderStatus from "../components/workOrderStatus"
import * as roleServices from "../pages/services/roleServices"
import YesNo from "./YesNo"
import { spacing } from "../pages/services/styleServices"
import NavButtons from "./NavButtons"
import PageNo from "./PageNo"
import { J4J_YELLOW } from "../pages/services/colorServices"
import * as supplierServices from "../pages/services/supplierServices"
import { Desktop, Mobile } from "./WindowSizes"
import WorkOrderTile from "./WorkOrderTile"
import { useHistory } from "react-router-dom"
import {
  getCountFromServer,
  collection,
  query,
  orderBy,
  startAfter,
  startAt,
  limit,
  getDocs,
} from "firebase/firestore"
import useDocumentPagination from "./useDocumentPagination"
import FilterItems from "./FilterItems"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import TruncatedText from "./TruncatedText"

const WorkOrderGrid = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    selected,
    workOrders,
    rowCount,
    onRequestSort,
    isSupplierViewing,
  } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const headCells = useMemo(() => {
    if (isSupplierViewing === undefined) {
      return
    }

    const cols = [
      {
        id: "label",
        numeric: false,
        disablePadding: true,
        label: "Label",
        sortable: false,
      },
      {
        id: "work_order_no",
        numeric: false,
        disablePadding: true,
        label: "Work Order",
        sortable: false,
      },
      {
        id: "supplier",
        numeric: false,
        disablePadding: true,
        label: "Supplier",
        sortable: false,
      },
      {
        id: "status",
        numeric: false,
        disablePadding: true,
        label: "Status",
        sortable: false,
      },
      {
        id: "start_date",
        numeric: false,
        disablePadding: true,
        label: "Start Date",
        sortable: false,
      },
      {
        id: "created",
        numeric: false,
        disablePadding: true,
        label: "Created",
        sortable: true,
      },
    ]

    if (isSupplierViewing === true) {
      return cols.filter((item) => item.id !== "supplier")
    } else {
      return cols
    }
  }, [isSupplierViewing])

  const workOrdersSelectedCount = workOrders.filter((item) =>
    selected.find((s) => item.id === s.id)
  ).length
  const allWorkOrdersOnPageSelected =
    workOrdersSelectedCount === rowCount && rowCount > 0
  const someWorkOrdersOnPageSelected =
    workOrdersSelectedCount > 0 && workOrdersSelectedCount < rowCount

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={someWorkOrdersOnPageSelected || rowCount === 0}
            checked={allWorkOrdersOnPageSelected}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all work orders" }}
            sx={{ color: J4J_YELLOW }}
          />
        </TableCell>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            ) : (
              <b>{headCell.label}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledToolbar = styled(Toolbar)(({ numselected, theme }) => {
  const result = {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  }

  if (numselected > 0) {
    result.color = theme.palette.secondary.main
    result.backgroundColor = lighten(theme.palette.secondary.light, 0.85)
  }

  return result
})

WorkOrderGrid.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  isSupplierViewing: PropTypes.bool.isRequired,
}

const toolbarStyles = {
  title: {
    flex: "1 1 100%",
  },
}

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    selected,
    setSelected,
    workOrders,
    accountId,
    dispatch,
    setWorkOrderGridSelectedItems,
  } = props

  const { enqueueSnackbar } = useSnackbar()

  const [yesNoCloseWorkOrderConfig, setYesNoCloseWorkOrderConfig] = useState({
    openPrompt: false,
    description: "Close selected work order(s)",
    title: "Close",
    handleConfirm: null,
  })

  const handleDeleteChip = (workOrderId) => {
    const newSelected = selected.filter((item) => item.id !== workOrderId)
    setSelected(newSelected)
    dispatch(setWorkOrderGridSelectedItems(newSelected))
  }

  const handlePromptCloseWorkOrders = async () => {
    const newConfig = {
      ...yesNoCloseWorkOrderConfig,
      openPrompt: true,
      handleConfirm: () => handleCloseWorkOrders(),
    }

    setYesNoCloseWorkOrderConfig(newConfig)
  }

  const handleCloseWorkOrders = async () => {
    // 'workOrders' will only contain the work orders on the current page
    const foundWorkOrders = workOrders.filter((item) =>
      selected.find((s) => item.id === s.id)
    )

    // 'selected' will contain all the work orders selected across all pages
    const missingWorkOrders = selected.filter(
      (item) => !foundWorkOrders.find((s) => item.id === s.id)
    )

    // Load work orders not on the current page
    const loadedWorkOrders = await dataServices.getWorkOrdersByIdChunks({
      workOrderIds: missingWorkOrders.map((item) => item.id),
      accountId,
    })

    // Merge the work orders on the current page with the loaded work orders
    const workOrdersToClose = [...foundWorkOrders, ...loadedWorkOrders]

    const processed = []

    for (var i = 0; i < workOrdersToClose.length; i++) {
      const workOrder = workOrdersToClose[i]

      const result = await workOrderServices.changeWorkOrderStatus({
        workOrderId: workOrder.id,
        status: workOrderStatus.STATUS_CLOSED,
      })

      if (result.status === "error") {
        enqueueSnackbar(
          `#${workOrder.work_order_no} ${workOrder.label} - ${result.msg}`,
          {
            variant: "info",
          }
        )
      } else {
        processed.push(workOrder.id)
      }
    }

    // Remove items that were processed from being selected
    setSelected(selected.filter((item) => !processed.includes(item.id)))
  }

  return (
    <StyledToolbar numselected={numSelected}>
      {numSelected > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={toolbarStyles.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
          <Box sx={{ marginLeft: "10px", display: "flex", gap: "5px" }}>
            {selected.map((item) => (
              <Chip
                label={item.label}
                onDelete={() => handleDeleteChip(item.id)}
                color="primary"
                key={item.id}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Typography
          sx={toolbarStyles.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Work Orders
        </Typography>
      )}

      <YesNo config={yesNoCloseWorkOrderConfig} />

      {numSelected > 0 && (
        <Box sx={{ display: "flex", flexDirection: "row", marginLeft: "auto" }}>
          <Controls.Button
            aria-label="Close"
            text="Close"
            size="small"
            onClick={handlePromptCloseWorkOrders}
          ></Controls.Button>
        </Box>
      )}
    </StyledToolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: spacing(2),
  },
  table: {
    minWidth: 750,
  },
  gridContainer: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  filterGrid: {
    paddingLeft: "15px",
  },
  eventIcon: {
    verticalAlign: "middle",
    marginLeft: spacing(1),
    fontSize: "18px",
  },
  tableRow: {
    "&:hover": {
      cursor: "hand",
    },
  },
}

export default function EnhancedTable() {
  const selectedWorkOrderItems = useSelector(selectWorkOrderGridSelectedItems)
  const [selected, setSelected] = React.useState(selectedWorkOrderItems)

  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = React.useState(true)

  const pag = useSelector(selectWorkOrderGridPagination)

  const pgDocs = useSelector(selectWorkOrderGridPageDocs)
  const {
    addPage,
    clearPageDocs,
    getFirstDocThisPage,
    getLastDocPreviousPage,
  } = useDocumentPagination(pgDocs)

  const dispatch = useDispatch()

  // Filter by status
  const [statusFilter, setStatusFilter] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const [centres, setCentres] = useState([])

  const [workOrderNo, setWorkOrderNo] = useState(pag.work_order_no)

  const [invoiceNo, setInvoiceNo] = useState(pag.invoice_no)

  const [workOrderCount, setWorkOrderCount] = useState(0)

  const [suppliers, setSuppliers] = useState([])

  const [users, setUsers] = useState([])

  // Leave as undefined til actually known, so we don't issue a query and trigger a firestore permission error
  const [isCentreUserLoggedIn, setCentreUserLoggedIn] = useState()

  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const history = useHistory()

  const [isSupplierViewing, setSupplierViewing] = useState()

  const [userRoles, setUserRoles] = useState([])

  const [workOrders, setWorkOrders] = useState([])

  const [accountId, setAccountId] = useState()

  const [accountType, setAccountType] = useState()

  const [canSearchCentres, setCanSearchCentres] = useState(true)

  const [currentUser, setCurrentUser] = useState()

  const [claims, setClaims] = useState()

  const [userCentreIds, setUserCentreIds] = useState(undefined)

  useEffect(() => {
    if (accountType && pag.statuses === undefined) {
      if (accountType === roleServices.SUPPLIER) {
        const newPag = { ...pag, statuses: [workOrderStatus.STATUS_ALLOCATED] }
        dispatch(setWorkOrderGridPagination(newPag))
        setStatusFilter([workOrderStatus.STATUS_ALLOCATED])
      } else {
        const newPag = {
          ...pag,
          statuses: [
            workOrderStatus.STATUS_OPEN,
            workOrderStatus.STATUS_ALLOCATED,
          ],
        }
        dispatch(setWorkOrderGridPagination(newPag))
        setStatusFilter([
          workOrderStatus.STATUS_OPEN,
          workOrderStatus.STATUS_ALLOCATED,
        ])
      }
    } else {
      setStatusFilter(pag.statuses)
    }
  }, [pag, accountType])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user != null) {
        user.getIdTokenResult().then((token) => {
          const isJobAdmin = token.claims.roles.includes(Roles.JOB_ADMIN)
          const centreIds = token.claims.centre_ids

          setUserRoles(token.claims.roles)
          setClaims(token.claims)

          const canSearch =
            isJobAdmin || (centreIds !== undefined && centreIds.length > 0)

          setCanSearchCentres(canSearch)

          if (token.claims.roles.includes(Roles.JOB_ADMIN)) {
            setUserCentreIds([]) // all centres
          } else {
            setUserCentreIds(token.claims.centre_ids)

            if (token.claims.centre_ids.length === 1) {
              setCentreFilterValue(token.claims.centre_ids[0])
            }
          }
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    const auth = getAuth()
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setCurrentUser(token)
          setAccountId(token.claims.account_id)
          setAccountType(token.claims.account_type)
          setCentreUserLoggedIn(token.claims.account_type === "centre")

          const isSupplier = token.claims.account_type === "supplier"
          setSupplierViewing(isSupplier)
        })
      } else {
        setCentreUserLoggedIn(false)
      }
    })

    return unsub
  }, [])

  const handleSupplierChange = (event) => {
    // if we click the 'x' icon in the drop down we get null instead of '' for the event.target.value
    const value = event.target.value === null ? "" : event.target.value

    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      supplier_id: value,
    }

    setDirection("")
    dispatch(setWorkOrderGridPagination(updatedPagination))
  }

  const setCentreFilterValue = (centreId) => {
    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      centre_id: centreId,
    }

    setDirection("")
    dispatch(setWorkOrderGridPagination(updatedPagination))
  }

  const handleCloseWorkOrder = (workOrderId) => {
    workOrderServices
      .changeWorkOrderStatus({
        workOrderId: workOrderId,
        status: workOrderStatus.STATUS_CLOSED,
      })
      .then((result) => {
        enqueueSnackbar(result.msg, { variant: result.status })
      })
  }

  const handleOpenWorkOrder = (workOrderId) => {
    workOrderServices.changeWorkOrderStatus({
      workOrderId: workOrderId,
      status: workOrderStatus.STATUS_OPEN,
    })
  }

  const handleCompleteWorkOrder = (workOrderId) => {
    workOrderServices.changeWorkOrderStatus({
      workOrderId: workOrderId,
      status: workOrderStatus.STATUS_COMPLETED,
    })
  }

  const handleStatusFilterChange = (statuses) => {
    setStatusFilter(statuses)

    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      statuses: statuses,
    }

    setDirection("")
    dispatch(setWorkOrderGridPagination(updatedPagination))
  }

  const handleCentreChange = (event) => {
    // if we click the 'x' icon in the drop down we get null instead of '' for the event.target.value
    const value = event.target.value === null ? "" : event.target.value

    if (value === pag.centre_id) {
      return
    }

    setCentreFilterValue(value)
  }

  const updateWorkOrderNoFilter = (event) => {
    const value = event.target.value === null ? "" : event.target.value

    if (value === pag.work_order_no) {
      return
    }

    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      work_order_no: value,
    }

    setDirection("")
    dispatch(setWorkOrderGridPagination(updatedPagination))
  }

  const updateInvoiceNoFilter = (event) => {
    const value = event.target.value === null ? "" : event.target.value

    if (value === pag.invoice_no) {
      return
    }

    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      invoice_no: value,
    }

    setDirection("")
    dispatch(setWorkOrderGridPagination(updatedPagination))
  }

  const updateMyWorkOrdersFilter = (value) => {
    clearPageDocs()

    const updatedPagination = {
      ...pag,
      page: 0,
      show_my_work_orders: value,
    }

    setDirection("")
    dispatch(setWorkOrderGridPagination(updatedPagination))
  }

  const handleWorkOrderNoChange = (event) => {
    setWorkOrderNo(event.target.value)
  }

  const COLLECTION_NAME = "work_orders"

  // Listen for changes

  useEffect(() => {
    if (claims === undefined) {
      return
    }

    const queryMods = []

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())
      .orderBy("modified", "desc")

    queryMods.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    let unsub

    switch (claims.account_type) {
      case "centre":
        query = query.where("account_id", "==", claims.account_id)
        queryMods.push("account_id == " + claims.account_id)
        break

      case "supplier":
        query = query.where(
          "supplier_access_account_ids",
          "array-contains",
          claims.account_id
        )
        queryMods.push(
          "supplier_access_account_ids array-contains " + claims.account_id
        )
        break

      default:
        throw new Error("Unknown account type " + claims.account_type)
    }

    unsub = query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const changedWorkOrder = {
            id: change.doc.id,
            ...change.doc.data(),
            openUrl: workOrderServices.getOpenWorkOrderUrl({
              accountType: claims.account_type,
              workOrderId: change.doc.id,
            }),
          }

          switch (change.type) {
            case "added":
              setWorkOrders((curr) => [
                changedWorkOrder,
                ...curr.filter((item) => item.id !== changedWorkOrder.id),
              ])

              break

            case "modified":
              setWorkOrders((curr) => {
                const newWorkOrders = curr.map((item) => {
                  if (item.id === change.doc.id) {
                    return changedWorkOrder
                  } else {
                    return item
                  }
                })
                return newWorkOrders
              })
              break

            case "removed":
              setWorkOrders((curr) => {
                const newWorkOrders = curr.filter(
                  (item) => item.id !== change.doc.id
                )
                return newWorkOrders
              })
              break

            default:
              console.error("Unknown change type", change.type)
          }
        })
      },
      (error) => console.error("error listening for work order changes", error)
    )

    return unsub
  }, [claims])

  const updatePageDocs = ({ querySnapshot, pagination, direction }) => {
    if (querySnapshot.docs.length > 0 && direction !== "prev") {
      const newPageDocs = addPage({ querySnapshot, page: pagination.page })
      dispatch(setWorkOrderGridPageDocs(newPageDocs))
    }
  }

  const distinct = (value, index, self) => {
    return self.indexOf(value) === index
  }

  useEffect(() => {
    if (isSupplierViewing === undefined) {
      return
    }

    const centreIds = workOrders
      .map((workOrder) => workOrder.centre_id)
      .filter(distinct)
      .filter((item) => item !== "")

    if (centreIds.length > 0) {
      // See if centres already contain the required keys
      const loadedIds = centres.map((centre) => centre.id)

      const containsAll = centreIds.every((id) => loadedIds.includes(id))

      if (!containsAll) {
        const missingCentreIds = centreIds.filter(
          (id) => id !== undefined && !loadedIds.includes(id)
        )

        if (missingCentreIds.length > 0) {
          dataServices
            .getCentresByIdChunks(missingCentreIds)
            .then((extraCentres) => {
              const newCentres = [...centres, ...extraCentres]
              setCentres(newCentres)
            })
        }
      }
    }

    if (isSupplierViewing === false) {
      const supplierIds = workOrders
        .map((workOrder) => workOrder.supplier_id)
        .filter(distinct)
        .filter((item) => item !== "" && item !== null)

      if (supplierIds.length > 0) {
        // See if suppliers already contain the required keys
        const loadedIds = suppliers.map((supplier) => supplier.id)

        const containsAll = supplierIds.every((id) => loadedIds.includes(id))

        if (!containsAll) {
          const missingSupplierIds = supplierIds.filter(
            (id) => id !== undefined && !loadedIds.includes(id)
          )

          if (missingSupplierIds.length > 0) {
            dataServices
              .getSuppliersById(missingSupplierIds, accountId)
              .then((extraSuppliers) => {
                const newSuppliers = [...suppliers, ...extraSuppliers]
                setSuppliers(newSuppliers)
              })
          }
        }
      }

      const userIds = workOrders
        .map((workOrder) => workOrder.maint_user_id)
        .filter(distinct)
        .filter((item) => item !== "" && item !== null && item !== undefined)

      if (userIds.length === 0) {
        return
      }

      // See if users already contain the required keys
      const loadedUserIds = users.map((user) => user.id)

      const containsAllUsers = userIds.every((id) => loadedUserIds.includes(id))

      if (!containsAllUsers) {
        const missingUserIds = userIds.filter(
          (id) => !loadedUserIds.includes(id)
        )

        dataServices
          .getUsersByIdChunks(accountId, missingUserIds)
          .then((extraUsers) => {
            const newUsers = [...users, ...extraUsers]
            setUsers(newUsers)
          })
      }
    }
  }, [workOrders, accountId, isSupplierViewing])

  const getWorkOrderCount = ({ queryConstraints }) => {
    const countWorkOrders = query(
      collection(db, "work_orders"),
      ...queryConstraints
    )
    getCountFromServer(countWorkOrders).then((result) => {
      setWorkOrderCount(result.data().count)
    })
  }

  // Load work orders

  const [reloadState, setReloadState] = useState({})

  useEffect(() => {
    if (accountId === undefined) {
      return
    }

    if (!canSearchCentres) {
      return
    }

    const newReloadState = { accountId, pag, userCentreIds }

    const isReloadStateChanged = !_.isEqual(reloadState, newReloadState)

    setReloadState(newReloadState)

    if (!isReloadStateChanged) {
      return
    }

    const workOrderQueryParams = workOrderServices.getWorkOrderQueryParams({
      direction: "next",
      pagination: pag,
      currentUser,
    })

    let { queryMods, queryConstraints } =
      workOrderServices.createWorkOrderQuery(workOrderQueryParams)

    const dataQueryClauses = []
    const isSingleDocSearch = pag.work_order_no !== "" || pag.invoice_no !== ""

    if (!isSingleDocSearch) {
      dataQueryClauses.push(orderBy(pag.orderBy, pag.order))
      queryMods.push("order by " + pag.orderBy + " " + pag.order)
    }

    if (pag.page > 0 && direction !== "prev") {
      const lastDoc = getLastDocPreviousPage(pag.page)
      if (lastDoc) {
        queryMods.push("start after last doc on previous page", { lastDoc })
        dataQueryClauses.push(startAfter(lastDoc))
      }
    } else if (direction === "prev") {
      const firstDoc = getFirstDocThisPage(pag.page)
      queryMods.push("start at 1st doc on page", { firstDoc })
      dataQueryClauses.push(startAt(firstDoc))
    }

    dataQueryClauses.push(limit(rowsPerPage))
    queryMods.push("limit " + rowsPerPage)

    const dataQuery = query(
      collection(db, "work_orders"),
      ...[...queryConstraints, ...dataQueryClauses]
    )

    if (canSearchCentres) {
      getDocs(dataQuery).then((querySnapshot) => {
        const workOrders = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })

        const workOrdersWithOpenUrl = workOrders.map((workOrder) => {
          const openUrl = workOrderServices.getOpenWorkOrderUrl({
            accountType,
            workOrderId: workOrder.id,
          })
          return { ...workOrder, openUrl }
        })

        setWorkOrders(workOrdersWithOpenUrl)
        updatePageDocs({ querySnapshot, pagination: pag, direction })

        if (workOrders.length === rowsPerPage || pag.page > 0) {
          getWorkOrderCount({ queryConstraints })
        } else {
          setWorkOrderCount(workOrders.length)
        }
      })
    } else {
      setWorkOrders([])
      clearPageDocs()
    }
  }, [accountId, pag, userCentreIds])

  const handleRequestSort = (event, property) => {
    const isAsc = pag.orderBy === property && pag.order === "asc"

    const updatedPagination = {
      ...pag,
      page: 0,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }

    dispatch(setWorkOrderGridPagination(updatedPagination))
    clearPageDocs()
    dispatch(setWorkOrderGridPageDocs([]))
    setDirection("")
  }

  // Select all on work order grid

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allSelected = workOrders.map((n) => {
        return {
          id: n.id,
          label: n.label,
        }
      })

      // Find newly selected ids

      const newSelected = [...selected]

      const newAdditions = allSelected.filter(
        (item) => selected.find((s) => item.id === s.id) === undefined
      )
      newAdditions.forEach((item) => newSelected.push(item))

      setSelected(newSelected)
      dispatch(setWorkOrderGridSelectedItems(newSelected))
    } else {
      // Remove all work orders from selected
      const allDeselected = workOrders.map((n) => {
        return {
          id: n.id,
          label: n.label,
        }
      })
      const newSelected = selected.filter(
        (item) => allDeselected.find((s) => item.id === s.id) === undefined
      )
      setSelected(newSelected)
      dispatch(setWorkOrderGridSelectedItems(newSelected))
    }
  }

  const deselectWorkOrder = (id) => {
    const existing = selected.find((item) => item.id === id)
    if (existing) {
      const newSelected = selected.filter((item) => item.id !== id)
      setSelected(newSelected)
      dispatch(setWorkOrderGridSelectedItems(newSelected))
    }
  }

  const handleClick = (event, label, id) => {
    if (event.target.checked) {
      const newSelected = [...selected]
      newSelected.push({
        id: id,
        label: label,
      })
      setSelected(newSelected)
      dispatch(setWorkOrderGridSelectedItems(newSelected))
    } else {
      deselectWorkOrder(id)
    }
  }

  const handlePageNav = (pageChange) => {
    const newPage = pag.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pag,
        page: newPage,
      }

      dispatch(setWorkOrderGridPagination(updatedPagination))
    }
  }

  const isSelected = (id) =>
    selected.find((item) => item.id === id) !== undefined

  return (
    <>
      <FilterItems>
        {isCentreUserLoggedIn && (
          <Box sx={{ minWidth: "300px" }}>
            <Controls.SupplierCombobox
              name="supplier_id"
              label="Supplier"
              value={pag.supplier_id}
              accountId={accountId}
              readonly={pag.show_my_work_orders}
              onChange={handleSupplierChange}
            />
          </Box>
        )}

        {/* <Box sx={{ minWidth: "300px" }}>
                            <Controls.SupplierAutocomplete
                                onChange={handleSupplier2Change}
                                value={supplier2Id}
                                accountId={accountId}
                            />
                        </Box> */}

        {isCentreUserLoggedIn && (
          <Box sx={{ minWidth: "300px" }}>
            {userCentreIds && (
              <Controls.CentreCombobox
                name="centre_id"
                label="Centre"
                value={pag.centre_id}
                accountId={accountId}
                readonly={false}
                onChange={handleCentreChange}
                centreIdsFilter={userCentreIds}
              />
            )}
          </Box>
        )}

        <Box>
          {statusFilter && (
            <WorkOrderStatusSelector
              statusFilter={statusFilter}
              setStatusFilter={handleStatusFilterChange}
              userCentreIds={userCentreIds}
              accountType={accountType}
            />
          )}
        </Box>

        <Box sx={{ maxWidth: "120px" }}>
          <Controls.TextInput
            label="Work Order No"
            name="workOrderNo"
            value={workOrderNo}
            type="search"
            onChange={handleWorkOrderNoChange}
            onBlur={updateWorkOrderNoFilter}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                updateWorkOrderNoFilter(event)
              }
            }}
          />
        </Box>

        {isCentreUserLoggedIn && (
          <Box sx={{ maxWidth: "120px" }}>
            <Controls.TextInput
              label="Invoice No"
              name="invoiceNo"
              value={invoiceNo}
              type="search"
              onChange={(e) => setInvoiceNo(e.target.value)}
              onBlur={updateInvoiceNoFilter}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  updateInvoiceNoFilter(event)
                }
              }}
            />
          </Box>
        )}

        {isCentreUserLoggedIn && userRoles.includes(Roles.MAINTENANCE) && (
          <Controls.Checkbox
            label="My Work Orders"
            name="maint_user_id"
            value={pag.show_my_work_orders}
            onChange={(e) => updateMyWorkOrdersFilter(e.target.value)}
          />
        )}
      </FilterItems>

      <Box>
        <Mobile width={675}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {centres &&
              workOrders &&
              workOrders.map((workOrder) => (
                <WorkOrderTile
                  key={workOrder.id}
                  workOrder={workOrder}
                  isEditable={false}
                  suppliers={suppliers}
                  users={users}
                  isSupplierViewing={isSupplierViewing}
                  handleCloseWorkOrder={handleCloseWorkOrder}
                  handleOpenWorkOrder={handleOpenWorkOrder}
                  handleCompleteWorkOrder={handleCompleteWorkOrder}
                  handleEditWorkOrder={() => {
                    const url = workOrderServices.getOpenWorkOrderUrl({
                      accountType,
                      workOrderId: workOrder.id,
                    })

                    history.push(url)
                  }}
                />
              ))}
          </Box>
        </Mobile>
      </Box>

      <div sx={styles.root}>
        <Desktop width={675}>
          <Box>
            <EnhancedTableToolbar
              numSelected={selected.length}
              selected={selected}
              setSelected={setSelected}
              workOrders={workOrders}
              accountId={accountId}
              accountType={accountType}
              dispatch={dispatch}
              setWorkOrderGridSelectedItems={setWorkOrderGridSelectedItems}
            />
            <TableContainer>
              <Table
                sx={styles.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="Work Orders"
              >
                {/* Wait until we've established if a supplier or centre is viewing the page */}
                {isSupplierViewing !== undefined && (
                  <WorkOrderGrid
                    numSelected={selected.length}
                    selected={selected}
                    workOrders={workOrders}
                    order={pag.order}
                    orderBy={pag.orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={workOrders.length}
                    isSupplierViewing={isSupplierViewing}
                  />
                )}
                <TableBody>
                  {workOrders.map((row, index) => {
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    let supplierName = ""
                    let maintUserName = ""

                    if (row.supplier_id !== "") {
                      const supplier = suppliers.find(
                        (supplier) => supplier.id === row.supplier_id
                      )
                      if (supplier) {
                        supplierName = supplier.name
                      }
                    } else if (row.maint_user_id !== "") {
                      const maintUser = users.find(
                        (user) => user.id === row.maint_user_id
                      )
                      if (maintUser) {
                        maintUserName = maintUser.name
                      }
                    }

                    return (
                      <TableRow
                        hover
                        onClick={(event) => {
                          if (event.target.name === "select-work-order") return
                          history.push(row.openUrl)
                        }}
                        sx={styles.tableRow}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            name="select-work-order"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            sx={{
                              color: J4J_YELLOW,
                            }}
                            onClick={(event) =>
                              handleClick(event, row.label, row.id)
                            }
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <TruncatedText
                            variant="body2"
                            sx={{ maxWidth: "400px" }}
                          >
                            {row.label || "Work Order (no label)"}
                          </TruncatedText>
                        </TableCell>

                        <TableCell align="left" padding="none">
                          <LinkButton to={row.openUrl}>
                            {row.work_order_no}
                          </LinkButton>
                        </TableCell>

                        {isSupplierViewing !== undefined &&
                          isSupplierViewing === false && (
                            <TableCell align="left" padding="none">
                              {supplierName && row.supplier_id && (
                                <LinkButton
                                  to={`/SupplierEdit/${row.supplier_id}`}
                                >
                                  <TruncatedText
                                    variant="body2"
                                    sx={{ maxWidth: "120px" }}
                                  >
                                    {supplierName}
                                  </TruncatedText>
                                </LinkButton>
                              )}
                              {maintUserName && row.maint_user_id && (
                                <TruncatedText
                                  variant="body2"
                                  sx={{ maxWidth: "120px" }}
                                >
                                  {maintUserName}
                                </TruncatedText>
                              )}
                            </TableCell>
                          )}

                        <TableCell
                          align="left"
                          padding="none"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Controls.StatusChip status={row.status} />
                          <ChangeWorkOrderStatus
                            userRoles={userRoles}
                            isSupplierViewing={isSupplierViewing}
                            currentStatus={row.status}
                            workOrderId={row.id}
                          />
                        </TableCell>
                        <TableCell align="left" padding="none">
                          <Typography variant="body2" noWrap={true}>
                            {row.start_date &&
                              format(row.start_date.toDate(), "dd MMM yy")}
                            {row.schedule && row.schedule.type && (
                              <EventRepeatIcon sx={styles.eventIcon} />
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" padding="none">
                          <Typography variant="body2" noWrap={true}>
                            {format(row.created.toDate(), "dd MMM yy")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Desktop>
        {!canSearchCentres && (
          <ListItem>
            <ListItemIcon>
              <AnnouncementIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="caption">
                You don't have permissions to search work orders. See your
                administrator
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        <NavButtons>
          <Controls.Button
            variant="contained"
            size="small"
            disabled={pag.page === 0}
            onClick={() => handlePageNav(-1)}
            text="Prev"
          />
          <Controls.Button
            variant="contained"
            size="small"
            disabled={workOrders.length < rowsPerPage}
            onClick={() => handlePageNav(1)}
            text="Next"
          />
        </NavButtons>

        <PageNo
          pageNo={pag.page + 1}
          count={workOrderCount}
          type={"work orders"}
        />
      </div>
    </>
  )
}

const ChangeWorkOrderStatus = (props) => {
  const { userRoles, isSupplierViewing, currentStatus, workOrderId } = props

  const { enqueueSnackbar } = useSnackbar()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleChangeStatus = async ({ toStatus }) => {
    setAnchorEl(null)

    const result = await workOrderServices.changeWorkOrderStatus({
      workOrderId: workOrderId,
      status: toStatus,
    })

    // Only update supplier access if the change is made by the centre
    if (isSupplierViewing === false) {
      await supplierServices.updateSupplierAccess({
        workOrderId,
        source: "work order grid",
      })
    }
    if (result.msg !== "") {
      enqueueSnackbar(result.msg, { variant: "info" })
    }
  }

  const menuItems = useMemo(() => {
    if (anchorEl !== null) {
      const actions = WorkOrder.getAvailableActions(
        userRoles,
        isSupplierViewing,
        currentStatus,
        true
      )

      const menuItems = actions.map((action) => (
        <MenuItem
          key={action.label}
          onClick={(e) => {
            e.stopPropagation()
            handleChangeStatus({ toStatus: action.to })
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">{action.label}</Typography>
            <StatusChip status={action.to} />
          </Box>
        </MenuItem>
      ))

      return menuItems
    } else {
      return []
    }
  }, [anchorEl, workOrderId])

  return (
    <>
      <IconButton
        size="small"
        sx={{ marginLeft: "5px" }}
        onClick={(event) => {
          event.stopPropagation()
          setAnchorEl(event.currentTarget)
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(e) => {
          e.stopPropagation()
          setAnchorEl(null)
        }}
      >
        {menuItems && menuItems.map((item) => item)}
      </Menu>
    </>
  )
}
