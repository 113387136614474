import { Box } from "@mui/material"

const FilterItems = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        marginTop: "10px",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  )
}

export default FilterItems
