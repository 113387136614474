import React from "react"
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material"
import Controls from "./controls/Controls"
import StatusChip from "./controls/StatusChip"
import { Typography } from "@mui/material"
import moment from "moment"
import { spacing } from "../pages/services/styleServices"

const styles = {
    buttons: {
        margin: spacing(1),
        padding: 0,
        display: "flex",
        justifyContent: "flex-end",
    },
    statusBox: {
        paddingRight: spacing(3),
        minWidth: 120,
    },
    historyRow: {
        display: "flex",
        flexWrap: "no-wrap",
        marginBottom: spacing(1),
        alignItems: "center",
    },
}

const History = (props) => {
    const { open, setOpen, historyObj } = props

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">History ({historyObj.history?.length})</DialogTitle>
            <DialogContent>
                <DialogContentText>View History</DialogContentText>
                <Grid container direction="column">
                    {historyObj && historyObj.history.map((historyEntry) => (
                        <Grid item sx={styles.historyRow} key={historyEntry.date.seconds}>
                            <Box sx={styles.statusBox}>
                                <StatusChip status={historyEntry.status} />
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                                    {historyEntry.user}
                                </Typography>
                                <Typography variant="caption" color="primary">
                                    {moment(historyEntry.date.toDate()).format(
                                        "DD MMM yyyy h:mm a"
                                    )}
                                </Typography>
                                {historyEntry.comment && (
                                    <Typography
                                        variant="caption"
                                        sx={{ color: "#555", marginTop: "5px" }}
                                    >
                                        {historyEntry.comment}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="column">
                    <Grid item>
                        <Box sx={styles.buttons}>
                            <Controls.Button
                                text="Close"
                                onClick={handleClose}
                                variant="outlined"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default History
