import React, { useEffect, useState } from "react"
import { Backdrop, Box, CircularProgress, Paper, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { spacing } from "../pages/services/styleServices"

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
}))

const styles = {
    label: {
        padding: spacing(1),
        marginTop: "10px",
    },
}

const ProgressBackdrop = (props) => {
    const { label } = props

    const [isOpen, setOpen] = useState(props.open)

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    return (
        <StyledBackdrop open={isOpen} onClick={handleClose}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                }}
            >
                <CircularProgress color="inherit" />
                {label && (
                    <Paper sx={styles.label}>
                        <Typography>{label}</Typography>
                    </Paper>
                )}
            </Box>
        </StyledBackdrop>
    )
}

export default ProgressBackdrop
