import { ListItemIcon, Menu, MenuItem, Typography, useMediaQuery, useTheme } from "@mui/material"
import * as icons from "../icons"
import EditIcon from "@mui/icons-material/Edit"
import * as workOrderStatus from "./workOrderStatus"

const WorkOrderMenu = ({
    workOrder,
    anchorEl,
    setAnchorEl,
    menuId,
    isEditable,
    isSupplierViewing,
    handleCloseWorkOrder,
    handleOpenWorkOrder,
    handleCompleteWorkOrder,
    handleEditWorkOrder,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    return (
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: isMobile ? "bottom" : "top",
                horizontal: isMobile ? "left" : "left",
            }}
            transformOrigin={{
                vertical: isMobile ? "top" : "top",
                horizontal: isMobile ? "left" : "left",
            }}
            sx={{
                left: isMobile ? 0 : undefined, // Position menu at the left edge of the screen on mobile devices
                width: isMobile ? "100%" : undefined, // Set menu width to 100% on mobile devices
            }}
        >
            {/* Suppliers can't mark a work order as closed */}
            {workOrder.status !== workOrderStatus.STATUS_CLOSED && !isSupplierViewing && (
                <MenuItem key="close-work-order" onClick={handleCloseWorkOrder}>
                    <ListItemIcon>
                        <icons.JobCloseIcon />
                    </ListItemIcon>
                    <Typography>Close Work Order</Typography>
                </MenuItem>
            )}

            {workOrder.status !== workOrderStatus.STATUS_OPEN && !isSupplierViewing && (
                <MenuItem key="reopen-work-order" onClick={handleOpenWorkOrder}>
                    <ListItemIcon>
                        <icons.JobOpenIcon />
                    </ListItemIcon>
                    <Typography>Open Work Order</Typography>
                </MenuItem>
            )}

            {workOrder.status !== workOrderStatus.STATUS_CLOSED && (
                <MenuItem key="complete-work-order" onClick={handleCompleteWorkOrder}>
                    <ListItemIcon>
                        <icons.JobCompleteIcon />
                    </ListItemIcon>
                    <Typography>Complete Work Order</Typography>
                </MenuItem>
            )}

            <MenuItem onClick={handleEditWorkOrder}>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                {isSupplierViewing ? "View Work Order" : "Edit Work Order"}
            </MenuItem>
        </Menu>
    )
}

export default WorkOrderMenu
